import axios from "axios";

export interface DictionaryList {
    ngUid: number;
    ngNm: string;
    info:string;
    searchIndex: string;
    crtUid: number;
    crtDt: string;
    updUid: number;
    updDt: string;
    delUid: number;
    delDt: string;
}

interface GetAllParams {
    ngNm?: string;
    searchIndex?: string;
}
const getList = (params: GetAllParams) => {
    return axios
    .get('/api/glossary/getList',{params})
    .then((res) => res.data as DictionaryList[]);
}


const DictionaryAPI = {
    getList,
}
export default DictionaryAPI;