import React, { useState } from 'react';
import './VillGiftCardPage.scss';
import { useDialog } from '../../../dialog/DialogProvider';
import { useNavigate } from 'react-router';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import Pagination from '../../../components/common/Pagination';
import VillGiftCardDialog from '../../../dialog/myPage/VillGiftCardDialog';
const VillGiftCardPage = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const handleClick = () => {
		// const title = '마을 상품권 교환';
		// const message = '현재 준비중인 서비스입니다.';
		// const action = '인증ok';
		// showDialog(OneButtonAlertDialog, {
		// 	title,
		// 	message,
		// 	handleDialogResult(result) {
		// 		if (result === '확인') {
		// 		}
		// 	},
		// });
		showDialog(VillGiftCardDialog);
	};
	//
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	return (
		<main className="VillGiftCardPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				{/* <div className="lnb_menu">
					<ul className="lnb_dep1">
						<li className="lnb_dep1_li">
							<a className="home">
								<img src="../assets/ic_home.png" alt="메인으로" />
							</a>
						</li>
						<li className="lnb_dep1_li">
							<a>마이페이지</a>
						</li>
						<li className="lnb_dep1_li end">
							<a>내 알람</a>
							<ul className="lnb_dep2">
								<li>
									<a>내 정보 수정</a>
								</li>
								<li>
									<a>내 마을 변경</a>
								</li>
								<li>
									<a>내 교육프로그램</a>
								</li>
								<li className="active">
									<a>내 알람</a>
								</li>
							</ul>
						</li>
					</ul>
				</div> */}
				<div className="lnb_txt">
					<h3>마을상품권 교환</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>
						내 E 포인트로 에너지전환마을에서 사용할 수 있는 마을상품권으로
						교환하세요
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/* 현재 포인트 시작 */}
					<div className="point_wrap">
						<p className="big">
							현재 가용 POINT <span>5,000 E</span>
						</p>
					</div>
					{/* 현재 포인트 종료 */}
					{/* 리스트 시작 */}
					<div className="gift_wrap mt40">
						<ul>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">
											거점마을 상품권 거점마을 상품권 거점마을 상품권 거점마을
											상품권
										</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">
											거점마을 상품권 거점마을 상품권 거점마을 상품권 거점마을
											상품권
										</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">
											거점마을 상품권 거점마을 상품권 거점마을 상품권 거점마을
											상품권
										</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
							<li onClick={handleClick}>
								<a className="pa30">
									<div className="gift_inner">
										<p className="txt_cut1">남은수량 : 20 / 100 개</p>
										<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
										<p className="point mt5">5,000</p>
									</div>
								</a>
							</li>
						</ul>
					</div>
					<Pagination
						page={Number(page)}
						totalPageCount={Number(totalPage)}
						// totalPageCount={Math.ceil(count / 8)}
						onPageChange={handlePageChange}
					/>
					{/* 리스트 종료 */}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default VillGiftCardPage;
