import React, { useEffect, useRef, useState } from 'react';
import NavComponent from '../../../components/common/NavComponent';
import Pagination from '../../../components/common/Pagination';
import CommTab from '../CommTab';
import './List_ProgramPage.scss';
import test04 from '../../../assets/test04.png';
import {
	comm_filter,
	comm_filter2_1,
	comm_filter2_2,
	comm_filter2_3,
	comm_filter2_4,
} from './comm_filter';
import { time } from 'console';
import { useNavigate } from 'react-router';
import useMember from '../../../hooks/useMember';
import EduProgramAPI from '../../../apis/EduProgramAPI';
import { EduProgramList, ProgramCategory } from '../../../models/EduProgram';
import moment from 'moment';
import noList from '../../../assets/noList.png';
import defaultImg from '../../../assets/img_gjd.png';
const List_ProgramPage = () => {
	const townId = sessionStorage.getItem('town');
	const navigate = useNavigate();
	const { member } = useMember();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState<number>(0);
	const [cateList, setCateList] = useState<ProgramCategory>();
	const [selectedcateList, setSelectedcateList] = useState([] as number[]);
	const [selectedStatus, setSelectedStatus] = useState<string>();
	const [selectedCateList_1, setSelectedCateList_1] = useState([] as number[]);
	const [selectedCateList_2, setSelectedCateList_2] = useState([] as number[]);
	const [searchKeyword, setSearchKeyword] = useState<string>();
	const [search, setSearch] = useState<string>();
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	// 테스트용 State
	//prev 대기, ing 신청중, over 신청종료, end 교육종료
	const [eduProgramList, setEduProgramList] = useState<EduProgramList>();
	// 글 권한
	const [postRoles, setPostRoles] = useState(false);
	// 글, 댓글 비공개 권한
	const [praviteRoles, setPraviteRoles] = useState(false);
	// 마을뉴스 카테고리 등록 권한
	const [newsCateRoles, setNewsCateRoles] = useState(false);
	//권한 별 글 및 댓글 등록, 수정, 삭제 마을뉴스 카테고리 등록 권한 아직 테스트 못함 2023.09.05
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setPostRoles(true);
				setPraviteRoles(true);
				// setNewsCateRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO && member.townDTO.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setPostRoles(true);
					setPraviteRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// // console.log("권한 : 마을활동가, 마을주민");
				// if(member.townId === Number(townId) && member.id === crtUid){
				// setPostRoles(true);
				// }
			}
		}
	}, []);
	useEffect(() => {
		EduProgramAPI.getCategory().then((res) => {
			// console.log('카테고리 목록', res);
			setCateList(res);
		});
	}, []);
	useEffect(() => {
		setSelectedcateList([]);
		selectedcateList.push(...selectedCateList_1);
		selectedcateList.push(...selectedCateList_2);
		EduProgramAPI.getEduProgramList({
			page: page - 1,
			size: 6,
			sort: 'educationEndDate,DESC',
			category: selectedcateList ? selectedcateList : undefined,
			tcUid: Number(townId),
			status: selectedStatus == '' ? undefined : selectedStatus,
			keyword: searchKeyword ? searchKeyword : undefined,
		}).then((res) => {
			// console.log('프로그램 리스트 >>> ', res);
			setEduProgramList(res);
			setCount(res.totalElements);
			res &&
				res.content &&
				res.content.map((list, index) => {
					if (list.tcategories.length != 0) {
						list.tCateList = list.tcategories
							.map((item) => item.categoryTypeCode)
							.join(', ');
					}
					if (list.fcategories.length != 0) {
						list.fCateList = list.fcategories
							.map((item) => item.categoryTypeCode)
							.join(', ');
					}
					const nowDate = new Date();
					const startDateTime = new Date(list.requestStartDateTime);
					const endDateTime = new Date(list.requestEndDateTime);
					const endDate = new Date(list.educationEndDate);
					// // console.log("현재날짜", nowDate)
					// // console.log("교육신청기간 날짜", startDateTime)
					// // console.log("교육신청종료기간 날짜", endDateTime)
					// // console.log("교육기간종료 날짜", endDate)

					// prev 대기, ing 신청중, over 신청종료, end 교육종료
					if (
						nowDate > startDateTime &&
						nowDate > endDateTime &&
						nowDate > endDate
					) {
						// console.log('교육종료');
						list.state = 'end';
					} else if (nowDate < startDateTime) {
						// console.log('대기');
						list.state = 'prev';
					} else if (nowDate > startDateTime && nowDate < endDateTime) {
						// console.log('신청중');
						list.state = 'ing';
					} else if (nowDate > startDateTime && nowDate > endDateTime) {
						// console.log('신청종료');
						list.state = 'over';
					}
					// 	if (list.tcategories.length != 0) {
					// 		list.tcategories.map((list2, index2) => {
					// 			res.content[index].tCateList = [];
					// 			res.content[index].tCateList.push(list2.categoryTypeCode)
					// 		})
					// 	}
					// 	if (list.fcategories.length != 0) {
					// 		list.fcategories.map((list2, index2) => {
					// 			res.content[index].fCateList = [];
					// 			res.content[index].fCateList.push(list2.categoryTypeCode)
					// 		})
					// 	}
				});
		});
	}, [
		page,
		selectedCateList_1,
		selectedCateList_2,
		selectedStatus,
		searchKeyword,
	]);

	const handleSelect_1 = (select: string) => {
		// console.log('selectBox_1 :: ', select);
		setSelectedStatus(select);
	};
	const handleSelect_2 = (select: number[]) => {
		// console.log('selectBox_2 :: ', select);
		setSelectedCateList_1(select);
	};
	const handleSelect_3 = (select: number[]) => {
		// console.log('selectBox_3 :: ', select);
		setSelectedCateList_2(select);
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// 상세화면 이동
	const handleDetail = (sfUid: number) => {
		sessionStorage.setItem('space', String(sfUid));
		navigate('/commu_02/eduProgram_03-detail');
	};
	// 등록하기 이동
	const handleWrite = () => {
		navigate('/commu_02/eduProgram_04-write');
	};
	// 검색창 엔터
	const handleEnterSearch = (e: any) => {
		if (e.key === 'Enter') {
			setSearchKeyword(search);
		}
	};
	// 검색 버튼클릭
	const handleClickSearch = () => {
		setSearchKeyword(search);
	};
	return (
		<main className="List_ProgramPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={4} />
					<div className="village_program">
						<div className="comm_search mt40">
							<ul>
								<SelectComponent1 handleSelect_1={handleSelect_1} />
								<SelectComponent2
									cateList={cateList}
									handleSelect_2={handleSelect_2}
								/>
								<SelectComponent3
									cateList={cateList}
									handleSelect_3={handleSelect_3}
								/>
								<li>
									<div className="comm_search_plus">
										<input
											type="text"
											placeholder="검색어를 입력해주세요."
											onChange={(e) => setSearch(e.target.value)}
											onKeyPress={handleEnterSearch}
										/>
									</div>
									<button type="button" onClick={handleClickSearch}>
										검색
									</button>
								</li>
							</ul>
						</div>
						{postRoles && (
							<div className="comm_tool mt25">
								<button
									type="button"
									className="comm_write_button"
									onClick={handleWrite}
								>
									등록하기
								</button>
							</div>
						)}
						<div className="village_program_list mt25">
							<ul>
								{/* 비공개 게시물 non클래스 붙음 */}
								{eduProgramList && eduProgramList?.content.length == 0 ? (
									<div className="comm_noList">
										<img src={noList} alt="내용없음 이미지" />
										<p className="mt25">{nullListMessage}</p>
									</div>
								) : (
									eduProgramList?.content.map((list, index) => (
										<li
											key={index}
											className={`${list.visible === 'Y' ? '' : 'non'}`}
											onClick={() => handleDetail(list.sfUid)}
										>
											<a>
												{/* prev 대기, ing 신청중, over 신청종료, end 교육종료 */}
												<div className={`box_program ${list.state}`}>
													<div className="img_program">
														<img
															src={
																list.filePath
																	? `/api/attach/getFile?savedPath=${list.filePath}`
																	: defaultImg
															}
															alt="프로그램 이미지"
														/>
														<span>
															{list.bookingCnt}/{list.memberCount}
														</span>
													</div>
													<h4 className="comm_title txt_cut1 mt10">
														{list.facilityName}
													</h4>
													<div className="info3 mt10">
														{list.tCateList && (
															<p className="target txt_cut1">
																{list.tCateList}
															</p>
														)}
														{list.fCateList && (
															<p className="part txt_cut1">{list.fCateList}</p>
														)}
														<p className="time">
															{moment(list.educationStartDate).format(
																'yyyy.MM.DD'
															)}{' '}
															~{' '}
															{moment(list.educationEndDate).format(
																'yyyy.MM.DD'
															)}
														</p>
													</div>
												</div>
											</a>
										</li>
									))
								)}
								{/* 비공개 게시물 non클래스 붙음 */}
							</ul>
						</div>
						<Pagination
							page={page}
							// totalPageCount={10}
							totalPageCount={Math.ceil(count / 6)}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</article>
		</main>
	);
};
// 진행상태 컴포넌트
const SelectComponent1 = ({
	handleSelect_1,
}: {
	handleSelect_1: (select: string) => void;
}) => {
	const [listbox_1, setListbox_1] = useState(false);

	return (
		<li>
			<select
				className="comm_select01"
				onChange={(e) => handleSelect_1(e.target.value)}
			>
				<option value="">진행상태</option>
				<option value="a">대기</option>
				<option value="b">신청중</option>
				<option value="c">신청종료</option>
				<option value="d">교육종료</option>
			</select>
		</li>
	);
};
// 교육대상 컴포넌트
const SelectComponent2 = ({
	cateList,
	handleSelect_2,
}: {
	cateList?: ProgramCategory;
	handleSelect_2: (select: number[]) => void;
}) => {
	const [listbox_2, setListbox_2] = useState(false);
	const [selectValue, setSelectValue] = useState([] as number[]);

	useEffect(() => {
		handleSelect_2(selectValue);
	}, [selectValue]);
	const handleListBox_2 = () => {
		// console.log(listbox_2);
		setListbox_2((listbox) => !listbox);
	};
	// 체크박스 체크항목 selectValue State 에 추가 체크해제 시 삭제
	const handleSelectCheckbox = (checked: any) => {
		// console.log(checked.target.value);
		// 제한없음 체크 시 다른 체크항목 전부 체크해제
		if (checked.target.value == 7) {
			setSelectValue([Number(checked.target.value)]);
		}
		// 제한없음 체크해제 또는 다른항목 체크 시 제한없음 체크해제
		if (checked.target.checked) {
			if (checked.target.value != 7) {
				setSelectValue((cur) => [
					...cur.filter((item) => item != 7),
					Number(checked.target.value),
				]);
			}
			// 체크해제
		} else {
			const notChecked = selectValue.filter(
				(item) => item != Number(checked.target.value)
			);
			setSelectValue(notChecked);
		}
	};
	return (
		<li>
			<div className="comm_filter" onClick={handleListBox_2}>
				<p className="txt_cut1">교육대상</p>
				<div
					className="comm_filter_popup pa20"
					style={{ display: listbox_2 ? 'block' : 'none' }}
				>
					<ul>
						{cateList?.tcategoryList.map((list, index) => (
							<li key={index}>
								<p>
									<label>
										<input
											type="checkbox"
											name="targer"
											defaultValue='tg"'
											value={list.scUid}
											checked={selectValue.includes(list.scUid)}
											onChange={handleSelectCheckbox}
										/>
										<span>{list.categoryTypeCode}</span>
									</label>
								</p>
							</li>
						))}
					</ul>
				</div>
			</div>
		</li>
	);
};

// 교육분야 컴포넌트
const SelectComponent3 = ({
	cateList,
	handleSelect_3,
}: {
	cateList?: ProgramCategory;
	handleSelect_3: (select: number[]) => void;
}) => {
	const [listbox_3, setListbox_3] = useState(false);
	const [selectValue, setSelectValue] = useState([] as number[]);

	useEffect(() => {
		handleSelect_3(selectValue);
	}, [selectValue]);

	const handleListBox_3 = () => {
		// console.log(listbox_3);
		setListbox_3((listbox) => !listbox);
	};

	// 체크박스 체크항목 selectValue State 에 추가 체크해제 시 삭제
	const handleSelectCheckbox = (checked: any) => {
		// // console.log(checked.target.checked);
		if (checked.target.checked) {
			setSelectValue((cur) => [...cur, Number(checked.target.value)]);
			// 체크해제
		} else {
			const notChecked = selectValue.filter(
				(item) => item !== Number(checked.target.value)
			);
			setSelectValue(notChecked);
		}
	};
	return (
		<li>
			<div className="comm_filter" onClick={handleListBox_3}>
				<p className="txt_cut1">교육분야</p>
				<div
					className="comm_filter_popup pa20"
					style={{ display: listbox_3 ? 'block' : 'none' }}
				>
					{/* 묶음 시작 */}
					<h4>일반 환경보건</h4>
					<ul>
						{cateList?.fcategoryList
							.filter((filter) => filter.scUid <= 9 || filter.scUid === 19)
							.map((list, index) => (
								<li key={index}>
									<p>
										<label>
											<input
												type="checkbox"
												name="part"
												defaultValue='pt"'
												value={list.scUid}
												checked={selectValue.includes(list.scUid)}
												onChange={handleSelectCheckbox}
											/>
											<span>{list.categoryTypeCode}</span>
										</label>
									</p>
								</li>
							))}
					</ul>
					{/* 묶음 종료 */}
					{/* 묶음 시작 */}
					<h4>신재생 에너지</h4>
					<ul>
						{cateList?.fcategoryList
							.filter((filter) => filter.scUid > 9 && filter.scUid <= 13)
							.map((list, index) => (
								<li key={index}>
									<p>
										<label>
											<input
												type="checkbox"
												name="part"
												defaultValue='pt"'
												value={list.scUid}
												checked={selectValue.includes(list.scUid)}
												onChange={handleSelectCheckbox}
											/>
											<span>{list.categoryTypeCode}</span>
										</label>
									</p>
								</li>
							))}
					</ul>
					{/* 묶음 종료 */}
					{/* 묶음 시작 */}
					<h4>기후변화</h4>
					<ul>
						{cateList?.fcategoryList
							.filter((filter) => filter.scUid > 13 && filter.scUid <= 15)
							.map((list, index) => (
								<li key={index}>
									<p>
										<label>
											<input
												type="checkbox"
												name="part"
												defaultValue='pt"'
												value={list.scUid}
												checked={selectValue.includes(list.scUid)}
												onChange={handleSelectCheckbox}
											/>
											<span>{list.categoryTypeCode}</span>
										</label>
									</p>
								</li>
							))}
					</ul>
					{/* 묶음 종료 */}
					{/* 묶음 시작 */}
					<h4>자원순환</h4>
					<ul>
						{cateList?.fcategoryList
							.filter((filter) => filter.scUid > 15 && filter.scUid <= 18)
							.map((list, index) => (
								<li key={index}>
									<p>
										<label>
											<input
												type="checkbox"
												name="part"
												defaultValue='pt"'
												value={list.scUid}
												checked={selectValue.includes(list.scUid)}
												onChange={handleSelectCheckbox}
											/>
											<span>{list.categoryTypeCode}</span>
										</label>
									</p>
								</li>
							))}
					</ul>
					{/* 묶음 종료 */}
				</div>
			</div>
		</li>
	);
};

export default List_ProgramPage;
