import React, { useEffect, useRef, useState } from 'react';
import NavComponent from '../../../components/common/NavComponent';
import test04 from '../../../assets/test04.png';
import test05 from '../../../assets/test05.png';
import program01 from '../../../assets/program01.png';
import program02 from '../../../assets/program02.png';
import './Detail_ProgramPage.scss';
import CommTab from '../CommTab';
import Map_ProgramDetailPage from './Map_ProgramDetailPage';
import { SubmitHandler, useForm } from 'react-hook-form';
import ShareDialog from '../../../dialog/common/ShareDialog';
import { useDialog } from '../../../dialog/DialogProvider';
import { useNavigate } from 'react-router';
import TwoButtonAlertDialog from '../../../dialog/TwoButtonAlertDialog';
import { BookingContent, ProgramContent } from '../../../models/EduProgram';
import EduProgramAPI from '../../../apis/EduProgramAPI';
import LikeRoleDialog from '../../../dialog/common/LikeRoleDialog ';
import useMember from '../../../hooks/useMember';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import moment from 'moment';
import defaultImg from '../../../assets/img_gjd.png';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import Pagination from '../../../components/common/Pagination';
import SelectDialog from '../../../dialog/program/SelectDialog';
import 'moment/locale/ko';
import { useSearchParams } from 'react-router-dom';
import noList from '../../../assets/noList.png';
import MemberAPI from '../../../apis/MemberAPI';
import MemberInfo from '../../../models/MemberInfo';
import { listenerCount } from 'process';
import DeleteFailDailog from '../../../dialog/common/DeleteFailDailog';
import PrivateSuccessDialog from '../../../dialog/common/PrivateSuccessDialog';

const serviceType = [1, 2, 3, 4]
const serviceList = [
	{ id: 0, serviceNameList: [{ serviceName: '1111111' }, { serviceName: '22222222' }, { serviceName: '33333333' }] },
	{ id: 1, serviceNameList: [{ serviceName: '44444444' }, { serviceName: '5555555' }, { serviceName: '66666666' }] },
	{ id: 2, serviceNameList: [{ serviceName: '77777777' }, { serviceName: '8888888' }] },
	{ id: 3, serviceNameList: [{ serviceName: '10101010101010' }] },
]
// 소통마당 > 교육프로그램 상세

const Detail_ProgramPage = () => {
	const [searchParams] = useSearchParams();
	const space = searchParams.get('space');
	const town = searchParams.get('town');
	const { member } = useMember();
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [state, setState] = useState<string>('신청');
	const [managementTab, setManagementTab] = useState<string>('');
	const [programDetail, setProgramDetail] = useState<ProgramContent>();
	const [formattedDate, setFormattedDate] = useState('');
	const [likeCheck, setLikeCheck] = useState(false);
	const sfUid = sessionStorage.getItem('space')
		? sessionStorage.getItem('space')
		: space;
	const townId = sessionStorage.getItem('town')
		? sessionStorage.getItem('town')
		: town;

	const [statusCheck, setStatusCheck] = useState(false);
	const [generalRoles, setGeneralRoles] = useState(false);
	const [managerRoles, setManagerRoles] = useState(false);
	const [memberRoles, setMemberRoles] = useState(false);
	const [bookingList, setBookingList] = useState([] as BookingContent[]);
	const [page, setPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState(0);
	const [updateStatus, setUpdateStatus] = useState(false);
	const [length, setLength] = useState(false);
	const [bookingListCount, setBookingListCount] = useState([] as BookingContent[]);
	const [hasApplied, setHasApplied] = useState(false);

	// 권한 검사
	useEffect(() => {
		// console.log('서치파람', space);
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setGeneralRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				if (member.townDTO && member.townDTO.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setManagerRoles(true);
				}
			}
		}
	}, []);

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	// 신청자 관리 탭
	const handleManagementTab = (tab: string) => {
		// console.log('신청자 관리 탭 번호 :: ', tab);
		setManagementTab(tab);
	};

	// 공유하기
	const handleShare = () => {
		// console.log('공유하기 클릭');
		showDialog(ShareDialog, {
			id: space ? '' : `?space=${sfUid}&town=${townId}`,
		});
	};
	// 목록으로
	const handleListPage = () => {
		navigate('/commu_02/eduProgram_03');
	};

	// 교육프로그램 상세 조회
	useEffect(() => {
		EduProgramAPI.getEduProgramDetail({
			sfUid: Number(sfUid),
		}).then((res) => {
			setProgramDetail(res);
			if (member && res.likeDTO) {
				setLikeCheck(true); // 좋아요
			}
			if (member && res.bookingList) {
				// 예약 유무 체크
				const bookingList = res.bookingList;
				const lastBooking = bookingList[bookingList.length - 1]; // 가장 최신 예약

				// 가장 최신 예약 상태가 '취소'가 아닌 경우 - 대기 / 확정 / 완료 -> 재신청 불가 
				if (lastBooking && lastBooking.rsrvSt !== '취소') {
					setHasApplied(true);
				} else {
				// 예약 취소 후 재신청 가능 
					setHasApplied(false);
				}
			}
			setState(res.status);
		});
	}, [member, likeCheck, statusCheck]);

	// console.log('프로그램 상세조회', programDetail);

	// 예약 목록 조회
	useEffect(() => {
		const listParams: {
			sfUid: number;
			page: number;
			size: number;
			rsrvSt: string;
		} = {
			sfUid: Number(sfUid),
			page: Number(page) - 1,
			size: 5,
			rsrvSt: String(managementTab),
		};

		// 예약 내역 개인정보 노출 이슈로 추가 
		if(generalRoles || managerRoles){
			EduProgramAPI.getBookingList(listParams).then((res) => {
				setBookingList(res.content);
				setTotalPage(res.totalPages);
				if (res.content && res.content.length == 0) {
					setLength(true);
				} else {
					setLength(false);
				}
			});
		}
	}, [generalRoles, managerRoles, page, updateStatus, length, managementTab]);

	// 예약 목록 조회 Count
	useEffect(() => {
		const listParams: {
			sfUid: number;
			page: number;
			size: number;
			// rsrvSt: string;
		} = {
			sfUid: Number(sfUid),
			page: Number(page) - 1,
			size: 1000,
			// rsrvSt: String(managementTab),
		};

		// 예약 내역 개인정보 노출 이슈로 추가 
		if(generalRoles || managerRoles){
			EduProgramAPI.getBookingList(listParams).then((res) => {
				//console.log(res);
				setBookingListCount(res.content);
			});
		}
	}, [generalRoles, managerRoles, updateStatus]);
	// console.log('예약 목록 조회', bookingList);

	const handleUpdateStatus = () => {
		setUpdateStatus(!updateStatus);
	};

	const handleModify = () => {
		const title = '';
		const message = '수정하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					// console.log('수정확인 !!');
					navigate('/commu_02/eduProgram_04-modify');
				}
			},
		});
	};
	// 삭제
	const handleDelete = (sfUid: number) => {
		const title = '';
		const message = '삭제하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			async handleDialogResult(result) {
				if (result === '확인') {
					try {
					// console.log('삭제확인 !!');
						const res = await EduProgramAPI.deleteProgram({sfUid: sfUid,});
						if (res) {
							const title = '';
							const message = '삭제되었습니다.';
							showDialog(OneButtonAlertDialog, {
								title,
								message,
								handleDialogResult(result) {
									if (result === 'ok') {
										navigate('/commu_02/eduProgram_03');
									}
								},
							});
						}
					} catch (error: any) {
						const title = '삭제 실패';
						const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';
						showDialog(OneButtonAlertDialog, {
								title,
								message
							})					
					}
				}
			},
		});
	};
	const handleInsertCheck = () => {
		setStatusCheck(!statusCheck);
	};
	// 비공개, 공개 처리
	const handlePrivate = (sfUid: number, visible: string) => {
		const title = '';
		const message =
			visible === 'Y'
				? '해당 글을 비공개하시겠습니까?'
				: '해당 글을 공개하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					EduProgramAPI.privateProgram({
						sfUid: sfUid,
						visible: visible === 'Y' ? 'N' : 'Y',
					}).then((res) => {
						handleInsertCheck();
						const title = '비공개/공개 성공';
						const message = '성공하였습니다.'
						showDialog(OneButtonAlertDialog, {
							title,
							message
						})
					}).catch((error)=>{
						const title = '비공개/공개 실패';
						const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';						
						showDialog(OneButtonAlertDialog, {
							title,
							message
						})
					});
				}
			},
		});
	};

	// 좋아요
	const handleLike = async (sfUid: string | null) => {
		if (!member) {
			// alert('가입한 주민만 좋아요를 할 수 있습니다');
			showDialog(LikeRoleDialog);
			return;
		}
		try {
			const res = await EduProgramAPI.insertLike({
				// ...data,
				param: Number(sfUid),
			});
			window.location.reload();
			// console.log('좋아요 성공', res);
		} catch (error: any) {
			const title = '좋아요 실패';
    		const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message
			})
		}
	};

	return (
		<main className="Detail_ProgramPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={4} />
					<div className="village_commu_detail">
						<div className="program_impor_wrap">
							<div className="impor_txt">
								<div className="comm_detail_title mb25">
									<div className="comm_btn_set">
										{(generalRoles || managerRoles) && (
											<>
												<button
													type="button"
													className={`${programDetail?.visible === 'Y' ? 'non' : 'pub'
														}`}
													onClick={() =>
														handlePrivate(
															Number(programDetail?.sfUid),
															programDetail?.visible === 'Y' ? 'Y' : 'N'
														)
													}
												>
													{`${programDetail?.visible === 'Y' ? '비공개' : '공개'
														}`}
												</button>
												{/* <button type="button" className="pub">공개</button> */}
											</>
										)}
										{/* <button type="button" className="non">비공개</button>
										<button type="button" className="pub">공개</button> */}
										{(generalRoles || managerRoles) && (
											<>
												<button type="button" onClick={handleModify}>
													수정
												</button>
												<button
													type="button"
													onClick={() =>
														handleDelete(Number(programDetail?.sfUid))
													}
												>
													삭제
												</button>
											</>
										)}
										{/* <button type="button" onClick={handleModify}>수정</button>
										<button type="button" onClick={handleDelete}>삭제</button> */}
									</div>
								</div>
								<div className="comm_detail_cont pa30">
									<p>{programDetail?.facilityName}</p>
									{state === '신청중' &&
										<p>
											{/* {formattedDate} */}
											{`${moment(programDetail?.requestEndDateTime)
												.locale('ko')
												.format('yyyy.MM.DD(ddd)')}`}{' '}
											23:59까지 <span>{programDetail?.bookingCnt}</span>/
											{programDetail?.memberCount}명 신청가능
										</p>
									}
								</div>
								{/* 대기 */}
								{state === '대기' && (
									<WaitComponent programDetail={programDetail} />
								)}

								{/* 신청 시작 */}
								{state === '신청중' &&
									(!hasApplied ? (
										// 신청시작
										<ApplyComponent
											sfUid={sfUid}
											member={member}
											programDetail={programDetail}
										/>
									) : (
										// 완료
										<CompletionComponent
											member={member}
											sfUid={sfUid}
											bookingList={bookingList}
										/>
									))}

								{/* 마감 시작 */}
								{state === '신청종료' && <EndComponent />}
								{/* 마감 종료 */}
								<div className="comm_detail_btn">
									<p>
										<input
											type="checkbox"
											id="act01"
											name="act"
											checked={likeCheck}
											onChange={() => handleLike(String(programDetail?.sfUid))}
										/>
										<label htmlFor="act01">
											<span />
											{programDetail?.likeCnt}
										</label>
									</p>
									<a className="btn_share" onClick={handleShare}>
										공유
									</a>
									<a className="btn_list" onClick={handleListPage}>
										목록
									</a>
								</div>
							</div>
							{programDetail?.filePath ? (
								<div className="impor_img">
									<img
										src={`/api/attach/getFile?savedPath=${programDetail.filePath}`}
									/>
								</div>
							) : (
								<div className="impor_img">
									<img src={defaultImg} />
								</div>
							)}
						</div>
						{/* 프로그램 설명 */}
						<ProgramDescriptionComponent programDetail={programDetail} />
						{/* 프로그램 설명 종료 */}

						{/* 프로그램 교육장소 */}
						<ProgramPlaceComponent programDetail={programDetail} />
						{/* 프로그램 교육장소 종료 */}

						{/* 신청자 관리 */}
						{(generalRoles || managerRoles) && (
							<ApplyManagementComponent
								handleManagementTab={handleManagementTab}
								sfUid={sfUid}
								bookingList={bookingList}
								handlePageChange={handlePageChange}
								page={Number(page)}
								totalPage={Number(totalPage)}
								length={length}
								handleUpdateStatus={handleUpdateStatus}
								bookingListCount={bookingListCount}
							/>
						)}
						{/* 신청자 관리 종료 */}
					</div>
					{/* 해당 페이지의 종료입니다 */}
				</div>
			</article>
		</main>
	);
};
export interface ApplyInput {
	rsrvNo?: number;
	rsrvNm?: string;
	telNo?: string;
	sfUid?: number;
	sbUid?: number;
	rsrvSt?: string;
	refuseReason?: string;
}


// 신청 컴포넌트
const ApplyComponent = ({
	sfUid,
	member,
	programDetail,
}: {
	sfUid: String | null;
	member: MemberInfo | undefined;
	programDetail: ProgramContent | undefined;
}) => {
	const { register, getValues, watch, handleSubmit } = useForm<ApplyInput>();
	const [buttonOn, setButtonOn] = useState(false);
	const rsrvNm = watch('rsrvNm');
	const telNo = watch('telNo');
	const [rsrvNmError, setRsrvNmError] = useState(false);
	const [telNoError, setTelNoError] = useState(false);
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	useEffect(() => {
		if (rsrvNm !== undefined && telNo !== undefined) {
			if (rsrvNm.length > 0 && telNo.length > 0) {
				setButtonOn(true);
			} else {
				setButtonOn(false);
			}
		} else {
			setButtonOn(false);
		}
	}, [rsrvNm, telNo]);

	// 신청하기 버튼
	const onSubmit: SubmitHandler<ApplyInput> = async (data) => {
		if (member?.townDTO.townId !== programDetail?.tcUid) {
			const message = '해당 마을 주민만 신청할 수 있습니다.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		}

		// 신청자명
		if (rsrvNm === '') {
			setRsrvNmError(true);
			return;
		} else {
			setRsrvNmError(false);
		}
		// 연락처
		if (telNo === '') {
			setTelNoError(true);
			return;
		} else {
			setTelNoError(false);
		}

		if (rsrvNm === '' || telNo === '') {
			return;
		}

		EduProgramAPI.bookingProgram({
			...data,
			rsrvNo: Number(1),
			rsrvNm: String(rsrvNm),
			telNo: String(telNo),
			sfUid: Number(sfUid),
		})
			.then((res) => {
				// // console.log("교욱프로그램 예약 신청", res);
				const message = '신청 완료 되었습니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				// 3초 후에 navigate 함수 호출
				setTimeout(() => {
					navigate('/commu_02/eduProgram_03-detail');
				}, 1500);
				// navigate('/commu_02/eduProgram_03')
			})
			.catch((error) => {
				// // console.log("교육 프로그램 예약 싵청 실패", error);
				const message = '신청 실패 하였습니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
			});
	};

	return (
		<div className="comm_program">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group">
					<div className="floating-box">
						<input
							id="name"
							className="form-control textbox"
							type="text"
							{...register('rsrvNm')}
							onChange={(e) => setRsrvNmError(false)}
						/>
						<label htmlFor="name" className="floating">
							실명
						</label>
						{rsrvNmError && (
							<p className="error-message" style={{ color: 'red' }}>
								실명 입력은 필수입니다.
							</p>
						)}
					</div>
				</div>
				<FloatingBox selector="#name" />
				<div className="form-group">
					<div className="floating-box">
						<input
							id="tel"
							className="form-control textbox"
							type="number"
							{...register('telNo', {
								onChange: (e: any) => {
									const inputText = e.target.value;
									e.target.value = e.target.value.replace(/[^\d]/g, '');
									if (inputText.length > 11) {
										e.target.value = inputText.slice(0, 11); // 11자 이상의 입력은 자르기
									}
								},
							})}
						/>
						<label htmlFor="tel" className="floating">
							연락처 ( - 없이 11자리를 입력해주세요.)
						</label>
						{telNoError && (
							<p className="error-message" style={{ color: 'red' }}>
								연락처 입력은 필수입니다.
							</p>
						)}

					</div>
				</div>
				<FloatingBox selector="#tel" />
				{/* 작성되면 on 클래스 붙기 */}
				<button type="submit" className={`${buttonOn ? 'on' : ''}`}>
					신청하기
				</button>
			</form>
			<p className="txt_info">
				※ 신청 시 [대기] 상태가 되며,
				<br />
				교육 운영자가 승인한 후 [확정] 상태가 되어 교육 참여가 가능합니다.
			</p>
		</div>
	);
};
// 대기 컴포넌트
const WaitComponent = ({
	programDetail,
}: {
	programDetail: ProgramContent | undefined;
}) => {
	return (
		<div className="comm_program pa30">
			<div className="box_program_state">
				<img src={program02} alt="프로그램 신청 마감 이미지" />
				<p>프로그램 신청이 대기중입니다.</p>
				<p>
					신청일 :{' '}
					{`${moment(programDetail?.requestStartDateTime)
						.locale('ko')
						.format('yyyy.MM.DD(ddd)')}`}
				</p>
			</div>
		</div>
	);
};

// 완료 컴포넌트
const CompletionComponent = ({
	member,
	sfUid,
	bookingList,
}: {
	member: MemberInfo | undefined;
	sfUid: string | null;
	bookingList: BookingContent[];
}) => {
	const navigate = useNavigate();

	// 마이페이지 이동
	const handleMyPage = () => {
		navigate('/myPage_02/myProgram_04');
	};

	return (
		<>
			{/* {bookingList && bookingList.map((list) => (
			list.crtUid === member?.id ? ( */}
			<div className="comm_program pa30">
				<div className="box_program_state mb25">
					<img src={program01} alt="프로그램 신청 완료 이미지" />
					<p>프로그램 신청이 완료되었습니다.</p>
				</div>
				<button type="submit" className="my" onClick={handleMyPage}>
					마이페이지
				</button>
				<p className="txt_info mt10">
					※ 교육 운영자가 승인한 후 [확정] 상태가 되어 교육 참여가 가능합니다.
					<br />
					신청내역은 마이페이지 에서 확인 하실 수 있습니다.
				</p>
			</div>
			{/* ): null
		))} */}
		</>
	);
};

// 마감 컴포넌트
const EndComponent = () => {
	return (
		<div className="comm_program pa30">
			<div className="box_program_state">
				<img src={program02} alt="프로그램 신청 마감 이미지" />
				<p>프로그램 신청이 마감되었습니다.</p>
			</div>
		</div>
	);
};
// 프로그램 설명 컴포넌트
const ProgramDescriptionComponent = ({
	programDetail,
}: {
	programDetail: ProgramContent | undefined;
}) => {
	return (
		<div className="program_info_wrap">
			<h3 className="mb25">프로그램 설명</h3>
			<div className="info_table">
				<ul>
					<li>
						<h4>교육기간</h4>

						<p className="pa20">
							{`${moment(
								programDetail && programDetail?.educationStartDate
							).format('yyyy.MM.DD')}`}
							{' ~ '}
							{`${moment(
								programDetail && programDetail?.educationEndDate
							).format('yyyy.MM.DD')}`}
						</p>
					</li>
					<li>
						<h4>교육시간</h4>
						<p className="pa20">
							{programDetail &&
								moment(programDetail.educationStartTime, 'HH:mm').format(
									'HH:mm'
								)}
							{' ~ '}
							{programDetail &&
								moment(programDetail.educationEndTime, 'HH:mm').format('HH:mm')}
						</p>
					</li>
					<li>
						<h4>교육대상</h4>
						<p className="pa20">
							{programDetail &&
								programDetail.tcategories.map((list, index, array) =>
									index < array.length - 1
										? `${list.categoryTypeCode}, `
										: list.categoryTypeCode
								)}
						</p>
					</li>
					<li>
						<h4>교육분야</h4>
						<p className="pa20">
							{programDetail &&
								programDetail.fcategories.map((list, index, array) =>
									index < array.length - 1
										? `${list.categoryTypeCode}, `
										: list.categoryTypeCode
								)}
						</p>
					</li>
					<li>
						<h4>설명</h4>
						<p className="pa20">
							{programDetail &&
								programDetail?.facilityDes.split('\n').map((item, idx) => (
									<React.Fragment key={idx}>
										{item}
										<br />
									</React.Fragment>
								))}
						</p>
					</li>
					<li>
						<h4>운영형태</h4>
						<p className="pa20">
							{programDetail?.inOutCheck && programDetail?.inOutCheck}
						</p>
					</li>
					<li>
						<h4>참가비용</h4>
						<p className="pa20">
							{programDetail?.costRadio && programDetail?.costRadio}
						</p>
					</li>
					<li>
						<h4>담당자</h4>
						<p className="pa20">
							{programDetail?.manager && programDetail?.manager}
						</p>
					</li>
					<li>
						<h4>교육장소</h4>
						<p className="pa20">
							{programDetail?.educationPlace && programDetail?.educationPlace}
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
};
// 프로그램 교육장소 컴포넌트
const ProgramPlaceComponent = ({
	programDetail,
}: {
	programDetail: ProgramContent | undefined;
}) => {
	return (
		<div className="program_info_wrap">
			<h3 className="mb25">프로그램 교육장소</h3>
			<div className="info_place">
				<Map_ProgramDetailPage programDetail={programDetail} />
				{/* <div className="place_map">
					<img src={test05} alt="임시 이미지입니다 삭제해주세요" />
				</div> */}
				{/* <p className="mt10">
					{programDetail?.address}
				</p> */}
			</div>
		</div>
	);
};
// 신청자 관리 컴포넌트
const ApplyManagementComponent = ({
	handleManagementTab,
	sfUid,
	bookingList,
	handlePageChange,
	page,
	totalPage,
	length,
	handleUpdateStatus,
	bookingListCount
}: {
	handleManagementTab: (tab: string) => void;
	sfUid: string | null;
	bookingList: BookingContent[];
	handlePageChange: (page: number) => void;
	page: number;
	totalPage: number;
	length: boolean;
	handleUpdateStatus: () => void;
	bookingListCount: BookingContent[];
}) => {
	const [tabCate, setTabCate] = useState<string>();
	const { showDialog } = useDialog();

	const handleTabCate = (tab: string) => {
		handleManagementTab(tab);
		setTabCate(tab);
	};

	// 예약 승인
	const handleManagementButton = (
		action: string,
		sbUid: number,
		rsrvNo: number,
		rsrvNm: string,
		telNo: string,
		rsrvSt: string
	) => {
		const title = '';
		const message = action === '예약승인' ? '승인 하시겠습니까?' : '';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result) {
					if (action === '예약승인') {
						// console.log('승인버튼 확인 ~', action);
						EduProgramAPI.modifyBookingProgram({
							sbUid: Number(sbUid),
							rsrvNo: Number(rsrvNo),
							rsrvNm: String(rsrvNm),
							telNo: String(telNo),
							rsrvSt: '확정',
						})
							.then((res) => {
								// console.log('예약승인 성공');
								const title = '예약 승인';
								const message = '완료되었습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message,
									handleDialogResult(result) {
										if (result === 'ok') {
											handleUpdateStatus();
										}
									},
								});
								// window.location.reload();
							})
							.catch((error) => {
								// console.log('예약승인 실패', error);	
								const title = '예약 승인 실패';
								const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message
								})
							});
					}
				}
			},
		});
	};

	// 예약 거절
	const handleBookingCancel = (
		action: string,
		sbUid: number,
		rsrvNo: number,
		rsrvNm: string,
		telNo: string,
		rsrvSt: string
	) => {
		const title = '';
		const message =
			action === '예약거절'
				? '거절 하시겠습니까?'
				: action === '완료'
					? '완료 하시겠습니까?'
					: '';

		if (action === '예약거절' || action === '완료') {
			showDialog(SelectDialog, {
				title,
				message,
				action,
				handleDialogResult(result?, etcReason?) {
					// console.log('result', result);
					// console.log('etcReason', etcReason);
					if (result === '대기') {
						// console.log("대기 대기 대기대기")
						EduProgramAPI.modifyBookingProgram({
							sbUid: Number(sbUid),
							rsrvNo: Number(rsrvNo),
							rsrvNm: String(rsrvNm),
							telNo: String(telNo),
							rsrvSt: '대기',
							// refuseReason: String(etcReason ? etcReason : result),
						})
							.then((res) => {
								// console.log(`${action} 성공`);
								const title = `${action}`;
								const message = '완료되었습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message,
									handleDialogResult(result) {
										if (result === 'ok') {
											handleUpdateStatus();
										}
									},
								});
								return;
							})
							.catch((error) => {
								const title = `${action}`;
								const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message
								})
							});
					} else if (result !== '대기') {
						let rsrvStValue = '';
						if (action === '예약거절') {
							rsrvStValue = '취소';
						} else if (action === '완료') {
							if (result === '연락두절(노쇼)') {
								rsrvStValue = '취소';
							} else {
								rsrvStValue = '완료';
							}
						}

						EduProgramAPI.modifyBookingProgram({
							sbUid: Number(sbUid),
							rsrvNo: Number(rsrvNo),
							rsrvNm: String(rsrvNm),
							telNo: String(telNo),
							rsrvSt: rsrvStValue,
							refuseReason: String(etcReason ? etcReason : result),
						})
							.then((res) => {
								// console.log(`${action} 성공`);
								const title = `${action}`;
								const message = '완료되었습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message,
									handleDialogResult(result) {
										if (result === 'ok') {
											handleUpdateStatus();
										}
									},
								});
							})
							.catch((error) => {
								const title = `${action}`;
								const message = error.response.data.message || '알 수 없는 오류가 발생했습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message
								})
							});
					}
				},
			});
		}
	};

	// 사유 버튼
	const resultBtn = (refuseReason: string) => {
		const title = '취소 사유';
		const message = refuseReason;
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === 'ok') {
					handleUpdateStatus();
				}
			},
		});
	};

	return (
		<div className="program_info_wrap mt40">
			<h3 className="mb25">신청자 관리</h3>
			<div className="info_tool mb25">
				<ul>
					<li
						onClick={() => handleTabCate('전체')}
						style={{ cursor: 'pointer' }}
					>
						<p className={`${tabCate == '전체' && 'on'}`}>
							전체({bookingListCount && bookingListCount.length})
						</p>
					</li>
					<li
						onClick={() => handleTabCate('대기')}
						style={{ cursor: 'pointer' }}
					>
						<p className={`${tabCate == '대기' && 'on'}`}>
							대기({bookingListCount.filter((list) => list.rsrvSt === '대기').length}
							)
						</p>
					</li>
					<li
						onClick={() => handleTabCate('확정')}
						style={{ cursor: 'pointer' }}
					>
						<p className={`${tabCate == '확정' && 'on'}`}>
							확정({bookingListCount.filter((list) => list.rsrvSt === '확정').length}
							)
						</p>
					</li>
					<li
						onClick={() => handleTabCate('완료')}
						style={{ cursor: 'pointer' }}
					>
						<p className={`${tabCate == '완료' && 'on'}`}>
							완료({bookingListCount.filter((list) => list.rsrvSt === '완료').length}
							)
						</p>
					</li>
					<li
						onClick={() => handleTabCate('취소')}
						style={{ cursor: 'pointer' }}
					>
						<p className={`${tabCate == '취소' && 'on'}`}>
							취소({bookingListCount.filter((list) => list.rsrvSt === '취소').length}
							)
						</p>
					</li>
				</ul>
			</div>
			{length ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">해당 프로그램에 신청자가 없습니다.</p>
				</div>
			) : (
				<>
					<div className="info_manage">
						<ul className="thead pa20">
							<li>상태</li>
							<li>신청시간</li>
							<li>실명</li>
							<li>연락처</li>
							<li>승인여부</li>
						</ul>
						{/* 1) 대기
						프로그램 신청 승인 대기중
						- 승인, 거절 버튼 노출
						2) 확정
						운영자가 승인한 상태
						- 완료 버튼 노출
						3) 완료
						운영자가 완료>교육완료를 선택한 상태
						4) 취소
						운영자가 완료 > 노쇼를 선택한 상태
						본인이 취소한 상태 */}

						<ul className="tbody">
							{/* prev 대기, fix 확정, end 완료, cancel 취소 */}
							{bookingList &&
								bookingList.map((list, idx) => (
									<li
										key={idx}
										className={`pa20 ${list.rsrvSt === '대기'
											? 'prev'
											: list.rsrvSt === '확정'
												? 'fix'
												: list.rsrvSt === '완료'
													? 'fix' //end
													: list.rsrvSt === '취소'
														? 'cancel'
														: ''
											}`}
									>
										<p>{list.rsrvSt}</p>
										{/* <p>{`${moment(list.crtDt).format('YYYY.MM.DD HH:mm')}`} </p> */}
										<p>
											{list?.crtDt[0]}.{list?.crtDt[1]}.{list?.crtDt[2]}{' '}
											{list?.crtDt[3]}:{Number(list?.crtDt[4]) < 10 ? "0" + Number(list?.crtDt[4]) : Number(list?.crtDt[4])}
										</p>
										<p>{list.rsrvNm}</p>
										<p>
											{list.telNo.slice(0, 3)}-{list.telNo.slice(3, 7)}-
											{list.telNo.slice(7)}
										</p>
										<p className="mt5">
											<button
												type="button"
												className="btn_blue"
												onClick={() =>
													handleManagementButton(
														'예약승인',
														Number(list.sbUid),
														Number(list.rsrvNo),
														String(list.rsrvNm),
														String(list.telNo),
														String(list.rsrvSt)
													)
												}
											>
												승인
											</button>
											<button
												type="button"
												className="btn_red"
												onClick={() =>
													handleBookingCancel(
														'예약거절',
														Number(list.sbUid),
														Number(list.rsrvNo),
														String(list.rsrvNm),
														String(list.telNo),
														String(list.rsrvSt)
													)
												}
											>
												거절
											</button>
											<button
												type="button"
												className="btn_black"
												onClick={() =>
													handleBookingCancel(
														'완료',
														Number(list.sbUid),
														Number(list.rsrvNo),
														String(list.rsrvNm),
														String(list.telNo),
														String(list.rsrvSt)
													)
												}
											>
												완료
											</button>
											<button
												type="button"
												className="btn_gray"
												style={{
													display: list.rsrvSt === '취소' ? 'block' : 'none',
												}}
												onClick={() => resultBtn(String(list.refuseReason))}
											>
												사유
											</button>
											<button
												type="button"
												className="btn_gray"
												style={{
													display: list.rsrvSt === '취소' ? 'block' : 'none',
												}}
												onClick={() =>
													handleBookingCancel(
														'완료',
														Number(list.sbUid),
														Number(list.rsrvNo),
														String(list.rsrvNm),
														String(list.telNo),
														String(list.rsrvSt)
													)
												}
											>
												완료
											</button>
										</p>
									</li>
								))}
						</ul>
					</div>
					<Pagination
						page={Number(page)}
						onPageChange={handlePageChange}
						// totalPageCount={Math.ceil(count / 8)}
						totalPageCount={Number(totalPage) / 5}
					/>
				</>
			)}
		</div>
	);
};

// 제이쿼리
const FloatingBox: React.FC<{ selector: string }> = ({ selector }) => {
	const classActive = 'active';
	const classCorrect = 'correct';

	const handleFocus = (e: Event) => {
		const parentElement = (e.currentTarget as HTMLElement).parentElement;
		parentElement?.classList.add(classActive);
	};

	const handleBlur = (e: Event) => {
		const target = e.currentTarget as HTMLInputElement | HTMLTextAreaElement;
		const parentElement = target.parentElement;

		if (target.value === '') {
			parentElement?.classList.remove(classActive);
			parentElement?.classList.remove(classCorrect);
		} else {
			parentElement?.classList.add(classCorrect);
		}
	};

	useEffect(() => {
		const inputElement = document.querySelector(selector) as
			| HTMLInputElement
			| HTMLTextAreaElement
			| null;

		if (inputElement) {
			inputElement.addEventListener('focus', handleFocus);
			inputElement.addEventListener('blur', handleBlur);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('focus', handleFocus);
				inputElement.removeEventListener('blur', handleBlur);
			}
		};
	}, [selector]);

	return null;
};

export default Detail_ProgramPage;
