import axios from "axios"
import { serialize } from "object-to-formdata";
import { NoticeList } from "../models/Notice";

interface GetAllParams {
    nntUid?: number;
    title?: string;
    status?: string;
    content?: string;
    page?:number;
    pageSize?:number;
    savedPath?: string;
}

const insertNotice = (params: GetAllParams, uploadFiles?: File[] | FileList, docFiles?: File[] | FileList) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        if(value !== undefined){
            formData.append(key, value);
        }
	
	});
    // 파일 추가
    if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}

    if(docFiles) {
        const files = Array.from(docFiles);
        for (let i = 0; i < files.length; i++) {
            formData.append('docFiles', files[i], files[i].name);
        }
    }

    return axios
    .post('/api/notice/save', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then((res) => res as any)
}

const updateNotice = (params: GetAllParams, uploadFiles?: File[] | FileList, docFiles?: File[] | FileList, deleteFileIds?: number[]) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        if(value !== undefined){
            formData.append(key, value);
        }
	});

    if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}

    if(docFiles) {
        const files = Array.from(docFiles);
        for (let i = 0; i < files.length; i++) {
            formData.append('docFiles', files[i], files[i].name);
        }
    }

    if (deleteFileIds?.length !== 0) {
		if (deleteFileIds) {
			const fileDel = Array.from(deleteFileIds);
			for (let i = 0; i < fileDel.length; i++) {
				if (fileDel[i]) {
					formData.append(`deletefileList[${i}].npfUid`, String(fileDel[i]));
				}
			}
		}
	}

    return axios
    .post('/api/notice/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
    .then((res) => res as any);
}

const deleteNotice = (params: GetAllParams) => {
    return axios
    .post('/api/notice/delete',serialize(params))
    .then((res) => res as any)
}

const getNoticeList = (params: GetAllParams) => {
    return axios
    .get('/api/notice', {params})
    .then((res) => res.data as NoticeList[])
}

const getNoticeDetail = (params: GetAllParams) => {
return axios
.get('/api/notice/get',{params})
.then((res) => res.data as NoticeList)
}

// 파일 조회
const getFiles = (params: GetAllParams) => {
    return axios
    .get(`/api/attach/getFile`, {
		responseType: 'blob',
		params: params,
	})
}

const NoticeAPI = {
    insertNotice,
    updateNotice,
    deleteNotice,
    getNoticeList,
    getNoticeDetail,
    getFiles
}
export default NoticeAPI;