import React, { useState } from 'react';
import Pagination from '../../components/common/Pagination';
import { DialogProps } from '../DialogProvider';
import './AdminPage_CalculateStoreDialog.scss'
interface AdminPage_CalculateStoreDialog extends DialogProps {
    type: '';

}
const AdminPage_CalculateStoreDialog = ({ ...props }: AdminPage_CalculateStoreDialog) => {
    const [totalPage, setTotalPage] = useState(5);
    const [page, setPage] = useState<number>(1);

    const handlePageChange = (page: number) => {
        setPage(page);
    };
    const handleClose = () => {
        props.onClose?.();
    }
    return (
        <div className='AdminPage_CalculateStoreDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title">
                        <h3>커피전문점</h3>
                        <a className="popup_close" onClick={handleClose}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC" alt="닫기" />
                        </a>
                    </div>
                    <div className="comm_popup_content mt25">
                        {/* 검색 시작 */}
                        <div className="comm_search mt25">
                            <ul>
                                <li>
                                    <select>
                                        <option>상태</option>
                                        <option>지급대기</option>
                                        <option>지급완료</option>
                                    </select>
                                    <select>
                                        <option>선택</option>
                                        <option>사용자</option>
                                        <option>상품권명</option>
                                    </select>
                                </li>
                                <li>
                                    <div className="comm_search_plus">
                                        <input type="text" placeholder="닉네임을 입력해주세요." />
                                    </div>
                                    <button type="button">검색</button>
                                </li>
                            </ul>
                        </div>
                        {/* 검색 종료 */}
                        {/*  시작 */}
                        <div className="comm_tool mb25">
                            <p>
                                선택된 상품권 현금 총액 <strong>15,000</strong>
                            </p>
                            <button type="button" className="comm_write_button">목록 다운로드</button>
                        </div>
                        {/*  종료 */}
                        {/*테이블 시작*/}
                        <div className="gift_table mt25">
                            <ul>
                                {/* end가 붙으면 지급완료끝 */}
                                <li className="pa20">
                                    <p>2023.10.01</p>
                                    <p>도토리</p>
                                    <p>
                                        거점마을 상점 5,000원 상품권 교환
                                    </p>
                                    <p>5,000E</p>
                                    <p>5,000원</p>
                                    <p>
                                        <span className="state01">지급대기</span>
                                        <span className="state02">지급완료</span>
                                    </p>
                                    <p className="endTime">2023.10.01</p>
                                    <p className="endBtn">
                                        <button type="button">지급완료하기</button>
                                    </p>
                                </li>
                                {/* end가 붙으면 지급완료끝 */}
                                <li className="pa20">
                                    <p>2023.10.01</p>
                                    <p>도토리</p>
                                    <p>
                                        거점마을 상점 5,000원 상품권 교환
                                    </p>
                                    <p>5,000E</p>
                                    <p>5,000원</p>
                                    <p>
                                        <span className="state01">지급대기</span>
                                        <span className="state02">지급완료</span>
                                    </p>
                                    <p className="endTime">2023.10.01</p>
                                    <p className="endBtn">
                                        <button type="button">지급완료하기</button>
                                    </p>
                                </li>
                                {/* end가 붙으면 지급완료끝 */}
                                <li className="pa20 end">
                                    <p>2023.10.01</p>
                                    <p>도토리</p>
                                    <p>
                                        거점마을 상점 5,000원 상품권 교환
                                    </p>
                                    <p>5,000E</p>
                                    <p>5,000원</p>
                                    <p>
                                        <span className="state01">지급대기</span>
                                        <span className="state02">지급완료</span>
                                    </p>
                                    <p className="endTime">2023.10.01</p>
                                    <p className="endBtn">
                                        <button type="button">지급완료하기</button>
                                    </p>
                                </li>
                                {/* end가 붙으면 지급완료끝 */}
                                <li className="pa20 end">
                                    <p>2023.10.01</p>
                                    <p>도토리</p>
                                    <p>
                                        거점마을 상점 5,000원 상품권 교환
                                    </p>
                                    <p>5,000E</p>
                                    <p>5,000원</p>
                                    <p>
                                        <span className="state01">지급대기</span>
                                        <span className="state02">지급완료</span>
                                    </p>
                                    <p className="endTime">2023.10.01</p>
                                    <p className="endBtn">
                                        <button type="button">지급완료하기</button>
                                    </p>
                                </li>
                                {/* end가 붙으면 지급완료끝 */}
                                <li className="pa20 end">
                                    <p>2023.10.01</p>
                                    <p>도토리</p>
                                    <p>
                                        거점마을 상점 5,000원 상품권 교환
                                    </p>
                                    <p>5,000E</p>
                                    <p>5,000원</p>
                                    <p>
                                        <span className="state01">지급대기</span>
                                        <span className="state02">지급완료</span>
                                    </p>
                                    <p className="endTime">2023.10.01</p>
                                    <p className="endBtn">
                                        <button type="button">지급완료하기</button>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        {/*테이블 종료*/}
                        <Pagination
                            page={Number(page)}
                            totalPageCount={Number(totalPage)}
                            // totalPageCount={Math.ceil(count / 8)}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AdminPage_CalculateStoreDialog;