import React, { ChangeEvent, useEffect, useState } from 'react';
import '../missionYard/MissionYardPage.scss';
import SubLocation from '../../components/common/SubLocation';
import ic_plus from '../../assets/ic_plus.png';
import ic_same from '../../assets/ic_same.png';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import { useNavigate } from 'react-router-dom';
import TownList from '../../models/TownList';
import TownAPI from '../../apis/TownAPI';
import useMember from '../../hooks/useMember';
import character01 from '../../assets/character01.png';
import character02 from '../../assets/character02.png';
import character03 from '../../assets/character03.png';
import character04 from '../../assets/character04.png';
import character05 from '../../assets/character05.png';
import character06 from '../../assets/character06.png';
import MissionAPI from '../../apis/MissionAPI';
import MissionInfo, {
	GetEnergy,
	MyPoint,
	Rankings,
} from '../../models/MissionInfo';
import noList from '../../assets/noList.png';
import moment from 'moment';

const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	{ href: '/reward_02/myEPoint', text: '내 E 포인트' },
];

// 참여마당 > 에너지전환 현황
const MissionYardPage = () => {
	const { member } = useMember();
	const navigate = useNavigate();
	const [getEnergy, setGetEnergy] = useState<GetEnergy>();
	const [myEnergy, setMyEnergy] = useState<GetEnergy>();
	const [villRanking, setVillRanking] = useState<GetEnergy>();
	const [ranking, setRanking] = useState<GetEnergy>();
	const [townId, setTownId] = useState<number>(
		Number(member?.townDTO?.townId ? Number(member?.townDTO?.townId) : 1)
	);
	const nowDate = new Date();
	const [period, setPeriod] = useState<string>('주간');
	const [period2, setPeriod2] = useState<string>('주간');
	const [prgrsSt, setPrgrsSt] = useState<string>('진행');
	const [villMissionList, setVillMissionList] = useState([] as MissionInfo[]);
	const [mssionList, setMissionList] = useState([] as MissionInfo[]);
	const [mmiUid, setMmiUid] = useState<number>(0);
	const [villMmiUid, setVillMmiUid] = useState<number>(0);
	const [myPoint, setMyPoint] = useState<MyPoint>();
	const handleMypage = () => {
		navigate('/myPage_02');
	};
	const [townList, setTownList] = useState<TownList>();
	// const townId = sessionStorage.getItem('town');
	const getTownId = (e: ChangeEvent<HTMLSelectElement>) => {
		// if (changeTownList != undefined) {
		// changeTownList(Number(e.target.value));
		// sessionStorage.setItem('town', String(e.target.value));
		setTownId(Number(e.target.value));
		// navigate('/commu_02/mainVillage_03');
		// }
	};
	// // console.log('townId', townId);

	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			if (res) {
				// console.log('마을 목록', res);
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((item) => item.districtName)
						.join(', ');
				});
				setTownList(res);
			}
		});

		MissionAPI.getEnergy({
			tcUid: Number(townId),
		}).then((res) => {
			// console.log('getEnergy ::::', res?.data);
			setGetEnergy(res?.data);
		});
		MissionAPI.getRanking({
			mssnDe: '마을미션',
			mmiUid: villMmiUid ? villMmiUid : undefined,
			period: period,
			past: period === '주간' ? '저번주' : undefined,
		}).then((res) => {
			// console.log('마을미션 ::::::', res.data);
			setVillRanking(res.data);
		});
		MissionAPI.getRanking({
			mssnDe: '기본미션',
			mmiUid: mmiUid ? mmiUid : undefined,
			period: period2,
			past: period2 === '주간' ? '저번주' : undefined,
		}).then((res) => {
			// console.log('기본미션 ::::::', res.data);
			setRanking(res.data);
		});
		// 마을미션 셀렉트 목록조회
		MissionAPI.getMissionList({
			mssnDe: '마을미션',
			prgrsSt: prgrsSt,
		}).then((res) => {
			// console.log('getMissionList ::::', res);
			setVillMissionList(res.content);
		});
		MissionAPI.getMissionList({
			mssnDe: '기본미션',
			prgrsSt: prgrsSt,
		}).then((res) => {
			// console.log('getMissionList ::::', res);
			setMissionList(res.content);
		});
		if (member?.id) {
			// 내 에너지현황 조회
			MissionAPI.getMyEnergy().then((res) => {
				// console.log('내 에너지 현황 ::::', res);
				setMyEnergy(res.data);
			});
			// 내 에너지 포인트 조회
			MissionAPI.getMyPoint().then((res) => {
				// console.log('getMyPoint ::::::', res);
				setMyPoint(res.data);
			});
		}
	}, [townId, period, period2, mmiUid, villMmiUid]);
	// // console.log('ranking:::::::', ranking);

	const [myCharacter, setMyCharacter] = useState<string>();

	useEffect(() => {
		if (member?.character == 1) {
			setMyCharacter(character01);
		} else if (member?.character == 2) {
			setMyCharacter(character02);
		} else if (member?.character == 3) {
			setMyCharacter(character03);
		} else if (member?.character == 4) {
			setMyCharacter(character04);
		} else if (member?.character == 5) {
			setMyCharacter(character05);
		} else if (member?.character == 6) {
			setMyCharacter(character06);
		}
	}, []);
	const handleMmiUid = (mmiUid: number) => {
		// // console.log('villMmiUid:::::::', mmiUid);
		// console.log('mmiUid:::::::::', mmiUid);
		setMmiUid(mmiUid);
		// setVillMmiUid(mmiUid);
	};
	const handleVillMmiUid = (mmiUid: number) => {
		// // console.log('villMmiUid:::::::', mmiUid);
		// console.log('mmiUid:::::::::', mmiUid);
		setVillMmiUid(mmiUid);
		// setVillMmiUid(mmiUid);
	};
	return (
		<main className="MissionYardPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 현황"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>에너지전환 현황</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>에너지전환 활동 현황과 미션 순위를 확인하세요.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/*에너지전환활동시작*/}
					<div className="energy_box">
						<div className="energy_activit">
							<div className="select_box">
								<select onChange={getTownId} value={Number(townId)}>
									{townList?.content.map((list) => (
										<option
											key={list.townId}
											value={list.townId}
										// selected={Number(townId) == list.townId}
										>
											{list.townName}
											{/* 행정동 추가할 것 */}
										</option>
									))}
								</select>
							</div>
							<div className="energy_title">
								<h2>에너지전환 활동</h2>
								<p>
									<span>1TOE </span> 는 중형자동차가 서울과 부산을{' '}
									<span>16번 </span>
									왕복할 수 있을 정도의 에너지예요.
								</p>
							</div>
						</div>
						<div className="energy_activit1">
							<div className="energy_activit3">
								<div className="energy_activit2">
									<div className="circle">
										<div className="circle_txt blue">
											<h2>
												{getEnergy?.pwrerMwh ? getEnergy?.pwrerMwh : 0}{' '}
												<span>MWh</span>
											</h2>
											<p>
												{getEnergy?.pwrerToe ? getEnergy?.pwrerToe : 0}{' '}
												<span>
													10<sup>-3</sup> TOE
												</span>
											</p>
											<h1>전력</h1>
										</div>
									</div>
								</div>
								<div className="ic_plus">
									<img src={ic_plus} alt="플러스이미지" />
								</div>
								<div className="energy_activit2">
									<div className="circle">
										<div className="circle_txt green">
											<h2>
												{getEnergy?.gasMj ? getEnergy?.gasMj : 0}{' '}
												<span>MJ</span>
											</h2>
											<p>
												{getEnergy?.gasToe ? getEnergy?.gasToe : 0}{' '}
												<span>
													10<sup>-3</sup> TOE
												</span>
											</p>
											<h1>도시가스</h1>
										</div>
									</div>
								</div>
							</div>
							<div className="ic_plus1">
								<img src={ic_same} alt="등호표시이미지" />
							</div>
							<div className="energy_activit4">
								<div className="energy_activit2">
									<div className="circle">
										<div className="circle_txt red">
											<p>
												{getEnergy?.redcnToe ? getEnergy?.redcnToe : 0}{' '}
												<span>
													10<sup>-3</sup> TOE
												</span>
											</p>
											<h1>총 에너지 절감량</h1>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*에너지전환활동끝*/}
					{/*내 에너지전환활동현황 시작*/}
					{member && member?.id ? (
						<>
							{' '}
							<div className="energy_box2 pa30 mt40">
								<div className="energy_title2">
									<h2>내 에너지전환 활동 및 현황</h2>
									<div className="mypage_btn">
										{member?.id && (
											<button type="button" onClick={handleMypage}>
												마이페이지
											</button>
										)}
									</div>
								</div>
								<div className="character_box mt25">
									<div className="character">
										<img src={myCharacter} alt="기본캐릭터" />
									</div>
									<div className="mypoint">
										<h3>
											내 누적 포인트
											<span>
												{' '}
												{myPoint?.accmltPt
													? myPoint?.accmltPt.toLocaleString()
													: 0}
											</span>
										</h3>
										<h3>
											내 비가용 포인트
											<span>
												{' '}
												{myPoint?.disAblePt
													? myPoint?.disAblePt.toLocaleString()
													: 0}
											</span>
										</h3>
										<h2>
											현재 가용 포인트
											<span>
												{myPoint?.ablePt ? myPoint?.ablePt.toLocaleString() : 0}
											</span>
										</h2>
									</div>
								</div>
								<div className="table_inner mt25">
									<ul className="thead pa20">
										<li>전력</li>
										<li>도시가스</li>
										<li>총 에너지 절감량</li>
									</ul>
									<ul className="tbody">
										<li className="pa20">
											<p className="blue">
												{myEnergy?.pwrerMwh ? myEnergy?.pwrerMwh : 0}
												<span>MWh</span> <br />
												{myEnergy?.pwrerToe ? myEnergy?.pwrerToe : 0}{' '}
												<span>
													10<sup>-3</sup>TOE
												</span>
											</p>
											<p className="green">
												{myEnergy?.gasMj ? myEnergy?.gasMj : 0} <span>MJ</span>{' '}
												<br />
												{myEnergy?.gasToe ? myEnergy?.gasToe : 0}{' '}
												<span>
													10<sup>-3</sup>TOE
												</span>
											</p>
											<p className="red">
												{myEnergy?.redcnToe ? myEnergy?.redcnToe : 0}{' '}
												<span>
													10<sup>-3</sup>TOE
												</span>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</>
					) : (
						<></>
					)}

					{/*내 에너지전환활동현황 끝*/}
					{/* 마을,기본미션 순위 시작 */}
					<div className="mission_list_wrap">
						{/* 마을순위 시작 */}
						<div className="mission_list_box">
							<div className="mission_cont_title">
								<h3 className="comm_title">마을순위</h3>
							</div>
							<div className="mission_cont_set mt10">
								<select
									onChange={(e) => handleVillMmiUid(Number(e.target.value))}
								>
									<option value={undefined}>마을미션 전체</option>
									{villMissionList &&
										villMissionList.map((list, idx) => (
											<option key={idx} value={list?.mmiUid}>
												{list?.mssnNm}
											</option>
										))}
								</select>
								<ul>
									<li>
										<p
											className={period === '주간' ? 'on' : ''}
											onClick={() => setPeriod('주간')}
										>
											주간
										</p>
									</li>
									<li>
										<p
											className={period === '월간' ? 'on' : ''}
											onClick={() => setPeriod('월간')}
										>
											월간
										</p>
									</li>
									<li>
										<p
											className={period === '연간' ? 'on' : ''}
											onClick={() => setPeriod('연간')}
										>
											연간
										</p>
									</li>
								</ul>
							</div>

							{villRanking &&
								villRanking?.rankings &&
								villRanking?.rankings.length == 0 ? (
								<div className="comm_noList">
									<img src={noList} alt="내용없음 이미지" />
									<p className="mt25">
										해당 기간에 미션에 참여한 마을이 없습니다.
									</p>
								</div>
							) : (
								<div className="mission_cont_content mt25">
									<div className="ranking_list">
										{villRanking &&
											villRanking?.rankings.map((villList, villIndex) => (
												<p key={villIndex}>
													{villList?.rank < 4 ? (
														<span className="num">
															<img
																src={
																	villList?.rank === 1
																		? ic_rank01
																		: villList?.rank === 2
																			? ic_rank02
																			: villList?.rank === 3
																				? ic_rank03
																				: ''
																}
																alt="랭킹 아이콘"
															/>
														</span>
													) : (
														villList?.rank >= 4 && (
															<>
																<span className="num">{villList?.rank}</span>
															</>
														)
													)}
													<span className="villname">
														{villList?.town?.townName}

														<strong>
															{' '}
															(
															{villList?.town?.districts.map(
																(districtsList, districtsIndex) => (
																	<> {districtsList?.districtName} </>
																)
															)}
															)
														</strong>
													</span>
													<span className="point">
														{villList?.point.toLocaleString()} E
													</span>
												</p>
											))}
									</div>
									<span className="update">
										최근 업데이트 {moment(nowDate).format('YYYY.MM.DD')}
										<br />
										{period === '주간'
											? '주간 순위는 지난주 기준입니다.'
											: period === '월간'
												? '월간 순위는 이번달 기준입니다.'
												: period === '연간'
													? '연간 순위는 올해 기준입니다.'
													: ''}
									</span>
									{/* <span className="update">
										주간순위는 저번 주, 월간순위는 이번 달, 연간순위는 올해
										기준입니다.
									</span> */}
								</div>
							)}
						</div>
						{/* 마을순위 종료 */}
						{/* 기본순위 시작 */}
						<div className="mission_list_box">
							<div className="mission_cont_title">
								<h3 className="comm_title">기본순위</h3>
							</div>
							<div className="mission_cont_set mt10">
								<select onChange={(e) => handleMmiUid(Number(e.target.value))}>
									<option value={undefined}>기본미션 전체</option>
									{mssionList &&
										mssionList.map((list, idx) => (
											<option key={idx} value={list?.mmiUid}>
												{list?.mssnNm}
											</option>
										))}
								</select>
								<ul>
									<li>
										<p
											className={period2 === '주간' ? 'on' : ''}
											onClick={() => setPeriod2('주간')}
										>
											주간
										</p>
									</li>
									<li>
										<p
											className={period2 === '월간' ? 'on' : ''}
											onClick={() => setPeriod2('월간')}
										>
											월간
										</p>
									</li>
									<li>
										<p
											className={period2 === '연간' ? 'on' : ''}
											onClick={() => setPeriod2('연간')}
										>
											연간
										</p>
									</li>
								</ul>
							</div>

							{ranking && ranking?.rankings && ranking?.rankings.length == 0 ? (
								<div className="comm_noList">
									<img src={noList} alt="내용없음 이미지" />
									<p className="mt25">
										해당 기간에 미션에 참여한 회원이 없습니다.
									</p>
								</div>
							) : (
								<div className="mission_cont_content mt25">
									<div className="ranking_list">
										{ranking &&
											ranking?.rankings.map((list, index) => (
												<p key={index}>
													{list?.rank < 4 ? (
														<span className="num">
															<img
																src={
																	list?.rank === 1
																		? ic_rank01
																		: list?.rank === 2
																			? ic_rank02
																			: list?.rank === 3
																				? ic_rank03
																				: ''
																}
																alt="랭킹 아이콘"
															/>
														</span>
													) : (
														list?.rank >= 4 && (
															<>
																<span className="num">{list?.rank}</span>
															</>
														)
													)}
													{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}

													<span
														className={
															list.member.character === 1
																? 'nickname basic1'
																: list.member.character === 2
																	? 'nickname basic2'
																	: list.member.character === 3
																		? 'nickname Lv1'
																		: list.member.character === 4
																			? 'nickname Lv2'
																			: list.member.character === 5
																				? 'nickname Lv3'
																				: list.member.character === 6
																					? 'nickname Lv4'
																					: ''
														}
													>
														{list?.member?.nickname}
													</span>
													<span className="point">
														{' '}
														{list?.point.toLocaleString()} E
													</span>
												</p>
											))}
									</div>
									<span className="update">
										최근 업데이트 {moment(nowDate).format('YYYY.MM.DD')}
										<br />
										{period2 === '주간'
											? '주간 순위는 지난주 기준입니다.'
											: period2 === '월간'
												? '월간 순위는 이번달 기준입니다.'
												: period2 === '연간'
													? '연간 순위는 올해 기준입니다.'
													: ''}
									</span>
								</div>
							)}
						</div>
						{/* 기본순위 종료 */}
					</div>
					{/* 마을,기본미션 순위 끝 */}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default MissionYardPage;
