import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SubLocation from '../../../components/common/SubLocation';
import ic_cam from '../../../assets/ic_cam.png';
import test01 from '../../../assets/test01.png';
import {
	boardFileDTOS,
	formVillageCommu,
	villageCommuContent,
} from '../../../models/VillageCommuInfo';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDialog } from '../../../dialog/DialogProvider';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import SubmitSuccessDialog from '../../../dialog/common/SubmitSuccessDialog';
import SubmitFailDialog from '../../../dialog/common/SubmitFailDialog';
import ModifySuccessDialog from '../../../dialog/common/ModifySuccessDialog';
import ModifyFailDialog from '../../../dialog/common/ModifyFailDialog';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import './Write_VillageCommuPage.scss';
// 소통마당 > 마을소통 > 수정하기
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const Modify_VillageCommuPage = () => {
	return (
		<main className='Write_VillageCommuPage'>
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<Form />
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

// nav
const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>마을소통 수정하기</h3>
			</div>
		</nav>
	);
};
interface PreviewFile {
	// file: File;
	// preview: string
	name: string;
	boardFileId?: number;
}
// 수정form
const Form = () => {
	const { register, handleSubmit, setValue, watch, setFocus } = useForm<formVillageCommu>(
		{}
	);
	const { showDialog } = useDialog();
	const [category, setCategory] = useState('');
	// const [content, setContent] = useState('');
	const [allInput, setAllInput] = useState(false);
	const [categoryError, setCategoryError] = useState(false);
	const [contentError, setContentError] = useState(false);
	const townId = sessionStorage.getItem('town');
	const navigate = useNavigate();
	const boardId = sessionStorage.getItem('boardId');
	const [villageCommuDetail, setVillageCommuDetail] =
		useState<villageCommuContent>();
	const [updateImgList, setUpdateImgList] = useState<boardFileDTOS[]>([]);
	const content = watch('content');
	// 이미지 
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [deleteImgFileIds, setDeleteImgFileIds] = useState([] as number[]);
	const [fileList, setFileList] = useState([] as File[]);

	// pdf	
	const [pdfFiles, setPdfFiles] = useState([] as File[]);
	// type PreviewFile = string | { file: File; preview: string; name: string; boardFileId:number };
	const [previewFiles, setPreviewFiles] = useState([] as PreviewFile[]);
	const [deletePdfFileIds, setDeletePdfFileIds] = useState([] as number[]);


	// 취소
	const handleCancle = () => {
		navigate(-1);
	};

	// 필수 사항 모두 입력 시 'on' 클래스 추가됨
	const checkInputs = () => {
		return category.trim() !== '' && content?.trim() !== '';
	};

	const updSubmitBtn = () => {
		const allInput = checkInputs();
		setAllInput(allInput);
	};

	// 사진 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 5) {
			const title = '사진 첨부';
			const message = '사진은 최대 5장까지 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 사진 삭제
	const handleRemovePreview = (index: number, boardFileId?: number) => {
		if (boardFileId) {
			setDeleteImgFileIds((cur) => [...cur, boardFileId]);
		}
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 파일 첨부
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedFiles = Array.from(files);

		setPdfFiles((prevFiles: File[]) => {
			const newFiles = [...prevFiles];
			selectedFiles.slice(0, 5).forEach((file) => newFiles.push(file));
			// // console.log('filesToSubmit ::::::::::::::::: ', uploadFiles);
			return newFiles;
		});

		// 미리보기 파일 추가
		const filesToSubmit = selectedFiles.slice(0, 5);
		const newPreviewFiles = filesToSubmit.map((file) => {
			// 파일명 추출
			const fileName = file.name;
			// const fileBoardFileId = file.boardFileId;
			setPreviewFiles((prevFiles) => [...prevFiles, {
				name: file.name
			}]);
			return {
				file,
				preview: URL.createObjectURL(file),
				name: fileName,
				// boardFileId: fileBoardFileId
			};
		});
	}



	// // 파일 미리보기 삭제
	// const handleRemoveFilePreview = (index: number) => {
	// 	setPreviewFiles((prevFiles) => {
	// 		const newFiles = [...prevFiles];
	// 		newFiles.splice(index, 1);
	// 		return newFiles;
	// 	});
	// 	setPdfFiles((prevFiles) => {
	// 		const newFiles = [...prevFiles];
	// 		newFiles.splice(index, 1);
	// 		return newFiles;
	// 	});
	// };

	// 파일 삭제
	const handleRemovePdf = (index: number, boardFileId?: number) => {
		// console.log('파일삭제', boardFileId);
		if (boardFileId) {
			setDeletePdfFileIds((cur) => [...cur, boardFileId]);
		}
		setPreviewFiles((prevPdfs) => {
			const newPdfs = [...prevPdfs];
			newPdfs.splice(index, 1);
			return newPdfs;
		});
		setPdfFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 마을소통 상세조회
	useEffect(() => {
		if (boardId) {
			VillageCommuAPI.getVillageCommuDetail({
				boardId: Number(boardId),
			}).then((data) => {
				setVillageCommuDetail(data);
				setCategory(data.category ?? null);
				setValue('content', data.content);
				setValue('visible', data.visible);


				// 사진 조회 api추가되면 수정
				if (data?.boardFileDTOS) {
					const imageFiles: File[] = [];
					const pdfFiles: File[] = [];

					const imagePromises: Promise<any>[] = [];
					const pdfPromises: Promise<any>[] = [];

					for (let i = 0; i < data.boardFileDTOS.length; i++) {
						const file = data.boardFileDTOS[i];
						// 이미지 파일	
						if (file.fileRealName.includes(".png") || file.fileRealName.includes(".jpg")) {
							const promise = ActivityLogAPI.getImages({
								savedPath: file.filePath,
							}).then((res) => {
								if (res) {
									const image = new File(
										[res.data],
										file.fileRealName,
										{ type: 'image/png' }
									);

									imageFiles.push(image);

									const previewImage = `/api/attach/getFile?savedPath=${file.filePath}&boardFileId=${file.boardFileId}`;
									setPreviewImages((prevImages) => [...prevImages, previewImage]);
								}
							});

							imagePromises.push(promise);

							// pdf파일	
						} else if (file.fileRealName.includes(".pdf")) {
							const promise = ActivityLogAPI.getImages({
								savedPath: file.filePath,
							}).then((res) => {
								if (res) {
									const pdf = new File(
										[res.data],
										file.fileRealName
									);


									pdfFiles.push(pdf);

									const previewpdf = `/api/attach/getFile?savedPath=${file.filePath}&boardFileId=${file.boardFileId}`;
									// setPreviewFiles((prevPdfs) => [...prevPdfs, previewpdf]);
									// 기존 파일 정보를 추가
									setPreviewFiles((prevFiles: PreviewFile[]) => [...prevFiles, { file: pdf, preview: '', name: file.fileRealName, boardFileId: file.boardFileId }]);
								}
							});

							pdfPromises.push(promise);
						}
					}

					// Promise.all(imagePromises.concat(pdfPromises)).then(() => {
					//   setFileList(imageFiles.concat(pdfFiles));
					// });
				}

			});
		}

	}, [boardId]);

	// // console.log('boardId', boardId);
	// console.log('마을소통 수정글', villageCommuDetail);
	// console.log("이미지파일", previewImages)
	// console.log("pdf파일", previewFiles)

	// 등록 버튼
	const onSubmit: SubmitHandler<formVillageCommu> = async (data) => {
		const formDataToSubmit = new FormData();
		Array.from(data.uploadFiles || []).forEach((image) => {
			formDataToSubmit.append('images', image);
		});

		// 카테고리(필수)
		if (category === '') {
			const title = ''
			const message = '카테고리 선택은 필수입니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			// setCategoryError(true);
			// setFocus('category');
			return;
		} else {
			setCategoryError(false);
		}
		// 내용(필수)
		if (!data.content) {
			const title = ''
			const message = '내용 입력은 필수입니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			// setContentError(true);
			return;
		} else {
			setContentError(false);
		}

		// if (category === '' || content === '') {
		// 	return;
		// }

		VillageCommuAPI.updateVillageCommu(
			{
				...data,
				category: String(category),
				townId: Number(townId),
				visible: String(data.visible),
				boardId: Number(boardId),
				// content: data.content
			},
			uploadFiles ? uploadFiles : undefined,
			deleteImgFileIds ? deleteImgFileIds : undefined,
			pdfFiles ? pdfFiles : undefined,
			deletePdfFileIds ? deletePdfFileIds : undefined,
		)
			.then((res) => {
				// console.log('마을소통 글 수정', res);
				// navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`)
				const tab = 'villageCommu';
				showDialog(ModifySuccessDialog, { tab, boardId });
			})
			.catch((error) => {
				// console.log('마을소통 글 수정 실패', error);
				showDialog(ModifyFailDialog);
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="comm_write_wrap">
				<div className="cont-wrap">
					<h3 className="important">카테고리</h3>
					<div className="cont-box">
						<div className="select-box">
							{/* 마을뉴스 카테고리는 'ABR','MBR' 한테 안보임 */}
							<select
								value={category}
								className='category'
								{...register('category')}
								onChange={(e) => {
									setCategory(e.target.value);
									setCategoryError(false);
									updSubmitBtn();
								}}
							>
								<option value=''>선택</option>
								<option value={'ASK'}>마을질문</option>
								<option value={'DAILY'}>마을일상</option>
								<option value={'NEWS'}>마을뉴스</option>
							</select>
							{categoryError && (
								<p className="error-message"
									style={{
										color: 'red',
										fontSize: '14px',
									}}
								>! 카테고리 선택은 필수입니다.</p>
							)}
						</div>
					</div>
				</div>
				{/* <div className="cont-wrap">
					<h3 className="important">제목</h3>
					<div className="cont-box">
						<input type="text" className="long" placeholder="제목을 입력하세요" 
						    value={title} onChange={(e) => {setTitle(e.target.value)
															updSubmitBtn()}}/>
					</div>
				</div> */}
				<div className="cont-wrap">
					<h3 className="important">내용</h3>
					<div className="cont-box">
						<textarea
							placeholder="내용을 입력하세요"
							defaultValue={''}
							{...register('content')}
							onChange={(e) => {
								setContentError(false);
								updSubmitBtn();
							}}
						/>
					</div>
					{contentError && (
						<p className="error-message"
							style={{
								color: 'red',
								fontSize: '14px',
							}}
						>! 내용 입력은 필수입니다.</p>
					)}
				</div>
				<div className="cont-wrap">
					<h3>첨부 이미지<br />(최대 15MB)</h3>
					<div className="cont-box">
						<div className="imgBox">
							<input className="upload-photo" />
							<label htmlFor="img">
								<img src={ic_cam} alt="사진 첨부" />
								사진 첨부
								<span>(최대 5장)</span>
							</label>
							<input
								type="file"
								id="img"
								{...register('uploadFiles')}
								accept="image/*"
								onChange={handleImageChange}
							/>
						</div>
						<div className="imglistBox">
							<ul>
								{previewImages.map((imageUrl, index) => (
									<li key={index}>
										<img src={imageUrl} key={imageUrl} />
										<button
											type="button"
											onClick={() =>
												handleRemovePreview(
													index,
													Number(imageUrl.split('boardFileId=')[1])
												)

											}
											className="btn-file-delet"
										/>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className="cont-wrap">
					<h3>파일첨부(PDF)</h3>
					<div className="cont-box">
						<div className="fileBox">
							<input className="upload-name" defaultValue="선택한 파일 없음" placeholder="선택한 파일 없음" style={{ display: 'none' }} />
							<label htmlFor="file">파일 선택</label>
							<input
								type="file"
								id="file"
								{...register('pdfFiles')}
								accept="file/*"
								onChange={handleFileChange}
							/>
						</div>
						<div className="filelistBox mt10">
							<ul>
								{previewFiles.map((file, index) => (
									<li key={index}>
										<p key={index} className="txt_cut1">
											{typeof file === 'string' ? '' : file.name}
										</p>
										<p className='delet'>
											<button
												type="button"
												onClick={() => handleRemovePdf(
													index,
													file.boardFileId
												)}
											>삭제</button>
										</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>


			</div>
			<div className="comm_btn_wrap">
				<button type="button" className="btn_cancel" onClick={handleCancle}>
					취소
				</button>
				{/* <button type="submit" className={`btn_next ${allInput ? 'on' : ''}`}> */}
				<button type="submit" className="btn_next on">
					수정
				</button>{' '}
				{/* 모두 체크 시 on클래스 붙음*/}
			</div>
		</form>
	);
};

export default Modify_VillageCommuPage;
