import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import TownAPI from '../../../apis/TownAPI';
import CommSelect from '../../../components/common/CommSelect';
import SubLocation from '../../../components/common/SubLocation';
import ShareDialog from '../../../dialog/common/ShareDialog';
import { useDialog } from '../../../dialog/DialogProvider';
import { content } from '../../../models/TownList';
import CommTab from '../CommTab';

import test04 from '../../../assets/test04.png';
import NavComponent from '../../../components/common/NavComponent';
import useMember from '../../../hooks/useMember';
import ModifyDialog from '../../../dialog/common/ModifyDialog';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import { Content, JournalComment } from '../../../models/ActivityLog';
import moment from 'moment';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import CmtNonmemberDialog from '../../../dialog/common/CmtNonmemberDialog';
import TwoButtonAlertDialog from '../../../dialog/TwoButtonAlertDialog';
import { useSearchParams } from 'react-router-dom';
import LikeRoleDialog from '../../../dialog/common/LikeRoleDialog ';
// 소통마당 > 활동일지 > 상세
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
const Detail_ActivePage = () => {
	const [searchParams] = useSearchParams();
	const townId = sessionStorage.getItem('town');
	const journal = searchParams.get('journal');
	const journalId = sessionStorage.getItem('journal')
		? sessionStorage.getItem('journal')
		: journal;
	const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [journalDetail, setJournalDetail] = useState<Content | undefined>();
	const { showDialog } = useDialog();
	const { member } = useMember();
	const [generalRoles, setGeneralRoles] = useState(false);
	const [managerRoles, setManagerRoles] = useState(false);
	const [memberRoles, setMemberRoles] = useState(false);
	const [subManagerRoles, setSubManagerRoles] = useState(false);
	const [commentInsert, setCommentInsert] = useState(false);
	//권한 별 글 및 댓글 등록, 수정, 삭제 마을뉴스 카테고리 등록 권한 아직 테스트 못함 2023.09.05
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setGeneralRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				if (member.townDTO && member.townDTO.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setManagerRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log('권한 : 마을활동가, 마을주민');
				if (member.townDTO && member.townDTO.townId === Number(townId)) {
					setMemberRoles(true);
				}
			}
		}
	}, []);
	// 활동일지 주제내역 상세조회
	useEffect(() => {
		ActivityLogAPI.getJournalDetail(Number(journalId)).then((res) => {
			setJournalDetail(res);
			// console.log('디테일 >> ', res);
		});
	}, [commentInsert]);
	// 선택한 마을 townId
	const changeTownList = (townId: number) => {
		setSelectedTown(townId);
	};

	const handleInsertCheck = () => {
		setCommentInsert(!commentInsert);
	};
	return (
		<main className="List01_ActivePage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<CommTab clickTab={3} />
					<div className="village_commu_detail mt40">
						{/* 상세 글 정보 및 내용 */}
						<Detail
							journalDetail={journalDetail}
							generalRoles={generalRoles}
							managerRoles={managerRoles}
							memberRoles={memberRoles}
							handleInsertCheck={handleInsertCheck}
						/>
						{/* 좋아요, 공유하기, 목록으로 가기 */}
						<EtcBtn
							journalDetail={journalDetail}
							count={Number(journalDetail?.likeCnt)}
							handleInsertCheck={handleInsertCheck}
						/>
						<Comment
							generalRoles={generalRoles}
							managerRoles={managerRoles}
							memberRoles={memberRoles}
							journalComment={journalDetail?.journalCommentDTOS}
							handleCommentInsertCheck={handleInsertCheck}
						/>{' '}
						{/* 댓글 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};
interface detailProp {
	generalRoles?: boolean;
	managerRoles?: boolean;
	memberRoles?: boolean;
	journalDetail?: Content;
	handleInsertCheck: () => void;
}
// 상세 글 정보 및 내용
const Detail = ({
	journalDetail,
	generalRoles,
	managerRoles,
	memberRoles,
	handleInsertCheck,
}: detailProp) => {
	const { member } = useMember();
	const navigate = useNavigate();
	const { showDialog } = useDialog();

	useEffect(() => {
		// console.log('총괄권한', generalRoles);
		// console.log('운영자, 부운영자권한', managerRoles);
		// console.log('활동가, 주민권한', memberRoles);
	}, [generalRoles, managerRoles, memberRoles]);
	// 비공개, 공개 처리
	const handlePrivate = (journalId: number, visible: string) => {
		const title = '';
		const message =
			visible === 'Y'
				? '해당 글을 비공개하시겠습니까?'
				: '해당 글을 공개하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.privateJournal({
						journalId: journalId,
						visible: visible === 'Y' ? 'N' : 'Y',
					}).then((res) => {
						handleInsertCheck();
					});
				}
			},
		});
	};
	// 삭제
	const handleDelete = (journalId: number) => {
		const title = '';
		const message = '해당 글을 삭제하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.deleteJournal({ journalId: journalId }).then((res) => {
						if (res) {
							navigate(`/commu_02/active_03-history`);
						}
					});
				}
			},
		});
	};
	// 수정
	const handleModify = () => {
		// const tab = 'activeSubject';
		// sessionStorage.setItem('subject', String(subjectId));
		// showDialog(ModifyDialog, { tab });
		navigate(`/commu_02/active_04-modify-history`);
	};

	return (
		<>
			<div className="comm_detail_title mb25">
				<div className="comm_btn_set">
					{(generalRoles || managerRoles) && (
						<>
							<button
								type="button"
								className={`${journalDetail?.visible === 'Y' ? 'non' : 'pub'}`}
								onClick={() =>
									handlePrivate(
										Number(journalDetail?.journalId),
										journalDetail?.visible === 'Y' ? 'Y' : 'N'
									)
								}
							>
								{`${journalDetail?.visible === 'Y' ? '비공개' : '공개'}`}
							</button>
							{/* <button type="button" className="pub">공개</button> */}
						</>
					)}
					{(generalRoles || managerRoles) && (
						<>
							<button type="button" onClick={handleModify}>
								수정
							</button>
							<button
								type="button"
								onClick={() => handleDelete(Number(journalDetail?.journalId))}
							>
								삭제
							</button>
						</>
					)}
					{memberRoles && member && member.id == journalDetail?.createdId && (
						<>
							<button type="button" onClick={handleModify}>
								수정
							</button>
							<button
								type="button"
								onClick={() => handleDelete(Number(journalDetail?.journalId))}
							>
								삭제
							</button>
						</>
					)}
				</div>
			</div>
			<div className="comm_detail_cont pa30">
				<div className="info01">
					{/* 레벨별 class "Lv~" */}
					<p
						// className="name Lv1"
						className={
							journalDetail?.character === 1
								? 'name basic1'
								: journalDetail?.character === 2
									? 'name basic2'
									: journalDetail?.character === 3
										? 'name Lv1'
										: journalDetail?.character === 4
											? 'name Lv2'
											: journalDetail?.character === 5
												? 'name Lv3'
												: journalDetail?.character === 6
													? 'name Lv4'
													: ''
						}
					>
						{journalDetail?.nickname}
					</p>
					<p>{moment(journalDetail?.createdAt).format('yyyy.MM.DD')}</p>
				</div>
				<p className="mt25">{journalDetail?.content}</p>
				{/* 파일조회API 없어서 보류 */}
				{journalDetail?.journalFileDTOS.map((list, index) => (
					<img
						key={index}
						src={`/api/attach/getFile?savedPath=${list.filePath}`}
						alt="상세 이미지"
						className="mt25"
					/>
				))}
			</div>
		</>
	);
};
// 좋아요, 공유하기, 목록으로 가기
const EtcBtn = ({
	count,
	journalDetail,
	handleInsertCheck,
}: {
	count: number;
	journalDetail?: Content;
	handleInsertCheck: () => void;
}) => {
	const journalId = sessionStorage.getItem('journal');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const { member } = useMember();

	// 목록가기
	const backList = () => {
		navigate('/commu_02/active_03-history');
	};

	// 공유하기
	const handleShare = () => {
		// console.log('공유하기 클릭');
		showDialog(ShareDialog, {
			id: journalId ? `?journal=${journalId}` : '',
		});
		// const title = '공유하기';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(OneButtonAlertDialog, { title, message });
	};

	// 좋아요
	const handleLike = async () => {
		if (!member) {
			// alert('가입한 주민만 좋아요를 할 수 있습니다');
			showDialog(LikeRoleDialog);
			return;
		}
		try {
			const res = await ActivityLogAPI.likeJournal({ param: Number(journalId) }).then((res) => {
				// console.log('좋아요 성공~', res);
				handleInsertCheck();
			});
		} catch (error) {
			const title = '좋아요 실패';
			const message = '좋아요에 실패하였습니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message
			})
		}
	};
	return (
		<div className="comm_detail_btn mt25">
			<p>
				<input
					type="checkbox"
					id="act01"
					name="act"
					onClick={handleLike}
					checked={journalDetail?.journalLikeDTO ? true : false}
				/>
				<label htmlFor="act01">
					<span />
					{count}
				</label>
			</p>
			<a className="btn_share" onClick={handleShare}>
				공유
			</a>
			<a className="btn_list" onClick={backList}>
				목록
			</a>
		</div>
	);
};

interface commentProp {
	generalRoles?: boolean;
	managerRoles?: boolean;
	memberRoles?: boolean;
	journalComment?: JournalComment[];
	handleCommentInsertCheck: () => void;
}
// 댓글
const Comment = ({
	generalRoles,
	managerRoles,
	memberRoles,
	journalComment,
	handleCommentInsertCheck,
}: commentProp) => {
	const journalId = sessionStorage.getItem('journal');
	const { showDialog } = useDialog();
	const { member } = useMember();
	// 내용 500자 제한
	const [newContent, setNewContent] = useState<string>('');
	const [modifyContent, setModifyContent] = useState<string>('');
	const [cmtContentError, setCmtContentError] = useState('');
	//console.log("journalComment >>>>>> ", journalComment)
	// 댓글 수정창 열기
	useEffect(() => {
		// console.log('코멘트 총괄권한', generalRoles);
		// console.log('코멘트 운영자, 부운영자권한', managerRoles);
		// console.log('코멘트 활동가, 주민권한', memberRoles);
	}, [generalRoles, managerRoles, memberRoles]);
	// 등록
	const handleCommentInsert = () => {
		if (!newContent) {
			const title = '';
			const message = '댓글 내용을 입력해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (member) {
			if (generalRoles || managerRoles || memberRoles) {
				ActivityLogAPI.insertComment({
					journalId: Number(journalId),
					content: newContent,
				}).then((res) => {
					// console.log(res);
					setNewContent('');
					handleCommentInsertCheck();
				});
			} else {
				const title = '';
				const message = '마을 주민만 댓글을 작성하실 수 있습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
				});
				return;
			}
		} else {
			const title = '';
			const message = '로그인하고 댓글을 작성하실 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
	};
	// 댓글 수정
	const handleCommentUpdate = (journalCommentId: number) => {
		if (!commentValue) {
			const title = '';
			const message = '댓글 내용을 입력해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (member) {
			if (generalRoles || managerRoles || memberRoles) {
				ActivityLogAPI.updateComment({
					journalCommentId: journalCommentId,
					content: commentValue,
				}).then((res) => {
					// console.log(res);
					setNewContent('');
					handleCommentInsertCheck();
					setOpenCommentModify(true);
				});
			} else {
				const title = '';
				const message = '마을 주민만 댓글을 작성하실 수 있습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
				});
				return;
			}
		} else {
			const title = '';
			const message = '로그인하고 댓글을 작성하실 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
	};
	// 댓글 비공개
	const handlePrivateComment = (commentId: number, visible: string) => {
		const title = '';
		const message =
			visible === 'Y'
				? '댓글을 비공개 하시겠습니까?'
				: '댓글을 공개 하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.updateComment({
						journalCommentId: commentId,
						visible: visible === 'Y' ? 'N' : 'Y',
					}).then((res) => {
						// console.log('삭제완료', res);
						handleCommentInsertCheck();
					});
				}
			},
		});
	};

	// 댓글 삭제
	const deleteComment = (deletedId: number) => {
		const title = '';
		const message = '댓글을 삭제 하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.updateComment({
						journalCommentId: deletedId,
						deletedId: member && member.id,
					}).then((res) => {
						// console.log('삭제완료', res);
						handleCommentInsertCheck();
					});
				}
			},
		});
	};

	const maxCharacters: number = 500;
	// 새 댓글 작성시(내용 500자 제한)
	const handleNewContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		const newContent: string = event.target.value;
		if (newContent.length <= maxCharacters) {
			setNewContent(newContent);
		}
	};

	// 댓글 수정시
	const handleContentModify = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member?.id) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const modifyContent: string = event.target.value;
			if (modifyContent.length <= maxCharacters) {
				setModifyContent(modifyContent);
				setCommentValue(modifyContent);
			}
		}
	};

	// 대댓글 등록
	const handleReplyCmtWrite = (uid: number) => {
		if (replyContent == '') {
			const title = '답글 등록';
			const message = '답글 내용을 입력해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (member) {
			if (generalRoles || managerRoles || memberRoles) {
				ActivityLogAPI.insertComment({
					journalId: Number(journalId),
					content: replyContent,
					pjournalCommentId: uid
				}).then((res) => {
					// console.log(res);
					setReplyContent('');
					handleCommentInsertCheck();
					setOpenReplyCommentWrite(false);
				});
			} else {
				const title = '';
				const message = '마을 주민만 댓글을 작성하실 수 있습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
				});
				return;
			}
		} else {
			const title = '';
			const message = '로그인하고 댓글을 작성하실 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
	};
	// 대댓글 작성시(내용 500자 제한)
	const handleNewReplyContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const newContent: string = event.target.value;

			// 500자 제한
			if (newContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = newContent.slice(0, maxCharacters);
				setReplyContent(truncatedContent);
			} else {
				setReplyContent(newContent);
			}
		}
	};
	// 대댓글 수정
	const handleReplyCmtModify = (journalCommentId: number) => {
		setOpenCommentModify(true);

		if (modifyReplyContent == '') {
			const title = '';
			const message = '댓글 내용을 입력해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (member) {
			if (generalRoles || managerRoles || memberRoles) {
				ActivityLogAPI.updateComment({
					journalCommentId: journalCommentId,
					content: modifyReplyContent,
				}).then((res) => {
					// console.log(res);
					setNewContent('');
					handleCommentInsertCheck();
					setOpenCommentModify(true);
					setOpenReplyCommentModify(false);
				});
			} else {
				const title = '';
				const message = '마을 주민만 댓글을 작성하실 수 있습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
				});
				return;
			}
		} else {
			const title = '';
			const message = '로그인하고 댓글을 작성하실 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
	};
	// 대댓글수정시(내용 500자 제한)
	const handleModifyReplyContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const modifyContent: string = event.target.value;

			// 500자 제한
			if (modifyContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = modifyContent.slice(0, maxCharacters);
				seModifyReplyContent(truncatedContent);
				// setCommentValue(truncatedContent); // 수정된 내용을 댓글 값으로 설정
			} else {
				seModifyReplyContent(modifyContent);
				// setCommentValue(modifyContent); // 수정된 내용을 댓글 값으로 설정
			}
		}
	};

	// 대댓글 삭제
	const deleteReplyComment = async (deletedId: number) => {
		const title = '';
		const message = '댓글을 삭제 하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.updateComment({
						journalCommentId: deletedId,
						deletedId: member && member.id,
					}).then((res) => {
						// console.log('삭제완료', res);
						handleCommentInsertCheck();
					});
				}
			},
		});
	};

	// 대댓글 공개, 비공개
	const handleReplyCmtPrivate = async (commentId: number, visible: string) => {
		const title = '';
		const message =
			visible === 'Y'
				? '댓글을 비공개 하시겠습니까?'
				: '댓글을 공개 하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ActivityLogAPI.updateComment({
						journalCommentId: commentId,
						visible: visible === 'Y' ? 'N' : 'Y',
					}).then((res) => {
						// console.log('삭제완료', res);
						handleCommentInsertCheck();
					});
				}
			},
		});
	};


	// 댓글 수정창 열기
	const [openCommentModify, setOpenCommentModify] = useState(true);
	const [openReplyCommentWrite, setOpenReplyCommentWrite] = useState(true);
	const [openReplyCommentModify, setOpenReplyCommentModify] = useState(true);
	const [updateIndex, setUpdateIndex] = useState<number>();
	const [writeReplyIndex, setWriteReplyIndex] = useState<number>();
	const [modifyReplyIndex, setModifyReplyIndex] = useState<number>();
	const [commentValue, setCommentValue] = useState<string>();
	const [replyContent, setReplyContent] = useState<string>('')
	const [modifyReplyContent, seModifyReplyContent] = useState<string>('')
	useEffect(() => {
		//console.log("writeReplyIndex >>>>>> ", writeReplyIndex)
	}, [writeReplyIndex])
	// 대댓글 등록
	const clickReplyCommentWrite = (idx: number) => {
		setWriteReplyIndex(idx);
		setOpenReplyCommentWrite(true);
		setOpenReplyCommentModify(false);
		setOpenCommentModify(false);
	};
	// 대댓글 수정
	const clickReplyCommentModify = (idx: number, ridx: number, comment: string) => {
		setUpdateIndex(idx)
		setModifyReplyIndex(ridx);
		seModifyReplyContent(comment);
		setOpenReplyCommentModify(true);
		setOpenReplyCommentWrite(false);
		setOpenCommentModify(false);
	};
	// 대댓글 등록창 닫기
	const clickReplyCommentWriteCancle = () => {
		setOpenReplyCommentWrite(false);
	};
	// 대댓글 수정창 닫기
	const clickReplyCommentModifyCancle = () => {
		setOpenReplyCommentModify(false);
	};
	const handleCmtModify = (index: number, comment: string) => {
		// // console.log(e.target.parentElement.parentElement.parentElement.children[1]);
		setUpdateIndex(index);
		setCommentValue(comment);
		setOpenCommentModify(true);
		setOpenReplyCommentModify(false);
		setOpenReplyCommentWrite(false);
	};
	// 댓글 수정 > 취소
	const handleCmtCancle = () => {
		setOpenCommentModify(false);
	};
	useEffect(() => {
		if (journalComment) {
			//console.log(" 2 313242141 ", journalComment)
		}
	}, [journalComment])
	return (
		<>
			<div className="comm_comment_write mt40">
				<h5>
					댓글&nbsp;<span>{journalComment && journalComment.length
						? `(${journalComment.length}건)`
						: '(0건)'}</span>
				</h5>
				<div className="comm_box_write mt25 pa20">
					<textarea
						placeholder={
							!member ? '로그인하고 댓글을 작성하실 수 있습니다.' : '댓글을 입력해주세요.'
						}
						defaultValue={''}
						value={newContent}
						onChange={handleNewContentChange}
					/>
					<div className="write_info mt10">
						<p>
							{newContent.length}/{maxCharacters}
						</p>
						{/* {member?.roles === "AMN" || member?.townDTO.townId === villageCommuDetail?.townId && ( */}
						<button
							type="button"
							className="comm_write_button"
							onClick={handleCommentInsert}
						>
							등록
						</button>
						{/* )} */}
					</div>
				</div>
			</div>
			<div className="comm_comment_list">
				<ul>
					{journalComment && journalComment.map((cmtList, idx) => (
						<li
							key={idx}
							className={cmtList?.visible === 'N' ? 'mt25 non' : 'mt25'}
						>
							<div className="comm_box_list">
								<div className="info01">
									{/* 레벨별 class "Lv~" */}
									<p
										// className="name Lv1"
										className={
											cmtList && cmtList?.character === 1
												? 'name basic1'
												: cmtList.character === 2
													? 'name basic2'
													: cmtList.character === 3
														? 'name Lv1'
														: cmtList.character === 4
															? 'name Lv2'
															: cmtList.character === 5
																? 'name Lv3'
																: cmtList.character === 6
																	? 'name Lv4'
																	: ''
										}
									>
										{cmtList.nickname}</p>
									{/* <p>{`${moment(cmtList.createdAt).format('yyyy.MM.DD')}`}</p> */}
									{/* <p>
										{cmtList.createdAt[0]}.
										{formattedMonth}.
										{formattedDay}

									</p> */}
									<p>
										{moment(`${cmtList.createdAt[0]}-${cmtList.createdAt[1]}-${cmtList.createdAt[2]}`).format('YYYY.MM.DD')}
										{/* {`${cmtList.createdAt[0]}.${Number(cmtList.createdAt[1]) >= 10 ? '' : '0'}${cmtList.createdAt[1]}.${Number(cmtList.createdAt[2]) >= 10 ? '' : '0'}${Number(cmtList.createdAt[2])}`} */}
									</p>
									<p style={{ cursor: 'pointer' }} onClick={() => clickReplyCommentWrite(idx)}>답글</p>
									{generalRoles ||
										managerRoles ||
										(member && member.id == cmtList.createdId) ? (
										<>
											<a onClick={() => handleCmtModify(idx, cmtList.content)}>
												수정
											</a>
											<a onClick={() => deleteComment(cmtList.pathUid)}>삭제</a>
										</>
									) : (
										<></>
									)}
									{(generalRoles || managerRoles) && (
										<a
											className="txt_blue"
											onClick={() =>
												handlePrivateComment(
													cmtList.journalCommentId,
													cmtList.visible === 'Y' ? 'Y' : 'N'
												)
											}
										>
											{cmtList.visible === 'Y' ? '비공개' : '공개'}
										</a>
									)}
								</div>
								<h5 className="mt5">
									{cmtList.content &&
										cmtList.content.split('\n').map((item, index) => (
											<React.Fragment key={index}>
												{item}
												<br />
											</React.Fragment>
										))}
									{openCommentModify && (
										<div
											className="comm_box_write pa20"
											style={{ display: updateIndex === idx ? 'block' : 'none' }}
										>
											<textarea
												placeholder="댓글을 입력해주세요."
												defaultValue={''}
												value={commentValue}
												onChange={handleContentModify}
											/>
											{cmtContentError && (
												<p className="error" style={{ color: 'red' }}>
													{cmtContentError}
												</p>
											)}
											<div className="write_info mt10">
												<p>
													{(commentValue && commentValue.length) || 0}/
													{maxCharacters}
												</p>
												<div>
													<button
														type="button"
														className="comm_write_button small_white"
														onClick={handleCmtCancle}
													>
														취소
													</button>
													<button
														type="button"
														className="comm_write_button small_blue"
														onClick={() =>
															handleCommentUpdate(
																cmtList.journalCommentId
															)
														}
													>
														수정
													</button>
												</div>
											</div>
										</div>
									)}
									{/* 대댓글  */}
									{openReplyCommentWrite &&
										<>
											<div
												className="replyComm_box_write pa20"
												style={{
													display: writeReplyIndex === idx ? 'block' : 'none'
												}}
											>
												<textarea
													placeholder="답글을 입력해주세요."
													defaultValue={''}
													value={replyContent}
													// onChange={handleModifyContentChange}
													onChange={(e) => handleNewReplyContentChange(e)}
												/>
												{cmtContentError && (
													<p className="error" style={{ color: 'red' }}>
														{cmtContentError}
													</p>
												)}
												<div className="write_info mt10">
													<p>
														{(replyContent && replyContent.length) || 0}/
														{maxCharacters}
													</p>
													<div>
														<button
															type="button"
															className="comm_write_button small_white"
															onClick={clickReplyCommentWriteCancle}
														>
															취소
														</button>
														<button
															type="button"
															className="comm_write_button small_blue"
															onClick={() =>
																handleReplyCmtWrite(
																	Number(cmtList.journalCommentId),
																)
															}
														>
															등록
														</button>
													</div>
												</div>
											</div>
										</>
									}
									{/* 대댓글  */}
									{cmtList.journalCommentDTOS && cmtList.journalCommentDTOS.length !== 0 && cmtList.journalCommentDTOS.map((rList, rIndex) => (
										<>
											<div key={rIndex} className='replyCommentDiv' style={{ marginLeft: '30px' }}>
												<p>➥</p>
												<p
													// className="name Lv1"
													className={
														rList && rList?.character === 1
															? 'name basic1'
															: rList.character === 2
																? 'name basic2'
																: rList.character === 3
																	? 'name Lv1'
																	: rList.character === 4
																		? 'name Lv2'
																		: rList.character === 5
																			? 'name Lv3'
																			: rList.character === 6
																				? 'name Lv4'
																				: ''
													}
												>
													{rList.nickname}</p>
												<p>
													{moment(`${cmtList.createdAt[0]}-${cmtList.createdAt[1]}-${cmtList.createdAt[2]}`).format('YYYY.MM.DD')}
													{/* {`${rList.createdAt[0]}.${rList.createdAt[1] >= 10 ? '' : '0'}${rList.createdAt[1]}.${rList.createdAt[2] >= 10 ? '' : '0'}${rList.createdAt[2]}`} */}
												</p>
												{/* 대댓글 버튼 */}
												{
													(member && member.id == rList.createdId && (
														<>
															<a
																onClick={() =>
																	clickReplyCommentModify(idx, rIndex, rList.content)
																}
															>
																수정
															</a>
															<a

																onClick={() => deleteReplyComment(rList.journalCommentId)}
															>
																삭제
															</a>
														</>
													))}
												{(generalRoles || managerRoles) && (
													<a
														className="txt_blue"
														onClick={() =>
															handleReplyCmtPrivate(
																Number(rList.journalCommentId),
																rList.visible === 'Y' ? 'Y' : 'N'
															)
														}
													>
														{rList.visible === 'Y' ? '비공개' : '공개'}
													</a>
												)}
											</div>
											<div className={rList.visible == 'Y' ? '' : 'rePlyNon'} style={{ marginLeft: '70px' }}>
												{rList.content.split('\n').map((ritem, rindex) => (
													<React.Fragment key={rindex}>
														{ritem}
														<br />
													</React.Fragment>
												))}
												{/* 대댓글 수정 */}
												{openReplyCommentModify && (
													<div
														className="replyComm_box_write pa20"
														style={{ marginLeft: '0px', display: updateIndex === idx && modifyReplyIndex === rIndex ? 'block' : 'none' }}
													>
														<textarea
															placeholder="댓글을 입력해주세요."
															defaultValue={''}
															value={modifyReplyContent}
															onChange={(e) => handleModifyReplyContentChange(e)}
														/>
														{cmtContentError && (
															<p className="error" style={{ color: 'red' }}>
																{cmtContentError}
															</p>
														)}
														<div className="write_info mt10">
															<p>
																{(modifyReplyContent && modifyReplyContent.length) || 0}/
																{maxCharacters}
															</p>
															<div>
																<button
																	type="button"
																	className="comm_write_button small_white"
																	onClick={clickReplyCommentModifyCancle}
																>
																	취소
																</button>
																<button
																	type="button"
																	className="comm_write_button small_blue"
																	onClick={() =>
																		handleReplyCmtModify(
																			Number(rList.journalCommentId),
																		)
																	}
																>
																	수정
																</button>
															</div>
														</div>
													</div>
												)}
											</div>
										</>
									))}
									{/* 접속자 {member?.id}	
							<br/>
							작성자 {cmtList.createdId}	 */}
								</h5>

							</div>

						</li>
					))}
				</ul>
			</div>
		</>
	);
};
export default Detail_ActivePage;
