import React, { ChangeEvent, useEffect, useState } from 'react';
import CommSelect from '../../../components/common/CommSelect';
import SubLocation from '../../../components/common/SubLocation';
import CommTab from '../CommTab';
import test04 from '../../../assets/test04.png';
import VillageCommuInfo, {
	boardCommentDTOS,
	formVillageCommuCmt,
	villageCommuContent,
} from '../../../models/VillageCommuInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { content } from '../../../models/TownList';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import { useDialog } from '../../../dialog/DialogProvider';
import ShareDialog from '../../../dialog/common/ShareDialog';
import NavComponent from '../../../components/common/NavComponent';
import moment from 'moment';
import { listenerCount } from 'process';
import ModifyDialog from '../../../dialog/common/ModifyDialog';
import DeleteDialog from '../../../dialog/common/DeleteDialog';
import useMember from '../../../hooks/useMember';
import PrivateDialog from '../../../dialog/common/PrivateDialog';
import PublicDialog from '../../../dialog/common/PublicDialog';
import PrivateSuccessDialog from '../../../dialog/common/PrivateSuccessDialog';
import PrivateFailDialog from '../../../dialog/common/PrivateFailDialog';
import MemberInfo from '../../../models/MemberInfo';
import { data } from 'jquery';
import LikeRoleDialog from '../../../dialog/common/LikeRoleDialog ';
import { SubmitHandler, useForm } from 'react-hook-form';
import CmtSubmitSuccessDialog from '../../../dialog/common/CmtSubmitSuccessDialog';
import CmtSubmitFailDialog from '../../../dialog/common/CmtSubmitFailDialog';
import CmtNonmemberDialog from '../../../dialog/common/CmtNonmemberDialog';
import CmtDeleteSuccessDialog from '../../../dialog/common/CmtDeleteSuccessDialog';
import CmtDeleteFailDialog from '../../../dialog/common/CmtDeleteFailDialog';
import CmtDeleteDialog from '../../../dialog/common/CmtDeleteDialog';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';

// 마을소통 > 상세보기

const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];

const Detail_VillageCommuPage = () => {
	// 관리자 권한
	const [amnBtn, setAmnBtn] = useState(false);
	// 운영자, 부운영자 권한
	const [mstSmtBtn, setMstSmtBtn] = useState(false);
	// 활동가, 주민
	const [abrMbrBtn, setAbrMbrBtn] = useState(false);

	//------------------------------------------------------------------
	// 글 비공개 권한
	const [privateRoles, setPrivateRoles] = useState(false);
	// 글 공개 권한
	const [publicRoles, setPublicRoles] = useState(false);
	// 댓글 비공개 권한
	const [privateCmtRoles, setPrivateCmtRoles] = useState(false);
	// 댓글 공개 권한
	const [publicCmtRoles, setPublicCmtRoles] = useState(false);
	// 댓글 등록 권한
	const [cmtWriteRoles, setCmtWriteRoles] = useState(false);
	// 댓글 수정,삭제 권한
	const [cmtUpdRoles, setCmtUpdRoles] = useState(false);
	//--------------------------------------------------------------------------------------
	const { member } = useMember();
	const [villageCommuDetail, setVillageCommuDetail] =
		useState<villageCommuContent>();
	// const [townList, setTownList] = useState([] as content[]);
	// const [selectedTown, setSelectedTown] = useState<number | undefined>();
	// const [townInfo, setTownInfo] = useState([] as content[]);
	const [searchParams] = useSearchParams();
	const board = searchParams.get('boardId')
	const boardId = sessionStorage.getItem('boardId') ? sessionStorage.getItem('boardId') : board;
	const town = searchParams.get('town');
	const townId = sessionStorage.getItem('town') ? sessionStorage.getItem('town') : town;

	const createdId = sessionStorage.getItem('createdId');
	const [visible, setVisible] = useState<string | null>('');
	const [likeCheck, setLikeCheck] = useState(false);

	// 마을소통 상세조회 + 권한버튼
	useEffect(() => {
		const fetchData = async () => {
			if (boardId) {
				// 상세조회
				try {
					const data = await VillageCommuAPI.getVillageCommuDetail({
						boardId: Number(boardId),
					});
					setVillageCommuDetail(data); // 상세조회
					setVisible(data.visible); // 공개, 비공개 상태
					if (member && data.boardLikeDTO) {
						setLikeCheck(true); // 좋아요
					}

					// console.log('마을소통 상세글', villageCommuDetail);
					// // console.log("likeCheck",likeCheck);
					// console.log('마을소통 댓글', villageCommuDetail?.boardCommentDTOS);
					// 권한별 버튼
					// 	관리자/운영자/부운영자/활동가/주민/회원
					//  'AMN' 'MST'  'SMT'  'ABR' 'MBR''STB'
					if (member) {
						//	관리자
						if (member.roles === 'AMN') {
							// console.log('권한 : 플랫폼관리자');
							setAmnBtn(true);
							setCmtWriteRoles(true); // 댓글 등록
							setCmtUpdRoles(true); // 댓글 수정, 삭제
							if (visible === 'Y') {
								setPrivateRoles(true); // 글 비공개
							} else if (visible === 'N') {
								setPublicRoles(true); // 글 공개
							}
							setPrivateCmtRoles(true); // 댓글 비공개
							setPublicCmtRoles(true); // 댓글 공개
							// 운영자, 부운영자
						} else if (member.roles === 'MST' || member.roles === 'SMT') {
							setCmtWriteRoles(true); // 댓글 등록
							setCmtUpdRoles(true); // 댓글 수정, 삭제
							if (member.id === Number(createdId)) {
								// console.log('권한 : 운영자, 부운영자');
								setMstSmtBtn(true);
							}
							if (member.townDTO.townId === Number(townId) && visible === 'Y') {
								setPrivateRoles(true); // 글 비공개
								setCmtWriteRoles(true); // 댓글 등록
								setPrivateCmtRoles(true); // 댓글 비공개
								setPublicCmtRoles(true); // 댓글 공개
								setCmtUpdRoles(true); // 댓글 수정, 삭제

							} else if (visible === 'N') {
								setPublicRoles(true); // 글 공개
								setCmtWriteRoles(true); // 댓글 등록
								setPrivateCmtRoles(true); // 댓글 비공개
								setPublicCmtRoles(true); // 댓글 공개
								setCmtUpdRoles(true); // 댓글 수정, 삭제
							}

							// 활동가, 주민
						} else if (member.roles === 'ABR' || member.roles === 'MBR') {
							if (member.id === Number(createdId)) {
								// console.log('권한 : 활동가, 주민');
								setAbrMbrBtn(true);
							}
							// if (member.townDTO.townId) {
							// 	setCmtWriteRoles(true); // 댓글 등록
							// 	setCmtUpdRoles(true); // 댓글 수정, 삭제
							// } 추후 정리시 삭제 
							setCmtWriteRoles(true); // 댓글 등록
							setCmtUpdRoles(true); // 댓글 수정, 삭제
						} 
					}
				} catch (error) {
					// console.log('error', error);
				}
			}
		};
		fetchData();
	}, [member, visible, likeCheck]);

	return (
		<main>
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<CommTab clickTab={1} />
					<div className="village_commu_detail mt40">
						{/* 상세 글 정보 및 내용 */}
						<Detail
							villageCommuDetail={villageCommuDetail}
							boardId={boardId}
							// modifyRoles={modifyRoles}
							// deleteRoles={deleteRoles}
							privateRoles={privateRoles}
							publicRoles={publicRoles}
							visible={visible}
							amnBtn={amnBtn}
							mstSmtBtn={mstSmtBtn}
							abrMbrBtn={abrMbrBtn}
						/>
						{/* 좋아요, 공유하기, 목록으로 가기 */}
						<EtcBtn
							// boardId={boardId}
							member={member}
							villageCommuDetail={villageCommuDetail}
							likeCheck={likeCheck}
							setLikeCheck={setLikeCheck}
							boardId={boardId}
							board={board}
							townId={townId}
						/>
						{/* 댓글 */}
						<Comment
							villageCommuDetail={villageCommuDetail}
							boardId={boardId}
							member={member}
							// modifyCmtRoles={modifyCmtRoles}
							// deleteCmtRoles={deleteCmtRoles}
							privateCmtRoles={privateCmtRoles}
							publicCmtRoles={publicCmtRoles}
							visible={visible}
							amnBtn={amnBtn}
							mstSmtBtn={mstSmtBtn}
							abrMbrBtn={abrMbrBtn}
							cmtWriteRoles={cmtWriteRoles}
							cmtUpdRoles={cmtUpdRoles}
						/>
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

// 상세 글 정보 및 내용
const Detail = ({
	villageCommuDetail,
	boardId,
	// modifyRoles,
	// deleteRoles,
	privateRoles,
	publicRoles,
	visible,
	amnBtn,
	mstSmtBtn,
	abrMbrBtn,
}: {
	villageCommuDetail: villageCommuContent | undefined;
	boardId: string | null;
	// modifyRoles: boolean,
	// deleteRoles: boolean
	privateRoles: boolean;
	publicRoles: boolean;
	visible: string | null;
	amnBtn: boolean;
	mstSmtBtn: boolean;
	abrMbrBtn: boolean;
}) => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [uploadFiles, setUploadFiles] = useState([] as File[]);

	// 수정
	const handleModify = (boardId: string) => {
		// navigate(`/commu_02/villageCm_04-modify?boardId=${boardId}`);
		const tab = 'villageCommu';
		showDialog(ModifyDialog, { tab, boardId });
	};

	// 삭제
	const handleDelete = (boardId: string) => {
		// navigate(`/commu_02/villageCm_04-modify?boardId=${boardId}`);
		const tab = 'villageCommu';
		showDialog(DeleteDialog, { tab, boardId });
		// console.log('마을소통 삭제글 boardId', boardId);
	};

	// 공개, 비공개
	const handlePrivate = (
		visible: string,
		category: string,
		townId: number,
		boardId: number,
		content: string
	) => {
		if (visible === 'Y') {
			// 비공개 처리
			showDialog(PrivateDialog, {
				onComplete(result) {
					// console.log('PrivateDialog result ::::: ', result);
					if (result === 'yes') {
						VillageCommuAPI.updateVillageCommu(
							{
								category: String(category),
								townId: Number(townId),
								visible: 'N',
								boardId: Number(boardId),
								content: String(content),
							},
							uploadFiles
						)
							.then((res) => {
								// console.log('마을소통 글 비공개', res);
								// navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`)
								showDialog(PrivateSuccessDialog);
								// window.location.reload();
							})
							.catch((error) => {
								// console.log('마을소통 글 비공개 실패', error);
								showDialog(PrivateFailDialog);
							});
					}
				},
			});
		} else if (visible === 'N') {
			// 공개 처리
			showDialog(PublicDialog, {
				onComplete(result: string) {
					// console.log('PublicDialog result ::::: ', result);
					if (result === 'yes') {
						VillageCommuAPI.updateVillageCommu(
							{
								category: String(category),
								townId: Number(townId),
								visible: 'Y',
								boardId: Number(boardId),
							},
							uploadFiles
						)
							.then((res) => {
								// console.log('마을소통 글 공개', res);
								// navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`)
								showDialog(PrivateSuccessDialog);
								window.location.reload();
							})
							.catch((error) => {
								// console.log('마을소통 글 공개 실패', error);
								showDialog(PrivateFailDialog);
							});
					}
				},
			});
		}
	};

	return (
		<>
			<div className="comm_detail_title mb25">
				<span
					className={`comm_village ${villageCommuDetail &&
						villageCommuDetail?.category === 'NEWS' &&
						'news'
						}`}
				>
					{villageCommuDetail && villageCommuDetail?.category === 'NEWS'
						? '마을뉴스'
						: villageCommuDetail?.category === 'ASK'
							? '마을질문'
							: '마을일상'}
				</span>
				<div className="comm_btn_set">
					{privateRoles && (
						<button
							type="button"
							className="non"
							onClick={() =>
								handlePrivate(
									String(visible),
									String(villageCommuDetail?.category),
									Number(villageCommuDetail?.townId),
									Number(boardId),
									String(villageCommuDetail?.content)
								)
							}
						>
							비공개
						</button>
					)}
					{publicRoles && (
						<button
							type="button"
							className="pub"
							onClick={() =>
								handlePrivate(
									String(visible),
									String(villageCommuDetail?.category),
									Number(villageCommuDetail?.townId),
									Number(boardId),
									String(villageCommuDetail?.content)
								)
							}
						>
							공개
						</button>
					)}

					{(amnBtn || mstSmtBtn || abrMbrBtn) && (
						<>
							<button
								type="button"
								onClick={() => handleModify(String(boardId))}
							>
								수정
							</button>
							<button
								type="button"
								onClick={() => handleDelete(String(boardId))}
							>
								삭제
							</button>
						</>
					)}
					{/* {modifyRoles && (
					)}
					{deleteRoles && (
					)} */}
				</div>
			</div>
			<div className="comm_detail_cont pa30">
				<div className="info01">
					{/* 레벨별 class "Lv~" */}
					{/* <p className="name Lv1"> */}
					<p className={
						villageCommuDetail?.character === 1 ? 'name basic1 '
							: villageCommuDetail?.character === 2 ? 'name basic2'
								: villageCommuDetail?.character === 3 ? 'name Lv1'
									: villageCommuDetail?.character === 4 ? 'name Lv2'
										: villageCommuDetail?.character === 5 ? 'name Lv3'
											: villageCommuDetail?.character === 6 ? 'name Lv4'
												: ''
					}>
						{villageCommuDetail && villageCommuDetail?.nickname}
					</p>
					<p>{`${moment(
						villageCommuDetail && villageCommuDetail?.createdAt
					).format('yyyy.MM.DD')}`}</p>
				</div>
				<p className="mt25">
					{villageCommuDetail &&
						villageCommuDetail?.content.split('\n').map((item, idx) => (
							<React.Fragment key={idx}>
								{item}
								<br />
							</React.Fragment>
						))}
				</p>
				{villageCommuDetail?.boardFileDTOS[0] &&
					villageCommuDetail.boardFileDTOS.filter((imgList) => imgList.fileRealName.includes(".jpg") || imgList.fileRealName.includes(".png")
					).map((imgList2) => (
						<img
							className="mt25"
							key={imgList2.boardFileId}
							src={`/api/attach/getFile?savedPath=${imgList2.filePath}`}
						/>
					))}

				{villageCommuDetail?.boardFileDTOS[0] &&
					villageCommuDetail.boardFileDTOS.filter((pdfList) => pdfList.fileRealName.includes(".pdf")).map((pdfList2) => (
						<div className="fileList mt25">
							<a
								href={`/api/attach/getFile?savedPath=${pdfList2.filePath}&download=true`}
							// href={`http://localhost:12158/api/attach/getFile?savedPath=${imgList2.filePath}&download=true`}
							>{pdfList2.fileRealName}</a>
						</div>
					))}
			</div>
		</>
	);
};

// 좋아요, 공유하기, 목록으로 가기
const EtcBtn = ({
	member,
	villageCommuDetail,
	likeCheck,
	setLikeCheck,
	boardId,
	board,
	townId
}: {
	member: MemberInfo | undefined;
	villageCommuDetail: villageCommuContent | undefined;
	likeCheck: boolean;
	setLikeCheck: (value: boolean) => void;
	boardId: string | null;
	board: string | null
	townId: string | null
}) => {
	const { showDialog } = useDialog();

	const navigate = useNavigate();

	// 목록가기
	const backList = () => {
		// console.log('마을소통 목록으로 이동');
		navigate(`/commu_02/villageCm_03`);
	};

	// 공유하기
	const handleShare = () => {
		// console.log('공유하기 클릭');
		// showDialog(ShareDialog);
		// const title = '공유하기';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(ShareDialog, {id: `?board=${boardId}` });
		showDialog(ShareDialog, { id: board ? "" : `?board=${boardId}&town=${townId}` });
		// showDialog(ShareDialog, {id: "" });
	};

	// 좋아요
	const handleLike = async (boardId: string | null) => {
		if (!member) {
			// alert('가입한 주민만 좋아요를 할 수 있습니다');
			showDialog(LikeRoleDialog);
			return;
		}
		try {
			const res = await VillageCommuAPI.insertLike({
				...data,
				param: Number(boardId),
			});
			window.location.reload();
			// console.log('좋아요 성공', res);
		} catch (error) {
			const title = '좋아요 실패';
			const message = '좋아요에 실패하였습니다.'
			showDialog(OneButtonAlertDialog, {
				title,
				message
			})
		}
	};

	return (
		<div className="comm_detail_btn mt25">
			<p>
				<input
					type="checkbox"
					id="act01"
					name="act"
					checked={likeCheck}
					onChange={() => handleLike(String(villageCommuDetail?.boardId))}
				/>
				<label htmlFor="act01">
					<span />
					{villageCommuDetail?.likeCnt}
				</label>
			</p>
			<a className="btn_share" onClick={handleShare}>
				공유
			</a>
			<a className="btn_list" onClick={backList}>
				목록
			</a>
		</div>
	);
};

// 댓글
const Comment = ({
	villageCommuDetail,
	boardId,
	member,
	// modifyCmtRoles,
	// deleteCmtRoles,
	privateCmtRoles,
	publicCmtRoles,
	visible,
	amnBtn,
	mstSmtBtn,
	abrMbrBtn,
	cmtWriteRoles,
	cmtUpdRoles,
}: {
	villageCommuDetail: villageCommuContent | undefined;
	boardId: string | null;
	member: MemberInfo | undefined;
	// modifyCmtRoles: boolean,
	// deleteCmtRoles: boolean
	privateCmtRoles: boolean;
	publicCmtRoles: boolean;
	visible: string | null;
	amnBtn: boolean;
	mstSmtBtn: boolean;
	abrMbrBtn: boolean;
	cmtWriteRoles: boolean;
	cmtUpdRoles: boolean;
}) => {
	const { showDialog } = useDialog();
	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<formVillageCommuCmt>();
	const [cmtContentError, setCmtContentError] = useState('');

	// 내용 500자 제한
	const [newContent, setNewContent] = useState<string>('');
	const [modifyContent, setModifyContent] = useState<string>();
	const maxCharacters: number = 500;
	useEffect(() => {
		//console.log("villageCommuDetail >>>>> ", villageCommuDetail)
	}, [villageCommuDetail])

	// 새 댓글 작성시(내용 500자 제한)
	const handleNewContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const newContent: string = event.target.value;

			// 500자 제한
			if (newContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = newContent.slice(0, maxCharacters);
				setNewContent(truncatedContent);
			} else {
				setNewContent(newContent);
			}
		}
	};

	// 댓글 수정시(내용 500자 제한)
	const handleModifyContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const modifyContent: string = event.target.value;

			// 500자 제한
			if (modifyContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = modifyContent.slice(0, maxCharacters);
				setModifyContent(truncatedContent);
				setCommentValue(truncatedContent); // 수정된 내용을 댓글 값으로 설정
			} else {
				setModifyContent(modifyContent);
				setCommentValue(modifyContent); // 수정된 내용을 댓글 값으로 설정
			}
		}
	};

	// 댓글 등록
	const handleCmtWrite: SubmitHandler<formVillageCommuCmt> = (data) => {
		if (!member) {
			const title = '';
			const message = '로그인하고 댓글을 작성하실 수 있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});

			return;
		}
		if (!data.content) {
			setCmtContentError('댓글 내용을 입력해주세요.');
		} else {
			VillageCommuAPI.writeVillageCmt({
				...data,
				boardId: Number(boardId),
			})
				.then((res) => {
					// console.log('마을소통 댓글 등록', res);
					showDialog(CmtSubmitSuccessDialog, {
						title: "댓글 등록"
					});
					setCommentValue('');
				})
				.catch((error) => {
					// console.log('마을소통 댓글 등록 실패', error);
					const log = error.data as { success: boolean; message: string };
					console.log(log);
					showDialog(CmtSubmitFailDialog);
				});
		}
	};

	// 댓글 수정창 열기
	const [openCommentModify, setOpenCommentModify] = useState(true);
	const [openReplyCommentWrite, setOpenReplyCommentWrite] = useState(true);
	const [openReplyCommentModify, setOpenReplyCommentModify] = useState(true);
	const [updateIndex, setUpdateIndex] = useState<number>();
	const [writeReplyIndex, setWriteReplyIndex] = useState<number>();
	const [modifyReplyIndex, setModifyReplyIndex] = useState<number>();
	const [commentValue, setCommentValue] = useState<string>();
	const [replyContent, setReplyContent] = useState<string>('')
	const [modifyReplyContent, seModifyReplyContent] = useState<string>('')
	// 대댓글 등록
	const clickReplyCommentWrite = (idx: number) => {
		setWriteReplyIndex(idx);
		setOpenReplyCommentWrite(true);
		setOpenReplyCommentModify(false);
		setOpenCommentModify(false);
	};
	// 대댓글 수정
	const clickReplyCommentModify = (idx: number, ridx: number, comment: string) => {
		setUpdateIndex(idx)
		setModifyReplyIndex(ridx);
		seModifyReplyContent(comment);
		setOpenReplyCommentModify(true);
		setOpenReplyCommentWrite(false);
		setOpenCommentModify(false);
	};
	// 대댓글 등록창 닫기
	const clickReplyCommentWriteCancle = () => {
		setOpenReplyCommentWrite(false);
	};
	// 대댓글 수정창 닫기
	const clickReplyCommentModifyCancle = () => {
		setOpenReplyCommentModify(false);
	};
	const clickCommentModify = (idx: number, comment: string) => {
		setUpdateIndex(idx);
		setCommentValue(comment);
		setOpenCommentModify(true);
		setOpenReplyCommentModify(false);
		setOpenReplyCommentWrite(false);
	};

	// 댓글 수정창 닫기
	const clickCommentModifyCancle = () => {
		setOpenCommentModify(false);
	};

	// 댓글 수정
	const insertCommentModify = async (
		boardCommentId: number,
		visible?: string
	) => {
		setOpenCommentModify(false);
		if (!commentValue) {
			setCmtContentError('댓글 내용을 입력해주세요.');
		}
		await VillageCommuAPI.updateVillageCmt({
			...data,
			content: String(commentValue),
			visible: String(visible),
			boardCommentId: Number(boardCommentId),
		})
			.then((res) => {
				// console.log('마을소통 댓글 수정', res);
				// navigate(`/commu_02/villageCm_03`)
				showDialog(CmtSubmitSuccessDialog, {
					title: "댓글 수정"
				});
			})
			.catch((error) => {
				// console.log('마을소통 댓글 수정 실패', error);
				showDialog(CmtSubmitFailDialog);
			});
	};

	// 삭제
	const deleteComment = async (boardCommentId: number) => {
		showDialog(CmtDeleteDialog, {
			onComplete(result) {
				// console.log('CmtDeleteDialog result ::::', result);
				if (result === 'yes') {
					VillageCommuAPI.updateVillageCmt({
						...data,
						// content: String(commentValue),
						// visible: String(visible),
						boardCommentId: Number(boardCommentId),
						deletedId: Number(1),
					})
						.then((res) => {
							// console.log('마을소통 댓글 삭제', res);
							// navigate(`/commu_02/villageCm_03`)
							showDialog(CmtDeleteSuccessDialog);
							window.location.reload();
						})
						.catch((error) => {
							// console.log('마을소통 댓글 삭제 실패', error);
							showDialog(CmtDeleteFailDialog);
						});
				}
			},
		});
	};

	// 댓글 공개, 비공개
	const handleCmtPrivate = async (
		boardCommentId: number,
		visible: string,
		comment: string
	) => {
		const modifyCmt = async (newVisible: string) => {
			try {
				const res = await VillageCommuAPI.updateVillageCmt({
					...data,
					visible: newVisible,
					boardCommentId: Number(boardCommentId),
				});


				showDialog(PrivateSuccessDialog);
				window.location.reload();
			} catch (error) {

				showDialog(PrivateFailDialog);
			}
		};

		if (visible === 'Y') {
			showDialog(PrivateDialog, {
				onComplete(result) {
					if (result === 'yes') {
						modifyCmt('N'); // 비공개 처리
						window.location.reload();
					}
				},

			});
		} else if (visible === 'N') {
			showDialog(PublicDialog, {
				onComplete(result) {
					if (result === 'yes') {
						modifyCmt('Y'); // 공개 처리
						window.location.reload();
					}
				},

			});
		}
	};

	// 월과 일이 한 자릿수일 때, 앞에 0을 붙이기
	// const formattedMonth = villageCommuDetail?.boardCommentDTOS.map((list,index) => list.createdAt[2] < 10 ? `0${list.createdAt[2]}` : list.createdAt[2])
	// const formattedMonth = villageCommuDetail?.boardCommentDTOS.map((list, index) => {
	// 	const month = parseInt(list.createdAt[1]);
	// 	return month < 10 ? `0${month}` : month;
	// });
	// const formattedDay = villageCommuDetail?.boardCommentDTOS.map((list, index) => {
	// 	const day = parseInt(list.createdAt[2]);
	// 	return day < 10 ? `0${day}` : day;
	// });

	const formatDate = (createdAt: string[]): string => {
		const [year, month, day, hour, minute, second] = createdAt;

		const formattedMonth = String(month).padStart(2, '0');
		const formattedDay = String(day).padStart(2, '0');

		return `${year}.${formattedMonth}.${formattedDay}`;
	};

	// 대댓글 등록
	const handleReplyCmtWrite = (uid: number) => {
		// if (member?.roles === 'STB') {
		// 	const title = '';
		// 	const message = '마을 주민만 댓글을 작성하실수 있습니다.';
		// 	showDialog(OneButtonAlertDialog, {
		// 		title,
		// 		message,
		// 	});

		// 	return;
		// }
		if (replyContent == '') {
			const title = '답글 등록'
			const message = '답글 내용을 입력해주세요.'
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		} else {
			VillageCommuAPI.writeVillageCmt({
				content: replyContent,
				boardId: Number(boardId),
				pBoardCommentId: uid
			})
				.then((res) => {
					// console.log('마을소통 댓글 등록', res);
					showDialog(CmtSubmitSuccessDialog, {
						title: "답글 등록"
					});
					setCommentValue('');
				})
				.catch((error) => {
					// console.log('마을소통 답글 등록 실패', error);
					showDialog(CmtSubmitFailDialog);
				});
		}
	};
	// 대댓글 수정
	const handleReplyCmtModify = async (
		boardCommentId: number,
		visible?: string
	) => {
		setOpenCommentModify(false);
		if (modifyReplyContent == '') {
			const title = '답글 수정'
			const message = '답글 내용을 입력해주세요.'
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		await VillageCommuAPI.updateVillageCmt({
			content: String(modifyReplyContent),
			visible: String(visible),
			boardCommentId: Number(boardCommentId),
		})
			.then((res) => {
				// console.log('마을소통 댓글 수정', res);
				// navigate(`/commu_02/villageCm_03`)
				showDialog(CmtSubmitSuccessDialog, {
					title: "답글 수정"
				});
			})
			.catch((error) => {
				// console.log('마을소통 댓글 수정 실패', error);
				showDialog(CmtSubmitFailDialog);
			});
	};
	// 대댓글 작성시(내용 500자 제한)
	const handleNewReplyContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const newContent: string = event.target.value;

			// 500자 제한
			if (newContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = newContent.slice(0, maxCharacters);
				setReplyContent(truncatedContent);
			} else {
				setReplyContent(newContent);
			}
		}
	};

	// 대댓글수정시(내용 500자 제한)
	const handleModifyReplyContentChange = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const modifyContent: string = event.target.value;

			// 500자 제한
			if (modifyContent.length > maxCharacters) {
				// 500자를 초과한 경우, 입력값을 500자까지 자름
				const truncatedContent = modifyContent.slice(0, maxCharacters);
				seModifyReplyContent(truncatedContent);
				// setCommentValue(truncatedContent); // 수정된 내용을 댓글 값으로 설정
			} else {
				seModifyReplyContent(modifyContent);
				// setCommentValue(modifyContent); // 수정된 내용을 댓글 값으로 설정
			}
		}
	};

	// 대댓글 삭제
	const deleteReplyComment = async (boardCommentId: number) => {
		showDialog(CmtDeleteDialog, {
			onComplete(result) {
				// console.log('CmtDeleteDialog result ::::', result);
				if (result === 'yes') {
					VillageCommuAPI.updateVillageCmt({
						// content: String(commentValue),
						// visible: String(visible),
						boardCommentId: Number(boardCommentId),
						deletedId: Number(1),
					})
						.then((res) => {
							// console.log('마을소통 댓글 삭제', res);
							// navigate(`/commu_02/villageCm_03`)
							showDialog(CmtDeleteSuccessDialog);
							window.location.reload();
						})
						.catch((error) => {
							// console.log('마을소통 댓글 삭제 실패', error);
							showDialog(CmtDeleteFailDialog);
						});
				}
			},
		});
	};

	// 대댓글 공개, 비공개
	const handleReplyCmtPrivate = async (
		boardCommentId: number,
		visible: string,
	) => {
		const modifyCmt = async (newVisible: string) => {
			try {
				const res = await VillageCommuAPI.updateVillageCmt({
					visible: newVisible,
					boardCommentId: Number(boardCommentId),
				});


				showDialog(PrivateSuccessDialog);
				window.location.reload();
			} catch (error) {

				showDialog(PrivateFailDialog);
			}
		};

		if (visible === 'Y') {
			showDialog(PrivateDialog, {
				onComplete(result) {
					if (result === 'yes') {
						modifyCmt('N'); // 비공개 처리
						window.location.reload();
					}
				},

			});
		} else if (visible === 'N') {
			showDialog(PublicDialog, {
				onComplete(result) {
					if (result === 'yes') {
						modifyCmt('Y'); // 공개 처리
						window.location.reload();
					}
				},

			});
		}
	};

	return (
		<>
			<div className="comm_comment_write mt40">
				<h5>
					댓글&nbsp;<span>({villageCommuDetail?.commentCnt}건)</span>
				</h5>
				{cmtWriteRoles && (
					<form onSubmit={handleSubmit(handleCmtWrite)}>
						<div className="comm_box_write mt25 pa20">
							<textarea
								placeholder={
									!member ? '로그인하고 댓글을 작성하실 수 있습니다.' : '댓글을 입력해주세요.'
								}
								defaultValue={''}
								{...register('content')}
								value={newContent}
								onChange={handleNewContentChange}
							/>
							{cmtContentError && (
								<p className="error" style={{ color: 'red' }}>
									{cmtContentError}
								</p>
							)}
							<div className="write_info mt10">
								<p>
									{newContent.length}/{maxCharacters}
								</p>
								{/* {member?.roles === "AMN" || member?.townDTO.townId === villageCommuDetail?.townId && ( */}
								<button type="submit" className="comm_write_button">
									등록
								</button>
								{/* )} */}
							</div>
						</div>
					</form>
				)}
			</div>
			<div className="comm_comment_list">
				<ul>
					{villageCommuDetail?.boardCommentDTOS.map((cmtList, idx) => (
						<li
							key={idx}
							className={cmtList?.visible === 'N' ? 'mt25 non' : 'mt25'}
						>
							<div className="comm_box_list">
								<div className="info01">
									{/* 레벨별 class "Lv~" */}
									<p
										// className="name Lv1"
										className={
											cmtList && cmtList?.character === 1
												? 'name basic1'
												: cmtList.character === 2
													? 'name basic2'
													: cmtList.character === 3
														? 'name Lv1'
														: cmtList.character === 4
															? 'name Lv2'
															: cmtList.character === 5
																? 'name Lv3'
																: cmtList.character === 6
																	? 'name Lv4'
																	: ''
										}
									>
										{cmtList.nickname}</p>
									{/* <p>{`${moment(cmtList.createdAt).format('yyyy.MM.DD')}`}</p> */}
									{/* <p>
										{cmtList.createdAt[0]}.
										{formattedMonth}.
										{formattedDay}

									</p> */}
									<p>
										{moment(`${cmtList.createdAt[0]}-${cmtList.createdAt[1]}-${cmtList.createdAt[2]}`).format('YYYY.MM.DD')}
										{/* {`${cmtList.createdAt[0]}.${cmtList.createdAt[1] >= 10 ? '' : '0'}${cmtList.createdAt[1]}.${cmtList.createdAt[2] >= 10 ? '' : '0'}${cmtList.createdAt[2]}`} */}
									</p>
									<p style={{ cursor: 'pointer' }} onClick={() => clickReplyCommentWrite(idx)}>답글</p>
									{cmtUpdRoles &&
										(member && member.id == cmtList.createdId && (
											<>
												<a

													onClick={() =>
														clickCommentModify(idx, cmtList.content)
													}
												>
													수정
												</a>
												<a

													onClick={() => deleteComment(cmtList.boardCommentId)}
												>
													삭제
												</a>
											</>
										))}
									{cmtList.visible === 'Y' && privateCmtRoles && (
										<a

											className="txt_blue"
											onClick={() =>
												handleCmtPrivate(
													Number(cmtList.boardCommentId),
													String(cmtList.visible),
													String(cmtList.content)
												)
											}
										>
											비공개
										</a>
									)}
									{cmtList.visible === 'N' && publicCmtRoles && (
										<a

											className="txt_blue"
											onClick={() => {
												handleCmtPrivate(
													Number(cmtList.boardCommentId),
													String(cmtList.visible),
													String(cmtList.content)
												);
											}}
										>
											공개
										</a>
									)}
								</div>
								<h5 className="mt5">
									{cmtList.content &&
										cmtList.content.split('\n').map((item, index) => (
											<React.Fragment key={index}>
												{item}
												<br />

											</React.Fragment>
										))}
									{openCommentModify && (
										<div
											className="comm_box_write pa20"
											style={{ display: updateIndex === idx ? 'block' : 'none' }}
										>
											<textarea
												placeholder="댓글을 입력해주세요."
												defaultValue={''}
												value={commentValue}
												onChange={handleModifyContentChange}
											/>
											{cmtContentError && (
												<p className="error" style={{ color: 'red' }}>
													{cmtContentError}
												</p>
											)}
											<div className="write_info mt10">
												<p>
													{(commentValue && commentValue.length) || 0}/
													{maxCharacters}
												</p>
												<div>
													<button
														type="button"
														className="comm_write_button small_white"
														onClick={clickCommentModifyCancle}
													>
														취소
													</button>
													<button
														type="button"
														className="comm_write_button small_blue"
														onClick={() =>
															insertCommentModify(
																Number(cmtList.boardCommentId),
																String(cmtList.visible)
															)
														}
													>
														수정
													</button>
												</div>
											</div>
										</div>
									)}
									{/* 대댓글  */}
									{openReplyCommentWrite &&
										<>
											<div
												className="replyComm_box_write pa20"
												style={{
													display: writeReplyIndex === idx ? 'block' : 'none',
												}}
											>
												<textarea
													placeholder="답글을 입력해주세요."
													defaultValue={''}
													value={replyContent}
													// onChange={handleModifyContentChange}
													onChange={(e) => handleNewReplyContentChange(e)}
												/>
												{cmtContentError && (
													<p className="error" style={{ color: 'red' }}>
														{cmtContentError}
													</p>
												)}
												<div className="write_info mt10">
													<p>
														{(replyContent && replyContent.length) || 0}/
														{maxCharacters}
													</p>
													<div>
														<button
															type="button"
															className="comm_write_button small_white"
															onClick={clickReplyCommentWriteCancle}
														>
															취소
														</button>
														<button
															type="button"
															className="comm_write_button small_blue"
															onClick={() =>
																handleReplyCmtWrite(
																	Number(cmtList.boardCommentId),
																)
															}
														>
															등록
														</button>
													</div>
												</div>
											</div>
										</>
									}
									{/* 대댓글  */}
									{cmtList.boardCommentDTOS && cmtList.boardCommentDTOS.length !== 0 && cmtList.boardCommentDTOS.map((rList, rIndex) => (
										<>
											<div key={rIndex} className='replyCommentDiv' style={{ marginLeft: '30px' }}>
												<p>➥</p>
												<p
													// className="name Lv1"
													className={
														rList && rList?.character === 1
															? 'name basic1'
															: rList.character === 2
																? 'name basic2'
																: rList.character === 3
																	? 'name Lv1'
																	: rList.character === 4
																		? 'name Lv2'
																		: rList.character === 5
																			? 'name Lv3'
																			: rList.character === 6
																				? 'name Lv4'
																				: ''
													}
												>
													{rList.nickname}</p>
												<p>
													{moment(`${cmtList.createdAt[0]}-${cmtList.createdAt[1]}-${cmtList.createdAt[2]}`).format('YYYY.MM.DD')}
													{/* {`${rList.createdAt[0]}.${rList.createdAt[1] >= 10 ? '' : '0'}${rList.createdAt[1]}.${rList.createdAt[2] >= 10 ? '' : '0'}${rList.createdAt[2]}`} */}
												</p>
												{/* 대댓글 버튼 */}
												{cmtUpdRoles &&
													(member && member.id == rList.createdId && (
														<>
															<a
																onClick={() =>
																	clickReplyCommentModify(idx, rIndex, rList.content)
																}
															>
																수정
															</a>
															<a

																onClick={() => deleteReplyComment(rList.boardCommentId)}
															>
																삭제
															</a>
														</>
													))}
												{rList.visible === 'Y' && privateCmtRoles && (
													<a

														className="txt_blue"
														onClick={() =>
															handleReplyCmtPrivate(
																Number(rList.boardCommentId),
																String(rList.visible),
															)
														}
													>
														비공개
													</a>
												)}
												{rList.visible === 'N' && publicCmtRoles && (
													<a

														className="txt_blue"
														onClick={() => {
															handleReplyCmtPrivate(
																Number(rList.boardCommentId),
																String(rList.visible),
															)
														}}
													>
														공개
													</a>
												)}
											</div>

											<div className={rList.visible == 'Y' ? '' : 'rePlyNon'} style={{ marginLeft: '70px' }}>
												{rList.content.split('\n').map((ritem, rindex) => (
													<React.Fragment key={rindex}>
														{ritem}
														<br />
													</React.Fragment>
												))}
												{/* 대댓글 수정 */}
												{openReplyCommentModify && (
													<div
														className="replyComm_box_write pa20"
														style={{ marginLeft: '0px', display: updateIndex === idx && modifyReplyIndex === rIndex ? 'block' : 'none' }}
													>
														<textarea
															placeholder="댓글을 입력해주세요."
															defaultValue={''}
															value={modifyReplyContent}
															onChange={(e) => handleModifyReplyContentChange(e)}
														/>
														{cmtContentError && (
															<p className="error" style={{ color: 'red' }}>
																{cmtContentError}
															</p>
														)}
														<div className="write_info mt10">
															<p>
																{(modifyReplyContent && modifyReplyContent.length) || 0}/
																{maxCharacters}
															</p>
															<div>
																<button
																	type="button"
																	className="comm_write_button small_white"
																	onClick={clickReplyCommentModifyCancle}
																>
																	취소
																</button>
																<button
																	type="button"
																	className="comm_write_button small_blue"
																	onClick={() =>
																		handleReplyCmtModify(
																			Number(rList.boardCommentId),
																			String(rList.visible)
																		)
																	}
																>
																	수정
																</button>
															</div>
														</div>
													</div>
												)}
											</div>
										</>
									))}
									{/* 접속자 {member?.id}	
							<br/>
							작성자 {cmtList.createdId}	 */}
								</h5>

							</div>

						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default Detail_VillageCommuPage;
