import React, { useEffect, useRef, useState } from 'react';

import Marker1 from '../../marker/mk_1.png';
import Marker2 from '../../marker/mk_2.png';
import Marker3 from '../../marker/mk_3.png';
import Marker4 from '../../marker/mk_10.png';
import Marker5 from '../../marker/mk_6.png';
import myPosition from '../../assets/myPosition.png';

interface KaKaoMapComponentTestProps {
	handleSelectLatLng: (lat: number, lng: number) => void;
}

const KaKaoMapComponentTest = ({
	handleSelectLatLng,
}: KaKaoMapComponentTestProps) => {
	// const mapRef = useRef<HTMLDivElement | null>(null);
	// const markerRef = useRef<kakao.maps.Marker | null>(null);
	// const [myLatLng, setMyLatLng] = useState<kakao.maps.LatLng | null>(null);
	const [offMarker, setOffMarker] = useState([] as any[]);
	const [lat, setLat] = useState('');
	const [lng, setLng] = useState('');

	useEffect(() => {
		// if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
		// if (window.navigator.geolocation) {
		// 	window.navigator.geolocation.getCurrentPosition(
		// 		function (position) {
		// 			var latitude = position.coords.latitude;
		// 			var longitude = position.coords.longitude;
		// 			// console.log("현재 위치:", latitude, longitude);
		// 			const container = document.getElementById('selectMap');
		// 			const options = {
		// 				center: new window.kakao.maps.LatLng(latitude, longitude),
		// 				level: 5,
		// 			};
		// 			handleSelectLatLng(
		// 				// 35.15012924277688,
		// 				// 126.91273155110082
		// 				latitude,
		// 				longitude
		// 			);
		// 			const map = new window.kakao.maps.Map(container, options);
		// 			// 지도 이동 완료 시 이벤트 리스너 등록
		// 			window.kakao.maps.event.addListener(map, 'dragend', function () {
		// 				const center = map.getCenter(); // 지도의 현재 중심 좌표 얻기
		// 				setLat(center.getLat());
		// 				setLng(center.getLng());
		// 				handleSelectLatLng(center.getLat(), center.getLng());
		// 				// //지오코딩 api 사용
		// 				// const geocoder = new window.kakao.maps.services.Geocoder();
		// 				// geocoder.coord2Address(
		// 				// 	center.getLng(),
		// 				// 	center.getLat(),
		// 				// 	function (result: any, status: any) {
		// 				// 		if (status === window.kakao.maps.services.Status.OK) {
		// 				// 			const address = result[0].address.address_name;
		// 				// 			SelectAddress(address);
		// 				// 		} else {
		// 				// 			console.error(
		// 				// 				"주소를 가져오지 못했습니다.",
		// 				// 				status
		// 				// 			);
		// 				// 		}
		// 				// 	}
		// 				// );
		// 			});
		// 			const mobileMoveToCurrentButton = document.querySelector(
		// 				'.mobile_myPositionButton'
		// 			);
		// 			mobileMoveToCurrentButton?.addEventListener('click', () => {
		// 				// alert("외않되");
		// 				map.panTo(new window.kakao.maps.LatLng(latitude, longitude)); // 35.14851596242328, 126.84877575766228
		// 				handleSelectLatLng(
		// 					// 35.15012924277688,
		// 					// 126.91273155110082
		// 					latitude,
		// 					longitude
		// 				);
		// 			});
		// 		},
		// 		function (error) {
		// 			// 위치 정보 가져오기 실패
		// 			console.error('위치 정보를 가져오는 데 실패했습니다.', error);
		// 		}
		// 	);
		// } else {
		// 	// Geolocation을 지원하지 않는 경우
		// 	console.error('Geolocation을 지원하지 않습니다.');
		// }
		// } else {
		// if (window.navigator.geolocation) {
		// Geolocation을 지원하는 경우
		const container = document.getElementById('selectMap');
		// window.navigator.geolocation.getCurrentPosition(
		// function (position) {
		// 위치 정보 가져오기 성공
		// var latitude = position.coords.latitude;
		// var longitude = position.coords.longitude;
		var latitude = 35.1616118196548;
		var longitude = 126.843250495039;
		// console.log("현재 위치:", latitude, longitude);
		// var locPosition = new window.kakao.maps.LatLng(latitude, longitude)
		// 위치 정보를 활용하여 원하는 작업을 수행할 수 있습니다.
		const options = {
			center: new window.kakao.maps.LatLng(
				// 35.15012924277688,
				// 126.91273155110082
				latitude,
				longitude
			),
			level: 5,
		};
		// console.log("options !! ", options);
		handleSelectLatLng(
			// 35.15012924277688,
			// 126.91273155110082
			latitude,
			longitude
		);

		const map = new window.kakao.maps.Map(container, options);
		// 동구청 마커
		// const defaultMarker = new window.kakao.maps.Marker({
		// 	// position: currentLatLng,
		// 	position: new window.kakao.maps.LatLng(
		// 		35.1461417002004,
		// 		126.92309316107
		// 	), // 35.14851596242328, 126.84877575766228
		// });
		// defaultMarker.setMap(map);
		const mobileMoveToCurrentButton = document.querySelector(
			'.mobile_myPositionButton'
		);
		mobileMoveToCurrentButton?.addEventListener('click', () => {
			// alert("외않되");
			map.panTo(new window.kakao.maps.LatLng(latitude, longitude)); // 35.14851596242328, 126.84877575766228
			handleSelectLatLng(
				// 35.15012924277688,
				// 126.91273155110082
				latitude,
				longitude
			);
		});
		window.kakao.maps.event.addListener(map, 'mouseup', () => {
			// console.log(map.getCenter());
			const mapCenter = map.getCenter();
			var marker = new window.kakao.maps.Marker({
				position: new window.kakao.maps.LatLng(
					mapCenter.Ma,
					mapCenter.La
				), // 35.14851596242328, 126.84877575766228
			});
			handleSelectLatLng(mapCenter.Ma, mapCenter.La);
			offMarker.push(marker);
			handleOffMarker(null);
			// marker.setMap(map);
		});
		window.kakao.maps.event.addListener(map, 'zoom_changed', () => {
			// console.log(map.getCenter());
			const mapCenter = map.getCenter();
			var marker = new window.kakao.maps.Marker({
				position: new window.kakao.maps.LatLng(
					mapCenter.Ma,
					mapCenter.La
				), // 35.14851596242328, 126.84877575766228
			});
			handleSelectLatLng(mapCenter.Ma, mapCenter.La);
			offMarker.push(marker);
			handleOffMarker(null);
			// marker.setMap(map);
		});
		// },
		// function (error) {
		// 	// 위치 정보 가져오기 실패
		// 	console.error('위치 정보를 가져오는 데 실패했습니다.', error);
		// }
		// );
		// } else {
		// Geolocation을 지원하지 않는 경우
		console.error('Geolocation을 지원하지 않습니다.');
		// }
		// }

		// let marker: kakao.maps.Marker | null = null;

		// // 카카오 맵 초기화
		// const initMap = () => {
		//   const container = mapRef.current;
		//   const mapOptions = {
		// 	center: new kakao.maps.LatLng(0, 0), // 초기 중심 위치
		// 	level: 8, // 초기 줌 레벨
		//   };
		//   const map = new kakao.maps.Map(container!, mapOptions);

		//   // 실시간 위치 업데이트
		//   const updateLocation = (position: GeolocationPosition) => {
		// 	const { latitude, longitude } = position.coords;
		// 	// console.log("현재위치 좌표", latitude, longitude);

		// 	const currentLatLng = new kakao.maps.LatLng(latitude, longitude);
		// 	setMyLatLng(currentLatLng);
		// 	// console.log("currentLatLng :::::: >>>>> ", currentLatLng);

		// 	// if (!marker) {
		// 	//   // 첫 번째 위치일 때만 마커 생성
		// 	//   marker = new kakao.maps.Marker({
		// 	// 	position: currentLatLng,
		// 	// 	map: map,
		// 	//   });
		// 	//   markerRef.current = marker;
		// 	// } else {
		// 	//   // 이미 생성된 마커의 위치 업데이트
		// 	//   marker.setPosition(currentLatLng);
		// 	// }

		// 	// 지도 중심 위치 설정
		// 	marker = new kakao.maps.Marker({
		// 		position: currentLatLng,
		// 		map: map,
		// 	  });
		// 	map.setCenter(currentLatLng);
		//   };

		//   navigator.geolocation.watchPosition(updateLocation, console.error, {
		// 	enableHighAccuracy: true,
		// 	maximumAge: 30000,
		// 	timeout: 5000,
		//   });
		// };

		// // 카카오 맵 초기화
		// initMap();

		// return () => {
		//   // 컴포넌트 언마운트 시 정리 작업
		//   markerRef.current?.setMap(null);
		// };
	}, []);

	const handleOffMarker = (map: any) => {
		// // console.log("제발돼라", offMarker);
		for (let i = 0; i < offMarker.length; i++) {
			offMarker[i].setMap(map);
		}
	};

	return (
		// <div ref={mapRef} style={{ width: '700px', height: '600px' }} />
		<>
			<div className="selectMapMarker" style={{ width: '100%' }}>
				<div
					id="selectMap"
					style={{
						width: '100%',
						height: '300px',
						borderRadius: '10px',
					}}
				>
					<img
						src={Marker1}
						id="selectMarker"
						style={{
							width: '25px',
							height: '30px',
							position: 'absolute',
							zIndex: '50',
							top: '41%',
							left: '48%',
						}}
					/>
				</div>
			</div>
			{/* <div
				className="mobile_myPositionButton"
				style={{
					top: '75%',
					marginLeft: '93%',
					position: 'absolute',
					zIndex: '2',
					backgroundColor: 'white',
					width: '32px',
					height: '30px',
					borderRadius: '3px',
					boxShadow: 'rgba(0,0,0,0.15) 0px 2px 2px 0px',
					cursor: 'pointer',
				}}
			>
				나중에 주석해제해야됨
				<img
					src={myPosition}
					alt="현 위치"
					style={{
						width: '25px',
						height: '25px',
						marginTop: '2px',
						marginLeft: '4px',
					}}
				/>
			</div> */}
		</>
	);
};
export default KaKaoMapComponentTest;
