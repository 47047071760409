import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import SubLocation from '../../../components/common/SubLocation';
import { useDialog } from '../../../dialog/DialogProvider';
import EduProgram, { ProgramCategory } from '../../../models/EduProgram';
import {
	comm_filter,
	comm_filter2_1,
	comm_filter2_2,
	comm_filter2_3,
	comm_filter2_4,
	comm_filterAll,
} from './comm_filter';
import { useNavigate } from 'react-router';
import PostCodeDialog from '../../../dialog/activeMap/PostCodeDialog';
import camImg from '../../../assets/ic_cam.png';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import EduProgramAPI from '../../../apis/EduProgramAPI';
import moment from 'moment';
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
const Modify_ProgramPage = () => {
	// 교육인원 입력창
	const townId = sessionStorage.getItem('town');
	const sfUid = sessionStorage.getItem('space');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const geocoder = new window.kakao.maps.services.Geocoder();
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [cateList, setCateList] = useState<ProgramCategory>();
	const [selectedcateList, setSelectedcateList] = useState([] as number[]);
	const [selectedCateList_1, setSelectedCateList_1] = useState([] as number[]);
	const [selectedCateList_2, setSelectedCateList_2] = useState([] as number[]);
	const [buttonOn, setButtonOn] = useState(false);
	const [tCateList, setTCateList] = useState([] as number[]);
	const [fCateList, setFCateList] = useState([] as number[]);
	// 실내 실외 Checkbox
	const [inOutCheck, setInOutCheck] = useState<string[]>([]);
	// 참가비용 Radio
	const [costRadio, setCostRadio] = useState<string>('');
	// 교육인원 입력창
	const [inputNumberValue, setInputNumberValue] = useState<number>();
	// 주소검색
	const [address, setAddress] = useState('');
	// 경도 x
	const [lat, setLat] = useState();
	// 위도 y
	const [lng, setLng] = useState();
	const {
		register,
		getValues,
		setValue,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<EduProgram>();
	const eduStartDate = watch('educationStartDate');
	const eduEndDate = watch('educationEndDate');
	const eduStartTime = watch('educationStartTime');
	const eduEndTime = watch('educationEndTime');
	const reStartDateTime = watch('requestStartDateTime');
	const reEndDateTime = watch('requestEndDateTime');
	const memberCount = watch('memberCount');
	const addr = watch('address');
	const addrDetail = watch('addressDetail');
	const fName = watch('facilityName');
	const fDes = watch('facilityDes');
	useEffect(() => {
		EduProgramAPI.getCategory().then((res) => {
			// console.log('카테고리 목록', res);
			setCateList(res);
		});
	}, []);
	useEffect(() => {
		EduProgramAPI.getEduProgramDetail({
			sfUid: Number(sfUid),
		}).then((data) => {

			data.tcategories.map((list, index) => {
				setTCateList((cur) => [...cur, list.scUid]);
			});
			data.fcategories.map((list, index) => {
				setFCateList((cur) => [...cur, list.scUid]);
			});
			setValue(
				'educationStartDate',
				moment(data.educationStartDate).format('YYYY-MM-DD')
			);
			setValue(
				'educationEndDate',
				moment(data.educationEndDate).format('YYYY-MM-DD')
			);
			setValue(
				'educationStartTime',
				moment(data.educationStartTime, 'HH:mm:ss').format('HH:mm')
			);
			setValue(
				'educationEndTime',
				moment(data.educationEndTime, 'HH:mm:ss').format('HH:mm')
			);
			setValue(
				'requestStartDateTime',
				moment(data.requestStartDateTime).format('YYYY-MM-DD')
			);
			setValue(
				'requestEndDateTime',
				moment(data.requestEndDateTime).format('YYYY-MM-DD')
			);
			setInputNumberValue(Number(data.memberCount));
			setValue('memberCount', data.memberCount);
			setValue('address', data.address);

			setAddress(data.address);
			geocoder.addressSearch(data.address, (result: any, status: any) => {
				// console.log('result x>>>', result[0].x);
				// console.log('result y >>>', result[0].y);
				setLat(result[0].x);
				setLng(result[0].y);
			});
			setValue('addressDetail', data.addressDetail);
			setValue('facilityName', data.facilityName);
			setValue('facilityDes', data.facilityDes);
			setValue('educationPlace', data.educationPlace);
			setValue('manager', data.manager);
			setCostRadio(data.costRadio);
			if (data.inOutCheck) {
				const inoutData = data.inOutCheck.split('/');
				// console.log("inoutData >>>>>>>>>> ", inoutData)
				setInOutCheck(inoutData);
			}

			if (data?.filePath) {
				const files: File[] = [];
				const promises: Promise<any>[] = [];
				const promise = EduProgramAPI.getImages({
					savedPath: data.filePath,
				}).then((res) => {
					if (res) {
						var startIndex = data.filePath.lastIndexOf('/');
						var endIndex = data.filePath.lastIndexOf('.png');
						var jpgEndIndex = data.filePath.lastIndexOf('.jpg');
						// console.log("png파일", endIndex)
						// console.log("jpg파일", jpgEndIndex)
						// if (startIndex !== -1 && endIndex !== -1) {
						var result = endIndex !== -1 ? data.filePath.slice(startIndex + 1, endIndex + 4) : data.filePath.slice(startIndex + 1, jpgEndIndex + 4); // 파일명과 확장자를 포함해서 잘라냄
						// console.log("파일명?", result)
						const file = new File([res.data], result, { type: 'image/png' });
						files.push(file);
						// console.log("파일 ㅎㅎ", files); // 결과 출력
						setUploadFiles(files);
						// console.log(result); // 결과 출력
						// } else {
						// // console.log('패턴을 찾을 수 없습니다.');
						// }

						// console.log('파일 ㅋㅋ', res);
						const previewImage = `/api/attach/getFile?savedPath=${data.filePath}`;
						setPreviewImages((prevImages) => [...prevImages, previewImage]);
					}
				});
				promises.push(promise);
			}
		});
	}, []);
	useEffect(() => {
		if (
			eduStartDate &&
			eduEndDate &&
			eduStartTime &&
			eduEndTime &&
			reStartDateTime &&
			reEndDateTime &&
			memberCount &&
			addr &&
			addrDetail &&
			fName &&
			fDes
		) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [
		eduStartDate,
		eduEndDate,
		eduStartTime,
		eduEndTime,
		reStartDateTime,
		reEndDateTime,
		reStartDateTime,
		memberCount,
		addr,
		addrDetail,
		fName,
		fDes,
	]);
	// 교육인원 입력 시 숫자만 입력되게 하는 함수
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자 이외의 문자를 모두 제거
		const sanitizedValue = Number(value.replace(/[^0-9]/g, ''));

		setInputNumberValue(sanitizedValue);
	};
	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);

		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 1개 까지 업로드할수있습니다.');
			const title = '';
			const message = '사진은 최대 1개 까지 업로드할수있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};
	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 주소검색
	const handlePostShearch = () => {
		showDialog(PostCodeDialog, {
			onComplete(address) {
				//! // console.log("address >>>> ", address);
				setAddress(address.roadAddress);
				setValue('address', address.roadAddress);
				geocoder.addressSearch(address.address, (result: any, status: any) => {
					// console.log('result x>>>', result[0].x);
					// console.log('result y >>>', result[0].y);
					setLat(result[0].x);
					setLng(result[0].y);
				});
			},
		});
	};
	// 교육대상 선택한 Checkbox
	const handleChecked_1 = (select: number[]) => {
		// console.log('handleChecked_1 :: ', select);
		setSelectedCateList_1(select);
	};
	// 교육분야 선택한 Checkbox
	const handleChecked_2 = (select: number[]) => {
		// console.log('handleChecked_2 :: ', select);
		setSelectedCateList_2(select);
	};

	useEffect(() => {
		// console.log('selectedCateList1 ::: ', selectedCateList_1);
		// console.log('selectedCateList2 ::: ', selectedCateList_2);
	}, [selectedCateList_1, selectedCateList_2]);
	const onSubmit: SubmitHandler<EduProgram> = async (data) => {
		// console.log('수정 !! ', data);
		// console.log('수정 파일 !! ', uploadFiles);
		const stDt = data.requestStartDateTime;
		const edDt = data.requestEndDateTime;
		const stTime = data.educationStartTime;
		const edTime = data.educationEndTime;
		const startDate = data.educationStartDate;
		const endDate = data.educationEndDate;
		const dateTime = new Date();
		const currentHours = ('0' + dateTime.getHours()).slice(-2); // 현재 시간을 가져옵니다.
		const currentMinutes = ('0' + dateTime.getMinutes()).slice(-2); // 현재 분을 가져옵니다.
		const currentSeconds = ('0' + dateTime.getSeconds()).slice(-2); // 현재 초를 가져옵니다.
		const resultStartDateTime = `${stDt} 00:00:00`;
		const resultEndDateTime = `${edDt} 23:00:00`;
		const resultStartTime = `${stTime}:${currentSeconds}`;
		const resultEndTime = `${edTime}:${currentSeconds}`;
		selectedcateList.push(...selectedCateList_1);
		selectedcateList.push(...selectedCateList_2);
		if (selectedCateList_1.length == 0) {
			const title = '';
			const message = '교육대상 카테고리를 선택해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedCateList_2.length == 0) {
			const title = '';
			const message = '교육분야 카테고리를 선택해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (inOutCheck.length == 0) {
			const title = '';
			const message = '운영형태를 선택해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (costRadio == '') {
			const title = '';
			const message = '참가비용을 선택해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		var inOutString = ''
		if (inOutCheck.some((some) => (inOutCheck.length == 1 && some.trim() == '실내'))) {
			inOutString = '실내';
		} else if (inOutCheck.some((some) => (inOutCheck.length == 1 && some.trim() == '실외'))) {
			inOutString = '실외';
		} else {
			inOutString = '실내 / 실외';
		}
		EduProgramAPI.updateProgram(
			{
				...data,
				address: address,
				requestStartDateTime: resultStartDateTime,
				requestEndDateTime: resultEndDateTime,
				educationStartTime: resultStartTime,
				educationEndTime: resultEndTime,
				tcUid: Number(townId),
				lat: Number(lat),
				lng: Number(lng),
				sfUid: Number(sfUid),
				inOutCheck: inOutString,
				costRadio: costRadio,
			},
			uploadFiles,
			selectedcateList
		)
			.then((res) => {
				// console.log(res);
				const message = '수정되었습니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				navigate('/commu_02/eduProgram_03');
			})
			.catch((error) => {
				return;
			});
	};
	const checkedInOut = (inOrOut: string) => {
		//console.log("받음", inOrOut);
		if (inOutCheck.some((some) => some.trim() == inOrOut)) {
			setInOutCheck(() => {
				return inOutCheck.filter((filter) => filter.trim() !== inOrOut);
			})
			return;
		}
		setInOutCheck((cur) => [...cur, inOrOut]);
	}
	const checkedCost = (costType: string) => {
		setCostRadio(costType);
	}

	const handleCancle = () => {
		navigate('/commu_02/eduProgram_03');
	};
	return (
		<main className="Write_ProgramPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation titleText1="소통마당" liList={SubLocationList} />
				<div className="lnb_txt">
					<h3>교육프로그램 등록하기</h3>
					{/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							{/* 교육대상 체크박스 */}
							<CheckBoxListComponent_1
								tCateList={tCateList}
								cateList={cateList}
								handleChecked_1={handleChecked_1}
							/>
							{/* 교육대상 체크박스 종료*/}

							{/* 교육분야 체크박스 */}
							<CheckBoxListComponent_2
								fCateList={fCateList}
								cateList={cateList}
								handleChecked_2={handleChecked_2}
							/>
							{/* 교육분야 체크박스 종료 */}

							<div className="cont-wrap">
								<h3 className="important">교육기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											{...register('educationStartDate', { required: true })}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											className="endDate"
											{...register('educationEndDate', { required: true })}
										/>
									</div>
									{(errors.educationStartDate || errors.educationEndDate) && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 교육기간을 선택해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">교육시간</h3>
								<div className="cont-box">
									<div className="time_box">
										<input
											type="time"
											data-placeholder="시작일시"
											// min={
											// 	reEndDateTime
											// 		? new Date(reEndDateTime ? reEndDateTime : 0)
											// 			.toISOString()
											// 			.split('T')[0]
											// 		: new Date().toISOString().split('T')[0]
											// }
											{...register('educationStartTime', { required: true })}
										/>
										<input
											type="time"
											data-placeholder="종료일시"
											className="endTime"
											// min={
											// 	reEndDateTime
											// 		? new Date(reEndDateTime ? reEndDateTime : 0)
											// 			.toISOString()
											// 			.split('T')[0]
											// 		: new Date().toISOString().split('T')[0]
											// }
											{...register('educationEndTime', { required: true })}
										/>
									</div>
									{(errors.educationStartTime || errors.educationEndTime) && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 교육시간을 선택해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">신청기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											// min={new Date().toISOString().split('T')[0]}
											{...register('requestStartDateTime', { required: true })}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											className="endDate"
											// min={new Date().toISOString().split('T')[0]}
											{...register('requestEndDateTime', { required: true })}
										/>
									</div>
									{(errors.requestStartDateTime ||
										errors.requestEndDateTime) && (
											<span
												style={{
													color: 'red',
													fontSize: '14px',
												}}
											>
												! 신청기간을 선택해주세요.
											</span>
										)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">교육인원</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="숫자로만 입력하세요"
										value={inputNumberValue}
										{...register('memberCount', { required: true })}
										onChange={handleInputChange}
									/>
									{errors.memberCount && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 교육인원을 입력해주세요.
										</span>
									)}
								</div>
							</div>
							{/* 20240617 김덕현 추가 */}
							<div className="cont-wrap">
								<h3 className="important">운영형태</h3>
								<div className="cont-box">
									<div className="eduPart-box">
										<ul>
											<li>
												<label htmlFor={`inside`}>
													<input
														type="checkbox"
														id={`inside`}
														// checked={selectValue.includes(
														// 	Number(cateList?.fcategoryList[0].scUid)
														// )}
														onChange={() => checkedInOut('실내')}
														checked={inOutCheck.some((some) => some.trim() == '실내')}
													/>
													<span>실내</span>
												</label>
											</li>
											<li>
												<label htmlFor={`outdoor`}>
													<input
														type="checkbox"
														id={`outdoor`}
														// checked={selectValue.includes(
														// 	Number(cateList?.fcategoryList[1].scUid)
														// )}
														onChange={() => checkedInOut('실외')}
														checked={inOutCheck.some((some) => some.trim() == '실외')}
													/>
													<span>실외</span>
												</label>
											</li>
										</ul>
									</div>
									{/* {errors.memberCount && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 교육인원을 입력해주세요.
										</span>
									)} */}
								</div>
							</div>
							{/* 20240617 김덕현 추가 */}
							<div className="cont-wrap">
								<h3 className="important">참가비용</h3>
								<div className="cont-box">
									<div className="eduPart-box">
										<ul>
											<li>
												<label htmlFor={`free`}>
													<input
														type="radio"
														id={`free`}
														name='cost'
														// checked={selectValue.includes(
														// 	Number(cateList?.fcategoryList[0].scUid)
														// )}
														onChange={() => checkedCost('무료')}
														checked={costRadio === '무료'}
													/>
													<span>무료</span>
												</label>
											</li>
											<li>
												<label htmlFor={`pay`}>
													<input
														type="radio"
														id={`pay`}
														name='cost'
														// checked={selectValue.includes(
														// 	Number(cateList?.fcategoryList[1].scUid)
														// )}
														onChange={() => checkedCost('유료')}
														checked={costRadio === '유료'}
													/>
													<span>유료</span>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
							{/* 20240617 김덕현 추가 */}
							<div className="cont-wrap">
								<h3 className="important">교육장소</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="교육장소를 입력하세요"
										{...register('educationPlace', { required: true })}
									/>
									{errors.educationPlace && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 교육장소를 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">주소</h3>
								<div className="cont-box">
									<input
										type="text"
										className="address"
										value={address}
										placeholder="주소를 입력하세요"
										{...register('address', { required: true })}
										disabled
									/>

									<button
										type="button"
										className="btn_address"
										onClick={handlePostShearch}
									>
										주소검색
									</button>
									{errors.address && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 주소를 입력해주세요.
										</span>
									)}
									<input
										type="text"
										className="detail_address"
										placeholder="상세주소를 입력하세요"
										{...register('addressDetail', { required: true })}
									/>
									{errors.addressDetail && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 상세주소를 입력해주세요.
										</span>
									)}
								</div>
							</div>
							{/* 20240617 김덕현 추가 */}
							<div className="cont-wrap">
								<h3 className="important">담당자</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="담당자를 입력하세요"
										{...register('manager', { required: true })}
									/>
									{errors.manager && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 담당자를 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">프로그램명</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="프로그램명을 입력하세요"
										{...register('facilityName', { required: true })}
									/>
									{errors.facilityName && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 프로그램명을 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">프로그램 설명</h3>
								<div className="cont-box">
									<textarea
										placeholder="프로그램 설명을 입력하세요"
										{...register('facilityDes', { required: true })}
									/>
									{errors.facilityDes && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 프로그램 설명을 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3>첨부 이미지<br />(최대 15MB)</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											// {...register('uploadFiles')}
											accept="image/png, image/jpeg"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
							>
								수정
							</button>{' '}
							{/* 모두 체크 시 on클래스 붙음*/}
						</div>
					</form>
				</div>
			</article>
		</main>
	);
};

// 교육대상 컴포넌트
const CheckBoxListComponent_1 = ({
	tCateList,
	cateList,
	handleChecked_1,
}: {
	tCateList?: number[];
	cateList?: ProgramCategory;
	handleChecked_1: (select: number[]) => void;
}) => {
	const [selectValue, setSelectValue] = useState([] as number[]);
	useEffect(() => {
		// console.log('티 카테 >> ', tCateList);
		setSelectValue(tCateList ? tCateList : []);
	}, [tCateList]);
	useEffect(() => {
		handleChecked_1(selectValue);
	}, [selectValue]);

	// 체크박스 체크항목 selectValue State 에 추가 체크해제 시 삭제
	const handleSelectCheckbox = (checked: any) => {
		// console.log(checked.target.value);
		// 제한없음 체크 시 다른 체크항목 전부 체크해제
		if (checked.target.value == 7) {
			setSelectValue([Number(checked.target.value)]);
		}
		// 제한없음 체크해제 또는 다른항목 체크 시 제한없음 체크해제
		if (checked.target.checked) {
			if (checked.target.value != 7) {
				setSelectValue((cur) => [
					...cur.filter((item) => item != 7),
					Number(checked.target.value),
				]);
			}
			// 체크해제
		} else {
			const notChecked = selectValue.filter(
				(item) => item != Number(checked.target.value)
			);
			setSelectValue(notChecked);
		}
	};

	return (
		<div className="cont-wrap">
			<h3 className="important">교육대상</h3>
			<div className="cont-box">
				<ul>
					{cateList?.tcategoryList.map((list, index) => (
						<li key={index}>
							<label htmlFor={`target1-${index}`}>
								<input
									type="checkbox"
									id={`target1-${index}`}
									value={list.scUid}
									checked={selectValue.includes(list.scUid)}
									onChange={handleSelectCheckbox}
								/>
								<span>{list.categoryTypeCode}</span>
							</label>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
// 교육대상 컴포넌트
const CheckBoxListComponent_2 = ({
	fCateList,
	cateList,
	handleChecked_2,
}: {
	fCateList: number[];
	cateList?: ProgramCategory;
	handleChecked_2: (select: number[]) => void;
}) => {
	const [selectValue, setSelectValue] = useState([] as number[]);
	useEffect(() => {
		// console.log('에프 카테 >> ', fCateList);
		setSelectValue(fCateList ? fCateList : []);
	}, [fCateList]);
	useEffect(() => {
		handleChecked_2(selectValue);
	}, [selectValue]);

	// 체크박스 체크항목 selectValue State 에 추가 체크해제 시 삭제
	const handleSelectCheckbox = (checked: any) => {
		// // console.log(checked.target.checked);
		if (checked.target.checked) {
			setSelectValue((cur) => [...cur, Number(checked.target.value)]);
			// 체크해제
		} else {
			const notChecked = selectValue.filter(
				(item) => item !== Number(checked.target.value)
			);
			setSelectValue(notChecked);
		}
	};
	return (
		<div className="cont-wrap">
			<h3 className="important">교육분야</h3>
			<div className="cont-box">
				<ul>
					{cateList?.fcategoryList.map((list, index) => (
						<li>
							<label htmlFor={`part1-${index}`}>
								<input
									type="checkbox"
									id={`part1-${index}`}
									value={list.scUid}
									checked={selectValue.includes(list.scUid)}
									onChange={handleSelectCheckbox}
								/>
								<span>{list.categoryTypeCode}</span>
							</label>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
export default Modify_ProgramPage;
