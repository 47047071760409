import React, { useState } from 'react';
import LocationDialog from '../activeMap/LocationDialog';
import { DialogProps, useDialog } from '../DialogProvider';
import '../MissionYard/Write_MapMissionDialog.scss';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import ic_cam from '../../assets/ic_cam.png'
import { Content } from '../../models/MissionInfoDetail';
import { SubmitHandler, useForm } from 'react-hook-form';
import MissionAPI from '../../apis/MissionAPI';
import useMember from '../../hooks/useMember';
import MissionInfo from '../../models/MissionInfo';

// 지도미션 > 인증하기
interface Write_MapMissionDialog extends DialogProps {
	missionDetail: MissionInfo | undefined;
}

const Write_MapMissionDialog = ({ missionDetail, ...props }: Write_MapMissionDialog) => {
	const { showDialog } = useDialog();

	const handleClose = () => {
		props.onClose?.();
		return;
	};
	const { register, handleSubmit, getValues, setValue, watch, setFocus } =
		useForm<Content>();
	const { member } = useMember();

	const geocoder = new window.kakao.maps.services.Geocoder();
	const [selectLat, setSelectLat] = useState<number>();
	const [selectLng, setSelectLng] = useState<number>();
	// 주소검색
	const [address, setAddress] = useState('');
	// 경도 x
	const [lat, setLat] = useState();
	// 위도 y
	const [lng, setLng] = useState();
	const [updateStatus, setUpdateStatus] = useState(false);

	// 필수값 미입력 에러 메시지
	const [locInfo, setLocInfo] = useState('');
	const [cmcUid, setCmcUid] = useState('');
	const [certCn, setCertCn] = useState('');

	// console.log("인증하기", missionDetail)

	// 위치찾기
	const handleSearch = () => {
		showDialog(LocationDialog, {
			// handleAddressSubmit(address) {
			// 	setAddress(address);
			// },
			handleAddressSubmit(lat: number, lng: number) {
				setSelectLat(lat);
				setSelectLng(lng);
				// // console.log("주소입력 다이얼로그 lat", lat);
				// // console.log("주소입력 다이얼로그 lng", lng);
				// 좌표를 주소로 변환
				geocoder.coord2Address(lat, lng, (result: any, status: any) => {
					if (status === window.kakao.maps.services.Status.OK) {
						if (result[0]) {
							const address = result[0].address.address_name;
							// // console.log("주소:", address);
							setAddress(address);
						} else {
							// // console.log("주소를 찾을 수 없습니다.");
						}
					} else {
						// // console.log("Geocoder 실패:", status);
					}
				});
			},
		});
	};

	// 이미지 파일 첨부
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log("files ::::::: ", event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 3) {
			// alert('사진은 최대 5개 까지 업로드할수있다.');
			const title = '사진 첨부';
			const message = '사진은 최대 3장까지 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log("imagesToSubmit ::::::::::::::::: ", uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 3);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 등록
	const onSubmit: SubmitHandler<Content> = async (data) => {
		// console.log('uploadFiles ::::::', uploadFiles);
		// console.log('data::::::::::::', data);

		if (!address) {
			setLocInfo("주소를 입력하세요.");
			setFocus('locInfo');
			return
		}
		if (!data.cmcUid) {
			setCmcUid("분류를 선택하세요.");
			setFocus('cmcUid');
			return
		}
		if (!data.certCn) {
			setCertCn("내용을 입력하세요.");
			setFocus('certCn');
			return
		}


		MissionAPI.insertCert2(
			{
				mmiUid: missionDetail?.mmiUid,
				tcUid: member?.townDTO?.townId,
				accmlPt: Number(missionDetail?.pymntPt),
				certProgrsSt: '신청',
				locInfo: address,
				subLocInfo: data.subLocInfo !== null && data.subLocInfo !== undefined ? data.subLocInfo : undefined,
				cntrLat: selectLat || 0,
				cntrLng: selectLng || 0,
				cmcUid: data.cmcUid,
				certCn: data.certCn || null || undefined,
			},
			uploadFiles
		)
			.then((res) => {
				const title = '이미지 인증하기';
				// const message = `인증을 완료해 ${missionDetail?.pymntPt
				// 	.toString()
				// 	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				// 	포인트가 적립되었습니다`;

				const message = `인증을 완료해 ${missionDetail?.pymntPt} 포인트가 적립되었습니다`;

				showDialog(OneButtonAlertDialog, {
					title,
					message,
					handleDialogResult(result) {
						// console.log('result::::', result);

						if (result === 'ok') {
							// console.log('rerer');
							window.location.reload();
							setUpdateStatus(!updateStatus);
						}
					},
				});
			})
			.catch((e) => {
				const title = '지도미션 인증하기';
				const message = '인증을 실패하였습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
					handleDialogResult(result) {
						if (result === 'ok') {
							setUpdateStatus(!updateStatus);
						}
					},
				});
			});

	};

	return (
		<div className="comm_popup Write_MapMissionDialog" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30" style={{ maxWidth: '800px' }}>
				<div className="comm_popup_title">
					<h3>지도 인증하기</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="comm_popup_content comm_write_wrap mt25">
						<div className="cont-wrap">
							<h3 className="important">위치</h3>
							<div className="cont-box">
								<input
									type="text"
									className="address"
									placeholder="주소를 입력하세요"
									value={address}
								/>
								<button type="button" className="btn_address" onClick={handleSearch}>
									위치찾기
								</button>
								{locInfo && (
									<p className="error" style={{ color: 'red' }}>
										{locInfo}
									</p>
								)}
								<input
									type="text"
									className="detail_address"
									placeholder="상세주소를 입력하세요"
									{...register('subLocInfo')}
								/>
							</div>
						</div>
						<div className="cont-wrap">
							<h3 className="important">분류</h3>
							<div className="cont-box">
								<div className="select-box">
									<select {...register('cmcUid')}>
										{missionDetail?.categorys?.map((list, index) => (
											<option
												value={list.cmcUid}
												key={index}
												selected={index === 0}
											>{list.title}</option>

										))}
									</select>
								</div>
								{cmcUid && (
									<p className="error" style={{ color: 'red' }}>
										{cmcUid}
									</p>
								)}
							</div>
						</div>
						<div className="cont-wrap">
							<h3 className="important">내용</h3>
							<div className="cont-box">
								<textarea placeholder="내용을 입력하세요"
									{...register('certCn')} ></textarea>
							</div>
							{certCn && (
								<p className="error" style={{ color: 'red' }}>
									{certCn}
								</p>
							)}
						</div>
						<div className="cont-wrap">
							<h3>첨부 이미지<br />(최대 15MB)</h3>
							<div className="cont-box">
								<div className="imgBox">
									<input className="upload-photo" />
									<label htmlFor="img">
										<img src={ic_cam} alt="사진 첨부" />
										사진 첨부
										<span>(최대 3장)</span>
									</label>
									<input type="file" id="img"
										accept="image/*" onChange={handleImageChange}
									/>
								</div>
								<div className="imglistBox">
									<ul className="no-scroll">
										{previewImages.map((imageUrl, index) => (
											<li key={index}>
												<img src={imageUrl} key={imageUrl} />
												<button
													type="button"
													className="btn-file-delet"
													onClick={() => handleRemovePreview(index)}
												/>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="comm_popup_btn mt25">
						<button type="button" className="btn_cancel" onClick={handleClose}>
							취소
						</button>
						<button type="submit" className="btn_check">
							등록
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Write_MapMissionDialog;
