import React, { useEffect, useState } from 'react';
import { DialogProps } from '../DialogProvider';
import './SelectDialog.scss';
interface SelectDialogDialogProps extends DialogProps {
    title: string;
    message?: string;
    action?: string;
    handleDialogResult: (result?: string, etcReason?: string) => void;
}
const SelectDialog = ({
    message,
    title,
    action,
    handleDialogResult,
    ...props
}: SelectDialogDialogProps) => {

    const handleClose = () => {
        props.onClose?.();
    };

    const [reason, setReason] = useState('');   //  사유
    const [etcBtn, setEtcBtn] = useState(false);    // 기타 사유
    const [etcReason, setEtcReason] = useState(''); // 기타 사유 내용
    // const [cancel, setCancel] = useState(false);    // 거절
    // const [ok, setOk] = useState(false);    // 이용완료


    const handleSubmit = () => {
        // console.log('셀렉트 다이얼로그 action', action);
        if (action === '예약거절') {
            if (reason !== "기타") {
                setReason('');
            }
            handleDialogResult(reason, etcReason);
        } else if (action === '완료') {
            handleDialogResult(reason, etcReason);
        }
    }

    const handleRadioChange = (e: any) => {
        const selectedValue = e.target.value;

        if (selectedValue === '기타') {
            setEtcBtn(true);
            setReason(selectedValue);
        } else {
            setEtcBtn(false);
            setReason(selectedValue);
        }
    };

    const handleEtcReason = (e: any) => {
        const value = e.target.value;
        setEtcReason(value);
    }


    return (
        <div className="comm_popup" style={{ display: 'block' }}>
            <div className="comm_popup_wrap pa30">
                <div className="comm_popup_title">
                    <h3>상태 변경</h3>
                </div>
                <div className="comm_popup_content mt10">
                    <p className="explain">
                        {message}
                    </p>
                    <div className="state_list mt25">
                        {/* 거절 */}
                        {action === "예약거절" && (
                            <ul>
                                <li>
                                    <label htmlFor="radio1-5">
                                        <input type="radio" id="radio1-5" name="radio1" value="연락두절(노쇼)"
                                            onChange={handleRadioChange}
                                        />
                                        <p>연락두절(노쇼)</p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="radio1-6">
                                        <input type="radio" id="radio1-6" name="radio1" value="자리부족"
                                            onChange={handleRadioChange}
                                        />
                                        <p>자리부족</p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="radio1-7">
                                        <input type="radio" id="radio1-7" name="radio1" value="기타"
                                            onChange={handleRadioChange} />
                                        <p>
                                            기타
                                            {etcBtn && (
                                                <input type="text" placeholder="기타 사유를 입력해주세요." className="pa20 mt10"
                                                    onChange={handleEtcReason} />
                                            )}
                                        </p>
                                    </label>
                                </li>
                            </ul>
                        )}

                        {/* 완료*/}
                        {action === "완료" && (
                            <ul>
                                <li>
                                    <label htmlFor="radio1-5">
                                        <input type="radio" id="radio1-5" name="radio1" value="이용완료"
                                            onChange={handleRadioChange}
                                        />
                                        <p>이용완료</p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="radio1-6">
                                        <input type="radio" id="radio1-6" name="radio1" value="연락두절(노쇼)"
                                            onChange={handleRadioChange}
                                        />
                                        <p>연락두절(노쇼)</p>
                                    </label>
                                </li>
                                <li>
                                    <label htmlFor="radio1-7">
                                        <input type="radio" id="radio1-7" name="radio1" value="대기"
                                            onChange={handleRadioChange}
                                        />
                                        <p>대기</p>
                                    </label>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <div className="comm_popup_btn mt25">
                    <button type="button" className="btn_cancel" onClick={handleClose}>취소</button>
                    <button type="submit" className="btn_check" onClick={handleSubmit} >확인</button>
                </div>
            </div>
        </div>

    );
};

export default SelectDialog;
