import React, { useState } from 'react';
import './SearchMemberDialog.scss';
import Pagination from '../../components/common/Pagination';
import { useDialog } from '../DialogProvider';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
const SearchMemberDialog = ({ ...props }) => {
	const { showDialog } = useDialog();
	const handleClose = () => {
		props.onClose?.();
	};
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const handleClick = () => {
		const title = '받는사람 찾기';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30" style={{ maxWidth: '800px' }}>
				<div className="comm_popup_title">
					<h3>받는사람 찾기</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>
				<form>
					<div className="comm_search mt10">
						<div className="comm_search_plus">
							<input type="text" placeholder="닉네임을 입력해주세요." />
						</div>
						<button type="button">검색</button>
					</div>
					<div className="comm_popup_content nickname_talbe mt25">
						<ul className="thead pa20">
							<li>선택</li>
							<li>닉네임</li>
						</ul>
						<ul className="tbody">
							<li className="pa20">
								<p>
									<label htmlFor="radioNick-1">
										<input type="radio" id="radioNick-1" name="Nick-1" />
									</label>
								</p>
								<p className="txt_cut1">도토리</p>
							</li>
							<li className="pa20">
								<p>
									<label htmlFor="radioNick-2">
										<input type="radio" id="radioNick-2" name="Nick-1" />
									</label>
								</p>
								<p className="txt_cut1">도토리</p>
							</li>
							<li className="pa20">
								<p>
									<label htmlFor="radioNick-3">
										<input type="radio" id="radioNick-3" name="Nick-1" />
									</label>
								</p>
								<p className="txt_cut1">도토리</p>
							</li>
							<li className="pa20">
								<p>
									<label htmlFor="radioNick-4">
										<input type="radio" id="radioNick-4" name="Nick-1" />
									</label>
								</p>
								<p className="txt_cut1">도토리</p>
							</li>
							<li className="pa20">
								<p>
									<label htmlFor="radioNick-5">
										<input type="radio" id="radioNick-5" name="Nick-1" />
									</label>
								</p>
								<p className="txt_cut1">도토리</p>
							</li>
						</ul>
					</div>
					<Pagination
						page={Number(page)}
						totalPageCount={Number(totalPage)}
						// totalPageCount={Math.ceil(count / 8)}
						onPageChange={handlePageChange}
					/>
					<div className="comm_popup_btn mt25">
						<button type="button" className="btn_cancel">
							취소
						</button>
						<button type="submit" className="btn_check" onClick={handleClick}>
							선택
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SearchMemberDialog;
