import React, { useEffect, useState } from 'react';
import '../missionYard/List_ChallengePage.scss';
import SubLocation from '../../components/common/SubLocation';
import Pagination from '../../components/common/Pagination';
import { useNavigate } from 'react-router-dom';
import { ChallengeContent } from '../../models/ChallengeInfo';
import ChallengeAPI from '../../apis/ChallengeAPI';
import moment from 'moment';
import noList from '../../assets/noList.png';
import useMember from '../../hooks/useMember';
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/myEPoint', text: '내 E 포인트' },
];

// 챌린지 목록
const List_ChallengePage = () => {
	const { member } = useMember();
	const navigate = useNavigate();
	const [count, setCount] = useState(0);
	const [challengeList, setChallengeList] = useState([] as ChallengeContent[]);
	const [page, setPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState(0);

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	// 챌린지 등록
	const handleWrite = () => {
		navigate('/reward_02/challenge_04-write');
	};

	// 챌린지 디테일
	const handleDetail = (mciUid: string, mypoint: string, rank: string) => {
		navigate('/reward_02/challenge_05-detail');
		sessionStorage.setItem('mciUid', String(mciUid));
		sessionStorage.setItem('mypoint', String(mypoint));
		sessionStorage.setItem('rank', String(rank));
	};

	// 챌린지 목록 조회
	useEffect(() => {
		ChallengeAPI.getChallengeList().then((res) => {
			setChallengeList(res.content);
			setTotalPage(res.totalPages);
		});
	}, [page]);

	// console.log('챌린지 목록-->', challengeList);

	return (
		<main className="List_ChallengePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 챌린지"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>에너지전환 챌린지</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>목표 포인트를 모아 추가 포인트를 획득하는 챌린지에 참여하세요.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="comm_tool mb25">
						{member && member?.roles === 'AMN' && (
							<div className="box_comm_write_button">
								<button
									type="button"
									className="comm_write_button"
									onClick={handleWrite}
								>
									챌린지 만들기
								</button>
							</div>
						)}
					</div>
					{challengeList && challengeList.length == 0 ? (
						<>
							{' '}
							<div className="comm_noList">
								<img src={noList} alt="내용없음 이미지" />
								<p className="mt25">작성된 게시글이 없습니다.</p>
							</div>
						</>
					) : (
						<>
							<div className="allList_list mt25">
								<ul>
									{/* 비공개 non  */}
									{challengeList &&
										challengeList.map((list, index) => (
											<li
												className={list.openYn === 'N' ? 'non' : ''}
												key={index}
												onClick={() =>
													handleDetail(
														String(list?.mciUid),
														String(list?.myPoint),
														String(list?.rank)
													)
												}
											>
												<a className="pa30">
													<div className="box_challenge">
														<div className="state">
															{/* 23.10.23 기준 - 참여하기 가능하게 되면 상태 추가할 예정임 */}
															{/* [상태]
										대기: 챌린지 기간이 도래하지 않은 상태
										참여가능: 진행중 상태1, 로그인한 회원 또는 비회원이 참여하지 않았고 자리가 남았을 경우 표시 + 참여중/참여가능 인원 수 표시
										참여중: 진행중 상태2, 로그인한 회원이 해당 챌린지에 참여중일 경우 표시 + 인원 수 표시
										참여마감: 진행중 상태3, 로그인한 회원 또는 비회원이 참여하지 않았고 남은 자리가 없을 경우 표시 + 인원 수 표시
										종료: 종료 상태1, 로그인한 회원 또는 비회원이 참여하지 않았고 챌린지 기간이 종료 + 인원수
										최종 n위: 종료 상태2, 로그인한 회원이 챌린지에 참여했고 참여한 사람이 포인트 지급 기준 순위를 달성한 상태 + 인원 수 표시
										달성 실패: 종료 상태3, 로그인한 회원이 챌린지에 참여했고 참여한 사람이 포인트 지급 기준을 달성하지 못한 상태 + 인원 수 표시

										상태 우선으로 최신등록순으로 정렬
										(참여중-참여가능-대기-참여마감-최종n위-달성실패-종료 순) */}
															<span
																className={`${list.prgrsSt === '진행' ? 'ing' : ''
																	}`}
															>
																{list.prgrsSt}
															</span>
															<span className="state_number">
																{list.participateCnt} /{' '}
																{list.mxJoinMbr != 0
																	? list.mxJoinMbr
																	: '제한없음'}
															</span>
														</div>
														<h4 className="comm_title txt_cut1 mt25">
															{list.challengeName}
														</h4>
														<p className="mt5">
															{`${moment(list.startDt).format('YYYY.MM.DD')}`}
															{'~'}
															{`${moment(list.endDt).format('YYYY.MM.DD')}`}
														</p>
														<div className="allRanking pa20 mt10">
															<h5>
																수행할 미션 (
																{list.goalPt
																	.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
																E)
															</h5>

															{list.missionInfos
																.slice(0, 3)
																.map((mission, idx2) => (
																	<p
																		className={`${list.missionInfos.some(
																			(villageList) =>
																				villageList.mssnDe === '마을미션'
																		)
																				? 'villageMission txt_cut1'
																				: 'basicMission txt_cut1'
																			}`}
																	>
																		{mission.mssnNm}
																	</p>
																))}
														</div>
													</div>
												</a>
											</li>
										))}
								</ul>
							</div>{' '}
						</>
					)}

					<Pagination
						page={Number(page)}
						totalPageCount={Number(totalPage)}
						// totalPageCount={Math.ceil(count / 8)}
						onPageChange={handlePageChange}
					/>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default List_ChallengePage;
