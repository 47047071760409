import React from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import CloseIcon from '../../assets/ic_close.png';
import { DialogProps, useDialog } from '../DialogProvider';
import './Write_ActiveMapDialog.scss';

const postCodeStyle: React.CSSProperties = {
	display: 'block',
	width: '100%',
	height: '600px',
};

interface PostCodeDialogProps extends DialogProps {
	onComplete: (address: Address) => void;
}

const PostCodeDialog = ({ onComplete, ...props }: PostCodeDialogProps) => {
	const handlePostCode = (data: Address) => {
		onComplete(data);
		props.onClose?.();
	};

	const handleClose = () => {
		props.onClose?.();
	};

	return (
		<div className="comm_popup" {...props}>
			<div
				className="comm_popup_wrap pa30"
				style={{
					overflowY: 'visible',
					width: '640px',
					height: '680px',
				}}
			>
				<div className="comm_popup_title">
					<h3>주소검색</h3>
					<a className="popup_close" onClick={handleClose}>
						<img src={CloseIcon} alt="닫기" />
					</a>
				</div>
				<DaumPostcode
					style={postCodeStyle}
					onComplete={handlePostCode} // 값을 선택할 경우 실행되는 이벤트
					autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
				/>
			</div>
		</div>
	);
};

export default PostCodeDialog;
