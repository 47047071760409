import React, { useEffect, useState } from 'react';
import '../MissionYard/MapInfoDialog.scss';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import errorImg from '../../assets/errorImg.jpg';
import moment from 'moment';
import { DialogProps } from '../DialogProvider';
import MissionInfo from '../../models/MissionInfo';
import noList from '../../assets/noList.png';
import { Content, RankContent } from '../../models/MissionInfoDetail';
import MissionAPI from '../../apis/MissionAPI';

// 지도미션 > 지도미션 정보
interface MapInfoDialog extends DialogProps {
	missionDetail: MissionInfo | undefined;
	certData: Content[] | undefined,
}

const MapInfoDialog = ({
	missionDetail,
	certData,
	...props }: MapInfoDialog) => {
	const handleClose = () => {
		props.onClose?.();
	};
	const [tab, setTab] = useState<number>(1);
	const [rankData, setRankData] = useState([] as RankContent[]);	// 순위 데이터
	const mmiUid = sessionStorage.getItem('mmiUid');

	// 순위 정렬 (주간, 월간, 연간)
	const [sort, setSort] = useState<string>('주간');

	// console.log("지도미션 팝업",missionDetail);
	// console.log("지도미션 팝업 - 인증",certData);
	// // console.log("지도미션 팝업 - 순위",rankData);
	// // console.log("지도미션 팝업 - sort",sort);

	const handleChageSort = (sort: string) => {
		setSort(sort);
	}

	// 미션 상세조회
	useEffect(() => {
		MissionAPI.getMissionDetail({
			mmiUid: Number(mmiUid),
			period: sort,
			// cert_page: page-1,
			// cert_size: 10
			past: sort === '주간' ? '저번주' : undefined,
		}).then((res) => {
			// console.log("랭킹", res.rankings.content);
			// setMissionDetail(res.missionInfo)
			setRankData(res.rankings.content)
			// setCertData(res.missionCerts.content)
		})
	}, [sort])


	return (
		<div className="comm_popup MapInfoDialog" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30" style={{ maxWidth: '800px' }}>
				<div className="comm_popup_title">
					<h3>지도미션 정보</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>
				<div className="comm_popup_content mt25">
					{/* 타이틀 시작 */}
					<div className="comm_detail_cont pa20">
						<p className="comm_title">{missionDetail?.mssnNm}</p>
					</div>
					{/* 타이틀 종료 */}
					{/* 정보 시작 */}
					<div className="comm_mission pa20">
						<ul>
							<li>
								{/* 인증방법 */}
								<p className="way">지도인증</p>
							</li>
							<li>
								{/* 인증기간 */}
								<p className="period">
									{`${moment(missionDetail?.startDt).format('yyyy.MM.DD')}`}
									{'~'}
									{`${moment(missionDetail?.endDt).format('yyyy.MM.DD')}`}
								</p>
							</li>
							<li>
								{/* 지급 포인트 */}
								<p className="point">{missionDetail?.pymntPt?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} E</p>
							</li>
							<li>
								{/* 참여 횟수 */}
								<p className="number">
									{
										missionDetail?.certPdDe === "D" ? "매일 " :
											missionDetail?.certPdDe === "M" ? "매달 " :
												missionDetail?.certPdDe === "P" ? "기간 내 " : ""
									}
									<span>{missionDetail?.mxCertCo}</span>회 참여 가능
								</p>
							</li>
							<li>
								{/* 미션설명 */}
								<p className="cont">
									{missionDetail?.mssnDtl}
								</p>
							</li>
						</ul>
						{missionDetail?.missionFiles.some((list) => list.imgGb === "미션이미지") ? (
							missionDetail.missionFiles
								.filter((list) => list.imgGb === "미션이미지")
								.map((list2) => (
									<img
										className="mt25"
										key={list2.savePath}
										src={`/api/attach/getFile?savedPath=${list2.savePath}`}

									/>
								))
						) : ""}

						<span className="explain mt10" style={{ color: "red" }}>
							<br />※ 인증 완료 시 즉시 승인되며, 이후 관리자 확인을 통해
							잘못된 인증은 승인 취소 및 포인트 반환될 수 있습니다.
						</span>

					</div>
					{/* 정보 종료 */}
					{/* 탭 시작 */}
					<div className="tabs mt25">
						<button
							type="button"
							// className="tab tab_active"
							className={tab === 1 ? 'tab tab_active' : 'tab non_active'}
							onClick={() => setTab(1)}
						>
							방법
						</button>
						<button
							type="button"
							className={tab === 2 ? 'tab tab_active' : 'tab non_active'}
							onClick={() => setTab(2)}
						>
							순위
						</button>
					</div>
					{/* 탭 종료 */}

					{/* 탭(방법내용) 시작 */}
					{tab === 1 && (
						<div className="tab_content mt25">
							<div className="mission_cont_wrap">
								<div className="mission_cont_title">
									<h3 className="comm_title">실천인증 방법</h3>
								</div>
								<div className="mission_cont_content mt10">
									<p>
										{missionDetail?.certDes}
									</p>
								</div>
							</div>

							{(missionDetail?.certLnk != null || missionDetail?.missionFiles.some((list) => list.imgGb === "인증예시")) && (
								<div className="mission_cont_wrap mt25">
									<div className="mission_cont_title">
										<h3 className="comm_title">실천인증 예시</h3>
									</div>
									<div className="mission_cont_content mt10">
										{/* <p>
										실천인증 예시는 어쩌고 저쩌고 길게 작성을 할 예정입니다 길게
										작성해서 두 줄 이상이 될 수 있습니다. 감안해주세요~ 더 길게
										적어보겠습니다 디자인에도 반영을 해야하니까요... 실제론 이런
										데이터를 넣어서는 안돼요... 저는 디자인이니까 적을게요....
									</p> */}
										{missionDetail?.certLnk != null &&
											<a href={missionDetail?.certLnk} target="_blank">{missionDetail?.certLnk}</a>
										}

										{missionDetail?.missionFiles
											.filter((list) => list.imgGb === "인증예시")
											.map((list, index) => (
												<img
													key={index}
													src={
														list.savePath
															? `/api/attach/getFile?savedPath=${list.savePath}`
															: errorImg
													}
												/>
											))}
									</div>
								</div>

							)}
						</div>
					)}

					{/* 탭(방법내용) 종료 */}
					{/* 탭(순위내용) 시작 */}
					{tab === 2 && (
						<div className="tab_content mt25">
							<div className="mission_cont_wrap mt25">
								<div className="mission_cont_title">
									<h3 className="comm_title">마을순위</h3>
									<ul>
										<li>
											<p className={`${sort === '주간' && 'on'}`}
												onClick={() => handleChageSort("주간")}
												style={{ cursor: 'pointer' }}
											>주간</p>
										</li>
										<li>
											<p className={`${sort === '월간' && 'on'}`}
												onClick={() => handleChageSort("월간")}
												style={{ cursor: 'pointer' }}
											>월간</p>
										</li>
										<li>
											<p className={`${sort === '연간' && 'on'}`}
												onClick={() => handleChageSort("연간")}
												style={{ cursor: 'pointer' }}
											>연간</p>
										</li>
									</ul>
								</div>

								{rankData && rankData.length === 0 ?
									<div className="comm_noList">
										<img src={noList} alt="내용없음 이미지" />
										<p className="mt25">해당 기간에 미션 참여자가 없습니다.</p>
									</div>
									:
									<div className="mission_cont_content mt25">
										<div className="ranking_list">
											{/* 1등 */}
											{rankData && rankData.length > 0 && (
												<p>
													<span className="num">
														<img src={ic_rank01} alt="랭킹 아이콘" />
													</span>
													<span className="villname">{rankData[0]?.town.townName} <strong>
														({rankData[0] && rankData[0]?.town && rankData[0]?.town.districts &&
															rankData[0]?.town.districts.map((list, index) => list.districtName).join(", ")})
													</strong></span>
													<span className="point">{rankData[0]?.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} E</span>
												</p>
											)}

											{/* 2등   */}
											{rankData && rankData.length > 1 && (
												<p>
													<span className="num">
														<img src={ic_rank02} alt="랭킹 아이콘" />
													</span>
													<span className="villname">{rankData[1]?.town.townName} <strong>
														({rankData[1]?.town.districts.map((list, index) => list.districtName).join(", ")})
													</strong></span>
													<span className="point">{rankData[1]?.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} E</span>
												</p>
											)}

											{/* 3등 */}
											{rankData && rankData.length > 2 && (
												<p>
													<span className="num">
														<img src={ic_rank03} alt="랭킹 아이콘" />
													</span>
													<span className="villname">{rankData[2]?.town.townName} <strong>
														({rankData[2]?.town.districts.map((list, index) => list.districtName).join(", ")})
													</strong></span>
													<span className="point">{rankData[2]?.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} E</span>
												</p>
											)}

											{/* 4 ~ 10등 */}
											{rankData && rankData.slice(3, 10).map((list, index) => (
												<p key={index}>
													<span className="num">{index + 4}</span>
													<span className="villname">{list.town.townName} <strong>
														({list.town.districts.map((list2, index) => list2.districtName).join(", ")})
													</strong></span>
													<span className="point">{list.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} E</span>
												</p>
											))}

										</div>
										<span className="update">최근 업데이트 {moment().format('YYYY.MM.DD')}
											<br />
											{sort === '주간'
												? '주간 순위는 지난주 기준입니다.'
												: sort === '월간'
													? '월간 순위는 이번달 기준입니다.'
													: sort === '연간'
														? '연간 순위는 올해 기준입니다.'
														: ''}
										</span>
									</div>

								}
							</div>
						</div>
					)}

					{/* 탭(순위내용) 종료 */}
				</div>
			</div>
		</div>
	);
};

export default MapInfoDialog;
