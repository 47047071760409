import React from 'react';
import { DialogProps } from '../DialogProvider';
// 댓글 또는 게시물 공개 전환 팝업
interface PublicDialog extends DialogProps {
	type?: '';
	onComplete: (result: string) => void;
}
const PublicDialog = ({ ...props }: PublicDialog) => {
	const handleClose = () => {
		props.onComplete('no');
		// console.log(result);
		props.onClose?.();
	};
	const handleConfirm = () => {
		props.onComplete('yes');
		props.onClose?.();
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>공개</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">공개처리 하시겠습니까?</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button type="submit" className="btn_check" onClick={handleConfirm}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default PublicDialog;
