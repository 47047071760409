class MemberInfo {
	// 수정필요
	constructor(
		public userId: string,
		public email: string,
		public socialId: string,
		public username: string,
		public nickname: string,
		public character: number,
		public password: string,
		public status: string,
		public roles: string,
		public gender: string,
		public id: number,
		public telno: string,
		public address: string,
		// public level: string
		public detailAddress: string,
		public filePath: string,
		public birth: string,
		public townId: number,
		public townDTO: TownDTO,
		public gu: string,
		public dong: string,
		public delDt: string,
	) {}
}
interface TownDTO {
	districtDTOList: [];
	filePath: string;
	townId: number;
	townName: string;
}

export interface UserList {
	content: UserContent[];
	totalPages: number;
	totalElements: number
}

export interface UserContent {
	id: number,
	username: string,
	character: number,
	nickname: string,
	gu: string,
	dong: string,
	status: string,
	roles: string,
	gender: string,
	telno: string,
	birth: string,
	email: string,
	address: string,
	detailAddress: string,
	filePath: string,
	ablePt: number,
	townName: string,
	crtDt: string,
	townDTO: TownDTO,
	snsType: string,
	delDt: string,
	delcnt: number
}

// 관리자 > 회원관리 > 닉네임 팝업
export interface UserOne {
	energyCurrentSituationView: EnergyCurrentSituationView
	point: Point
	viewMember: ViewMember
}

export interface EnergyCurrentSituationView {
	pwrerMwh: number,
	pwrerToe: number,
	gasMj: number,
	gasToe: number,
	redcnToe: number,
	rankings: number
}

export interface Point {
	accmltPt: number,
	ablePt: number,
	disAblePt: number
}

export interface ViewMember {
	mbrUid: number,
	snsType: string ,
	userId: string ,
	character: number,
	userName: string ,
	gu: string ,
	dong: string ,
	nickname: string ,
	telno: string ,
	phoneNo: string,
	email: string,
	addr: string ,
	addrDtl: string,
	mbrStat: string ,
	authRole: string ,
	dropReason: string,
	lastLoginDt: string,
	lastLoginIp: string,
	loginFailCnt: number,
	thumbnail: string,
	crtUid: number,
	crtDt: string
	updUid: number,
	updDt: string,
	delUid: number,
	delDt: string,
	tcUid: number,
	townName: string ,
	ablePt: number
}



export default MemberInfo;
