import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../dialog/DialogProvider';
import './AdminPage_TodayList.scss';
import Pagination from '../../components/common/Pagination';
import axios from 'axios';
import SubLocation from '../../components/common/SubLocation';
import UtilizationAPI from '../../apis/UtilizationAPI';
import { TodayList } from '../../models/Utilization';
import moment from 'moment';
import useMember from '../../hooks/useMember';

const SubLocationList = [{ href: '/admin_02/', text: '관리자 메뉴' }];
const SubLocationList2 = [
    { href: '/admin_02/', text: '회원관리' },
    { href: '/admin_02/point', text: '포인트 및 보상 관리' },
    { href: '/admin_02/static', text: '활동 통계' },
    { href: '/admin_02/today', text: '이용자 수 통계' },
];

const AdminPage_TodayList = () => {
    const navigate = useNavigate();
    const { showDialog } = useDialog();
    const [yearSelectList, setYearSelectList] = useState<number[]>([2024]);
    const [startDt, setStartDt] = useState<string>(moment(new Date()).format('YYYY'));
    const [searchButton, setSearchButton] = useState(false);
    const [todayList, setTodayList] = useState<TodayList[]>([]);
    const { member } = useMember();

    // 권한관리
	useEffect(() => {
		if (!member || member.roles !== 'AMN') {
			navigate('/');
		}
	}, []);
    
    useEffect(() => {
        const day = new Date();
        const year = moment(new Date()).format('YYYY');
        if (year) {
            for (let i = 0; i < Number(year) - 2024; i++) {
                setYearSelectList((cur) => [...cur, Number(year) - i]);
            }
        }
    }, [])

    useEffect(() => {
        UtilizationAPI.getTodayList(startDt)
            .then((res) => {
                if (res) {
                    //console.log("이용자 수 통계 LIST >>>>>>>>>>>>>>>>>>>>>>", res);
                    setTodayList(res);
                } else {
                    setTodayList([]);
                }
            }).catch(() => {
                setTodayList([]);
            })
    }, [searchButton])

    const handleSearchButton = () => {
        setSearchButton(!searchButton);
    };
    return (
        <main className="AdminPage_TodayList">
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>
                <SubLocation
                    titleText1="관리자 메뉴"
                    liList={SubLocationList}
                    titleText2="이용자 수 통계"
                    liList2={SubLocationList2}
                />
                <div className="lnb_txt">
                    <h3>이용자 수 통계</h3>
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content" className="activity_stat">
                    {/*수거함 배출통계*/}
                    <div className="status_table mt40">
                        <div className="date mt10">
                            {/* <select
                                className="category"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    setPage(1);
                                }}
                            >
                                <option value="A">종량제수거함</option>
                                <option value="B">음식물종량기</option>
                                <option value="C">음식물감량기</option>
                                <option value="D">재활용회수기(캔)</option>
                                <option value="E">재활용회수기(페트)</option>
                            </select> */}
                            <select
                                className="month"
                                onChange={(e) => {
                                    setStartDt(e.target.value);
                                }}
                            >
                                {yearSelectList.sort((a, b) => a - b).map((yearList, yIndex) => (
                                    <option key={yIndex} value={yearList} selected={yearSelectList[yearSelectList.length - 1] == yearList}>{yearList}년</option>
                                ))}
                            </select>
                            <button type="button" onClick={handleSearchButton}>
                                조회
                            </button>
                        </div>
                        <ul className="thead" style={{ marginTop: '20px' }}>
                            <li>구분</li>
                            <li style={{ fontWeight: '600' }}>오늘</li>
                            <li>1월</li>
                            <li>2월</li>
                            <li>3월</li>
                            <li>4월</li>
                            <li>5월</li>
                            <li>6월</li>
                            <li>7월</li>
                            <li>8월</li>
                            <li>9월</li>
                            <li>10월</li>
                            <li>11월</li>
                            <li>12월</li>
                            <li style={{ fontWeight: '600' }}>합계</li>
                        </ul>
                        <ul className="tbody">
                            {todayList &&
                                todayList.length !== 0 ?
                                todayList.map((list, index) => (
                                    <li key={index}>
                                        <p>{list.title}</p>
                                        <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {list.today == 0 ? 0 : Math.round(list.today).toLocaleString()}
                                        </p>
                                        <p>{list.month1}</p>
                                        <p>{list.month2}</p>
                                        <p>{list.month3}</p>
                                        <p>{list.month4}</p>
                                        <p>{list.month5}</p>
                                        <p>{list.month6}</p>
                                        <p>{list.month7}</p>
                                        <p>{list.month8}</p>
                                        <p>{list.month9}</p>
                                        <p>{list.month10}</p>
                                        <p>{list.month11}</p>
                                        <p>{list.month12}</p>
                                        <p
                                            style={{
                                                color: '#0b325d',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {list.total}
                                        </p>
                                    </li>
                                ))
                                :
                                <li>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                    <p>-</p>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

export default AdminPage_TodayList;
