import React, { ChangeEvent, useEffect, useState } from 'react';
import './EnergyConversionVillage.scss';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router-dom';
import useMember from '../../hooks/useMember';
import header_logo from '../../assets/header_logo.png';
import testImgKo from '../../assets/testImgKo.png';
import img_energyMap from '../../assets/img_energyMap.png';
import character04 from '../../assets/character04.png';
import pop_gift03 from '../../assets/pop_gift03.png';
import mapMarker from '../../assets/mapMarker.png';
import EnergyVillageMapComponent from './EnergyVillageMapComponent';
import IntroAPI from '../../apis/IntroAPI';
import IntroInfo from '../../models/IntroInfo';

const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
    { href: '/platform_02/', text: '플랫폼' },
    { href: '/reward_02/mission-main', text: '에너지전환마을' },
];
const menuList = [
    { title: '소통마당', info: '소통마당은 마을 주민들이 에너지와 관련해 소통하고 활동하는 공간입니다.' },
    { title: '참여마당', info: '참여마당은 에너지와 관련된 미션과 챌린지에 참여해 각 마을의 에너지전환 활동으로 절약한 수치를 확인할 수 있습니다.' },
]
const markerList = [

]
// 참여마당 > 에너지전환 현황
const EnergyConversionVillage = () => {
    const { member } = useMember();
    const navigate = useNavigate();
    const [markerX, setMarkerX] = useState<number>(0);
    const [markerY, setMarkerY] = useState<number>(0);
    const mouseClick = (e: any) => {
        // console.log(e);
        // console.log(e.clientX);
        // console.log(e.clientY);
        setMarkerX(e.clientX)
        setMarkerY(e.clientY)
    }
    useEffect(() => {

    }, [])
    return (
        <main className="EnergyConversionVillage">
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>
                <SubLocation
                    titleText1="소개마당"
                    liList={SubLocationList}
                    titleText2="에너지전환마을"
                    liList2={SubLocationList2}
                />
                <div className="lnb_txt">
                    <h3>에너지전환마을</h3>
                    {/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
                    <p>에너지전환마을에 대해 함께 알아보아요.</p>
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content" >
                    <div className='villageListDiv'>
                        <MobileList />
                        <EnergyVillageMapComponent />
                        {/* <img className='energyMapImg' onClick={(e) => mouseClick(e)} src={img_energyMap} alt="에너지전환마을 지도" />
                        <div
                            className="markerDiv"
                        >
                            <img className="marker" src={mapMarker} alt="에너지전환마을 지도" />
                        </div> */}
                    </div>
                    <h3>에너지전환마을 이란?</h3>
                    <div className='villageInfoListDiv'>
                        <ul className='villageListUl'>
                            {/* {menuList.map((list, index) => (
                                <li key={index}>
                                    <p className='menuTitle'>{list.title}</p>
                                    <p >{list.info}</p>
                                </li>
                            ))} */}
                            <li >
                                <p className='menuTitle'>에너지전환마을은 광주광역시가 지역 시민들의 생활권인 마을에서부터</p>
                                <p>탄소중립 전환 실천을 위해 에너지 공동체 조성 지원 사업에서 시작하였습니다.</p>
                                <p>광주 에너지전환마을은 동구 3개소 <span style={{ fontWeight: '600', color: '#1d5d9b' }}>지원마을, 지산마을, 동명마을,</span> 서구3개소 <span style={{ fontWeight: '600', color: '#1d5d9b' }}>풍암마을, 발산마을, 서창마을,</span> </p>
                                <p>남구 3개소<span style={{ fontWeight: '600', color: '#1d5d9b' }}> 양림마을, 사직마을, 방림마을,</span> 북구 3개소<span style={{ fontWeight: '600', color: '#1d5d9b' }}> 일곡마을, 문산마을, 삼각마을,</span> 광산구 3개소<span style={{ fontWeight: '600', color: '#1d5d9b' }}> 첨단마을, 수완마을, 비아마을 </span></p>
                                <p> 총 <span style={{ fontWeight: '600', color: '#1d5d9b' }}>15</span>개소가 있습니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main >
    );
};
const MobileList = () => {
    const navigate = useNavigate();
    const [villageList, setVillageList] = useState([] as IntroInfo[]);

    useEffect(() => {
        IntroAPI.getIntroInfoList().then((res) => {
            setVillageList(res)
        })
    }, [])

    const listClick = (townId: number) => {
        navigate(`/commu_02/mainVillage_03`);
        sessionStorage.setItem('town', String(townId));
    }
    return (
        <div className='mobileVillListDiv'>
            <ul className='mobileVillListUl'>
                {villageList.map((list, index) => (
                    <>
                        <li key={index} onClick={() => listClick(list.townId)}>{`${list.townName}`}
                            <p>{`${list.centerName == null ? '센터정보 없음' : list.centerName}`}</p>
                        </li>
                    </>
                ))}
            </ul>
        </div>
    );
}
export default EnergyConversionVillage;
