import React, { useEffect, useState } from 'react';
import Pagination from '../../components/common/Pagination';
import SubLocation from '../../components/common/SubLocation';
import { useDialog } from '../../dialog/DialogProvider';
import './AdminPage_VillPoint.scss';
import './AdminPage_Point.scss';
import './AdminPage_Store.scss';
import './AdminPage_GiftCard.scss';
import './AdminPage_UseGiftCard.scss';
import AdminPage_VillPointDialog from '../../dialog/adminMenu/AdminPage_VillPointDialog';
import AdminPage_StoreDialog from '../../dialog/adminMenu/AdminPage_WriteStoreDialog';
import AdminPage_GiftCardDialog from '../../dialog/adminMenu/AdminPage_GiftCardDialog';
import AdminPage_MemberDialog from '../../dialog/adminMenu/AdminPage_MemberDialog';
import AdminPage_InfoStoreDialog from '../../dialog/adminMenu/AdminPage_InfoStoreDialog';
import AdminPage_CalculateStoreDialog from '../../dialog/adminMenu/AdminPage_CalculateStoreDialog';
import useMember from '../../hooks/useMember';
import { useNavigate } from 'react-router-dom';

const SubLocationList = [{ href: '/admin_02/', text: '관리자 메뉴' }];
const SubLocationList2 = [
	{ href: '/admin_02/', text: '회원관리' },
	{ href: '/admin_02/point', text: '포인트 및 보상 관리' },
	{ href: '/admin_02/static', text: '활동 통계' },
	{ href: '/admin_02/today', text: '이용자 수 통계' },
];

const AdminPage_Point = () => {
	const { showDialog } = useDialog();
	const [mainClassName, setMainClassName] = useState<string>(
		'AdminPage_VillPoint'
	);
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);
	const [tab, setTab] = useState<number>(0);
	const { member } = useMember();
	const navigate = useNavigate();
	
	// 권한관리
	useEffect(() => {
		if (!member || member.roles !== 'AMN') {
			navigate('/');
		}
	}, []);

	useEffect(() => {
		setPage(1);
		if (tab == 0) {
			setMainClassName('AdminPage_VillPoint');
		} else if (tab == 1) {
			setMainClassName('AdminPage_Point');
		} else if (tab == 2) {
			setMainClassName('AdminPage_Store');
		} else if (tab == 3) {
			setMainClassName('AdminPage_GiftCard');
		} else if (tab == 4) {
			setMainClassName('AdminPage_UseGiftCard');
		}
	}, [tab]);

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	return (
		<main className={mainClassName}>
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="관리자 메뉴"
					liList={SubLocationList}
					titleText2="포인트 및 보상 관리"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>포인트 및 보상 관리</h3>
					<p>
						에너지전환마을 지원플랫폼의 포인트 및 보상과 관련된 메뉴들을 관리할
						수 있습니다.
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/*탭 시작*/}
					<div className="comm_tab01 no-scroll">
						<ul>
							<li>
								<a className={tab == 0 ? `on` : ''} onClick={() => setTab(0)}>
									마을 포인트 관리
								</a>
							</li>
							<li>
								<a className={tab == 1 ? `on` : ''} onClick={() => setTab(1)}>
									개인 포인트 관리
								</a>
							</li>
							<li>
								<a className={tab == 2 ? `on` : ''} onClick={() => setTab(2)}>
									상점 관리
								</a>
							</li>
							<li>
								<a className={tab == 3 ? `on` : ''} onClick={() => setTab(3)}>
									상품권 관리
								</a>
							</li>
							<li>
								<a className={tab == 4 ? `on` : ''} onClick={() => setTab(4)}>
									상품권 사용 관리
								</a>
							</li>
						</ul>
					</div>
					{/*탭 종료*/}
					<div className="myEnergyAct">
						{/* 검색 시작 */}
						{tab == 0 && <SearchBar_VillPoint />}
						{tab == 1 && <SearchBar_Point />}
						{tab == 2 && <SearchBar_Store />}
						{tab == 3 && <SearchBar_GiftCard />}
						{tab == 4 && <SearchBar_UseGiftCard />}
						{/* 검색 종료 */}
						{/* 테이블 시작 */}
						{tab == 0 && <VillPointDataTable />}
						{tab == 1 && <PointDataTable />}
						{tab == 2 && <StoreDataTable />}
						{tab == 3 && <GiftCardDataTable />}
						{tab == 4 && <UseGiftCardDataTable />}
						<Pagination
							page={Number(page)}
							totalPageCount={Number(totalPage)}
							// totalPageCount={Math.ceil(count / 8)}
							onPageChange={handlePageChange}
						/>
						{/* 테이블 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const SearchBar_VillPoint = () => {
	const { showDialog } = useDialog();

	const handleVillPointDialog = () => {
		showDialog(AdminPage_VillPointDialog);
	};
	return (
		<div className="comm_search01 mt40">
			<select className="comm_select03">
				<option>연도 전체</option>
				<option>2023 4분기</option>
			</select>
			<div className="button">
				<button
					type="button"
					className="comm_write_button"
					onClick={handleVillPointDialog}
				>
					마을한도 설정
				</button>
				<button type="button" className="comm_write_button">
					목록 다운로드
				</button>
			</div>
		</div>
	);
};

const SearchBar_Point = () => {
	return (
		<>
			<div className="comm_search mt40">
				<ul>
					<li>
						<select className="comm_select03">
							<option>마을전체</option>
							<option>첨단마을</option>
							<option>수완마을</option>
							<option>비아마을</option>
							<option>머굴마을</option>
							<option>소원마을</option>
							<option>동밖마실동명마을</option>
							<option>풍암마을</option>
							<option>양3마을</option>
							<option>서창마을</option>
							<option>양림마을</option>
							<option>사직마을</option>
							<option>방림마을</option>
							<option>일곡마을</option>
							<option>문산마을</option>
							<option>삼각마을</option>
						</select>
						<select className="comm_select03">
							<option>분류 전체</option>
							<option>기본미션</option>
							<option>마을미션</option>
							<option>챌린지 상품권교환</option>
							<option>보낸선물</option>
							<option>받은선물</option>
							<option>포인트회수</option>
							<option>소멸일시경과</option>
						</select>
					</li>
					<li>
						<div className="comm_search_plus">
							<input type="text" placeholder="닉네임을 입력해주세요." />
						</div>
						<button type="button">검색</button>
					</li>
				</ul>
			</div>
			<div className="comm_tool mt25">
				<button type="button" className="comm_write_button">
					목록 다운로드
				</button>
			</div>
		</>
	);
};

const SearchBar_Store = () => {
	const { showDialog } = useDialog();

	const handleStoreDialog = () => {
		showDialog(AdminPage_StoreDialog);
	};
	return (
		<>
			<div>
				<div className="comm_search mt40">
					<ul>
						<li>
							<select className="comm_select03">
								<option>상점명 전체</option>
								<option>커피전문점</option>
							</select>
						</li>
						<li>
							<div className="comm_search_plus">
								<input type="text" placeholder="검색어를 입력하세요." />
							</div>
							<button type="button">검색</button>
						</li>
					</ul>
				</div>
				{/* 검색 종료 */}
				<div className="comm_tool mt25">
					<button
						type="button"
						className="comm_write_button"
						onClick={handleStoreDialog}
					>
						상점 등록
					</button>
				</div>
			</div>
		</>
	);
};
const SearchBar_GiftCard = () => {
	const { showDialog } = useDialog();

	const handleGiftCardDialog = () => {
		showDialog(AdminPage_GiftCardDialog);
	};
	return (
		<div className="comm_search01 mt40">
			<select className="comm_select03">
				<option>연도 전체</option>
				<option>2024년</option>
				<option>2023년</option>
			</select>
			<select className="comm_select03">
				<option>소속마을 전체</option>
				<option>첨단마을</option>
				<option>수완마을</option>
				<option>비아마을</option>
				<option>머굴마을</option>
				<option>소원마을</option>
				<option>동밖마실동명마을</option>
				<option>풍암마을</option>
				<option>양3마을</option>
				<option>서창마을</option>
				<option>양림마을</option>
				<option>사직마을</option>
				<option>방림마을</option>
				<option>일곡마을</option>
				<option>문산마을</option>
				<option>삼각마을</option>
			</select>
			<div className="button">
				<button
					type="button"
					className="comm_write_button"
					onClick={handleGiftCardDialog}
				>
					상품권 등록
				</button>
			</div>
		</div>
	);
};

const SearchBar_UseGiftCard = () => {
	return (
		<>
			<div>
				<div className="comm_search mt40">
					<ul>
						<li>
							<select className="comm_select03">
								<option>연도 전체</option>
								<option>2024년</option>
								<option>2023년</option>
							</select>
						</li>
						<li>
							<select className="comm_select03">
								<option>소속마을 전체</option>
								<option>첨단마을</option>
								<option>수완마을</option>
								<option>비아마을</option>
								<option>머굴마을</option>
								<option>소원마을</option>
								<option>동밖마실동명마을</option>
								<option>풍암마을</option>
								<option>양3마을</option>
								<option>서창마을</option>
								<option>양림마을</option>
								<option>사직마을</option>
								<option>방림마을</option>
								<option>일곡마을</option>
								<option>문산마을</option>
								<option>삼각마을</option>
							</select>
						</li>
						<li>
							<select className="comm_select03">
								<option>전체</option>
								<option>닉네임</option>
								<option>상품권명</option>
							</select>
						</li>
						<li>
							<div className="comm_search_plus">
								<input type="text" placeholder="닉네임을 입력해주세요." />
							</div>
							<button type="button">검색</button>
						</li>
					</ul>
				</div>
				{/* 검색 종료 */}
				<div className="comm_tool mt25">
					<button type="button" className="comm_write_button">
						목록 다운로드
					</button>
				</div>
			</div>
		</>
	);
};
const VillPointDataTable = () => {
	return (
		<div className="point_table mt25">
			<ul className="thead pa20">
				<li>마을명</li>
				<li>
					2023년 한도
					<br />
					(남은한도)
				</li>
				<li>가용 포인트</li>
				<li>비가용 포인트</li>
				<li>
					소멸된
					<br />
					포인트
				</li>
				<li>
					교환완료
					<br />
					포인트
				</li>
				<li>
					선물완료
					<br />
					포인트
				</li>
				<li>
					사용완료
					<br />
					포인트
				</li>
			</ul>
			<ul className="tbody">
				<li className="pa20">
					<p>전체</p>
					<p>
						200,000,000
						<br />
						(100,000,000)
					</p>
					<p>900,000</p>
					<p>100,000</p>
					<p>400,000</p>
					<p>400,000</p>
					<p>400,000</p>
					<p>400,000</p>
				</li>
				<li className="pa20">
					<p>첨단마을</p>
					<p>
						10,000,000
						<br />
						(100,000)
					</p>
					<p>150,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>수완마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>양림마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>사직마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>일곡마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>일곡마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>비아마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>비아마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
				<li className="pa20">
					<p>비아마을</p>
					<p>
						15,000,000
						<br />
						(500,000)
					</p>
					<p>200,000</p>
					<p>100,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
					<p>20,000</p>
				</li>
			</ul>
		</div>
	);
};

const PointDataTable = () => {
	const [testCount, setTestCount] = useState<number[]>([1, 2, 3, 4, 5]);

	const { showDialog } = useDialog();

	const handleMemberDialog = () => {
		showDialog(AdminPage_MemberDialog);
	};
	return (
		<div className="point_table mt25">
			<ul className="thead pa20">
				<li>일자</li>
				<li>닉네임</li>
				<li>소속마을</li>
				<li>분류</li>
				<li>내용(미션명)</li>
				<li>포인트</li>
				<li>
					가용포인트
					<br />
					전환일자
				</li>
				<li>
					포인트
					<br />
					소멸일지
				</li>
			</ul>
			<ul className="tbody">
				{testCount.map((list, index) => (
					<li className="pa20">
						<p>2023.10.01</p>
						<p>
							<a onClick={handleMemberDialog}>도토리</a>
						</p>
						<p>첨단마을</p>
						<p>기본미션</p>
						<p>[도토리]님에게 1,000포인트 선물</p>
						<p className="blue">+5,000</p>
						<p>2023.10.07</p>
						<p>2024.01.01</p>
					</li>
				))}
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a onClick={handleMemberDialog}>도토리</a>
					</p>
					<p>첨단마을</p>
					<p>기본미션</p>
					<p>[도토리]님에게 1,000포인트 선물</p>
					<p className="red">-5,000</p>
					<p>2023.10.07</p>
					<p>2024.01.01</p>
				</li>
			</ul>
		</div>
	);
};

const StoreDataTable = () => {
	const [testCount, setTestCount] = useState<number[]>([1, 2, 3, 4, 5]);

	const { showDialog } = useDialog();

	const handleStoreDialog = () => {
		showDialog(AdminPage_InfoStoreDialog);
	};
	const handleCalculateStoreDialog = () => {
		showDialog(AdminPage_CalculateStoreDialog);
	};
	return (
		<div className="point_table mt25">
			<ul className="thead pa20">
				<li>구분</li>
				<li>상점명</li>
				<li>사업자등록번호</li>
				<li>연락처</li>
				<li>소재지</li>
				<li>상태</li>
				<li>정산필요</li>
			</ul>
			<ul className="tbody">
				{testCount.map((list, index) => (
					<li className="pa20">
						<p>거점마을 상점</p>
						<p>
							<a onClick={handleStoreDialog}>커피전문점</a>
						</p>
						<p>111-11-11111</p>
						<p>062-111-1111</p>
						<p>서구 상무평화로 89 상무구영드림빌 203호</p>
						<p>사용</p>
						<p>
							<a onClick={handleCalculateStoreDialog}>100건</a>
						</p>
					</li>
				))}

				{/* <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p className="red">미사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p className="red">미사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p className="red">미사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li>
                <li className="pa20">
                    <p>거점마을 상점</p>
                    <p>
                        <a >커피전문점</a>
                    </p>
                    <p>111-11-11111</p>
                    <p>062-111-1111</p>
                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                    <p>사용</p>
                    <p>
                        <a >100건</a>
                    </p>
                </li> */}
			</ul>
		</div>
	);
};

const GiftCardDataTable = () => {
	return (
		<div className="point_table mt25">
			<ul className="thead pa20">
				<li>상품권명</li>
				<li>사용 유효기간</li>
				<li>배포마을</li>
				<li>포인트 단가</li>
				<li>현금 단가</li>
				<li>
					상품권수량
					<br />
					(사용/배포/총수량)
				</li>
				<li>상태</li>
				<li>사용된 총 금액</li>
				<li>수정</li>
			</ul>
			<ul className="tbody">
				<li className="pa20">
					<p>거점마을 상점 5,000원 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>5,000E</p>
					<p>5,000원</p>
					<p>1,299/5,000/10,000</p>
					<p className="blue">배포중</p>
					<p>6,495,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="red">일시정지</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p>배포완료</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p>기간만료</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
				<li className="pa20">
					<p>이벤트 거점마을 상점 상품권</p>
					<p>
						2023.01.01~
						<br />
						2023.12.11
					</p>
					<p>첨단마을</p>
					<p>1,000E</p>
					<p>5,000원</p>
					<p>2/50/100</p>
					<p className="blue">배포중</p>
					<p>10,000원</p>
					<p>
						<button type="button" className="table_button">
							수정
						</button>
					</p>
				</li>
			</ul>
		</div>
	);
};

const UseGiftCardDataTable = () => {
	return (
		<div className="point_table mt25">
			<ul className="thead pa20">
				<li>교환일시</li>
				<li>닉네임</li>
				<li>배포마을</li>
				<li>상품권명</li>
				<li>포인트 단가</li>
				<li>현금 단가</li>
				<li>상태</li>
				<li>
					사용완료일시
					<br />
					(소멸일시)
				</li>
			</ul>
			<ul className="tbody">
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>첨단마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="blue">교환완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="blue">교환완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">사용완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">사용완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">소멸</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">소멸</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="blue">교환완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="blue">교환완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">사용완료</p>
					<p>2023.10.01</p>
				</li>
				<li className="pa20">
					<p>2023.10.01</p>
					<p>
						<a >도토리</a>
					</p>
					<p>수완마을</p>
					<p>거점마을 상점 5,000원 상품권</p>
					<p>5,000원</p>
					<p>5,000원</p>
					<p className="gray">소멸</p>
					<p>2023.10.01</p>
				</li>
			</ul>
		</div>
	);
};
export default AdminPage_Point;
