import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { NavermapsProvider } from 'react-naver-maps';
import './styles/base.css';
import './styles/layout.css';
import './styles/common.css';
import './styles/commonWrite.css';
import './styles/commPopup.css';
import DialogProvider from './dialog/DialogProvider';
import MemberContextProvider from './components/context/MemberContextProvider';
import ScrollToTop from './components/common/ScrollToTop';
import Urlupdate from './components/common/Urlupdate';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<BrowserRouter>
		<ScrollToTop />
		{/* <Urlupdate /> */}
		<MemberContextProvider>
			{/* <React.StrictMode> */}
			<DialogProvider>
				<App />
			</DialogProvider>
			{/* </React.StrictMode> */}
		</MemberContextProvider>
	</BrowserRouter>
);
{
	/* <NavermapsProvider
		ncpClientId='4f9n2afciv'>
		<App />
	  </NavermapsProvider> */
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
