import axios from 'axios';
import MapInfo, { MycmMasterList, InsertCmMapping } from '../models/MapInfo';
import MappingList, { CmMappingList } from '../models/MappingList';
import { serialize } from 'object-to-formdata';
interface GetAllParams {
	page?: number;
	tcUid?: number;
	// townId?: number;
	crtUid?: number;
	bifUid?: number;
	itemTp?: string;
	openTp?: string;
	searchType?: string;
	cmmUid?: number;
	cmcUid?: number;
	pageSize?: number;
	searchKeyword?: string;
	cmUid?: number;
	cmfUid?: number;
	updUid?: number;
	delUid?: number;
	search?: string;
	prgrsSt?: string;
}
// 생활지도 댓글 등록 폼
export interface CommentInputForm {
	cmUid?: number;
	cmtContent?: string;
	openTp?: string;
	crtUid?: number;
	cmtUid: number;
	delUid?: number;
	delDt?: string;
}
const getMyMapList = (params: GetAllParams) => {
	return axios
		.get('/api/community/MycmMasterList', { params })
		.then((res) => res.data as MapInfo);
};
const getAllMapList = (params: GetAllParams) => {
	return axios
		.get('/api/community/AnycmMasterList', { params })
		.then((res) => res.data as MapInfo);
};
// 생활지도 > 주제등록
const InsertCmmaster = (
	data: MycmMasterList,
	uploadFiles: File[] | FileList
) => {
	// console.log('data', data);
	const formData = new FormData();
	formData.append('tcUid', JSON.stringify(data.tcUid));
	formData.append('title', data.title || '');
	formData.append('dtlTxt', data.dtlTxt || '');
	formData.append('stDt', data.stDt || '');
	formData.append('edDt', data.edDt || '');
	formData.append('crtUid', JSON.stringify(data.crtUid));
	formData.append('openTp', data.openTp || '');
	// formData.append('prgrsSt', data.prgrsSt || '');
	if (data.categoryList) {
		for (let i = 0; i < data.categoryList.length; i++) {
			formData.append(
				`categoryList[${i}].title`,
				data.categoryList[i].title || ''
			);
		}
	}
	if (data.categoryList) {
		for (let i = 0; i < data.categoryList?.length; i++) {
			formData.append(
				`categoryList[${i}].crtUid`,
				JSON.stringify(data.categoryList[i].crtUid)
			);
		}
	}
	if (data.markerList) {
		for (let i = 0; i < data.markerList?.length; i++) {
			formData.append(
				`markerList[${i}].crtUid`,
				JSON.stringify(data.markerList[i].crtUid)
			);
		}
	}
	if (data.markerList) {
		for (let i = 0; i < data.markerList?.length; i++) {
			formData.append(
				`markerList[${i}].markerFile`,
				data.markerList[i].markerFile || ''
			);
		}
	}

	// 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
	return axios
		.post('/api/community/cmmasterInsert', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((response) => {
			// return response.data as MycmMasterList;
			return response.data;
		});
};
// 주제 단일 조회
const getMappingList = (params: GetAllParams) => {
	return axios.get('/api/community/searchComapOne', { params }).then((res) => {
		return res.data as MappingList;
	});
};
// 주제 수정 단일 조회
const getMasterDetail = (params: GetAllParams) => {
	return axios
		.get('/api/community/searchComapUp', { params })
		.then((res) => res.data as MycmMasterList);
};
// 주제 수정
const updateCmMaster = (
	data: MycmMasterList,
	uploadFiles: File[] | FileList
) => {
	const formData = new FormData();
	formData.append('cmmUid', JSON.stringify(data.cmmUid));
	formData.append('tcUid', JSON.stringify(data.tcUid));
	formData.append('title', data.title || '');
	formData.append('dtlTxt', data.dtlTxt || '');
	formData.append('stDt', data.stDt || '');
	formData.append('edDt', data.edDt || '');
	formData.append('updUid', JSON.stringify(data.updUid));
	formData.append('openTp', data.openTp || '');
	// formData.append('prgrsSt', data.prgrsSt || '');
	if (data.categoryList) {
		for (let i = 0; i < data.categoryList.length; i++) {
			formData.append(
				`categoryList[${i}].title`,
				data.categoryList[i].title || ''
			);
			formData.append(
				`categoryList[${i}].crtUid`,
				JSON.stringify(data.categoryList[i].crtUid)
			);
			formData.append(
				`categoryList[${i}].cmcUid`,
				JSON.stringify(data.categoryList[i].cmcUid)
			);
		}
	}

	if (data.markerList) {
		for (let i = 0; i < data.markerList?.length; i++) {
			formData.append(
				`markerList[${i}].crtUid`,
				JSON.stringify(data.markerList[i].crtUid)
			);
			formData.append(
				`markerList[${i}].markerFile`,
				data.markerList[i].markerFile || ''
			);

			formData.append(
				`markerList[${i}].cmcUid`,
				JSON.stringify(data.markerList[i].cmcUid)
			);
		}
	}

	// 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
	return axios
		.post('/api/community/cmmasterUpdate', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((response) => {
			return response.data as MycmMasterList;
		});
};
// 주제 삭제
const deleteCmMaster = (params: GetAllParams) => {
	return axios
		.post('/api/community/cmmasterUpdate', serialize(params))
		.then((res) => {
			return res as any;
		});
};
// 생활지도 > 주제수정 > 카테고리 사용여부 조회
const getCategoryYn = (params: GetAllParams) => {
	// console.log('카테고리 사용여부 조회');
	return axios
		.get('/api/community/ckCategory', { params })
		.then((res) => res.data as string);
};
// 주제 이미지 조회
const getMasterImg = (params: GetAllParams) => {
	return axios.get(`/api/community/getCmFiles`, {
		responseType: 'blob',
		params: params,
	});
	//.then((res) => res.data as File);
};
// 맵핑 이미지 조회
const getMappingImg = (params: GetAllParams) => {
	return axios.get('/api/community/getMapFiles', {
		responseType: 'blob',
		params: params,
	});
	//.then((res) => res.data as File);
};
// 맵핑 등록
const insertCmMapping = (
	data: InsertCmMapping,
	uploadFiles: File[] | FileList
) => {
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		formData.append(key, String(value));
	});

	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
	return axios
		.post('/api/community/cmmappingInsert', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
// 맵핑 디테일 조회
const getMappingDetail = (params: GetAllParams) => {
	return axios
		.get('/api/community/searchMappingUp', { params })
		.then((res) => res.data as CmMappingList);
};
// 맵핑 수정
const updateMapping = (
	data: InsertCmMapping,
	uploadFiles: File[] | FileList
) => {
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		formData.append(key, String(value));
	});

	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
	return axios
		.post('/api/community/cmmappingUpdate', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
// 맵핑글 삭제
const deleteMapping = (params: GetAllParams) => {
	return axios
		.post('/api/community/cmmappingUpdate', serialize(params))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
// 맵핑 댓글 등록
const insertCmt = (data: CommentInputForm) => {
	// console.log('insertComment', data);
	return axios
		.post('/api/community/registerCmt', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
// 댓글 수정
const updateCmt = (data: CommentInputForm) => {
	// console.log('updateComment', data);
	return axios
		.post('/api/community/registerCmt', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
// 댓글 삭제
const deleteCmt = (data: CommentInputForm) => {
	// console.log('deleteComment', data);
	return axios
		.post('/api/community/registerCmt', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};
const privateCmt = (data: CommentInputForm) => {
	// console.log('updateComment', data);
	return axios
		.post('/api/community/registerCmtAdmin', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};

const ActiveMapAPI = {
	getMyMapList,
	getAllMapList,
	InsertCmmaster,
	getMappingList,
	insertCmMapping,
	getMappingDetail,
	getMasterDetail,
	updateCmMaster,
	deleteCmMaster,
	getCategoryYn,
	getMasterImg,
	getMappingImg,
	updateMapping,
	deleteMapping,
	insertCmt,
	updateCmt,
	deleteCmt,
	privateCmt,
};

export default ActiveMapAPI;
