import React, { useEffect, useState } from 'react';
import '../missionYard/Main_MissionPage.scss';
import ic_home from '../../assets/ic_home.png';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import { useNavigate } from 'react-router';
import SubLocation from '../../components/common/SubLocation';
import CommSelect from '../../components/common/CommSelect';
import { content } from '../../models/TownList';
import TownAPI from '../../apis/TownAPI';
import MissionInfo, { GetEnergy } from '../../models/MissionInfo';
import MissionAPI from '../../apis/MissionAPI';
import moment from 'moment';
import noList from '../../assets/noList.png';
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/myEPoint', text: '내 E 포인트' },
];
const Main_MissionPage = () => {
	const navigate = useNavigate();

	const handleVillMission = () => {
		navigate('/reward_02/villageMission_03-list');
	};
	const handleMission = () => {
		navigate('/reward_02/mission_03-list');
	};
	const [villRanking, setVillRanking] = useState<GetEnergy>();
	const [ranking, setRanking] = useState<GetEnergy>();
	const nowDate = new Date();
	const [period, setPeriod] = useState<string>('주간');
	const [period2, setPeriod2] = useState<string>('주간');
	const [prgrsSt, setPrgrsSt] = useState<string>('진행');
	const [villMissionList, setVillMissionList] = useState([] as MissionInfo[]);
	const [mssionList, setMissionList] = useState([] as MissionInfo[]);
	const [mmiUid, setMmiUid] = useState<number>(0);
	const [villMmiUid, setVillMmiUid] = useState<number>(0);
	useEffect(() => {
		MissionAPI.getRanking({
			mssnDe: '마을미션',
			mmiUid: villMmiUid ? villMmiUid : undefined,
			period: period,
			past: period === '주간' ? '저번주' : undefined,
		}).then((res) => {
			// console.log('마을미션 ::::::', res.data);
			setVillRanking(res.data);
		});
		MissionAPI.getRanking({
			mssnDe: '기본미션',
			mmiUid: mmiUid ? mmiUid : undefined,
			period: period2,
			past: period2 === '주간' ? '저번주' : undefined,
		}).then((res) => {
			// console.log('기본미션 ::::::', res.data);
			setRanking(res.data);
		});
		// 마을미션 셀렉트 목록조회
		MissionAPI.getMissionList({
			mssnDe: '마을미션',
			prgrsSt: prgrsSt,
		}).then((res) => {
			// console.log('getMissionList ::::', res);
			setVillMissionList(res.content);
		});
		MissionAPI.getMissionList({
			mssnDe: '기본미션',
			prgrsSt: prgrsSt,
		}).then((res) => {
			// console.log('getMissionList ::::', res);
			setMissionList(res.content);
		});
	}, [prgrsSt, mmiUid, villMmiUid, period, period2]);
	const handleMmiUid = (mmiUid: number) => {
		// // console.log('villMmiUid:::::::', mmiUid);
		// console.log('mmiUid:::::::::', mmiUid);
		setMmiUid(mmiUid);
		// setVillMmiUid(mmiUid);
	};
	const handleVillMmiUid = (mmiUid: number) => {
		// // console.log('villMmiUid:::::::', mmiUid);
		// console.log('mmiUid:::::::::', mmiUid);
		setVillMmiUid(mmiUid);
		// setVillMmiUid(mmiUid);
	};
	return (
		<main className="Main_MissionPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 활동미션"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>에너지전환 활동미션</h3>
					{/* <CommSelect
						townList={townList}
						// changeTownList={changeTownList}
						// selectedTown={selectedTown}
					/> */}
					<p>
						마을미션과 기본미션에 참여해 에너지전환 활동을 실천하고 포인트를
						모아보세요.
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="mission_link_wrap">
						<a className="pa30" onClick={handleVillMission}>
							<div>
								<h3>마을미션</h3>
								<p className="mt10">
									마을이 주체가 되어 마을 주민들이 에너지전환에 도움을 주는
									활동을 실천하면 포인트를 지급 받는 인증미션
								</p>
							</div>
						</a>
						<a className="pa30" onClick={handleMission}>
							<div>
								<h3>기본미션</h3>
								<p className="mt10">
									개인이 일상에서 간단하게 에너지전환 활동을 실천해 인증하면
									에너지전환량에 대해 포인트를 지급 받는 인증미션
								</p>
							</div>
						</a>
					</div>
					<div className="mission_info_wrap mt40">
						<h3>기본미션 종합 순위 주간 10위권 내에 진입시 추가 포인트 지급</h3>
						<p className="mt5">
							(매주 월 00:00 ~ 일 00:00 기준, 차주월요일 새벽 자동 지급)
						</p>
						<ul className="mt25">
							<li>
								<div>
									<p>1위</p>
									<span>1,000E</span>
								</div>
							</li>
							<li>
								<div>
									<p>2위</p>
									<span>700E</span>
								</div>
							</li>
							<li>
								<div>
									<p>3위</p>
									<span>500E</span>
								</div>
							</li>
							<li>
								<div>
									<p>4~10위</p>
									<span>100E</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="mission_list_wrap mt40">
						{/* 마을순위 시작 */}
						<div className="mission_list_box">
							<div className="mission_cont_title">
								<h3 className="comm_title">마을순위</h3>
							</div>
							<div className="mission_cont_set mt10">
								<select
									onChange={(e) => handleVillMmiUid(Number(e.target.value))}
								>
									<option value={undefined}>마을미션 전체</option>
									{villMissionList &&
										villMissionList.map((list, idx) => (
											<option key={idx} value={list?.mmiUid}>
												{list?.mssnNm}
											</option>
										))}
								</select>
								<ul>
									<li>
										<p
											className={period === '주간' ? 'on' : ''}
											onClick={() => setPeriod('주간')}
										>
											주간
										</p>
									</li>
									<li>
										<p
											className={period === '월간' ? 'on' : ''}
											onClick={() => setPeriod('월간')}
										>
											월간
										</p>
									</li>
									<li>
										<p
											className={period === '연간' ? 'on' : ''}
											onClick={() => setPeriod('연간')}
										>
											연간
										</p>
									</li>
								</ul>
							</div>
							{villRanking &&
								villRanking?.rankings &&
								villRanking?.rankings.length == 0 ? (
								<div className="comm_noList">
									<img src={noList} alt="내용없음 이미지" />
									<p className="mt25">
										해당 기간에 미션에 참여한 마을이 없습니다.
									</p>
								</div>
							) : (
								<div className="mission_cont_content mt25">
									<div className="ranking_list">
										{villRanking &&
											villRanking?.rankings.map((villList, villIndex) => (
												<p key={villIndex}>
													{villList?.rank < 4 ? (
														<span className="num">
															<img
																src={
																	villList?.rank === 1
																		? ic_rank01
																		: villList?.rank === 2
																			? ic_rank02
																			: villList?.rank === 3
																				? ic_rank03
																				: ''
																}
																alt="랭킹 아이콘"
															/>
														</span>
													) : (
														villList?.rank >= 4 && (
															<>
																<span className="num">{villList?.rank}</span>
															</>
														)
													)}
													<span className="villname">
														{villList?.town?.townName}

														<strong>
															{' '}
															(
															{villList?.town?.districts.map(
																(districtsList, districtsIndex) => (
																	<> {districtsList?.districtName} </>
																)
															)}
															)
														</strong>
													</span>
													<span className="point">
														{villList?.point.toLocaleString()} E
													</span>
												</p>
											))}
									</div>
									<span className="update">
										최근 업데이트 {moment(nowDate).format('YYYY.MM.DD')}
										<br />
										{period === '주간'
											? '주간 순위는 지난주 기준입니다.'
											: period === '월간'
												? '월간 순위는 이번달 기준입니다.'
												: period === '연간'
													? '연간 순위는 올해 기준입니다.'
													: ''}
									</span>
								</div>
							)}
						</div>
						{/* 마을순위 종료 */}
						{/* 기본순위 시작 */}
						<div className="mission_list_box">
							<div className="mission_cont_title">
								<h3 className="comm_title">기본순위</h3>
							</div>
							<div className="mission_cont_set mt10">
								<select onChange={(e) => handleMmiUid(Number(e.target.value))}>
									<option value={undefined}>기본미션 종합</option>
									{mssionList &&
										mssionList.map((list, idx) => (
											<option key={idx} value={list?.mmiUid}>
												{list?.mssnNm}
											</option>
										))}
								</select>
								<ul>
									<li>
										<p
											className={period2 === '주간' ? 'on' : ''}
											onClick={() => setPeriod2('주간')}
										>
											주간
										</p>
									</li>
									<li>
										<p
											className={period2 === '월간' ? 'on' : ''}
											onClick={() => setPeriod2('월간')}
										>
											월간
										</p>
									</li>
									<li>
										<p
											className={period2 === '연간' ? 'on' : ''}
											onClick={() => setPeriod2('연간')}
										>
											연간
										</p>
									</li>
								</ul>
							</div>
							{ranking && ranking?.rankings && ranking?.rankings.length == 0 ? (
								<div className="comm_noList">
									<img src={noList} alt="내용없음 이미지" />
									<p className="mt25">
										해당 기간에 미션에 참여한 회원이 없습니다.
									</p>
								</div>
							) : (
								<div className="mission_cont_content mt25">
									<div className="ranking_list">
										{ranking &&
											ranking?.rankings.map((list, index) => (
												<p key={index}>
													{list?.rank < 4 ? (
														<span className="num">
															<img
																src={
																	list?.rank === 1
																		? ic_rank01
																		: list?.rank === 2
																			? ic_rank02
																			: list?.rank === 3
																				? ic_rank03
																				: ''
																}
																alt="랭킹 아이콘"
															/>
														</span>
													) : (
														list?.rank >= 4 && (
															<>
																<span className="num">{list?.rank}</span>
															</>
														)
													)}
													{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}

													<span
														className={
															list.member.character === 1
																? 'nickname basic1'
																: list.member.character === 2
																	? 'nickname basic2'
																	: list.member.character === 3
																		? 'nickname Lv1'
																		: list.member.character === 4
																			? 'nickname Lv2'
																			: list.member.character === 5
																				? 'nickname Lv3'
																				: list.member.character === 6
																					? 'nickname Lv4'
																					: ''
														}
													>
														{list?.member?.nickname}
													</span>
													<span className="point">
														{' '}
														{list?.point.toLocaleString()} E
													</span>
												</p>
											))}
									</div>
									<span className="update">
										최근 업데이트 {moment(nowDate).format('YYYY.MM.DD')}
										<br />
										{period2 === '주간'
											? '주간 순위는 지난주 기준입니다.'
											: period2 === '월간'
												? '월간 순위는 이번달 기준입니다.'
												: period2 === '연간'
													? '연간 순위는 올해 기준입니다.'
													: ''}
									</span>
								</div>
							)}
						</div>
						{/* 기본순위 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Main_MissionPage;
