import axios from "axios";
import { ChartList, BarChartDataList } from "../models/ChartList";

interface GetAllParams{
    area?: string;
    yyyy?: string;
    areaUpper?: string;
    syyyy?: string;
    eyyyy?: string;
    buldCtgry?: string;
    type?: string;
}
// 정보마당 > 광주에너지분석 차트데이타
const getChartDataList = (params: GetAllParams) => {
    return axios
    .get('/api/energydata/situation', {params})
    .then((res) => res.data as ChartList)
}

const getBarChartDataList = (params: GetAllParams) => {
    return axios
    .get('/api/energydata/energyanalyze', {params})
    .then((res) => res.data as BarChartDataList)
}

const getBarChartGasDataList = (params: GetAllParams) => {
    return axios
    .get('/api/energydata/ggssanalyze', {params})
    .then((res) => res.data as BarChartDataList)
}

const getBarChartPopanalyzeDataList = (params: GetAllParams) => {
    return axios
    .get('/api/energydata/popanalyze', {params})
    .then((res) => res.data as BarChartDataList)
}
const ChartAPI = {
    getChartDataList,
    getBarChartDataList,
    getBarChartGasDataList,
    getBarChartPopanalyzeDataList,
}
export default ChartAPI;