import React, { useEffect, useState } from 'react';
import test01 from '../../assets/test01.png';
import camImg from '../../assets/ic_cam.png';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDialog } from '../../dialog/DialogProvider';
import oneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import NoticeAPI from '../../apis/NoticeAPI';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
const SubLocationList = [
    { href: '/commu_02/', text: '소통마당' },
    // { href: '/reward_02/', text: '참여마당' },
    // { href: '/info_02/', text: '정보마당' },
];

const Modify_NoticePage = () => {
    const uid = sessionStorage.getItem('noticeN');
    const navigate = useNavigate();
    const { showDialog } = useDialog();
    const [uploadFiles, setUploadFiles] = useState([] as File[]);
    const [previewImages, setPreviewImages] = useState<string[]>([]);
    const [deleteImgFileIds, setDeleteImgFileIds] = useState([] as number[]);
    const [content, setContent] = useState<string>('')
    const [title, setTitle] = useState<string>('');
     // 첨부파일
	const [docFiles, setDocFiles] = useState([] as File[]);
	type PreviewFile = string | { file: File; preview: string; name: string };
	const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);
    

    useEffect(() => {
        NoticeAPI.getNoticeDetail({
            nntUid: Number(uid)
        }).then((res) => {
            if (res) {
                setTitle(res.title)
                setContent(res.content)
                				// 사진 조회 api추가되면 수정
				if (res?.fileList) {
					const imageFiles: File[] = [];
					const pdfFiles: File[] = [];

					const imagePromises: Promise<any>[] = [];
					const pdfPromises: Promise<any>[] = [];

					for (let i = 0; i < res.fileList.length; i++) {
						const file = res.fileList[i];
						// 이미지 파일	
						if (file.fileName.includes(".png") || file.fileName.includes(".jpg")) {
							const promise =  NoticeAPI.getFiles({
								savedPath: file.savePath,
							}).then((res) => {
								if (res) {
									const image = new File(
										[res.data],
										file.fileName,
										{ type: 'image/png' }
									);

									imageFiles.push(image);

									const previewImage = `/api/attach/getFile?savedPath=${file.savePath}&boardFileId=${file.ntfUid}`;
									setPreviewImages((prevImages) => [...prevImages, previewImage]);
								}
							});

							imagePromises.push(promise);

							// pdf, hwp파일	
						} else if (file.fileName.includes(".pdf") || file.fileName.includes(".hwp")) {
							const promise = NoticeAPI.getFiles({
								savedPath: file.savePath,
							}).then((res) => {
								if (res) {
									const pdf = new File(
										[res.data],
										file.fileName,
									);


									pdfFiles.push(pdf);

									const previewpdf = `/api/attach/getFile?savedPath=${file.savePath}&boardFileId=${file.ntfUid}`;
									// setPreviewFiles((prevPdfs) => [...prevPdfs, previewpdf]);
									// 기존 파일 정보를 추가
									setPreviewFiles((prevFiles: PreviewFile[]) => [...prevFiles, { file: pdf, preview: '', name: file.fileName, boardFileId: file.ntfUid }]);
								}
							});

							pdfPromises.push(promise);
						}
					}
                }
            }
        })
    }, [])

    const handleModify = () => {
        if (title == '') {
            const title = '공지사항';
            const message = '제목을 입력해주세요.';
            showDialog(OneButtonAlertDialog, { title, message });
            return;
        }
        if (content == '') {
            const title = '공지사항';
            const message = '내용을 입력해주세요.';
            showDialog(OneButtonAlertDialog, { title, message });
            return;
        }

        NoticeAPI.updateNotice(
            {
                nntUid: Number(uid),
                title: title,
                content: content
            },
            uploadFiles ? uploadFiles : undefined,
            docFiles ? docFiles : undefined,
            deleteImgFileIds ? deleteImgFileIds : undefined
        ).then((res) => {
            if (res.data.type == 1) {
                // console.log("공지사항 수정", res)
                const title = '공지사항 수정';
                const message = '수정되었습니다.'
                showDialog(OneButtonAlertDialog, {
                    title,
                    message,
                    handleDialogResult(result) {
                        if (result === 'ok') {
                            navigate('/notice_02/notice_03-detail');
                        }
                    }
                })
            }
        })
    }
    // 이미지 파일 첨부
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('files ::::::: ', event.target.files);
        const files = event.target.files;
        if (!files) return;
        const selectedImages = Array.from(files);
        // 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
        if (previewImages.length + selectedImages.length > 5) {
            const title = '';
            const message = '사진은 최대 5개 까지 업로드할수있습니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (selectedImages && selectedImages[0].size > 15000000) {
            const title = '';
            const message = '파일 크기는 최대 15MB까지만 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
            const title = '';
            const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        setUploadFiles((prevImages: any) => {
            const newImages = [...prevImages];
            selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
            // // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
            return newImages;
        });
        // 미리보기 이미지 추가
        const imagesToSubmit = selectedImages.slice(0, 5);
        const newPreviewImages = imagesToSubmit.map((file) =>
            URL.createObjectURL(file)
        );
        setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
    };

    // 첨부한 이미지 파일 x 버튼 누를시 삭제
    const handleRemovePreview = (index: number, boardFileId?: number) => {
        if (boardFileId) {
            setDeleteImgFileIds((cur) => [...cur, boardFileId]);
        }
        setPreviewImages((prevImages) => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
        setUploadFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };
    // 파일 첨부
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) return;
		const selectedFiles = Array.from(files);
        // console.log('files ::::::: ', selectedFiles[0]);

        if (selectedFiles && selectedFiles.length > 5) {
            const title = '';
            const message = '파일은 최대 5개까지 업로드할 수 있습니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (!(
            selectedFiles[0].type === 'application/pdf' || 
            selectedFiles[0].name.endsWith(".hwp")
        )) {
            const title = '';
            const message = '파일 형식은 pdf, hwp 만 등록 가능합니다.';
            console.log(selectedFiles[0].type);
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        
		setDocFiles((prevFiles: File[]) => {
			const newFiles = [...prevFiles];
			selectedFiles.slice(0, 5).forEach((file) => newFiles.push(file));
			// // console.log('filesToSubmit ::::::::::::::::: ', uploadFiles);
			return newFiles;
		});

		// 미리보기 파일 추가
		const filesToSubmit = selectedFiles.slice(0, 5);
		const newPreviewFiles = filesToSubmit.map((file) => {
			// 파일명 추출
			const fileName = file.name;
			return {
				file,
				preview: URL.createObjectURL(file),
				name: fileName,
			};
		});

		setPreviewFiles((prevFiles: PreviewFile[]) => [...prevFiles, ...newPreviewFiles]);
	}

	// 파일 미리보기 삭제
	const handleRemoveFilePreview = (index: number) => {
		setPreviewFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
		setDocFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	}; 

    // 취소 버튼
    const handleCancle = () => {
        navigate(-1);
    };

    return (
        <main>
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>
                {/* <SubLocation titleText1="소통마당" liList={SubLocationList} /> */}
                <div className="lnb_txt">
                    <h3>공지사항 수정하기</h3>
                    {/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <form >
                        <div className="comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">제목</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="제목을 입력하세요"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">내용</h3>
                                <div className="cont-box">
                                    <textarea
                                        placeholder="내용을 입력하세요"
                                        defaultValue={''}
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>첨부 이미지<br />(최대 15MB)</h3>
                                <div className="cont-box">
                                    <div className="imgBox">
                                        <input className="upload-photo" value="" placeholder="" />
                                        <label htmlFor="img">
                                            <img src={camImg} alt="사진 첨부" />
                                            사진 첨부
                                            <span>(최대 5장)</span>
                                        </label>
                                        <input
                                            type="file"
                                            id="img"
                                            // {...register('uploadFiles')}
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div className="imglistBox">
                                        <ul className="no-scroll">
                                            {previewImages.map((imageUrl, index) => (
                                                <li key={index}>
                                                    <img src={imageUrl} key={imageUrl} />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemovePreview(index)}
                                                        className="btn-file-delet"
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>파일첨부<br />(PDF, HWP)</h3>
                                <div className="cont-box">
                                    <div className="fileBox">
                                        <input className="upload-name" defaultValue="선택한 파일 없음" placeholder="선택한 파일 없음" style={{ display: 'none' }} />
                                        <label htmlFor="file">파일 선택</label>
                                        <input
                                            type="file"
                                            id="file"
                                            accept="file/*"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <div className="filelistBox mt10">
                                        <ul>
                                            {previewFiles.map((file, index) => (
                                                <li key={index}>
                                                    <p key={`${index}_${file}`} className="txt_cut1">
                                                        {typeof file === 'string' ? '' : file.name}
                                                    </p>
                                                    <p className='delet'>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveFilePreview(index)}
                                                        >삭제</button>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>      
                        </div>
                        <div className="comm_btn_wrap">
                            <button
                                type="button"
                                className="btn_cancel"
                                onClick={handleCancle}
                            >
                                취소
                            </button>
                            <button
                                type="button"
                                className={`btn_next ${content !== '' ? 'on' : ''}`}
                                disabled={content !== '' ? false : true}
                                onClick={handleModify}
                            >
                                수정
                            </button>{' '}
                            {/* 모두 체크 시 on클래스 붙음*/}
                        </div>
                    </form>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

export default Modify_NoticePage;
