import React, { useEffect, useState } from 'react';
import './Write_VillageHistoryDialog.scss';
import { DialogProps, useDialog } from '../DialogProvider';
import { CaseInfo, CaseInputForm, TccfList } from '../../models/IntroInfo';
import IntroAPI from '../../apis/IntroAPI';
import { SubmitHandler, useForm } from 'react-hook-form';
import { data } from 'jquery';
import ModifySuccessDialog from '../common/ModifySuccessDialog';
import ModifyFailDialog from '../common/ModifyFailDialog';
import ic_cam from '../../assets/ic_cam.png';
import ImageDialog from '../common/ImageDialog';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
interface Modify_VillageHistoryDialog extends DialogProps {
	type?: '';
}
const Modify_VillageCaseDialog = ({
	...props
}: Modify_VillageHistoryDialog) => {
	const { showDialog } = useDialog();
	const tcUid = sessionStorage.getItem('town');
	const tccUid = sessionStorage.getItem('tccUid');
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<CaseInputForm>();
	// 첨부파일
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	// 파일 불러오기
	const [fileList, setFileList] = useState([] as File[]);
	const [updateImgList, setUpdateImgList] = useState<TccfList[]>([]);
	const [deleteImgFileIds, setDeleteImgFileIds] = useState([] as number[]);
	// 단일 조회
	const [caseOne, setCaseOne] = useState<CaseInfo>();
	const handleClose = () => {
		props.onClose?.();
		window.location.reload();
	};
	// 사례 단일 조회
	useEffect(() => {
		IntroAPI.getCaseOne(Number(tccUid)).then((res) => {
			// console.log('getCaseOne', res);
			setCaseOne(res);
			setValue('name', res?.name);
			setValue('content', res?.content);

			if (res?.tccfList) {
				const files: File[] = [];
				const promises: Promise<any>[] = [];

				for (let i = 0; i < res.tccfList.length; i++) {
					const promise = IntroAPI.getImg({
						savedPath: res.tccfList[i].filePath,
						// download: false,
					}).then((data) => {
						// console.log(' ::::::::', res);

						if (data) {
							const file = new File([data.data], res.tccfList[i].fileRealName, {
								type: 'image/png',
							});
							files.push(file);
							// // 미리보기 이미지 추가
							const previewImage = `/api/attach/getFile?savedPath=${res.tccfList[i].filePath}&id=${res.tccfList[i].id}`;
							setPreviewImages((prevImages) => [...prevImages, previewImage]);
						}
					});

					promises.push(promise);
				}

				// Promise.all(promises).then(() => {
				// 	setFileList(files);
				// });
			}
		});
	}, []);
	// 사진 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 5개 까지 업로드 할 수있다.');
			showDialog(ImageDialog);
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 사진 삭제
	const handleRemovePreview = (index: number, id?: number) => {
		// console.log('사진삭제', id);
		if (id) {
			setDeleteImgFileIds((cur) => [...cur, id]);
		}
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 에러 메시지
	const [errorName, setErrorName] = useState('');
	const [errorContent, setErrorContent] = useState('');
	// 등록
	const onSubmit: SubmitHandler<CaseInputForm> = async (data) => {
		const formDataToSubmit = new FormData();
		Array.from(data.uploadFiles || []).forEach((image) => {
			formDataToSubmit.append('images', image);
		});

		if (!data.name) {
			setErrorName('사례명을 입력해주세요.');
			return;
		}
		if (!data.content) {
			setErrorContent('사례내용을 입력해주세요.');
			return;
		}

		await IntroAPI.updateCase(
			{
				...data,
				tccUid: Number(tccUid),
				tcUid: Number(tcUid),
				name: data.name,
				content: data.content,
			},
			uploadFiles ? uploadFiles : [],
			deleteImgFileIds ? deleteImgFileIds : undefined
		)
			.then((res) => {
				// console.log('updateCase:::::', res);
				const tab = 'case';
				showDialog(ModifySuccessDialog, { tab });
				window.location.reload();
			})
			.catch((e) => {
				showDialog(ModifyFailDialog);
			});
	};
	return (
		<div className="Write_VillageHistoryDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>활동사례 수정하기</h3>
						<a className="popup_close">
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
								onClick={handleClose}
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_popup_content comm_write_wrap mt25">
							<div className="cont-wrap">
								<h3 className="important">사례명</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="사례명을 입력하세요"
										{...register('name')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">사례 내용</h3>
								<div className="cont-box">
									<textarea
										placeholder="내용을 입력하세요"
										{...register('content')}
									></textarea>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>첨부 이미지<br />(최대 15MB)</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" value="" placeholder="" />
										<label htmlFor="img">
											<img src={ic_cam} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											// {...register('uploadFiles')}
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() =>
															handleRemovePreview(
																index,
																Number(imageUrl.split('id=')[1])
															)
														}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Modify_VillageCaseDialog;
