import React, { useEffect, useState } from 'react';
import '../missionYard/List_MissionPage.scss';
import ic_home from '../../assets/ic_home.png';
import ic_prev from '../../assets/ic_prev.png';
import ic_prev2 from '../../assets/ic_prev2.png';
import ic_next from '../../assets/ic_next.png';
import ic_next2 from '../../assets/ic_next2.png';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router';
import Pagination from '../../components/common/Pagination';
import MissionAPI from '../../apis/MissionAPI';
import MissionInfo, { MissionListContent } from '../../models/MissionInfo';
import moment from 'moment';
import errorImg from '../../assets/errorImg.jpg';
import noList from '../../assets/noList.png';
import useMember from '../../hooks/useMember';
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/myEPoint', text: '내 E 포인트' },
];
const List_MissionPage = () => {
	const { member } = useMember();
	const navigate = useNavigate();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	const [missionList, setMissionList] = useState([] as MissionListContent[]);
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	// 미션 만들기
	const handleWrite = () => {
		navigate('/reward_02/mission_04-write');
	};

	// 기본 미션 > 상세
	const handleDetail = (mmiUid: string) => {
		// console.log('기본미션 상세보기 이동', mmiUid);
		sessionStorage.setItem('mmiUid', String(mmiUid));
		navigate(`/reward_02/mission_05-detail?mmiUid=${mmiUid}`);
	};

	// select 페이지 이동
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		if (window.document.location.href.includes('villageMission_03-list')) {
			setSelectedValue('villageMission');
		}
		if (window.document.location.href.includes('mission_03-list')) {
			setSelectedValue('basicMission');
		}
	}, [window.document.location.href]);
	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = e.target.value;
		// console.log('selectedValue', selectedValue);

		// 선택된 옵션에 따라 페이지 이동을 처리
		if (selectedValue === 'villageMission') {
			setSelectedValue(e.target.value);
			navigate('/reward_02/villageMission_03-list');
		} else if (selectedValue === 'basicMission') {
			setSelectedValue(e.target.value);
			navigate('/reward_02/mission_03-list');
		}
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const now = new Date();
	const seoulTime = now.toLocaleString('en-US', { timeZone: 'Asia/Seoul' });
	const nowDate = `${moment(seoulTime).format('yyyy.MM.DD')}`;
	// console.log('nowDate:::::', nowDate);
	const today = new Date(nowDate);
	const nowDateMonth = `${moment(today).format('yyyy.MM.DD')}`;

	useEffect(() => {
		MissionAPI.getMissionList({
			page: page - 1,
			size: 6,
			mssnDe: '기본미션',
			// sort: 'prgrsSt,ASC',
		}).then((res) => {
			// console.log('getMissionList:::::', res);
			// console.log('res.content', typeof res.content[1]?.status);
			setMissionList(res.content);
			if (res.content) {
				res.content.map((list) => {
					const formattedStartDate = `${moment(list.startDt).format(
						'yyyy.MM.DD'
					)}`;
					const formattedEndDate = `${moment(list.endDt).format('yyyy.MM.DD')}`;
					const stDt = new Date(formattedStartDate);
					const edDt = new Date(formattedEndDate);
					// // console.log('엔드 데이트', edDt);
					if (today > edDt) {
						list.status = '마감';
					} else if (today >= stDt && today <= edDt) {
						list.status = '진행';
					} else {
						list.status = '대기';
					}
					// list.missionFiles.map((list2: any, index2: number) => {
					// 	list.thumbnail = list2.filePath;
					// });
					// list.startDate = formattedStartDate;
					// list.endDate = formattedEndDate;
					// // console.log('뭐임', formattedStartDate);
				});
			}
			setCount(res.totalElements);
		});
	}, [page]);
	// 인증 완료 : 로그인한 회원이 기간 내 제한된 횟수의 인증을 모두 완료한 경우 인증완료로 표시되고 인증 페이지 이동 제한 설정
	// 마감 : 인증기간이 종료되었거나 로그인한 회원이 소속된 마을에서 사용할 수 있는 포인트가 모두 소진된 경우 마감으로 표시되고 인증 페이지 이동 이동 제한 설정
	// 대기 : 인증기간에 도달하지 않은 경우 대기로 표시되고 인증 페이지 이동 이동 제한 설정
	// 매일/매월/기간내 n회 참여 가능 : 진행중 상태1, 참여할 수 있는 수치를 보여주고 내가 이미 참여한 경우 수치 차감하여 출력/비회원은 참여할 수 있는 수치 고정

	return (
		<main className="List_MissionPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 활동미션"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>소통마당</h3>
					<select onChange={handleSelectChange} value={selectedValue}>
						<option value="basicMission">기본미션</option>
						<option value="villageMission">마을미션</option>
					</select>
					<p>개인이 일상에서 간단하게 에너지전환 활동을 실천하고 인증해요</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="comm_tool">
						{/* <p>
							{nowDateMonth.slice(5, 7)}월 적립한 포인트&nbsp;
							<span>1,000 E</span> / 10,000 E
						</p> */}
						{/* 총괄만 가능 */}
						{member?.roles === 'AMN' ? (
							<>
								<div className="box_comm_write_button" onClick={handleWrite}>
									<button type="button" className="comm_write_button">
										미션 만들기
									</button>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
					{missionList && missionList?.length !== 0 ? (
						<>
							{/* 최신순 정렬 */}
							<div className="mission_basic_list mt25">
								<ul>
									{missionList &&
										missionList?.map((list, idex) => (
											<li
												className={list?.visible === 'N' ? 'non' : ''}
												onClick={() => handleDetail(String(list.mmiUid))}
												key={idex}
											>
												{/* 비공개 게시물 non클래스 붙음 */}
												<a className="pa30">
													{/* 이미지 없으면 noimg class 붙음 */}
													<div
														// className="box_missionBasic"
														className={
															list?.missionFiles?.length === 0
																? 'box_missionBasic noimg'
																: 'box_missionBasic'
														}
													>
														<div className="missionBasic_txt">
															<div className="state">
																<span
																	className={`${list.prgrsSt === '진행' ? 'ing' : ''
																		}`}
																>
																	{/* 상태값이 진행 &&  회원 && 인증을 했다 ? '매월 mxCertCo-(인증한 횟수)회 참여가능'
																	만약 (인증한 횟수 === mxCertCo) ? '인증완료'
																	 certPdDe에 따라 mxCertCo는 리셋되어야 함
																	*/}
																	{/* 상태값이 진행 && 비회원 ? '매월 mxCertCo회 참여가능 */}
																	{list?.prgrsSt === '진행' &&
																		list?.certPdDe === 'D' &&
																		Number(list?.mxCertCo) -
																		Number(list?.certCnt) >
																		0
																		? `매일 ${Number(list?.mxCertCo) -
																		Number(list?.certCnt)
																		}회 참여가능`
																		: list?.prgrsSt === '진행' &&
																			list?.certPdDe === 'D' &&
																			Number(list?.mxCertCo) -
																			Number(list?.certCnt) ===
																			0
																			? '인증완료'
																			: list?.prgrsSt === '진행' &&
																				list?.certPdDe === 'M' &&
																				Number(list?.mxCertCo) -
																				Number(list?.certCnt) >
																				0
																				? `매달 ${Number(list?.mxCertCo) -
																				Number(list?.certCnt)
																				}회 참여가능`
																				: list?.prgrsSt === '진행' &&
																					list?.certPdDe === 'M' &&
																					Number(list?.mxCertCo) -
																					Number(list?.certCnt) ===
																					0
																					? `인증완료`
																					: list?.prgrsSt === '진행' &&
																						list?.certPdDe === 'P' &&
																						Number(list?.mxCertCo) -
																						Number(list?.certCnt) >
																						0
																						? `기간 내 ${Number(list?.mxCertCo) -
																						Number(list?.certCnt)
																						}회 참여가능`
																						: list?.prgrsSt === '진행' &&
																							list?.certPdDe === 'P' &&
																							Number(list?.mxCertCo) -
																							Number(list?.certCnt) ===
																							0
																							? '인증완료'
																							: list?.prgrsSt === '대기'
																								? '대기'
																								: list?.prgrsSt === '종료'
																									? '마감'
																									: '인증횟수 초과'}
																	{/* {list?.status} */}
																</span>
																<span className="state_number">
																	{list?.redcnDe === '전력'
																		? '전력절감 인증미션'
																		: list?.redcnDe === '가스'
																			? '도시가스절감 인증미션'
																			: list?.redcnDe === '별도'
																				? '별도 인증미션'
																				: '절감 구분'}
																</span>
															</div>
															<h4 className="comm_title txt_cut1 mt10">
																{list?.mssnNm}
															</h4>
															<p className="txt_cut1">
																{' '}
																{`${moment(list.startDt).format('yyyy.MM.DD')}`}
																{'~'}
																{`${moment(list.endDt).format('yyyy.MM.DD')}`}
															</p>
															<p className="point">
																{list?.pymntPt
																	?.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
																E
															</p>
														</div>
														<div className="missionBasic_img">
															{list?.missionFiles?.length !== 0 ? (
																list?.missionFiles
																	.filter(
																		(file) => file?.imgGb === '미션이미지'
																	)
																	.map((missionFile, index) => (
																		<img
																			key={index}
																			src={`/api/attach/getFile?savedPath=${missionFile?.savePath}`}
																			alt="지도 이미지"
																		/>
																	))
															) : (
																<img src={errorImg} alt="지도 이미지" />
															)}
														</div>
													</div>
												</a>
											</li>
										))}
								</ul>
							</div>
						</>
					) : (
						<>
							{' '}
							<div className="comm_noList">
								<img src={noList} alt="내용없음 이미지" />
								<p className="mt25">{nullListMessage}</p>
							</div>{' '}
						</>
					)}

					<Pagination
						page={page}
						// totalPageCount={Math.ceil(8 / 8)}
						totalPageCount={Math.ceil(count / 6)}
						onPageChange={handlePageChange}
					/>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default List_MissionPage;
