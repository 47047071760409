// 관리자 메뉴 API

import axios from 'axios';

export interface GetParams {
	mssnDe?: string;
	title?: string;
	townName?: string;
	prgrsSt?: string;
	size?: number;
	page?: number;
}
// 활동 통계 목록 조회
const getStaticList = (params: GetParams) => {
	return axios.get('/api/admin/active', { params }).then((res) => res as any);
};
const AdminAPI = {
	getStaticList,
};
export default AdminAPI;
