import React, { useEffect, useRef, useState } from 'react';
import IntroInfo from '../../../models/IntroInfo';

// 마을 소개 거점센터 위치 맵
const Map_IntroMainPage = ({
	townIntro,
}: {
	townIntro: IntroInfo | undefined;
}) => {
	const [coord, setCoord] = useState<any>();
	const [centerName, setCenterName] = useState<string>('');
	// String(townIntro?.location) ? String(townIntro?.location) : '도착'
	const resizeTargetRef = useRef(null);
	useEffect(() => {
		// console.log('townIntro:::::', townIntro);
		setCenterName(String(townIntro?.centerName));
		var geocoder = new window.kakao.maps.services.Geocoder();
		// 주소로 좌표를 검색합니다
		geocoder.addressSearch(
			`${townIntro?.location}`,
			(result: any, status: any) => {
				// 정상적으로 검색이 완료됐으면
				if (status === window.kakao.maps.services.Status.OK) {
					var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
					setCoord(coords);
					// console.log('위치', coords);
					var markerPosition = new window.kakao.maps.LatLng(
						coords.Ma,
						coords.La
					);
					var marker = {
						position: markerPosition,
					};
					var staticMapContainer = document.getElementById('staticMap'), // 이미지 지도를 표시할 div
						staticMapOption = {
							center: new window.kakao.maps.LatLng(coords.Ma, coords.La), // 이미지 지도의 중심좌표
							level: 3, // 이미지 지도의 확대 레벨
							marker: marker, // 이미지 지도에 표시할 마커
						};

					// 이미지 지도를 생성합니다
					var staticMap = new window.kakao.maps.StaticMap(
						staticMapContainer,
						staticMapOption
					);
					const resizeObserver = new ResizeObserver((entries) => {
						for (const entry of entries) {
							// const { target, contentRect } = entry;
							// // console.log(`Element ${target.id} dimensions: ${contentRect.width}x${contentRect.height}`);
							// staticMap.relayout();
							staticMap.setCenter(
								new window.kakao.maps.LatLng(coords.Ma, coords.La)
							);
						}
					});
					if (resizeTargetRef.current) {
						resizeObserver.observe(resizeTargetRef.current);
					}
				}
			}
		);
	}, [townIntro]);

	const clickStaticMap = (event: any) => {
		// 이벤트 막기
		event.preventDefault();
		// 카카오맵 길안내
		if (coord?.Ma && coord?.La) {
			window.open(
				`https://map.kakao.com/link/to/${centerName},${coord.Ma},${coord.La}`,
				'_blank'
			);
		}
	};
	return (
		<>
			<div className="pa20">
				{townIntro?.location} {townIntro?.subLocation}
				<div className="box_map" ref={resizeTargetRef}>
					<div
						id="staticMap"
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
						}}
						onClick={clickStaticMap}
					/>
				</div>
			</div>
		</>
	);
};

export default Map_IntroMainPage;
