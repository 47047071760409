import React from 'react';
import { DialogProps } from '../DialogProvider';
import '../Login/LoginDialog.scss';
import { useNavigate } from 'react-router';
interface JoinInfoPrevDialog extends DialogProps {
	type: '';
}

const JoinInfoPrevDialog = ({ ...props }: JoinInfoPrevDialog) => {
	const navigate = useNavigate();
	const handleClose = () => {
		props.onClose?.();
		return;
	};
	const handleConfirm = () => {
		props.onClose?.();
		navigate('/join_04/village');
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>회원가입 정보입력</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">
						이전 페이지로 돌아가면 입력하신 개인정보가 초기화됩니다.
						이동하시겠습니까?
					</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button type="button" className="btn_check" onClick={handleConfirm}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};
export default JoinInfoPrevDialog;
