import React, { useEffect, useState } from 'react';
import '../missionYard/Main_ChallengePage.scss';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/common/Pagination';
import img_challenge from '../../assets/img_challenge.png';
import ChallengeAPI from '../../apis/ChallengeAPI';
import { ChallengeContent } from '../../models/ChallengeInfo';
import moment from 'moment';
import noList from '../../assets/noList.png';
import useMember from '../../hooks/useMember';

const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/myEPoint', text: '내 E 포인트' },
];
const Main_ChallengePage = () => {
	const { member } = useMember();
	const navigate = useNavigate();
	const [challengeList, setChallengeList] = useState([] as ChallengeContent[]);
	const [page, setPage] = useState<number>(1);
	const [prgrsSt, setPrgrsSt] = useState<string>();
	const [count, setCount] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const handleList = () => {
		navigate('/reward_02/challenge_05-list');
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// 챌린지 목록 조회
	useEffect(() => {
		ChallengeAPI.getMyChallengeList({
			my: 'my',
			prgrsSt: prgrsSt ? prgrsSt : undefined,
			// sort: 'prgrsSt,ASC&sort=crtDt,DESC',
		}).then((res) => {
			// console.log('challengeList:::::', res);
			// // console.log(':::::::::', res.content[0].prgrsSt.split(''));

			setChallengeList(res.content);
			setTotalPage(res.totalPages);
		});
	}, [page, prgrsSt]);
	// 챌린지 디테일
	const handleDetail = (mciUid: string, mypoint: string, rank: string) => {
		navigate('/reward_02/challenge_05-detail');
		sessionStorage.setItem('mciUid', String(mciUid));
		sessionStorage.setItem('mypoint', String(mypoint));
		sessionStorage.setItem('rank', String(rank));
	};

	return (
		<main className="Main_ChallengePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 챌린지"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>에너지전환 챌린지</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>목표 포인트를 모아 추가 포인트를 획득하는 챌린지에 참여하세요.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="challenge_info_wrap">
						<img src={img_challenge} alt="챌린지 이미지" />
						<div className="info_content">
							<h3>목표 포인트를 먼저 달성하는 사람이 승리!</h3>
							<p>
								챌린지 기간 동안 마을/기본미션 활동에서 획득한 E 포인트로 승리를
								결정!
								<br />
								목표로 정한 E 포인트를 달성한 순서대로 순위가 정해지고, 정해진
								규칙대로 포인트를 배분해요
							</p>
							<button type="button" onClick={handleList}>
								참여하기
							</button>
						</div>
					</div>
					{member && member?.id && (
						<div className="challenge_myList_wrap mt40">
							<div className="myList_title">
								<h3 className="comm_title">내 챌린지 현황</h3>
								<ul>
									<li>
										<p
											className={prgrsSt === '' ? 'on' : ''}
											onClick={() => setPrgrsSt('')}
										>
											전체
										</p>
									</li>
									<li>
										<p
											className={prgrsSt === '진행' ? 'on' : ''}
											onClick={() => setPrgrsSt('진행')}
										>
											진행중
										</p>
									</li>
									<li>
										<p
											className={prgrsSt === '종료' ? 'on' : ''}
											onClick={() => setPrgrsSt('종료')}
										>
											종료
										</p>
									</li>
								</ul>
							</div>
							{challengeList && challengeList.length === 0 ? (
								<>
									<div className="comm_noList">
										<img src={noList} alt="내용없음 이미지" />
										<p className="mt25">현재 참여한 챌린지가 없습니다.</p>
									</div>{' '}
								</>
							) : (
								<>
									<div className="myList_list mt25">
										<ul>
											{challengeList &&
												challengeList.map((list, idex) => (
													/* 비공개 non  */
													<li
														className={list.openYn === 'N' ? 'non' : ''}
														key={idex}
														onClick={() =>
															handleDetail(
																String(list?.mciUid),
																String(list?.myPoint),
																String(list?.rank)
															)
														}
													>
														<a className="pa30">
															<div className="box_challenge">
																<div className="state">
																	<span
																		className={`${list.prgrsSt === '진행'
																				? 'ing'
																				: list.prgrsSt === '대기'
																					? 'prev'
																					: list.prgrsSt === '종료'
																						? 'end'
																						: ''
																			}`}
																	>
																		{list?.prgrsSt}
																	</span>
																</div>
																<h4 className="comm_title txt_cut1 mt25">
																	{list?.challengeName}
																</h4>
																<p className="mt5">
																	{`${moment(list.startDt).format(
																		'YYYY.MM.DD'
																	)}`}
																	{'~'}
																	{`${moment(list.endDt).format('YYYY.MM.DD')}`}
																</p>
																<div className="myRanking pa20 mt10">
																	{list?.rank !== null &&
																		list?.myPoint !== null ? (
																		<>
																			<p>
																				내 순위{' '}
																				<span className="txt_blue">
																					{list?.rank}위
																				</span>
																				&nbsp;/&nbsp;참여자수{' '}
																				<span>{list?.participateCnt}명</span>
																			</p>
																			<p className="mt5">
																				내 포인트{' '}
																				<span className="txt_blue">
																					{list?.myPoint
																						?.toString()
																						.replace(
																							/\B(?=(\d{3})+(?!\d))/g,
																							','
																						)}{' '}
																					E
																				</span>
																				&nbsp;/&nbsp;목표 포인트{' '}
																				<span>
																					{list?.goalPt
																						.toString()
																						.replace(
																							/\B(?=(\d{3})+(?!\d))/g,
																							','
																						)}{' '}
																					E
																				</span>
																			</p>
																		</>
																	) : (
																		<>
																			{' '}
																			<p>
																				참여자수{' '}
																				<span>{list?.participateCnt}명</span>
																			</p>
																			<p className="mt5">
																				목표 포인트{' '}
																				<span>
																					{list?.goalPt
																						.toString()
																						.replace(
																							/\B(?=(\d{3})+(?!\d))/g,
																							','
																						)}{' '}
																					E
																				</span>
																			</p>
																		</>
																	)}
																</div>
															</div>
														</a>
													</li>
												))}
										</ul>
									</div>{' '}
								</>
							)}

							<Pagination
								page={1}
								totalPageCount={Math.ceil(8 / 8)}
								// totalPageCount={Math.ceil(count / 8)}
								onPageChange={handlePageChange}
							/>
						</div>
					)}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Main_ChallengePage;
