import React, { useEffect, useRef, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './JoinVillagePage.scss';
import { useNavigate } from 'react-router';
import { useDialog } from '../../dialog/DialogProvider';
import JoinVillageDialog from '../../dialog/Join/JoinVillageDialog';
import TownAPI from '../../apis/TownAPI';
import TownList from '../../models/TownList';
const villageList = [
	{ villageTitle: '첨단마을', villageText: '첨단1동, 첨단2동' },
	{ villageTitle: '수완마을', villageText: '수완동' },
	{ villageTitle: '지원1마을', villageText: '지원1동' },
	{ villageTitle: '지산2마을', villageText: '지산2동' },
	{ villageTitle: '풍암마을', villageText: '풍암동' },
	{ villageTitle: '양3마을', villageText: '양3동' },
	{ villageTitle: '양림마을', villageText: '양림동' },
	{ villageTitle: '사직마을', villageText: '사직동' },
	{ villageTitle: '일곡마을', villageText: '일곡동' },
	{ villageTitle: '문산마을', villageText: '문흥1동, 문흥2동, 오치1동' },
	{ villageTitle: '선택 안함', villageText: '' },
];

const JoinVillagePage = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [selectVillage, setSelectVillage] = useState<string | undefined>();
	const [townList, setTownList] = useState<TownList>();
	const handleSelectVillage = (village: string) => {
		setSelectVillage(village);
		if (village == selectVillage) {
			setSelectVillage(undefined);
		}
	};
	useEffect(() => {
		TownAPI.getTownList()
			.then((res) => {
				if (res) {
					// console.log("마을 목록", res)
					res.content.map((list, index) => {
						list.townTitle = list.districtDTOList.map((item) => item.districtName).join(', ');
					})
					setTownList(res);
				}
			})
	}, [])
	useEffect(() => {
		// console.log('selectVillage !', selectVillage);
	}, [selectVillage]);
	const handlePre = () => {
		navigate('/join_04/*');
	};
	const handleNext = () => {
		if (!selectVillage) {
			// alert(
			// 	'마을 선택은 필수입니다. 활동 마을이 없다면 "선택 안함" 을 체크해주세요'
			// );
			showDialog(JoinVillageDialog);
			return;
		} else {
			navigate(`/join_04/info`);
			sessionStorage.setItem('selectVillage', selectVillage)
		}
	};
	return (
		<main className="JoinVillagePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>회원가입</h3>

					<p>
						마을선택
						<br />
						(마을 미선택 시 마을미션 참여 불가하며, 가입 후 선택이 가능합니다.)
					</p>
				</div>
			</nav>
			{/* <article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
	
				<div id="content">
					마을선택페이지
					<div>
						<h2>마을선택 기능</h2>
						{villageList.map((list, index) => (
							<label key={index}>
								{list.villageTitle}
								<input
									type="checkbox"
									value={list.villageTitle}
									onChange={(e) => handleSelectVillage(e.target.value)}
									checked={list.villageTitle == selectVillage}
								/>
							</label>
						))}
						<p>선택한 마을 : {selectVillage ? selectVillage : '없음'}</p>
					</div>
				</div>
			</article> */}
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<p className="only_mobile">
						마을선택
						<br />
						(마을 미선택 시 마을미션 참여 불가하며, <br />
						가입 후 선택이 가능합니다.)
					</p>
					<form
						onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
							e.preventDefault();
						}}
					>
						<ul className="village_list">
							{townList?.content.map((list, index) => (
								<li key={index}>
									<label>
										<input
											type="radio"
											id="radio1-1"
											name="radio1"
											value={list.townId}
											onChange={(e) => handleSelectVillage(e.target.value)}
										// checked={list.villageTitle == selectVillage}
										/>
										<p>
											{/* {list.districtDTOList.map((list2, index2) => (list2.districtName))} */}
											{list.townTitle}
											<span>{list.townName}</span>
										</p>
									</label>
								</li>
							))}
							<li>
								<label>
									<input
										type="radio"
										id="radio1-1"
										name="radio1"
										value={0}
										onChange={(e) => handleSelectVillage(e.target.value)}
									// checked={list.villageTitle == selectVillage}
									/>
									<p>
										{/* {list.districtDTOList.map((list2, index2) => (list2.districtName))} */}
										<span>선택안함</span>
									</p>
								</label>
							</li>
						</ul>
						<div className="comm_btn_wrap">
							<button type="button" className="btn_cancel" onClick={handlePre}>
								이전
							</button>
							<button
								type="submit"
								// className="btn_next"
								className={selectVillage ? 'btn_next on' : 'btn_next'}
								onClick={handleNext}
							>
								다음
							</button>
							{/* 마을 선택 시 완료버튼 클래스네임에 on 붙음 -> 파란색 버튼으로 바뀜
									완료 버튼이 회색일 경우에는 넘어가면 안됨
								*/}
						</div>
					</form>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default JoinVillagePage;
