import axios from "axios";
import { serialize } from "object-to-formdata";
import { PopupListInfo } from "../models/PopupManager";

interface GetAllParams {
    title?: string;
    status?: string;
    page?: number;
    pageSize?: number;
    npuUid?:number;
}

const insertPopup = (params: GetAllParams, uploadFiles?: File[] | FileList) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        if(value !== undefined){
            formData.append(key, value);
        }
		// 파일 추가
	});

    if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}

    return axios
    .post('/api/popup/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
    .then((res) => res as any);
}

const updatePopup = (params: GetAllParams, uploadFiles?: File[] | FileList, deleteFileIds?: number[]) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        if(value !== undefined){
            formData.append(key, value);
        }
	});

    if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}

    if (deleteFileIds?.length !== 0) {
		if (deleteFileIds) {
			const fileDel = Array.from(deleteFileIds);
			for (let i = 0; i < fileDel.length; i++) {
				if (fileDel[i]) {
					formData.append(`deletefileList[${i}].npfUid`, String(fileDel[i]));
				}
			}
		}
	}

    return axios
    .post('/api/popup/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
    .then((res) => res as any);
}

const deletePopup = (params: GetAllParams) => {
    return axios
    .post('/api/popup/delete', serialize(params))
    .then((res) => res as any)
}

const getPopupList = (params: GetAllParams) => {
    return axios
    .get('/api/popup', {params})
    .then((res) => res.data as PopupListInfo[])
}

const getPopupDetail = (params: GetAllParams) => {
    return axios
    .get('/api/popup/get',{params})
    .then((res) => res.data as PopupListInfo)
}

const PopupAPI = {
    insertPopup,
    updatePopup,
    deletePopup,
    getPopupList,
    getPopupDetail,
}
export default PopupAPI;