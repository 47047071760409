import CloseIcon from '../../assets/ic_close.png';
import KaKaoMapComponentTest from '../../components/common/KaKaoMapComponentTest';
import { DialogProps } from '../DialogProvider';
import { useEffect, useState } from 'react';

interface LocationDialog extends DialogProps {
	handleAddressSubmit: (lat: number, lng: number) => void;
	// handleAddressSubmit: (address: string) => void;
}

const LocationDialog = ({ handleAddressSubmit, ...props }: LocationDialog) => {
	const [selectLat, setSelectLat] = useState<number>();
	const [selectLng, setSelectLng] = useState<number>();
	const [address, setAddress] = useState<string>('');
	const geocoder = new window.kakao.maps.services.Geocoder();
	const handleClose = () => {
		props.onClose?.();
	};

	const handleSelectLatLng = (lng: number, lat: number) => {
		// console.log("엘에이티", lat);
		// console.log("엘엔쥐", lng);
		setSelectLat(lat);
		setSelectLng(lng);
		// 좌표를 주소로 변환
		geocoder.coord2Address(lat, lng, (result: any, status: any) => {
			if (status === window.kakao.maps.services.Status.OK) {
				if (result[0]) {
					const address = result[0].address.address_name;
					// console.log("주소:", address);
					setAddress(address);
				} else {
					// console.log("주소를 찾을 수 없습니다.");
				}
			} else {
				// console.log("Geocoder 실패:", status);
			}
		});
	};
	const handleSubmit = () => {
		handleAddressSubmit(Number(selectLat), Number(selectLng));
		// handleAddressSubmit(address);
		props.onClose?.();
	};

	const SelectAddress = (address: string) => {
		setAddress(address);
	};

	return (
		<div className="comm_popup sharedSpacePage" {...props}>
			<div
				className="comm_popup_wrap pa30"
				style={{
					maxWidth: '800px',
				}}
			>
				<div className="comm_popup_title">
					<h3>위치찾기</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>

				<div className="comm_popup_content mt25">
					<KaKaoMapComponentTest handleSelectLatLng={handleSelectLatLng} />

					<div
						className="ad_name"
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							marginTop: '15px',
						}}
					>
						<p style={{}}>위치 : {address}</p>
						<button
							type="button"
							onClick={handleSubmit}
							style={{
								backgroundColor: 'white',
								border: '1px solid #258cef',
								borderColor: '#258cef',
								borderRadius: '5px',
								fontSize: '14px',
								width: '80px',
								padding: '5px 0',
								fontWeight: '500',
								color: '#258cef',
								marginLeft: 'auto',
							}}
						>
							선택완료
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocationDialog;
