import React, { useEffect, useState } from 'react';
import '../missionYard/Write_ChallengePage.scss';
import { useDialog } from '../../dialog/DialogProvider';
import AddVillMissionDialog from '../../dialog/MissionYard/AddVillMissionDialog';
import AddMissionDialog from '../../dialog/MissionYard/AddMissionDialog';
import ChallengeAPI from '../../apis/ChallengeAPI';
import DetailChanllengeInfo from '../../models/DetailChallengeInfo';
import { SubmitHandler, useForm } from 'react-hook-form';
import ChallengeInput, { SelectChallengeMissions } from '../../models/ChallengeInput';
import { useNavigate } from 'react-router';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import moment from 'moment';
const Modify_ChallengePage = () => {
	const mciUid = sessionStorage.getItem('mciUid');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [selectVillMission, setSelectVillMission] = useState<SelectChallengeMissions[]>([]);
	const [selectMission, setSelectMission] = useState<SelectChallengeMissions[]>([]);
	const [mexJoinCount, setMaxJoinCount] = useState(true);
	const [topRank, setTopRank] = useState([] as string[]);
	const [pymntPt, setPymntPt] = useState([] as string[]);
	const [openYn, setOpenYn] = useState<string>();
	const [challengeDetail, setChallengeDetail] = useState<DetailChanllengeInfo>();
	const { register, setValue, getValues, setFocus, watch, handleSubmit, formState: { errors } } = useForm<ChallengeInput>()
	const mxJoinMbr = watch('mxJoinMbr');

	useEffect(() => {
		ChallengeAPI.getModifyChallengeOne({
			mciUid: Number(mciUid),
		})
			.then((res) => {
				// console.log("챌린지 상세 >> ", res)
				setChallengeDetail(res);
				setOpenYn(res.openYn);
				setMaxJoinCount(res.mxJoinMbr == 0 ? true : false);
				if (res.mxJoinMbr != 0) {
					setValue('mxJoinMbr', String(res.mxJoinMbr));
				}
				setValue('challengeName', res.challengeName);
				setValue('startDt', moment(res.startDt).format('YYYY-MM-DD'))
				setValue('endDt', moment(res.endDt).format('YYYY-MM-DD'))
				if (res.toDoMissions) {
					res.toDoMissions.map((list, index) => {
						if (list.mssnDe === '마을미션') {
							setSelectVillMission((cur) => [...cur, {
								mmiUid: list.mmiUid,
								mssnNm: list.mssnNm,
								mssnDe: list.mssnDe
							}]);
						}
						if (list.mssnDe === '기본미션') {
							setSelectMission((cur) => [...cur, {
								mmiUid: list.mmiUid,
								mssnNm: list.mssnNm,
								mssnDe: list.mssnDe
							}]);
						}
					})
				}
				setValue('goalPt', String(res.goalPt));
				if (res.challengeRewards) {
					res.challengeRewards.map((list, index) => {
						// console.log(index);
						setTopRank((cur) => [...cur, String(list.topRank)])
						setPymntPt((cur) => [...cur, String(list.pymntPt)])
					})
				}
			})
	}, [])

	// useEffect(() => {
	// 	if (!mexJoinCount) {
	// 		if (Number(mxJoinMbr) < topRank.length) {
	// 			setTopRank((cur) => {
	// 				const removeRank = [...cur];
	// 				for (let i = 0; i < topRank.length - Number(mxJoinMbr); i++) {
	// 					removeRank.splice(removeRank.length - 1, 1);
	// 				}
	// 				return removeRank;
	// 			});
	// 			setPymntPt((cur) => {
	// 				const removePoint = [...cur];
	// 				for (let i = 0; i < topRank.length - Number(mxJoinMbr); i++) {
	// 					removePoint.splice(removePoint.length - 1, 1);
	// 				}
	// 				return removePoint;
	// 			})
	// 		}
	// 	}
	// }, [mxJoinMbr])
	const handleOnBlur = () => {
		if (!mexJoinCount) {
			if (Number(mxJoinMbr) < topRank.length) {
				setTopRank((cur) => {
					const removeRank = [...cur];
					for (let i = 0; i < topRank.length - Number(mxJoinMbr); i++) {
						removeRank.splice(removeRank.length - 1, 1);
					}
					return removeRank;
				});
				setPymntPt((cur) => {
					const removePoint = [...cur];
					for (let i = 0; i < topRank.length - Number(mxJoinMbr); i++) {
						removePoint.splice(removePoint.length - 1, 1);
					}
					return removePoint;
				})
			}
		}
	}
	useEffect(() => {
		// console.log("랭크 상태", topRank)
		// console.log("포인트 상태", pymntPt)
	}, [topRank])
	const handleDeleteMission = (mmiUid: number, mssnDe: string) => {
		if (mssnDe === '마을미션') {
			const notChecked = selectVillMission && selectVillMission.filter((item) => item.mmiUid != mmiUid);
			setSelectVillMission(notChecked);
		} else if (mssnDe === '기본미션') {
			const notChecked = selectMission && selectMission.filter((item) => item.mmiUid != mmiUid);
			setSelectMission(notChecked);
		}
	}

	// 마을미션 선택
	const handleVillMission = () => {
		showDialog(AddVillMissionDialog, {
			selectVillMission,
			handleSelectVillMission(select: SelectChallengeMissions[]) {
				// console.log("마을미션 다이얼로그에서 받아온 미션 ", select);
				setSelectVillMission(select);
			}
		});
	};
	// 기본미션 선택
	const handleMission = () => {
		showDialog(AddMissionDialog, {
			selectMissions: selectMission,
			handleSelectMission(select: SelectChallengeMissions[]) {
				// console.log("기본미션 다이얼로그에서 받아온 미션 ", select);
				setSelectMission(select);
			}
		});
	};

	const handleMexJoin = (e: any) => {
		if (e.target.checked) {
			setMaxJoinCount(true);
			setValue('mxJoinMbr', '')
		} else {
			setMaxJoinCount(false);
		}
	}
	const handleAddRewardsList = () => {
		if (!mexJoinCount) {
			if (Number(mxJoinMbr) <= topRank.length) {
				return
			}
		}
		const updateTopRank = [...topRank, String(topRank.length + 1)];
		setTopRank(updateTopRank);
	}
	const handleRankPoint = (index: number, e: any) => {
		setPymntPt((cur) => {
			const updatePoint = [...cur];
			updatePoint[index] = e;
			return updatePoint;
		})
	}
	const handleDeleteRank = () => {
		setTopRank((cur) => {
			const removeRank = [...cur];
			removeRank.splice(topRank.length - 1, 1);
			return removeRank;
		});
		setPymntPt((cur) => {
			const removePoint = [...cur];
			removePoint.splice(topRank.length - 1, 1);
			return removePoint;
		})
	}

	const onSubmit: SubmitHandler<ChallengeInput> = async (data) => {
		if (!selectVillMission && !selectMission) {
			const title = ''
			const message = '미션을 선택해주세요.'
			showDialog(OneButtonAlertDialog, {
				title, message
			})
			return;
		}
		if (topRank.length == 0) {
			const title = ''
			const message = '보상 기준을 추가해주세요.'
			showDialog(OneButtonAlertDialog, {
				title, message
			})
			return;
		}
		if (pymntPt.length == 0 || pymntPt.some((some) => some === '')) {
			const title = ''
			const message = '보상 기준포인트를 입력해주세요.'
			showDialog(OneButtonAlertDialog, {
				title, message
			})
			return;
		}
		ChallengeAPI.updateChallenge({
			...data,
			mciUid: Number(mciUid),
			openYn: String(openYn),
			startDt: data.startDt + ' 00:00:00',
			endDt: data.endDt + ' 23:59:59',
			mxJoinMbr: mexJoinCount ? '0' : mxJoinMbr,
		}, selectVillMission ? selectVillMission : undefined,
			selectMission ? selectMission : undefined,
			pymntPt ? pymntPt : undefined,
			topRank ? topRank : undefined
		).then((res) => {
			const title = '';
			const message = '수정되었습니다';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
				handleDialogResult(result) {
					if (result === 'ok') {
						navigate('/reward_02/challenge_05-list');
					}
				}
			})
		})
	}

	const handleCancle = () => {
		navigate('/reward_02/challenge_05-detail');
	};
	return (
		<main className="Write_ChallengePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<div className="lnb_txt">
					<h3>챌린지 수정하기</h3>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">챌린지명</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="챌린지명을 입력하세요"
										{...register('challengeName', { required: true })}
									/>
									{errors.challengeName && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 챌린지명을 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">챌린지기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											{...register('startDt', { required: true })}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											className="endDate"
											{...register('endDt', { required: true })}
										/>
									</div>
									{(errors.startDt || errors.endDt) && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 챌린지명을 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">미션 선택</h3>
								<div className="cont-box">
									<div className="btn_mission">
										<button type="button" onClick={handleVillMission}>
											마을미션 선택
										</button>
										<button type="button" onClick={handleMission}>
											기본미션 선택
										</button>
									</div>
									<div className="box_mission mt10">
										<ul>
											{selectVillMission && selectVillMission.map((vmList, index) => (
												<li key={index}>
													<p className="cate">{vmList.mssnDe}</p>
													<p className="txt_cut1">{vmList.mssnNm}</p>
													<p className="delet">
														<button
															type="button"
															onClick={() => handleDeleteMission(vmList.mmiUid, vmList.mssnDe)}
														>
															삭제
														</button>
													</p>
												</li>
											))}
											{selectMission && selectMission.map((mList, index) => (
												<li key={index}>
													<p className="cate">{mList.mssnDe}</p>
													<p className="txt_cut1">{mList.mssnNm}</p>
													<p className="delet">
														<button
															type="button"
															onClick={() => handleDeleteMission(mList.mmiUid, mList.mssnDe)}
														>
															삭제
														</button>
													</p>
												</li>
											))}
											{/* <li>
												<p className="cate">마을미션</p>
												<p className="txt_cut1">미션명입니다.</p>
												<p className="delet">
													<button type="button">삭제</button>
												</p>
											</li> */}
										</ul>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">목표 포인트</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long figure"
										placeholder="숫자만 입력"
										{...register('goalPt', { required: true })}
										onChange={(e) => {
											const value = e.target.value.replace(/[^0-9]/g, '');
											setValue('goalPt', value);
										}}
									/>
									E
									{errors.goalPt && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 목표 포인트를 입력해주세요.
										</span>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">최대 참여자수</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long figure"
										placeholder="숫자만 입력"
										{...register('mxJoinMbr', { required: mexJoinCount ? false : true })}
										onChange={(e) => {
											const value = e.target.value.replace(/[^0-9]/g, '');
											setValue('mxJoinMbr', value);
										}}
										readOnly={mexJoinCount}
										onBlur={handleOnBlur}
									/>{' '}
									명
									{!mexJoinCount && errors.mxJoinMbr && (
										<span
											style={{
												color: 'red',
												fontSize: '14px',
											}}
										>
											! 최대 참여자수를 입력해주세요.
										</span>
									)}
									<ul className="village_list mt10">
										<li>
											<label htmlFor="checkbox1-1">
												<input
													type="checkbox"
													id="checkbox1-1"
													name="checkbox1"
													onChange={(e) => handleMexJoin(e)}
													checked={mexJoinCount}
												/>
												<p>제한없음</p>
											</label>
										</li>
									</ul>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">보상 기준</h3>
								<div className="cont-box">
									<button type="button" className="standard-btn" onClick={handleAddRewardsList}>
										기준추가
									</button>
									<div className="standard-box mt25">
										<ul>
											{topRank.map((tList, index) => (
												<li key={index}>
													<p className="rank">
														<input
															type="text"
															placeholder="숫자"
															value={index + 1}
															readOnly
														// {...register(`topRank.${index}`)}
														/>
													</p>
													<p className="point">
														<input
															type="text"
															placeholder="숫자"
															value={pymntPt[index]}
															onChange={(e) => {
																const value = e.target.value.replace(/[^0-9]/g, '');
																handleRankPoint(index, value)
															}}
														// {...register(`pymntPt.${index}`)}
														/>
													</p>
													<p className="delet">
														<button type="button" onClick={handleDeleteRank}>삭제</button>
													</p>
												</li>
											))}
											{/* <li>
												<p className="rank">
													<input type="text" placeholder="숫자" />
												</p>
												<p className="point">
													<input type="text" placeholder="숫자" />
												</p>
												<p className="delet">
													<button type="button">삭제</button>
												</p>
											</li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button type="button" className="btn_cancel" onClick={handleCancle}>
								취소
							</button>
							<button type="submit" className="btn_next on">
								수정
							</button>{' '}
							{/* 모두 체크 시 on클래스 붙음*/}
						</div>
					</form>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Modify_ChallengePage;
