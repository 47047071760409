import React, { ChangeEvent, useEffect, useState } from 'react';
import ActiveMapComponent from '../../../components/common/ActiveMapComponent';
import SubLocation from '../../../components/common/SubLocation';
import CommSelect from '../../../components/common/CommSelect';
import './Detail_ActiveMapPage.scss';
import CommTab from '../CommTab';
import ic_mapinfo from '../../../assets/ic_mapinfo.png';
import ic_mappeople from '../../../assets/ic_mappeople.png';
import ic_delt from '../../../assets/ic_delt.png';
import ic_map_setting from '../../../assets/ic_map_setting.png';
import ic_back from '../../../assets/ic_back.png';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Pagination from '../../../components/common/Pagination';
import { useDialog } from '../../../dialog/DialogProvider';
import Write_ActiveMapDialog, {
	Marker,
} from '../../../dialog/activeMap/Write_ActiveMapDialog';
import { content } from '../../../models/TownList';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import ActiveMapAPI, { CommentInputForm } from '../../../apis/ActiveMapAPI';
import MappingList, {
	CmMappingList,
	CmMasterVO,
} from '../../../models/MappingList';
import PrivateDialog from '../../../dialog/common/PrivateDialog';
import CmPrivateDialog from '../../../dialog/activeMap/CmPrivateDialog';
import ModifyDialog from '../../../dialog/common/ModifyDialog';
import DeleteDialog from '../../../dialog/common/DeleteDialog';
import CmDeleteDialog from '../../../dialog/activeMap/CmDeleteDialog';
import Modify_ActiveMapDialog from '../../../dialog/activeMap/Modify_ActiveMapDialog';
import CmModifyDialog from '../../../dialog/activeMap/CmModifyDialog';
import useMember from '../../../hooks/useMember';
import PrivateSuccessDialog from '../../../dialog/common/PrivateSuccessDialog';
import PrivateFailDialog from '../../../dialog/common/PrivateFailDialog';
import PublicDialog from '../../../dialog/common/PublicDialog';
import CmtNonmemberDialog from '../../../dialog/common/CmtNonmemberDialog';
import { SubmitHandler, useForm } from 'react-hook-form';
import CmtSubmitSuccessDialog from '../../../dialog/common/CmtSubmitSuccessDialog';
import CmtSubmitFailDialog from '../../../dialog/common/CmtSubmitFailDialog';
import CmtDeleteDialog from '../../../dialog/common/CmtDeleteDialog';
import CmtDeleteSuccessDialog from '../../../dialog/common/CmtDeleteSuccessDialog';
import CmtDeleteFailDialog from '../../../dialog/common/CmtDeleteFailDialog';
import InfoDialog from '../../../dialog/activeMap/InfoDialog';
import PeopleDialog from '../../../dialog/activeMap/PeopleDialog';
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import noList from '../../../assets/noList.png';
import ShareDialog from '../../../dialog/common/ShareDialog';
import defaultImg from '../../../assets/img_default.png';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import MemberInfo from '../../../models/MemberInfo';
import moment from 'moment';
import NavComponent from '../../../components/common/NavComponent';

const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];

const Detail_ActiveMapPage = () => {
	const { member } = useMember();
	const [searchParams] = useSearchParams();
	const cmm = searchParams.get('cmm');
	const town = searchParams.get('town');
	const crt = searchParams.get('crt');
	const mapping = searchParams.get('mapping');
	const townId = sessionStorage.getItem('town')
		? sessionStorage.getItem('town')
		: town;
	const cmmUid = sessionStorage.getItem('cmmUid')
		? sessionStorage.getItem('cmmUid')
		: cmm;
	const crtUid = sessionStorage.getItem('crtUid')
		? sessionStorage.getItem('crtUid')
		: crt;
	const mappingCrtUid = sessionStorage.getItem('mappingCrtUid')
		? sessionStorage.getItem('mappingCrtUid')
		: mapping;
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [contentExtend, setContentExtend] = useState(false);
	const [mobileContentExtend, setMobileContentExtend] = useState(false);
	const [clickBtnLeft, setClickBtnLeft] = useState(false);
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	const [townList, setTownList] = useState([] as content[]);
	// const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [townInfo, setTownInfo] = useState([] as content[]);
	// 분류 선택 시
	const [selectedCmcUid, setSelectedCmcUid] = useState<number>();
	// 맵핑 리스트
	const [mappingList, setMappingList] = useState<MappingList>(); // CmMastrtVO
	const [cmMappingList, setCmMappingList] = useState([] as CmMappingList[]);
	const [cmMasterVO, setCmMasterVO] = useState<CmMasterVO | undefined>();
	const [selectedListIndex, setSelectedListIndex] = useState<number | null>(
		null
	);
	const [cmMappingDetail, setCmMappingDetail] = useState<CmMappingList>();
	// 마커
	const [markerList, setMarkerList] = useState([] as Marker[]);
	const [cmUid, setCmUid] = useState<number>();
	const [caTitle, setCaTitle] = useState<string>();
	const [detailData, setDetailDate] = useState<string>();
	const [uploadFiles, setUploadFiles] = useState([] as File[]);

	// 지도 주제 수정
	const [masterModifyRole, setMasterModifyRoles] = useState(false);
	// 맵핑 작성 권한
	const [postRoles, setPostRoles] = useState(false);
	// 맵핑 수정 및 삭제
	const [mappingUdpRole, setMappingUdpRole] = useState(false);
	// 맵핑 비공개
	const [mappingPriRole, setMappingPriRole] = useState(false);
	// 댓글 작성 권한
	const [cmtWriteRole, setCmtWriteRole] = useState(false);
	// 댓글 수정 및 삭제 권한
	const [cmtUdpRole, setCmtUdpRole] = useState(false);
	// 댓글 비공개 권한
	const [cmtPrivateRole, setCmtPrivateRole] = useState(false);
	// 검색어
	const [search, setSearch] = useState<string>();
	// 총괄 권한
	const [generalRoles, setGeneralRoles] = useState(false);
	// noList
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');

	// 검색어를 입력하세요
	const handleSearchKeyword = (search: string | undefined) => {
		setPage(1);
		setSearch(search);
		setNullListMessage('검색된 게시글이 없습니다.');
		// console.log('searchKeyword !!!!!!!!!!!!! ', search);
	};
	const getSearchKeyword = (e: any) => {
		if (handleSearchKeyword) {
			handleSearchKeyword(search);
		}
		if (e.key === 'Enter') {
			if (handleSearchKeyword) {
				e.preventDefault();
				handleSearchKeyword(search);
			}
		}
	};
	useEffect(() => {
		// 처음부터 천천히 다시 해보쟈
		// 관리자/운영자/부운영자/활동가/주민/회원 : 'AMN/MST/SMT/ABR/MBR/STB
		// 1. 최고관리자 : 모든 권한 ㅇㅇ
		// 2. 운영자, 부운영자 > 내 마을 : 주제 작성, 주제 수정, 맵핑 작성, 맵핑 비공개, 댓글 작성, 댓글 비공개
		// 3. 운영자, 부운영자 > 내가 쓴 글 : 맵핑 수정, 맵핑 삭제, 댓글 수정, 댓글 삭제
		// 4. 마을 활동가, 마을 주민 > 내 마을 : 맵핑 작성, 댓글 작성
		// 5. 마을 활동가, 마을 주민 > 내가 쓴 글 : 맵핑 수정 및 삭제, 댓글 수정 및 삭제
		if (member) {
			if (member?.roles === 'AMN') {
				// console.log('aaaaaaaa');
				setGeneralRoles(true);
				setPostRoles(true);
				setMasterModifyRoles(true);
				setMappingUdpRole(true);
				setMappingPriRole(true);
				setCmtWriteRole(true);
				setCmtUdpRole(true);
				setCmtPrivateRole(true);
			} else if (member?.roles === 'MST' || member?.roles === 'SMT') {
				if (member?.townDTO?.townId === Number(townId)) {
					// setMappingUdpRole(true);
					setMasterModifyRoles(true);
					setPostRoles(true);
					setMappingPriRole(true);
					setCmtWriteRole(true);
					setCmtPrivateRole(true);
					// // console.log('맵핑crt >>', mappingCrtUid);
					if (member?.id == Number(mappingCrtUid)) {
						// console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
						setMappingUdpRole(true);
					}
				}
			} else if (member?.roles === 'ABR' || member?.roles === 'MBR') {
				// console.log('eeeeeeeeeee');
				if (member?.townDTO?.townId === Number(townId)) {
					// console.log('fffffffffffff');
					setPostRoles(true);
					setCmtWriteRole(true);
					if (member?.id === Number(mappingCrtUid)) {
						// console.log('hhhhhhhhhhhhhh');
						// console.log('맵핑 작성 mappingCrtUid :::::', Number(mappingCrtUid));
						setPostRoles(true);
						setCmtWriteRole(true);
						setMappingUdpRole(true);
						setCmtUdpRole(true);
					} else {
						// console.log('iiiiiiiiiiiiiiii');
						// setPostRoles(false);
						// setCmtWriteRole(false);
						// setMappingUdpRole(false);
						// setCmtUdpRole(false);
					}
				}
			}
			// console.log('jjjjjjjjjjj');
		}
		// // console.log('postRoles', postRoles);
	}, []);
	// 마을 목록 조회
	useEffect(() => {
		// // console.log('aa');
		setSelectedTown(Number(townId));
		VillageCommuAPI.getTownList().then((res) => {
			setTownList(res.content);
			setTownInfo(res.content.filter((list) => list.townId === Number(townId)));
			res.content.map((list, index) => {
				list.townTitle = list.districtDTOList
					.map((list2, index2) => list2.districtName)
					.join(', ');
			});
		});
	}, [townId, selectedTown]);

	// 선택한 마을 townId
	const changeTownList = (townId: number) => {
		setSelectedTown(townId);
	};

	// 리스트 디테일 닫기
	const handleClickBtnLeft = (e: any) => {
		// // // console.log(e.target.parentElement.parentElement);
		e.target.parentElement.parentElement.classList.toggle('off');
		setClickBtnLeft(!clickBtnLeft);
	};
	// 돌아가기
	const handleBackList = () => {
		navigate(-1);
	};
	// 리스트 클릭
	const [listIndex, setListIndex] = useState<number>(0);
	const [listClickEvent, setListClickEvent] = useState(false);
	const handleListClick = (
		index: number,
		cmUid?: number,
		caTitle?: string,
		crtUid?: number
	) => {
		sessionStorage.setItem('mappingCrtUid', String(crtUid));
		setContentExtend(true);
		// setMobileContentExtend(true);
		setSelectedListIndex(index);
		setCmUid(cmUid);
		setCaTitle(caTitle);
		setListIndex(index);
		setListClickEvent((cur) => !cur);
		ActiveMapAPI.getMappingDetail({
			// crtUid: Number(crtUid) || undefined,
			crtUid: member?.id || undefined,
			cmUid: Number(cmUid),
			cmmUid: Number(cmmUid),
			page: page,
		})
			.then((res) => {
				setCmMappingDetail(res);
				// console.log('cmMappingDetail', res);
				const apiResponse = res.crtDt;
				const date = new Date(
					Number(apiResponse[0]),
					Number(apiResponse[1]),
					Number(apiResponse[2]),
					Number(apiResponse[3]),
					Number(apiResponse[4])
				);
				// // // console.log("date >> ", date);
				const formattedDate = `${date.getFullYear()}.${date
					.getMonth()
					.toString()
					.padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`;
				setDetailDate(formattedDate);
				// // // console.log("cmDetail >>>> ", res);
				if (res.cmFileList) {
					const files: File[] = [];
					const promises: Promise<any>[] = [];

					for (let i = 0; i < res.cmFileList.length; i++) {
						const promise = ActiveMapAPI.getMappingImg({
							cmfUid: res.cmFileList[i].cmfUid,
						}).then((data) => {
							if (data) {
								const file = new File([data.data], res.cmFileList[i].fileName, {
									type: 'image/png',
								});
								files.push(file);
							}
						});
						promises.push(promise);
					}

					Promise.all(promises).then(() => {
						setUploadFiles(files);
					});
				}
			})
			.catch((e) => { });
	};
	// Pagination
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	//주제 master 수정
	const handleSubModify = (crtUid: number) => {
		const tab = 'activeMasterMap';
		sessionStorage.setItem('crtUid', String(crtUid));
		showDialog(ModifyDialog, { tab });
	};
	//주제 master 삭제
	const handleSubDelete = (cmmUid: number, delUid: number) => {
		const tab = 'activeMasterMap';
		showDialog(DeleteDialog, { tab, cmmUid, delUid });
	};

	//////////////////////////////////////나도 참여//////////////////////////
	// 카테고리 선택
	const handleCmcUidChange = (e: any) => {
		// // console.log("cmcUid", e.target.value);
		setSelectedCmcUid(e.target.value);
	};
	// mappingList 가져오기
	useEffect(() => {
		// console.log('뭐가왔냐', cmmUid);
		ActiveMapAPI.getMappingList({
			crtUid: member?.id ? Number(member?.id) : undefined,
			cmmUid: Number(cmmUid),
			cmcUid: selectedCmcUid,
			page: Number(page),
			// pageSize: 4,
			search: search,
		})
			.then((res) => {
				setMappingList(res);
				// console.log('mappingList', res);
				setCmMappingList(res.cmMappingList);
				setCmMasterVO(res.cmMasterVO);
				setCount(Number(res.cmMappingList[0].totalCount));
			})
			.catch((e) => { });
	}, [search]);
	// 등록하기 버튼
	const handleWrite = () => {
		// navigate('/commu_02/actMap_03-detail-write');

		showDialog(Write_ActiveMapDialog, {
			mappingList,
			onComplete(marker) {
				// // // console.log("adsadasd", marker)
				setMarkerList((cur) => [...cur, marker]);
			},
		});
	};
	// 비공개 버튼 N : 비공개 A : 공개
	const handlePrivate = async (
		cmTitle: string,
		cntrLat: number,
		cntrLng: number,
		locInfo: string,
		cmUid: number
	) => {
		// PrivateDialog 창에서 예, 아니오 값 받아오기
		// ShowDialog에서 onComplete 써서 if-else문으로 updateAPI 호출해줄 것.
		if (cmMappingDetail?.openTp !== 'N') {
			showDialog(PrivateDialog, {
				async onComplete(result) {
					// console.log('PrivateDialog result ::::: ', result);

					if (result === 'yes') {
						await ActiveMapAPI.updateMapping(
							{
								cmcUid: mappingList?.calist.find(
									(find) => find.title === caTitle
								)?.cmcUid,
								cmmUid: Number(cmmUid),
								cmTitle: cmTitle,
								updUid: Number(member?.id),
								cntrLat: cntrLat,
								cntrLng: cntrLng,
								locInfo: locInfo,
								cmUid: cmUid,
								openTp: 'N',
							},
							uploadFiles
						).then((res) => {
							if (res.success) {
								// alert('글을 공개 설정하였습니다. ');
								showDialog(PrivateSuccessDialog);
								window.location.reload();
							} else {
								showDialog(PrivateFailDialog);
							}
						});
					} else {
						return;
					}
				},
			});
		} else if (cmMappingDetail?.openTp === 'N') {
			showDialog(PublicDialog, {
				async onComplete(result) {
					// console.log('PublicDialog result ::::: ', result);

					if (result === 'yes') {
						await ActiveMapAPI.updateMapping(
							{
								cmcUid: mappingList?.calist.find(
									(find) => find.title === caTitle
								)?.cmcUid,
								cmmUid: Number(cmmUid),
								cmTitle: cmTitle,
								updUid: Number(member?.id),
								cntrLat: cntrLat,
								cntrLng: cntrLng,
								locInfo: locInfo,
								cmUid: cmUid,
								openTp: 'A',
							},
							uploadFiles
						).then((res) => {
							if (res.success) {
								// alert('글을 공개 설정하였습니다. ');
								showDialog(PrivateSuccessDialog);
								window.location.reload();
							} else {
								showDialog(PrivateFailDialog);
							}
						});
					} else {
						return;
					}
				},
			});
		}
	};
	// 수정 버튼
	const handleModify = (cmUid: number, crtUid: number) => {
		// console.log('handleModifyhandleModifyhandleModify :::');

		sessionStorage.setItem('crtUid', String(crtUid));
		// CmMasterVO
		// if (list.title === caTitle) {
		// console.log('mappingList', mappingList);
		// console.log('caTitle >>', caTitle);
		if (caTitle) {
			const cmcUid = mappingList?.calist.filter((filter) => filter.title === caTitle)[0].cmcUid;
			showDialog(Modify_ActiveMapDialog, {
				caTitle,
				cmcUid,
				cmUid,
			});
		}
		// });
	};
	// 삭제 버튼
	const handleDelete = (cmUid: number, crtUid: number) => {
		// console.log('deleteMapping cmUid:::::::::', cmUid);
		showDialog(CmDeleteDialog, { cmUid, crtUid });
		// window.location.reload();
	};
	//////////////////////댓글////////////////////////////////////////
	const [openCommentModify, setOpenCommentModify] = useState(true);
	// 댓글 수정창 열기
	const [openReplyCommentWrite, setOpenReplyCommentWrite] = useState(true);
	const [openReplyCommentModify, setOpenReplyCommentModify] = useState(true);
	const [updateIndex, setUpdateIndex] = useState<number>();
	const [writeReplyIndex, setWriteReplyIndex] = useState<number>();
	const [modifyReplyIndex, setModifyReplyIndex] = useState<number>();
	const [cmtContentError, setCmtContentError] = useState('');
	// 내용 500자 제한
	const [newContent, setNewContent] = useState<string>('');
	const [modifyContent, setModifyContent] = useState<string>('');
	const maxCharacters: number = 500;
	// const [likeCheck, setLikeCheck] = useState(false);
	const [commentValue, setCommentValue] = useState<string>();
	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<CommentInputForm>();
	// 대댓글 등록
	const clickReplyCommentWrite = (idx: number) => {
		setWriteReplyIndex(idx);
		setOpenReplyCommentWrite(true);
		setOpenReplyCommentModify(false);
		setOpenCommentModify(true);
	};
	// 대댓글 수정
	const clickReplyCommentModify = (idx: number) => {
		setModifyReplyIndex(idx);
		setOpenReplyCommentModify(true);
		setOpenReplyCommentWrite(false);
		setOpenCommentModify(true);
	};
	// 대댓글 등록창 닫기
	const clickReplyCommentWriteCancle = () => {
		setOpenReplyCommentWrite(false);
	};
	// 대댓글 수정창 닫기
	const clickReplyCommentModifyCancle = () => {
		setOpenReplyCommentModify(false);
	};
	// 댓글 등록
	const commentInsertButton: SubmitHandler<CommentInputForm> = (data) => {
		if (!data.cmtContent) {
			setCmtContentError('댓글 내용을 입력해주세요.');
		} else {
			ActiveMapAPI.insertCmt({
				...data,
				openTp: 'A',
				cmUid: cmUid,
				crtUid: Number(member?.id),
			})
				.then((res) => {
					if (res.success) {
						showDialog(CmtSubmitSuccessDialog, {
							title: '댓글 등록',
						});
						ActiveMapAPI.getMappingDetail({
							crtUid: member?.id || undefined,
							cmUid: cmUid,
							cmmUid: Number(cmmUid),
						}).then((res) => {
							setCmMappingDetail(res);
							setCommentValue('');
							setValue('cmtContent', '');
						});
					} else {
					}
				})
				.catch((e) => {
					showDialog(CmtSubmitFailDialog);
				});
		}
	};
	// 댓글 수정 버튼
	const handleCmtModify = (index: number, comment: string) => {
		// // console.log(e.target.parentElement.parentElement.parentElement.children[1]);
		setUpdateIndex(index);
		setCommentValue(comment);
		setOpenCommentModify(false);
		setOpenReplyCommentModify(false);
		setOpenReplyCommentWrite(false);
	};
	// 댓글 삭제 버튼
	const handleCmtDelete = (cmtUid: number) => {
		showDialog(CmtDeleteDialog, {
			onComplete(result) {
				// console.log('CmtDeleteDialog result ::::', result);
				if (result === 'yes') {
					ActiveMapAPI.deleteCmt({
						cmtUid: cmtUid,
						delUid: Number(member?.id),
					})
						.then((res) => {
							if (res.success) {
								ActiveMapAPI.getMappingDetail({
									crtUid: member?.id || undefined,
									cmUid: cmUid,
									cmmUid: Number(cmmUid),
								}).then((res) => {
									showDialog(CmtDeleteSuccessDialog);
									setCmMappingDetail(res);
								});
							} else {
							}
						})
						.catch((e) => {
							showDialog(CmtDeleteFailDialog);
							return;
						});
				}
			},
		});
	};
	// 댓글 비공개 버튼
	const handleCmtPrivate = (cmtUid: number, openTp: string) => {
		if (openTp !== 'N') {
			showDialog(PrivateDialog, {
				async onComplete(result) {
					if (result === 'yes') {
						await ActiveMapAPI.privateCmt({
							cmtUid: Number(cmtUid),
							crtUid: Number(member?.id),
							openTp: 'N',
							cmUid: Number(cmUid),
						})
							.then((res) => {
								if (res.success) {
									showDialog(PrivateSuccessDialog);
									ActiveMapAPI.getMappingDetail({
										crtUid: member?.id || undefined,
										cmUid: cmUid,
										cmmUid: Number(cmmUid),
									}).then((res) => {
										setCmMappingDetail(res);
										const apiResponse = res.crtDt;
										const date = new Date(
											Number(apiResponse[0]),
											Number(apiResponse[1]),
											Number(apiResponse[2]),
											Number(apiResponse[3]),
											Number(apiResponse[4])
										);
										const formattedDate = `${date.getFullYear()}.${date
											.getMonth()
											.toString()
											.padStart(2, '0')}.${date
												.getDate()
												.toString()
												.padStart(2, '0')}`;
										setDetailDate(formattedDate);
										// // // console.log("cmDetail >>>> ", res);
										if (res.cmFileList) {
											const files: File[] = [];
											const promises: Promise<any>[] = [];

											for (let i = 0; i < res.cmFileList.length; i++) {
												const promise = ActiveMapAPI.getMappingImg({
													cmfUid: res.cmFileList[i].cmfUid,
												}).then((data) => {
													if (data) {
														const file = new File(
															[data.data],
															res.cmFileList[i].fileName,
															{
																type: 'image/png',
															}
														);
														files.push(file);
													}
												});
												promises.push(promise);
											}

											Promise.all(promises).then(() => {
												setUploadFiles(files);
											});
										}
									});
								}
							})
							.catch((e) => {
								showDialog(PrivateFailDialog);
							});
					}
				},
			});
		} else {
			showDialog(PublicDialog, {
				async onComplete(result) {
					if (result === 'yes') {
						await ActiveMapAPI.privateCmt({
							cmtUid: Number(cmtUid),
							crtUid: Number(member?.id),
							openTp: 'A',
							cmUid: Number(cmUid),
						})
							.then((res) => {
								if (res.success) {
									showDialog(PrivateSuccessDialog);
									ActiveMapAPI.getMappingDetail({
										crtUid: member?.id || undefined,
										cmUid: cmUid,
										cmmUid: Number(cmmUid),
									}).then((res) => {
										setCmMappingDetail(res);
										const apiResponse = res.crtDt;
										const date = new Date(
											Number(apiResponse[0]),
											Number(apiResponse[1]),
											Number(apiResponse[2]),
											Number(apiResponse[3]),
											Number(apiResponse[4])
										);
										const formattedDate = `${date.getFullYear()}.${date
											.getMonth()
											.toString()
											.padStart(2, '0')}.${date
												.getDate()
												.toString()
												.padStart(2, '0')}`;
										setDetailDate(formattedDate);
										// // // console.log("cmDetail >>>> ", res);
										if (res.cmFileList) {
											const files: File[] = [];
											const promises: Promise<any>[] = [];

											for (let i = 0; i < res.cmFileList.length; i++) {
												const promise = ActiveMapAPI.getMappingImg({
													cmfUid: res.cmFileList[i].cmfUid,
												}).then((data) => {
													if (data) {
														const file = new File(
															[data.data],
															res.cmFileList[i].fileName,
															{
																type: 'image/png',
															}
														);
														files.push(file);
													}
												});
												promises.push(promise);
											}

											Promise.all(promises).then(() => {
												setUploadFiles(files);
											});
										}
									});
								}
							})
							.catch((e) => {
								showDialog(PrivateFailDialog);
							});
					}
				},
			});
		}
	};

	// 새 댓글 작성시
	const handleContentWrite = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member?.id) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const newContent: string = event.target.value;
			if (newContent.length <= maxCharacters) {
				setNewContent(newContent);
			}
		}
	};

	// 댓글 수정시
	const handleContentModify = (
		event: ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!member?.id) {
			showDialog(CmtNonmemberDialog);
			return;
		} else {
			const modifyContent: string = event.target.value;
			if (modifyContent.length <= maxCharacters) {
				setModifyContent(modifyContent);
				setCommentValue(modifyContent);
			}
		}
	};
	// 댓글 수정 > 수정
	const handleCmtModify2 = async (cmtUid: number) => {
		setOpenCommentModify(true);
		if (!commentValue) {
			setCmtContentError('댓글 내용을 입력해주세요.');
		}
		await ActiveMapAPI.updateCmt({
			openTp: 'A',
			crtUid: Number(member?.id),
			cmtUid: cmtUid,
			cmtContent: commentValue,
		})
			.then((res) => {
				if (res.success) {
					showDialog(CmtSubmitSuccessDialog, {
						title: '댓글 수정',
					});
					ActiveMapAPI.getMappingDetail({
						crtUid: member?.id || undefined,
						cmUid: cmUid,
						cmmUid: Number(cmmUid),
					}).then((res) => {
						setCmMappingDetail(res);
					});
				} else {
				}
			})
			.catch((error) => {
				showDialog(CmtSubmitFailDialog);
				return;
			});
	};
	// 댓글 수정 > 취소
	const handleCmtCancle = () => {
		setOpenCommentModify(true);
	};
	// 지도 정보 팝업
	const handleInfo = (cmMasterVO: any) => {
		showDialog(InfoDialog, { cmMasterVO });
	};
	// 참여자 팝업
	const handlePeople = (cmMasterVO: any) => {
		showDialog(PeopleDialog, { cmMasterVO });
	};

	// 공유하기 팝업
	const handleShareDialog = () => {
		showDialog(ShareDialog, {
			id: `?cmm=${cmmUid}&town=${townId}&crt=${crtUid}&mapping=${mappingCrtUid}`,
		});
		// const title = '공유하기';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(OneButtonAlertDialog, { title, message });
	};
	useEffect(() => {
		// console.log('야 이눔의 자식아  ::::', mappingUdpRole);
	}, []);
	return (
		<main className="Detail_ActiveMapPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			{/* <article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
			
				<div id="content">
					{' '}
					<ActiveMapComponent />
				</div>
			
			</article> */}
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={2} />
					<div className="village_mapDetail mt40 admin">
						{/* <!-- 관리자 접속 시 admin 클래스 붙음 --> */}
						<div
							// className="village_mapDetail_map"
							className={
								contentExtend
									? 'village_mapDetail_map on'
									: 'village_mapDetail_map'
							}
						>
							{/* list 클릭 시 village_mapDetail_map on 
								btn_close 클릭 시 village_mapDetail_map
							*/}
							{/* 모바일 */}
							<div className="btn_list">
								<a
									// className="btn_maplist mobile_btn none"
									className={
										mobileContentExtend
											? 'btn_maplist mobile_btn none'
											: 'btn_maplist mobile_btn'
									}
									onClick={() => {

										setMobileContentExtend(true);
									}}
								>
									목록보기
								</a>
								<a
									// className="btn_map mobile_btn"
									className={
										!mobileContentExtend
											? 'btn_map mobile_btn none'
											: 'btn_map mobile_btn'
									}
									onClick={() => {

										setMobileContentExtend(false);
									}}
								>
									지도보기
								</a>
								{postRoles && (
									<a className="btn_write" onClick={handleWrite}>
										등록하기
									</a>
								)}
							</div>

							<div className="left_inner">
								<a
									className="btn_close"
									onClick={() => {
										// console.log('btn_close Click !!!', contentExtend);
										setContentExtend(false);
									}}
								></a>
								<a className="btn_left" onClick={handleClickBtnLeft}></a>

								<div className="left_box">
									<div className="title">
										<a className="btn_back" onClick={handleBackList}></a>
										<h2 className="txt_cut1">{cmMasterVO?.title}</h2>
									</div>
									<div className="map_btn_wrap">
											<button
												type="button"
												className="btn_info"
												onClick={() => handleInfo(cmMasterVO)}
											>
												<img src={ic_mapinfo} alt="지도 정보 아이콘" />
											</button>
											<button
												type="button"
												className="btn_people"
												onClick={() => handlePeople(cmMasterVO)}
											>
												<img src={ic_mappeople} alt="지도 참여인원 아이콘" />
											</button>
											{masterModifyRole && (
												<button
													type="button"
													className="btn_setting"
													onClick={() =>
														handleSubModify(
															Number(mappingList?.cmMasterVO?.crtUid)
														)
													}
												>
													<img src={ic_map_setting} alt="지도 설정 아이콘" />
												</button>
											)}
											{masterModifyRole && (
												<button
													type="button"
													className="btn_delt"
													onClick={() =>
														handleSubDelete(
															Number(cmmUid),
															Number(member?.id)
														)
													}
												>
													<img src={ic_delt} alt="지도 삭제 아이콘" />
												</button>
											)}
										</div>
									<form>
										<div className="category">
											<select className="cate_01" onChange={handleCmcUidChange}>
												<option value="">전체</option>
												{mappingList?.calist.map((list, index) => (
													<option key={index} value={list.cmcUid}>
														{list.title}
													</option>
												))}
											</select>
										</div>
										<div className="search">
											<input
												type="text"
												placeholder="검색어를 입력하세요"
												onChange={(e) => setSearch(e.target.value)}
												onKeyPress={getSearchKeyword}
											/>
											<button
												type="button"
												className="btn_search"
												onClick={getSearchKeyword}
											></button>
										</div>
									</form>
									<WebList
										cmMappingList={cmMappingList}
										nullListMessage={nullListMessage}
										selectedListIndex={selectedListIndex}
										handleListClick={handleListClick}
										count={count}
										handlePageChange={handlePageChange}
									/>
								</div>
								{/* 목록 디테일 */}
								{contentExtend ? (
									<>
										<LeftDetailBox
											caTitle={caTitle ? caTitle : ''}
											mappingPriRole={mappingPriRole}
											cmMappingDetail={cmMappingDetail}
											handlePrivate={handlePrivate}
											mappingUdpRole={mappingUdpRole}
											handleModify={handleModify}
											handleDelete={handleDelete}
											commentInsertButton={commentInsertButton}
											newContent={newContent}
											handleContentWrite={handleContentWrite}
											cmtContentError={cmtContentError}
											maxCharacters={maxCharacters}
											cmtWriteRole={cmtWriteRole}
											// cmtModifyRole={cmtModifyRole}
											handleCmtModify={handleCmtModify}
											handleCmtDelete={handleCmtDelete}
											cmtPrivateRole={cmtPrivateRole}
											handleCmtPrivate={handleCmtPrivate}
											openCommentModify={openCommentModify}
											updateIndex={updateIndex}
											commentValue={commentValue}
											handleContentModify={handleContentModify}
											handleCmtCancle={handleCmtCancle}
											handleCmtModify2={handleCmtModify2}
											handleShareDialog={handleShareDialog}
											member={member}
											generalRoles={generalRoles ? generalRoles : undefined}
											openReplyCommentWrite={openReplyCommentWrite}
											openReplyCommentModify={openReplyCommentModify}
											writeReplyIndex={writeReplyIndex}
											modifyReplyIndex={modifyReplyIndex}
											clickReplyCommentWrite={clickReplyCommentWrite}
											clickReplyCommentModify={clickReplyCommentModify}
											clickReplyCommentWriteCancle={clickReplyCommentWriteCancle}
											clickReplyCommentModifyCancle={clickReplyCommentModifyCancle}
										/>
									</>
								) : (
									<></>
								)}
							</div>

							<ActiveMapComponent
								// handleSelectLatLng={() => {}}
								cmMappingList={cmMappingList}
								listIndex={listIndex}
								listClickEvent={listClickEvent}
								handleListClick={handleListClick}
								cmmUid={Number(cmmUid)}
							// search={search}
							/>
							{/* 모바일 메뉴 */}
							<div className="mobile_menu_inner">
								<div className="map_btn_popup">
									<button
										type="button"
										className="btn_back"
										onClick={handleBackList}
									>
										<img src={ic_back} alt="지도 뒤로가기 아이콘" />
									</button>
									<button
										type="button"
										className="btn_info"
										onClick={() => handleInfo(cmMasterVO)}
									>
										<img src={ic_mapinfo} alt="지도 정보 아이콘" />
									</button>
									<button
										type="button"
										className="btn_people"
										onClick={() => handlePeople(cmMasterVO)}
									>
										<img src={ic_mappeople} alt="지도 참여인원 아이콘" />
									</button>
									{masterModifyRole && (<button
										type="button"
										className="btn_setting"
										onClick={() =>
											handleSubModify(Number(mappingList?.cmMasterVO?.crtUid))
										}
									>
										<img src={ic_map_setting} alt="지도 설정 아이콘" />
									</button>
									)}
									{masterModifyRole && (
										<button
											type="button"
											className="btn_delt"
											onClick={() =>
												handleSubDelete(
													Number(cmmUid),
													Number(member?.id)
												)
											}
										>
											<img src={ic_delt} alt="지도 삭제 아이콘" />
										</button>
									)}
								</div>
								<>
									<div
										className="detail_popup"
										style={{
											display: contentExtend ? 'block' : 'none',
										}}
									>
										<div className="white_box">
											<div className="left_detail_box pa20">
												<a
													className="detail_popup_close"
													onClick={() => setContentExtend(!contentExtend)}
												></a>
												{cmMappingDetail && contentExtend && (
													<div className="left_detail_wrap mt25">
														<div className="comm_btn_set_wrap mb25">
															<div className="comm_btn_set">
																{mappingPriRole && (
																	<>
																		<button
																			type="button"
																			className="non"
																			onClick={() =>
																				handlePrivate(
																					String(
																						cmMappingDetail?.cmMapperVO.cmText
																					),
																					Number(cmMappingDetail?.cntrLat),
																					Number(cmMappingDetail?.cntrLng),
																					String(cmMappingDetail?.locInfo),
																					Number(cmMappingDetail?.cmUid)
																				)
																			}
																		>
																			{`${cmMappingDetail?.openTp === 'N'
																				? '공개'
																				: '비공개'
																				}`}
																		</button>
																	</>
																)}

																{generalRoles ||
																	member?.id == cmMappingDetail?.crtUid ? (
																	<>
																		<button
																			type="button"
																			onClick={() =>
																				handleModify(
																					Number(cmMappingDetail?.cmUid),
																					Number(
																						cmMappingDetail?.cmMapperVO?.crtUid
																					)
																				)
																			}
																		>
																			수정
																		</button>
																		<button
																			type="button"
																			onClick={() =>
																				handleDelete(
																					Number(cmMappingDetail?.cmUid),
																					Number(cmMappingDetail?.crtUid)
																				)
																			}
																		>
																			삭제
																		</button>
																	</>
																) : (
																	<></>
																)}
															</div>
														</div>
														<div className="comm_detail_cont">
															<div className="info01">
																{/* <!-- 레벨별 class "Lv~" --> */}
																<p
																	// className="name Lv1"

																	className={
																		cmMappingDetail?.character1 === 1
																			? 'name basic1'
																			: cmMappingDetail?.character1 === 2
																				? 'name basic2'
																				: cmMappingDetail?.character1 === 3
																					? 'name Lv1'
																					: cmMappingDetail?.character1 === 4
																						? 'name Lv2'
																						: cmMappingDetail?.character1 === 5
																							? 'name Lv3'
																							: cmMappingDetail?.character1 === 6
																								? 'name Lv4'
																								: ''
																	}
																>
																	{cmMappingDetail?.nickName}
																</p>
																<p>
																	{/* {moment(
																		cmMappingDetail?.cmMapperVO.crtDt
																	).format('yyyy.MM.DD')} */}
																	{/* {cmMappingDetail?.cmMapperVO.crtDt[0]}.
																	{cmMappingDetail?.cmMapperVO.crtDt[1]}.
																	{cmMappingDetail?.cmMapperVO.crtDt[2]} */}
																	{moment(`${cmMappingDetail?.cmMapperVO.crtDt[0]}-
																		${cmMappingDetail?.cmMapperVO.crtDt[1]}-
																		${cmMappingDetail?.cmMapperVO.crtDt[2]}`).format('YYYY.MM.DD')}
																</p>
															</div>
															<p className="txt_cut1 cate mt10">
																{cmMappingDetail?.categoryList[0].title}
															</p>
															<p className="mt10">
																{cmMappingDetail?.cmMapperVO?.cmText}
															</p>
															{cmMappingDetail &&
																cmMappingDetail?.cmFileList.length > 0 && (
																	// <Swiper
																	// 	modules={[
																	// 		Navigation,
																	// 		Pagination,
																	// 		Scrollbar,
																	// 	]}
																	// 	navigation
																	// 	pagination={{
																	// 		clickable: true,
																	// 		type: 'fraction',
																	// 	}}
																	// >
																	// 	{cmMappingDetail.cmFileList.map(
																	// 		(files, index) => (
																	// 			<SwiperSlide key={index}>
																	// 				<img
																	// 					src={`/api/community/getMapFiles?cmfUid=${files.cmfUid}`}
																	// 					alt="#"
																	// 					className="mt10"
																	// 				/>
																	// 			</SwiperSlide>
																	// 		)
																	// 	)}
																	// </Swiper>
																	<ul>
																		{/* {cmMappingDetail.cmFileList.map(
																			(files, index) => (
																				<li key={index}>
																					<img
																						src={`/api/community/getMapFiles?cmfUid=${files.cmfUid}`}
																						alt="#"
																						className="mt10"
																					/>
																				</li>
																			)
																		)} */}
																		<Swiper
																			className="swiper-wrapper"
																			navigation
																		// onSwiper={(swiper) => {
																		// 	setSwiper(swiper);
																		// }}
																		>
																			<li>
																				{cmMappingDetail.cmFileList.map((files, index) => (
																					<>
																						<SwiperSlide>
																							<img
																								key={index}
																								src={`/api/community/getMapFiles?cmfUid=${files.cmfUid}`}
																								alt="활동지도 이미지"
																								className="mt10"
																							/>
																						</SwiperSlide>
																					</>
																				))}
																			</li>
																		</Swiper>
																	</ul>
																)}
															{/* 사진 없을 때
															{cmMappingDetail?.cmFileList.length === 0 && (
																<div
																	className="nophoto_img"
																	style={{ display: 'block' }}
																>
																	<img
																		src={defaultImg}
																		alt=""
																		className="mt10"
																	/>
																</div>
															)} */}
															<p className="address mt10">
																{cmMappingDetail?.locInfo}{' '}
																{cmMappingDetail?.sublocInfo}
															</p>
														</div>
														<div className="comm_detail_btn mt25">
															<a
																className="btn_share"
																onClick={handleShareDialog}
															>
																공유
															</a>
														</div>
														<div className="comm_comment_write mt25">
															<h5>
																댓글&nbsp;
																<span>
																	({cmMappingDetail?.commentList.length || 0}건)
																</span>
															</h5>
															<form
																onSubmit={handleSubmit(commentInsertButton)}
															>
																{cmtWriteRole && (
																	<div className="comm_box_write mt10 pa20">
																		<textarea
																			placeholder="로그인하고 댓글을 작성하실 수 있습니다."
																			defaultValue={''}
																			{...register('cmtContent')}
																			value={newContent}
																			onChange={handleContentWrite}
																		></textarea>
																		{cmtContentError && (
																			<p
																				className="error"
																				style={{ color: 'red' }}
																			>
																				{cmtContentError}
																			</p>
																		)}
																		<div className="write_info mt10">
																			<p>
																				{newContent.length}/{maxCharacters}
																			</p>
																			{cmtWriteRole && (
																				<>
																					<button
																						type="submit"
																						className="comm_write_button"
																					>
																						등록
																					</button>
																				</>
																			)}
																		</div>
																	</div>
																)}
															</form>
														</div>
														<div className="comm_comment_list">
															<ul>
																{cmMappingDetail?.commentList.map(
																	(cmtList, index) => (
																		<li
																			// className="mt25"
																			className={
																				cmtList?.openTp === 'N'
																					? 'mt25 non'
																					: 'mt25'
																			}
																			key={index}
																		>
																			<div className="comm_box_list">
																				<div className="info01">
																					{/* <!-- 레벨별 className "Lv~" --> */}
																					<p
																						// className="name Lv1"
																						className={
																							cmtList?.character1 === 1
																								? 'name basic1'
																								: cmtList?.character1 === 2
																									? 'name basic2'
																									: cmtList?.character1 === 3
																										? 'name Lv1'
																										: cmtList?.character1 === 4
																											? 'name Lv2'
																											: cmtList?.character1 === 5
																												? 'name Lv3'
																												: cmtList?.character1 === 6
																													? 'name Lv4'
																													: ''
																						}
																					>
																						{cmtList.nickName}
																					</p>
																					<p>
																						{/* {moment(cmtList.crtDt).format(
																							'YYYY.MM.DD'
																						)} */}
																						{moment(`${cmtList.crtDt[0]}-${cmtList.crtDt[1]}-${cmtList.crtDt[2]}`).format('YYYY.MM.DD')}
																					</p>
																					<p style={{ cursor: 'pointer' }} onClick={() => clickReplyCommentWrite(index)}>답글</p>
																					{(member &&
																						member?.id == cmtList?.crtUid) &&
																						<>
																							<a
																								onClick={() =>
																									handleCmtModify(
																										index,
																										cmtList.cmtContent
																									)
																								}
																							>
																								수정
																							</a>
																							<a
																								onClick={() =>
																									handleCmtDelete(
																										cmtList?.cmtUid
																									)
																								}
																							>
																								삭제
																							</a>
																						</>
																					}
																					{cmtPrivateRole && (
																						<>
																							<a
																								className="txt_blue"
																								onClick={() =>
																									handleCmtPrivate(
																										cmtList?.cmtUid,
																										cmtList?.openTp
																									)
																								}
																							>
																								{`${cmtList?.openTp === 'N'
																									? '공개'
																									: '비공개'
																									}`}
																							</a>
																						</>
																					)}
																				</div>
																				<h5 className="mt5">
																					{cmtList.cmtContent
																						.split('\n')
																						.map((item, index) => (
																							<React.Fragment key={index}>
																								{item}
																								<br />
																								{!openCommentModify && (
																									<div
																										className="comm_box_write pa20"
																										style={{
																											display:
																												updateIndex === index
																													? 'block'
																													: 'none',
																										}}
																									>
																										<textarea
																											placeholder="댓글을 입력해주세요."
																											// defaultValue={''}
																											value={commentValue}
																											// {...register('cmtContent')}
																											onChange={handleContentModify}
																										></textarea>
																										{cmtContentError && (
																											<p
																												className="error"
																												style={{ color: 'red' }}
																											>
																												{cmtContentError}
																											</p>
																										)}
																										<div className="write_info mt10">
																											<p>
																												{(commentValue &&
																													commentValue.length) ||
																													0}
																												/{maxCharacters}
																											</p>
																											<div>
																												<button
																													type="button"
																													className="comm_write_button small_white"
																													onClick={handleCmtCancle}
																												>
																													취소
																												</button>
																												<button
																													type="button"
																													className="comm_write_button small_blue"
																													onClick={() =>
																														handleCmtModify2(
																															Number(cmtList.cmtUid)
																														)
																													}
																												>
																													수정
																												</button>
																											</div>
																										</div>
																									</div>
																								)}
																								{/* 대댓글  */}
																								{openReplyCommentWrite &&
																									<>
																										<div
																											className="replyComm_box_write pa20"
																											style={{
																												display: writeReplyIndex === index ? 'block' : 'none',
																											}}
																										>
																											<textarea
																												placeholder="답글을 입력해주세요."
																												defaultValue={''}
																											// value={commentValue}
																											// onChange={handleModifyContentChange}
																											/>
																											{cmtContentError && (
																												<p className="error" style={{ color: 'red' }}>
																													{cmtContentError}
																												</p>
																											)}
																											<div className="write_info mt10">
																												<p>
																													{(commentValue && commentValue.length) || 0}/
																													{maxCharacters}
																												</p>
																												<div>
																													<button
																														type="button"
																														className="comm_write_button small_white"
																														onClick={clickReplyCommentWriteCancle}
																													>
																														취소
																													</button>
																													<button
																														type="button"
																														className="comm_write_button small_blue"
																													// onClick={() =>
																													// 	insertCommentModify(
																													// 		Number(cmtList.boardCommentId),
																													// 		String(cmtList.visible)
																													// 	)
																													// }
																													>
																														등록
																													</button>
																												</div>
																											</div>
																										</div>
																									</>
																								}
																								{/* 대댓글  */}
																								<>
																									<div className='replyCommentDiv' style={{ marginLeft: '30px' }}>
																										<p>➥</p>
																										<p
																											// className="name Lv1"
																											className={
																												cmtList && cmtList?.character1 === 1
																													? 'name basic1'
																													: cmtList?.character1 === 2
																														? 'name basic2'
																														: cmtList?.character1 === 3
																															? 'name Lv1'
																															: cmtList?.character1 === 4
																																? 'name Lv2'
																																: cmtList?.character1 === 5
																																	? 'name Lv3'
																																	: cmtList?.character1 === 6
																																		? 'name Lv4'
																																		: ''
																											}
																										>
																											{cmtList.nickName}</p>
																										<p>
																											{moment(`${cmtList.crtDt[0]}-${cmtList.crtDt[1]}-${cmtList.crtDt[2]}`).format('YYYY.MM.DD')}
																											{/* {`${cmtList.crtDt[0]}.${Number(cmtList.crtDt[1]) >= 10 ? '' : '0'}${cmtList.crtDt[1]}.${Number(cmtList.crtDt[2]) >= 10 ? '' : '0'}${Number(cmtList.crtDt[2])}`} */}
																										</p>
																										{/* 대댓글 버튼 */}
																										{member &&
																											(member?.id == cmtList?.crtUid && (
																												<>
																													<a
																														onClick={() =>
																															clickReplyCommentModify(index)
																														}
																													>
																														수정
																													</a>
																													<a

																													// onClick={() => deleteComment(cmtList.boardCommentId)}
																													>
																														삭제
																													</a>
																												</>
																											))}
																										{cmtPrivateRole && (
																											<>
																												<a
																													className="txt_blue"
																													onClick={() =>
																														handleCmtPrivate(
																															cmtList?.cmtUid,
																															cmtList?.openTp
																														)
																													}
																												>
																													{`${cmtList?.openTp === 'N'
																														? '공개'
																														: '비공개'
																														}`}
																												</a>
																											</>
																										)}
																									</div>

																									<div style={{ marginLeft: '70px' }}>
																										{item}
																										{/* 대댓글 수정 */}
																										{openReplyCommentModify && (
																											<div
																												className="replyComm_box_write pa20"
																												style={{ marginLeft: '0px', display: modifyReplyIndex === index ? 'block' : 'none' }}
																											>
																												<textarea
																													placeholder="댓글을 입력해주세요."
																													defaultValue={''}
																													value={commentValue}
																												// onChange={handleModifyContentChange}
																												/>
																												{cmtContentError && (
																													<p className="error" style={{ color: 'red' }}>
																														{cmtContentError}
																													</p>
																												)}
																												<div className="write_info mt10">
																													<p>
																														{(commentValue && commentValue.length) || 0}/
																														{maxCharacters}
																													</p>
																													<div>
																														<button
																															type="button"
																															className="comm_write_button small_white"
																															onClick={clickReplyCommentModifyCancle}
																														>
																															취소
																														</button>
																														<button
																															type="button"
																															className="comm_write_button small_blue"
																														// onClick={() =>
																														// 	insertCommentModify(
																														// 		Number(cmtList.boardCommentId),
																														// 		String(cmtList.visible)
																														// 	)
																														// }
																														>
																															수정
																														</button>
																													</div>
																												</div>
																											</div>
																										)}
																									</div>
																								</>
																							</React.Fragment>
																						))}
																				</h5>
																			</div>
																		</li>
																	)
																)}
															</ul>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
									<div
										// className="mobile_box_wrap close"
										className={
											mobileContentExtend
												? 'mobile_box_wrap'
												: 'mobile_box_wrap close'
										}
									>
										{mobileContentExtend ? (
											<>
												<form>
													<div className="category">
														<select
															className="cate_01"
															onChange={handleCmcUidChange}
														>
															<option value="">전체</option>
															{mappingList?.calist.map((list, index) => (
																<option key={index} value={list.cmcUid}>
																	{list.title}
																</option>
															))}
														</select>
													</div>
													<div className="search">
														<input
															type="text"
															placeholder="검색어를 입력하세요"
															onChange={(e) => setSearch(e.target.value)}
															onKeyPress={getSearchKeyword}
														/>
														<button
															type="button"
															className="btn_search"
															onClick={getSearchKeyword}
														></button>
													</div>
												</form>
												<div className="mobile_list">
													{cmMappingList && cmMappingList.length !== 0 ? (
														<>
															<div className="list">
																<ul>
																	{cmMappingList &&
																		cmMappingList.map((list, index) => (
																			<li
																				key={index}
																				className={
																					list?.openTp === 'N' ? 'non' : ''
																				}
																			>
																				<a
																					className={`${selectedListIndex === index
																						? 'on'
																						: ''
																						}`}
																					onClick={() =>
																						handleListClick(
																							index,
																							list.cmUid,
																							list.caTitle,
																							list.crtUid
																						)
																					}
																				>
																					{/* <!--선택되면 on 클래스 붙음--> */}
																					<div
																						// className="list_table"
																						className={
																							list.cmfUid
																								? 'list_table'
																								: 'list_table noimg'
																						}
																					>
																						{/* <!--이미지 없으면 noimg 클래스 붙음--> */}
																						<div className="txt">
																							<p className="txt_cut1 cate">
																								{list.caTitle}{' '}
																							</p>
																							<h5 className="txt_cut2">
																								{list.cmText}
																							</h5>
																							<p className="address">
																								{list.locInfo} {list.sublocInfo}
																							</p>
																						</div>
																						{/* {list.cmfUid && (
													<div className="img">
														<div className="img_box">
															<img
																src={`/api/community/getMapFiles?cmfUid=${list.cmfUid}`}
																alt="이미지"
															/>
															<span className="number">{list.fileSize}</span>
														</div>
													</div>
												)} */}
																					</div>
																				</a>
																			</li>
																		))}
																</ul>
															</div>
														</>
													) : (
														<>
															<div className="comm_noList">
																<img src={noList} alt="내용없음 이미지" />
																<p className="mt25">{nullListMessage}</p>
															</div>
														</>
													)}

													<Pagination
														page={1}
														// totalPageCount={10}
														totalPageCount={Math.ceil(count / 8)}
														onPageChange={handlePageChange}
													/>
												</div>{' '}
											</>
										) : (
											<> </>
										)}
									</div>
								</>
							</div>
							{/* 모바일 메뉴 끝 */}
						</div>
					</div>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};
// nav
const Nav = ({
	townList,
	changeTownList,
	selectedTown,
	townInfo,
}: {
	townList: content[];
	changeTownList: (townId: number) => void;
	selectedTown: number | undefined;
	townInfo: content[];
}) => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>소통마당</h3>
				<CommSelect
					townList={townList}
				// changeTownList={changeTownList}
				// selectedTown={selectedTown}
				/>
				{/* {townInfo && townInfo.map((list, idx) => 
					list.districtDTOList.map((list2, idx2) =>(
					<p key={idx}>
						{list2.districtName}
						{idx2 !== list.districtDTOList.length - 1 && ','}
					</p>
				)))} */}
				{townInfo &&
					townInfo.map((list, idx) => (
						<p key={idx}>
							{list.districtDTOList.map((list2, idx2) => (
								<span key={list2.districtId}>
									{list2.districtName}
									{idx2 !== list.districtDTOList.length - 1 && ','}
								</span>
							))}
						</p>
					))}
			</div>
		</nav>
	);
};
const WebList = ({
	cmMappingList,
	nullListMessage,
	selectedListIndex,
	handleListClick,
	count,
	handlePageChange,
}: {
	cmMappingList: CmMappingList[];
	nullListMessage: string;
	selectedListIndex: number | null;
	handleListClick: (
		index: number,
		cmUid?: number,
		caTitle?: string,
		crtUid?: number
	) => void;
	count: number;
	handlePageChange: (page: number) => void;
}) => {
	return (
		<div className="web_list">
			{cmMappingList && cmMappingList.length !== 0 ? (
				<div className="list">
					<ul>
						{cmMappingList &&
							cmMappingList.map((list, index) => (
								<li key={index} className={list?.openTp === 'N' ? 'non' : ''}>
									<a
										className={`${selectedListIndex === index ? 'on' : ''}`}
										onClick={() =>
											handleListClick(
												index,
												list.cmUid,
												list.caTitle,
												list.crtUid
											)
										}
									>
										{/* <!--선택되면 on 클래스 붙음--> */}
										<div
											// className="list_table"
											className={
												list.cmfUid ? 'list_table' : 'list_table noimg'
											}
										>
											{/* <!--이미지 없으면 noimg 클래스 붙음--> */}
											<div className="txt">
												<p className="txt_cut1 cate">{list.caTitle} </p>
												<h5 className="txt_cut2">{list.cmText}</h5>
												<p className="address">
													{list.locInfo} {list.sublocInfo}
												</p>
											</div>
											{list.cmfUid && (
												<div className="img">
													<div className="img_box">
														<img
															src={`/api/community/getMapFiles?cmfUid=${list.cmfUid}`}
															alt="이미지"
														/>
														<span className="number">{list.fileSize}</span>
													</div>
												</div>
											)}
										</div>
									</a>
								</li>
							))}
					</ul>
					<Pagination
						page={1}
						// totalPageCount={10}
						totalPageCount={Math.ceil(count / 8)}
						onPageChange={handlePageChange}
					/>
				</div>
			) : (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">{nullListMessage}</p>
				</div>
			)}
		</div>
	);
};
const LeftDetailBox = ({
	mappingPriRole,
	cmMappingDetail,
	handlePrivate,
	mappingUdpRole,
	handleModify,
	handleDelete,
	commentInsertButton,
	newContent,
	handleContentWrite,
	cmtContentError,
	maxCharacters,
	cmtWriteRole,
	// cmtModifyRole,
	handleCmtModify,
	handleCmtDelete,
	cmtPrivateRole,
	handleCmtPrivate,
	openCommentModify,
	updateIndex,
	commentValue,
	handleContentModify,
	handleCmtCancle,
	handleCmtModify2,
	handleShareDialog,
	member,
	generalRoles,
	caTitle,
	openReplyCommentWrite,
	openReplyCommentModify,
	writeReplyIndex,
	modifyReplyIndex,
	clickReplyCommentWrite,
	clickReplyCommentModify,
	clickReplyCommentWriteCancle,
	clickReplyCommentModifyCancle,
}: {
	mappingPriRole: boolean;
	cmMappingDetail: CmMappingList | undefined;
	handlePrivate: (
		cmTitle: string,
		cntrLat: number,
		cntrLng: number,
		locInfo: string,
		cmUid: number
	) => void;
	mappingUdpRole: boolean;
	handleModify: (cmUid: number, crtUid: number) => void;
	handleDelete: (cmUid: number, crtUid: number) => void;
	commentInsertButton: SubmitHandler<CommentInputForm>;
	newContent: string;
	handleContentWrite: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	cmtContentError: string;
	maxCharacters: number;
	cmtWriteRole: boolean;
	// cmtModifyRole: boolean;
	handleCmtModify: (index: number, comment: string) => void;
	handleCmtDelete: (cmtUid: number) => void;
	cmtPrivateRole: boolean;
	handleCmtPrivate: (cmtUid: number, openTp: string) => void;
	openCommentModify: boolean;
	updateIndex: number | undefined;
	commentValue: string | undefined;
	handleContentModify: (event: ChangeEvent<HTMLTextAreaElement>) => void;
	handleCmtCancle: () => void;
	handleCmtModify2: (cmtUid: number) => void;
	handleShareDialog: () => void;
	member: MemberInfo | undefined;
	generalRoles?: boolean;
	caTitle: string;
	openReplyCommentWrite: boolean;
	openReplyCommentModify: boolean;
	writeReplyIndex?: number;
	modifyReplyIndex?: number;
	clickReplyCommentWrite: (idx: number) => void;
	clickReplyCommentModify: (idx: number) => void;
	clickReplyCommentWriteCancle: () => void;
	clickReplyCommentModifyCancle: () => void;
}) => {
	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
	} = useForm<CommentInputForm>();
	SwiperCore.use([Navigation, Pagination, Scrollbar]);
	useEffect(() => {

	}, []);

	return (
		<div className="left_detail_box">
			<div className="left_detail_wrap pa20">
				<div className="comm_btn_set_wrap mb25">
					<div className="comm_btn_set">
						{mappingPriRole && (
							<>
								<button
									type="button"
									className="non"
									onClick={() =>
										handlePrivate(
											String(cmMappingDetail?.cmMapperVO.cmText),
											Number(cmMappingDetail?.cntrLat),
											Number(cmMappingDetail?.cntrLng),
											String(cmMappingDetail?.locInfo),
											Number(cmMappingDetail?.cmUid)
										)
									}
								>
									{`${cmMappingDetail?.openTp === 'N' ? '공개' : '비공개'}`}
								</button>
							</>
						)}

						{generalRoles || member?.id == cmMappingDetail?.crtUid ? (
							<>
								{/* 콘솔에 출력 */}
								<button
									type="button"
									onClick={() =>
										handleModify(
											Number(cmMappingDetail?.cmUid),
											// String(cmMappingDetail?.caTitle),
											Number(cmMappingDetail?.cmMapperVO?.crtUid)
										)
									}
								>
									수정
								</button>
								<button
									type="button"
									onClick={() =>
										handleDelete(
											Number(cmMappingDetail?.cmUid),
											Number(cmMappingDetail?.crtUid)
										)
									}
								>
									삭제
								</button>
							</>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="comm_detail_cont">
					<div className="info01">
						{/* <!-- 레벨별 class "Lv~" --> */}
						<p
							// className="name Lv1"
							className={
								cmMappingDetail?.character1 === 1
									? 'name basic1'
									: cmMappingDetail?.character1 === 2
										? 'name basic2'
										: cmMappingDetail?.character1 === 3
											? 'name Lv1'
											: cmMappingDetail?.character1 === 4
												? 'name Lv2'
												: cmMappingDetail?.character1 === 5
													? 'name Lv3'
													: cmMappingDetail?.character1 === 6
														? 'name Lv4'
														: ''
							}
						>
							{cmMappingDetail?.nickName}
						</p>
						<p>
							{/* {moment(cmMappingDetail?.cmMapperVO.crtDt).format('YYYY.MM.DD')}
							 */}
							{moment(`${cmMappingDetail?.cmMapperVO.crtDt[0]}-${cmMappingDetail?.cmMapperVO.crtDt[1]}-${cmMappingDetail?.cmMapperVO.crtDt[2]}`).format('YYYY.MM.DD')}
							{/* {cmMappingDetail?.cmMapperVO.crtDt[0]}.
							{cmMappingDetail?.cmMapperVO.crtDt[1]}.
							{cmMappingDetail?.cmMapperVO.crtDt[2]} */}
						</p>
					</div>
					<p className="txt_cut1 cate mt10">
						{caTitle}
					</p>
					<p className="mt10">{cmMappingDetail?.cmMapperVO?.cmText}</p>
					{cmMappingDetail && cmMappingDetail?.cmFileList.length > 0 && (
						// <Swiper
						// 	modules={[Navigation, Pagination, Scrollbar]}
						// 	navigation
						// 	pagination={{
						// 		clickable: true,
						// 		type: 'fraction',
						// 	}}
						// 	spaceBetween={1}
						// >
						// 	{cmMappingDetail.cmFileList.map((files, index) => (
						// 		<SwiperSlide key={index}>
						// 			<img
						// 				src={`/api/community/getMapFiles?cmfUid=${files.cmfUid}`}
						// 				alt="#"
						// 				className="mt10"
						// 			/>
						// 		</SwiperSlide>
						// 	))}
						// </Swiper>
						<ul>
							<Swiper
								className="swiper-wrapper"
								navigation
							// onSwiper={(swiper) => {
							// 	setSwiper(swiper);
							// }}
							>
								<li>
									{cmMappingDetail.cmFileList.map((files, index) => (
										<>
											<SwiperSlide>
												<img
													key={index}
													src={`/api/community/getMapFiles?cmfUid=${files.cmfUid}`}
													alt="활동지도 이미지"
													className="mt10"
												/>
											</SwiperSlide>
										</>
									))}
								</li>
							</Swiper>

						</ul>
					)}
					{/*사진 없을 때*/}
					{/* {!cmMappingDetail?.cmFileList.length && (
						<div className="nophoto_img" style={{ display: 'block' }}>
							<img
								// src={noimg}
								alt=""
								className="mt10"
							/>
						</div>
					)} */}
					<p className="address mt10">
						{cmMappingDetail?.locInfo} {cmMappingDetail?.sublocInfo}
					</p>
				</div>
				<div className="comm_detail_btn mt25">
					<a className="btn_share" onClick={handleShareDialog}>
						공유
					</a>
				</div>
				<div className="comm_comment_write mt25">
					<h5>
						댓글&nbsp;
						<span>({cmMappingDetail?.commentList.length || 0}건)</span>
					</h5>
					<form onSubmit={handleSubmit(commentInsertButton)}>
						{cmtWriteRole && (
							<div className="comm_box_write mt10 pa20">
								<textarea
									placeholder="로그인하고 댓글을 작성하실 수 있습니다."
									defaultValue={''}
									{...register('cmtContent')}
									value={newContent}
									onChange={handleContentWrite}
								></textarea>
								{cmtContentError && (
									<p className="error" style={{ color: 'red' }}>
										{cmtContentError}
									</p>
								)}
								<div className="write_info mt10">
									<p>
										{newContent.length}/{maxCharacters}
									</p>
									{cmtWriteRole && (
										<>
											<button type="submit" className="comm_write_button">
												등록
											</button>
										</>
									)}
								</div>
							</div>
						)}
					</form>
				</div>

				<div className="comm_comment_list">
					<ul>
						{cmMappingDetail?.commentList.map((cmtList, index) => (
							<li
								// className="mt25"
								className={cmtList?.openTp === 'N' ? 'mt25 non' : 'mt25'}
								key={index}
							>
								<div className="comm_box_list">
									<div className="info01">
										{/* <!-- 레벨별 className "Lv~" --> */}
										<p
											// className="name Lv1"
											className={
												cmtList?.character1 === 1
													? 'name basic1'
													: cmtList?.character1 === 2
														? 'name basic2'
														: cmtList?.character1 === 3
															? 'name Lv1'
															: cmtList?.character1 === 4
																? 'name Lv2'
																: cmtList?.character1 === 5
																	? 'name Lv3'
																	: cmtList?.character1 === 6
																		? 'name Lv4'
																		: ''
											}
										>
											{cmtList.nickName}
										</p>
										<p>
											{/* {moment(cmtList.crtDt).format('YYYY.MM.DD')} */}
											{moment(`${cmtList.crtDt[0]}-${cmtList.crtDt[1]}-${cmtList.crtDt[2]}`).format('YYYY.MM.DD')}
											{/* {`${Number(cmtList.crtDt[0])}.${Number(cmtList.crtDt[1]) > 10 ? '' : '0'}${Number(cmtList.crtDt[1])}.${Number(cmtList.crtDt[2]) > 10 ? '' : '0'}${Number(cmtList.crtDt[2])}`} */}
										</p>
										<p style={{ cursor: 'pointer' }} onClick={() => clickReplyCommentWrite(index)}>답글</p>
										{member && member?.id == cmtList?.crtUid &&
											(
												<>
													<a
														onClick={() =>
															handleCmtModify(index, cmtList.cmtContent)
														}
													>
														수정
													</a>
													<a onClick={() => handleCmtDelete(cmtList?.cmtUid)}>
														삭제
													</a>
												</>
											)}
										{cmtPrivateRole && (
											<>
												<a
													className="txt_blue"
													onClick={() =>
														handleCmtPrivate(cmtList?.cmtUid, cmtList?.openTp)
													}
												>
													{`${cmtList?.openTp === 'N' ? '공개' : '비공개'}`}
												</a>
											</>
										)}
									</div>
									<h5 className="mt5">
										{cmtList.cmtContent.split('\n').map((item, index) => (
											<React.Fragment key={index}>
												{item}
												<br />
												{!openCommentModify && (
													<div
														className="comm_box_write pa20"
														style={{
															display: updateIndex === index ? 'block' : 'none',
														}}
													>
														<textarea
															placeholder="로그인하고 댓글을 작성하실 수 있습니다."
															// defaultValue={''}
															value={commentValue}
															// {...register('cmtContent')}
															onChange={handleContentModify}
														></textarea>
														{cmtContentError && (
															<p className="error" style={{ color: 'red' }}>
																{cmtContentError}
															</p>
														)}
														<div className="write_info mt10">
															<p>
																{(commentValue && commentValue.length) || 0}/
																{maxCharacters}
															</p>
															<div>
																<button
																	type="button"
																	className="comm_write_button small_white"
																	onClick={handleCmtCancle}
																>
																	취소
																</button>
																<button
																	type="button"
																	className="comm_write_button small_blue"
																	onClick={() =>
																		handleCmtModify2(Number(cmtList.cmtUid))
																	}
																>
																	수정
																</button>
															</div>
														</div>
													</div>
												)}
												{/* 대댓글  */}
												{/* {openReplyCommentWrite &&
													<>
														<div
															className="replyComm_box_write pa20"
															style={{
																display: writeReplyIndex === index ? 'block' : 'none',
															}}
														>
															<textarea
																placeholder="답글을 입력해주세요."
																defaultValue={''}
															// value={commentValue}
															// onChange={handleModifyContentChange}
															/>
															{cmtContentError && (
																<p className="error" style={{ color: 'red' }}>
																	{cmtContentError}
																</p>
															)}
															<div className="write_info mt10">
																<p>
																	{(commentValue && commentValue.length) || 0}/
																	{maxCharacters}
																</p>
																<div>
																	<button
																		type="button"
																		className="comm_write_button small_white"
																		onClick={clickReplyCommentWriteCancle}
																	>
																		취소
																	</button>
																	<button
																		type="button"
																		className="comm_write_button small_blue"
																	// onClick={() =>
																	// 	insertCommentModify(
																	// 		Number(cmtList.boardCommentId),
																	// 		String(cmtList.visible)
																	// 	)
																	// }
																	>
																		등록
																	</button>
																</div>
															</div>
														</div>
													</>
												} */}
												{/* 대댓글  */}
												<>
													{/* <div className='replyCommentDiv' style={{ marginLeft: '0px', gap: '10px' }}>
														<p style={{ fontSize: '14px' }}>➥</p>
														<p
															// className="name Lv1"
															style={{ fontSize: '14px' }}
															className={
																cmtList && cmtList?.character1 === 1
																	? 'name basic1'
																	: cmtList.character1 === 2
																		? 'name basic2'
																		: cmtList.character1 === 3
																			? 'name Lv1'
																			: cmtList.character1 === 4
																				? 'name Lv2'
																				: cmtList.character1 === 5
																					? 'name Lv3'
																					: cmtList.character1 === 6
																						? 'name Lv4'
																						: ''
															}
														>
															{cmtList.nickName}</p>
														<p style={{ fontSize: '14px' }}>
															{`${Number(cmtList.crtDt[0])}.${Number(cmtList.crtDt[1]) > 10 ? '' : '0'}${Number(cmtList.crtDt[1])}.${Number(cmtList.crtDt[2]) > 10 ? '' : '0'}${Number(cmtList.crtDt[2])}`}
														</p>
														대댓글 버튼 
														{
															(member && member.id == cmtList.crtUid && (
																<>
																	<a style={{ fontSize: '14px' }}
																		onClick={() =>
																			clickReplyCommentModify(index)
																		}
																	>
																		수정
																	</a>
																	<a
																		style={{ fontSize: '14px' }}
																	// onClick={() => deleteComment(cmtList.boardCommentId)}
																	>
																		삭제
																	</a>
																</>
															))}
														{cmtPrivateRole && (
															<>
																<a
																	style={{ fontSize: '14px' }}
																	className="txt_blue"
																	onClick={() =>
																		handleCmtPrivate(cmtList?.cmtUid, cmtList?.openTp)
																	}
																>
																	{`${cmtList?.openTp === 'N' ? '공개' : '비공개'}`}
																</a>
															</>
														)}
													</div> */}
													{/* 대댓글 수정 */}
													{/* <div style={{ marginLeft: '70px' }}>
														{item}
														{openReplyCommentModify && (
															<div
																className="replyComm_box_write pa20"
																style={{ marginLeft: '0px', display: modifyReplyIndex === index ? 'block' : 'none' }}
															>
																<textarea
																	placeholder="댓글을 입력해주세요."
																	defaultValue={''}
																	value={commentValue}
																// onChange={handleModifyContentChange}
																/>
																{cmtContentError && (
																	<p className="error" style={{ color: 'red' }}>
																		{cmtContentError}
																	</p>
																)}
																<div className="write_info mt10">
																	<p>
																		{(commentValue && commentValue.length) || 0}/
																		{maxCharacters}
																	</p>
																	<div>
																		<button
																			type="button"
																			className="comm_write_button small_white"
																			onClick={clickReplyCommentModifyCancle}
																		>
																			취소
																		</button>
																		<button
																			type="button"
																			className="comm_write_button small_blue"
																		// onClick={() =>
																		// 	insertCommentModify(
																		// 		Number(cmtList.boardCommentId),
																		// 		String(cmtList.visible)
																		// 	)
																		// }
																		>
																			수정
																		</button>
																	</div>
																</div>
															</div>
														)}
													</div> */}
												</>
											</React.Fragment>
										))}
									</h5>
								</div>


							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Detail_ActiveMapPage;
