import React from 'react';
import './PointGiftPage.scss';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import { useDialog } from '../../../dialog/DialogProvider';
import SearchMemberDialog from '../../../dialog/myPage/SearchMemberDialog';
const PointGiftPage = () => {
	const { showDialog } = useDialog();
	const handleClick = () => {
		const title = '포인트 선물하기';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};
	const handleSearch = () => {
		showDialog(SearchMemberDialog);
	};
	return (
		<main className="PointGiftPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>포인트 선물하기</h3>

					<p>내 E 포인트를 확인하고 사용해보세요</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/* 현재 포인트 시작 */}
					<div className="point_wrap">
						<p className="big">
							현재 가용 POINT <span>5,000 E</span>
						</p>
					</div>
					{/* 현재 포인트 종료 */}
					{/* 설명 시작 */}
					<div className="explain_wrap mt40">
						<h3 className="comm_title">선물하기 주의사항</h3>
						<p className="mt10">
							1. 매월 포인트 선물한도는 최대 5,000포인트이며, 같은마을
							주민에게만 선물이 가능합니다.
							<br />
							2. 한번 선물이 완된 포인트는 회수가 불가능합니다.
						</p>
					</div>
					{/* 설명 종료 */}
					{/* 작성폼 시작 */}
					<form>
						<div className="comm_popup_content comm_write_wrap mt25">
							<div className="cont-wrap">
								<h3 className="important">받는사람</h3>
								<div className="cont-box">
									<input
										type="text"
										className="address"
										placeholder="닉네임"
										disabled
									/>
									<button
										type="button"
										className="btn_address"
										onClick={handleSearch}
									>
										찾기
									</button>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">선물할 E포인트</h3>
								<div className="cont-box">
									<div className="select-box">
										<select>
											<option value={0}>선택</option>
											<option value={1}>1,000</option>
											<option value={2}>5,000</option>
										</select>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">선물 메세지</h3>
								<div className="cont-box">
									<textarea
										placeholder="기본 문구 함께 에너지전환해요 (100자 이내 입력)"
										defaultValue={''}
									/>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap2">
							<button
								type="submit"
								className="btn_next on"
								onClick={handleClick}
							>
								선물하기
							</button>
						</div>
					</form>
					{/* 작성폼 종료 */}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default PointGiftPage;
