import axios from "axios";
import { EnergyMapList, TownNameList } from "../models/EnergyMap";

interface GetAllParams{
    townName?: string;
    page?: number; 
}
const getTownNameList = () => {
    return axios 
    .get('/api/energydata/townNameList')
    .then((res) => res.data as TownNameList[])
}

const getEnergyMapList = (params: GetAllParams) => {
    return axios 
    .get('/api/energydata/energymap', {params})
    .then((res) => res.data as EnergyMapList[])
}

const EnergyMapAPI = {
    getTownNameList,
    getEnergyMapList,
}
export default EnergyMapAPI;