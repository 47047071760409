// 마을소통 API

import axios from "axios";
import { serialize } from "object-to-formdata";
import TownList, { content } from "../models/TownList";
import VillageCommuInfo, { formVillageCommu, formVillageCommuCmt, likeParam, villageCommuContent } from "../models/VillageCommuInfo";

interface GetAllParams{
    townId?: number;
    page?: number;
    size?: number;
    boardId?: number;
	category?: string;
	sort?: string;
}

// 마을 목록 조회
const getTownList = () => {
    return axios
        .get('/api/town')
        .then((res) => res.data as TownList);
} 

// 마을 단일 조회
const getTownOne = (params: GetAllParams) => {
    return axios
    .get('/api/town/get', {params})
    .then((res) => res.data as TownList);
} 

// 마을소통 글 목록 조회
const getVillageCommuList = (params: GetAllParams) => {
    return axios
        .get('/api/board', {params})
        .then((res) => res.data as VillageCommuInfo);
}

// 마을소통 글 단일조회
const getVillageCommuDetail = (params: GetAllParams) => {
    return axios
        .get('/api/board/get', {params})
        .then((res) => res.data as villageCommuContent);
}

// 마을소통 글 등록하기
const writeVillageCommu = (
    data: formVillageCommu,
    uploadFiles: File[] | FileList,
    pdfFiles: File[] | FileList
    ) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
		formData.append(key, value);
		// 파일 추가
	});
    const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
    const FilesPdf = Array.from(pdfFiles);
	for (let i = 0; i < FilesPdf.length; i++) {
		formData.append('pdfFiles', FilesPdf[i], FilesPdf[i].name);
	}
    return axios
		.post('/api/board/board/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			// console.log(res);
			return res.data as { success: boolean; message: string };
		});
}

// 마을소통 글 수정하기
const updateVillageCommu = (
    data: formVillageCommu,
    uploadFiles?: File[],
    deleteImgFileIds?: number[],
	pdfFiles?: File[] | FileList,
    deletePdfFileIds?: number[],
    ) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
		formData.append(key, value);
		// 파일 추가
	});
    if(uploadFiles){
        const files = Array.from(uploadFiles);
        for (let i = 0; i < files.length; i++) {
            formData.append('uploadFiles', files[i], files[i].name);
        }
    }
    if(pdfFiles){
        const FilesPdf = Array.from(pdfFiles);
		for (let i = 0; i < FilesPdf.length; i++) {
			formData.append('pdfFiles', FilesPdf[i], FilesPdf[i].name);
		}
    }
    if (deleteImgFileIds?.length !== 0) {
		if (deleteImgFileIds) {
			const imgDel = Array.from(deleteImgFileIds);
			for (let i = 0; i < imgDel.length; i++) {
				if (imgDel[i]) {
					formData.append(`boardFileDTOList[${i}].boardFileId`,String(imgDel[i]));
					
				}
			}
		}
	}
    if (deletePdfFileIds?.length !== 0) {
		if (deletePdfFileIds) {
			const pdfDel = Array.from(deletePdfFileIds);
			for (let i = 0; i < pdfDel.length; i++) {
				if (pdfDel[i]) {
					formData.append(`boardFileDTOList[${i}].boardFileId`,String(pdfDel[i]));
					
				}
			}
		}
	}

    return axios
		.post('/api/board/board/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			// console.log(res);
			return res.data as { success: boolean; message: string };
		});
}

// 마을소통 글 삭제하기
const deleteVillageCommu = (data: formVillageCommu) => {
	// console.log('deleteRitual', data);
	return axios
		.post('/api/board/board/delete', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};

// 좋아요
const insertLike = (data: likeParam) => {
	// console.log('insertLike', data);
	return axios.post('/api/board/like', serialize(data)).then((res) => {
		return res.data as { success: boolean; message: string };
	});
};

// 마을소통 댓글 작성
const writeVillageCmt = (data: formVillageCommuCmt) => {
	// console.log('insertComment', data);
	return axios
		.post('/api/board/saveComment', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};

// 마을소통 댓글 수정 & 삭제
const updateVillageCmt = (data: formVillageCommuCmt) => {
	// console.log('insertComment', data);
	return axios
		.post('/api/board/updateComment', serialize(data))
		.then((res) => {
			return res.data as { success: boolean; message: string };
		});
};


// 마을소통 댓글 삭제



const VillageCommuAPI = {
    getTownList,
    getTownOne,
    getVillageCommuList,
    getVillageCommuDetail,
    writeVillageCommu,
    updateVillageCommu,
    deleteVillageCommu,
    insertLike,
    writeVillageCmt,
    updateVillageCmt
}
export default VillageCommuAPI;