import React, { PropsWithChildren, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { plainToInstance } from "class-transformer";
import { useNavigate } from "react-router-dom";

import MemberContext, { LoginBasic } from "./MemberContext";
import MemberInfo from "../../models/MemberInfo";
import TimeUtil from "../../utils/TimeUtil";

const getMember = () => {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
        const jsonString = localStorage.getItem("member");
        if (jsonString) {
            return plainToInstance(
                MemberInfo,
                JSON.parse(jsonString) as MemberInfo
            );
        }
    } else {
        const jsonString = sessionStorage.getItem("member");
        if (jsonString) {
            return plainToInstance(
                MemberInfo,
                JSON.parse(jsonString) as MemberInfo
            );
        }
    }
};

const MemberContextProvider = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const [member, setMember] = useState<MemberInfo | undefined>(getMember());
    const login = async (snsId: string, snsType: string) => {
        // try {
        // 	const member = await MemberAPI.login(userId: snsType + snsId);
        // 	setMember(member);
        // 	localStorage.setItem('member', JSON.stringify(member));
        // 	await TimeUtil.delay(10);
        // 	navigate('/citizen-mileage');
        // } catch (e) {
        // 	navigate('/join');
        // }
    };
    const loginBasic = async (data: LoginBasic) => {
        // try {
        // 	const member = await MemberAPI.loginCheck({ ...data });
        // 	// console.log("member : ", member)
        // 	if (member.success) {
        // 		// console.log("로그인 성공")
        // 		// console.log("login info : ", member)
        // 		await MemberAPI.login().then((res) => {
        // 			// console.log("mem info : ", res)
        // 			// setMember(res.data as MemberInfo);
        // 			localStorage.setItem('member', JSON.stringify(res.data));
        // 		});
        // 		let checkMem: MemberInfo = JSON.parse(
        // 			localStorage.getItem('member') as string
        // 		);
        // 		// console.log(checkMem)
        // 		// window.alert(checkMem)
        // 		if (checkMem.depart?.deptCd === 'SI') {
        // 			navigate(`/area`);
        // 		} else {
        // 			if (
        // 				checkMem.depart?.deptCd === 'ORG' &&
        // 				checkMem.srvcManageYn === 'N'
        // 			) {
        // 				//기관 현장
        // 				window.alert('권한이 없습니다.');
        // 				localStorage.removeItem('member');
        // 			} else {
        // 				setMember(checkMem);
        // 			}
        // 		}
        // 	} else {
        // 		// console.log("로그인 실패 : ", member.message)
        // 		window.alert(member.message);
        // 	}
        // } catch (e) {
        // 	// await TimeUtil.delay(10);
        // 	// navigate("/home");
        // 	navigate('/join');
        // }
    };
    const getMemInfo = (target: MemberInfo) => {
        // console.log('target : ', target);

        if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
            localStorage.setItem("member", JSON.stringify(target));
            setMember(target);
        } else {
            sessionStorage.setItem("member", JSON.stringify(target));
            setMember(target);
        }
    };
    const selectLocation = () => {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
            setMember(
                JSON.parse(
                    localStorage.getItem("member") as string
                ) as MemberInfo
            );
            navigate("/dobanjang");
        } else {
            setMember(
                JSON.parse(
                    sessionStorage.getItem("member") as string
                ) as MemberInfo
            );
            navigate("/dobanjang");
        }
    };
    const logout = async () => {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
            setMember(undefined);
            localStorage.removeItem("member");

            await TimeUtil.delay(10);
            // navigate("/dobanjang");
        } else {
            setMember(undefined);
            sessionStorage.removeItem("member");

            await TimeUtil.delay(10);
            // navigate("/dobanjang");
        }
    };
    const debugLogin = (member: MemberInfo) => {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
            setMember(member);
            localStorage.setItem("member", JSON.stringify(member));
            navigate("/dobanjang");
        } else {
            setMember(member);
            sessionStorage.setItem("member", JSON.stringify(member));
            navigate("/dobanjang");
        }
    };
    return (
        <MemberContext.Provider
            value={{
                member,
                login,
                loginBasic,
                logout,
                debugLogin,
                selectLocation,
                setMember,
                getMemInfo,
            }}
        >
            {children}
        </MemberContext.Provider>
    );
};

export default MemberContextProvider;
