import React, { RefObject, useEffect, useRef, useState } from 'react';
import CommSelect from '../../components/common/CommSelect';
import SubLocation from '../../components/common/SubLocation';
import './Main_VillagePage.scss';
import img_village from '../../assets/img_village.png';
import CommTab from './CommTab';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';
import VillageCommuInfo, {
	villageCommuContent,
} from '../../models/VillageCommuInfo';
import ActiveInfo from '../../models/ActiveInfo';
import test02 from '../../assets/test02.png';
import moment from 'moment';
import TownList, { content } from '../../models/TownList';
import VillageCommuAPI from '../../apis/VillageCommuAPI';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import ActivityLogAPI from '../../apis/ActivityLogAPI';
import { ActivityLogList } from '../../models/ActivityLog';
import NavComponent from '../../components/common/NavComponent';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import EduProgramAPI from '../../apis/EduProgramAPI';
import { AllcmMasterList } from '../../models/MapInfo';
import { EduProgramList } from '../../models/EduProgram';
import useMember from '../../hooks/useMember';
import TownAPI from '../../apis/TownAPI';
import noList from '../../assets/noList.png';

// 소통마당 > 마을 메인
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const Main_VillagePage = () => {
	const townIdStr = sessionStorage.getItem('town');
	const townId = townIdStr ? parseInt(townIdStr, 10) : null;
	const [townList, setTownList] = useState([] as content[]);
	const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [townInfo, setTownInfo] = useState([] as content[]);
	const [population, setPopulation] = useState<number | undefined>();
	const tcUid = sessionStorage.getItem('town');

	// 마을 목록 조회
	useEffect(() => {
		setSelectedTown(Number(townId));
		VillageCommuAPI.getTownList().then((res) => {
			// // console.log('a');
			if (res) {
				setTownList(res.content);
				setTownInfo(
					res.content.filter((list) => list.townId === Number(townId))
				);
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((list2, index) => list2.districtName)
						.join(',');
				});
			}
		});

		TownAPI.getTownStatistics({
			tcUid: Number(tcUid),
		}).then((res) => setPopulation(res.population as number | undefined));
	}, [townId, selectedTown]);

	// console.log('townList-->', townList);
	// console.log('마을 단일조회', townInfo);
	// console.log('마을 주민수', population);

	// 선택한 마을 townId
	const changeTownList = (townId: number) => {
		setSelectedTown(townId);
	};

	return (
		<main className="Main_VillagePage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<CommTab clickTab={0} />

					<div className="village">
						{/* 마을소개 2024.07.02 주석처리*/}
						{/* <Village_Info townInfo={townInfo} population={population} /> */}
						{/* 마을순위 */}
						<Village_Rank />
						{/* 마을소통 */}
						<Village_Commu townId={townId} />
						{/* 활동일지 */}
						<Activity />
						{/* 교육프로그램 및 활동지도 */}
						<Program_Map townId={townId} />
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

// nav
const Nav = ({
	townList,
	changeTownList,
	selectedTown,
	townInfo,
}: {
	townList: content[];
	changeTownList: (townId: number) => void;
	selectedTown: number | undefined;
	townInfo: content[];
}) => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>소통마당</h3>
				<CommSelect
					townList={townList}
				// changeTownList={changeTownList}
				// selectedTown={selectedTown}
				/>
				{townInfo &&
					townInfo.map((list, idx) => (
						<p key={idx}>
							{list.townTitle}
							{/* {idx2 !== list.districtDTOList.length - 1 && ','} */}
						</p>
					))}
			</div>
		</nav>
	);
};

// 마을소개
const Village_Info = ({
	population,
	townInfo,
}: {
	population: number | undefined;
	townInfo: content[];
}) => {
	return (
		<>
			{/* 소개시작 */}
			<div className="village_info mt40">
				{/* <img src={img_village} alt="에너지 이미지" />
				<div className="box_info pa30">
					<p>
						{townInfo[0] && townInfo[0].townName && townInfo[0].townName}의{' '}
						<span>참여자 수는</span>{' '}
						<span className="txt_blue">{population}명</span> 입니다.
					</p>
					<p>
						{townInfo[0] && townInfo[0].townName && townInfo[0].townName}의
						에너지소비량은 97개 행정동 중
					</p>
					<p>
						{townInfo[0] &&
							townInfo[0].districtDTOList[0] &&
							townInfo[0].districtDTOList[0] && (
								<>
									<span>{townInfo[0].districtDTOList[0].districtName}</span>{' '}
									<span className="txt_blue">10위</span>
								</>
							)}
						{townInfo[0] &&
							townInfo[0].districtDTOList[1] &&
							townInfo[0].districtDTOList[1] && (
								<>
									<span> ,{townInfo[0].districtDTOList[1].districtName}</span>{' '}
									<span className="txt_blue">4위</span>
								</>
							)}
						{townInfo[0] &&
							townInfo[0].districtDTOList[2] &&
							townInfo[0].districtDTOList[2] && (
								<>
									<span> ,{townInfo[0].districtDTOList[2].districtName}</span>{' '}
									<span className="txt_blue">15위</span>
								</>
							)}
						<span> 입니다.</span>
					</p>
				</div> */}
			</div>
			{/* 소개종료 */}
		</>
	);
};

// 마을순위
SwiperCore.use([Navigation, Pagination, Autoplay]);
const Village_Rank = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	// 마을미션 참여하기 버튼
	const handleMission = () => {
		const title = '마을미션 참여하기';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(OneButtonAlertDialog, { title, message });
		// return;
		// // console.log("마을미션 참여하기 클릭");
		navigate(`/reward_02/villageMission_03-list`);
	};

	const swiperParams = {
		spaceBetween: 50,
		slidesPerView: 1,
		centeredSlides: true,
		loop: true,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'fraction',
		},
	};

	const handleSwiper = (swiper: typeof Swiper) => {
		// swiperRef.current = swiper; // Assign the Swiper instance to swiperRef.current
	};

	return (
		<>
			{/* 순위시작 */}
			<div className="village_cont mt40">
				<div className="cont_title">
					<h4>
						마을미션 순위<span>&#40;종합 3위&#41;</span>
					</h4>
					<button type="button" onClick={handleMission}>
						마을미션 참여하기
					</button>
				</div>
				<div className="cont_content mt25 village_rank">
					<Swiper
						// {...swiperParams}
						className="mySwiper"
						modules={[Navigation, Pagination, Autoplay]}
						loop={true}
						direction="vertical"
						autoplay={{
							delay: 3000,
							// pauseOnMouseEnter: true,
							// disableOnInteraction: false,
						}}
						navigation={{
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						}}
						pagination={{
							el: '.pagination',
							type: 'fraction',
						}}
					// onSwiper={handleSwiper}
					>
						<ul className="swiper-wrapper">
							<SwiperSlide>
								<li className="swiper-slide">
									<span className="num">1</span>
									<span className="villname">2023년 에너지대전 참가하기1</span>
									<span className="score">3위</span>
								</li>
							</SwiperSlide>
							<SwiperSlide>
								<li className="swiper-slide">
									<span className="num">2</span>
									<span className="villname">2023년 에너지대전 참가하기2</span>
									<span className="score">3위</span>
								</li>
							</SwiperSlide>
							<SwiperSlide>
								<li className="swiper-slide">
									<span className="num">3</span>
									<span className="villname">2023년 에너지대전 참가하기3</span>
									<span className="score">5위</span>
								</li>
							</SwiperSlide>
						</ul>
						<div className="swiper-button-wrap">
							<div className="swiper-button-prev"></div>
							<div className="swiper-button-next"></div>
						</div>
					</Swiper>
				</div>
			</div>
			{/* 순위종료 */}
		</>
	);
};

// 마을소통
const Village_Commu = ({ townId }: { townId: number | null }) => {
	const navigate = useNavigate();

	const [villageCommuList, setVillageCommuList] = useState(
		[] as villageCommuContent[]
	);

	// 마을소통 글 목록 조회
	useEffect(() => {
		if (townId !== null) {
			VillageCommuAPI.getVillageCommuList({
				townId: Number(townId),
				size: 4,
				sort: 'createdAt,DESC',
			}).then((res) => {
				setVillageCommuList(res.content);
			});
		}
	}, [townId]);

	// console.log('마을소통 글 목록', villageCommuList);

	// 상세보기
	const handleDetail = (boardId: string) => {
		// console.log('마을소통 상세보기 이동');
		navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`);
		sessionStorage.setItem('boardId', String(boardId));
	};

	return (
		<>
			{/* 소통시작 */}
			{villageCommuList && villageCommuList.length != 0 ? (
				<div className="village_cont mt40">
					<div className="cont_title">
						<h4>마을소통</h4>
					</div>
					<div className="cont_content mt25 village_commu_list">
						<ul>
							{/* 비공개 게시물 non클래스 붙음 */}
							{villageCommuList &&
								villageCommuList.map((list) => (
									<li
										key={list.boardId}
										className={list.visible === 'N' ? 'non' : ''}
										onClick={() => handleDetail(String(list.boardId))}
									>
										<a className="pa30">
											<div className="box_commu">
												{/* 카테고리 (마을뉴스) 일 경우 news class추가 */}
												<span
													className={`comm_village ${list.category === 'NEWS' && 'news'
														}`}
												>
													{list.category === 'NEWS'
														? '마을뉴스'
														: list.category === 'ASK'
															? '마을질문'
															: '마을일상'}
												</span>
												<h4 className="comm_title txt_cut2 mt25">
													{list.content}
												</h4>
												<div className="info01 mt10">
													{/* <p>{list.createdId}</p> */}
													<p
														className={
															list.character === 1
																? 'name basic1'
																: list.character === 2
																	? 'name basic2'
																	: list.character === 3
																		? 'name Lv1'
																		: list.character === 4
																			? 'name Lv2'
																			: list.character === 5
																				? 'name Lv3'
																				: list.character === 6
																					? 'name Lv4'
																					: ''
														}
													>
														{list.nickname}
													</p>
													<p>{`${moment(list.createdAt).format(
														'yyyy.MM.DD'
													)}`}</p>
												</div>
												<div className="info02 mt10">
													<p className="ic_comm_heart">{list.likeCnt}</p>
													<p className="ic_comm_comment">{list.commentCnt}</p>
												</div>
											</div>
										</a>
									</li>
								))}
						</ul>
					</div>
				</div>
			) : (
				<></>
			)}
			{/* 소통종료 */}
		</>
	);
};

// 활동일지
const Activity = () => {
	const navigate = useNavigate();
	const townId = sessionStorage.getItem('town');
	const [activeSubList, setActiveSubList] = useState<
		ActivityLogList | undefined
	>();

	// 주제내역 이동
	const handleHistory = (subjectId: number) => {
		sessionStorage.setItem('subject', String(subjectId));
		navigate('/commu_02/active_03-history');
	};
	useEffect(() => {
		const nowDate = new Date();
		ActivityLogAPI.getSubjectList({
			page: 0,
			size: 4,
			townId: Number(townId),
			sort: 'createdAt,DESC',
		}).then((res) => {
			if (res) {
				// console.log(res);
				res.content.map((list, index) => {
					const formattedStartDate = `${list.startedAt[0]}.${String(
						list.startedAt[1]
					).padStart(2, '0')}.${String(list.startedAt[2]).padStart(2, '0')}`;
					const formattedEndDate = `${list.endedAt[0]}.${String(
						list.endedAt[1]
					).padStart(2, '0')}.${String(list.endedAt[2]).padStart(2, '0')}`;
					const startDate = new Date(formattedStartDate);
					const endDate = new Date(formattedEndDate);
					// console.log('엔드 데이트', endDate);
					if (nowDate > endDate) {
						list.status = 'end';
					} else if (nowDate > startDate && nowDate < endDate) {
						list.status = 'ing';
					} else {
						list.status = 'prev';
					}
					list.startDate = formattedStartDate;
					list.endDate = formattedEndDate;
					list.subjectFileList.map((list2, index2) => {
						list.thumbnail = list2.filePath;
					});
				});
				setActiveSubList(res);
			}
		});
	}, [townId]);
	return (
		<>
			{/* 일지시작 */}
			{activeSubList && activeSubList.content.length !== 0 ? (
				<div className="village_cont mt40">
					<div className="cont_title">
						<h4>활동일지</h4>
					</div>
					<div className="cont_content mt25 village_act_list">
						<ul>
							{/* 비공개 게시물 non클래스 붙음 */}
							{activeSubList &&
								activeSubList?.content.map((list, index) => (
									<li
										key={index}
										className={list.visible === 'N' ? 'non' : ''}
										onClick={() => handleHistory(list.subjectId)}
									>
										<a className="pa30">
											{/* 이미지 없으면 noimg class 붙음 */}
											<div
												className={`box_act ${!list.thumbnail ? 'noimg' : ''}`}
											>
												<div className="act_txt">
													<h4 className="comm_title txt_cut1">{list.title}</h4>
													<p className="txt_cut1">{list.content}</p>
													{/* 진행중 ing, 종료 end 클래스붙음 */}
													<p className={`period mt10 ${list.status}`}>
														{list.startDate} ~ {list.endDate}
													</p>
												</div>
												<div className="act_img">
													<img
														src={`/api/attach/getFile?savedPath=${list.thumbnail}`}
														alt="일지 주제 이미지"
													/>
												</div>
											</div>
										</a>
									</li>
								))}
						</ul>
					</div>
				</div>
			) : (
				''
			)}
			{/* 일지종료 */}
		</>
	);
};

// 교육프로그램 및 활동지도
const Program_Map = ({ townId }: { townId: number | null }) => {
	const navigate = useNavigate();
	const { member } = useMember();
	const [page, setPage] = useState<number>(1);
	const [tab, setTab] = useState<string>('1');
	// 모든 마을 활동지도 목록조회
	const [mapList, setMapList] = useState([] as AllcmMasterList[]);

	// 모든 마을 교육프로그램 목록조회
	const [programList, setProgramList] = useState<EduProgramList>();

	useEffect(() => {
		// 활동지도 목록조회
		ActiveMapAPI.getAllMapList({
			tcUid: Number(townId),
			crtUid: Number(member?.id) || undefined,
			pageSize: 6,
			// prgrsSt: '진행',
			// page: page,
			// search: search,
		})
			.then((res) => {
				// console.log('마을메인 getAllMapList', res);
				setMapList(res.allcmMasterList);
				// setCount(Number(res.allcmMasterList[0].totalCount));
			})
			.catch((e) => { });

		// 교육프로그램
		EduProgramAPI.getEduProgramList({
			tcUid: Number(townId),
			page: page - 1,
			size: 6,
			sort: 'createdAt,DESC',
			// status: 'b',
			// status: selectedStatus == "" ? undefined : selectedStatus,
		}).then((res) => {
			// console.log('마을메인 getEduProgramList', res);
			setProgramList(res);
		});
	}, []);

	// 활동 지도 디테일 페이지
	const handleDetailMap = (cmmUid: string, crtUid: number, tcUid: string) => {
		navigate('/commu_02/actMap_03-detail');
		sessionStorage.setItem('cmmUid', String(cmmUid));
		sessionStorage.setItem('crtUid', String(crtUid));
		sessionStorage.setItem('town', String(tcUid));
	};

	// 상세화면 이동
	const handleDetailPro = (sfUid: number, tcUid: string) => {
		sessionStorage.setItem('space', String(sfUid));
		sessionStorage.setItem('town', String(tcUid));
		navigate('/commu_02/eduProgram_03-detail');
	};

	return (
		<>
			{/* 프로그램,지도시작 */}
			<div className="village_cont mt40">
				<div className="cont_tab">
					<button
						type="button"
						className={tab === '1' ? 'tab tab_active' : 'tab non_active'}
						onClick={() => setTab('1')}
					>
						활동지도
					</button>
					<button
						type="button"
						className={tab === '2' ? 'tab tab_active' : 'tab non_active'}
						onClick={() => setTab('2')}
					>
						교육프로그램
					</button>
				</div>

				{/* 지도	 */}
				{tab === '1' && mapList && mapList.length !== 0 ? (
					<div className="tab_content mt25 village_table">
						<ul className="thead pa20">
							<li>상태</li>
							<li>주제</li>
							<li>기간</li>
						</ul>
						<ul className="tbody">
							{mapList &&
								mapList.map((mapList, mapIdx) => (
									<li
										className={`${mapList.openTp === 'N' ? 'non ' : ''}pa20`}
										key={mapIdx}
										onClick={() =>
											handleDetailMap(
												String(mapList?.cmmUid),
												Number(mapList?.crtUid),
												String(mapList?.cmtcUid)
											)
										}
									>
										<a >
											{/* 진행 red class붙음 */}
											<p className={mapList.prgrsSt === '진행' ? 'red' : ''}>
												{mapList.prgrsSt}
											</p>
											<p className="txt_cut1 comm_title">{mapList.title}</p>
											<p>
												{`${moment(mapList.stDt).format('yyyy.MM.DD')}`}
												{'~'}
												{`${moment(mapList.edDt).format('yyyy.MM.DD')}`}
											</p>
										</a>
									</li>
								))}
						</ul>
					</div>
				) : tab === '1' && mapList && mapList.length === 0 ? (
					<div className="comm_noList">
						<img src={noList} alt="내용없음 이미지" />
						<p className="mt25">작성된 게시글이 없습니다.</p>
					</div>
				) : (
					''
				)}

				{/* 프로그램			 */}
				{tab === '2' && programList && programList.content.length !== 0 ? (
					<div className="tab_content mt25 village_table">
						<ul className="thead pa20">
							<li>상태</li>
							<li>주제</li>
							<li>교육기간</li>
						</ul>
						<ul className="tbody">
							{programList &&
								programList.content.map((proList, proIdx) => (
									<li
										className={`${proList.visible === 'N' ? 'non ' : ''}pa20`}
										key={proIdx}
										onClick={() =>
											handleDetailPro(
												Number(proList?.sfUid),
												String(proList?.tcUid)
											)
										}
									>
										<a >
											{/* 진행 red class붙음 */}
											<p className={proList.status === '신청중' ? 'red' : ''}>
												{proList.status}
											</p>
											<p className="txt_cut1 comm_title">
												{proList.facilityName}
											</p>
											<p>
												{`${moment(proList.educationStartDate).format(
													'yyyy.MM.DD'
												)}`}
												{'~'}
												{`${moment(proList.educationEndDate).format(
													'yyyy.MM.DD'
												)}`}
											</p>
										</a>
									</li>
								))}
						</ul>
					</div>
				) : tab === '2' && programList && programList.content.length == 0 ? (
					<div className="comm_noList">
						<img src={noList} alt="내용없음 이미지" />
						<p className="mt25">작성된 게시글이 없습니다.</p>
					</div>
				) : (
					''
				)}
			</div>

			{/* 프로그램,지도종료 */}
		</>
	);
};

export default Main_VillagePage;
