import React from 'react';
import { DialogProps, useDialog } from '../DialogProvider';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import useMember from '../../hooks/useMember';
import DeleteSuccessDailog from '../common/DeleteSuccessDailog';
import DeleteFailDailog from '../common/DeleteFailDailog';

// 활동지도 댓글 또는 게시물 수정 팝업
interface CmDeleteDialog extends DialogProps {
	type?: '';
	cmUid: number;
	crtUid: number;
}
const CmDeleteDialog = ({ cmUid, crtUid, ...props }: CmDeleteDialog) => {
	const { showDialog } = useDialog();
	const { member } = useMember();
	const handleClose = () => {
		props.onClose?.();
	};
	const handleDelete = async (cmUid: number, crtUid: number) => {
		await ActiveMapAPI.deleteMapping({
			delUid: Number(crtUid),
			cmUid: cmUid,
		})
			.then((res) => {
				// console.log('deleteMapping res ::: ', res);
				const tab = 'activeMapping';
				showDialog(DeleteSuccessDailog, { tab, handleClose });
				// window.location.reload();
				// props.onClose?.();
			})

			.catch((e) => {
				showDialog(DeleteFailDailog);
			});
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>삭제</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">삭제 하시겠습니까?</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button
						type="submit"
						className="btn_check"
						onClick={() => handleDelete(Number(cmUid), Number(crtUid))}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default CmDeleteDialog;
