import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import '../informationYard/EnergyStatusPage.scss';
import img_energyMap from '../../assets/img_energyMap.png';
import test09 from '../../assets/test09.png';
import test10 from '../../assets/test10.png';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../dialog/DialogProvider';
import Write_EnergyStatusDialog from '../../dialog/infomationYard/Write_EnergyStatusDialog';
import Piechart from '../../components/common/ChartComponent_pie';
import ChartAPI from '../../apis/ChartAPI';
import { ChartData, ChartList } from '../../models/ChartList';
import {
	BugguSelect,
	DongguSelect,
	GwangsanguSelect,
	NamguSelect,
	SeoguSelect,
} from '../../models/EnergyDataSelect';
// 정보마당 > 광주 에너지현황
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const SubLocationList2 = [
	{ href: '/info_02/', text: '광주 에너지현황' },
	{ href: '/info_02/analysis', text: '광주 에너지분석' },
	{ href: '/info_02/map', text: '광주 태양광 에너지지도' },
	{ href: '/info_02/cal', text: '탄소계산기' },
	{ href: '/dictionary_02', text: '에너지 용어사전' },
];
// const data = [
// 	{ dong: '송정동', energy: 190000, color: '#F7819F' },
// 	{ dong: '도산동', energy: 87500, color: '#58FA82' },
// 	{ dong: '신흥동', energy: 30000, color: '#DA81F5' },
// 	{ dong: '어룡동', energy: 50000, color: '#FFBF00' },
// 	{ dong: '우산동', energy: 158000, color: '#58ACFA' },
// ]
// const data2 = [
// 	{ dong: '송정동', energy: 160000, color: '#F7819F' },
// 	{ dong: '도산동', energy: 87500, color: '#58FA82' },
// 	{ dong: '신흥동', energy: 30000, color: '#DA81F5' },
// 	{ dong: '우산동', energy: 158000, color: '#58ACFA' },
// 	{ dong: '어룡동', energy: 190000, color: '#FFBF00' },
// ]
const EnergyStatusPage = () => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [guRadioSelect, setGuRadioSelect] = useState<string>('전체');
	const [dongSelect, setDongSelect] = useState<string>('전체');
	const [yyyySelect, setYyyySelect] = useState<string>('2023');

	const [dataList, setDataList] = useState<ChartList>();
	const [energyChartList, setEnergyChartList] =
		useState<{ name: string; value: number }[]>();
	const [energyConsumption, setEnergyConsumption] = useState<number>();
	const [gasChartList, setGasChartList] =
		useState<{ name: string; value: number }[]>();
	const [gasEmissions, setGasEmissions] = useState<number>();
	const [guEnergyChartList, setGuEnergyChartList] =
		useState<{ name: string; value: number }[]>();
	const [guEnergyConsumption, setGuEnergyConsumption] = useState<number>();
	const [guGasChartList, setGuGasChartList] =
		useState<{ name: string; value: number }[]>();
	const [guGasEmissions, setGuGasEmissions] = useState<number>();
	useEffect(() => {
		ChartAPI.getChartDataList({
			areaUpper: guRadioSelect,
			area: dongSelect,
			yyyy: yyyySelect,
		})
			.then((res) => {
				// console.log('차트 데이터리스트 >> ', res);
				setDataList(res);
				const formattedEnergyData = Object.entries(res.energyData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGasData = Object.entries(res.ggssData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGuEnergyData = Object.entries(res.guenergyData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGuGasData = Object.entries(res.guggssData).map(
					([name, value]) => ({ name, value })
				);
				// // console.log('왓냐 ㅋ', formattedEnergyData[0].name);
				setEnergyConsumption(res.energyData.에너지소비량);
				setGasEmissions(res.ggssData.온실가스배출량);
				setGuEnergyConsumption(res.guenergyData.에너지소비량);
				setGuGasEmissions(res.guggssData.온실가스배출량);
				setEnergyChartList(
					formattedEnergyData.filter(
						(filter) =>
							filter.name !== '에너지소비량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
				setGasChartList(
					formattedGasData.filter(
						(filter) =>
							filter.name !== '온실가스배출량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
				setGuEnergyChartList(
					formattedGuEnergyData.filter(
						(filter) =>
							filter.name !== '에너지소비량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
				setGuGasChartList(
					formattedGuGasData.filter(
						(filter) =>
							filter.name !== '온실가스배출량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
			})
			.catch((error) => {
				alert('데이터가 없습니다.');
			});
	}, [dongSelect, yyyySelect]);

	useEffect(() => {
		if (guRadioSelect === '동구') {
			setDongSelect('학동');
			return;
		}
		if (guRadioSelect === '서구') {
			setDongSelect('광천동');
			return;
		}
		if (guRadioSelect === '남구') {
			setDongSelect('대촌동');
			return;
		}
		if (guRadioSelect === '북구') {
			setDongSelect('건국동');
			return;
		}
		if (guRadioSelect === '광산구') {
			setDongSelect('도산동');
			return;
		}
		if (guRadioSelect === '전체') {
			setDongSelect('전체');
			return;
		}
	}, [guRadioSelect]);

	const handleWrite = () => {
		showDialog(Write_EnergyStatusDialog);
	};
	const handleGuRadioButton = (e: any) => {
		// console.log('구 선택 >>> ', e.target.value);
		setGuRadioSelect(e.target.value);
	};
	const handleDongSelect = (e: any) => {
		// console.log('동 선택 >>>> ', e.target.value);
		setDongSelect(e.target.value);
	};
	const handleYyyySelect = (e: any) => {
		// console.log('연도 선택 >>>> ', e.target.value);
		setYyyySelect(e.target.value);
	};

	return (
		<main className="EnergyStatusPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="정보마당"
					liList={SubLocationList}
					titleText2="광주 에너지현황"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>광주 에너지현황</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>광주광역시 자치구와 행정동의 에너지현황을 확인해보세요.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="energyStatus">
						{/* 지도시작 */}
						<div className="energyStatus_map">
							<form>
								<div className="map_img">
									<img src={img_energyMap} alt="광주 지도" />
									<p>
										<input
											type="radio"
											id="radio1-1"
											name="radio1"
											value="광산구"
											onClick={handleGuRadioButton}
											defaultChecked
										/>
										<label htmlFor="radio1-1">광산구</label>
									</p>
									<p>
										<input
											type="radio"
											id="radio1-2"
											name="radio1"
											value="서구"
											onClick={handleGuRadioButton}
										/>
										<label htmlFor="radio1-2">서구</label>
									</p>
									<p>
										<input
											type="radio"
											id="radio1-3"
											name="radio1"
											value="북구"
											onClick={handleGuRadioButton}
										/>
										<label htmlFor="radio1-3">북구</label>
									</p>
									<p>
										<input
											type="radio"
											id="radio1-4"
											name="radio1"
											value="남구"
											onClick={handleGuRadioButton}
										/>
										<label htmlFor="radio1-4">남구</label>
									</p>
									<p>
										<input
											type="radio"
											id="radio1-5"
											name="radio1"
											value="동구"
											onClick={handleGuRadioButton}
										/>
										<label htmlFor="radio1-5">동구</label>
									</p>
									<p>
										<input
											type="radio"
											id="radio1-6"
											name="radio1"
											value="전체"
											onClick={handleGuRadioButton}
											defaultChecked
										/>
										<label htmlFor="radio1-6">
											광주 전체 선택
											<span />
										</label>
									</p>
								</div>
								<div className="map_select mt25">
									<select onChange={handleDongSelect} value={dongSelect}>
										{dongSelect === '전체' && (
											<option value="전체">동 전체</option>
										)}
										{guRadioSelect === '동구' &&
											DongguSelect.map((list, index) => (
												<option key={index} value={list.area}>
													{list.area}
												</option>
											))}
										{guRadioSelect === '서구' &&
											SeoguSelect.map((list, index) => (
												<option key={index} value={list.area}>
													{list.area}
												</option>
											))}
										{guRadioSelect === '남구' &&
											NamguSelect.map((list, index) => (
												<option key={index} value={list.area}>
													{list.area}
												</option>
											))}
										{guRadioSelect === '북구' &&
											BugguSelect.map((list, index) => (
												<option key={index} value={list.area}>
													{list.area}
												</option>
											))}
										{guRadioSelect === '광산구' &&
											GwangsanguSelect.map((list, index) => (
												<option key={index} value={list.area}>
													{list.area}
												</option>
											))}
									</select>
									<select className="mt10" value={yyyySelect} onChange={handleYyyySelect}>
										<option value='2023'>연도 선택</option>
										<option value='2015'>2015</option>
										<option value='2016'>2016</option>
										<option value='2017'>2017</option>
										<option value='2018'>2018</option>
										<option value='2019'>2019</option>
										<option value='2020'>2020</option>
										<option value='2021'>2021</option>
										<option value='2022'>2022</option>
										<option value='2023'>2023</option>
									</select>
								</div>
							</form>
						</div>
						{/* 지도종료 */}
						{/* 그래프시작 */}
						<div className="energyStatus_graph">
							{/* 데이터 업데이트*/}
							{/* <div className="graph_updateBtn mb25">
								<button type="button" onClick={handleWrite}>
									데이터 업데이트
								</button>
							</div> */}
							<div className="graph_circle">
								<div className="box_circle pa20">
									<h3>
										{yyyySelect}년 {guRadioSelect} 에너지 소비량
										<br />
										{/* (1,160천 TOE) */}(
										{guEnergyConsumption?.toLocaleString()}천 TOE)
									</h3>
									<div className="mt10">
										{/* <img src={test09} alt="임시 이미지!" /> */}
										<Piechart data={guEnergyChartList} />
									</div>
								</div>
								<div className="box_circle pa20">
									<h3>
										{yyyySelect}년 {guRadioSelect} 온실가스 배출량
										<br />({guGasEmissions?.toLocaleString()}천 tCO2eq)
									</h3>
									<div className="mt10">
										{/* <img src={test10} alt="임시 이미지!" /> */}
										<Piechart data={guGasChartList} />
									</div>
								</div>
							</div>
							{guRadioSelect != '전체' && (
								<div className="graph_circle mt25">
									<div className="box_circle pa20">
										<h3>
											{yyyySelect}년 {dongSelect} 에너지 소비량
											<br />({energyConsumption?.toLocaleString()}천 TOE)
										</h3>
										<div className="mt10">
											{/* <img src={test09} alt="임시 이미지!" /> */}
											<Piechart data={energyChartList} />
										</div>
									</div>
									<div className="box_circle pa20">
										<h3>
											{yyyySelect}년 {dongSelect} 온실가스 배출량
											<br />({gasEmissions?.toLocaleString()}천 tCO2eq)
										</h3>
										<div className="mt10">
											{/* <img src={test10} alt="임시 이미지!" /> */}
											<Piechart data={gasChartList} />
										</div>
									</div>
								</div>
							)}
							<div className="graph_table mt25">
								{guRadioSelect != '전체' &&
									<>
										<ul className="thead pa20">
											<li>구분</li>
											<li>값(tCO2eq)</li>
											<li>{`순위(전체 97개 중)`}</li>
											<li>전년대비 증가율(%)</li>
											<li>광주대비 증가율(%)</li>
										</ul>
										<ul className="tbody">
											<li className="pa20">
												<p>에너지 소비량</p>
												<p>{energyConsumption?.toLocaleString()}</p>
												<p>{dataList?.energyData.rank}</p>
												<p
													className={`${Number(dataList?.energyData.yperdata) < 0
														? 'red'
														: 'blue'
														}`}
												>
													{`${Number(dataList?.energyData.yperdata) < 0
														? dataList?.energyData.yperdata
														: `+${dataList?.energyData.yperdata}`
														}`}
												</p>
												<p>{dataList?.energyData.gperdata}</p>
											</li>
											<li className="pa20">
												<p>온실가스 배출량</p>
												<p>{gasEmissions?.toLocaleString()}</p>
												<p>{dataList?.ggssData.rank}</p>
												<p
													className={`${Number(dataList?.ggssData.yperdata) < 0 ? 'red' : 'blue'
														}`}
												>
													{`${Number(dataList?.ggssData.yperdata) < 0
														? dataList?.ggssData.yperdata
														: `+${dataList?.ggssData.yperdata}`
														}`}
												</p>
												<p>{dataList?.ggssData.gperdata}</p>
											</li>
										</ul>
									</>
								}
								<span className="mt10">
									* 국토부 건물부문 에너지사용량 데이터과 도로교통 부문 데이터의
									에너지사용량 및 온실가스 배출량적용
								</span>
							</div>
						</div>
						{/* 그래프종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default EnergyStatusPage;
