import { listenerCount } from 'process';
import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import TownList, { content } from '../../models/TownList';
import { useSearchParams } from 'react-router-dom';

const CommSelect = ({
	townList,
}: // changeTownList,
// selectedTown,
{
	townList?: content[];
	// changeTownList?: (townId: number) => void;
	// selectedTown?: number;
}) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const town = searchParams.get('town');

	const townId = sessionStorage.getItem('town')
		? sessionStorage.getItem('town')
		: town;
	const getTownId = (e: ChangeEvent<HTMLSelectElement>) => {
		// if (changeTownList != undefined) {
		// changeTownList(Number(e.target.value));
		sessionStorage.setItem('town', String(e.target.value));
		navigate('/commu_02/mainVillage_03');
		// }
	};

	return (
		// <div className="comm_search_left">
		<select onChange={getTownId} value={Number(townId)}>
			{townList?.map((list) => (
				<option
					key={list.townId}
					value={list.townId}
					// selected={Number(townId) == list.townId}
				>
					{list.townName}
				</option>
			))}
		</select>
		// </div>
	);
};

export default CommSelect;
