import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PopupAPI from '../../apis/PopupAPI';
import ic_cam from '../../assets/ic_cam.png';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import './Detail_PopupManagement.scss';
const Detail_PopupManagement = () => {
    const { showDialog } = useDialog();
    const navigate = useNavigate();
    const [uploadFiles, setUploadFiles] = useState([] as File[]);
    const [previewImages, setPreviewImages] = useState<string[]>([]);
    const [textValue, setTextValue] = useState<string>('');
    const [popUseStatus, setPopUseStatus] = useState(true);
    const [deleteFileIds, setDeleteFileIds] = useState([] as number[]);

    // useEffect(() => {
    //     console.log(textValue.length);
    //     console.log("previewImages >> ", previewImages);
    //     console.log(popUseStatus);
    //     console.log("uploadFiles >> ", uploadFiles);

    // }, [textValue, previewImages, popUseStatus, uploadFiles])

    // 사진 첨부
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;
        const selectedImages = Array.from(files);
        // 미리보기 이미지 추가
        const imagesToSubmit = selectedImages.slice(0, 5);
        const newPreviewImages = imagesToSubmit.map((file) =>
            URL.createObjectURL(file)
        );
        // setUploadFiles((prevImages: any) => {
        //     const newImages = [...prevImages];
        //     selectedImages.slice(0, 1).forEach((image) => newImages.push(image));
        //     // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
        //     return newImages;
        // });
        setUploadFiles((prevImages) => [...selectedImages]);
        setPreviewImages((prevImages) => [...newPreviewImages]);
    };

    const handleTextValue = (text: string) => {
        setTextValue(text);
    }
    const handlePopupUseStatus = (status: boolean) => {
        if (status) {
            setPopUseStatus(true);
        } else {
            setPopUseStatus(false);
        }
    }

    // 첨부한 이미지 파일 x 버튼 누를시 삭제
    const handleRemovePreview = (index: number) => {

        setPreviewImages((prevImages) => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
        setUploadFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const handleInsert = () => {

        if (uploadFiles.length == 0 && textValue == '') {
            const title = '팝업 관리';
            const message = '사진 또는 내용을 입력해주세요.';
            showDialog(OneButtonAlertDialog, { title, message });
            return;
        }

        PopupAPI.insertPopup({
            title: textValue === '' ? undefined : textValue,
            status: popUseStatus ? 'Y' : 'N',
        }, uploadFiles)
            .then((res) => {
                if (res.data.type == 1) {
                    // console.log("등록 성공 !!~! ", res);
                    const title = '팝업 관리';
                    const message = '등록을 성공하였습니다.';
                    showDialog(OneButtonAlertDialog, {
                        title,
                        message,
                        handleDialogResult(result) {
                            if (result === 'ok') {
                                navigate('/popup_02/');
                                window.location.reload();
                            }
                        },
                    });
                } else {
                    // console.log("등록 실패 !!~! ", res);
                    const title = '팝업 관리';
                    const message = '등록을 실패하였습니다.';
                    showDialog(OneButtonAlertDialog, {
                        title,
                        message,
                    });
                }
            }).catch(() => {
                // console.log("등록 실패 !!~! ");
                const title = '팝업 관리';
                const message = '등록을 실패하였습니다.';
                showDialog(OneButtonAlertDialog, {
                    title,
                    message,
                });
            })
    }
    const handleCancle = () => {
        navigate(-1);
    }
    return (
        <main className="Detail_PopupManagement">
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>

                <div className="lnb_txt">
                    <h3 style={{ display: 'block', fontSize: '40px' }}>팝업 관리</h3>
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content" className="comm_manage_wrap">
                    <div className='imgInput'>
                        <p>
                            {/* <input
                                type="file"
                                id="img"
                                accept="image/*"
                                onChange={handleImageChange}
                            /> */}
                            <div className="imgContainer">
                                <div className="imgBox">
                                    <input className="upload-photo" />
                                    <label htmlFor="img">
                                        <img src={ic_cam} alt="사진 첨부" />
                                        사진 첨부
                                        <span>(1장)</span>
                                    </label>
                                    <input
                                        type="file"
                                        id="img"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                                <div className="imglistBox">
                                    <ul>
                                        {previewImages.map((imageUrl, index) => (
                                            <li key={index}>
                                                <img src={imageUrl} key={imageUrl} />
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemovePreview(
                                                        index,
                                                    )}
                                                    className="btn-file-delet"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </p>
                        <p>
                            <textarea
                                placeholder="내용을 입력하세요"
                                className='textBox'
                                defaultValue={''}
                                onChange={(e) => handleTextValue(e.target.value)}
                                style={{ resize: 'none' }}
                            />
                        </p>
                        <p style={{ fontWeight: '600', marginTop: '10px' }}>
                            <input
                                type="checkbox"
                                id='chek'
                                checked={popUseStatus}
                                onChange={(e) => handlePopupUseStatus(e.target.checked)}
                            />
                            팝업 사용 여부
                        </p>
                        <p className='buttonDiv'>
                            <button type='button' id="cancleButton" onClick={handleCancle}>취소</button>
                            <button type='button' className={`insertButton ${uploadFiles.length == 0 ? '' : 'on'}`} onClick={handleInsert}>등록</button>
                        </p>
                    </div>
                    <div className='preview'>
                        {previewImages && previewImages.map((imageUrl, index) => (
                            <img className='previewImg' src={imageUrl} key={imageUrl} alt="" />
                        ))}
                        <p style={{ height: previewImages.length !== 0 ? '80px' : '600px' }}>{textValue && textValue.split("\n")
                            .map(
                                (
                                    item,
                                    index
                                ) => (
                                    <React.Fragment
                                        key={
                                            index
                                        }
                                    >
                                        {item}
                                        <br />
                                    </React.Fragment>
                                )
                            )}</p>
                    </div>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

export default Detail_PopupManagement;