import React, { useEffect, useRef, useState } from 'react';
import SubLocation from '../../../components/common/SubLocation';
import CommSelect from '../../../components/common/CommSelect';
import './Write01_ActiveMapPage.scss';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MycmMasterList } from '../../../models/MapInfo';
import camImg from '../../../assets/ic_cam.png';
import ActiveMapAPI from '../../../apis/ActiveMapAPI';
import { useDialog } from '../../../dialog/DialogProvider';
import SubmitSuccessDialog from '../../../dialog/common/SubmitSuccessDialog';
import SubmitFailDialog from '../../../dialog/common/SubmitFailDialog';
import useMember from '../../../hooks/useMember';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import { AxiosError } from 'axios';

const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
// 지도 주제 작성 페이지
const Write01_ActiveMapPage = () => {
	const { member } = useMember();
	const townId = sessionStorage.getItem('town');
	const { showDialog } = useDialog();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<MycmMasterList>();
	const navigate = useNavigate();
	// 동록 버튼 활성화
	const [buttonOn, setButtonOn] = useState(false);
	const stDt = watch('stDt');
	const edDt = watch('edDt');
	const title = watch('title');
	const dtlTxt = watch('dtlTxt');
	const categoryList = watch('categoryList');

	useEffect(() => {
		if (stDt && edDt && title && dtlTxt && categoryList) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [stDt, edDt, title, dtlTxt, categoryList]);
	const handleCancle = () => {
		navigate(-1);
	};
	// 공개, 비공개
	const [isOpen, setIsOpen] = useState(false);
	const openChange = (e: any) => {
		setIsOpen(e.target.checked);
	};

	// 팔레트 옵션
	const colorOptions = Array.from(
		{ length: 15 },
		(_, index) => `color${index + 1}`
	);

	// 색상 팔레트 열기
	const [showColor, setShowColor] = useState(false);
	const colorOpen = () => {
		// // console.log('팔레트오픈');
		setShowColor(!showColor);
	};
	const colorRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				colorRef.current &&
				!colorRef.current.contains(event.target as Node)
			) {
				setShowColor(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// 팔레트에서 색상 선택
	const [clickColor, setClickColor] = useState('');
	const colorPick = (color: string) => {
		// // console.log('컬러픽');
		setClickColor(color);
	};
	useEffect(() => {
		// console.log('clickColor-> ', clickColor);
	}, [clickColor]);
	const [errorColor, setErrorColor] = useState('');
	const [errorCName, setErrorCName] = useState('');
	// 분류 추가
	const [marker, setMarker] = useState<{ mkName: string }[]>([]);
	const [part, setPart] = useState<{ color: string; name: string }[]>([]);
	const handleAdd = () => {
		// // console.log('추가클릭');
		const input = document.querySelector(
			'.partmake input[type="text"]'
		) as HTMLInputElement;
		const partName = input.value.trim();
		const markerMappings: { [color: string]: string } = {};

		if (!clickColor) {
			setErrorColor('분류색을 지정해주세요.');
		} else if (!partName) {
			setErrorCName('분류명을 입력해주세요.');
		}

		if (partName && clickColor) {
			const newPart = {
				color: clickColor,
				name: partName,
			};
			colorOptions.forEach((color, index) => {
				markerMappings[color] = `src/marker/mk_${index + 1}.png`;
			});
			const newMarker = { mkName: markerMappings[clickColor] };
			setPart([...part, newPart]);
			setMarker([...marker, newMarker]);
			input.value = '';
			setClickColor('');
		}
	};
	// 분류 삭제
	const handleDelete = (index: number) => {
		setPart((prevPart) => prevPart.filter((_, i) => i !== index));
	};

	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);

	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 5개 까지 업로드할수있습니다.');
			const title = '사진 첨부';
			const message = '사진은 1장만 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 필수값 미입력 에러 메시지
	const [errorDt, setErrorDt] = useState('');
	const [errorTitle, setErrorTitle] = useState('');
	const [errorDtlTxt, setErrorDtlTxt] = useState('');
	const [errorCate, setErrorCate] = useState('');
	const [result, setResult] = useState('');
	// 더블클릭 등록 방지
	const [noDoubleClick, setNoDoubleClick] = useState(false);
	// 등록하기
	const onSubmit: SubmitHandler<MycmMasterList> = async (data) => {
		setNoDoubleClick(true);
		const stDt = data.stDt;
		const edDt = data.edDt;
		const dateTime = new Date();
		const currentHours = ('0' + dateTime.getHours()).slice(-2); // 현재 시간을 가져옵니다.
		const currentMinutes = ('0' + dateTime.getMinutes()).slice(-2); // 현재 분을 가져옵니다.
		const currentSeconds = ('0' + dateTime.getSeconds()).slice(-2); // 현재 초를 가져옵니다.
		const resultStartTime = `${stDt} ${currentHours}:${currentMinutes}:${currentSeconds}`;
		const resultEndTime = `${edDt} ${currentHours}:${currentMinutes}:${currentSeconds}`;

		if (data.stDt && data.edDt && data.edDt < data.stDt) {
			// setErrorDt('활동기간을 다시 입력하세요.');
			const title = ''
			const message = '활동기간을 다시 입력해주세요.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			setNoDoubleClick(false);
			return;
		} else {
			setErrorDt('');
		}

		// 유효성 검사
		if (!data.stDt || !data.edDt) {
			// setErrorDt('활동기간을 입력해주세요.');
			const title = ''
			const message = '활동기간을 입력해주세요.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			setNoDoubleClick(false);
			return;
		} else {
			setErrorDt('');
		}
		if (!data.title) {
			// setErrorTitle('주제를 입력해주세요.');
			const title = ''
			const message = '주제를 입력해주세요.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			setNoDoubleClick(false);
			return;
		} else {
			setErrorTitle('');
		}
		if (!data.dtlTxt) {
			// setErrorDtlTxt('설명을 입력해주세요.');
			const title = ''
			const message = '설명을 입력해주세요.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
		} else {
			setErrorDtlTxt('');
		}
		if (!data.categoryList || data.categoryList.length === 0) {
			// setErrorCate('분류를 1개 이상 생성해주세요.');
			const title = ''
			const message = '분류를 1개 이상 생성해주세요.'
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
		} else {
			setErrorCate('');
		}
		try {
			await ActiveMapAPI.InsertCmmaster(
				{
					...data,
					// tcUid: Number(seletedTown),
					tcUid: Number(townId),
					title: data.title,
					dtlTxt: data.dtlTxt,
					stDt: resultStartTime,
					edDt: resultEndTime,
					crtUid: Number(member?.id),
					// crtUid: Number(member?.userID),
					openTp: isOpen ? 'N' : 'A',
					prgrsSt: data.prgrsSt,
					categoryList: part.map((pt, ptIdx) => ({
						title: pt.name,
						// crtUid: Number(member?.mbrUid),
						crtUid: Number(member?.id),
					})),
					markerList: marker.map((mk, mkIdx) => ({
						// crtUid: Number(member?.mbrUid),
						crtUid: Number(member?.id),
						markerFile: mk.mkName,
					})),
				},
				uploadFiles
			).then((res) => {
				const tab = 'activeMasterMap';
				showDialog(SubmitSuccessDialog, { tab });
				// console.log('성공 메세지: ', res);
				setNoDoubleClick(false);
				// navigate(-1);
				// setResult(res)
			});
		} catch (error) {
			const title = '등록 실패';
			let message = '등록에 실패하였습니다.';
			
			if (error instanceof AxiosError) {
				// AxiosError의 경우
				message = error.response?.data?.message ?? error.message;
			} else if (error instanceof Error) {
				// 일반 Error의 경우
				message = error.message;
			}

			showDialog(OneButtonAlertDialog, { 
				title, 
				message });
			setNoDoubleClick(false);
		}
	};

	return (
		<main className="List01_ActiveMapPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation titleText1="소통마당" liList={SubLocationList} />
				<div className="lnb_txt">
					<h3>활동지도 등록하기</h3>
					{/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">활동기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											// min={new Date().toISOString().split('T')[0]}
											{...register('stDt')}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											// min={new Date().toISOString().split('T')[0]}
											{...register('edDt')}
											className="endDate"
										/>
									</div>
									{errorDt && (
										<p className="error" style={{ color: 'red' }}>
											{errorDt}
										</p>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">주제</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="주제를 입력하세요"
										{...register('title')}
									/>
									{errorTitle && (
										<p className="error" style={{ color: 'red' }}>
											{errorTitle}
										</p>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">설명</h3>
								<div className="cont-box">
									<textarea
										placeholder="설명을 입력하세요"
										{...register('dtlTxt')}
									></textarea>
									{errorDtlTxt && (
										<p className="error" style={{ color: 'red' }}>
											{errorDtlTxt}
										</p>
									)}
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">분류 생성</h3>
								{errorCate && (
									<p className="error" style={{ color: 'red' }}>
										{errorCate}
									</p>
								)}
								<div className="cont-box">
									<div className="partmake">
										<input
											type="text"
											placeholder="분류명 (최대10자)"
											className="short"
											maxLength={10}
											{...register('categoryList')}
										/>

										<button
											type="button"
											className="btn_color"
											onClick={colorOpen}
										>
											{/* <!-- class마다 색넣어집니다 --> */}
											<span className={clickColor} />
										</button>
										<button
											type="button"
											className="btn_black"
											onClick={handleAdd}
										>
											추가
										</button>
										<div
											className="color_popup"
											style={{ display: showColor ? 'block' : 'none' }}
											ref={colorRef}
										>
											<ul>
												{colorOptions.map((color, index) => (
													<li key={index}>
														<p onClick={() => colorPick(color)}>
															<input
																type="radio"
																name="color"
																value={color}
															// onChange={() => colorPick(color)}
															// checked={clickColor === color}
															// onClick={colorPick}
															/>
															<label className={color} />
														</p>
													</li>
												))}
											</ul>
										</div>
									</div>
									<div className="color_table mt25">
										<ul className="thead pa20">
											<li>색상</li>
											<li>분류명</li>
											<li>관리</li>
										</ul>
										{/* <ul className="tbody">
											<li className="pa20">
												<p>
													<span className="color1"></span>
												</p>
												<p>분류명1</p>
												<p>
													<button type="button" className="btn_red">
														삭제
													</button>
												</p>
											</li>
										</ul> */}
										{part.map((pt, index) => (
											<ul className="tbody">
												<li className="pa20">
													<p>
														{/* <span className="color01" /> */}
														<span className={pt.color} />
													</p>
													<p>{pt.name}</p>
													<p>
														<button
															type="button"
															className="btn_red"
															onClick={() => handleDelete(index)}
														>
															삭제
														</button>
													</p>
												</li>
											</ul>
										))}
									</div>
								</div>
								{errorColor && (
									<p className="error" style={{ color: 'red' }}>
										{errorColor}
									</p>
								)}
								{errorCName && (
									<p className="error" style={{ color: 'red' }}>
										{errorCName}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3>사진첨부</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>상태</h3>
								<div className="cont-box">
									<p className="listSelect">
										<label>
											<input
												type="checkbox"
												// name="nicks"
												// value="ns"
												onClick={openChange}
												{...register('openTp')}
											/>
											<span>주민 비공개</span>
										</label>
									</p>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
							// disabled={buttonOn ? false : true}
							>
								등록
							</button>
							{/* <!-- 모두 체크 시 on클래스 붙음--> */}
						</div>
					</form>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default Write01_ActiveMapPage;
