import React from 'react';

import { DialogProps } from '../DialogProvider';

interface CharacterDialog extends DialogProps {
	type: '';
}
// 캐릭터 필수값 팝업
const CharacterDialog = ({ onClose }: CharacterDialog) => {
	const handleClose = () => {
		onClose?.();
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>회원가입 정보입력</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">
						캐릭터 선택은 필수입니다.
						{/* <br />
						캐릭터를 선택해주세요. */}
					</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};
export default CharacterDialog;
