import React, { useEffect, useState } from 'react';
import SubLocation from '../../../components/common/SubLocation';
import test01 from '../../../assets/test01.png';
import camImg from '../../../assets/ic_cam.png';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import ActivityLog, { Content } from '../../../models/ActivityLog';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import moment from 'moment';
import oneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import { useDialog } from '../../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
const Modify01_ActivePage = () => {
	const townId = sessionStorage.getItem('town');
	const subjectId = sessionStorage.getItem('subject');
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [detailSubject, setDetailSubject] = useState<Content>();
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [visibleCheck, setVisibleCheck] = useState(false);
	const [deleteImgFileIds, setDeleteImgFileIds] = useState([] as number[]);
	const [buttonOn, setButtonOn] = useState(false);
	const { register, getValues, setValue, watch, handleSubmit } =
		useForm<ActivityLog>();

	const startedAt = watch('startedAt');
	const endedAt = watch('endedAt');
	const title = watch('title');
	const content = watch('content');

	useEffect(() => {
		if (startedAt && endedAt && title && content) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [startedAt, endedAt, title, content]);

	useEffect(() => {
		ActivityLogAPI.getSubjectDetail(Number(subjectId)).then((data) => {
			// console.log('주제 수정 ', data);
			setValue('startedAt', moment(data.startedAt).format('YYYY-MM-DD'));
			setValue('endedAt', moment(data.endedAt).format('YYYY-MM-DD'));
			setValue('title', data.title);
			setValue('content', data.content);
			setDetailSubject(data);
			if (data?.subjectFileList) {
				const files: File[] = [];
				const promises: Promise<any>[] = [];

				for (let i = 0; i < data.subjectFileList.length; i++) {
					const promise = ActivityLogAPI.getImages({
						savedPath: data.subjectFileList[i].filePath,
					}).then((res) => {
						if (res) {
							const file = new File(
								[res.data],
								data.subjectFileList[i].fileRealName,
								{ type: 'image/png' }
							);
							// console.log(data.subjectFileList[i].fileRealName);
							files.push(file);
							const previewImage = `/api/attach/getFile?savedPath=${data.subjectFileList[i].filePath}&subjectFileId=${data.subjectFileList[i].subjectFileId}`;
							setPreviewImages((prevImages) => [...prevImages, previewImage]);
						}
					});
					promises.push(promise);
				}
			}
		});
	}, []);

	// 비공개 여부
	const handleVisibleCheck = (check: HTMLInputElement) => {
		// console.log(check.checked);
		if (check.checked) {
			setVisibleCheck(true);
		} else {
			setVisibleCheck(false);
		}
	};
	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			const title = '사진 첨부';
			const message = '사진은 1장만 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number, subjectFileId?: number) => {
		// console.log('사진삭제', subjectFileId);
		if (subjectFileId) {
			setDeleteImgFileIds((cur) => [...cur, subjectFileId]);
		}
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 취소 버튼
	const handleCancle = () => {
		navigate(-1);
	};

	// 등록 버튼
	const onSubmit: SubmitHandler<ActivityLog> = async (data) => {
		// console.log('data >> ', data);
		if (!data.startedAt) {
			const message = '활동 기간을 선택해 주세요.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (!data.endedAt) {
			const message = '활동 기간을 선택해 주세요.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (!data.title) {
			const message = '주제를 입력해 주세요.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (!data.content) {
			const message = '설명을 입력해 주세요.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			return;
		}
		const stDt = data.startedAt;
		const edDt = data.endedAt;
		const dateTime = new Date();
		const currentHours = ('0' + dateTime.getHours()).slice(-2); // 현재 시간을 가져옵니다.
		const currentMinutes = ('0' + dateTime.getMinutes()).slice(-2); // 현재 분을 가져옵니다.
		const currentSeconds = ('0' + dateTime.getSeconds()).slice(-2); // 현재 초를 가져옵니다.
		const resultStartTime = `${stDt} ${currentHours}:${currentMinutes}:${currentSeconds}`;
		const resultEndTime = `${edDt} ${currentHours}:${currentMinutes}:${currentSeconds}`;
		await ActivityLogAPI.updateSubject(
			{
				...data,
				townId: Number(townId),
				subjectId: Number(subjectId),
				startedAt: resultStartTime,
				endedAt: resultEndTime,
				visible: visibleCheck ? 'N' : 'Y',
			},
			uploadFiles ? uploadFiles : undefined,
			deleteImgFileIds ? deleteImgFileIds : undefined
		).then((res) => {
			// console.log('뭐왔냐', res);
			const message = '수정되었습니다.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			navigate('/commu_02/active_03-history');
		});
	};
	return (
		<main className="Write01_ActivePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation titleText1="소통마당" liList={SubLocationList} />
				<div className="lnb_txt">
					<h3>활동일지 주제 수정하기</h3>
					{/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">활동기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											{...register('startedAt')}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											className="endDate"
											{...register('endedAt')}
										/>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">주제</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="주제를 입력하세요"
										{...register('title')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">설명</h3>
								<div className="cont-box">
									<textarea
										placeholder="설명을 입력하세요"
										defaultValue={''}
										{...register('content')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>주제 이미지</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" value="" placeholder="" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											// {...register('uploadFiles')}
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul className="no-scroll">
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() =>
															handleRemovePreview(
																index,
																Number(imageUrl.split('subjectFileId=')[1])
															)
														}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>상태</h3>
								<div className="cont-box">
									<p className="listSelect">
										<label>
											<input
												type="checkbox"
												name="nicks"
												defaultValue="ns"
												onChange={(e) => handleVisibleCheck(e.target)}
											// defaultChecked
											/>
											<span>비공개</span>
										</label>
									</p>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
							// disabled={buttonOn ? false : true}
							>
								수정
							</button>{' '}
							{/* 모두 체크 시 on클래스 붙음*/}
						</div>
					</form>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Modify01_ActivePage;
