import React, { ChangeEvent, useEffect, useState } from 'react';
import './PlatformPage.scss';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router-dom';
import useMember from '../../hooks/useMember';
import header_logo from '../../assets/header_logo.png';
import energyLogoImg from '../../assets/logo_gj.png';
import testImgKo from '../../assets/testImgKo.png';
import infoImg from '../../assets/infoImg.png';

const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
    { href: '/platform_02/', text: '플랫폼' },
    { href: '/platform_02/energyConversionVillage/', text: '에너지전환마을' },
];
const menuList = [
    { title: '소통마당', info: '소통마당은 마을 주민들이 에너지와 관련해 소통하고 활동하는 공간입니다.'},
    { title: '참여마당', info: '참여마당은 에너지와 관련된 미션과 챌린지에 참여해 각 마을의 에너지전환 활동으로 절약한 수치를 확인할 수 있습니다.' },
    { title: '정보마당', info: '광주광역시 자치구와 행정동의 에너지 현황을 확인할 수 있습니다.' },
]
// 참여마당 > 에너지전환 현황
const PlatformPage = () => {
    const { member } = useMember();
    const navigate = useNavigate();

    return (
        <main className="PlatformPage">
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>
                <SubLocation
                    titleText1="소개마당"
                    liList={SubLocationList}
                    titleText2="플랫폼"
                    liList2={SubLocationList2}
                />
                <div className="lnb_txt">
                    <img className='platformLogo' src={energyLogoImg} alt="플랫폼 로고 이미지" />
                    {/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
                    <p className='platformWelCome'>광주광역시 에너지전환마을 지원 플랫폼에 오신 것을 환영합니다!</p>
                    <div className='platformImg'>
                        <img src={infoImg} alt="테스트 이미지" />
                    </div>
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content" >
                    <div className='platformInfoDiv'>
                        <p>
                            광주광역시 에너지전환마을 지원 플랫폼은 기후위기 및 에너지문제에 대응하기 위해<br />
                            광주광역시 에너지전환마을들의 활동을 공유하고 커뮤니티 조성을 위해 구축된 <span>' 시민참여형 에너지 전환 플랫폼 '</span> 입니다.
                        </p>
                        <h3>플랫폼 이용 메뉴 안내</h3>
                        <div className='platformMenuListDiv'>
                            <ul className='platformMenuListUl'>
                                    <li>
                                        <h4>소개마당</h4>
                                        <p className='Titleinfo'>: 플랫폼과 에너지전환마을에 대한 소개와 메뉴 이용 방법을 알아볼 수 있는 공간입니다.</p>
                                        <p>- 플랫폼 : 광주광역시 에너지전환마을 지원 플랫폼 소개와 플랫폼의 이용 방법을 알 수 있습니다. </p>
                                        <p>- 에너지전환마을 : 에너지전환마을 소개와 마을 15개소가 표시된 지도를 통해 각 마을로 입장할 수 있습니다. </p>
                                    </li>
                                    <li>
                                        <h4>소통마당</h4>
                                        <p className='Titleinfo'>: 마을 주민들이 에너지와 관련해 소통하고 활동하는 공간입니다.</p>
                                        <p>- 마을소통 : 마을의 소식을 확인하고 글과 댓글로 좋아요로 소통할 수 있습니다.</p>
                                        <p>- 활동지도 : 주제별로 주민들이 위치를 공유해 우리 마을만의 활동지도를 만들 수 있습니다.</p>
                                        <p>- 활동일지 : 활동일지 주제를 만들고, 주제에 대한 일지를 작성할 수 있습니다.</p>
                                        <p>- 교육프로그램 : 마을에서 주최하는 교육프로그램을 확인하고 참여 신청을 할 수 있습니다.</p>
                                        <p>- 마을소개 : 마을사업 내용, 거점센터, 활동사례 등을 확인할 수 있습니다.</p>
                                    </li>
                                    <li>
                                        <h4>참여마당</h4>
                                        <p className='Titleinfo'>: 에너지와 관련된 미션과 챌린지에 참여해 각 마을의 에너지전환 활동으로 절약한 수치를 확인 할 수 있습니다.</p>
                                        <p>- 에너지전환 현황 : 각 마을의 에너지 전환 활동으로 절약한 수치를 확인할 수 있습니다. 로그인을 하면 내가 활동해서 모은 포인트와 에너지 절감량만 따로 볼 수 있습니다.</p>
                                        <p>- 에너지전환 활동미션 : 마을미션(마을)과 기본미션(개인)이 있으며, 다양한 활동을 실천하는 인증미션입니다.</p>
                                        <p>- 에너지전환 챌린지 : 챌린지 기간 동안 마을/기본미션 활동에서 획득한 E 포인트로 승리를 결정됩니다. 목표로 정한 E 포인트를 달성 시 정해진 규칙대로 포인트를 배분합니다.</p>
                                    </li>
                                    <li>
                                        <h4>정보마당</h4>
                                        <p className='Titleinfo'>: 우리 동이 얼마나 에너지를 소비하고 있는지를 확인하고 탄소 계산기로 직접 내가 배출하고 있는 이산화탄소 배출량을 확인하는 등의 다양한 에너지 관련 정보를 확인할 수 있는 메뉴입니다.</p>
                                        <p>- 광주 에너지현황 : 광주광역시의 자치구와 행정동의 에너지 현황을 확인할 수 있습니다.</p>
                                        <p>- 광주 에너지분석 : 광주광역시의 자치구와 행정동의 에너지 소비량, 온실가스 배출량, 인구수 원단위를 확인할 수 있습니다.</p>
                                        <p>- 광주 태양광 에너지지도 : 태양전지판을 설치해 햇빛을 전기로 바꾸는 햇빛발전소를 세울 수 있는 부지를 알아보는 지도입니다.</p>
                                        <p>- 탄소 계산기 : 내가 사용한 전력과 가스의 사용량을 이산화탄소와 잣나무로 환산해 확인할 수 있습니다.</p>
                                        <p>- 에너지 용어 사전 : 에너지 전환과 관련된 용어 검색을 통해 관련 내용을 알아볼 수 있는 공간입니다.</p>
                                    </li>


                                {/* {menuList.map((list, index) => (
                                    <li key={index}>
                                        <p className='menuTitle'>{list.title}</p>
                                        <p >{list.info}</p>
                                    </li>
                                ))} */}


                            </ul>
                        </div>
                    </div>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main >
    );
};

export default PlatformPage;
