import React, { useEffect, useState } from 'react';
import '../missionYard/List_VillageMissionPage.scss';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router';
import Pagination from '../../components/common/Pagination';
import MissionAPI from '../../apis/MissionAPI';
import MissionInfo, { MissionListContent } from '../../models/MissionInfo';
import moment from 'moment';
import noList from '../../assets/noList.png';
import useMember from '../../hooks/useMember';
import errorImg from '../../assets/errorImg.jpg';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/', text: '내 E 포인트' },
];
const List_VillageMissionPage = () => {
	const { member } = useMember();
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	const [villMissionList, setVillMissionList] = useState(
		[] as MissionListContent[]
	);
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	const handleWrite = () => {
		navigate('/reward_02/villageMission_04-write');
	};

	// 상세보기 이동
	const handleDetail = (mmiUid: string, mssnDe: string) => {
		// console.log('마을미션 상세보기 이동', mssnDe);
		// console.log('마을미션 상세보기 이동', mmiUid);
		sessionStorage.setItem('mmiUid', String(mmiUid));
		if (mssnDe === '마을미션') {
			navigate(`/reward_02/villageMission_05-detail?mmiUid=${mmiUid}`);
		} else if (mssnDe === '지도미션') {
			navigate(`/reward_02/mapMission_05-detail?mmiUid=${mmiUid}`);
		}
	};
	// select 페이지 이동
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		if (window.document.location.href.includes('villageMission_03-list')) {
			setSelectedValue('villageMission');
		}
		if (window.document.location.href.includes('mission_03-list')) {
			setSelectedValue('basicMission');
		}
	}, [window.document.location.href]);
	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedValue = e.target.value;
		// console.log('selectedValue', selectedValue);

		// 선택된 옵션에 따라 페이지 이동을 처리
		if (selectedValue === 'villageMission') {
			setSelectedValue(e.target.value);
			navigate('/reward_02/villageMission_03-list');
		} else if (selectedValue === 'basicMission') {
			setSelectedValue(e.target.value);
			navigate('/reward_02/mission_03-list');
		}
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const nowDate = new Date();
	// // console.log('nowDate:::::', nowDate);
	const nowDateMonth = `${moment(nowDate).format('yyyy.MM.DD')}`;

	// 마을 미션 목록 조회
	useEffect(() => {
		MissionAPI.getMissionList({
			page: page - 1,
			size: 6,
			mssnDe: '마을미션',
			mssnDe2: '지도미션',
			// sort: 'prgrsSt,ASC',
		}).then((res) => {
			// console.log('getMissionList ::::', res);

			// // console.log('res.content ::::', res.content);
			// if (res.content) {
			// 	res.content.map((list: any, index: number) => {
			// 		const formattedStartDate = `${moment(list.startDt).format(
			// 			'yyyy.MM.DD'
			// 		)}`;
			// 		const formattedEndDate = `${moment(list.endDt).format('yyyy.MM.DD')}`;
			// 		const stDt = new Date(formattedStartDate);
			// 		const edDt = new Date(formattedEndDate);
			// 		// // console.log('엔드 데이트', edDt);
			// 		if (nowDate > edDt) {
			// 			list.status = '마감';
			// 		} else if (nowDate >= stDt && nowDate <= edDt) {
			// 			list.status = '진행';
			// 		} else {
			// 			list.status = '대기';
			// 		}
			// 		// list.missionFiles.map((list2: any, index2: number) => {
			// 		// 	list.thumbnail = list2.filePath;
			// 		// });
			// 		// list.startDate = formattedStartDate;
			// 		// list.endDate = formattedEndDate;
			// 		// // console.log('뭐임', formattedStartDate);
			// 	});
			// }
			// console.log('ddddd', res);
			res.content.map((list) => {
				// console.log(list.prgrsSt.split(''));
				// console.log(list.prgrsSt.length);
			});
			setVillMissionList(res.content);
			setCount(res.totalElements);
			// console.log('villMissionList :::::', villMissionList);
		});
	}, [page]);
	// 지도미션 만들기
	const handleMapMission = () => {
		// const title = '지도 미션 만들기';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(OneButtonAlertDialog, { title, message });
		navigate('/reward_02/mapMission_04-write');
	};
	const handleMapDetail = () => {
		navigate('/reward_02/mapMission_05-detail');
	};
	return (
		<main className="List_VillageMissionPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 활동미션"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>소통마당</h3>
					<select onChange={handleSelectChange} value={selectedValue}>
						<option value="basicMission">기본미션</option>
						<option value="villageMission">마을미션</option>
					</select>
					<p>
						마을이 주체가 되어 에너지 전환에 도움이 되는 활동을 실천하고
						인증해요
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="comm_tool">
						{/*  클릭 시 내 E 포인트로 이동. 이 달에 적립한 포인트/ 이 달에 적립 가능한 포인트 */}
						{/* <p>
							{nowDateMonth.slice(5, 7)}월 적립한 포인트&nbsp;<span>1,000</span>{' '}
							/ 10,000
						</p> */}
						{/* 총괄만 등록 가능 */}
						{member?.roles === 'AMN' ? (
							<>
								<div className="box_comm_write_button">
									<button
										type="button"
										className="comm_write_button border"
										onClick={handleMapMission}
									>
										지도미션 만들기
									</button>

									<button
										type="button"
										className="comm_write_button"
										onClick={handleWrite}
									>
										미션 만들기
									</button>
								</div>
							</>
						) : (
							<></>
						)}
					</div>
					{/* 최신순 정렬 */}
					{/* 비공개 게시물 non클래스 붙음 */}
					{villMissionList && villMissionList.length !== 0 ? (
						<>
							<div className="mission_village_list mt25">
								<ul>
									{villMissionList &&
										villMissionList.map((list, idex) => (
											<li
												className={list?.visible === 'N' ? 'non' : ''}
												onClick={() =>
													handleDetail(
														String(list.mmiUid),
														String(list?.mssnDe)
													)
												}
												key={idex}
											>
												{/* 비공개 게시물 non클래스 붙음 */}
												<a className="pa30">
													{/* 이미지 없으면 noimg class 붙음 */}
													<div
														// className="box_missionVillage"
														className={
															list?.missionFiles?.length === 0
																? 'box_missionVillage noimg'
																: 'box_missionVillage'
														}
													>
														<div className="missionVillage_txt">
															<div className="state">
																<span
																	className={
																		list?.prgrsSt === '대기'
																			? 'prev'
																			: list?.prgrsSt === '진행'
																				? 'ing'
																				: list?.prgrsSt === '종료'
																					? 'end'
																					: ''
																	}
																>
																	{/* 상태값이 진행 &&  회원 && 인증을 했다 ? '매월 mxCertCo-(인증한 횟수)회 참여가능'
																	만약 (인증한 횟수 === mxCertCo) ? '인증완료'
																	// certPdDe에 따라 mxCertCo는 리셋되어야 함
																	*/}
																	{/* 상태값이 진행 && 비회원 ? '매월 mxCertCo회 참여가능 */}
																	{list?.prgrsSt === '진행' &&
																		list?.certPdDe === 'D' &&
																		Number(list?.mxCertCo) -
																		Number(list?.certCnt) >
																		0
																		? `매일 ${Number(list?.mxCertCo) -
																		Number(list?.certCnt)
																		}회 참여가능`
																		: list?.prgrsSt === '진행' &&
																			list?.certPdDe === 'D' &&
																			Number(list?.mxCertCo) -
																			Number(list?.certCnt) ===
																			0
																			? '인증완료'
																			: list?.prgrsSt === '진행' &&
																				list?.certPdDe === 'M' &&
																				Number(list?.mxCertCo) -
																				Number(list?.certCnt) >
																				0
																				? `매달 ${Number(list?.mxCertCo) -
																				Number(list?.certCnt)
																				}회 참여가능`
																				: list?.prgrsSt === '진행' &&
																					list?.certPdDe === 'M' &&
																					Number(list?.mxCertCo) -
																					Number(list?.certCnt) ===
																					0
																					? `인증완료`
																					: list?.prgrsSt === '진행' &&
																						list?.certPdDe === 'P' &&
																						Number(list?.mxCertCo) -
																						Number(list?.certCnt) >
																						0
																						? `기간 내 ${Number(list?.mxCertCo) -
																						Number(list?.certCnt)
																						}회 참여가능`
																						: list?.prgrsSt === '진행' &&
																							list?.certPdDe === 'P' &&
																							Number(list?.mxCertCo) -
																							Number(list?.certCnt) ===
																							0
																							? '인증완료'
																							: list?.prgrsSt === '대기'
																								? '대기'
																								: list?.prgrsSt === '종료'
																									? '마감'
																									: '인증횟수 초과'}
																	{/* {list?.prgrsSt} */}
																</span>
																{/* 지도미션일 경우에만 보여주기 */}
																{list?.mssnDe === '지도미션' && (
																	<span className="state_number">지도미션</span>
																)}
															</div>
															<h4 className="comm_title txt_cut1 mt10">
																{list?.mssnNm}
															</h4>
															<p className="txt_cut1">
																{' '}
																{`${moment(list.startDt).format('yyyy.MM.DD')}`}
																{'~'}
																{`${moment(list.endDt).format('yyyy.MM.DD')}`}
															</p>
															<p className="point">
																{list?.pymntPt
																	?.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
																E
															</p>
														</div>
														<div className="missionVillage_img">
															{list?.missionFiles?.length !== 0 ? (
																list?.missionFiles
																	.filter(
																		(file) => file?.imgGb === '미션이미지'
																	)
																	.map((missionFile, index) => (
																		<img
																			key={index}
																			src={`/api/attach/getFile?savedPath=${missionFile?.savePath}`}
																			alt="지도 이미지"
																		/>
																	))
															) : (
																<img src={errorImg} alt="지도 이미지" />
															)}
														</div>
													</div>
												</a>
											</li>
										))}
								</ul>
							</div>
						</>
					) : (
						<>
							{' '}
							<div className="comm_noList">
								<img src={noList} alt="내용없음 이미지" />
								<p className="mt25">{nullListMessage}</p>
							</div>{' '}
						</>
					)}

					<Pagination
						page={page}
						// totalPageCount={Math.ceil(8 / 8)}
						totalPageCount={Math.ceil(count / 6)}
						onPageChange={handlePageChange}
					/>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default List_VillageMissionPage;
