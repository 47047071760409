import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MemberAPI from '../../apis/MemberAPI';
import TownAPI from '../../apis/TownAPI';
import VillageMngAPI from '../../apis/VillageMngAPI';
import SubLocation from '../../components/common/SubLocation';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import useMember from '../../hooks/useMember';
import TownList from '../../models/TownList';
import './MyVillageModify.scss';

// 마이페이지 > 내 마을 변경
const SubLocationList = [{ href: '/myPage_02', text: '마이페이지' }];

const SubLocationList2 = [
	{ href: '/mypage_02/myInfoModify_04', text: '내 정보 수정' },
	{
		href: '/mypage_02/mission-myVillageModify_04',
		text: '내 마을 변경',
	},
	{ href: '/mypage_02/myProgram_04', text: '내 교육프로그램' },

	{ href: '/mypage_02/myEPoint', text: '내 E 포인트' },
	{ href: '/mypage_02/myEnergy', text: '내 에너지 활동' },
	{ href: '/mypage_02/myAlarm_04', text: '내 알람' },
	// { href: '/reward_02/', text: '내 E 포인트' },
];

const MyVillageModify = () => {
	return (
		<main className="MyVillageModify">
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<Form />
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation
				titleText1="마이페이지"
				liList={SubLocationList}
				titleText2="내 마을 변경"
				liList2={SubLocationList2}
			/>
			<div className="lnb_txt">
				<h3>내 마을 변경</h3>
				{/* <CommSelect/> */}
				<p>내 정보를 확인하고 관리할 수 있습니다.</p>
			</div>
		</nav>
	);
};

const Form = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const { member } = useMember();
	const [selectVillage, setSelectVillage] = useState<number | undefined>();
	const [townList, setTownList] = useState<TownList>();

	// 이전 버튼
	const handleBack = () => {
		navigate('/myPage_02');
	};

	// 멤버 정보 조회
	useEffect(() => {
		MemberAPI.getUser().then((res) => {
			// console.log('내 마을 변경 > 멤버정보', res);
			setSelectVillage(res.townDTO.townId);
		});
	}, []);

	const handleSelectVillage = (village: number) => {
		if (village === selectVillage) {
			setSelectVillage(undefined);
		} else {
			setSelectVillage(village);
		}
	};

	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			if (res) {
				// console.log('마을 목록', res);
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((item) => item.districtName)
						.join(', ');
				});
				setTownList(res);
			}
		});
	}, []);

	useEffect(() => {
		// console.log('selectVillage !', selectVillage);
	}, [selectVillage]);

	const handleChangeVillage = () => {
		MemberAPI.updateMember({
			nickname: member ? member?.nickname : '',
			townId: selectVillage,
		}).then((res) => {
			if (res) {
				const title = '';
				const message = '마을변경이 완료되었습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
					handleDialogResult(result) {
						if (result === 'ok') {
							navigate('/myPage_02/1');
							window.location.reload();
						}
					},
				});
			}
		});
	};
	return (
		<>
			<form>
				<ul className="village_list">
					{townList?.content.map((list, index) => (
						<li
							key={index}
							className={
								member &&
									member.townDTO &&
									list &&
									list.townId === member?.townDTO.townId
									? 'myvill'
									: ''
							}
						>
							<label>
								<input
									type="radio"
									id="radio1-1"
									name="radio1"
									value={list.townId}
									onChange={(e) => handleSelectVillage(Number(e.target.value))}
									checked={list.townId === selectVillage}
								/>
								<p>
									{list.townTitle}
									<span>{list.townName}</span>
								</p>
							</label>
						</li>
					))}
					<li>
						<label>
							<input
								type="radio"
								id="radio1-1"
								name="radio1"
								value={0}
								checked={0 === selectVillage}
								onChange={(e) => handleSelectVillage(Number(e.target.value))}
							/>
							<p>
								{/* {list.districtDTOList.map((list2, index2) => (list2.districtName))} */}
								<span>선택안함</span>
							</p>
						</label>
					</li>
				</ul>
				<div className="comm_btn_wrap">
					<button type="button" className="btn_cancel" onClick={handleBack}>
						이전
					</button>
					<button
						type="button"
						className="btn_next on"
						onClick={handleChangeVillage}
					>
						변경
					</button>{' '}
					{/* 모두 체크 시 on클래스 붙음*/}
				</div>
			</form>
			<p className="important mt25">
				마을 변경은 <span>분기에 1회만 가능</span>하며, <span>포인트가 0으로 초기화</span>됩니다.
			</p>
		</>
	);
};

export default MyVillageModify;
