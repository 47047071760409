import React from 'react';
import { DialogProps } from '../DialogProvider';

interface TelCheckDialog extends DialogProps {
	type: '';
}
const TelCheckDialog = ({ onClose }: TelCheckDialog) => {
	const handleClose = () => {
		onClose?.();
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>회원가입 정보입력</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">
						연락처 중복체크를 해주세요.
						{/* <br />
						캐릭터를 선택해주세요. */}
					</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};
export default TelCheckDialog;
