import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, LabelList,
} from 'recharts';
import { BarChartDataList, YearTrnspData } from '../../models/ChartList';
// const data = [
//     { 'yyyy': '2015', '에너지소비량': 1660 },
//     { 'yyyy': '2016', '에너지소비량': 1720 },
//     { 'yyyy': '2017', '에너지소비량': 1680 },
//     { 'yyyy': '2018', '에너지소비량': 1760 },
//     { 'yyyy': '2019', '에너지소비량': 1670 },
//     { 'yyyy': '2020', '에너지소비량': 1780 },
//     { 'yyyy': '2021', '에너지소비량': 1750 },
//     // { 'dong': '우산동', '에너지소비량': 158000 },
//     // { 'dong': '월곡동', '에너지소비량': 155000 },
//     // { 'dong': '운남동', '에너지소비량': 141000 },
//     // { 'dong': '하남동', '에너지소비량': 138000 },
//     // { 'dong': '첨단동', '에너지소비량': 135000 },
//     // { 'dong': '비아동', '에너지소비량': 122000 },
//     // { 'dong': '신가동', '에너지소비량': 118000 },
//     // { 'dong': '신창동', '에너지소비량': 117000 },
//     // { 'dong': '평동', '에너지소비량': 111000 },
//     // { 'dong': '임곡동', '에너지소비량': 100000 },
//     // { 'dong': '등곡동', '에너지소비량': 90000 },
//     // { 'dong': '수완동', '에너지소비량': 82000 },
//     // { 'dong': '삼도동', '에너지소비량': 80000 },
//     // { 'dong': '본량동', '에너지소비량': 77000 },
// ]
const ChartComponent_bar_energy = ({ data, category, tab }: { data?: BarChartDataList, category: string, tab: number }) => {
    const formatYAxis = (tickItem: any) => tickItem.toLocaleString();
    const [barChartData, setBarChartData] = useState([] as YearTrnspData[])
    const [maxValue, setMaxValue] = useState<number>()
    const [valueList, setValueList] = useState<number[]>([]);
    useEffect(() => {
        // console.log("차트 데이터", data)
        // if (data) {
        //     if (data?.yearData.length !== 0) {
        //         for (let i = 0; i < data?.yearData.length; i++) {
        //             valueList.push(data.yearData[i].data);
        //         }
        //         const max = valueList.reduce((max, cur) => Math.max(max, cur))
        //         // console.log("maxmaxmaxmax>> max", max)
        //         setMaxValue(max);
        //     }
        // }
        if (category === '승용차' || category === '승합차' || category === '특수차' || category === '화물차') {
            if (data?.yearData) {
                // console.log("1111111111", data?.yearData)
                setBarChartData(data?.yearData);
            }
        } else {
            if (data?.yearData) {
                // console.log("22222222", data?.yearData)
                setBarChartData(data?.yearData);
            }
        }
    }, [data])

    const CustomTooltip = (e: any) => {
        const value = e.payload[0]?.value;

        if (value === 0|| value === undefined|| value === null) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '3px', boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.1)' }}>
                    <p className="label">데이터 자료 없음</p>
                </div>
            );
        } else {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '3px', boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.1)' }}>
                    <p className="label">
                        {`${e.label} ${
                            tab === 0 ? '에너지소비량' : ''
                        }${tab === 1 ? '온실가스 배출량' : ''}${
                            tab === 2 ? '인구수 원단위' : ''
                        } : ${value?.toLocaleString()}${
                            tab === 0 ? ' TOE' : ''
                        }${tab === 1 ? ' tCO2eq' : ''}${
                            tab === 2 ? ' tonCO2eq/인' : ''
                        }`}
                    </p>
                </div>
            );
        }
    };

    return (
        barChartData.length != 0 ?
            <ResponsiveContainer width='100%' height="100%" aspect={2.0}>
                <BarChart margin={{ top: 6, right: 5, bottom: 0, left: 30 }} data={barChartData} barCategoryGap={90}>
                    {/* <CartesianGrid strokeDasharray="3 0" /> */}
                    <CartesianGrid vertical={false} horizontal={true} />
                    <XAxis
                        dataKey="year"
                        tickSize={0}
                        tick={{ stroke: '#000', strokeWidth: 0 }}
                        tickMargin={10}
                        type='category'
                    // scale="auto"
                    />
                    <YAxis
                        dataKey="data"
                        type='number'
                        tickFormatter={formatYAxis}
                        // tickCount={20}
                        // domain={[0, `dataMax + ${maxValue}`]}
                        // domain={[0, 'dataMax+1000']}
                        interval={0}
                    />
                    <Tooltip cursor={false} content={CustomTooltip} formatter={formatYAxis} separator=' - ' />
                    <Bar dataKey="data" fill="#8884d8" barSize={30}>
                        {barChartData.map((list, index) => (
                            <Cell
                                key={list.data}
                                cursor="pointer"
                                fill={'#58ACFA'}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            :
            <div>데이터 없음</div>
    )
};

export default ChartComponent_bar_energy;