import axios from "axios";
import TownList, { content } from "../models/TownList";

interface GetAllParams {
    tcUid?: number
}

interface PopulationData {
    population: number
}

const getTownList = () => {
    return axios
    .get("/api/town")
    .then((res) => res.data as TownList);
}
const getTownOne = (townId: number) => {
    return axios
    .get(`/api/town/get?townId=${townId}`)
    .then((res) => res.data as content);
}

const getTownStatistics = (params: GetAllParams) => {
    return axios
    .get('/api/town/statistics', {params})
    .then((res) => res.data as PopulationData );
}


const TownAPI = {
    getTownList,
    getTownOne,
    getTownStatistics
}
export default TownAPI;