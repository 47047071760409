import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import '../informationYard/CarbonCalculatorPage.scss';
import ic_arrow from '../../assets/ic_arrow.png';
import ic_CO2 from '../../assets/ic_CO2.png';
import ic_tree from '../../assets/ic_tree.png';
// 정보마당 > 탄소계산기
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const SubLocationList2 = [
	{ href: '/info_02/', text: '광주 에너지현황' },
	{ href: '/info_02/analysis', text: '광주 에너지분석' },
	{ href: '/info_02/map', text: '광주 태양광 에너지지도' },
	{ href: '/info_02/cal', text: '탄소계산기' },
	{ href: '/dictionary_02', text: '에너지 용어사전' },
];

const CarbonCalculatorPage = () => {
	const [inputPowerUseMwhValue, setInputPowerUseMwhValue] = useState('');
	const [inputGasUseMjValue, setInputGasUseMjValue] = useState('');
	const handlePowerUseMwh = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자와 . 이외의 문자를 모두 제거
		const sanitizedValue4 = value.replace(/[^0-9.]/g, '');
		setInputPowerUseMwhValue(sanitizedValue4);
	};
	const handleGasUseMj = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자와 . 이외의 문자를 모두 제거
		const sanitizedValue4 = value.replace(/[^0-9.]/g, '');
		setInputGasUseMjValue(sanitizedValue4);
	};
	const [emissions, setEmissions] = useState<number>(0);
	const [tree, setTree] = useState<number>(0);
	useEffect(() => {
		const result = (
			Number(inputPowerUseMwhValue) * 0.4747 +
			Number(inputGasUseMjValue) * 0.0561
		).toFixed(2);
		// console.log('이산화탄소 발생량', result);
		setEmissions(Number(result));
		const treeResult = (Number(result) / 0.27).toFixed(2);
		// console.log('잣나무 수 ', treeResult);
		setTree(Number(treeResult));
	}, [inputPowerUseMwhValue, inputGasUseMjValue]);
	return (
		<main className="CarbonCalculatorPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="정보마당"
					liList={SubLocationList}
					titleText2="탄소계산기"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>탄소 계산기</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>
						내가 사용한 전력과 가스의 사용량을 이산화탄소로 환산해 확인해보세요.
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="wrapper">
						<div className="electric_gas pa30">
							<div className="electric_box">
								<h1>전력</h1>
								<div className="blue_box pa20 mt10">
									<input
										className="pa20"
										type="text"
										placeholder="소수점 입력가능"
										value={inputPowerUseMwhValue}
										onChange={handlePowerUseMwh}
									/>
									<div className="kwh">
										<p>KWh</p>
									</div>
								</div>
							</div>
							<div className="electric_box">
								<h1>가스</h1>
								<div className="blue_box pa20 mt10">
									<input
										className="pa20"
										type="text"
										placeholder="소수점 입력가능"
										value={inputGasUseMjValue}
										onChange={handleGasUseMj}
									/>
									<div className="kwh">
										<p>MJ</p>
									</div>
								</div>
							</div>
						</div>
						<div className="img_box pa20">
							<img src={ic_arrow} alt="화살표이미지" />
						</div>
						<div className="electric_gas pa30">
							<div className="electric_box">
								<div className="co2_box">
									<div className="blue_box01 pa20">
										<img src={ic_CO2} alt="co2발생량이미지" />
										<h1>
											CO<sup>2</sup> 발생량
										</h1>
										<div className="white_box pa20 mt10">
											<h1>{emissions.toLocaleString()}<span style={{ fontSize: '17px' }}> CO2eq</span> </h1>
										</div>
									</div>
								</div>
							</div>
							<div className="electric_box">
								<div className="co2_box">
									<div className="blue_box01 pa20">
										<img src={ic_tree} alt="내가심어야할잣나무이미지" />
										<h1>내가 심어야 할 잣나무</h1>
										<div className="white_box pa20 mt10">
											<h1>{tree.toLocaleString()} <span style={{ fontSize: '17px' }}> 그루</span></h1>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default CarbonCalculatorPage;
