import React, { useEffect, useState } from 'react';
import '../mypage/MyEPointPage.scss';
import ic_point from '../../assets/ic_point.png';
import ic_change from '../../assets/ic_change.png';
import ic_voucher from '../../assets/ic_voucher.png';
import ic_gift from '../../assets/ic_gift.png';
import ic_gift_list from '../../assets/ic_gift_list.png';
import SubLocation from '../../components/common/SubLocation';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import { useNavigate } from 'react-router';
import MissionAPI from '../../apis/MissionAPI';
import { MyPoint } from '../../models/MissionInfo';
const SubLocationList = [{ href: '/mypage_02/', text: '마이페이지' }];
const SubLocationList2 = [
	{ href: '/mypage_02/myInfoModify_04', text: '내 정보 수정' },
	{
		href: '/mypage_02/mission-myVillageModify_04',
		text: '내 마을 변경',
	},
	{ href: '/mypage_02/myProgram_04', text: '내 교육프로그램' },

	{ href: '/mypage_02/myEPoint', text: '내 E 포인트' },
	{ href: '/mypage_02/myEnergy', text: '내 에너지 활동' },
	{ href: '/mypage_02/myAlarm_04', text: '내 알람' },
];

const MyEPointPage = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [myPoint, setMyPoint] = useState<MyPoint>();
	const handleClick = () => {
		const title = '내 E포인트';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};
	const handleVillGift = () => {
		navigate('/myPage_02/villGiftCard_04');
	};
	const handleMyGift = () => {
		navigate('/myPage_02/myGiftCard_04');
	};
	const handlePoint = () => {
		navigate('/myPage_02/pointGift_04');
	};
	const handlePointList = () => {
		navigate('/myPage_02/pointGift_04-list');
	};
	// 내 포인트 조회
	useEffect(() => {
		MissionAPI.getMyPoint().then((res) => {
			// console.log('getMyPoint::::::', res);
			setMyPoint(res.data);
		});
	}, []);
	return (
		<main className="MyEPointPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="마이페이지"
					liList={SubLocationList}
					titleText2="내 E 포인트"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>내 E포인트</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>내 정보를 확인하고 관리할 수 있습니다.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="myEpoint">
						{/*내 포인트 시작*/}
						<div className="my_point pa30">
							<div className="point1 mb25">
								<h4>내 포인트</h4>
							</div>
							<div className="line"></div>
							<div className="point2 mt25">
								<h2>누적 포인트</h2>
								<p>
									{myPoint?.accmltPt ? myPoint?.accmltPt.toLocaleString() : 0}
								</p>
								<img src={ic_point} alt="E포인트이미지" />
							</div>
							<div className="point3 mt10">
								<h2>비가용 포인트</h2>
								<p>
									{myPoint?.disAblePt ? myPoint?.disAblePt.toLocaleString() : 0}
								</p>
								<img src={ic_point} alt="E포인트이미지" />
							</div>
							<div className="point4 mt10">
								<h2>현재 가용 포인트</h2>
								<span>
									{myPoint?.ablePt ? myPoint?.ablePt.toLocaleString() : 0}
								</span>
								<img src={ic_point} alt="E포인트이미지" />
							</div>
						</div>
						{/*내 포인트 끝*/}
						{/*상품권 교환 시작*/}
						<div className="title mt40">
							<h3>상품권 교환</h3>
						</div>
						<div className="voucher_change mt25">
							<button className="btn01 pa30" onClick={handleVillGift}>
								<div className="img_box">
									<img src={ic_change} alt="상품권교환이미지" />
									<h3 className="mt10">마을상품권 교환</h3>
								</div>
							</button>
							<button className="btn02 pa30" onClick={handleMyGift}>
								<div className="img_box">
									<img src={ic_voucher} alt="내마을상품권이미지" />
									<h3 className="mt10">내 마을상품권</h3>
								</div>
							</button>
						</div>
						{/*상품권 교환 끝*/}
						{/*포인트 선물 시작*/}
						<div className="title mt40">
							<h3>포인트 선물</h3>
						</div>
						<div className="point_gift mt25">
							<button className="btn01 pa30" onClick={handlePoint}>
								<div className="img_box">
									<img src={ic_gift} alt="상품권교환이미지" />
									<h3 className="mt10">포인트 선물하기</h3>
								</div>
							</button>
							<button className="btn02 pa30" onClick={handlePointList}>
								<div className="img_box">
									<img src={ic_gift_list} alt="내마을상품권이미지" />
									<h3 className="mt10">포인트 선물내역</h3>
								</div>
							</button>
						</div>
						{/*포인트 선물 끝*/}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default MyEPointPage;
