import React from 'react';
import { DialogProps } from '../DialogProvider';
import camImg from '../../assets/ic_cam.png';
import test01 from '../../assets/test01.png';
import './AdminPage_StoreDialog.scss'
interface AdminPage_StoreDialog extends DialogProps {
    type: '';

}
const AdminPage_StoreDialog = ({ ...props }: AdminPage_StoreDialog) => {

    const handleClose = () => {
        props.onClose?.();
    }
    return (
        <div className='AdminPage_StoreDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title mb25">
                        <h3>상점 등록</h3>
                        <a className="popup_close" onClick={handleClose}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC" alt="닫기" />
                        </a>
                    </div>
                    <form>
                        <p className="comm_title mb25">상점 정보</p>
                        <div className="comm_popup_content comm_write_wrap mb25">
                            <div className="cont-wrap">
                                <h3 className="important">
                                    구분
                                </h3>
                                <div className="cont-box">
                                    <div className="select-box">
                                        <select>
                                            <option value={0}>거점마을 상점</option>
                                            <option value={1}>거점마을 상점2</option>
                                            <option value={2}>거점마을 상점3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">상점명</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="상점명 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">연락처</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">소재지</h3>
                                <div className="cont-box">
                                    <input type="text" className="address" placeholder="주소를 입력하세요" disabled />
                                    <button type="button" className="btn_address">주소검색</button>
                                    <input type="text" className="detail_address" placeholder="상세주소를 입력하세요" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">상점소개</h3>
                                <div className="cont-box">
                                    <textarea placeholder="상점소개 (200자 이내)" defaultValue={""} />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>상점 이미지</h3>
                                <div className="cont-box">
                                    <div className="imgBox">
                                        <input className="upload-photo" />
                                        <label htmlFor="img">
                                            <img src={camImg} alt="사진 첨부" />
                                            사진 첨부
                                            <span>(1장)</span>
                                        </label>
                                        <input type="file" id="img" />
                                    </div>
                                    <div className="imglistBox">
                                        <ul className="no-scroll">
                                            <li>
                                                <img src={test01} alt="#" />
                                                <button type="button" className="btn-file-delet" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="comm_title mb25">사업자 정보</p>
                        <div className="comm_popup_content comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">사업자 등록번호</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">상점코드</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="영문숫자 혼합 8자리 이상 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>대표자명</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="이름 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>예금주</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="이름 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>은행명</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="은행명 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>계좌번호</h3>
                                <div className="cont-box">
                                    <input type="text" placeholder="숫자만 입력" />
                                </div>
                            </div>
                        </div>
                        <div className="comm_popup_btn mt25">
                            <button type="button" className="btn_cancel" onClick={handleClose}>취소</button>
                            <button type="submit" className="btn_check">등록</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default AdminPage_StoreDialog;