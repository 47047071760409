import moment from 'moment';
import { listenerCount } from 'process';
import React, { useEffect, useState } from 'react';
import MemberAPI from '../../apis/MemberAPI';
import Pagination from '../../components/common/Pagination';
import SubLocation from '../../components/common/SubLocation';
import AdminPage_MemberDialog from '../../dialog/adminMenu/AdminPage_MemberDialog';
import { useDialog } from '../../dialog/DialogProvider';
import { UserContent, UserList } from '../../models/MemberInfo';
import './AdminPage_Member.scss';
import { BugguSelect, DongguSelect, GwangsanguSelect, NamguSelect, SeoguSelect } from '../../models/EnergyDataSelect';
import TownList from '../../models/TownList';
import TownAPI from '../../apis/TownAPI';
import axios from 'axios';
import useMember from '../../hooks/useMember';
import { useNavigate } from 'react-router-dom';

const SubLocationList = [{ href: '/admin_02/', text: '관리자 메뉴' }];
const SubLocationList2 = [
	{ href: '/admin_02/', text: '회원관리' },
	{ href: '/admin_02/point', text: '포인트 및 보상 관리' },
	{ href: '/admin_02/static', text: '활동 통계' },
	{ href: '/admin_02/today', text: '이용자 수 통계' },
];

interface DownloadParams {
	gu?: string;
	dong?: string;
	townName?: string;
	authRole?: string;
	nickname?: string;
	fileName?: string;
	downloadFileName?: string;
}

// 관리자메뉴 회원관리
const AdminPage_Member = () => {
	const { showDialog } = useDialog();
	const [page, setPage] = useState<number>(1);
	const [userList, setUserList] = useState([] as UserContent[]);
	const [totalElements, setTotalElements] = useState<number>();
	const [totalPage, setTotalpage] = useState<number>();
	const [townList, setTownList] = useState<TownList>();
	// 검색
	const [gu, setGu] = useState<string>("구 전체");
	const [dong, setDong] = useState<string>("동 전체");
	const [townName, setTownName] = useState<string>("마을 전체");
	const [roles, setRoles] = useState<string>("권한 전체");
	const [nick, setNick] = useState<string>();
	// 권한
	const { member } = useMember();
	const navigate = useNavigate();

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	// 권한관리
	useEffect(() => {
		if (!member || member.roles !== 'AMN') {
			navigate('/');
		}
	}, []);

	// 목록조회
	useEffect(() => {
		const apiParams: {
			page: number,
			size: number,
			sort: string,
			// 검색
			gu?: string,
			dong?: string,
			townName?: string,
			authRole?: string,
			nickname?: string
		} = {
			page: page - 1,
			size: 10,
			sort: "crtDt,DESC",
		}

		if (gu !== "구 전체") {
			apiParams.gu = gu;
		}
		if (dong !== "동 전체") {
			apiParams.dong = dong;
		}
		if (townName !== "마을 전체") {
			apiParams.townName = townName;
		}
		if (roles !== "권한 전체") {
			apiParams.authRole = roles;
		}
		if (nick) {
			apiParams.nickname = nick;
		}

		MemberAPI.getUserList(apiParams
		).then((res) => {
			// console.log("res", res);
			setUserList(res.content);
			setTotalElements(res.totalElements);
			setTotalpage(res.totalPages);
		})
	}, [page, gu, dong, townName, roles, nick]);

	// console.log("유저리스트-->",userList);


	// 검색
	const guChange = (selectGu: string) => {
		setGu(selectGu);
		setDownloadParams({ ...downloadParams, gu: selectGu });
	}

	const dongChange = (selectDong: string) => {
		setDong(selectDong);
		setDownloadParams({ ...downloadParams, dong: selectDong });
	}

	const townChange = (selectTown: string) => {
		setTownName(selectTown);
		setDownloadParams({ ...downloadParams, townName: selectTown });
	}

	const rolesChange = (selectRoles: string) => {
		setRoles(selectRoles);
		setDownloadParams({ ...downloadParams, authRole: selectRoles });
	}

	// 닉네임 검색창
	const handleSearchNick = (nick: string | undefined) => {
		setNick(nick);
		setDownloadParams({ ...downloadParams, nickname: nick });
	}

	const getSearchKeyword = (e: any) => {
		if (handleSearchNick) {
			handleSearchNick(nick);
		}
		if (e.key === 'Enter') {
			if (handleSearchNick) {
				handleSearchNick(nick);
			}
		}
	};

	const [downloadParams, setDownloadParams] = useState<DownloadParams>({
		gu,
		dong,
		townName,
		authRole: roles,
		nickname: nick,
		downloadFileName: 'example_filename',
	});

	// console.log("downloadParams--", downloadParams); 


	// 목록 다운로드
	const handleDownload = async (downloadParams: DownloadParams
	) => {
		try {
			// const queryParams: DownloadParams = {};
			const queryParams: DownloadParams = { ...downloadParams };


			if (downloadParams.gu && downloadParams.gu === "구 전체") {
				delete queryParams.gu;
				// queryParams.gu = downloadParams.gu;
			}

			if (downloadParams.dong && downloadParams.dong === "동 전체") {
				// queryParams.dong = downloadParams.dong;
				delete queryParams.dong;
			}

			if (downloadParams.townName && downloadParams.townName === "마을 전체") {
				// queryParams.townName = downloadParams.townName;
				delete queryParams.townName;
			}

			if (downloadParams.authRole && downloadParams.authRole === "권한 전체") {
				// queryParams.authRole = downloadParams.authRole;
				delete queryParams.authRole;
			}

			if (downloadParams.nickname) {
				queryParams.nickname = downloadParams.nickname;
				// delete queryParams.gu;
			}

			// console.log("ffff", queryParams)
			const response = await axios
				.get(`/api/admin/userlist/download`, {
					responseType: 'blob',
					params: queryParams
				})
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', downloadParams.downloadFileName + '.xlsx');
			document.body.appendChild(link);
			link.click();
		} catch (error) {
		}
	}



	return (
		<main className="AdminPage_Member">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="관리자 메뉴"
					liList={SubLocationList}
					titleText2="회원관리"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>회원 관리</h3>
					<p>에너지전환마을 지원플랫폼의 회원을 관리할 수 있습니다.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="myEnergyAct">
						{/* 검색 시작 */}
						<SearchBar
							gu={String(gu)}
							guChange={guChange}
							dong={String(dong)}
							dongChange={dongChange}
							townName={String(townName)}
							townChange={townChange}
							roles={String(roles)}
							rolesChange={rolesChange}
							handleSearchNick={handleSearchNick}
							getSearchKeyword={getSearchKeyword}
						/>
						{/* 검색 종료 */}
						{/* 간단정보 시작 */}
						<div className="comm_tool mt25">
							<p>
								전체 가입자 수 <span className="blue">{totalElements && totalElements.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>명 (탈퇴{' '}
								<span className="red">{userList && userList[0] &&
									userList[0].delcnt !== null ? userList[0].delcnt : 0
								}</span>명 포함)
							</p>
							<button type="button" className="comm_write_button"
								onClick={() => handleDownload({
									...downloadParams,
									fileName: 'UserList.xlsx',
									downloadFileName: '회원 목록'
								})
								}>
								목록 다운로드
							</button>
						</div>
						{/* 간단정보 종료 */}
						{/* 테이블 시작 */}
						<MemberDataTable
							userList={userList}
							totalPage={Number(totalPage)}
							handlePageChange={handlePageChange}
							page={Number(page)}
						/>
						{/* 테이블 종료 */}

					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const SearchBar = ({
	gu,
	guChange,
	dong,
	dongChange,
	townName,
	townChange,
	roles,
	rolesChange,
	handleSearchNick,
	getSearchKeyword
}: {
	gu: string,
	guChange: (gu: string) => void
	dong: string,
	dongChange: (dong: string) => void
	townName: string,
	townChange: (townName: string) => void
	roles: string,
	rolesChange: (townName: string) => void
	handleSearchNick: (nick: string) => void
	getSearchKeyword: (e: any) => void
}) => {

	const [townList, setTownList] = useState<TownList>();
	useEffect(() => {
		TownAPI.getTownList()
			.then((res) => {
				setTownList(res);
			})
	}, [])

	return (
		<div className="comm_search mt40">
			<ul>
				<li>
					<select className="comm_select03" onChange={(e) => guChange(e.target.value)}>
						<option >구 전체</option>
						<option value="광산구">광산구</option>
						<option value="북구">북구</option>
						<option value="남구">남구</option>
						<option value="서구">서구</option>
						<option value="동구">동구</option>
					</select>
					<select className="comm_select03" onChange={(e) => dongChange(e.target.value)}>
						<option>동 전체</option>
						{gu === "광산구" &&
							GwangsanguSelect.map((list, index) => (
								<option key={index} value={list.area}>{list.area}</option>
							))
						}
						{gu === "북구" &&
							BugguSelect.map((list, index) => (
								<option key={index} value={list.area}>{list.area}</option>
							))
						}
						{gu === "남구" &&
							NamguSelect.map((list, index) => (
								<option key={index} value={list.area}>{list.area}</option>
							))
						}
						{gu === "서구" &&
							SeoguSelect.map((list, index) => (
								<option key={index} value={list.area}>{list.area}</option>
							))
						}
						{gu === "동구" &&
							GwangsanguSelect.map((list, index) => (
								<option key={index} value={list.area}>{list.area}</option>
							))
						}
					</select>
				</li>
				<li>
					<select className="comm_select03" onChange={(e) => townChange(e.target.value)}>
						<option >마을 전체</option>
						{townList?.content.map((list) => (
							<option value={list.townName}>{list.townName}</option>
						))}
					</select>
					<select className="comm_select03"
						onChange={(e) => rolesChange(e.target.value)}
					>
						<option>권한 전체</option>
						<option value="AMN">총괄 관리자</option>
						<option value="MST">마을 운영자</option>
						<option value="SMT">마을 부운영자</option>
						<option value="ABR">마을 활동가</option>
						<option value="MBR">주민</option>
						<option value="STB">회원</option>
					</select>
				</li>
				<li>
					<div className="comm_search_plus">
						<input type="text" placeholder="닉네임을 입력해주세요."
							onChange={(e) => handleSearchNick(e.target.value)}
							onKeyPress={getSearchKeyword}
						/>
					</div>
					<button type="button">검색</button>
				</li>
			</ul>
		</div>
	);
};

const MemberDataTable = ({
	userList,
	totalPage,
	handlePageChange,
	page
}: {
	userList: UserContent[] | undefined,
	totalPage: number,
	handlePageChange: (page: number) => void,
	page: number
}) => {
	const { showDialog } = useDialog();

	// 닉네임 팝업 
	const clickMemberNickName = (id: number) => {
		showDialog(AdminPage_MemberDialog, { id });
	};

	return (
		<>
			<div className="point_table mt25">
				<ul className="thead pa20">
					<li>
						회원
						<br />
						가입일자
					</li>
					<li>가입SNS</li>
					<li>닉네임</li>
					<li>현재 가용포인트</li>
					<li>거주지</li>
					<li>소속마을</li>
					<li>권한</li>
					<li>상태</li>
				</ul>
				<ul className="tbody">
					{userList &&
						userList.map((list, index) => (
							<li className="pa20" key={index}>
								{/* 가입일 */}
								<p>{moment(list.crtDt).format('YYYY.MM.DD')}</p>
								{/* 가입SNS */}
								<p>{list?.snsType === "K" ? "카카오" : "네이버"}</p>
								{/* 닉네임 */}
								<p>
									<a onClick={() => clickMemberNickName(list.id)}>{list?.nickname}</a>
								</p>
								{/* 현재 가용포인트 */}
								<p>{list?.ablePt > 0 ?
									list?.ablePt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									: "-"
								}</p>
								{/* 거주지 */}
								{list.gu ?
									<p>{list?.gu}&gt;{list.dong}</p>
									: <p>-</p>
								}
								{/* 소속마을 */}
								<p>{list?.townName
									? list?.townName : "-"}</p>
								{/* 권한 */}
								<p>{
									list?.roles === "AMN" ? "총괄 관리자" :
										list?.roles === "MST" ? "마을 운영자" :
											list?.roles === "SMT" ? "마을 부운영자" :
												list?.roles === "ABR" ? "마을 활동가" :
													list?.roles === "MBR" ? "주민" :
														list?.roles === "STB" ? "회원" : ""
								}
								</p>
								{/* 상태 */}
								<p className={list?.delDt != null ? "red" : ""}>
									{list?.delDt != null ? "탈퇴" : "-"}
								</p>
							</li>
						))}
				</ul>
			</div>
			<Pagination
				page={Number(page)}
				totalPageCount={Number(totalPage)}
				// totalPageCount={Math.ceil(count / 8)}
				onPageChange={handlePageChange}
			/>
		</>
	);
};
export default AdminPage_Member;
