import React from 'react';
import { DialogProps } from './DialogProvider';
import './Login/LoginDialog.scss';
interface OneButtonAlertDialogProps extends DialogProps {
	title: string;
	message: string;
	handleDialogResult?: (result: string) => void;
}
const OneButtonAlertDialog = ({
	message,
	title,
	handleDialogResult,
	...props
}: OneButtonAlertDialogProps) => {
	const handleClose = () => {
		if (handleDialogResult) {
			handleDialogResult('ok');
		}
		props.onClose?.();
		return;
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>{title}</h3>
				</div>
				<div className="comm_popup_content mt10">
					{message === 'null' ? '없음' : message.split('\n').map((line, index) => (
						<p className="explain" key={index}>
							{line}
						</p>
					))}
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default OneButtonAlertDialog;
