import moment from 'moment';
import { useEffect, useState } from 'react';
import EduProgramAPI from '../../apis/EduProgramAPI';
import Pagination from '../../components/common/Pagination';
import SubLocation from '../../components/common/SubLocation';
import { useDialog } from '../../dialog/DialogProvider';
import { BookingContent } from '../../models/EduProgram';
import './MyProgram.scss';
import 'moment/locale/ko';
import { useNavigate } from 'react-router-dom';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import noList from '../../assets/noList.png';

// 마이페이지 > 내 교육프로그램
const SubLocationList = [{ href: '/myPage_02', text: '마이페이지' }];

const SubLocationList2 = [
	{ href: '/mypage_02/myInfoModify_04', text: '내 정보 수정' },
	{
		href: '/mypage_02/mission-myVillageModify_04',
		text: '내 마을 변경',
	},
	{ href: '/mypage_02/myProgram_04', text: '내 교육프로그램' },

	{ href: '/mypage_02/myEPoint', text: '내 E 포인트' },
	{ href: '/mypage_02/myEnergy', text: '내 에너지 활동' },
	{ href: '/mypage_02/myAlarm_04', text: '내 알람' },
];
const MyProgram = () => {
	const { showDialog } = useDialog();
	const [bookingList, setBookingList] = useState([] as BookingContent[]);
	const [page, setPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState(0);
	const navigate = useNavigate();
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const [updateStatus, setUpdateStatus] = useState(false);
	const [length, setLength] = useState(false);

	// 예약 목록 조회
	useEffect(() => {
		const listParams: {
			// sfUid: number;
			page: number;
			size: number;
			// sort?: string;
			member: string;
		} = {
			// sfUid: Number(sfUid),
			page: Number(page) - 1,
			size: 4,
			// sort: sort
			member: String('member'),
		};

		EduProgramAPI.getBookingList(listParams).then((res) => {
			setBookingList(res.content);
			setTotalPage(res.totalPages);
			if (res.content && res.content.length == 0) {
				setLength(true);
			} else {
				setLength(false);
			}
		});
	}, [page, updateStatus, length]);

	// console.log('마이페이지 > 예약 목록 조회', bookingList);

	// 교육 프로그램 상세화면 이동
	const handleDetail = (sfUid: number) => {
		navigate(`/commu_02/eduProgram_03-detail?${sfUid}`);
	};

	// 예약 취소
	const handleBookingCancel = (
		action: string,
		sbUid: number,
		rsrvNo: number,
		rsrvNm: string,
		telNo: string,
		rsrvSt: string
	) => {
		const title = '';
		const message =
			action === '예약취소'
				? '교육프로그램 신청을 취소하시겠습니까? 취소된 신청은 복구할 수 없습니다. '
				: '';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result) {
					if (action === '예약취소') {
						// console.log('취소버튼 확인 ~', action);
						EduProgramAPI.modifyBookingProgram({
							sbUid: Number(sbUid),
							rsrvNo: Number(rsrvNo),
							rsrvNm: String(rsrvNm),
							telNo: String(telNo),
							rsrvSt: '취소',
						})
							.then((res) => {
								// console.log('예약취소 성공');
								const title = '예약 취소';
								const message = '완료되었습니다.';
								showDialog(OneButtonAlertDialog, {
									title,
									message,
									handleDialogResult(result) {
										if (result === 'ok') {
											setUpdateStatus(!updateStatus);
										}
									},
								});
								// window.location.reload();
							})
							.catch((error) => {
								// console.log('예약취소 실패', error);
							});
					}
				}
			},
		});
	};

	return (
		<main className="MyProgram">
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				{length ? (
					<div className="comm_noList">
						<img src={noList} alt="내용없음 이미지" />
						<p className="mt25">신청한 교육프로그램이 없습니다.</p>
					</div>
				) : (
					<div id="content">
						<div className="myProgram">
							<div className="myProgram_list">
								<ul>
									{bookingList &&
										bookingList.map((list, idx) => (
											<li>
												<a >
													{/* prev 대기, ing 신청중, over 신청종료, end 교육종료 */}
													{/* <div className={`box_myProgram pa30 ${list.facilityView.status === "대기" ? 'prev' : list.facilityView.status === "신청중" ? 'ing' :
                                                                                list.facilityView.status === "신청종료" ? 'over' : list.facilityView.status === "교육종료" ? 'end' : ''    
                                                                                }`}> */}
													<div
														className={`box_myProgram pa30 ${list.rsrvSt === '대기'
															? 'prev'
															: list.rsrvSt === '확정'
																? 'ing'
																: list.rsrvSt === '완료'
																	? 'over'
																	: list.rsrvSt === '취소'
																		? 'end'
																		: ''
															}`}
													>
														<h3>{list.facilityView.facilityName}</h3>
														<div className="mp_txt">
															{/* <p>list.rsrvSt - {list.rsrvSt}</p>
                                                <p>list.facilityView.status - {list.facilityView.status}</p> */}
															<p className="period">
																{`${moment(list.facilityView.educationStartDate)
																	.locale('ko')
																	.format('yyyy.MM.DD(ddd)')}`}
																{' ~ '}
																{`${moment(list.facilityView.educationEndDate)
																	.locale('ko')
																	.format('yyyy.MM.DD(ddd)')}`}
															</p>
															<p className="time">
																{moment(
																	list.facilityView.educationStartTime,
																	'HH:mm'
																).format('HH:mm')}
																{' ~ '}
																{moment(
																	list.facilityView.educationEndTime,
																	'HH:mm'
																).format('HH:mm')}
															</p>
															<p className="name">{list.rsrvNm}</p>
															<p className="number">
																{list.telNo.slice(0, 3)}-
																{list.telNo.slice(3, 7)}-{list.telNo.slice(7)}
															</p>
														</div>
														{/* [상태값]
                                                1) 대기
                                                교육프로그램 신청 후 승인 대기 상태
                                                - 교육프로그램 보기, 취소 노출
                                                2) 확정
                                                마을 부운영자 이상이 승인한 상태
                                                - 교육프로그램 보기 노출
                                                3) 교육완료
                                                마을 부운영자 이상이 완료>이용완료를 선택한 상태
                                                - 교육프로그램 보기 노출
                                                4) 취소
                                                마을 부운영자 이상이 완료 > 불참을 선택한 상태 또는
                                                본인이 대기 상태일 때 취소를 선택한 상태
                                                - 교육프로그램 보기 노출 */}

														<div className="mp_btn">
															<button
																type="button"
																onClick={() => handleDetail(Number(list.sfUid))}
															>
																교육프로그램 보기
															</button>
															{list.rsrvSt === '대기' && (
																<button
																	type="button"
																	className="red"
																	onClick={() =>
																		handleBookingCancel(
																			'예약취소',
																			Number(list.sbUid),
																			Number(list.rsrvNo),
																			String(list.rsrvNm),
																			String(list.telNo),
																			String(list.rsrvSt)
																		)
																	}
																>
																	취소
																</button>
															)}
														</div>
													</div>
												</a>
											</li>
										))}
								</ul>
							</div>
						</div>
						<Pagination
							page={Number(page)}
							onPageChange={handlePageChange}
							// totalPageCount={Math.ceil(count / 8)}
							totalPageCount={Number(totalPage)}
						/>
					</div>
				)}
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation
				titleText1="마이페이지"
				liList={SubLocationList}
				titleText2="내 교육프로그램"
				liList2={SubLocationList2}
			/>
			<div className="lnb_txt">
				<h3>내 교육프로그램</h3>
				{/* <CommSelect/> */}
				<p>내가 신청한 교육프로그램을 확인하세요.</p>
			</div>
		</nav>
	);
};

export default MyProgram;
