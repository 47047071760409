import React, { useEffect, useState } from 'react';
import { CartesianGrid, Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { ChartList } from '../../models/ChartList';
// const data = [
//     { 'dong': '송정동', '에너지소비량': 190000, 'color': '#F7819F' },
//     { 'dong': '도산동', '에너지소비량': 87500, 'color': '#58FA82' },
//     { 'dong': '신흥동', '에너지소비량': 30000, 'color': '#DA81F5' },
//     { 'dong': '어룡동', '에너지소비량': 50000, 'color': '#FFBF00' },
//     { 'dong': '우산동', '에너지소비량': 158000, 'color': '#58ACFA' },
//     // { 'dong': '월곡동', '에너지소비량': 30000 },
//     // { 'dong': '운남동', '에너지소비량': 141000 },
//     // { 'dong': '하남동', '에너지소비량': 138000 },
//     // { 'dong': '첨단동', '에너지소비량': 135000 },
//     // { 'dong': '비아동', '에너지소비량': 122000 },
//     // { 'dong': '신가동', '에너지소비량': 118000 },
//     // { 'dong': '신창동', '에너지소비량': 117000 },
//     // { 'dong': '평동', '에너지소비량': 111000 },
//     // { 'dong': '임곡동', '에너지소비량': 100000 },
//     // { 'dong': '등곡동', '에너지소비량': 90000 },
//     // { 'dong': '수완동', '에너지소비량': 82000 },
//     // { 'dong': '삼도동', '에너지소비량': 80000 },
//     // { 'dong': '본량동', '에너지소비량': 77000 },
// ]
export interface dataProp {
    dong: string;
    energy: number;
    color: string;
}
const chartColor = [
    { color: '#f63498' },
    { color: '#fe9ef2' },
    { color: '#9a6afc' },
    { color: '#a3a7f8' },
    { color: '#334ed8' },
    { color: '#2a8aeb' },
    { color: '#2cbce0' },
    { color: '#1fdcc8' },
    { color: '#0b8a0b' },
    { color: '#79b543' },
    { color: '#f5bd00' },
    { color: '#e6613e' },
    { color: '#ed463a' },
]
const Piechart = ({ data }: { data?: { name: string, value: number }[] }) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const formatYAxis = (tickItem: any) => tickItem.toLocaleString();
    const randomIndex = Math.floor(Math.random() * chartColor.length);

    const formatTooltip = (value: number) => {
        return `${value.toLocaleString()}%`;
    };

    return (
        <PieChart width={400} height={200}>
            <Tooltip
                cursor={false}
                formatter={formatTooltip}
            />
            <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                innerRadius={50}
                outerRadius={100}
                paddingAngle={0}
            // label
            // activeShape={(props) => <g>{props.children}</g>} // 활성화된 원의 스타일을 커스터마이징
            // activeShape={() => <div>aa</div>} // 활성화된 원의 스타일을 커스터마이징
            >
                {data?.map((list, index) => {
                    return (
                        <Cell
                            key={list.name}
                            // fill={chartColor[Math.floor(Math.random() * chartColor.length)].color}
                            fill={chartColor[index].color}
                        />
                    );
                })}
            </Pie>
            <Legend align='right' layout='vertical' />
        </PieChart>
    );
};

export default Piechart;