// 동구 행정동 13개
export const DongguSelect = [
    {area: '학동'},
    {area: '동명동'},
    {area: '서남동'},
    {area: '충장동'},
    {area: '학운동'},
    {area: '계림1동'},
    {area: '계림2동'},
    {area: '산수1동'},
    {area: '산수2동'},
    {area: '지산1동'},
    {area: '지산2동'},
    {area: '지원1동'},
    {area: '지원2동'},
]
// 서구 행정동 18개
export const SeoguSelect = [
    {area: '광천동'},
    {area: '금호1동'},
    {area: '금호2동'},
    {area: '농성1동'},
    {area: '농성2동'},
    {area: '동천동'},
    {area: '상무1동'},
    {area: '상무2동'},
    {area: '서창동'},
    {area: '양3동'},
    {area: '양동'},
    {area: '유덕동'},
    {area: '치평동'},
    {area: '풍암동'},
    {area: '화정1동'},
    {area: '화정2동'},
    {area: '화정3동'},
    {area: '화정4동'},
]
// 남구 행정동 17개
export const NamguSelect = [
    {area: '대촌동'},
    {area: '방림1동'},
    {area: '방림2동'},
    {area: '백운1동'},
    {area: '백운2동'},
    {area: '봉선1동'},
    {area: '봉선2동'},
    {area: '사직동'},
    {area: '송암동'},
    {area: '양림동'},
    {area: '월산동'},
    {area: '월산4동'},
    {area: '월산5동'},
    {area: '주월1동'},
    {area: '주월2동'},
    {area: '진월동'},
    {area: '효덕동'},
]
// 북구 행정동 28개
export const BugguSelect = [
    {area: '건국동'},
    {area: '동림동'},
    {area: '두암1동'},
    {area: '두암2동'},
    {area: '두암3동'},
    {area: '매곡동'},
    {area: '문화동'},
    {area: '문흥1동'},
    {area: '문흥2동'},
    {area: '삼각동'},
    {area: '석곡동'},
    {area: '신안동'},
    {area: '신용동'},
    {area: '양산동'},
    {area: '오치1동'},
    {area: '오치2동'},
    {area: '용봉동'},
    {area: '우산동'},
    {area: '운암1동'},
    {area: '운암2동'},
    {area: '운암3동'},
    {area: '일곡동'},
    {area: '임동'},
    {area: '중앙동'},
    {area: '중흥1동'},
    {area: '중흥2동'},
    {area: '중흥3동'},
    {area: '풍향동'},
]
// 광산구 행정동 21개
export const GwangsanguSelect = [
    {area: '도산동'},
    {area: '동곡동'},
    {area: '본량동'},
    {area: '비아동'},
    {area: '삼도동'},
    {area: '송정1동'},
    {area: '송정2동'},
    {area: '수완동'},
    {area: '신가동'},
    {area: '신창동'},
    {area: '신흥동'},
    {area: '어룡동'},
    {area: '우산동'},
    {area: '운남동'},
    {area: '월곡1동'},
    {area: '월곡2동'},
    {area: '임곡동'},
    {area: '첨단1동'},
    {area: '첨단2동'},
    {area: '평동'},
    {area: '하남동'},
]