import axios from "axios";
import { serialize } from "object-to-formdata";
import EduProgram, { BookingList, EduProgramList, ProgramCategory, ProgramContent } from "../models/EduProgram";
import { likeParam } from "../models/VillageCommuInfo";
import { ApplyInput } from "../pages/communicationYard/EduProgram/Detail_ProgramPage";

interface GetAllParams{
    page?: number;
    size?: number;
    category?: number[];
    sort?: string;
    sfUid?: number;
    tcUid?: number;
    status?: string;
    keyword?: string;
    savedPath?: string;
    visible?: string;
}
const insertProgram = (params: EduProgram, uploadFiles : File[], categoryList: number[]) => {
    const formData = new FormData();
     // 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
	});
     // 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFile', files[i], files[i].name);
	}
    for (let i = 0; i < categoryList.length; i++) {
        formData.append(`category[${i}]`, JSON.stringify(categoryList[i]));
    }
    return axios
    .post('/api/facility/save', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then((res) => res as any)
}
// 프로그램 업데이트
const updateProgram = (params: EduProgram, uploadFiles: File[], categoryList: number[]) => {
    const formData = new FormData();
    // 데이터 추가
    Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
    });
    // 파일 추가
    if(uploadFiles.length != 0){
        const files = Array.from(uploadFiles);
        for(let i = 0; i < files.length; i++){
            formData.append('uploadFile', files[i], files[i].name);
        }
    }else{
        formData.append('filePath', "null")
    }
    for(let i = 0; i < categoryList.length; i++){
        formData.append(`category[${i}]`, JSON.stringify(categoryList[i]));
    }
    return axios
    .post('/api/facility/update', formData, {
        headers:{
            'Content-Type':'multipart/form-data',
        },
    })
    .then((res) => res as any)
}

const privateProgram = (params: GetAllParams) => {
    return axios
    .post('/api/facility/update', serialize(params))
    .then((res) => res as any);
}
// 프로그램 삭제
const deleteProgram = (params : GetAllParams) => {
    return axios
    .post('/api/facility/delete', serialize(params))
    .then((res) => res as any);
}
const getEduProgramList = (params: GetAllParams) => {
    return axios
    .get(`/api/facility`, {params:{
        page: params.page,
        size: params.size,
        sort: params.sort,
        tcUid: params.tcUid,
        category: params.category ? params.category?.join(",") : undefined,
        status: params.status ? params.status : undefined,
        keyword: params.keyword ? params.keyword : undefined
    }})
    .then((res) => res.data as EduProgramList)
}
const getCategory = () => {
    return axios
    .get('/api/facility/getCategory')
    .then((res) => res.data as ProgramCategory)
}

// 교육프로그램 상세조회
const getEduProgramDetail = (params: GetAllParams) => {
    return axios
        .get('/api/facility/get', {params})
        .then((res) => res.data as ProgramContent);
}
// 이미지 조회
const getImages = (params: GetAllParams) => {
    return axios
    .get(`/api/attach/getFile`, {
		responseType: 'blob',
		params: params,
	})
}

// 교육 프로그램 예약
const bookingProgram = (params: ApplyInput) => {
    const formData = new FormData();
     // 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
	});
    return axios
        .post('/api/booking/save', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res as any)
}

// 좋아요
const insertLike = (data: likeParam) => {
	// console.log('insertLike', data);
	return axios.post('/api/facility/like', serialize(data)).then((res) => {
		return res.data as { success: boolean; message: string };
	});
};

// 예약 목록 조회
const getBookingList = (params: GetAllParams) => {
    return axios
        .get('/api/booking', {params})
        .then((res) => res.data as BookingList);
}

// 예약 수정
const modifyBookingProgram = (params: ApplyInput) => {
    const formData = new FormData();
     // 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
	});
    return axios
        .post('/api/booking/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((res) => res as any)
}

const EduProgramAPI = {
    insertProgram,
    getCategory,
    getEduProgramList,
    getEduProgramDetail,
    getImages,
    updateProgram,
    deleteProgram,
    privateProgram,
    bookingProgram,
    insertLike,
    getBookingList,
    modifyBookingProgram
}
export default EduProgramAPI;