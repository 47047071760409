import React, { useEffect, useState } from 'react';
import '../mypage/MyEnergyPage.scss';
import SubLocation from '../../components/common/SubLocation';
import ic_electricity from '../../assets/ic_electricity.png';
import ic_gas from '../../assets/ic_gas.png';
import ic_Savings from '../../assets/ic_Savings.png';
import ic_point from '../../assets/ic_point.png';
import ic_prev from '../../assets/ic_prev.png';
import ic_prev2 from '../../assets/ic_prev2.png';
import ic_next from '../../assets/ic_next.png';
import ic_next2 from '../../assets/ic_next2.png';
import MissionAPI from '../../apis/MissionAPI';
import { GetEnergy, MyPoint } from '../../models/MissionInfo';

const SubLocationList = [{ href: '/mypage_02/', text: '마이페이지' }];
const SubLocationList2 = [
	{ href: '/mypage_02/myInfoModify_04', text: '내 정보 수정' },
	{
		href: '/mypage_02/mission-myVillageModify_04',
		text: '내 마을 변경',
	},
	{ href: '/mypage_02/myProgram_04', text: '내 교육프로그램' },

	{ href: '/mypage_02/myEPoint', text: '내 E 포인트' },
	{ href: '/mypage_02/myEnergy', text: '내 에너지 활동' },
	{ href: '/mypage_02/myAlarm_04', text: '내 알람' },
];
const MyEnergyPage = () => {
	const [myEnergy, setMyEnergy] = useState<GetEnergy>();
	const [myPoint, setMyPoint] = useState<MyPoint>();
	useEffect(() => {
		MissionAPI.getMyEnergy().then((res) => {
			// console.log('getMyEnergy::::::', res);
			setMyEnergy(res.data);
		});
		// 내 포인트 조회
		MissionAPI.getMyPoint().then((res) => {
			setMyPoint(res.data);
		});
	}, []);
	return (
		<main className="MyEnergyPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="마이페이지"
					liList={SubLocationList}
					titleText2="내 에너지 활동"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>내 에너지 활동</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>내 에너지전환 활동을 통한 절감내역과 포인트 내역을 확인하세요</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="myEnergyAct">
						{/*에너지전환 활동시작*/}
						{myEnergy && (
							<div className="energy_activity pa30">
								<div className="energy_1">
									<div className="img_box">
										<img src={ic_electricity} alt="전기이미지" />
										<p>전기</p>
									</div>
									<div className="title_1">
										<h2>
											{myEnergy?.pwrerMwh ? myEnergy?.pwrerMwh : 0}{' '}
											<span>MWh</span>
										</h2>
										<h2>
											{myEnergy?.pwrerToe ? myEnergy?.pwrerToe : 0}{' '}
											<span>
												10<sup>-3</sup> TOE
											</span>
										</h2>
									</div>
								</div>
								<div className="energy_1">
									<div className="img_box">
										<img src={ic_gas} alt="도시가스이미지" />
										<p>도시가스</p>
									</div>
									<div className="title_1">
										<h2>
											{myEnergy?.gasMj ? myEnergy?.gasMj : 0} <span>MJ</span>
										</h2>
										<h2>
											{myEnergy?.gasToe ? myEnergy?.gasToe : 0}{' '}
											<span>
												10<sup>-3</sup> TOE
											</span>
										</h2>
									</div>
								</div>
								<div className="energy_1">
									<div className="img_box">
										<img src={ic_Savings} alt="총에너지절감량이미지" />
										<p>총 에너지 절감량</p>
									</div>
									<div className="title_1">
										<h2>
											{myEnergy?.redcnToe ? myEnergy?.redcnToe : 0}{' '}
											<span>
												10<sup>-3</sup> TOE
											</span>
										</h2>
									</div>
								</div>
							</div>
						)}

						{/*에너지전환 활동끝*/}
						{/*포인트내역1시작*/}
						<div className="point_box pa30 mt25">
							<div className="point_title">
								<h2>누적 포인트</h2>
								<p>
									{myPoint?.accmltPt ? myPoint?.accmltPt.toLocaleString() : 0}
								</p>
								<img src={ic_point} alt="포인트이미지" />
							</div>
							<div className="point_title mt10">
								<h2>비가용 포인트</h2>
								<p>
									{myPoint?.disAblePt ? myPoint?.disAblePt.toLocaleString() : 0}
								</p>
								<img src={ic_point} alt="포인트이미지" />
							</div>
							<div className="point_title1 mt10">
								<h2>현재 가용 포인트</h2>
								<p>{myPoint?.ablePt ? myPoint?.ablePt.toLocaleString() : 0}</p>
								<img src={ic_point} alt="포인트이미지" />
							</div>
						</div>
						{/*포인트내역1 끝*/}
						{/* 기간설정 시작 */}
						<div className="point_period mt40">
							<form>
								<div className="period_box">
									<p>
										<input type="radio" id="radio1-1" name="radio1" />
										<label htmlFor="radio1-1">1주일</label>
									</p>
									<p>
										<input type="radio" id="radio1-2" name="radio1" />
										<label htmlFor="radio1-2">1개월</label>
									</p>
									<p>
										<input type="radio" id="radio1-3" name="radio1" />
										<label htmlFor="radio1-3">3개월</label>
									</p>
									<div className="date_box">
										<input type="date" />
										&nbsp;~&nbsp;
										<input type="date" />
									</div>
									<button type="button">조회</button>
								</div>
							</form>
						</div>
						{/* 기간설정 종료 */}
						{/* 테이블 시작 */}
						<div className="point_table mt25">
							<ul className="thead pa20">
								<li>일시</li>
								<li>분류</li>
								<li>
									내용
									<br />
									(미션명)
								</li>
								<li>
									전력
									<br />
									(MWh)
								</li>
								<li>
									도시가스
									<br />
									(MJ)
								</li>
								<li>
									총 에너지
									<br />
									(TOE)
								</li>
								<li>포인트</li>
								<li>소멸일시</li>
							</ul>
							<ul className="tbody">
								<li className="pa20">
									<a>
										<p>2023.10.01</p>
										<p>기본미션</p>
										<p>거점마을 상점 5,000원 상품권 교환</p>
										<p>0.30</p>
										<p>nnn</p>
										<p>nnn</p>
										<p className="blue">+5,000</p>
										<p>2023.10.01</p>
									</a>
								</li>
								<li className="pa20">
									<a>
										<p>2023.10.01</p>
										<p>기본미션</p>
										<p>거점마을 상점 5,000원 상품권 교환</p>
										<p>0.30</p>
										<p>nnn</p>
										<p>nnn</p>
										<p className="red">-5,000</p>
										<p>2023.10.01</p>
									</a>
								</li>
								<li className="pa20">
									<a>
										<p>2023.10.01</p>
										<p>기본미션</p>
										<p>거점마을 상점 5,000원 상품권 교환</p>
										<p>0.30</p>
										<p>nnn</p>
										<p>nnn</p>
										<p className="blue">+5,000</p>
										<p>2023.10.01</p>
									</a>
								</li>
								<li className="pa20">
									<a>
										<p>2023.10.01</p>
										<p>기본미션</p>
										<p>거점마을 상점 5,000원 상품권 교환</p>
										<p>0.30</p>
										<p>nnn</p>
										<p>nnn</p>
										<p className="red">-5,000</p>
										<p>2023.10.01</p>
									</a>
								</li>
								<li className="pa20">
									<a>
										<p>2023.10.01</p>
										<p>기본미션</p>
										<p>거점마을 상점 5,000원 상품권 교환</p>
										<p>0.30</p>
										<p>nnn</p>
										<p>nnn</p>
										<p className="red">-5,000</p>
										<p>2023.10.01</p>
									</a>
								</li>
							</ul>
						</div>
						<div id="paging1" className="paging1 mt40">
							<p>
								<span className="numPN m_ar">
									<a data-page={1} title="처음 페이지로 이동하기">
										<img src={ic_prev2} alt="처음 페이지" />
									</a>
								</span>
								<span className="numPN over left">
									<a data-page={1} title="이전 페이지로 이동하기">
										<img src={ic_prev} alt="이전 페이지" />
									</a>
								</span>
								<span className="Present">
									<a className="on" data-page={1}>
										1
									</a>
								</span>
								<span>
									<a data-page={2}>2</a>
								</span>
								<span>
									<a data-page={3}>3</a>
								</span>
								<span>
									<a data-page={4}>4</a>
								</span>
								<span>
									<a data-page={5}>5</a>
								</span>
								<span className="numPN  over right">
									<a data-page={11} title="다음 페이지로 이동하기">
										<img src={ic_next} alt="다음 페이지" />
									</a>
								</span>
								<span className="numPN m_ar">
									<a data-page={14} title="마지막 페이지로 이동하기">
										<img src={ic_next2} alt="마지막 페이지" />
									</a>
								</span>
							</p>
						</div>
						{/* 테이블 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default MyEnergyPage;
