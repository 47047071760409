import React from 'react';
import { DialogProps } from '../DialogProvider';
import { useNavigate } from 'react-router-dom';

interface DeleteSuccessDailog extends DialogProps {
	type?: '';
	tab: string;
	handleClose: () => void;
}

const DeleteSuccessDailog = ({
	tab,
	handleClose,
	...props
}: DeleteSuccessDailog) => {
	const navigate = useNavigate();
	const handleConfirm = (tab: string) => {
		// console.log('tab:::::', tab);
		if (tab === 'activeMasterMap') {
			// 활동지도
			navigate('/commu_02/actMap_03');
			props.onClose?.();
		} else if (tab === 'villageCommu') {
			// 소통마당
			navigate(`/commu_02/villageCm_03`);
			props.onClose?.();
		} else if (tab === 'activeMapping') {
			// handleClose();
			window.location.reload();
			// navigate('/commu_02/actMap_03');

			//
		} else if (tab === 'introCase') {
			window.location.reload();
		}
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>삭제</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">완료되었습니다.</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button
						type="button"
						className="btn_check one"
						onClick={() => handleConfirm(tab)}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteSuccessDailog;
