import React, { useEffect, useState } from 'react';
import TownAPI from '../../apis/TownAPI';
import TownList, { content } from '../../models/TownList';
import CommSelect from './CommSelect';
import SubLocation from './SubLocation';
const SubLocationList = [
	{ href: '/platform_02/', text: '소개마당' },
	{ href: '/commu_02/', text: '소통마당' },
	{ href: '/reward_02/', text: '참여마당' },
	{ href: '/info_02/', text: '정보마당' },
];
const NavComponent = () => {
	const townId = sessionStorage.getItem('town');
	const [townList, setTownList] = useState([] as content[]);
	const [townInfo, setTownInfo] = useState([] as content[]);
	// const [selectedTown, setSelectedTown] = useState<number | undefined>();
	// 선택한 마을 townId
	// const changeTownList = (townId: number) => {
	//     setSelectedTown(townId);
	// };
	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			if (res) {
				setTownList(res.content);
				setTownInfo(
					res &&
						res.content &&
						res.content.filter((list) => list.townId === Number(townId))
				);
				res &&
					res.content &&
					res.content.map((list, index) => {
						list.townTitle = list.districtDTOList
							.map((list2, index2) => list2.districtName)
							.join(', ');
					});
			}
		});
	}, [townId]);
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation titleText1="소통마당" liList={SubLocationList} />
			<div className="lnb_txt">
				<h3>소통마당</h3>
				<CommSelect
					townList={townList}
					// changeTownList={changeTownList}
					// selectedTown={selectedTown}
				/>
				{townInfo &&
					townInfo.map((list, idx) => <p key={idx}>{list.townTitle}</p>)}
			</div>
		</nav>
	);
};

export default NavComponent;
