import React from 'react';

import '../Login/LoginDialog.scss';
import { DialogProps, useDialog } from '../DialogProvider';
import { useNavigate } from 'react-router-dom';
import VillageCommuAPI from '../../apis/VillageCommuAPI';
import DeleteSuccessDailog from './DeleteSuccessDailog';
import DeleteFailDailog from './DeleteFailDailog';
import IntroAPI from '../../apis/IntroAPI';
import ActivityLogAPI from '../../apis/ActivityLogAPI';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
// 댓글 또는 게시물 삭제 팝업
interface DeleteDialog extends DialogProps {
	type?: '';
	tab: string;
	boardId?: string;
	tccUid?: number;
	subjectId?: number;
	cmmUid?: number; 
	delUid?: number;
	// onComplete: (result: string) => void;
}
const DeleteDialog = ({ tab, tccUid, boardId, subjectId, cmmUid, delUid, ...props }: DeleteDialog) => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	const handleClose = () => {
		// // console.log(result);
		props.onClose?.();
	};

	const handleConfirm = async (
		tab: string,
		boardId?: string,
		tccUid?: Number,
		subjectId?: Number,
	) => {
		if (tab === 'villageCommu') {
			await VillageCommuAPI.deleteVillageCommu({
				boardId: Number(boardId),
			})
				.then((res) => {
					// console.log('마을소통 글 삭제', res);
					showDialog(DeleteSuccessDailog);

					navigate(`/commu_02/villageCm_03`);
					props.onClose?.();
				})
				.catch((error) => {
					// console.log('마을소통 글 삭제 실패', error);
					showDialog(DeleteFailDailog);
				});
		} else if (tab === 'introCase') {
			await IntroAPI.deleteCase(Number(tccUid))
				.then((res) => {
					// console.log('deleteCase ::::', res);
					showDialog(DeleteSuccessDailog, { tab, handleClose });
					// navigate('/commu_02/intro_03');
					// props.onClose?.();
				})
				.catch((e) => {
					showDialog(DeleteFailDailog);
				});
		} else if (tab === 'activeSubject') {
			await ActivityLogAPI.deleteSubject({
				subjectId: Number(subjectId)
			})
				.then((res) => {
					// console.log('deleteCase ::::', res);
					showDialog(DeleteSuccessDailog, { tab, handleClose });
					
					navigate(`/commu_02/active_03`);
					props.onClose?.();
				})
				.catch((e) => {
					showDialog(DeleteFailDailog);
				});
		} else if (tab == 'activeMasterMap') {
			await ActiveMapAPI.deleteCmMaster({
				cmmUid: Number(cmmUid), 
				updUid: Number(delUid),
				delUid: Number(delUid)
			})
				.then((res) => {
					// console.log('deleteCase ::::', res);
					showDialog(DeleteSuccessDailog, { tab, handleClose });
					
					navigate(`/commu_02/actMap_03`);
					props.onClose?.();
				})
				.catch((e) => {
					showDialog(DeleteFailDailog);
				});
		}
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>삭제</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">삭제 하시겠습니까?</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button
						type="submit"
						className="btn_check"
						onClick={() =>
							handleConfirm(String(tab), String(boardId), Number(tccUid), Number(subjectId))
						}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteDialog;
