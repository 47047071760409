import React from 'react';
// import { LoginBasic } from '../apis/MemberAPI';

import MemberInfo from '../../models/MemberInfo';
// 수정필요
export type LoginBasic = {
	// userId?: string;
	// userPw?: string;
	nickname: string;
	username?: string;
	telNo?: string;
	character: string;
};

interface MemberState {
	member?: MemberInfo;
	login: (snsId: string, snsType: string) => any;
	loginBasic: (data: LoginBasic) => any;
	logout: () => any;
	debugLogin: (member: MemberInfo) => any;
	selectLocation: () => any;
	setMember: (member: MemberInfo) => any;
	getMemInfo: (target: MemberInfo) => any;
}

const initialState: MemberState = {
	login() {},
	logout() {},
	debugLogin() {},
	loginBasic() {},
	selectLocation() {},
	setMember() {},
	getMemInfo() {},
};

const MemberContext = React.createContext<MemberState>(initialState);

export default MemberContext;
