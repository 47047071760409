import axios from 'axios';
import VillageMngInfo from '../models/VillageMngInfo';
import { serialize } from 'object-to-formdata';

export interface GetAllParams {
	tcUid: number;
    page?: number;
    size?: number;
}
export interface UpdParams {
	nickname: string;
	g?: boolean; // 양도 여부 true: false
	roles: string;
}
// 주민관리 > 목록조회
const getMngList = (params: GetAllParams) => {
	return axios
		.get('/user/list', { params })
		.then((res) => res.data as VillageMngInfo);
};
// 주민관리 > 권한변경 dddd
const updateRoles = (params: UpdParams) => {
	return axios.post('/user/roleChange', serialize(params)).then((res) => {
		res.data as VillageMngInfo;
	});
};
const VillageMngAPI = { getMngList, updateRoles };
export default VillageMngAPI;
