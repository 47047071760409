import { SubmitHandler, useForm } from 'react-hook-form';
import { Content } from '../../models/MissionInfoDetail';
import MissionInfo from '../../models/MissionInfo';
import { DialogProps, useDialog } from '../DialogProvider';
import './missionDialog.scss';
import MissionAPI from '../../apis/MissionAPI';
import useMember from '../../hooks/useMember';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import { useState } from 'react';

// 고지서 인증 팝업

interface BillMissionDialog extends DialogProps {
	missionDetail: MissionInfo | undefined;
}

const BillMissionDialog = ({ missionDetail, ...props }: BillMissionDialog) => {
	const { showDialog } = useDialog();
	const { member } = useMember();
	const { register, handleSubmit, getValues, setValue, watch, setFocus } =
		useForm<Content>();
	const [updateStatus, setUpdateStatus] = useState(false);
	const handleClose = () => {
		props.onClose?.();
	};

	// 필수값 미입력 에러 메시지
	const [errYy, setErrYt] = useState('');
	const [errMm, setErrMm] = useState('');
	const [errBuildStdr, setErrBuildStdr] = useState('');
	const [errRmstnfamCo, setErrRmstnfamCo] = useState('');
	const [errArea, setErrArea] = useState('');
	const [errResideTp, setErrResideTp] = useState('');
	const [errPwrerUseMwh, setErrPwrerUseMwh] = useState('');
	const [errGasUseMj, setErrGasUseMj] = useState('');

	const onSubmit: SubmitHandler<Content> = async (data) => {
		// console.log('data ::::::::', data);
		// // console.log('data?.billYyyy""""""', data?.billYyyy);

		// if (data?.billYyyy === '0') {
		// 	const title = '고지서 인증하기';
		// 	const message = '고지연월을 선택하세요.';
		// 	showDialog(OneButtonAlertDialog, { title, message });
		// 	return;
		// }
		// if (data?.billMm === '0') {
		// 	const title = '고지서 인증하기';
		// 	const message = '고지연월을 선택하세요.';
		// 	showDialog(OneButtonAlertDialog, { title, message });
		// 	return;
		// }
		// if (data?.buildStdr === '') {
		// 	const title = '고지서 인증하기';
		// 	const message = '구축기준을 선택하세요.';
		// 	showDialog(OneButtonAlertDialog, { title, message });
		// 	return;
		// }
		if (data?.billYyyy === '0') {
			setErrYt('고지연월을 선택하세요.');
			setFocus('billYyyy');
			return;
		}
		if (data?.billMm === '0') {
			setErrMm('고지연월을 선택하세요.');
			setFocus('billMm');
			return;
		}
		if (data?.buildStdr === '') {
			setErrBuildStdr('구축기준을 선택하세요.');
			setFocus('billYyyy');
			return;
		}
		if (!data?.rmstnfamCo) {
			setErrRmstnfamCo('세대원수를 입력하세요.');
			setFocus('rmstnfamCo');
			return;
		}
		if (!data?.area) {
			setErrArea('면적을 입력하세요.');
			setFocus('area');
			return;
		}
		if (data?.resideTp === '') {
			setErrResideTp('주거형태를 선택하세요.');
			setFocus('resideTp');
			return;
		}
		if (!data?.pwrerUseMwh) {
			setErrPwrerUseMwh('전력 사용량을 입력하세요.');
			setFocus('pwrerUseMwh');
			return;
		}
		if (!data?.gasUseMj) {
			setErrGasUseMj('가스 사용량을 입력하세요.');
			setFocus('gasUseMj');
			return;
		}

		MissionAPI.insertCert({
			mmiUid: missionDetail?.mmiUid,
			tcUid: member?.townDTO?.townId,
			accmlPt: Number(missionDetail?.pymntPt),
			billYyyy: data?.billYyyy,
			billMm: data?.billMm,
			buildStdr: data?.buildStdr,
			rmstnfamCo: data?.rmstnfamCo,
			area: data?.area,
			resideTp: data?.resideTp,
			pwrerUseMwh: data?.pwrerUseMwh,
			gasUseMj: data?.gasUseMj,
			certProgrsSt: '신청',
		})
			.then((res) => {
				const title = '고지서 인증하기';
				const message = `인증을 완료해 ${missionDetail?.pymntPt
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}포인트가 적립되었습니다`;

				showDialog(OneButtonAlertDialog, {
					title,
					message,
					handleDialogResult(result) {
						// console.log('result::::', result);

						if (result === 'ok') {
							// console.log('rerer');
							window.location.reload();
							setUpdateStatus(!updateStatus);
						}
					},
				});
			})
			.catch((e) => {
				const title = '고지서 인증하기';
				const message = '인증을 실패하였습니다.';
				showDialog(OneButtonAlertDialog, {
					title,
					message,
					handleDialogResult(result) {
						if (result === 'ok') {
							setUpdateStatus(!updateStatus);
						}
					},
				});
			});
	};
	const [inputRmstnfamCoValue, setInputRmstnfamCoValue] = useState(''); // 세대원 수
	const [inputAreaValue, setInputAreaValue] = useState('');
	const [inputPwrerUseMwhValue, setInputPwrerUseMwhValue] = useState('');
	const [inputGasUseMjValue, setInputGasUseMjValue] = useState('');

	// 인풋 입력 시 숫자만 입력되게 하는 함수
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자 이외의 문자를 모두 제거
		const sanitizedValue = value.replace(/[^0-9]/g, '');
		setInputRmstnfamCoValue(sanitizedValue);
	};
	const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자 이외의 문자를 모두 제거
		const sanitizedValue2 = value.replace(/[^0-9]/g, '');
		setInputAreaValue(sanitizedValue2);
	};
	const handleInputChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자와 . 이외의 문자를 모두 제거
		const sanitizedValue3 = value.replace(/[^0-9.]/g, '');
		setInputPwrerUseMwhValue(sanitizedValue3);
	};
	const handleInputChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자와 . 이외의 문자를 모두 제거
		const sanitizedValue4 = value.replace(/[^0-9.]/g, '');
		setInputGasUseMjValue(sanitizedValue4);
	};
	return (
		<div className="missionDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>고지서 인증하기</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="confirm_box mt25">
							<div className="comm_write_wrap">
								<div className="cont-wrap">
									<h3 className="important">고지연월</h3>
									<div className="cont-box">
										<div className="select-box">
											<select {...register('billYyyy')}>
												<option value={'0'}>선택 (년)</option>
												<option value={'2023'}>2023년</option>
												<option value={'2022'}>2022년</option>
												<option value={'2021'}>2021년</option>
												<option value={'2020'}>2020년</option>
											</select>
											{errYy && (
												<p className="error" style={{ color: 'red' }}>
													{errYy}
												</p>
											)}
											<select {...register('billMm')}>
												<option value={'0'}>선택 (월)</option>
												<option value={'1'}>1월</option>
												<option value={'2'}>2월</option>
												<option value={'3'}>3월</option>
												<option value={'4'}>4월</option>
												<option value={'5'}>5월</option>
												<option value={'6'}>6월</option>
												<option value={'7'}>7월</option>
												<option value={'8'}>8월</option>
												<option value={'9'}>9월</option>
												<option value={'10'}>10월</option>
												<option value={'11'}>11월</option>
												<option value={'12'}>12월</option>
											</select>
											{errMm && (
												<p className="error" style={{ color: 'red' }}>
													{errMm}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="cont-wrap">
									<h3 className="important">구축기준</h3>
									<div className="cont-box">
										<div className="select-box">
											<select {...register('buildStdr')}>
												<option value={''}>선택</option>
												<option value={'신축'}>신축</option>
												<option value={'기축'}>기축</option>
											</select>
										</div>
										{errBuildStdr && (
											<p className="error" style={{ color: 'red' }}>
												{errBuildStdr}
											</p>
										)}
										<span className="explain mt10">
											※ 신축 : 준공시기 5년 이내
											<br />
											&nbsp;&nbsp;&nbsp;&nbsp;기축 : 준공시기 6년 이상
										</span>
									</div>
								</div>
								<div className="cont-wrap">
									<h3 className="important">세대원수</h3>
									<div className="cont-box">
										<input
											type="text"
											className="long"
											placeholder="숫자만 입력하세요"
											value={inputRmstnfamCoValue}
											{...register('rmstnfamCo')}
											onChange={handleInputChange}
										/>
									</div>
									{errRmstnfamCo && (
										<p className="error" style={{ color: 'red' }}>
											{errRmstnfamCo}
										</p>
									)}
								</div>
								<div className="cont-wrap">
									<h3 className="important">면적(평수)</h3>
									<div className="cont-box">
										<input
											type="text"
											className="long"
											placeholder="숫자만 입력하세요"
											value={inputAreaValue}
											{...register('area')}
											onChange={handleInputChange2}
										/>
									</div>
									{errArea && (
										<p className="error" style={{ color: 'red' }}>
											{errArea}
										</p>
									)}
								</div>
								<div className="cont-wrap">
									<h3 className="important">주거형태</h3>
									<div className="cont-box">
										<div className="select-box">
											<select {...register('resideTp')}>
												<option value={''}>선택</option>
												<option value={'APT'}>공동주택</option>
												<option value={'IND'}>개별난방 아파트</option>
												<option value={'ARA'}>지역난방 아파트</option>
												<option value={'DTC'}>단독주택</option>
											</select>
										</div>
										{errResideTp && (
											<p className="error" style={{ color: 'red' }}>
												{errResideTp}
											</p>
										)}
									</div>
								</div>
								<div className="cont-wrap">
									<h3 className="important">전력 사용량 (kWh)</h3>
									<div className="cont-box">
										<input
											type="text"
											className="long"
											placeholder="소수점도 입력가능합니다"
											value={inputPwrerUseMwhValue}
											{...register('pwrerUseMwh')}
											onChange={handleInputChange3}
										/>
									</div>
									{errPwrerUseMwh && (
										<p className="error" style={{ color: 'red' }}>
											{errPwrerUseMwh}
										</p>
									)}
								</div>
								<div className="cont-wrap">
									<h3 className="important">가스 사용량 (MJ)</h3>
									<div className="cont-box">
										<input
											type="text"
											className="long"
											placeholder="소수점도 입력가능합니다"
											value={inputGasUseMjValue}
											{...register('gasUseMj')}
											onChange={handleInputChange4}
										/>
									</div>
									{errGasUseMj && (
										<p className="error" style={{ color: 'red' }}>
											{errGasUseMj}
										</p>
									)}
								</div>
							</div>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default BillMissionDialog;
