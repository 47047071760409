import React, { useEffect, useState } from 'react';
import SubLocation from '../../../components/common/SubLocation';
import test01 from '../../../assets/test01.png';
import camImg from '../../../assets/ic_cam.png';
import ActivityLog from '../../../models/ActivityLog';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import { useDialog } from '../../../dialog/DialogProvider';
import oneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
// 소통마당 > 활동일지 > 활동일지 주제 내역등록
const Write02_ActivePage = () => {
	const subjectId = sessionStorage.getItem('subject');
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [buttonOn, setButtonOn] = useState(false);
	const { register, getValues, watch, handleSubmit } = useForm<ActivityLog>();

	const content = watch('content');

	useEffect(() => {
		if (content) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [content]);

	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 5) {
			const title = '';
			const message = '사진은 최대 5개 까지 업로드할수있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 취소 버튼
	const handleCancle = () => {
		navigate(-1);
	};
	// 등록 버튼
	const onSubmit: SubmitHandler<ActivityLog> = async (data) => {
		// console.log('data >> ', data);
		if (!data.content) {
			const message = '내용을 선택해 주세요.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			return;
		}
		await ActivityLogAPI.insertJournal(
			{
				...data,
				subjectId: Number(subjectId),
			},
			uploadFiles
		).then((res) => {
			// console.log('뭐왔냐', res);
			const message = '등록되었습니다.';
			const title = '';
			showDialog(oneButtonAlertDialog, {
				message,
				title,
			});
			navigate('/commu_02/active_03-history');
		});
	};
	return (
		<main>
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation titleText1="소통마당" liList={SubLocationList} />
				<div className="lnb_txt">
					<h3>활동일지 등록하기</h3>
					{/* <CommSelect />
					<p>마을에 포함되어 있는 동 이름 나와야함</p> */}
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">내용</h3>
								<div className="cont-box">
									<textarea
										placeholder="내용을 입력하세요"
										{...register('content')}
										defaultValue={''}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>첨부 이미지<br />(최대 15MB)</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" value="" placeholder="" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(최대 5장)</span>
										</label>
										<input
											type="file"
											id="img"
											// {...register('uploadFiles')}
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul className="no-scroll">
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
								disabled={buttonOn ? false : true}
							>
								등록
							</button>{' '}
							{/* 모두 체크 시 on클래스 붙음*/}
						</div>
					</form>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Write02_ActivePage;
