import React, { useEffect, useState } from 'react';
import '../informationYard/EnergyMapPage.scss';
import SubLocation from '../../components/common/SubLocation';
import Pagination from '../../components/common/Pagination';
import EnergyMapComponent from '../../components/common/EnergyMapComponent';
import { useNavigate } from 'react-router-dom';
import { EnergyMapList, TownNameList } from '../../models/EnergyMap';
import EnergyMapAPI from '../../apis/EnergyMapAPI';
// 정보마당 > 광주 태양광 에너지지도
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const SubLocationList2 = [
	{ href: '/info_02/', text: '광주 에너지현황' },
	{ href: '/info_02/analysis', text: '광주 에너지분석' },
	{ href: '/info_02/map', text: '광주 태양광 에너지지도' },
	{ href: '/info_02/cal', text: '탄소계산기' },
	{ href: '/dictionary_02', text: '에너지 용어사전' },
];

const EnergyMapPage = () => {
	const navigate = useNavigate();
	const [totalCount, setTotalCount] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const [clickList, setClickList] = useState<number>();
	const [townNameList, setTownNameList] = useState([] as TownNameList[]);
	const [townName, setTownName] = useState<string>();
	const [EnergyDataList, setEnergyDataList] = useState<EnergyMapList[]>();
	const [listClickEvent, setListClickEvent] = useState(false);
	const [townClickEvent, setTownClickEvent] = useState(false);
	const [listIndex, setListIndex] = useState<number>(0);
	const [geocoder, setGeocoder] = useState<any>(null);

	// Kakao Maps API 초기화를 확인하고 geocoder를 설정
    useEffect(() => {
        const initializeKakaoMaps = () => {
            if (window.kakao && window.kakao.maps && window.kakao.maps.services) {
                setGeocoder(new window.kakao.maps.services.Geocoder());
            } else {
                setTimeout(initializeKakaoMaps, 100);
            }
        };

        initializeKakaoMaps();
    }, []);

	useEffect(() => {
		const fetchTownData = async () => {
			if (!geocoder) return;

			try {
				const res = await EnergyMapAPI.getTownNameList();
				
				// Promise.all을 사용하여 모든 geocoding 요청을 병렬로 처리
				const processedData = await Promise.all(
					res.map(async (list) => {
					if (list.locInfo) {
						// geocoder.addressSearch를 Promise로 래핑
						const geocodeResult = await new Promise((resolve, reject) => {
							geocoder.addressSearch(list.locInfo, (result: any[], status: any) => {
								if (status === window.kakao.maps.services.Status.OK) {
								resolve(result);
								} else {
								reject(new Error('Geocoding failed'));
								}
							});
						});
						const result = geocodeResult as any[];
						list.lat = Number(result[0].y);
						list.lng = Number(result[0].x);
						list.latLng = [Number(result[0].y), Number(result[0].x)];
					} else {
						// 기존 하드코딩된 좌표 적용
						switch (list.townName) {
						case '동명마을':
							list.lat = 35.145171301135015;
							list.lng = 126.94205866466017;
							list.latLng = [35.151219178762084, 126.92514259956823];
							break;
						case '지원마을':
							list.lat = 35.10594392623252;
							list.lng = 126.94208643929541;
							list.latLng = [35.1295935, 126.9342283];
							break;
						case '발산마을':
							list.lat = 35.15721345702481;
							list.lng = 126.85565217930191;
							list.latLng = [35.1583107, 126.894175];
							break;
						case '서창마을':
							list.lat = 35.13385878874825;
							list.lng = 126.86306673445287;
							list.latLng = [35.1147307, 126.8301966];
							break;
						case '풍암마을':
							list.lat = 35.115374184929564;
							list.lng = 126.84273785037078;
							list.latLng = [35.1234389, 126.883883];
							break;
						case '방림마을':
							list.lat = 35.133318829256496;
							list.lng = 126.90098735402998;
							list.latLng = [35.13202, 126.9220127];
							break;
						case '사직마을':
							list.lat = 35.10561466044455;
							list.lng = 126.8848753438983;
							list.latLng = [35.1439852, 126.909404];
							break;
						case '양림마을':
							list.lat = 35.081620597158675;
							list.lng = 126.83789024039861;
							list.latLng = [35.1386344, 126.9173644];
							break;
						case '문산마을':
							list.lat = 35.22573424519615;
							list.lng = 126.87152446094328;
							list.latLng = [35.1866861, 126.9142139];
							break;
						case '삼각마을':
							list.lat = 35.22866782587595;
							list.lng = 126.93233259509074;
							list.latLng = [35.1993421, 126.8994987];
							break;
						case '일곡마을':
							list.lat = 35.196058730259786;
							list.lng = 126.90776316207517;
							list.latLng = [35.2127039, 126.8937869];
							break;
						case '수완마을':
							list.lat = 35.187434545510364;
							list.lng = 126.73280522957783;
							list.latLng = [35.192224, 126.8301635];
							break;
						case '지산마을':
							list.lat = 35.184629988534795;
							list.lng = 126.77181183600231;
							list.latLng = [35.1860565, 126.7349629];
							break;
						case '첨단마을':
							list.lat = 35.135538071544836;
							list.lng = 126.74280616836822;
							list.latLng = [35.2214236, 126.8171571];
							break;
						}
					}
					return list;
				})
			);
				setTownNameList(processedData);
			} catch (error) {
			}
		};
			if (geocoder) {
				fetchTownData();
			}
    }, [geocoder]);

	useEffect(() => {
        const fetchEnergyData = async () => {
            if (!townName || !geocoder) return;

            try {
                const res = await EnergyMapAPI.getEnergyMapList({
                    page: page,
                    townName: townName
                });

                await Promise.all(
                    res.map(async (list) => {
                        try {
                            const geocodeResult = await new Promise((resolve, reject) => {
                                geocoder.addressSearch(list.locInfo, (result: any[], status: any) => {
                                    if (status === window.kakao.maps.services.Status.OK) {
                                        resolve(result);
                                    } else {
                                        // reject(new Error('Geocoding failed'));
                                    }
                                });
                            });
                            const result = geocodeResult as any[];
                            list.lat = Number(result[0].y);
                            list.lng = Number(result[0].x);
                        } catch (error) {
                            // console.error(error);
                        }
                    })
                );

                const townList = await EnergyMapAPI.getTownNameList();
                const count = townList.find((find) => find.townName === townName)?.centercnt;
                setTotalCount(Number(count || 0));
                setEnergyDataList(res);
            } catch (error) {
                console.error('Failed to fetch energy data:', error);
                setEnergyDataList([]);
            }
        };

        if (geocoder) {
            fetchEnergyData();
        }
    }, [townName, page, geocoder]);

	const handleListClick = (id: number) => {
		// console.log("상위", id)
		setListClickEvent((cur) => !cur);
		setListIndex(id);
		setClickList(id)
	}

	const handleOverlayClick = (townName: string) => {
		// console.log("오버레이 클릭 마을이름 >>> ", townName);
		setTownName(townName);
	}
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	const handleTest = () => {
		navigate('/info_02/map/test');
	};
	return (
		<main className="EnergyMapPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="정보마당"
					liList={SubLocationList}
					titleText2="광주 태양광 에너지지도"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>광주 태양광 에너지지도</h3>
					<select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select>
					<p>
						태양전지판을 설치해 햇빛을 전기로 바꾸는 햇빛발전소를 세울 수 있는
						부지를 알아보는 지도입니다.
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="gwangju_genergyMap mt40">
						<div className="gwangju_genergyMap_wrap">
							<div className="left_inner">
								<div className="left_box">
									<div className="title">
										<h2>햇빛발전소 가능 부지 지도</h2>
										<span className="mt10">
											출처 : 빛고을시민햇빛발전사회적협동조합, 광주테크노파크
											에너지전환 공동사업
										</span>
									</div>
									<form>
										<div className="category">
											<select className="cate_01" value={townName} onChange={(e) => handleOverlayClick(e.target.value)}>
												<option value='전체'>카테고리 선택</option>
												{townNameList.map((list, index) => (
													<option key={index} value={list.townName}>{list.townName}</option>
												))}
											</select>
										</div>
									</form>
									<div className="web_list">
										<div className="list">
											<ul>
												{townName ?
													EnergyDataList && EnergyDataList.map((list, index) => (
														<li
															key={index}
															onClick={() => {
																handleListClick(list.demUid)
																setClickList(list.demUid)
															}}>
															<a className={`${list.demUid == clickList ? 'on' : ''}`}>
																{/*선택되면 on 클래스 붙음*/}
																<div className='list_table'>
																	<p>{list.townName}</p>
																	<h3>{list.locInfo}</h3>
																	<h4>{list.volume}kW</h4>
																</div>
															</a>
														</li>
													))
													:
													townNameList.map((list, index) => (
														<li
															key={index}
															onClick={() => {
																handleOverlayClick(list.townName);
																// setClickList(index)
																setTownClickEvent(true);
															}}>
															<a className={`${index == clickList ? 'on' : ''}`}>
																{/*선택되면 on 클래스 붙음*/}
																<div className='list_table'>
																	<p>{list.townName}</p>
																	{/* <h3>광주 동구 무등로449번길 12</h3> */}
																	<h4>{list.volume}kW</h4>
																</div>
															</a>
														</li>
													))
												}
												{/*선택되면 on 클래스 붙음*/}
												{/* <li>
													<a>
														<div className="list_table">
															<p>지원마을에너지전환연대</p>
															<h3>광주 동구 무등로449번길 12</h3>
															<h4>70kW</h4>
														</div>
													</a>
												</li> */}
											</ul>
											<Pagination
												page={page}
												// totalPageCount={10}
												totalPageCount={Math.ceil(totalCount / 10)}
												onPageChange={handlePageChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="map_inner">
								{/* 웹 인포윈도우 시작 */}
								<EnergyMapComponent
									listId={listIndex}
									listClickEvent={listClickEvent}
									townClickEvent={townClickEvent}
									townNameList={townNameList}
									page={page}
									EnergyDataList={EnergyDataList}
									townName={townName}
									handleOverlayClick={handleOverlayClick}
									handleListClick={handleListClick}
								/>

								{/* 웹 인포윈도우 종료 */}
							</div>
						</div>
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default EnergyMapPage;
