import React, { useEffect, useState } from 'react';
import { DialogProps, useDialog } from '../DialogProvider';
import MappingList, { CmMappingList } from '../../models/MappingList';
import PostCodeDialog from './PostCodeDialog';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InsertCmMapping } from '../../models/MapInfo';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import camImg from '../../assets/ic_cam.png';
import SubmitSuccessDialog from '../common/SubmitSuccessDialog';
import SubmitFailDialog from '../common/SubmitFailDialog';
import ModifySuccessDialog from '../common/ModifySuccessDialog';
import ModifyFailDialog from '../common/ModifyFailDialog';
import LocationDialog from './LocationDialog';
import OneButtonAlertDialog from '../OneButtonAlertDialog';

interface Modify_ActiveMapDialog extends DialogProps {
	cmUid: number;
	cmmUid?: number;
	cmcUid?: number;
	caTitle: string;
}
const Modify_ActiveMapDialog = ({
	cmUid,
	cmcUid,
	caTitle,
	...props
}: Modify_ActiveMapDialog) => {
	const cmmUid = sessionStorage.getItem('cmmUid');
	const crtUid = sessionStorage.getItem('crtUid');
	// const cmUid = sessionStorage.getItem('cmUid');
	const [cmMappingDetail, setCmMappingDetail] = useState<CmMappingList>();
	const [fileList, setFileList] = useState([] as File[]);
	const [selectLat, setSelectLat] = useState<number>();
	const [selectLng, setSelectLng] = useState<number>();
	const [defaultCmcUid, setDefaultCmcUid] = useState<number>();
	const [modifyCmcUid, setModifyCmcUid] = useState<number>();
	const handleClose = () => {
		props.onClose?.();
	};
	const { showDialog } = useDialog();
	const { register, handleSubmit, getValues, setValue } =
		useForm<InsertCmMapping>();
	// 상세조회
	useEffect(() => {
		ActiveMapAPI.getMappingDetail({
			crtUid: Number(crtUid),
			cmmUid: Number(cmmUid),
			cmUid: Number(cmUid),
		})
			.then((data) => {
				setCmMappingDetail(data);
				// console.log("cmcUid >>>>>>>>>> ", cmcUid);

				if (data.categoryList.find((find) => find.title === caTitle)) {
					// // // console.log(
					// 	"asfasfs",
					// 	data.categoryList.find((find) => find.title === caTitle)
					// );
					setDefaultCmcUid(
						data.categoryList.find((find) => find.title === caTitle)?.cmcUid
					);
				}
				setValue('locInfo', data.locInfo);
				setAddress(data.locInfo);
				setValue('cmTitle', data.cmMapperVO.cmText);
				setModifyCmcUid(cmcUid);
				setSelectLat(Number(data?.cntrLat));
				setSelectLng(Number(data?.cntrLng));
				if (data?.cmFileList) {
					const files: File[] = [];
					const promises: Promise<any>[] = [];

					for (let i = 0; i < data.cmFileList.length; i++) {
						const promise = ActiveMapAPI.getMappingImg({
							cmfUid: data.cmFileList[i].cmfUid,
						}).then((res) => {
							if (res) {
								//let file = res.data as File
								//// // console.log(typeof res);
								// // console.log(res.data);
								//const blob = new Blob([res], { type: 'application/octet-stream' });
								const file = new File([res.data], data.cmFileList[i].fileName, {
									type: 'image/png',
								});
								// // // console.log("dasdas", res);
								// // console.log(file);
								files.push(file);
								// // 미리보기 이미지 추가
								const previewImage = `/api/community/getMapFiles?cmfUid=${data.cmFileList[i].cmfUid}`;
								setPreviewImages((prevImages) => [...prevImages, previewImage]);
							}
						});

						promises.push(promise);
					}

					Promise.all(promises).then(() => {
						setFileList(files);
						setUploadFiles(files);
						// // console.log("files ::::::::::", files);
					});
				}
			})
			.catch((e) => { });
	}, []);

	const geocoder = new window.kakao.maps.services.Geocoder();
	// 주소검색
	const [address, setAddress] = useState('');
	// 경도 x
	const [lat, setLat] = useState<number>();
	// 위도 y
	const [lng, setLng] = useState<number>();

	const handleSearch = () => {
		showDialog(LocationDialog, {
			// handleAddressSubmit(address) {
			// 	setAddress(address);
			// },
			handleAddressSubmit(lat: number, lng: number) {
				setSelectLat(lat);
				setSelectLng(lng);
				// // console.log("주소입력 다이얼로그 lat", lat);
				// // console.log("주소입력 다이얼로그 lng", lng);
				// 좌표를 주소로 변환
				geocoder.coord2Address(lat, lng, (result: any, status: any) => {
					if (status === window.kakao.maps.services.Status.OK) {
						if (result[0]) {
							const address = result[0].address.address_name;
							// // console.log("주소:", address);
							setAddress(address);
						} else {
							// // console.log("주소를 찾을 수 없습니다.");
						}
					} else {
						// // console.log("Geocoder 실패:", status);
					}
				});
			},
		});
	};

	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log("files ::::::: ", event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 5) {
			alert('사진은 최대 5개 까지 업로드할수있다.');
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log("imagesToSubmit ::::::::::::::::: ", uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	const handleSelectedCmcUid = (value: number) => {
		// console.log("value >>>> ", value)
		setModifyCmcUid(value);
	}
	const onSubmit: SubmitHandler<InsertCmMapping> = async (data) => {
		try {
			if (!data.cmTitle) {
				const title = '지도 참여하기';
				const message = '내용은 필수값입니다.';
				showDialog(OneButtonAlertDialog, { title, message });
				return;
			}
			await ActiveMapAPI.updateMapping(
				{
					...data,
					locInfo: address,
					cmmUid: Number(cmmUid),
					updUid: Number(crtUid),
					cmUid: cmMappingDetail?.cmUid,
					cntrLat: selectLat || 0,
					cntrLng: selectLng || 0,
					openTp: 'A',
					cmcUid: modifyCmcUid,
					cmTitle: data.cmTitle,
				},
				uploadFiles
			)
				.then((res) => {
					// console.log('updateMapping ::::::', res);
					const tab = 'activeMapping';
					showDialog(ModifySuccessDialog, { tab });
				})
				.catch((e) => {
					showDialog(ModifyFailDialog);
				});
		} catch { }
	};

	return (
		<div className="Write_ActiveMapDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>지도 참여하기 - 수정</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_popup_content comm_write_wrap mt25">
							<div className="cont-wrap">
								<h3 className="important">주소</h3>
								<div className="cont-box">
									<input
										type="text"
										className="address"
										placeholder="주소를 입력하세요"
										value={address}
									/>
									<button
										type="button"
										className="btn_address"
										onClick={
											() => handleSearch()
											// Number(cmMappingDetail?.cntrLat),
											// Number(cmMappingDetail?.cntrLng)
										}
									>
										위치 찾기
									</button>
									<input
										type="text"
										className="detail_address"
										placeholder="상세주소를 입력하세요"
										{...register('subLocInfo')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">분류</h3>
								<div className="cont-box">
									<div className="select-box">
										<select value={modifyCmcUid} onChange={(e) => handleSelectedCmcUid(Number(e.target.value))}>
											{cmMappingDetail?.categoryList.map((list, index) => (
												<option
													key={index}
													selected={caTitle === list.title ? true : false}
													value={list.cmcUid}
												>
													{list.title}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">내용</h3>
								<div className="cont-box">
									<textarea
										placeholder="내용을 입력하세요"
										{...register('cmTitle')}
									></textarea>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>사진첨부</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(최대 5장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Modify_ActiveMapDialog;
