import MissionInfo from '../../models/MissionInfo';
import { DialogProps, useDialog } from '../DialogProvider';
import './missionDialog.scss';
import ic_cam from '../../assets/ic_cam.png';
import { useEffect, useState } from 'react';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import { Content } from '../../models/MissionInfoDetail';
import { SubmitHandler, useForm } from 'react-hook-form';
import MissionAPI from '../../apis/MissionAPI';
import useMember from '../../hooks/useMember';

// 이미지 인증 팝업
interface ImageMissionDialog extends DialogProps {
	//	mmiUid: number;
	missionDetail: MissionInfo | undefined;
}

const ImageMissionDialog = ({
	missionDetail,
	...props
}: ImageMissionDialog) => {
	const { member } = useMember();
	const { showDialog } = useDialog();
	const [updateStatus, setUpdateStatus] = useState(false);
	const handleClose = () => {
		props.onClose?.();
	};
	useEffect(() => {
		// console.log('missionDetail ::::', missionDetail);
	}, []);
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<Content>();
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [photo, setPhoto] = useState<boolean>(false);

	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 2) {
			// alert('사진은 최대 5개 까지 업로드할수있습니다.');
			const title = '사진 첨부';
			const message = '사진은 2장만 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			setPhoto(true);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 2);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 2);
			setPhoto(true);
			return newFiles;
		});
	};
	const onSubmit: SubmitHandler<Content> = async (data) => {
		// console.log('uploadFiles ::::::', uploadFiles);
		// console.log('data::::::::::::', data);

		if (photo) {
			MissionAPI.insertCert2(
				{
					mmiUid: missionDetail?.mmiUid,
					tcUid: member?.townDTO?.townId,
					accmlPt: Number(missionDetail?.pymntPt),
					certProgrsSt: '신청',
				},
				uploadFiles
			)
				.then((res) => {
					const title = '이미지 인증하기';
					const message = `인증을 완료해 ${missionDetail?.pymntPt
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}포인트가 적립되었습니다`;

					showDialog(OneButtonAlertDialog, {
						title,
						message,
						handleDialogResult(result) {
							// console.log('result::::', result);

							if (result === 'ok') {
								// console.log('rerer');
								window.location.reload();
								setUpdateStatus(!updateStatus);
							}
						},
					});
				})
				.catch((e) => {
					const title = '이미지 인증하기 실패';
					const message = '이미지는 1장 당 15MB까지 등록 가능합니다';
					showDialog(OneButtonAlertDialog, {
						title,
						message,
						handleDialogResult(result) {
							if (result === 'ok') {
								setUpdateStatus(!updateStatus);
							}
						},
					});
				});
		} else {
			const title = '이미지 인증하기';
			const message = '이미지를 첨부해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
				handleDialogResult(result) {
					if (result === 'ok') {
						setUpdateStatus(!updateStatus);
					}
				},
			});
			return;
		}
	};
	return (
		<div className="missionDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>이미지 인증하기</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="confirm_box mt25">
							<div className="imgBox">
								<input className="upload-photo" />
								<label htmlFor="img">
									<img src={ic_cam} alt="사진 첨부" />
									사진 첨부
									<span>(최대 2장)</span>
								</label>
								<input
									type="file"
									id="img"
									accept="image/*"
									onChange={handleImageChange}
								/>
							</div>
							<div className="imglistBox">
								<ul className="no-scroll">
									{previewImages.map((imageUrl, index) => (
										<li key={index}>
											<img src={imageUrl} key={imageUrl} />
											<button
												type="button"
												onClick={() => handleRemovePreview(index)}
												className="btn-file-delet"
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ImageMissionDialog;
