import axios from 'axios';
import { param } from 'jquery';
import { serialize } from "object-to-formdata";
import MapInfo from '../models/MapInfo';
import MemberInfo, { UserList, UserOne } from '../models/MemberInfo';
import { content } from '../models/TownList';

interface GetAllParams {
    townId? : number;
    character? : number;
    nickname? : string;
    username? : string;
    telno? : string;
    gu?: string;
	dong?: string;
    townName?: string;
    authRole?: string;
    page?: number;
    size?: number;
    sort?: string
    id?: number
}

interface JoinInput {
	townId?: number;
	character?: number;
	nickname?: string;
	username?: string;
	telno?: string;
    address?: string;
	detailAddress?: string;
    gu?: string;
	dong?: string;
    delDt?: string
}
const getUser = () => {
    return axios
    .get("/user/me")
    .then((res) => res.data as MemberInfo);
}
const logout = () => {
    return axios
    .get('/user/logout2')
    .then((res) => res.status as number);
}
const updateMember = (params :JoinInput) => {
    return axios
    .post("/user/update2", serialize(params))
    .then((res) => res as any);
}

const nicknameDuplicate = (nickname :string) => {
    return axios
    .get(`/user/duplicate?nickname=${nickname}`)
    .then((res) => res as any);
}
const telnoDuplicate = (telno :string) => {
    return axios
    .get(`/user/duplicate?telno=${telno}`)
    .then((res) => res as any);
}

// 관리자 > 회원목록
const getUserList = (params: GetAllParams) => {
    return axios
    .get(`/api/admin/userlist`, {params})
    .then((res) => res.data as UserList);
} 

// 관리자 > 회원관리 > 회원정보 팝업
const getUserOne = (params: GetAllParams) => {
    return axios
    .get(`/api/admin/userget`, {params})
    .then((res) => res.data as UserOne)
}

const MemberAPI = {
	getUser,
    logout,
    updateMember,
    nicknameDuplicate,
    telnoDuplicate,
    getUserList,
    getUserOne,
};

export default MemberAPI;
