import React, { useEffect, useRef } from 'react';
import { ProgramContent } from '../../../models/EduProgram';

// 프로그램 교육장소 맵
const Map_ProgramDetailPage = ({
    programDetail
}: {
    programDetail: ProgramContent | undefined
}) => {

    const resizeTargetRef = useRef(null);
    useEffect(() => {
        // console.log("programDetail----", programDetail);
        var geocoder = new window.kakao.maps.services.Geocoder();
        // 주소로 좌표를 검색합니다
        geocoder.addressSearch(
            `${programDetail?.address}`,
            (result: any, status: any) => {
                // 정상적으로 검색이 완료됐으면
                if (status === window.kakao.maps.services.Status.OK) {
                    var coords = new window.kakao.maps.LatLng(
                        result[0].y,
                        result[0].x
                    );
                    // console.log("위치", coords)
                    var markerPosition = new window.kakao.maps.LatLng(coords.Ma, coords.La);
                    var marker = {
                        position: markerPosition
                    };
                    var staticMapContainer = document.getElementById('staticMap'), // 이미지 지도를 표시할 div  
                        staticMapOption = {
                            center: new window.kakao.maps.LatLng(coords.Ma, coords.La), // 이미지 지도의 중심좌표
                            level: 3, // 이미지 지도의 확대 레벨
                            marker: marker // 이미지 지도에 표시할 마커 
                        };

                    // 이미지 지도를 생성합니다
                    var staticMap = new window.kakao.maps.StaticMap(staticMapContainer, staticMapOption);
                    const resizeObserver = new ResizeObserver(entries => {
                        for (const entry of entries) {
                            // const { target, contentRect } = entry;
                            // // console.log(`Element ${target.id} dimensions: ${contentRect.width}x${contentRect.height}`);
                            // staticMap.relayout();
                            staticMap.setCenter(new window.kakao.maps.LatLng(coords.Ma, coords.La))
                        }
                    });
                    if (resizeTargetRef.current) {
                        resizeObserver.observe(resizeTargetRef.current);
                    }

                }
            }
        );
    }, [programDetail])

    return (
        <>
            <div className="place_map" id="staticMap" ref={resizeTargetRef}>
            </div>
            <p className="mt10">
                {programDetail?.address}
            </p>
        </>
    );
};

export default Map_ProgramDetailPage;