import { SubmitHandler, useForm } from 'react-hook-form';
import MissionInfo from '../../models/MissionInfo';
import { Content } from '../../models/MissionInfoDetail';
import { DialogProps, useDialog } from '../DialogProvider';
import './missionDialog.scss';
import axios from 'axios';
import MissionAPI from '../../apis/MissionAPI';
import useMember from '../../hooks/useMember';
import { useEffect, useState } from 'react';
import OneButtonAlertDialog from '../OneButtonAlertDialog';

// 글 인증 팝업
interface TextMissionDialog extends DialogProps {
	// mmiUid: number;
	missionDetail: MissionInfo | undefined;
}

const TextMissionDialog = ({ missionDetail, ...props }: TextMissionDialog) => {
	const { showDialog } = useDialog();
	const { member } = useMember();
	const { register, getValues, watch, handleSubmit, setValue, setFocus } =
		useForm<Content>();
	const [updateStatus, setUpdateStatus] = useState(false);
	const handleClose = () => {
		// console.log;
		props.onClose?.();
	};
	useEffect(() => {
		// console.log('misisonDetail:::::::::::', missionDetail);
	}, []);
	const onSubmit: SubmitHandler<Content> = async (data) => {
		// console.log('data.certCn :::::', data.certCn);
		if (data?.certCn) {
			MissionAPI.insertCert({
				mmiUid: missionDetail?.mmiUid,
				tcUid: member?.townDTO?.townId,
				certCn: data.certCn,
				accmlPt: Number(missionDetail?.pymntPt),
				certProgrsSt: '신청',
			})
				.then((res) => {
					const title = '글 인증하기';
					const message = `인증을 완료해 ${missionDetail?.pymntPt
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}포인트가 적립되었습니다`;

					showDialog(OneButtonAlertDialog, {
						title,
						message,
						handleDialogResult(result) {
							// console.log('result::::', result);

							if (result === 'ok') {
								// console.log('rerer');
								window.location.reload();
								setUpdateStatus(!updateStatus);
							}
						},
					});
				})
				.catch((e) => {
					const title = '글 인증하기';
					const message = '인증을 실패하였습니다.';
					showDialog(OneButtonAlertDialog, {
						title,
						message,
						handleDialogResult(result) {
							if (result === 'ok') {
								setUpdateStatus(!updateStatus);
							}
						},
					});
				});
		} else {
			const title = '글 인증하기';
			const message = '내용을 입력해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
				handleDialogResult(result) {
					if (result === 'ok') {
						setUpdateStatus(!updateStatus);
					}
				},
			});
		}
	};

	return (
		<div className="missionDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>글 인증하기</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="confirm_box mt25">
							<textarea
								placeholder="내용을 입력해주세요"
								defaultValue={''}
								{...register('certCn')}
							/>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default TextMissionDialog;
