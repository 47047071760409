import React, { useEffect, useState } from 'react';
import { DialogProps } from '../DialogProvider';
import './AdminPage_MemberDialog.scss'
import ic_electricity from '../../assets/ic_electricity.png'
import ic_gas from '../../assets/ic_gas.png'
import ic_Savings from '../../assets/ic_Savings.png'
import ic_point from '../../assets/ic_point.png'
import Pagination from '../../components/common/Pagination';
import MemberAPI from '../../apis/MemberAPI';
import { UserOne } from '../../models/MemberInfo';

// 관리자 > 회원관리 >  닉네임 팝업
interface AdminPage_MemberDialog extends DialogProps {
    id: number
}
const AdminPage_MemberDialog = ({ id, ...props }: AdminPage_MemberDialog) => {
    const [totalPage, setTotalPage] = useState(5);
    const [page, setPage] = useState<number>(1);
    const [userOne, setUserOne] = useState<UserOne | null>(null);

    const handlePageChange = (page: number) => {
        setPage(page);
    };
    const handleClose = () => {
        props.onClose?.();
    }

    useEffect(() => {
        MemberAPI.getUserOne({
            id: Number(id)
        }
        ).then((res) => {
            setUserOne(res)
        })
    }, [])

    // // console.log("id", id)
    // console.log("userOne", userOne)

    return (
        <div className='AdminPage_MemberDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title">
                        <h3>[{userOne?.viewMember.nickname}]님의 회원정보</h3>
                        <a className="popup_close" onClick={handleClose}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC" alt="닫기" />
                        </a>
                    </div>
                    <div className="comm_popup_content mt25">
                        {/*회원정보란 시작*/}
                        <div className="information_box">
                            <ul>
                                {userOne?.viewMember.userName &&
                                    <li className="name">
                                        <span>실명</span>
                                        <p>
                                            {userOne?.viewMember.userName}
                                            {/* <span>(실명인증미완료)</span> */}
                                        </p>
                                    </li>
                                }
                                {userOne?.viewMember.telno &&
                                    <li className="contect">
                                        <span>연락처</span>
                                        <p>{userOne?.viewMember.telno.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</p>
                                    </li>
                                }
                                {userOne?.viewMember.addr &&
                                    <li className="adress">
                                        <span>주소</span>
                                        <p>{userOne?.viewMember.addr}{userOne?.viewMember.addrDtl}</p>
                                    </li>
                                }
                                {userOne?.viewMember.gu &&
                                    <li className="residence">
                                        <span>거주지</span>
                                        <p>{userOne?.viewMember.gu}&gt;{userOne?.viewMember.dong}</p>
                                    </li>
                                }
                            </ul>
                        </div>
                        {/*회원정보란 종료*/}
                        {/*에너지전환 활동시작*/}
                        <div className="myEnergyAct mt25">
                            <div className="energy_activity pa30">
                                <div className="energy_1">
                                    <div className="img_box">
                                        <img src={ic_electricity} alt="전기이미지" />
                                        <p>전기</p>
                                    </div>
                                    <div className="title_1">
                                        <h2>{userOne?.energyCurrentSituationView.pwrerMwh} <span>MWh</span></h2>
                                        <h2>{userOne?.energyCurrentSituationView.pwrerToe} <span>10<sup>-3</sup> TOE</span></h2>
                                    </div>
                                </div>
                                <div className="energy_1">
                                    <div className="img_box">
                                        <img src={ic_gas} alt="도시가스이미지" />
                                        <p>도시가스</p>
                                    </div>
                                    <div className="title_1">
                                        <h2>{userOne?.energyCurrentSituationView.gasMj} <span>MJ</span></h2>
                                        <h2>{userOne?.energyCurrentSituationView.gasToe} <span>10<sup>-3</sup> TOE</span></h2>
                                    </div>
                                </div>
                                <div className="energy_1">
                                    <div className="img_box">
                                        <img src={ic_Savings} alt="총에너지절감량이미지" />
                                        <p>총 에너지 절감량</p>
                                    </div>
                                    <div className="title_1">
                                        <h2>{userOne?.energyCurrentSituationView.redcnToe} <span>10<sup>-3</sup> TOE</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*에너지전환 활동종료*/}
                        {/*포인트내역1시작*/}
                        <div className="point_box pa30 mt25">
                            <div className="point_title">
                                <h2>누적 포인트</h2>
                                <p>{userOne?.point.accmltPt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                <img src={ic_point} alt="포인트이미지" />
                            </div>
                            <div className="point_title mt10">
                                <h2>비가용 포인트</h2>
                                <p>{userOne?.point.disAblePt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                <img src={ic_point} alt="포인트이미지" />
                            </div>
                            <div className="point_title1 mt10">
                                <h2>현재 가용 포인트</h2>
                                <p>{userOne?.point.ablePt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                                <img src={ic_point} alt="포인트이미지" />
                            </div>
                        </div>
                        {/*포인트내역1 끝*/}
                        {/*테이블 시작*/}
                        <div className="point_table mt25">
                            <ul className="thead pa20">
                                <li>일시</li>
                                <li>분류</li>
                                <li>내용<br />(미션명)</li>
                                <li>전력<br />(MWh)</li>
                                <li>도시가스<br />(MJ)</li>
                                <li>총 에너지<br />(TOE)</li>
                                <li>포인트</li>
                                <li>소멸일시</li>
                            </ul>
                            <ul className="tbody">
                                <li className="pa20">
                                    <a >
                                        <p>2023.10.01</p>
                                        <p>기본미션</p>
                                        <p>
                                            거점마을 상점 5,000원 상품권 교환
                                        </p>
                                        <p>0.30</p>
                                        <p>nnn</p>
                                        <p>nnn</p>
                                        <p className="blue">+5,000</p>
                                        <p>2023.10.01</p>
                                    </a>
                                </li>
                                <li className="pa20">
                                    <a >
                                        <p>2023.10.01</p>
                                        <p>기본미션</p>
                                        <p>
                                            거점마을 상점 5,000원 상품권 교환
                                        </p>
                                        <p>0.30</p>
                                        <p>nnn</p>
                                        <p>nnn</p>
                                        <p className="red">-5,000</p>
                                        <p>2023.10.01</p>
                                    </a>
                                </li>
                                <li className="pa20">
                                    <a >
                                        <p>2023.10.01</p>
                                        <p>기본미션</p>
                                        <p>
                                            거점마을 상점 5,000원 상품권 교환
                                        </p>
                                        <p>0.30</p>
                                        <p>nnn</p>
                                        <p>nnn</p>
                                        <p className="blue">+5,000</p>
                                        <p>2023.10.01</p>
                                    </a>
                                </li>
                                <li className="pa20">
                                    <a >
                                        <p>2023.10.01</p>
                                        <p>기본미션</p>
                                        <p>
                                            거점마을 상점 5,000원 상품권 교환
                                        </p>
                                        <p>0.30</p>
                                        <p>nnn</p>
                                        <p>nnn</p>
                                        <p className="red">-5,000</p>
                                        <p>2023.10.01</p>
                                    </a>
                                </li>
                                <li className="pa20">
                                    <a >
                                        <p>2023.10.01</p>
                                        <p>기본미션</p>
                                        <p>
                                            거점마을 상점 5,000원 상품권 교환
                                        </p>
                                        <p>0.30</p>
                                        <p>nnn</p>
                                        <p>nnn</p>
                                        <p className="red">-5,000</p>
                                        <p>2023.10.01</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/*테이블 종료*/}
                        <Pagination
                            page={Number(page)}
                            totalPageCount={Number(totalPage)}
                            // totalPageCount={Math.ceil(count / 8)}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage_MemberDialog;