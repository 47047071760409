import axios from 'axios';
import IntroInfo, { CaseInfo, CaseInputForm } from '../models/IntroInfo';

//네트워크 등록
export interface NetworkParams {
	townId: number;
	projectName: string;
	projectPurpose: string;
	projectRegion: string;
	networkPartner: string;
	// networkFilePath?: string;
}
export interface CenterParams {
	townId: number;
	centerName: string;
	location: string;
	subLocation?: string;
	openDt: string;
	openingCeremony: string;
	// centerilePath?: string;
}
export interface CaseParams {
	name: string;
	content: string;
	tcUid: number;
}
export interface updCaseParams {
	tccUid: number;
	name: string;
	content: string;
	tcUid: number;
}
export interface GetParams {
	tccUid?: number;
	tcUid?: number;
	page?: number;
	size?: number;
}
// 네트워크 등록
const insertNetwork = (params: NetworkParams, networkFile: File[]) => {
	const formData = new FormData();
	// 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
		formData.append(key, value);
	});
	if (networkFile.length != 0) {
		// 파일 추가
		const files = Array.from(networkFile);
		for (let i = 0; i < files.length; i++) {
			formData.append('networkFile', files[i], files[i].name);
		}
	} else {
		formData.append('networkFilePath', 'null');
	}

	return axios.post('/api/town/update', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
// 센터 등록
const insertCenter = (params: CenterParams, centerFile: File[]) => {
	const formData = new FormData();
	// 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
		formData.append(key, value);
	});
	if (centerFile.length != 0) {
		// 파일 추가
		const files = Array.from(centerFile);
		for (let i = 0; i < files.length; i++) {
			formData.append('centerFile', files[i], files[i].name);
		}
	} else {
		formData.append('centerFilePath', 'null');
	}

	return axios.post('/api/town/update', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
// 네트워크 + 센터 조회
const getIntroInfo = (townId: number) => {
	return axios
		.get(`/api/town/get?townId=${townId}`)
		.then((res) => res.data as IntroInfo);
};
const getIntroInfoList = () => {
	return axios
		.get(`/api/town`)
		.then((res) => res.data.content as IntroInfo[]);
};
// 사례 등록
const insertCase = (params: CaseParams, uploadFiles: File[]) => {
	const formData = new FormData();
	// 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
		formData.append(key, value);
	});
	// 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
	return axios.post('/api/town/communityCase/save', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
// 사례 조회
const getCaseList = (params: GetParams) => {
	return axios
		.get('/api/town/communityCase', { params })
		.then((res) => res.data);
};
// 사례 수정
const updateCase = (
	data: CaseInputForm,
	uploadFiles: File[],
	deleteImgFileIds?: number[]
) => {
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		formData.append(key, value);
		// 파일 추가
	});
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}
	if (deleteImgFileIds?.length !== 0) {
		if (deleteImgFileIds) {
			const imgDel = Array.from(deleteImgFileIds);
			for (let i = 0; i < imgDel.length; i++) {
				if (imgDel[i]) {
					formData.append(
						`communityCaseFileDTOList[${i}].id`,
						String(imgDel[i])
					);
				}
			}
		}
	}

	return axios
		.post('/api/town/communityCase/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			// console.log(res);
			return res.data as { success: boolean; message: string };
		});
};
// 사례 단일 조회
const getCaseOne = (tccUid: number) => {
	return axios
		.get(`/api/town/communityCase/get?tccUid=${tccUid}`)
		.then((res) => res.data as CaseInfo);
};

// 사례 삭제
const deleteCase = (tccUid: number) => {
	return axios
		.post(`/api/town/communityCase/delete?tccUid=${tccUid}`)
		.then((res) => res.data);
};
interface fileParams {
	savedPath: string;
	download?: boolean;
}
// 파일 조회
const getImg = (params: fileParams) => {
	return axios.get(`/api/attach/getFile`, {
		responseType: 'blob',
		params: params,
	});
	// .then((res) => res.data as File[]);
};

const IntroAPI = {
	insertNetwork,
	insertCenter,
	getIntroInfo,
	insertCase,
	updateCase,
	getCaseList,
	getCaseOne,
	deleteCase,
	getImg,
	getIntroInfoList
};
export default IntroAPI;
