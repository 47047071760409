import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MemberAPI from '../../apis/MemberAPI';
import SubLocation from '../../components/common/SubLocation';
import PostCodeDialog from '../../dialog/activeMap/PostCodeDialog';
import { useDialog } from '../../dialog/DialogProvider';
import NickCheckDialog from '../../dialog/Join/NickCheckDialog';
import NickDialog from '../../dialog/Join/NickDialog';
import TelCheckDialog from '../../dialog/Join/TelCheckDialog';
import ValidDialog from '../../dialog/Join/ValidDialog';
import WithdrawalDialog from '../../dialog/myPage/WithdrawalDialog';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import useMember from '../../hooks/useMember';
import { BugguSelect, DongguSelect, GwangsanguSelect, NamguSelect, SeoguSelect } from '../../models/EnergyDataSelect';
import './MyInfoModify.scss';

const SubLocationList = [{ href: '/myPage_02', text: '마이페이지' }];

const SubLocationList2 = [
	{ href: '/mypage_02/myInfoModify_04', text: '내 정보 수정' },
	{
		href: '/mypage_02/mission-myVillageModify_04',
		text: '내 마을 변경',
	},
	{ href: '/mypage_02/myProgram_04', text: '내 교육프로그램' },

	{ href: '/mypage_02/myEPoint', text: '내 E 포인트' },
	{ href: '/mypage_02/myEnergy', text: '내 에너지 활동' },
	{ href: '/mypage_02/myAlarm_04', text: '내 알람' },
	// { href: '/reward_02/', text: '내 E 포인트' },
];

// 마이페이지 > 내 정보 수정
interface JoinInput {
	townId: number;
	character: string;
	nickname: string;
	username: string;
	telno: string;
	address: string;
	detailAddress: string;
	gu: string;
	dong: string;
}

// 제이쿼리
const FloatingBox: React.FC<{ selector: string }> = ({ selector }) => {
	const classActive = 'active';
	const classCorrect = 'correct';

	const handleFocus = (e: Event) => {
		const parentElement = (e.currentTarget as HTMLElement).parentElement;
		parentElement?.classList.add(classActive);
	};

	const handleBlur = (e: Event) => {
		const target = e.currentTarget as HTMLInputElement | HTMLTextAreaElement;
		const parentElement = target.parentElement;

		if (target.value === '') {
			parentElement?.classList.remove(classActive);
			parentElement?.classList.remove(classCorrect);
		} else {
			parentElement?.classList.add(classCorrect);
		}
	};

	useEffect(() => {
		const inputElement = document.querySelector(selector) as
			| HTMLInputElement
			| HTMLTextAreaElement
			| null;

		if (inputElement) {
			inputElement.addEventListener('focus', handleFocus);
			inputElement.addEventListener('blur', handleBlur);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('focus', handleFocus);
				inputElement.removeEventListener('blur', handleBlur);
			}
		};
	}, [selector]);

	return null;
};

export const nickValid = /^[0-9a-zA-Z가-힣]+$/; // 닉네임
export const nameValid = /^[가-힣]+$/; // 실명
export const telValid = /^([0-9]{11})/;

const MyInfoModify = () => {
	return (
		<main className="MyInfoModify">
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<Form />
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation
				titleText1="마이페이지"
				liList={SubLocationList}
				titleText2="내 정보 수정"
				liList2={SubLocationList2}
			/>
			<div className="lnb_txt">
				<h3>내 정보 수정</h3>
				{/* <CommSelect/> */}
				<p>내 정보를 확인하고 수정할 수 있습니다.</p>
			</div>
		</nav>
	);
};

// 수정form
const Form = () => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const { member, getMemInfo } = useMember();
	const { setMember } = useMember();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<JoinInput>({});
	// 닉네임 중복검사 여부
	const [nicknameValid, setNicknameValid] = useState(false);
	// 연락처 중복검사 여부
	const [telnoValid, setTelnoValid] = useState(true);
	// 상세주소 radio
	const [detailAddress, setdetailAddress] = useState<string>('기타');
	// 주소검색
	const [address, setAddress] = useState('');
	// 상세주소 기타
	const [address2, setAddress2] = useState('');
	// 상세주소 아파트
	const [address3, setAddress3] = useState('');
	const [address4, setAddress4] = useState('');
	const [dongGuSelect, setDongGuSelect] = useState(false);
	const gugu = watch('gu');
	const dongDong = watch('dong');
	// 멤버 정보 조회
	useEffect(() => {
		MemberAPI.getUser().then((res) => {
			// console.log('내 정보 수정 > 멤버정보', res);
			setMember(res);
			setValue('nickname', res.nickname);
			setValue('username', res.username);
			if (res.telno) {
				setTelnoValid(true);
				setValue('telno', res.telno);
			}
			setAddress(res.address);
			setValue('address', res.address);
			if (res.detailAddress) {
				// console.log(res.detailAddress[0]);
				if (Number(res.detailAddress[0]) == 2) {
					// console.log('기타 주소', res.detailAddress.slice(2));
					setAddress2(res.detailAddress.slice(2));
				} else if (Number(res.detailAddress[0]) == 1) {
					setdetailAddress('아파트');
					setAddress3(String(res.detailAddress.slice(2).match(/\d+(?=동)/g)));
					setAddress4(String(res.detailAddress.slice(2).match(/\d+(?=호)/g)));
				}
			}

			setValue('detailAddress', res.detailAddress);
			setValue('gu', res.gu);
			setTimeout(() => {
				setValue('dong', res.dong);
			}, 500);
		});
	}, []);

	const nicknameInput = watch('nickname');
	const telnoInput = watch('telno');
	useEffect(() => {
		// console.log('구', gugu);
		// console.log('동', dongDong);
	}, [gugu, dongDong]);
	useEffect(() => {
		// console.log('인풋창에 입력된 닉네임', nicknameInput);
		if (member?.nickname === nicknameInput) {
			setNicknameValid(true);
			return;
		} else {
			setNicknameValid(false);
			return;
		}
	}, [nicknameInput]);

	useEffect(() => {
		// console.log('인풋창에 입력된 연락처', telnoInput);
		if (!getValues('telno')) {
			setTelnoValid(true);
			return;
		} else if (member?.telno === telnoInput) {
			setTelnoValid(true);
			return;
		} else if (!telValid.test(telnoInput)) {
			setTelnoValid(false);
			return;
		}
	}, [telnoInput]);

	useEffect(() => {
		setValue('dong', '');
		// console.log('구 선택 >> ', gugu);
		if (gugu) {
			setDongGuSelect(true);
		}
		if (gugu === '') {
			setDongGuSelect(false);
		} else {
			setDongGuSelect(false);
		}
	}, [gugu]);

	useEffect(() => {
		// console.log('동 선택 >> ', dongDong);
		if (dongDong) {
			setDongGuSelect(true);
		} else if (dongDong === '') {
			setDongGuSelect(false);
		} else {
			setDongGuSelect(false);
		}
	}, [dongDong]);
	useEffect(() => {
		// console.log('닉네임', nicknameValid);
		// console.log('연락처', telnoValid);
		// console.log('동구', dongGuSelect);
	}, [nicknameValid, telnoValid, dongGuSelect]);
	// 닉네임 중복확인
	const nicknameCheck = () => {
		if (getValues('nickname').length === 0) {
			// 닉네임 필수
			setNicknameValid(false);
			// window.alert('닉네임을 입력해주세요.');
			showDialog(NickDialog);
			return;
		} else if (!nickValid.test(getValues('nickname'))) {
			setNicknameValid(false);
			// setInputCheck({stat: false, msg: "올바른 아이디를 입력하세요."})
			// alert('올바른 닉네임 입력하세요.');
			showDialog(ValidDialog);
			return;
		} else if (getValues('nickname').length > 8) {
			setNicknameValid(false);
			const message = '닉네임은 최대 8자리입니다.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		}
		MemberAPI.nicknameDuplicate(getValues('nickname')).then((res) => {
			// console.log('닉네임 중복확인', res);
			if (res.data || nicknameValid) {
				setNicknameValid(true);
				const message = '사용 가능한 닉네임입니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			} else {
				setNicknameValid(false);
				const message = '중복된 닉네임입니다. 다른 닉네임으로 다시 시도하세요.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			}
		});
		// await MemberAPI.nickCheck({ nickname: getValues('nickname') }).then(
		// 	(res) => {
		// 		if (res.success) {
		// 			setNickValid(true);
		// 			alert('사용 가능한 닉네임입니다.');
		// 		} else {
		// 			setNickValid(false);
		// 			alert('중복된 닉네임입니다. 다른 닉네임으로 다시 시도하세요.');
		// showDialog(NickCheckFailDialog);
		// 		}
		// 	}
		// );
	};

	// 연락처 중복체크
	const telNoCheck = () => {
		if (getValues('telno').length === 0) {
			if (!telValid.test(getValues('telno'))) {
				setTelnoValid(true);
				const message = '연락처를 입력해주세요.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			}
		} else if (!getValues('telno').startsWith('01')) {
			setTelnoValid(false);
			const message = '유효한 연락처를 입력해주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (!telValid.test(getValues('telno'))) {
			setTelnoValid(false);
			const message = '유효한 연락처를 입력해주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else {
			// alert('유효한 연락처를 입력해주세요.');
			MemberAPI.telnoDuplicate(getValues('telno')).then((res) => {
				// console.log('연락처 중복확인', res);
				if (res.data || telnoValid) {
					const message = '사용 가능한 연락처입니다.';
					const title = '';
					showDialog(OneButtonAlertDialog, {
						message,
						title,
					});
					setTelnoValid(true);
					return;
				} else {
					const message = '중복된 연락처입니다. 다시 시도해 주세요.';
					const title = '';
					showDialog(OneButtonAlertDialog, {
						message,
						title,
					});
					setTelnoValid(false);
					return;
				}
			});
		}
	};

	// 이전 버튼
	const handleBack = () => {
		navigate('/myPage_02');
	};
	// 등록 버튼
	const onSubmit: SubmitHandler<JoinInput> = async (data) => {
		if (!data.nickname) {
			// alert('닉네임을 입력해주세요.');
			showDialog(NickDialog);
			return;
		}
		// 닉네임 중복체크
		if (!nicknameValid) {
			// window.alert('닉네임 중복체크를 해주세요.');
			showDialog(NickCheckDialog);
			return;
		}
		// 연락처 중복체크
		if (!telnoValid) {
			// alert('연락처 중복체크를 해주세요.');
			showDialog(TelCheckDialog);
			return;
		}
		// 연락처 유효성 검사
		if (data.telno.includes('-')) {
			// alert('올바른 형식이 아닙니다. 다시 입력해주세요.');
			showDialog(ValidDialog);
			return;
		}
		if (!gugu || !dongDong) {
			const title = '회원가입 정보입력';
			const message = '거주지를 선택해주세요.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		// console.log('회원정보', data);
		MemberAPI.updateMember({
			...data,
			// townId: member && member?.townDTO && member?.townDTO.townId,
			character: undefined,
			telno: data.telno ? data.telno : undefined,
			address: address ? address : undefined,
			detailAddress:
				(address2 && detailAddress === '기타' ? '2 ' + address2 : undefined) ||
				(address3 && address4 && detailAddress === '아파트'
					? '1 ' + address3 + '동' + ' ' + address4 + '호'
					: undefined),
			// address && address2 ? detailAddress === '기타' ?
			// 	"2 " + address2 : "1 " + address3 + "동" + " " + address4 + "호"
			// 	: undefined
		})
			.then((res) => {
				// console.log('회원정보 수정완료', res);
				const message = '수정이 완료되었습니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
					handleDialogResult(result) {
						if (result === 'ok') {
							navigate('/myPage_02/1');
							window.location.reload();
						}
					},
				});
			})
			.catch((error) => {
				// console.log('회원정보 수정 실패 ㅠ', error);
			});
	};

	// 상세주소 선택하는값에 따라 input창 바뀜
	const handleDetailAddress = (e: any) => {
		setAddress2('');
		setAddress3('');
		setAddress4('');
		// console.log(e.target.value);
		setdetailAddress(e.target.value);
	};
	// 주소검색
	const handlePostShearch = () => {
		showDialog(PostCodeDialog, {
			onComplete(address) {
				// // console.log("address >>>> ", address);
				setAddress(address.roadAddress);
			},
		});
	};

	const handleInputChange_1 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자 이외의 문자를 모두 제거
		const sanitizedValue = value.replace(/[^0-9]/g, '');

		setAddress3(sanitizedValue);
	};
	const handleInputChange_2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// 숫자 이외의 문자를 모두 제거
		const sanitizedValue = value.replace(/[^0-9]/g, '');

		setAddress4(sanitizedValue);
	};

	// 탈퇴하기 
	const handleWithdrawal = () => {
		showDialog(WithdrawalDialog);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="join_info">
				<ul className="info_txt">
					<li>
						<div className="form-group">
							<div
								className={`floating-box ${member?.nickname ? 'active' : ''}`}
							>
								<input
									id="nick"
									className="form-control textbox"
									type="text"
									{...register('nickname', { onChange: (e: any) => { } })}
								/>
								<label htmlFor="name" className="floating">
									닉네임 <span>(필수)</span>
								</label>
								<button
									type="button"
									className="btn_duplicate"
									onClick={nicknameCheck}
								>
									중복확인
								</button>
							</div>
							{/* <p>{nicknameValid ? 'true' : 'false'}</p> */}
						</div>
						<FloatingBox selector="#nick" />
					</li>
					<li>
						<div className="cont-wrap">
							<h3>
								거주지&nbsp;<span>(필수)</span>
							</h3>
							<div className="cont-box">
								<div className="select-box">
									<select {...register('gu', { required: true })}>
										<option value="">구 선택</option>
										<option value="동구">동구</option>
										<option value="서구">서구</option>
										<option value="남구">남구</option>
										<option value="북구">북구</option>
										<option value="광산구">광산구</option>
									</select>
									<select {...register('dong')}>
										<option value="">동 선택</option>
										{gugu === '동구' &&
											DongguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{gugu === '서구' &&
											SeoguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{gugu === '남구' &&
											NamguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{gugu === '북구' &&
											BugguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
										{gugu === '광산구' &&
											GwangsanguSelect.map((list, index) => (
												<option key={index} value={list.area}>{list.area}</option>
											))
										}
									</select>
								</div>
							</div>
						</div>
					</li>
					<li className="mt40">
						<p className="txt_blue">
							※ 에너지전환 활동을 위해 실명, 연락처, 주소 필수
						</p>
					</li>
					<li>
						<div className="form-group">
							<div
								className={`floating-box ${member?.username ? 'active' : ''}`}
							>
								<input
									id="name"
									className="form-control textbox"
									type="text"
									{...register('username', { onChange: (e: any) => { } })}
								/>
								<label className="floating">실명</label>
							</div>
						</div>
						<FloatingBox selector="#name" />
					</li>
					<li>
						<div className="form-group">
							<div className={`floating-box ${member?.telno ? 'active' : ''}`}>
								<input
									id="tel"
									className="form-control textbox"
									type="tel"
									{...register('telno', {
										onChange: (e: any) => {
											const inputText = e.target.value;
											if (inputText.length > 11) {
												e.target.value = inputText.slice(0, 11); // 11자 이상의 입력은 자르기
											}
										},
									})}
								/>
								<label htmlFor="name" className="floating">
									연락처 ( - 없이 11자리를 입력해주세요.)
								</label>
								<button
									type="button"
									className="btn_duplicate"
									onClick={telNoCheck}
								>
									중복확인
								</button>
							</div>
							{/* <p>{telnoValid ? 'true' : 'false'}</p> */}
						</div>
						<FloatingBox selector="#tel" />
					</li>
					<li>
						<div className="cont-wrap">
							<h3>주소</h3>
							<div className="cont-box">
								<input
									type="text"
									className="address"
									placeholder="주소를 입력하세요"
									value={address}
									{...register('address')}
									disabled
								/>
								<button
									type="button"
									className="btn_address"
									onClick={handlePostShearch}
								>
									주소검색
								</button>
							</div>
						</div>
					</li>
					<li>
						<div className="cont-wrap">
							<h3>상세주소</h3>
							<div className="cont-box">
								<ul className="check-inner">
									<li>
										<label htmlFor="detail1-1">
											<input
												type="radio"
												id="detail1-1"
												name="detail1"
												value="아파트"
												onClick={handleDetailAddress}
												checked={detailAddress === '아파트' ? true : false}
											/>
											아파트
										</label>
									</li>
									<li>
										<label htmlFor="detail1-2">
											<input
												type="radio"
												id="detail1-2"
												name="detail1"
												checked={detailAddress === '기타' ? true : false}
												onClick={handleDetailAddress}
												value="기타"
											/>
											기타
										</label>
									</li>
								</ul>
								<ul className="txt-inner">
									{detailAddress === '기타' ? (
										<li>
											<input
												type="text"
												placeholder="상세주소를 입력해주세요."
												onChange={(e) => setAddress2(e.target.value)}
												value={address2}
											/>
										</li>
									) : (
										<li>
											<p>
												<input
													type="text"
													placeholder="000동"
													className="short"
													onChange={handleInputChange_1}
													value={address3}
												/>
												동
											</p>
											<p>
												<input
													type="text"
													placeholder="0000호"
													className="short"
													onChange={handleInputChange_2}
													value={address4}
												/>
												호
											</p>
										</li>
									)}
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div className="comm_btn_wrap">
				<button type="button" className="btn_cancel" onClick={handleBack}>
					이전
				</button>
				<button
					type="submit"
					className={`btn_next ${nicknameValid && telnoValid && dongGuSelect ? 'on' : ''
						}`}
				>
					완료
				</button>{' '}
				{/* 모두 체크 시 on클래스 붙음*/}
			</div>
			<button type="button" className="withdrawal mt25"
				onClick={handleWithdrawal}>탈퇴하기</button>
		</form>
	);
};

export default MyInfoModify;
