import React, { useEffect, useState } from 'react';
import './AdminPage_Static.scss';
import SubLocation from '../../components/common/SubLocation';
import Pagination from '../../components/common/Pagination';
import AdminAPI from '../../apis/AdminAPI';
import { StaticContent } from '../../models/AdminInfo';
import TownList from '../../models/TownList';
import TownAPI from '../../apis/TownAPI';
import moment from 'moment';
import axios from 'axios';
import useMember from '../../hooks/useMember';
import { useNavigate } from 'react-router-dom';

const SubLocationList = [{ href: '/admin_02/', text: '관리자 메뉴' }];
const SubLocationList2 = [
	{ href: '/admin_02/', text: '회원관리' },
	{ href: '/admin_02/point', text: '포인트 및 보상 관리' },
	{ href: '/admin_02/static', text: '활동 통계' },
	{ href: '/admin_02/today', text: '이용자 수 통계' },
];
interface DownloadParams {
	mssnDe?: string;
	size?: number;
	page?: number;
	townName?: string;
	prgrsSt?: string;
	title?: string;
	fileName?: string;
	downloadFileName?: string;
}

const AdminPage_Static = () => {
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);
	const [statics, setStatics] = useState([] as StaticContent[]);
	const [mssnDe, setMssnDe] = useState<string>('마을미션');
	const [townName, setTownName] = useState<string>();
	const [townList, setTownList] = useState<TownList>();
	const [prgrsSt, setPrgrsSt] = useState<string>();
	const [title, setTitle] = useState<string>();
	const [totalElements, setTotalElements] = useState<number>();
	const { member } = useMember();
	const navigate = useNavigate();

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	// 권한관리
	useEffect(() => {
		if (!member || member.roles !== 'AMN') {
			navigate('/');
		}
	}, []);

	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			setTownList(res);
		});
		AdminAPI.getStaticList({
			mssnDe: mssnDe ? mssnDe : undefined,
			size: 10,
			page: page - 1,
			townName: townName,
			prgrsSt: prgrsSt,
			title: title,
		})
			.then((res) => {
				// console.log(';;;;;;;;;;;;;;;;;', res);
				// console.log('res.data.totalElements :::::::', res.data.totalElements);
				setTotalElements(res.data.totalElements);
				setTotalPage(res.data.totalPages);
				// console.log('활동 통계', res.data.content);
				setStatics(res.data.content);
			})
			.catch((e) => { });
	}, [mssnDe, page, townName, prgrsSt, title]);
	// // console.log('statics :::::::', statics);
	const townChange = (selectTown: string) => {
		setTownName(selectTown);
		setDownloadParams({ ...downloadParams, townName: selectTown });
	};
	const handlePrgrsSt = (prgrsSt: string) => {
		setPrgrsSt(prgrsSt);
		setDownloadParams({ ...downloadParams, prgrsSt: prgrsSt });
	};
	const handleSearchTitle = (title: string) => {
		setTitle(title);
		setDownloadParams({ ...downloadParams, title: title });
	};
	const getSearchKeyword = (e: any) => {
		if (handleSearchTitle) {
			handleSearchTitle(String(title));
		}
		if (e.key === 'Enter') {
			if (handleSearchTitle) {
				handleSearchTitle(String(title));
			}
		}
	};
	// 목록 다운로드

	const [downloadParams, setDownloadParams] = useState<DownloadParams>({
		mssnDe: mssnDe,
		townName: townName,
		prgrsSt: prgrsSt,
		title: title,
		downloadFileName: 'example_filename',
	});
	const handleDownload = async (downloadParams: DownloadParams) => {
		// console.log('downloadParams::::::', downloadParams);

		try {
			const response = await axios.get(`/api/admin/active/download`, {
				responseType: 'blob',
				params: downloadParams,
			});
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', downloadParams.downloadFileName + '.xlsx');
			document.body.appendChild(link);
			link.click();
		} catch (error) { }
	};
	const handleVillMission = () => {
		setMssnDe('마을미션');
		setDownloadParams({ ...downloadParams, mssnDe: '마을미션' });
	};
	const handleMission = () => {
		setMssnDe('기본미션');
		setDownloadParams({ ...downloadParams, mssnDe: '기본미션' });
	};
	const handleMapMission = () => {
		setMssnDe('지도미션');
		setDownloadParams({ ...downloadParams, mssnDe: '지도미션' });
	};
	const handleChallenge = () => {
		setMssnDe('');
		setDownloadParams({ ...downloadParams, mssnDe: '' });
	};
	return (
		<main className="AdminPage_Static">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="관리자 메뉴"
					liList={SubLocationList}
					titleText2="활동 통계"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>활동 통계</h3>
					{/* <select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select> */}
					<p>참여마당의 에너지전환활동 통계를 확인하세요.</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="myEnergyAct">
						{/* 검색 시작 */}
						<div className="comm_search mt40">
							<ul>
								<li>
									<select
										className="comm_select03"
										onChange={(e) => townChange(e.target.value)}
									>
										<option>마을 전체</option>
										{townList?.content.map((list) => (
											<option value={list.townName}>{list.townName}</option>
										))}
									</select>
									<select
										className="comm_select03"
										onChange={(e) => handlePrgrsSt(e.target.value)}
									>
										<option value={''}>상태 전체</option>
										<option value={'진행'}>진행</option>
										<option value={'대기'}>대기</option>
										<option value={'종료'}>종료</option>
									</select>
								</li>
								<li>
									<div className="comm_search_plus">
										<input
											type="text"
											placeholder="미션명을 입력해주세요."
											onChange={(e) => handleSearchTitle(e.target.value)}
											onKeyPress={getSearchKeyword}
										/>
									</div>
									<button type="button">검색</button>
								</li>
							</ul>
						</div>
					</div>
					{/* 검색 종료 */}
					{/*탭 시작*/}
					<div className="button_box mt25">
						<div className="tab_three">
							<button
								type="button"
								onClick={handleVillMission}
								className={
									mssnDe === '마을미션' ? 'tab tab_active' : 'tab non_active'
								}
							>
								마을미션
							</button>
							<button
								type="button"
								onClick={handleMapMission}
								className={
									mssnDe === '지도미션' ? 'tab tab_active' : 'tab non_active'
								}
							>
								지도미션
							</button>
							<button
								type="button"
								onClick={handleMission}
								className={
									mssnDe === '기본미션' ? 'tab tab_active' : 'tab non_active'
								}
							>
								기본미션
							</button>
							<button
								type="button"
								onClick={handleChallenge}
								className={mssnDe === '' ? 'tab tab_active' : 'tab non_active'}
							>
								챌린지
							</button>
						</div>
						<div className="comm_tool01">
							<button
								type="button"
								className="comm_write_button"
								onClick={() =>
									handleDownload({
										...downloadParams,
										fileName: 'Active.xlsx',
										downloadFileName: '활동 통계',
									})
								}
							>
								목록 다운로드
							</button>
						</div>
					</div>
					{/*탭 종료*/}
					{/* 테이블 시작 */}
					{/*마을미션 탭 시작*/}
					{mssnDe === '마을미션' && (
						<div className="point_table mt25">
							<ul className="thead pa20">
								<li>미션 인증기간</li>
								<li>미션명</li>
								<li>마을명</li>
								<li>참여자 수</li>
								<li>인증 수</li>
								<li>상태</li>
							</ul>
							<ul className="tbody">
								{statics &&
									statics.map((list, idx) => (
										<li className="pa20" key={idx}>
											<p>
												{moment(list?.startDt).format('YYYY.MM.DD')} ~{' '}
												{moment(list?.endDt).format('YYYY.MM.DD')}{' '}
											</p>
											<p>{list?.title}</p>
											<p>{list?.townName}</p>
											<p>{list?.count1}명</p>
											<p>{list?.count2}개</p>
											<p
												className={
													list?.prgrsSt === '진행'
														? 'blue'
														: list?.prgrsSt === '종료'
															? 'gray'
															: list?.prgrsSt === '대기'
																? 'red'
																: ''
												}
											>
												{list?.prgrsSt}
											</p>
										</li>
									))}
							</ul>
						</div>
					)}

					{/*마을미션 탭 종료*/}
					{/*지도미션 탭 시작*/}
					{mssnDe === '지도미션' && (
						<div className="point_table mt25">
							<ul className="thead pa20">
								<li>미션 인증기간</li>
								<li>미션명</li>
								<li>마을명</li>
								<li>참여자 수</li>
								<li>인증 수</li>
								<li>상태</li>
							</ul>
							<ul className="tbody">
								{statics &&
									statics.map((list, idx) => (
										<li className="pa20" key={idx}>
											<p>
												{moment(list?.startDt).format('YYYY.MM.DD')} ~{' '}
												{moment(list?.endDt).format('YYYY.MM.DD')}{' '}
											</p>
											<p>{list?.title}</p>
											<p>{list?.townName}</p>
											<p>{list?.count1}명</p>
											<p>{list?.count2}개</p>
											<p
												className={
													list?.prgrsSt === '진행'
														? 'blue'
														: list?.prgrsSt === '종료'
															? 'gray'
															: list?.prgrsSt === '대기'
																? 'red'
																: ''
												}
											>
												{list?.prgrsSt}
											</p>
										</li>
									))}
							</ul>
						</div>
					)}

					{/*지도미션 탭 종료*/}
					{/*기본미션 탭 시작*/}
					{mssnDe === '기본미션' && (
						<div className="point_table mt25">
							<ul className="thead pa20">
								<li>미션 인증기간</li>
								<li>미션명</li>
								<li>마을명</li>
								<li>참여자 수</li>
								<li>인증 수</li>
								<li>상태</li>
							</ul>
							<ul className="tbody">
								{statics &&
									statics.map((list, idx) => (
										<li className="pa20" key={idx}>
											<p>
												{moment(list?.startDt).format('YYYY.MM.DD')} ~{' '}
												{moment(list?.endDt).format('YYYY.MM.DD')}{' '}
											</p>
											<p>{list?.title}</p>
											<p>{list?.townName}</p>
											<p>{list?.count1}명</p>
											<p>{list?.count2}개</p>
											<p
												className={
													list?.prgrsSt === '진행'
														? 'blue'
														: list?.prgrsSt === '종료'
															? 'gray'
															: list?.prgrsSt === '대기'
																? 'red'
																: ''
												}
											>
												{list?.prgrsSt}
											</p>
										</li>
									))}
							</ul>
						</div>
					)}

					{/*기본미션 탭 종료*/}
					{/*챌린지 탭 시작*/}
					{mssnDe === '' && (
						<div className="point_table mt25">
							<ul className="thead pa20">
								<li>챌린지 인증기간</li>
								<li>챌린지명</li>
								<li>마을명</li>
								<li>참여자 수</li>
								<li>인증 수</li>
								<li>상태</li>
							</ul>
							<ul className="tbody">
								{statics &&
									statics.map((list, idx) => (
										<li className="pa20" key={idx}>
											<p>
												{/* {list?.startDt[0]}.
												{String(list?.startDt[1]).padStart(2, '0')}.
												{String(list?.startDt[2]).padStart(2, '0')} ~{' '}
												{list?.endDt[0]}.
												{String(list?.endDt[1]).padStart(2, '0')}.
												{String(list?.endDt[2]).padStart(2, '0')}{' '} */}
												{moment(list?.startDt).format('YYYY.MM.DD')} ~{' '}
												{moment(list?.endDt).format('YYYY.MM.DD')}
											</p>
											<p>{list?.title}</p>
											<p>{list?.townName}</p>
											<p>{list?.count1}명</p>
											<p>{list?.count2}개</p>
											<p
												className={
													list?.prgrsSt === '진행'
														? 'blue'
														: list?.prgrsSt === '종료'
															? 'gray'
															: list?.prgrsSt === '대기'
																? 'red'
																: ''
												}
											>
												{list?.prgrsSt}
											</p>
										</li>
									))}
							</ul>
						</div>
					)}

					{/*챌린지 탭 종료*/}
					{/* 테이블 종료 */}
					<Pagination
						page={Number(page)}
						totalPageCount={Number(totalPage)}
						// totalPageCount={Math.ceil(count / 8)}
						onPageChange={handlePageChange}
					/>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default AdminPage_Static;
