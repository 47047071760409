import { useState, useEffect, CSSProperties } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MemberAPI from '../../apis/MemberAPI';
import SubLocation from '../../components/common/SubLocation';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import useMember from '../../hooks/useMember';
import '../home/MainPage.scss';
import img_energyMap from '../../assets/img_energyMap.png';
import test09 from '../../assets/test09.png';
import test10 from '../../assets/test10.png';
import MemberInfo from '../../models/MemberInfo';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import { AllcmMasterList } from '../../models/MapInfo';
import { Content } from '../../models/ActivityLog';
import ActivityLogAPI from '../../apis/ActivityLogAPI';
import moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import MissionAPI from '../../apis/MissionAPI';
import MissionInfo, { GetEnergy } from '../../models/MissionInfo';
import errorImg from '../../assets/errorImg.jpg';
import TownList from '../../models/TownList';
import TownAPI from '../../apis/TownAPI';
import img_gwangsan from '../../assets/img_gwangsan.png';
import img_vill01 from '../../assets/img_vill01.png';
import img_donggu from '../../assets/img_donggu.png';
import img_seogu from '../../assets/img_seogu.png';
import img_namgu from '../../assets/img_namgu.png';
import img_bukgu from '../../assets/img_bukgu.png';
import ChartAPI from '../../apis/ChartAPI';
import { ChartList } from '../../models/ChartList';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import Piechart from '../../components/common/ChartComponent_pie';
import PopupAPI from '../../apis/PopupAPI';
import { PopupListInfo } from '../../models/PopupManager';
import closeIcon from "../../assets/ic_close.png";
import UtilizationAPI from '../../apis/UtilizationAPI';
import axios from 'axios';

const closeButtonCss: CSSProperties = {
	position: 'absolute',
	boxSizing: 'border-box',
	width: '14px',
	height: '14px',
	background: closeIcon,
	backgroundSize: 'cover',
	top: '10px',
	right: '10px',
	cursor: 'pointer',
	zIndex: '5000',
	backgroundColor: '#fff',
	borderRadius: '50%',
}
const checkboxCss: CSSProperties = {
	width: '100%',
	height: '20px',
	backgroundColor: '#fff',
	// boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
	fontSize: '14px'
}
const MainPage = () => {
	const { member } = useMember();
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [guRadioSelect, setGuRadioSelect] = useState<string>('전체');
	const [dongSelect, setDongSelect] = useState<string>('도산동');
	const [yyyySelect, setYyyySelect] = useState<string>('2023');
	const [dataList, setDataList] = useState<ChartList>();
	const [energyChartList, setEnergyChartList] =
		useState<{ name: string; value: number }[]>();
	const [energyConsumption, setEnergyConsumption] = useState<number>();
	const [gasChartList, setGasChartList] =
		useState<{ name: string; value: number }[]>();
	const [gasEmissions, setGasEmissions] = useState<number>();
	const [guEnergyChartList, setGuEnergyChartList] =
		useState<{ name: string; value: number }[]>();
	const [guEnergyConsumption, setGuEnergyConsumption] = useState<number>();
	const [guGasChartList, setGuGasChartList] =
		useState<{ name: string; value: number }[]>();
	const [guGasEmissions, setGuGasEmissions] = useState<number>();
	useState<{ name: string; value: number }[]>();
	// 팝업
	const [mobile, setMobile] = useState(false);
	const seeNoMorePopupDay = String(localStorage.getItem('seenoMorePopupDay'))
	const [popupList, setPopupList] = useState([] as PopupListInfo[])
	const [npuUidList, setNpuUidList] = useState([] as number[]);
	const [seenoPopup, setSeenoPopup] = useState(false);

	const [isDragging, setIsDragging] = useState(false);
	const [draggingPos, setDraggingPos] = useState({ x: 0, y: 0 });
	const [clickPopupZindex, setClickPopupZindex] = useState<number>(0);
	const [clickedDiv, setClickedDiv] = useState<HTMLElement>();
	// const [testPdf, setTestPdf] = useState<string>('')
	// const [testPdf2, setTestPdf2] = useState<string>('')
	const mainMenu = localStorage.getItem('mainMenu');
	const userJSON = localStorage.getItem('todayId');
	const toDayUserId = userJSON ? JSON.parse(userJSON) : null;
	sessionStorage.setItem('nowTab', String('홈'));
	// useEffect(() => {
	// 	axios.get('http://localhost:12158/POPUP/2024/5/b35e7ba3-02c0-40f9-964a-44de3441fb90.pdf')
	// 		.then((res) => {
	// 			console.log("PDF 파일 >>>>>>>>>>>>>>>>>>>>>>>>>", res)
	// 		})
	// }, [])
	useEffect(() => {
		if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
			setMobile(true);
		}
	}, [])
	useEffect(() => {
		let menuTitle = ''
		if (!mainMenu) {
			menuTitle = 'main';
			localStorage.setItem('mainMenu', 'true');
			// api 호출
			// console.log('main api 호출 !!!!!');
			if (toDayUserId) {
				UtilizationAPI.todayMenuCount({
					id: toDayUserId.id,
					mainItem: '메인'
				}).then((res) => {
					if (res) {
						// console.log("메인 투데이 카운트 >>>>> ", res);
					}
				})
			}
		}
	}, [])
	useEffect(() => {
		if (window.document.location.href.includes('error')) {
			const title = '';
			const message = '이미 탈퇴한 회원입니다';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
				onClose: () => {
					navigate('/');
					window.location.reload();
				},
			});
		}
	}, []);

	useEffect(() => {
		ChartAPI.getChartDataList({
			areaUpper: guRadioSelect,
			area: dongSelect,
			yyyy: yyyySelect,
		})
			.then((res) => {
				setDataList(res);
				const formattedEnergyData = Object.entries(res.energyData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGasData = Object.entries(res.ggssData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGuEnergyData = Object.entries(res.guenergyData).map(
					([name, value]) => ({ name, value })
				);
				const formattedGuGasData = Object.entries(res.guggssData).map(
					([name, value]) => ({ name, value })
				);
				// console.log('왓냐 ㅋ', formattedEnergyData[0].name);
				setEnergyConsumption(res.energyData.에너지소비량);
				setGasEmissions(res.ggssData.온실가스배출량);
				setGuEnergyConsumption(res.guenergyData.에너지소비량);
				setGuGasEmissions(res.guggssData.온실가스배출량);
				setEnergyChartList(
					formattedEnergyData.filter(
						(filter) =>
							filter.name !== '에너지소비량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
				setGasChartList(
					formattedGasData.filter(
						(filter) =>
							filter.name !== '온실가스배출량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0 
					)
				);
				setGuEnergyChartList(
					formattedGuEnergyData.filter(
						(filter) =>
							filter.name !== '에너지소비량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
				setGuGasChartList(
					formattedGuGasData.filter(
						(filter) =>
							filter.name !== '온실가스배출량' &&
							filter.name !== 'gperdata' &&
							filter.name !== 'rank' &&
							filter.name !== 'yperdata' &&
							filter.value !== 0
					)
				);
			})
			.catch((error) => {
				alert('데이터가 없습니다.');
			});
	}, [guRadioSelect]);

	// useEffect(() => {
	// 	const fetchPdfUrl = async () => {
	// 		try {
	// 			if (testPdf) {
	// 				console.log('성공', testPdf)
	// 				const response = await axios.get(`/api/attach/getFile?savedPath=${testPdf}`, {
	// 					responseType: 'blob',
	// 				});
	// 				const file = new File([response.data], 'testaaa.pdf', {
	// 					type: 'application/pdf',
	// 				});
	// 				const pdfUrl = URL.createObjectURL(file);
	// 				setTestPdf2(pdfUrl);
	// 				console.log("response 1111111111111111 >>>>>>>>>", response);
	// 				console.log("pdfBlob 22222222222222222 >>>>>>>>>", file);
	// 				console.log("pdfUrl  3333333333333333 >>>>>>>>>", pdfUrl);
	// 			}
	// 		} catch (error) {
	// 			console.error('Error fetching PDF:', error);
	// 		}
	// 	};
	// 	fetchPdfUrl();
	// }, [testPdf]);
	// 팝업
	useEffect(() => {
		sessionStorage.removeItem('popupN');
		PopupAPI.getPopupList({
			page: 1,
			status: 'Y'
		})
			.then((res) => {
				if (res) {
					// console.log(res);
					// if (res[1]) {
					// 	setTestPdf(res[1].fileList[0].savePath)
					// }
					if (res[0]) {
						if (seeNoMorePopupDay) {
							const popupDt = Number(moment(seeNoMorePopupDay).format('YYYYMMDD'));
							const toDay = Number(moment().format('YYYYMMDD'));
							// // console.log("popupDt : ", popupDt, "toDay : ", toDay);
							if (popupDt < toDay) {
								localStorage.removeItem('seenoMorePopup');
							}
						}
						const seeNoMorePopupList = JSON.parse(String(localStorage.getItem('seenoMorePopup')))
						if (seeNoMorePopupList) {
							setNpuUidList(seeNoMorePopupList);
							res.map((list, index) => {
								popupList.push(list);
							})
							seeNoMorePopupList.forEach((noSeeList: string) => {
								popupList.map((list2, index2) => {
									// // console.log("list.npuUid >> ", list2.npuUid);
									// // console.log("noSeeList >> ", noSeeList);
									if (String(list2.npuUid).includes(noSeeList)) {
										// // console.log("개빡", list2);
										popupList.splice(index2, 1)
										return;
									} else {
										// // console.log("개빡치네", list2);
									}
								})
							});
						} else {
							setPopupList(res);
						}
					} else {
						setPopupList([]);
					}
				}
			})
	}, [])
	const handleGuRadioButton = (e: any) => {
		// console.log('구 선택 >>> ', e.target.value);
		setGuRadioSelect(e.target.value);
	};
	// 팝업
	const handlePopupClose = (index: number) => {
		// setPopupList((popupList) => {
		// 	const updatePopupList = [...popupList]
		// 	const newPopupList = updatePopupList.filter((filter) => filter.npuUid !== npuUid)
		// 	return newPopupList;
		// })
		const divList = document.getElementsByClassName('popupTopDiv')
		const element = divList[index] as HTMLElement;
		element.style.display = 'none';
		// // console.log("index > > > >", index)
		// popupList.splice(index, 1)
		if (seenoPopup) {
			setSeenoPopup(false);
			localStorage.setItem('seenoMorePopup', JSON.stringify(npuUidList));
			localStorage.setItem('seenoMorePopupDay', moment().format('YYYY-MM-DD'));
		}
	}
	const handlePopupSeenomoretoday = (e: any, npuUid: number) => {
		const checkboxValue = e.target.checked
		// // console.log(checkboxValue);
		if (checkboxValue) {
			setNpuUidList((cur) => [...cur, npuUid]);
			e.target.checked = true;
			setSeenoPopup(true);
		} else {
			setNpuUidList((cur) => {
				const updateUidList = [...cur]
				const newUidList = updateUidList.filter((filter) => filter !== npuUid)
				return newUidList
			})
			setSeenoPopup(false);
		}
	}
	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		// const clickedElement = e.target as HTMLDivElement;
		// // console.log("마우스 다운");
		setIsDragging(true);
		const divList = document.getElementsByClassName('popupTopDiv')
		const element = divList[index] as HTMLElement;
		setClickedDiv(element);
		// // console.log(" >>>>>> !!", e);
		element.style.zIndex = `${3000 + clickPopupZindex}`;
		setClickPopupZindex(cur => cur + 1);
		if (element) {
			setDraggingPos({
				// x: e.clientX - popupRef.current.offsetLeft,
				// y: e.clientY - popupRef.current.offsetTop
				x: e.clientX - element.offsetLeft,
				y: e.clientY - element.offsetTop
			});
		}
	};

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		// // console.log("마우스 드래그");
		const divList = document.getElementsByClassName('popupTopDiv')
		const element = divList[index] as HTMLElement;
		if (isDragging) {
			const newX = e.clientX - draggingPos.x;
			const newY = e.clientY - draggingPos.y;
			// popupRef.current.style.left = `${newX}px`;
			// popupRef.current.style.top = `${newY}px`;
			// element.style.left = `${newX}px`;
			// element.style.top = `${newY}px`;
			clickedDiv!.style.left = `${newX}px`;
			clickedDiv!.style.top = `${newY}px`;
		}
	};

	const handleMouseUp = () => {
		// // console.log("마우스 업");
		setIsDragging(false);
	};
	const handleDragStart = (e: any) => {
		e.preventDefault();
	};
	const handlePopupImg = (savePath: string) => {
		const img = `/api/attach/getFile?savedPath=${savePath}`
		// console.log("팝업 이미지 요청 img>>>", img);
		return img
	}
	return (
		<main className="MainPage">
			{popupList.length !== 0 ?
				popupList.map((list, index) => (
					<div className="popupTopDiv"
						// ref={popupRef}
						key={index}
						style={{
							position: 'fixed',
							zIndex: `${2900 - index}`,
							left: mobile ? '' : '300px',
							top: mobile ? '' : '100px',
							width: mobile ? window.innerWidth : '400px',
							// height: mobile ? '500px' : '600px',
							boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
							backgroundColor: '#fff',
							padding: '30px 15px',
						}}

					>
						<img
							src={closeIcon}
							alt="팝업 닫기 이미지"
							style={closeButtonCss}
							onClick={() => handlePopupClose(index)}
						/>
						<div
							onMouseDown={(e) => handleMouseDown(e, index)}
							onMouseMove={(e) => handleMouseMove(e, index)}
							onMouseUp={handleMouseUp}
							className='preview'
							style={{
								// width: '400px',
								// height: '600px',
								// position: 'absolute',
								// left: '300px',
								// top: '100px',
								// border: '2px solid #e8e9eb',
								width: '100%',
								height: '100%',
							}}>

							{list.fileList[0] ?
								<img
									onDragStart={handleDragStart}
									style={{
										width: '100%',
										// height: mobile ? list.title ? '400px' : '500px' : list.title ? '500px' : '600px',
										zIndex: '2800',
										// borderBottom: '2px solid #e8e9eb',
										marginBottom:'15px',
									}}
									className='previewImg'
									// src={`/api/attach/getFile?savedPath=${list.fileList[0].savePath}`}
									src={handlePopupImg(list.fileList[0].savePath)}
									alt=""
								/>
								:
								<></>
							}
							{/* <embed src='/POPUP/2024/5/b35e7ba3-02c0-40f9-964a-44de3441fb90.pdf' type="application/pdf" width="100%" height="500px" /> */}
							{/* <embed src={testPdf2} type="application/pdf" width="100%" height="100%" /> */}
							{list.title ?
								<p style={{
									// margin: '5px',
									height: mobile ? list.fileList[0] ? '80px' : '500px' : list.fileList[0] ? '80px' : '600px',
									zIndex: '3000',
									overflowY: 'auto',
									backgroundColor: '#fff',
									borderBottom: list.fileList[0] ? '0px solid #e8e9eb' : '1px solid #e8e9eb',
								}}>{list.title}</p>
								:
								<></>
							}
						</div>
						<div style={checkboxCss}>
							<input
								type="checkbox"
								className="popupCheckbox"
								// checked={seenoPopup}
								onChange={(e) => handlePopupSeenomoretoday(e, list.npuUid)}
							/>오늘 더 이상 보지 않기
						</div>
					</div>
				))
				:
				<></>
			}
			<h1 className="notext">전체 영역</h1>
			{/* <nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<div className="lnb_menu">
					<ul className="lnb_dep1">
						<li className="lnb_dep1_li">
							<a  className="home">
								<img src="../assets/ic_home.png" alt="메인으로" />
							</a>
						</li>
						<li className="lnb_dep1_li">
							<a >소통마당</a>
							<ul className="lnb_dep2">
								<li className="active">
									<a >소통마당</a>
								</li>
								<li>
									<a >참여마당</a>
								</li>
								<li>
									<a >정보마당</a>
								</li>
							</ul>
						</li>
						<li className="lnb_dep1_li end on">
							<a >참여소개</a>
							<ul className="lnb_dep2">
								<li className="active">
									<a >참여소개</a>
								</li>
								<li>
									<a >참여내용</a>
								</li>
								<li>
									<a >참여공지</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="lnb_txt">
					<h3>소통마당</h3>
					<select>
						<option>문산마을</option>
						<option>첨단마을</option>
						<option>사직마을</option>
					</select>
					<p>문흥1동, 문흥2동, 오치1동</p>
				</div>
			</nav> */}
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="Main mt40">
						{
							member && member?.id /* 비주얼존 (로후) 시작 */ ? (
								<MemberZone member={member} />
							) : (
								/* 비주얼존 (로후) 종료 */ /* 비주얼존 (로전) 시작 */
								<NonMemberZone />
							)
							/* 비주얼존 (로전) 종료 */
						}

						{/* 현황 시작 */}
						<div className="situate_wrap">
							<h3 className="big_title mb25">에너지현황</h3>
							<div className="situate_inner">
								<div className="map_img">
									<form>
										<img src={img_energyMap} alt="광주 지도" />
										<p>
											<input
												type="radio"
												id="radio1-1"
												name="radio1"
												value="광산구"
												onClick={handleGuRadioButton}
												defaultChecked
											/>
											<label htmlFor="radio1-1">광산구</label>
										</p>
										<p>
											<input
												type="radio"
												id="radio1-2"
												name="radio1"
												value="서구"
												onClick={handleGuRadioButton}
											/>
											<label htmlFor="radio1-2">서구</label>
										</p>
										<p>
											<input
												type="radio"
												id="radio1-3"
												name="radio1"
												value="북구"
												onClick={handleGuRadioButton}
											/>
											<label htmlFor="radio1-3">북구</label>
										</p>
										<p>
											<input
												type="radio"
												id="radio1-4"
												name="radio1"
												value="남구"
												onClick={handleGuRadioButton}
											/>
											<label htmlFor="radio1-4">남구</label>
										</p>
										<p>
											<input
												type="radio"
												id="radio1-5"
												name="radio1"
												value="동구"
												onClick={handleGuRadioButton}
											/>
											<label htmlFor="radio1-5">동구</label>
										</p>
										<p>
											<input
												type="radio"
												id="radio1-6"
												name="radio1"
												value="전체"
												onClick={handleGuRadioButton}
												defaultChecked
											/>
											<label htmlFor="radio1-6">
												광주 전체 선택
												<span />
											</label>
										</p>
									</form>
								</div>
								<div className="graph_circle">
									<div className="box_circle pa20">
										<h4>
											{yyyySelect}년 {guRadioSelect} 에너지 소비량
											<br />({guEnergyConsumption?.toLocaleString()}천 TOE)
										</h4>
										<div className="mt10">
											{/* <img src={test09} alt="임시 이미지!" /> */}
											<Piechart data={guEnergyChartList} />
										</div>
									</div>
									<div className="box_circle pa20">
										<h4>
											{yyyySelect}년 {guRadioSelect} 온실가스 배출량
											<br />({guGasEmissions?.toLocaleString()}천 tCO2eq)
										</h4>
										<div className="mt10">
											{/* <img src={test10} alt="임시 이미지!" /> */}
											<Piechart data={guGasChartList} />
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* 현황 종료 */}
						{/* 정보마당 시작 */}
						<InfoYard />
						{/* 정보마당 종료 */}
						{/* 활동 시작 */}
						<CommuYard />
						{/* 활동 종료 */}
						{/* 순위 시작 */}
						<MissionYard />
						{/* 순위 종료 */}
						{/* 미션 시작 */}

						{/* 미션 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};
const NonMemberZone = () => {
	const navigate = useNavigate();
	const [townList, setTownList] = useState<TownList>();
	const [townId, setTownId] = useState<number>(1);

	useEffect(() => {
		TownAPI.getTownList().then((res) => {
			// console.log('마을리스트', res);
			res &&
				res.content &&
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((item) => item.districtName)
						.join(', ');
					if (list.townId == 1 || list.townId == 2) {
						list.townImg = img_gwangsan;
					} else if (list.townId == 3) {
						list.townImg = img_vill01;
					} else if (list.townId == 4) {
						list.townImg = img_donggu;
					} else if (list.townId == 5 || list.townId == 6) {
						list.townImg = img_seogu;
					} else if (list.townId == 7 || list.townId == 8) {
						list.townImg = img_namgu;
					} else if (list.townId == 9 || list.townId == 10) {
						list.townImg = img_bukgu;
					}
				});
			setTownList(res);
		});
	}, []);
	useEffect(() => { }, [townId]);
	// console.log('ddddddddddd', townId);

	// 마을 상세로 이동(임시)
	const handleVillage = (townId: number) => {
		// console.log('클릭한 마을 메인으로 이동', townId);
		navigate(`/commu_02/mainVillage_03`);
		// sessionStorage.getItem("town");
		sessionStorage.setItem('town', String(townId));
	};

	return (
		<div className="visual_off_wrap">
			<div className="allVillage_inner">
				<h3 className="big_title mb25">
					마을중심의 시민실천 활동 지원
					<br />
					에너지 자립도 향상을 위한 플랫폼
				</h3>
				<div className="box_allVillage">
					<select onChange={(e) => setTownId(Number(e.target.value))}>
						{townList &&
							townList?.content.map((list, idex) => (
								<option key={idex} value={list.townId}>
									{list.townName}
								</option>
							))}
					</select>
					<button type="button" onClick={() => handleVillage(Number(townId))}>
						마을 입장하기
					</button>
				</div>
			</div>
		</div>
	);
};
const MemberZone = ({ member }: { member: MemberInfo }) => {
	const navigate = useNavigate();
	const [townId, setTownId] = useState<number>(0);
	const [myEnergy, setMyEnergy] = useState<GetEnergy>();
	useEffect(() => {
		setTownId(member?.townDTO?.townId);
		// console.log(member?.townDTO);
	}, []);
	// 내 마을 입장하기
	const handleMyVill = () => {
		navigate(`/commu_02/mainVillage_03`);
		// sessionStorage.getItem("town");
		sessionStorage.setItem('town', String(townId));
	};
	// 마을 보러가기
	const handleVill = () => {
		navigate('/commu_02/');
	};
	// 내 에너지전환 현황
	useEffect(() => {
		MissionAPI.getMyEnergy().then((res) => {
			// console.log('getMyEnergy::::::', res);
			setMyEnergy(res?.data);
		});
	}, []);
	return (
		<div className="visual_on_wrap">
			{member && member?.townDTO?.townId ? (
				<div className="myVillage_inner">
					<h3 className="big_title mb25">
						내 에너지전환마을은
						<br />
						{member?.townDTO?.townName}
						{/* <span>(첨단1동, 첨단2동)</span> */}
						입니다
					</h3>
					<button type="button" onClick={() => handleMyVill()}>
						내 마을 입장하기
					</button>
				</div>
			) : (
				<div
					className="myVillage_inner"
				// style={{ display: 'none' }}
				>
					<h3 className="big_title mb25">
						내 에너지전환마을이
						<br />
						아직 없어요
					</h3>
					<button type="button" onClick={handleVill}>
						마을 보러가기
					</button>
				</div>
			)}

			<div className="myData_inner">
				<h3 className="big_title mb25">내 에너지전환현황입니다</h3>
				<div className="box_myData">
					<ul>
						<li>
							<p>전력</p>
							<p className="txt_blue mt10">
								{myEnergy?.pwrerMwh ? myEnergy?.pwrerMwh : 0} MWh
							</p>
							<p className="txt_blue mt5">
								{myEnergy?.pwrerToe ? myEnergy?.pwrerToe : 0} 10<sup>-3</sup>TOE
							</p>
						</li>
						<li>
							<p>도시가스</p>
							<p className="txt_green mt10">
								{myEnergy?.gasMj ? myEnergy?.gasMj : 0} MJ
							</p>
							<p className="txt_green mt5">
								{myEnergy?.gasToe ? myEnergy?.gasToe : 0} 10<sup>-3</sup>TOE
							</p>
						</li>
						<li>
							<p>총 에너지 절감량</p>
							<p className="txt_red mt10">
								{myEnergy?.redcnToe ? myEnergy?.redcnToe : 0} 10<sup>-3</sup>TOE
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
const InfoYard = () => {
	const navigate = useNavigate();
	const handleStatus = () => {
		navigate('/info_02/');
	};
	const handleAnalysis = () => {
		navigate('/info_02/analysis/');
	};
	const handleMap = () => {
		navigate('/info_02/map/');
	};
	const handleCal = () => {
		navigate('/info_02/cal/');
	};
	const handleDic = () => {
		navigate('/dictionary_02/');
	};
	return (
		<div className="inform_wrap">
			<h3 className="big_title center mb25">정보마당</h3>
			<div className="inform_inner">
				<ul>
					<li>
						<div>
							<a onClick={handleStatus}>
								<h4 >에너지 현황</h4>
								<p>
									광주광역시 자치구와
									<br />
									행정동의 에너지현황
								</p>
								<span>바로가기</span>
							</a>
						</div>
					</li>
					<li>
						<div>
							<a onClick={handleAnalysis}>
								<h4>에너지 분석</h4>
								<p>
									광주광역시 자치구와
									행정동의 에너지를 분석한 결과
								</p>
								<span>바로가기</span>
							</a>
						</div>
					</li>
					<li>
						<div>
							<a onClick={handleMap}>
								<h4>에너지 지도</h4>
								<p>
									햇빛 발전소를 세울 수 있는
									부지를 알아보는 지도
								</p>
								<span>바로가기</span>
							</a>
						</div>
					</li>
					<li>
						<div>
							<a onClick={handleCal}>
								<h4>탄소계산기</h4>
								<p>
									내가 사용한 전력과 가스의
									사용량을 이산화탄소로 환산
								</p>
								<span>바로가기</span>
							</a>
						</div>
					</li>
					<li>
						<div>
							<a onClick={handleDic}>
								<h4>에너지 용어사전</h4>
								<p>
									궁금한 에너지전환 관련 용어
									한눈에 볼 수 있는 사전
								</p>
								<span>바로가기</span>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};
const CommuYard = () => {
	const navigate = useNavigate();
	const { member } = useMember();
	const [tab, setTab] = useState<number>(1);
	// 모든 마을 활동지도 목록조회
	const [mapList, setMapList] = useState([] as AllcmMasterList[]);

	// 모든 마을 활동일지 목록조회
	const [logList, setLogList] = useState<Content[] | undefined>();
	useEffect(() => {
		// 활동 지도
		ActiveMapAPI.getAllMapList({
			// tcUid: Number(townId),
			crtUid: Number(member?.id) || undefined,
			pageSize: 4,
			prgrsSt: '진행',
			// page: page,
			// search: search,
		})
			.then((res) => {
				// console.log('getAllMapList', res);
				setMapList(res.allcmMasterList);
				// setCount(Number(res.allcmMasterList[0].totalCount));
			})
			.catch((e) => { });
		// 활동 일지
		ActivityLogAPI.getSubjectList({
			// page: page - 1,
			// townId: Number(townId),
			// keyword: searchKeyword ? searchKeyword : undefined,
			sort: 'createdAt,DESC',
			size: 4,
		})
			.then((res) => {
				// console.log('getSubjectList :::::::::::: ', res);
				// // console.log('활동일지 상태값', res.content[0].prgrsSt.split(','));
				setLogList(res.content);
				const nowDate = new Date();
				// if (res?.content) {
				// 	res.content.map((list) => {
				// 		const formattedStartDate = `${list.startedAt[0]}.${String(
				// 			list.startedAt[1]
				// 		).padStart(2, '0')}.${String(list.startedAt[2]).padStart(2, '0')}`;
				// 		const formattedEndDate = `${list.endedAt[0]}.${String(
				// 			list.endedAt[1]
				// 		).padStart(2, '0')}.${String(list.endedAt[2]).padStart(2, '0')}`;
				// 		const startDate = new Date(formattedStartDate);
				// 		const endDate = new Date(formattedEndDate);
				// 		if (nowDate >= startDate && nowDate <= endDate) {
				// 			list.status = 'ing';
				// 		}
				// 		// console.log('aaaa', list.status);
				// 		// console.log(
				// 			'목록',
				// 			res.content
				// 				.filter((filter) => filter.status === 'ing')
				// 				.slice(0, 4)
				// 		);
				// 		setLogList(
				// 			res.content
				// 				.filter((filter) => filter.status === 'ing')
				// 				.slice(0, 4)
				// 		);
				// 	});
				// }
			})
			.catch((error) => {
				// console.log(error);
			});
	}, []);
	// 활동 지도 디테일 페이지
	const handleDetailMap = (cmmUid: string, crtUid: number, tcUid: string) => {
		navigate('/commu_02/actMap_03-detail');
		sessionStorage.setItem('cmmUid', String(cmmUid));
		sessionStorage.setItem('crtUid', String(crtUid));
		sessionStorage.setItem('town', String(tcUid));
	};
	// 주제내역 이동
	const handleLog = (subjectId: number, townId: string) => {
		sessionStorage.setItem('subject', String(subjectId));
		sessionStorage.setItem('town', String(townId));
		navigate('/commu_02/active_03-history');
	};

	// 월과 일이 한 자릿수일 때, 앞에 0을 붙이기
	const formattedMonth =
		logList?.map((list, index) => {
			const month = parseInt(list.createdAt[1]);
			return month < 10 ? `0${month}` : month;
		}) || [];
	const formattedDay =
		logList?.map((list, index) => {
			const day = parseInt(list.createdAt[2]);
			return day < 10 ? `0${day}` : day;
		}) || [];

	// // console.log("formattedMonth", formattedMonth)
	// // console.log("formattedDay", formattedDay)

	return (
		<div className="activity_wrap">
			<div className="tabs3 mb25">
				<button
					type="button"
					className={tab === 1 ? 'tab tab_active' : 'tab non_active'}
					onClick={() => setTab(1)}
				>
					활동일지
				</button>
				<button
					type="button"
					className={tab === 2 ? 'tab tab_active' : 'tab non_active'}
					onClick={() => setTab(2)}
				>
					활동지도
				</button>
			</div>
			{tab === 1 && (
				<div className="tab_content3">
					<ul>
						{logList &&
							logList.map((list, index) => (
								<li
									key={index}
									onClick={() =>
										handleLog(
											Number(list?.subjectId),
											String(list?.town.townId)
										)
									}
								>
									<a>
										<p className="village">{list?.town?.townName}</p>
										<h4 className="txt_cut1">{list?.title}</h4>
										<p>
											{list.createdAt[0]}.{formattedMonth[index]}.
											{formattedDay[index]}
										</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			)}
			{tab === 2 && (
				<div className="tab_content3">
					<ul>
						{mapList &&
							mapList.map((list2, index2) => (
								<li
									key={index2}
									onClick={() =>
										handleDetailMap(
											String(list2?.cmmUid),
											Number(list2?.crtUid),
											String(list2?.cmtcUid)
										)
									}
								>
									<a>
										<p className="village">{list2?.cmtitle}</p>
										<h4 className="txt_cut1">{list2?.title}</h4>
										<p>{`${moment(list2?.crtDt).format('YYYY.MM.DD')}`}</p>
									</a>
								</li>
							))}
					</ul>
				</div>
			)}
		</div>
	);
};
const MissionYard = () => {
	const [tab2, setTab2] = useState<number>(1);
	const navigate = useNavigate();
	const nowDate = new Date();
	const [villMissionList, setVillMissionList] = useState([] as MissionInfo[]);
	const [missionList, setMissionList] = useState([] as MissionInfo[]);
	const [villRanking, setVillRanking] = useState<GetEnergy>();
	// 마을 미션 목록 조회
	useEffect(() => {
		MissionAPI.getMissionList({
			// page: page - 1,
			size: 6,
			mssnDe: '마을미션',
			mssnDe2: '지도미션',
		}).then((res) => {
			// console.log('getMissionList ::::', res);
			// console.log('마을 상태값 :::::', res.content[0].prgrsSt.split(','));

			// // console.log('res.content ::::', res.content);
			if (res.content) {
				res.content.map((list: any, index: number) => {
					const formattedStartDate = `${moment(list.startDt).format(
						'yyyy.MM.DD'
					)}`;
					const formattedEndDate = `${moment(list.endDt).format('yyyy.MM.DD')}`;
					const stDt = new Date(formattedStartDate);
					const edDt = new Date(formattedEndDate);
					// // console.log('엔드 데이트', edDt);
					// if (nowDate > edDt) {
					// 	list.status = '마감';
					// } else if (nowDate >= stDt && nowDate <= edDt) {
					// 	list.status = '진행';
					// } else {
					// 	list.status = '대기';
					// }
				});
			}

			setVillMissionList(res.content);
			// setCount(res.numberOfElements);
			// console.log('villMissionList :::::', villMissionList);
		});
		MissionAPI.getMissionList({
			// page: page - 1,
			size: 6,
			mssnDe: '기본미션',
		}).then((res) => {
			// console.log('getMissionList:::::', res);
			// console.log('res.content', res.content);
			setMissionList(res.content);
			if (res.content) {
				res.content.map((list: any, index: number) => {
					const formattedStartDate = `${moment(list.startDt).format(
						'yyyy.MM.DD'
					)}`;
					const formattedEndDate = `${moment(list.endDt).format('yyyy.MM.DD')}`;
					const stDt = new Date(formattedStartDate);
					const edDt = new Date(formattedEndDate);
					// // console.log('엔드 데이트', edDt);
					// if (nowDate > edDt) {
					// 	list.status = '마감';
					// } else if (nowDate >= stDt && nowDate <= edDt) {
					// 	list.status = '진행';
					// } else {
					// 	list.status = '대기';
					// }
				});
			}
		});
		MissionAPI.getRanking({
			mssnDe: '마을미션',
		}).then((res) => {
			// console.log('마을미션 ::::::', res.data);
			setVillRanking(res.data);
		});
	}, []);
	// 마을 미션 상세보기 이동
	const handleVillDetail = (mmiUid: string, mssnDe: string) => {
		// console.log('마을미션 상세보기 이동', mssnDe);

		// console.log('마을미션 상세보기 이동', mmiUid);
		sessionStorage.setItem('mmiUid', String(mmiUid));
		if (mssnDe === '마을미션') {
			navigate(`/reward_02/villageMission_05-detail?mmiUid=${mmiUid}`);
		}

		if (mssnDe === '지도미션') {
			navigate(`/reward_02/mapMission_05-detail?mmiUid=${mmiUid}`);
		}
	};
	// 기본 미션 > 상세
	const handleDetail = (mmiUid: string) => {
		// console.log('기본미션 상세보기 이동', mmiUid);
		sessionStorage.setItem('mmiUid', String(mmiUid));
		navigate(`/reward_02/mission_05-detail?mmiUid=${mmiUid}`);
	};

	return (
		<>
			<div className="ranking_wrap">
				<div className="rankTitle_inner">
					<h3>마을미션 종합순위</h3>
				</div>
				<div className="rankList_inner">
					<div className="mySwiper swiper-container-initialized swiper-container-vertical">
						<Swiper
							className="mySwiper"
							modules={[Navigation, Pagination, Autoplay]}
							loop={true}
							direction="vertical"
							autoplay={{
								delay: 2000,
								// pauseOnMouseEnter: true,
								// disableOnInteraction: false,
							}}
							navigation={{
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							}}
							pagination={{
								el: '.pagination',
								type: 'fraction',
							}}
						>
							<div
								className="swiper-wrapper"
								style={{
									transform: 'translate3d(0px, -360px, 0px)',
									transitionDuration: '0ms',
								}}
							>
								{villRanking &&
									villRanking?.rankings.map((list, idx) => (
										<SwiperSlide>
											<p
												className="swiper-slide swiper-slide-duplicate"
												data-swiper-slide-index={9}
												style={{ height: 90 }}
												key={idx}
											>
												{list?.rank < 4 ? (
													<span className="num">
														<img
															src={
																list?.rank === 1
																	? ic_rank01
																	: list?.rank === 2
																		? ic_rank02
																		: list?.rank === 3
																			? ic_rank03
																			: ''
															}
															alt="랭킹 아이콘"
														/>
													</span>
												) : (
													list?.rank >= 4 && (
														<>
															<span className="num">{list?.rank}</span>
														</>
													)
												)}
												<span className="villname">
													{list?.town?.townName}{' '}
													<strong key={idx}>
														{' '}
														(
														{list?.town?.districts.map(
															(districtsList, districtsIndex) => (
																<span style={{ color: '#666666' }} key={districtsIndex}> {districtsList?.districtName} </span>
															)
														)}
														)
													</strong>
												</span>
												<span className="score">
													{' '}
													{list?.point.toLocaleString()} E
												</span>
											</p>
										</SwiperSlide>
									))}
							</div>
							<div className="swiper-button-wrap">
								<div
									className="swiper-button-prev"
									tabIndex={0}
									role="button"
									aria-label="Previous slide"
								/>
								<div
									className="swiper-button-next"
									tabIndex={0}
									role="button"
									aria-label="Next slide"
								/>
							</div>
							<span
								className="swiper-notification"
								aria-live="assertive"
								aria-atomic="true"
							/>
						</Swiper>
					</div>
				</div>
			</div>
			{(villMissionList && villMissionList.length != 0) ||
				(missionList && missionList.length != 0) ? (
				<div className="mission_wrap">
					<div className="tabs2 mb25">
						<button
							type="button"
							className={tab2 === 1 ? 'tab tab_active' : 'tab non_active'}
							onClick={() => setTab2(1)}
						>
							마을미션
						</button>
						<button
							type="button"
							className={tab2 === 2 ? 'tab tab_active' : 'tab non_active'}
							onClick={() => setTab2(2)}
						>
							기본미션
						</button>
					</div>
					{tab2 === 1 && (
						<div className="tab_content2">
							<ul>
								{villMissionList &&
									villMissionList.map((villList, villIndex) => (
										<li
											className={villList?.visible === 'N' ? 'non' : ''}
											onClick={() =>
												handleVillDetail(
													String(villList.mmiUid),
													String(villList?.mssnDe)
												)
											}
											key={villIndex}
										>
											<a className="pa30">
												{/* 이미지 없으면 noimg class 붙음 */}
												<div
													className={
														villList?.missionFiles?.length === 0
															? 'box_missionVillage noimg'
															: 'box_missionVillage'
													}
												>
													<div className="missionVillage_txt">
														<div className="state">
															<span
																className={
																	villList?.prgrsSt === '대기'
																		? 'prev'
																		: villList?.prgrsSt === '진행'
																			? 'ing'
																			: villList?.prgrsSt === '종료'
																				? 'end'
																				: ''
																}
															>
																{villList?.prgrsSt === '진행' &&
																	villList?.certPdDe === 'D' &&
																	Number(villList?.mxCertCo) -
																	Number(villList?.certCnt) >
																	0
																	? `매일 ${Number(villList?.mxCertCo) -
																	Number(villList?.certCnt)
																	}회 참여가능`
																	: villList?.prgrsSt === '진행' &&
																		villList?.certPdDe === 'D' &&
																		Number(villList?.mxCertCo) -
																		Number(villList?.certCnt) ===
																		0
																		? '인증완료'
																		: villList?.prgrsSt === '진행' &&
																			villList?.certPdDe === 'M' &&
																			Number(villList?.mxCertCo) -
																			Number(villList?.certCnt) >
																			0
																			? `매달 ${Number(villList?.mxCertCo) -
																			Number(villList?.certCnt)
																			}회 참여가능`
																			: villList?.prgrsSt === '진행' &&
																				villList?.certPdDe === 'M' &&
																				Number(villList?.mxCertCo) -
																				Number(villList?.certCnt) ===
																				0
																				? `인증완료`
																				: villList?.prgrsSt === '진행' &&
																					villList?.certPdDe === 'P' &&
																					Number(villList?.mxCertCo) -
																					Number(villList?.certCnt) >
																					0
																					? `기간 내 ${Number(villList?.mxCertCo) -
																					Number(villList?.certCnt)
																					}회 참여가능`
																					: villList?.prgrsSt === '진행' &&
																						villList?.certPdDe === 'P' &&
																						Number(villList?.mxCertCo) -
																						Number(villList?.certCnt) ===
																						0
																						? '인증완료'
																						: villList?.prgrsSt === '대기'
																							? '대기'
																							: villList?.prgrsSt === '종료'
																								? '마감'
																								: ''}
															</span>
															{villList?.mssnDe === '지도미션' && (
																<span className="state_number">지도미션</span>
															)}
														</div>
														<h4 className="comm_title txt_cut1 mt10">
															{villList?.mssnNm}
														</h4>
														<p className="txt_cut1">
															{' '}
															{`${moment(villList.startDt).format(
																'yyyy.MM.DD'
															)}`}
															{'~'}
															{`${moment(villList.endDt).format('yyyy.MM.DD')}`}
														</p>
														<p className="point">
															{villList?.pymntPt
																?.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</p>
													</div>
													<div className="missionVillage_img">
														{villList?.missionFiles?.length !== 0 ? (
															<>
																<img
																	src={
																		villList?.missionFiles &&
																		villList?.missionFiles[0]?.savePath &&
																		`/api/attach/getFile?savedPath=${villList.missionFiles[0]?.savePath}`
																	}
																	alt="지도 이미지"
																/>{' '}
															</>
														) : (
															<>
																{' '}
																<img src={errorImg} alt="지도 이미지" />
															</>
														)}
													</div>
												</div>
											</a>
										</li>
									))}
							</ul>
						</div>
					)}
					{tab2 === 2 && (
						<div className="tab_content2">
							<ul>
								{missionList &&
									missionList.map((list, index) => (
										<li
											className={list?.visible === 'N' ? 'non' : ''}
											onClick={() => handleDetail(String(list.mmiUid))}
											key={index}
										>
											<a className="pa30">
												{/* 이미지 없으면 noimg class 붙음 */}
												<div
													className={
														list?.missionFiles?.length === 0
															? 'box_missionVillage noimg'
															: 'box_missionVillage'
													}
												>
													<div className="missionVillage_txt">
														<div className="state">
															<span
																className={
																	list?.prgrsSt === '대기'
																		? 'prev'
																		: list?.prgrsSt === '진행'
																			? 'ing'
																			: list?.prgrsSt === '종료'
																				? 'end'
																				: ''
																}
															>
																{list?.prgrsSt === '진행' &&
																	list?.certPdDe === 'D' &&
																	Number(list?.mxCertCo) - Number(list?.certCnt) >
																	0
																	? `매일 ${Number(list?.mxCertCo) -
																	Number(list?.certCnt)
																	}회 참여가능`
																	: list?.prgrsSt === '진행' &&
																		list?.certPdDe === 'D' &&
																		Number(list?.mxCertCo) -
																		Number(list?.certCnt) ===
																		0
																		? '인증완료'
																		: list?.prgrsSt === '진행' &&
																			list?.certPdDe === 'M' &&
																			Number(list?.mxCertCo) -
																			Number(list?.certCnt) >
																			0
																			? `매달 ${Number(list?.mxCertCo) -
																			Number(list?.certCnt)
																			}회 참여가능`
																			: list?.prgrsSt === '진행' &&
																				list?.certPdDe === 'M' &&
																				Number(list?.mxCertCo) -
																				Number(list?.certCnt) ===
																				0
																				? `인증완료`
																				: list?.prgrsSt === '진행' &&
																					list?.certPdDe === 'P' &&
																					Number(list?.mxCertCo) -
																					Number(list?.certCnt) >
																					0
																					? `기간 내 ${Number(list?.mxCertCo) -
																					Number(list?.certCnt)
																					}회 참여가능`
																					: list?.prgrsSt === '진행' &&
																						list?.certPdDe === 'P' &&
																						Number(list?.mxCertCo) -
																						Number(list?.certCnt) ===
																						0
																						? '인증완료'
																						: list?.prgrsSt === '대기'
																							? '대기'
																							: list?.prgrsSt === '종료'
																								? '마감'
																								: '인증횟수 초과'}
															</span>
															<span className="state_number">
																{list?.redcnDe === '전력'
																	? '전력절감 인증미션'
																	: list?.redcnDe === '가스'
																		? '도시가스절감 인증미션'
																		: list?.redcnDe === '별도'
																			? '별도 인증미션'
																			: '절감 구분'}
															</span>
														</div>
														<h4 className="comm_title txt_cut1 mt10">
															{list?.mssnNm}
														</h4>
														<p className="txt_cut1">
															{' '}
															{`${moment(list.startDt).format('yyyy.MM.DD')}`}
															{'~'}
															{`${moment(list.endDt).format('yyyy.MM.DD')}`}
														</p>
														<p className="point">
															{list?.pymntPt
																?.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</p>
													</div>
													<div className="missionVillage_img">
														{list?.missionFiles?.length !== 0 ? (
															<>
																<img
																	src={
																		list?.missionFiles &&
																		list?.missionFiles[0]?.savePath &&
																		`/api/attach/getFile?savedPath=${list.missionFiles[0]?.savePath}`
																	}
																	alt="지도 이미지"
																/>{' '}
															</>
														) : (
															<>
																{' '}
																<img src={errorImg} alt="지도 이미지" />
															</>
														)}
													</div>
												</div>
											</a>
										</li>
									))}
							</ul>
						</div>
					)}
				</div>
			) : (
				<></>
			)}
		</>
	);
};
export default MainPage;
