import React from 'react';

import '../Login/LoginDialog.scss';
import { DialogProps, useDialog } from '../DialogProvider';
import Modify_ActiveMapDialog from '../activeMap/Modify_ActiveMapDialog';
import { useNavigate } from 'react-router';
import { idText } from 'typescript';
import Modify_VillageCaseDialog from '../infro/Modify_VillageCaseDialog';
// 댓글 또는 게시물 수정 팝업
interface ModifyDialog extends DialogProps {
	type?: '';
	tab: string;
	boardId?: string;
}
const ModifyDialog = ({ tab, boardId, ...props }: ModifyDialog) => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const handleClose = () => {
		props.onClose?.();
		return;
	};

	const handleConfirm = (tab: string, boardId?: string, tccUid?: number) => {
		// props.onClose?.();
		// console.log('tab:::::', tab);
		if (tab === 'activeMasterMap') {
			// 활동지도
			navigate('/commu_02/actMap_04-modify');
			props.onClose?.();
		} else if (tab === 'villageCommu') {
			// 소통마당
			navigate(`/commu_02/villageCm_04-modify?boardId=${boardId}`);
			props.onClose?.();
		} else if (tab === 'activeSubject') {
			// 활동일지 주제
			navigate('/commu_02/active_04-modify');
			props.onClose?.();
		} else if (tab === 'case') {
			showDialog(Modify_VillageCaseDialog);
		} else if (tab === 'mapMaster') {
			// 참여마당 > 마을미션 > 지도미션 주제 수정
			navigate('/reward_02/mapMission_04-modify');
			props.onClose?.();
		}
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>수정</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">수정 하시겠습니까?</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button
						type="submit"
						className="btn_check"
						onClick={() => handleConfirm(String(tab), String(boardId))}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModifyDialog;
