import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import MissionAPI from '../../apis/MissionAPI';

import ic_cam from '../../assets/ic_cam.png';
import ic_home from '../../assets/ic_home.png';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import MapMission from '../../models/MpaMission';
import '../missionYard/Write_MapMissionPage.scss';
export interface CategoryListInfo {
	color: string;
	title: string;
	marker: string;
	cmcUid?: number;
	// index: number;
}
const colorOption = [
	{ color: 'color01', value: 'src/marker/mk_1.png' },
	{ color: 'color02', value: 'src/marker/mk_2.png' },
	{ color: 'color03', value: 'src/marker/mk_3.png' },
	{ color: 'color04', value: 'src/marker/mk_4.png' },
	{ color: 'color05', value: 'src/marker/mk_5.png' },
	{ color: 'color06', value: 'src/marker/mk_6.png' },
	{ color: 'color07', value: 'src/marker/mk_7.png' },
	{ color: 'color08', value: 'src/marker/mk_8.png' },
	{ color: 'color09', value: 'src/marker/mk_9.png' },
	{ color: 'color10', value: 'src/marker/mk_10.png' },
	{ color: 'color11', value: 'src/marker/mk_11.png' },
	{ color: 'color12', value: 'src/marker/mk_12.png' },
	{ color: 'color13', value: 'src/marker/mk_13.png' },
	{ color: 'color14', value: 'src/marker/mk_14.png' },
	{ color: 'color15', value: 'src/marker/mk_15.png' },
]
const Write_MapMissionPage = () => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const inputRef = useRef<HTMLInputElement>(null);
	const [noDoubleClick, setNoDoubleClick] = useState(false);
	// 미션 이미지
	const [missionUploadFiles, setMissionUploadFiles] = useState([] as File[]);
	const [missionPreviewImages, setMissionPreviewImages] = useState<string[]>([]);
	// 인증예시 이미지
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [selectOpenYn, setSelectOpenYn] = useState(true);
	const [selectCertOpenYn, setSelectCertOpenYn] = useState<string>('Y');
	const [categoryList, setCategoryList] = useState([] as CategoryListInfo[]);
	const [colorOptionsOpen, setColorOptionsOpen] = useState(false);
	const [categoryTitle, setCategoryTitle] = useState<string>('');
	const [selectedColor, setSelectedColor] = useState<string>('color01');
	const [selectedMarker, setSelectedMarker] = useState<string>('src/marker/mk_1.png');
	const [insertOn, setInsertOn] = useState(false);
	const { register, handleSubmit, setValue, setFocus, getValues, watch } = useForm<MapMission>();

	const mssnNm = watch('mssnNm');
	const startDt = watch('startDt');
	const endDt = watch('endDt');
	const mssnDtl = watch('mssnDtl');
	const certDes = watch('certDes');
	const certPdDe = watch('certPdDe');
	const mxCertCo = watch('mxCertCo');
	const pymntPt = watch('pymntPt');

	useEffect(() => {
		if (!getValues('mssnNm')) {
			setInsertOn(false);
			return;
		} else if (!getValues('startDt')) {
			setInsertOn(false);
			return;
		} else if (!getValues('endDt')) {
			setInsertOn(false);
			return;
		} else if (!getValues('mssnDtl')) {
			setInsertOn(false);
			return;
		} else if (!getValues('certDes')) {
			setInsertOn(false);
			return;
		} else if (!getValues('certPdDe')) {
			setInsertOn(false);
			return;
		} else if (!getValues('mxCertCo')) {
			setInsertOn(false);
			return;
		} else if (!getValues('pymntPt')) {
			setInsertOn(false);
			return;
		} else if (categoryList.length == 0) {
			setInsertOn(false);
			return;
		}
		const stDt = new Date(getValues('startDt'))
		const enDt = new Date(getValues('endDt'))
		if (stDt > enDt) {
			setInsertOn(false);
			return;
		}

		setInsertOn(true);
	}, [mssnNm, startDt, endDt, mssnDtl, certDes, certPdDe, mxCertCo, pymntPt, categoryList])
	// 주민 비공개 여부
	const handleOpenYn = (e: any) => {
		if (e.target.checked) {
			// // console.log('비공개')
			setSelectOpenYn(false);
		} else {
			// // console.log('공개')
			setSelectOpenYn(true);
		}
	}
	// 인증 비공개 여부
	const handleCertOpenYn = (e: any) => {
		setSelectCertOpenYn(e.target.value);
	}
	// 미션 기본정보 이미지 파일 첨부
	const handleMissionImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (missionPreviewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 1개 까지 업로드할수있습니다.');
			const title = '';
			const message = '사진은 최대 1개 까지 업로드할수있습니다';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setMissionUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setMissionPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 실천인증 방법 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 1개 까지 업로드할수있습니다.');
			const title = '';
			const message = '사진은 최대 1개 까지 업로드할수있습니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 미션 기본정보 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleMissionRemovePreview = (index: number) => {
		setMissionPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setMissionUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 실천인증 방법 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};

	// 분류 추가버튼 
	const handleAddCate = () => {
		if (categoryTitle === '') {
			alert('분류명을 입력해주세요');
			return;
		}
		setCategoryList((cur) => [...cur, {
			color: selectedColor,
			title: categoryTitle,
			marker: selectedMarker
		}])
		setSelectedColor('color01');
		setSelectedMarker('src/marker/mk_1.png');
		setCategoryTitle('');
	}
	// 분류 삭제 버튼
	const handleDeleteCate = (index: number) => {
		setCategoryList((cate) => {
			const newCate = [...cate];
			newCate.splice(index, 1);
			return newCate;
		});
	}
	const selectedColorOption = (color: string, marker: string) => {
		setSelectedColor(color);
		setColorOptionsOpen(false);
		setSelectedMarker(marker);
	}

	const onSubmit: SubmitHandler<MapMission> = async (data) => {
		setNoDoubleClick(true);
		if (!getValues('mssnNm')) {
			alert('미션명을 입력해주세요')
			setFocus('mssnNm')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('startDt')) {
			alert('미션기간을 설정해주세요')
			setFocus('startDt')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('endDt')) {
			alert('미션기간을 설정해주세요')
			setFocus('endDt')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('mssnDtl')) {
			alert('미션설명을 입력해주세요')
			setFocus('mssnDtl')
			setNoDoubleClick(false);
			return;
		} else if (categoryList.length == 0) {
			alert('분류를 생성해주세요')
			inputRef?.current?.focus();
			setNoDoubleClick(false);
			return;
		} else if (!getValues('certDes')) {
			alert('실천인증방법 설명을 입력해주세요')
			setFocus('certDes')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('certPdDe')) {
			alert('인증제한 기간 및 횟수를 설정해주세요')
			setFocus('certPdDe')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('mxCertCo')) {
			alert('인증제한 기간 및 횟수를 설정해주세요')
			setFocus('mxCertCo')
			setNoDoubleClick(false);
			return;
		} else if (!getValues('pymntPt')) {
			alert('1회 실천 시 지급할 포인트를 설정해주세요')
			setFocus('pymntPt')
			setNoDoubleClick(false);
			return;
		}
		const stDt = new Date(data.startDt)
		const enDt = new Date(data.endDt)
		if (stDt > enDt) {
			alert('미션기간이 잘못 설정되었습니다');
			setFocus('endDt')
			setNoDoubleClick(false);
			return;
		}
		const resultStartDateTime = `${data.startDt} 00:00:00`;
		const resultEndDateTime = `${data.endDt} 23:00:00`;
		MissionAPI.insertMapMission({
			...data,
			mssnDe: '지도미션',
			certLnk: data.certLnk ? data.certLnk : undefined,
			// visible: selectOpenYn ? 'Y' : 'N',
			startDt: resultStartDateTime,
			endDt: resultEndDateTime,
			certOpenYn: selectCertOpenYn
		}, categoryList,
			missionUploadFiles ? missionUploadFiles : undefined,
			uploadFiles ? uploadFiles : undefined
		).then((res) => {
			// // console.log("등록 후 콘솔로끄", res);
			navigate('/reward_02/villageMission_03-list');
			setNoDoubleClick(false);
			alert('미션이 추가되었습니다');
			window.location.reload();
		}).catch((error) => {
			alert('미션 추가실패');
			setNoDoubleClick(false);
		})
	}


	useEffect(() => {
		// // console.log("분류 목록 >>>> ", categoryList)
	}, [categoryList])
	return (
		<main className="Write_MapMissionPage">
			{/* 등록 후 로딩 */}
			<div className="overlay" style={{ display: noDoubleClick ? "block" : "none" }}></div>
			<div className="loading-container" style={{ display: noDoubleClick ? "block" : "none" }}>
				<div className="loading"></div>
			</div>
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<div className="lnb_menu">
				</div>
				<div className="lnb_txt">
					<h3>지도미션 만들기</h3>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* 미션정보 시작 */}
						<p className="comm_title mb25">미션 기본정보</p>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">미션명</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="미션명을 입력하세요"
										{...register('mssnNm')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">미션기간</h3>
								<div className="cont-box">
									<div className="date_box period">
										<input
											type="date"
											data-placeholder="시작일시"
											{...register('startDt')}
										/>
										<input
											type="date"
											data-placeholder="종료일시"
											className="endDate"
											{...register('endDt')}
										/>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">미션설명</h3>
								<div className="cont-box">
									<textarea placeholder="설명을 입력하세요" defaultValue={''} {...register('mssnDtl')} />
								</div>
							</div>
							<div className="cont-wrap">
								<h3>미션 이미지</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={ic_cam} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleMissionImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul className="no-scroll">
											{missionPreviewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleMissionRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							{/* <div className="cont-wrap">
								<h3>상태</h3>
								<div className="cont-box">
									<p className="listSelect">
										<label>
											<input
												type="checkbox"
												name="nicks"
												defaultValue="ns"
												onChange={handleOpenYn}
											/>
											<span>주민 비공개</span>
										</label>
									</p>
								</div>
							</div> */}
							<div className="cont-wrap">
								<h3 className="important">분류 생성</h3>
								<div className="cont-box">
									<div className="partmake">
										<input
											type="text"
											placeholder="분류명 (최대10자)"
											className="short"
											ref={inputRef}
											value={categoryTitle}
											onChange={(e) => setCategoryTitle(e.target.value)}
										/>
										<button type="button" className="btn_color" onClick={() => setColorOptionsOpen(!colorOptionsOpen)}>
											{/* class마다 색넣어집니다 */}
											<span className={selectedColor} />
										</button>
										<button type="button" className="btn_black" onClick={handleAddCate}>
											추가
										</button>
										<div className="color_popup" style={{ display: colorOptionsOpen ? 'block' : 'none' }}>
											<ul>
												{colorOption.map((color, index) => (
													<li key={index} onClick={() => selectedColorOption(color.color, color.value)}>
														<p>
															<input
																type="radio"
																name="color"
																value={color.value}
															// defaultChecked
															/>
															<label className={color.color} />
														</p>
													</li>
												))}
											</ul>
										</div>
									</div>
									<div className="color_table mt25">
										<ul className="thead pa20">
											<li>색상</li>
											<li>분류명</li>
											<li>관리</li>
										</ul>
										<ul className="tbody">
											{categoryList.map((list, index) => (
												<li className="pa20" key={index}>
													<p>
														<span className={list.color} />
													</p>
													<p>{list.title}</p>
													<p>
														<button type="button" className="btn_red" onClick={() => handleDeleteCate(index)}>
															삭제
														</button>
													</p>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						{/* 미션정보 종료 */}
						{/* 인증방법 시작 */}
						<p className="comm_title mb25 mt40">실천인증 방법</p>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">인증방법 설명</h3>
								<div className="cont-box">
									<textarea placeholder="내용을 입력하세요" defaultValue={''} {...register('certDes')} />
								</div>
							</div>
							<div className="cont-wrap">
								<h3>인증관련 링크</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="링크주소를 입력하세요"
										{...register('certLnk')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>인증예시 이미지</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img2">
											<img src={ic_cam} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img2"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul className="no-scroll">
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
							{/* <div className="cont-wrap">
								<h3 className="important">인증공개 유무</h3>
								<div className="cont-box">
									<ul className="village_list">
										<li>
											<label htmlFor="radio11-1">
												<input
													type="radio"
													id="radio11-1"
													name="radio11"
													value='Y'
													onChange={(e) => handleCertOpenYn(e)}
													defaultChecked
												/>
												<p>공개</p>
											</label>
										</li>
										<li>
											<label htmlFor="radio11-2">
												<input
													type="radio"
													id="radio11-2"
													name="radio11"
													value='N'
													onChange={(e) => handleCertOpenYn(e)}
												/>
												<p>비공개</p>
											</label>
										</li>
									</ul>
									<span className="explain">
										※ 인증 비공개시 회원은 자신이 인증한 글만 확인할 수
										있습니다.{' '}
									</span>
								</div>
							</div> */}
							<div className="cont-wrap">
								<h3 className="important">인증제한</h3>
								<div className="cont-box">
									<div className="select-box">
										<select {...register('certPdDe')}>
											<option value=''>선택(기간)</option>
											<option value='D'>매일</option>
											<option value='M'>매달</option>
											<option value='P'>기간내</option>
										</select>
										<select {...register('mxCertCo')}>
											<option value=''>선택(횟수)</option>
											<option value={1}>1</option>
											<option value={2}>2</option>
											<option value={3}>3</option>
											<option value={4}>4</option>
											<option value={5}>5</option>
											<option value={6}>6</option>
											<option value={7}>7</option>
											<option value={8}>8</option>
											<option value={9}>9</option>
											<option value={10}>10</option>
										</select>
										회까지 인증 가능
									</div>
								</div>
							</div>
						</div>
						{/* 인증방법 종료 */}
						{/* 포인트설정 시작 */}
						<p className="comm_title mb25 mt40">포인트 설정</p>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">
									1회 실천 시<br />
									지급포인트 (E)
								</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="숫자만 입력"
										{...register('pymntPt')}
										onChange={(e) => {
											const value = e.target.value.replace(/[^0-9]/g, '');
											setValue('pymntPt', value);
										}}
									/>
								</div>
							</div>
						</div>
						{/* 포인트설정 종료 */}
						<div className="comm_btn_wrap">
							<button type="button" className="btn_cancel">
								취소
							</button>
							<button type="submit" className={`btn_next ${insertOn ? 'on' : ''}`}>만들기</button>
							{/* 모두 체크 시 on클래스 붙음*/}
						</div>
					</form>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Write_MapMissionPage;
