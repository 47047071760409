import React, { useEffect, useState } from 'react';
import './UpdateCharacterDialog.scss';
import character01 from '../../assets/character01.png';
import character02 from '../../assets/character02.png';
import character03 from '../../assets/character03.png';
import character04 from '../../assets/character04.png';
import character05 from '../../assets/character05.png';
import character06 from '../../assets/character06.png';
import MemberAPI from '../../apis/MemberAPI';
import { DialogProps, useDialog } from '../DialogProvider';
import TwoButtonAlertDialog from '../TwoButtonAlertDialog';
import useMember from '../../hooks/useMember';
import MissionAPI from '../../apis/MissionAPI';
import { GetEnergy } from '../../models/MissionInfo';
interface UpdateCharacterDialogProps extends DialogProps { }
const UpdateCharacterDialog = ({ ...props }: UpdateCharacterDialogProps) => {
	const { member, getMemInfo } = useMember();
	const { showDialog } = useDialog();
	const [selectCharacter, setSelectCharacter] = useState<number>();

	const handleClose = () => {
		props.onClose?.();
	};

	const handleSelectCharacter = (e: any) => {
		setSelectCharacter(e.target.value);
		const title = '';
		const message = '사용할 캐릭터를 변경하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					MemberAPI.updateMember({
						character: Number(e.target.value),
					}).then((res) => {
						MemberAPI.getUser().then((res) => {
							// console.log(res.character);
							getMemInfo(res);
							// console.log(res);
							window.location.reload();
						});
					});
				} else {
					return;
				}
			},
		});
	};
	const [getEnergy, setGetEnergy] = useState<GetEnergy>();
	const [level, setLevel] = useState<number>(0);
	useEffect(() => {
		MissionAPI.getEnergy({
			tcUid: Number(member?.townDTO?.townId),
			currentMonth: 'energy',
		}).then((res) => {
			// console.log('이달의 내 에너지 절감량', res);
			setGetEnergy(res.data);
		});
	}, []);

	useEffect(() => {
		if (getEnergy?.redcnToe) {
			if (0 < Number(getEnergy?.redcnToe) && Number(getEnergy?.redcnToe) <= 8) {
				setLevel(4);
			}
			if (
				8 < Number(getEnergy?.redcnToe) &&
				Number(getEnergy?.redcnToe) <= 300
			) {
				setLevel(3);
			}
			if (
				300 < Number(getEnergy?.redcnToe) &&
				Number(getEnergy?.redcnToe) <= 700
			) {
				setLevel(2);
			}
			if (700 < Number(getEnergy?.redcnToe)) {
				setLevel(1);
			}
		}
	}, [getEnergy]);
	// console.log('level :::::::', level);

	return (
		<div className="UpdateCharacterDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>내 캐릭터 현황 및 설정</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<div className="comm_popup_content mt25">
						<form>
							<div className="character_list">
								<ul>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-1"
												name="shop"
												value={1}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 1}
											/>
											<label htmlFor="select1-1" className="pa20">
												<img src={character01} alt="등급별 이미지" />
												<p className="mt10">기본</p>
											</label>
										</div>
									</li>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-2"
												name="shop"
												value={2}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 2}
											/>
											<label htmlFor="select1-2" className="pa20">
												<img src={character02} alt="등급별 이미지" />
												<p className="mt10">기본</p>
											</label>
										</div>
									</li>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-3"
												name="shop"
												value={3}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 3}
												disabled
											/>
											<label htmlFor="select1-3" className="pa20">
												<img src={character03} alt="등급별 이미지" />
												<p className="mt10">4등급</p>
											</label>
										</div>
									</li>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-4"
												name="shop"
												value={4}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 4}
												disabled
											/>
											<label htmlFor="select1-4" className="pa20">
												<img src={character04} alt="등급별 이미지" />
												<p className="mt10">3등급</p>
											</label>
										</div>
									</li>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-5"
												name="shop"
												value={5}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 5}
												disabled
											/>
											<label htmlFor="select1-5" className="pa20">
												<img src={character05} alt="등급별 이미지" />
												<p className="mt10">2등급</p>
											</label>
										</div>
									</li>
									<li>
										<div className="character_box">
											<input
												type="radio"
												id="select1-6"
												name="shop"
												value={6}
												onClick={(e) => handleSelectCharacter(e)}
												checked={member?.character == 6}
												disabled
											/>
											<label htmlFor="select1-6" className="pa20">
												<img src={character06} alt="등급별 이미지" />
												<p className="mt10">1등급</p>
											</label>
										</div>
									</li>
								</ul>
							</div>
						</form>
						<span className="explain mt10">
							※ 달성한 캐릭터와 이달의 내 총 에너지 절감량은 매월 1일
							초기화됩니다.
						</span>
						<div className="saving_my pa20 mt25">
							<h4>이달의 내 에너지 절감량</h4>
							{getEnergy ? (
								<p>
									<span>{getEnergy?.redcnToe}</span>10<sup>-3</sup>TOE
								</p>
							) : (
								<p>이달의 에너지 절감량이 없습니다.</p>
							)}
						</div>

						<div className="saving_table mt25">
							<ul className="thead pa20">
								<li>구분</li>
								<li>총 에너지 절감량(TOE)</li>
							</ul>
							<ul className="tbody">
								<li
									// className="pa20"
									className={level && level === 1 ? 'pa20 on' : 'pa20'}
								>
									<p>1등급</p>
									<p>700 &lt; 에너지 절감량</p>
								</li>
								<li className={level && level === 2 ? 'pa20 on' : 'pa20'}>
									<p>2등급</p>
									<p>300 &lt; 에너지 절감량 ≤ 700</p>
								</li>
								<li className={level && level === 3 ? 'pa20 on' : 'pa20'}>
									<p>3등급</p>
									<p>8 &lt; 에너지 절감량 ≤ 300</p>
								</li>
								<li className={level && level === 4 ? 'pa20 on' : 'pa20'}>
									<p>4등급</p>
									<p>0 ≤ 에너지 절감량 ≤ 8</p>
								</li>
							</ul>
						</div>
						{/* <span className="explain mt10">
                            ※ 달성한 캐릭터와 이달의 내 총 에너지 절감량은 매월 1일 초기화됩니다.
                        </span> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateCharacterDialog;
