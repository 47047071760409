import { useEffect, useState } from 'react';
import useMember from '../../hooks/useMember';

// 소통마당 > 탭

const CommTab = ({ clickTab }: { clickTab: number }) => {
	const townId = sessionStorage.getItem('town');
	const { member } = useMember();
	const [tabTitle, setTabTitle] = useState(clickTab);
	const [generalRoles, setGeneralRoles] = useState(false);
	const [managerRoles, setManagerRoles] = useState(false);
	const [subManagerRoles, setSubManagerRoles] = useState(false);
	const [wHeight, setWHeight] = useState<number>(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			// 창 크기 변경 시 호출되는 함수
			setWHeight(window.innerWidth);
		};

		// 이벤트 리스너 등록
		window.addEventListener('resize', handleResize);

		// 컴포넌트가 언마운트될 때 이벤트 리스너 제거
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	useEffect(() => {
		// console.log("wHeight >>>>> ", wHeight)
	}, [wHeight])
	// 권한 검사
	useEffect(() => {
		if (member) {
			if (member.roles === "AMN") {
				// console.log("권한 : 플랫폼관리자");
				setGeneralRoles(true);
				// setNewsCateRoles(true);
			} else if (member.roles === "MST" || member.roles === "SMT") {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO.townId === Number(townId)) {
					// console.log("권한 : 마을운영자, 마을부운영자");
					setManagerRoles(true);
					// setNewsCateRoles(true);
				} else {
					setManagerRoles(false);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log("펄쓰")
				setGeneralRoles(false);
				setManagerRoles(false);
			}
		}
	}, [townId])

	useEffect(() => {
		// console.log("dasdsadsa", window.innerWidth)
	}, [])
	return (
		<div className="comm_tab no-scroll">
			<ul>
				<li>
					{/* 선택된 탭 on 클래스 붙음 */}
					<a
						href="/commu_02/villageCm_03"
						className={tabTitle == 1 ? 'on' : ''}
						onClick={() => setTabTitle(1)}
					>
						마을소통
					</a>
				</li>
				<li>
					<a
						href="/commu_02/actMap_03"
						className={tabTitle == 2 ? 'on' : ''}
						onClick={() => setTabTitle(2)}
					>
						활동지도
					</a>
				</li>
				<li>
					<a
						href="/commu_02/active_03"
						className={tabTitle == 3 ? 'on' : ''}
						onClick={() => setTabTitle(3)}
					>
						활동일지
					</a>
				</li>
				<li>
					<a
						href="/commu_02/eduProgram_03"
						className={tabTitle == 4 ? 'on' : ''}
						onClick={() => setTabTitle(4)}
					>
						{wHeight >= 600 ? '교육프로그램'
							:
							(<>교육프로그램</>)
						}
					</a>
				</li>
				<li>
					<a
						href="/commu_02/intro_03"
						className={tabTitle == 5 ? 'on' : ''}
						onClick={() => setTabTitle(5)}
					>
						마을소개
					</a>
				</li>
				{(generalRoles || managerRoles) &&
					<li>
						<a
							href="/commu_02/villageManage_03"
							className={tabTitle == 6 ? 'on' : ''}
							onClick={() => setTabTitle(6)}
						>주민관리
						</a>
					</li>
				}
			</ul>
		</div>
	);
};

export default CommTab;
