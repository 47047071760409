import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Pagination from '../../../components/common/Pagination';
import CommTab from '../CommTab';

import './List01_ActivePage.scss';
import test04 from '../../../assets/test04.png';
import NavComponent from '../../../components/common/NavComponent';
import useMember from '../../../hooks/useMember';
import { useDialog } from '../../../dialog/DialogProvider';
import PrivateDialog from '../../../dialog/common/PrivateDialog';
import { ActivityLogList, Content } from '../../../models/ActivityLog';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import noList from '../../../assets/noList.png';
import moment from 'moment';
// 소통마당 > 활동일지 주제목록
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];
const List01_ActivePage = () => {
	const townId = sessionStorage.getItem('town');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const { member } = useMember();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(1);
	const [activeSubList, setActiveSubList] = useState<Content[] | undefined>();
	const [totalCount, setTotalCount] = useState<number>(0);
	const [searchKeyword, setSearchKeyword] = useState<string>();
	const [search, setSearch] = useState<string>();
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	// 글 권한
	const [postRoles, setPostRoles] = useState(false);
	// 글, 댓글 비공개 권한
	const [praviteRoles, setPraviteRoles] = useState(false);
	// 마을뉴스 카테고리 등록 권한
	const [newsCateRoles, setNewsCateRoles] = useState(false);

	//권한 별 글 및 댓글 등록, 수정, 삭제 마을뉴스 카테고리 등록 권한 아직 테스트 못함 2023.09.05
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setPostRoles(true);
				setPraviteRoles(true);
				// setNewsCateRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setPostRoles(true);
					setPraviteRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log('권한 : 마을활동가, 마을주민');
				// if(member.townId === townId && member.userId === crtUid){
				// setPostRoles(true);
				// }
			}
		}
	}, []);

	useEffect(() => {
		const nowDate = new Date();
		ActivityLogAPI.getSubjectList({
			page: page - 1,
			size: 4,
			townId: Number(townId),
			keyword: searchKeyword ? searchKeyword : undefined,
			sort: 'createdAt,DESC',
		})
			.then((res) => {
				// console.log('활동일지 주제 목록 >>>> ', res);
				const promises = res.content.map((list, index) => {
					const formattedStartDate = `${list.startedAt[0]}.${String(
						list.startedAt[1]
					).padStart(2, '0')}.${String(list.startedAt[2]).padStart(2, '0')}`;
					const formattedEndDate = `${list.endedAt[0]}.${String(
						list.endedAt[1]
					).padStart(2, '0')}.${String(list.endedAt[2]).padStart(2, '0')}`;
					const startDate = new Date(formattedStartDate);
					const endDate = new Date(formattedEndDate);
					// console.log('엔드 데이트', endDate);
					setTotalCount(res.totalElements);
					if (nowDate > endDate) {
						list.status = 'end';
					} else if (nowDate >= startDate && nowDate <= endDate) {
						list.status = 'ing';
					} else {
						list.status = 'prev';
					}
					list.subjectFileList.map((list2, index2) => {
						list.thumbnail = list2.filePath;
					});
					list.startDate = formattedStartDate;
					list.endDate = formattedEndDate;
					// console.log('뭐임', formattedStartDate);
					return ActivityLogAPI.getJournalList({
						page: 0,
						size: 3,
						subjectId: list.subjectId,
						sort: 'createdAt,DESC',
					})
						.then((res2) => {
							// console.log('쩌널리스트', res2.content);
							list.journalList = res2.content;
							// console.log('쩌널리스트12', list.journalList);
						})
						.catch((error) => {
							list.journalList = [];
						});
				});
				return Promise.all(promises).then(() => res.content);
				// res.content.map((list) => {
				// 	// console.log("서브잭", list);
				// })
				// // // console.log("서브잭", res);
			})
			.then((subjectList) => {
				setActiveSubList(subjectList);
			})
			.catch((error) => {
				// console.log(error);
				setActiveSubList(undefined);
			});
	}, [page, searchKeyword]);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// 주제내역 이동
	const handleHistory = (subjectId: number) => {
		sessionStorage.setItem('subject', String(subjectId));
		navigate('/commu_02/active_03-history');
	};
	// 등록하기
	const handleWrite = () => {
		navigate('/commu_02/active_04-write');
	};
	// 검색창 엔터
	const handleEnterSearch = (e: any) => {
		if (e.key === 'Enter') {
			setSearchKeyword(search);
		}
	};
	// 검색 버튼클릭
	const handleClickSearch = () => {
		setSearchKeyword(search);
	};
	return (
		<main className="List01_ActivePage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={3} />
					<div className="village_mapList">
						<div className="comm_search mt40">
							<ul>
								<li>
									<div className="comm_search_plus">
										<input
											type="text"
											placeholder="검색어를 입력해주세요."
											onChange={(e) => setSearch(e.target.value)}
											onKeyPress={handleEnterSearch}
										/>
									</div>
									<button type="button" onClick={handleClickSearch}>
										검색
									</button>
								</li>
							</ul>
						</div>
						<div className="comm_tool mt25">
							<button
								type="button"
								className="comm_write_button"
								onClick={handleWrite}
								style={{ display: postRoles ? 'block' : 'none' }}
							>
								주제등록
							</button>
						</div>
						<div className="village_actList_list mt25">
							<ul>
								{/* 비공개 게시물 non클래스 붙음 */}
								{activeSubList && activeSubList?.length == 0 ? (
									<div className="comm_noList">
										<img src={noList} alt="내용없음 이미지" />
										<p className="mt25">{nullListMessage}</p>
									</div>
								) : (
									activeSubList?.map((list, index) => (
										<li
											key={index}
											className={list.visible === 'N' ? 'non' : ''}
										>
											{/* 일지주제 시작 */}

											<div
												className="actList_title pa30"
												style={{ cursor: 'pointer' }}
												onClick={() => handleHistory(list.subjectId)}
											>
												{list && list?.subjectFileList.length > 0 && (
													<div style={{ height: '75px' }}>
														<img
															src={`/api/attach/getFile?savedPath=${list &&
																list?.subjectFileList[0].filePath
																}`}
															// src={noList}
															alt="활동주제 이미지"
															// className="mr10"
															style={{
																marginRight: '10px',
																width: '80px',
																height: '80px'
															}}
														/>
													</div>
												)}
												<div className="title_txt">
													<h3 className="comm_title txt_cut1">{list.title}</h3>
													<h4 className='txt_cut1' style={{ color: '#999' }}>{list.content}</h4>
													<p className="mt10">
														{list.startDate} ~ {list.endDate}
													</p>
												</div>
												<a className="title_link">바로가기</a>
											</div>
											{/* 일지주제 종료 */}
											{/* 일지내용 시작 */}
											{/* {list.journalList && list.journalList.length != 0 ? (
												list.journalList.map((jList, jIndex) => (
													<div className="actList_content pa30">
														<h4 className="comm_title txt_cut1">
															{jList.content}
														</h4>
														<div className="info01 mt10">
															<p
																className={
																	jList.character === 1
																		? 'name basic1'
																		: jList.character === 2
																		? 'name basic2'
																		: jList.character === 3
																		? 'name Lv1'
																		: jList.character === 4
																		? 'name Lv2'
																		: jList.character === 5
																		? 'name Lv3'
																		: jList.character === 6
																		? 'name Lv4'
																		: ''
																}
															>
																{jList.nickname}
															</p>
															<p>
																{moment(jList.createdAt).format('yyyy.MM.DD')}
															</p>
														</div>
														<div className="info02 mt10">
															<p className="ic_comm_heart">{jList.likeCnt}</p>
															<p className="ic_comm_comment">
																{jList.commentCnt}
															</p>
														</div>
													</div>
												))
											) : (
												<div className="comm_noList">
													<img src={noList} alt="내용없음 이미지" />
													<p className="mt25">{nullListMessage}</p>
												</div>
											)} */}
											{/* 일지내용 종료 */}
										</li>
									))
								)}
							</ul>
						</div>
						<Pagination
							page={page}
							// totalPageCount={10}
							totalPageCount={Math.ceil(totalCount / 4)}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};
// const Nav = ({
// 	townList,
// 	changeTownList,
// 	selectedTown,
// 	townInfo,
// }: {
// 	townList: content[];
// 	changeTownList: (townId: number) => void;
// 	selectedTown: number | undefined;
// 	townInfo: content[];
// }) => {
// 	return (
// 		<nav id="lnb">
// 			<h2 className="notext">네비게이션 영역</h2>
// 			<SubLocation titleText1="소통마당" liList={SubLocationList} />
// 			<div className="lnb_txt">
// 				<h3>소통마당</h3>
// 				<CommSelect
// 					townList={townList}
// 					changeTownList={changeTownList}
// 					selectedTown={selectedTown}
// 				/>
// 				{townInfo &&
// 					townInfo.map((list, idx) => (
// 						<p key={idx}>
// 							{list.townTitle}
// 							{/* {idx2 !== list.districtDTOList.length - 1 && ','} */}
// 						</p>
// 					))}
// 				{/* {townInfo &&
// 					townInfo.map((list, idx) => (
// 						<p key={idx}>
// 							{list.districtDTOList.map((list2, idx2) => (
// 								<span key={list2.districtId}>
// 									{list.townTitle}
// 									{idx2 !== list.districtDTOList.length - 1 && ','}
// 								</span>
// 							))}
// 						</p>
// 					))} */}
// 			</div>
// 		</nav>
// 	);
// };
export default List01_ActivePage;
