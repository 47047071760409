import React, { useState } from 'react';

const CommSearch = ({
	handleSearchKeyword,
}: {
	handleSearchKeyword?: (search: string) => void;
}) => {
	const [search, setSearch] = useState<string>('');
	const getSearchKeyword = (e: any) => {
		if (handleSearchKeyword) {
			handleSearchKeyword(search);
		}
		if (e.key === 'Enter') {
			if (handleSearchKeyword) {
				handleSearchKeyword(search);
			}
		}
	};
	return (
		// <div className="input_search">
		// 	<input
		// 		type="text"
		// 		placeholder="검색어를 입력하세요"
		// 		onChange={(e) => setSearch(e.target.value)}
		// 		onKeyPress={getSearchKeyword}
		// 	/>
		// 	<button type="button" className="btn_search" onClick={getSearchKeyword}>
		// 		검색
		// 	</button>
		// </div>

		<li>
			<div className="comm_search_plus">
				<input
					type="text"
					placeholder="검색어를 입력해주세요."
					onChange={(e) => setSearch(e.target.value)}
					onKeyPress={getSearchKeyword}
				/>
			</div>
			<button type="button" onClick={getSearchKeyword}>
				검색
			</button>
		</li>
	);
};

export default CommSearch;
