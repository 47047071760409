import React from 'react';
import { DialogProps } from '../DialogProvider';
import './AdminPage_VillPointDialog.scss'
interface AdminPage_VillPointDialog extends DialogProps {
    type: '';

}
const AdminPage_VillPointDialog = ({ ...props }: AdminPage_VillPointDialog) => {

    const handleClose = () => {
        props.onClose?.();
    }
    return (
        <div className='AdminPage_VillPointDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title mb25">
                        <h3>마을포인트 한도 설정</h3>
                        <a className="popup_close" onClick={handleClose}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC" alt="닫기" />
                        </a>
                    </div>
                    <form>
                        <div className="comm_popup_content comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">연도</h3>
                                <div className="cont-box">
                                    <div className="select-box">
                                        <select>
                                            <option>2023년</option>
                                            <option>2022년</option>
                                            <option>2021년</option>
                                            <option>2020년</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">첨단마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">수완마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">비아마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">머굴마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">소원마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">동밖마실동명마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">풍암마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">양3마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">서창마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">양림마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">사직마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">방림마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">일곡마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">문산마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">삼각마을</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="숫자만 입력" />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>전체</h3>
                                <div className="cont-box">
                                    <input type="text" className="long readonly" placeholder="자동 입력" readOnly />
                                </div>
                            </div>
                        </div>
                        <div className="comm_popup_btn mt25">
                            <button type="button" className="btn_cancel" onClick={handleClose}>취소</button>
                            <button type="submit" className="btn_check">저장</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default AdminPage_VillPointDialog;