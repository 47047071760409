import React, { PropsWithChildren, useContext, useState } from 'react';

import TimeUtil from '../utils/TimeUtil';
import LoginDialog from './Login/LoginDialog';
import JoinAgreeDialog from './Join/JoinAgreeDialog';

/**

 *
 * @example
 * // 1. 컴포넌트에 사용할 Props는 DialogProps를 확장한 형태로 정의
 * interface Props extends DialogProps { ... }
 *
 * // 2. 컴포넌트의 최상위 레이아웃에 props 넘겨주기
 * const Component = ({ ...props }: Props) => {
 *   return (
 *     <div {...props}>
 *     </div>
 *   )
 * }
 * @end
 *
 * 전역에서 팝업을 관리하기 위한 컴포넌트
 */

const DialogProvider = ({ children }: PropsWithChildren) => {
	const [dialogs, setDialogs] = useState([] as Dialog[]);

	const typeDiff = (length: number) => {
		if (
			String(sessionStorage.getItem(`typeData${length}`)) ===
			String(LoginDialog)
		) {
			// 로그인 팝업
			return LoginDialog;
		}
		// else if (
		// 	String(sessionStorage.getItem(`typeData${length}`)) ===
		// 	String(JoinAgreeDialog)
		// ) {
		// 	// 약관동의 팝업
		// 	return JoinAgreeDialog;
		// }
	};

	const showDialog: ShowDialogFn = async (type, props) => {
		const dialog: Dialog = {
			id: dialogs.length,
			type: type as React.ComponentType<DialogProps>,
			props: props,
			isOpen: false,
		};
		const newArr = [...dialogs, dialog];
		setDialogs(newArr);
		sessionStorage.setItem(`typeData${newArr.length - 1}`, String(type));

		await TimeUtil.delay(0);
		const openedDialog: Dialog = {
			...dialog,
			isOpen: true,
		};
		setDialogs([...newArr.slice(0, -1), openedDialog]);

		if (sessionStorage.getItem('dialogDepth')) {
			sessionStorage.removeItem('dialogDepth');
			sessionStorage.setItem(
				'dialogDepth',
				JSON.stringify([...newArr.slice(0, -1), openedDialog])
			);
		} else {
			sessionStorage.setItem(
				'dialogDepth',
				JSON.stringify([...newArr.slice(0, -1), openedDialog])
			);
		}

		// alert(JSON.stringify([...newArr.slice(0, -1), openedDialog]));
	};

	const handleClose = async (id: number, all?: any) => {
		const dialog = dialogs.find((dialog) => dialog.id === id);
		if (!dialog) {
			return;
		}
		// console.log(id);

		const closedDialog: Dialog = {
			...dialog,
			isOpen: false,
		};
		const newArr = dialogs.filter((dialog) => dialog.id !== id);
		setDialogs([...newArr, closedDialog]);

		await TimeUtil.delay(200);
		setDialogs(newArr);
		if (sessionStorage.getItem('dialogDepth')) {
			let tempDialogs: Dialog[] = JSON.parse(
				sessionStorage.getItem('dialogDepth') as string
			);
			let length = tempDialogs.length - 1;
			sessionStorage.removeItem(`typeData${length}`);
			tempDialogs.pop();
			if (tempDialogs.length >= 1) {
				sessionStorage.setItem('dialogDepth', JSON.stringify(tempDialogs));
			} else {
				sessionStorage.removeItem('dialogDepth');
			}
		}
		if (all) {
			setDialogs([]);
			sessionStorage.removeItem('dialogDepth');
		}
	};

	const dialogSearch = async () => {
		if (sessionStorage.getItem('dialogDepth')) {
			let tempDialogs: Dialog[] = JSON.parse(
				sessionStorage.getItem('dialogDepth') as string
			);
			let arrLeng = tempDialogs.length - 1;
			sessionStorage.removeItem(`typeDate${arrLeng}`);
			tempDialogs.pop();
			if (tempDialogs.length >= 1) {
				sessionStorage.removeItem('dialogDepth');
				sessionStorage.setItem('dialogDepth', JSON.stringify(tempDialogs));
				let typeCase = typeDiff(tempDialogs.length - 1);
				let insertDialog: Dialog = {
					...tempDialogs[0],
					type: typeCase as unknown as React.ComponentType<DialogProps>,
				};
				setDialogs([insertDialog]);
			} else {
				sessionStorage.removeItem('dialogDepth');
				setDialogs([]);
			}
		}
	};

	return (
		<DialogContext.Provider value={{ showDialog, handleClose, dialogSearch }}>
			{dialogs.map((dialog) => (
				<dialog.type
					key={dialog.id}
					onClose={(e: any) => {
						if (e) {
							handleClose(dialog.id, e);
						} else {
							handleClose(dialog.id);
						}
					}}
					style={{
						display: 'flex',
						transition: 'all .2s',
						opacity: dialog.isOpen ? 1 : 0,
						// visibility: dialog.isOpen ? "visible" : "hidden",
					}}
					{...dialog.props}
				/>
			))}
			{children}
		</DialogContext.Provider>
	);
};

export interface DialogProps {
	style?: React.CSSProperties;
	onClose?: (e?: any) => void;
}

export interface Dialog<P extends DialogProps = DialogProps> {
	id: number;
	type: React.ComponentType<P>;
	props?: P;
	isOpen: boolean;
}

type ShowDialogFn = <P extends DialogProps>(
	type: React.ComponentType<P>,
	props?: P
) => any;

const DialogContext = React.createContext<{
	showDialog: ShowDialogFn;
	handleClose: Function;
	dialogSearch: Function;
}>({
	showDialog: () => {},
	handleClose: () => {},
	dialogSearch: () => {},
});

export const useDialog = () => useContext(DialogContext);

export default DialogProvider;
