import React, { useEffect, useState } from 'react';
import header_logo from '../../assets/logo_gj.png';
import { useNavigate } from 'react-router';
import LoginDialog from '../../dialog/Login/LoginDialog';
import { DialogProps, useDialog } from '../../dialog/DialogProvider';
import useMember from '../../hooks/useMember';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import MemberAPI from '../../apis/MemberAPI';
interface Popup_mobile_menu extends DialogProps {
	type: '';
}

// 반응형 사이드 메뉴바(모바일)
const Popup_mobile_menu = ({ ...props }: Popup_mobile_menu) => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [tab, setTab] = useState<string>();
	const clickTab = (e: any) => {
		const title = '';
		const message = '현재 준비중인 서비스입니다.';
		switch (e.target.innerText) {
			case '회원가입':
				// navigate('/join_04/', { replace: true });
				showDialog(LoginDialog, {
					title: '회원가입',
				});
				setTab(e.target.innerText);
				break;
			case '로그인':
				// navigate('/login_04/', { replace: true });
				// const title = "회원가입"
				showDialog(LoginDialog, {
					title: '로그인',
				});
				setTab(e.target.innerText);
				break;
			case '마이페이지':
				navigate('/myPage_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '소통마당':
				// dep1
				setTab(e.target.innerText);
				break;
			case '소통마당　':
				// dep2
				navigate('/commu_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '참여마당':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				// navigate('/reward_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '에너지전환 현황':
				// // console.log('현황');
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/reward_02/', { replace: true });
				setTab(e.target.innerText);
				// window.location.reload();
				break;
			case '에너지전환 활동미션':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/reward_02/mission-main/', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();

				break;
			case '에너지전환 챌린지':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/reward_02/challenge-main', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();

				break;
			// case '내 E 포인트':
			// 	// showDialog(OneButtonAlertDialog, {
			// 	// 	title,
			// 	// 	message,
			// 	// });
			// 	navigate('/reward_02/myEPoint', { replace: true });
			// 	setTab(e.target.innerText);
			// 	break;
			case '정보마당':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				// navigate('/info_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '광주 에너지현황':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/info_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '광주 에너지분석':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/info_02/analysis/', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();

				break;
			case '광주 태양광 에너지지도':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/info_02/map/', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();

				break;
			case '탄소계산기':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				navigate('/info_02/cal/', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();

				break;
			case '공지사항':
				// dep1
				setTab(e.target.innerText);
				break;
			case '공지사항　':
				// dep12
				navigate('/notice_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '팝업 관리':
				navigate('/popup_02/', { replace: true });
				setTab(e.target.innerText);
				// window.location.reload();
				break;
			case '관리자 메뉴':
				// navigate('/admin_02/', { replace: true });
				setTab(e.target.innerText);
				// window.location.reload();
				break;
			case '회원관리':
				navigate('/admin_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '포인트 및 보상 관리':
				navigate('/admin_02/point', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();
				break;
			case '활동 통계':
				navigate('/admin_02/static', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();
				break;
			case '이용자 수 통계':
				navigate('/admin_02/today', { replace: true });
				setTab(e.target.innerText);
				window.location.reload();
				break;

			case '에너지 용어사전':
				navigate('/dictionary_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '소개마당':
				// navigate('/platform_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '플랫폼':
				navigate('/platform_02/', { replace: true });
				setTab(e.target.innerText);
				break;
			case '에너지전환마을':
				navigate('/platform_02/energyConversionVillage', { replace: true });
				setTab(e.target.innerText);
				//console.log(e.target.innerText)
				window.location.reload();
				break;
		}
	};

	// // // console.log("tttttt",tab)
	const handleMain = () => {
		navigate('/');
	};
	const handleClose = () => {
		props.onClose?.();
	};

	const { member, getMemInfo, logout } = useMember();

	useEffect(() => {
		// MemberAPI.getUser().then((res) => {
		// 	// // console.log("멤버정보 >> ", res)
		// 	if (res) {
		// 		if (res.townDTO) {
		// 			if (member) {
		// 				getMemInfo(res);
		// 				return;
		// 			} else {
		// 				getMemInfo(res);
		// 				const message = '로그인 되었습니다.';
		// 				const title = '';
		// 				showDialog(OneButtonAlertDialog, {
		// 					message,
		// 					title,
		// 				});
		// 			}
		// 		} else {
		// 			getMemInfo(res);
		// 			const message = '회원가입을 진행해 주세요.';
		// 			const title = '';
		// 			showDialog(OneButtonAlertDialog, {
		// 				message,
		// 				title,
		// 			});
		// 			// navigate("/join_04/")
		// 		}
		// 	}
		// });
	}, []);

	useEffect(() => {
		// // console.log('로그인 멤버 정보', member);
	}, [member]);

	const handleLogout = () => {
		MemberAPI.getUser().then((res) => {
			if (res) {
				MemberAPI.logout().then((res) => {
					if (res == 200) {
						logout();
						const message = '로그아웃 되었습니다.';
						const title = '';
						navigate('/');
						showDialog(OneButtonAlertDialog, {
							message,
							title,
							onClose: () => {
								// 확인 버튼을 클릭하면 페이지 다시 로드
								window.location.reload();
							},
						});
					}
				});
			} else {
				logout();
				const message = '로그아웃 되었습니다.';
				const title = '';
				navigate('/');
				showDialog(OneButtonAlertDialog, {
					message,
					title,
					onClose: () => {
						// 확인 버튼을 클릭하면 페이지 다시 로드
						window.location.reload();
					},
				});
			}
		});

		// deleteCookie()
		// navigate('/')
	};
	const handlePolicy = () => {
		navigate('/policy/policyPage');
	};
	const handleUsePolicy = () => {
		navigate('/policy/usePolicyPage');
	};
	useEffect(() => {
		// 모바일 사이드메뉴 메뉴별 열고닫기
		$('body').on('click', '.mobile_gnb_dep1_li > a', function () {
			const idx = $('.mobile_gnb_dep1_li').index($(this).parent());
			$('.mobile_gnb_dep1_li').each(function (index) {
				if (idx == index) {
					// console.log("this >>>>> ", this)
					if ($(this).is('.on') == false) {
						$(this).addClass('on');
						$(this).children('ul').stop().slideDown(150);
					} else {
						$(this).removeClass('on');
						$(this).children('ul').stop().slideUp(75);
					}
				} else {
					$(this).removeClass('on');
					$(this).children('ul').stop().slideUp(75);
				}
			});
		});
	}, [])

	return (
		<>
			{/* mobile */}
			<div className="Poup_mobile_menu">
				<div className="mobile_menu_bg" />
				<div className="mobile_menu_cont">
					<div className="mobile_title">
						<a className="logo">
							<img
								src={header_logo}
								alt="에너지전환마을 지원 플랫폼"
								onClick={handleMain}
							/>
						</a>
						<button
							type="button"
							className="mobile_close"
							onClick={handleClose}
						>
							<span className="sound_only">모바일메뉴닫기</span>
						</button>
					</div>
					<div className="mobile_login">
						<p>
							환영합니다
							<br />
							<b style={{ color: '#1d5d9b' }}>
								광주광역시 에너지전환마을 지원 플랫폼
							</b>{' '}
							입니다!
						</p>
						<div
							className="mobile_login_guest"
							style={{ display: member ? 'none' : 'flex' }}
						>
							<a
								// className="txt_blue"
								onClick={clickTab}
								className={`txt_blue ${tab === '로그인' ? 'selectTab' : ''}`}
							>
								로그인
							</a>
							<a
								onClick={clickTab}
								className={`${tab === '회원가입' ? 'selectTab' : ''}`}
							>
								회원가입
							</a>
						</div>
						<div
							className="mobile_login_member"
							style={{ display: member ? 'flex' : 'none' }}
						>
							<a
								// className="txt_blue"
								onClick={clickTab}
								className={`txt_blue${tab === '마이페이지' ? ' selectTab' : ''
									}`}
							>
								{/* <span className="header_alarm">1</span> */}
								마이페이지
							</a>
							<a onClick={handleLogout}>로그아웃</a>
						</div>
					</div>
					<nav id="mobile_gnb">
						<ul className="mobile_gnb_dep1">
							<li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
										onClick={clickTab}
										className={`${tab === '공지사항' ? 'selectTab' : ''}`}
									>
										공지사항
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '공지사항　' ? 'selectTab' : ''
												}`}
											id="close"
										>
											공지사항　
										</a>
									</li>
									{member && member?.roles === 'AMN' &&
										<li>
											<a
												onClick={clickTab}
												className={`${tab === '팝업 관리' ? 'selectTab' : ''
													}`}
												id="close"
											>
												팝업 관리
											</a>
										</li>
									}
								</ul>
							</li>
							<li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
									onClick={clickTab}
									className={`${tab === '소개마당' ? 'selectTab' : ''}`}
								>
									소개마당
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '플랫폼' ? 'selectTab' : ''
												}`}
											id="close"
										>
											플랫폼
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '에너지전환마을' ? 'selectTab' : ''
												}`}
											id="close"
										>
											에너지전환마을
										</a>
									</li>
								</ul>
							</li>
							<li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
									onClick={clickTab}
									className={`${tab === '소통마당' ? 'selectTab' : ''}`}
									id="close"
								>
									소통마당
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '소통마당　' ? 'selectTab' : ''
												}`}
											id="close"
										>
											소통마당　
										</a>
									</li>

								</ul>
							</li>
							<li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
									onClick={clickTab}
									className={`${tab === '참여마당' ? 'selectTab' : ''}`}
								>
									참여마당
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '에너지전환 현황' ? 'selectTab' : ''
												}`}
											id="close"
										>
											에너지전환 현황
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '에너지전환 활동미션' ? 'selectTab' : ''
												}`}
											id="close"
										>
											에너지전환 활동미션
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '에너지전환 챌린지' ? 'selectTab' : ''
												}`}
											id="close"
										>
											에너지전환 챌린지
										</a>
									</li>
									{/* <li>
										<a
											onClick={clickTab}
											className={`${tab === '내 E 포인트' ? 'selectTab' : ''}`}
											id="close"
										>
											내 E 포인트
										</a>
									</li> */}
								</ul>
							</li>
							<li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
									onClick={clickTab}
									className={`${tab === '정보마당' ? 'selectTab' : ''}`}
									id="close"
								>
									정보마당
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '광주 에너지현황' ? 'selectTab' : ''
												}`}
											id="close"
										>
											광주 에너지현황
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '광주 에너지분석' ? 'selectTab' : ''
												}`}
											id="close"
										>
											광주 에너지분석
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '광주 태양광 에너지지도' ? 'selectTab' : ''
												}`}
											id="close"
										>
											광주 태양광 에너지지도
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '탄소계산기' ? 'selectTab' : ''}`}
											id="close"
										>
											탄소계산기
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '에너지 용어사전' ? 'selectTab' : ''}`}
											id="close"
										>
											에너지 용어사전
										</a>
									</li>
								</ul>
							</li>
							{member && member?.roles === 'AMN' &&
								<li className="mobile_gnb_dep1_li mgd_li_drop">
									<a
										onClick={clickTab}
										className={`${tab === '관리자 메뉴' ? 'selectTab' : ''}`}
										id="close"
									>
										관리자 메뉴
									</a>
									<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
										<li>
											<a
												onClick={clickTab}
												className={`${tab === '회원관리' ? 'selectTab' : ''
													}`}
												id="close"
											>
												회원관리
											</a>
										</li>
										<li>
											<a
												onClick={clickTab}
												className={`${tab === '포인트 및 보상 관리' ? 'selectTab' : ''
													}`}
												id="close"
											>
												포인트 및 보상 관리
											</a>
										</li>
										<li>
											<a
												onClick={clickTab}
												className={`${tab === '활동 통계' ? 'selectTab' : ''
													}`}
												id="close"
											>
												활동 통계
											</a>
										</li>
										<li>
											<a
												onClick={clickTab}
												className={`${tab === '이용자 수 통계' ? 'selectTab' : ''}`}
												id="close"
											>
												이용자 수 통계
											</a>
										</li>
									</ul>
								</li>
							}
							{/* <li className="mobile_gnb_dep1_li mgd_li_drop">
								<a
									onClick={clickTab}
									className={`${tab === '관리자 메뉴' ? 'selectTab' : ''}`}
								>
									관리자 메뉴
								</a>
								<ul className="mobile_gnb_dep2" style={{ display: 'none' }}>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '회원관리' ? 'selectTab' : ''
												}`}
											id="close"
										>
											회원관리
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '포인트 및 보상 관리' ? 'selectTab' : ''
												}`}
											id="close"
										>
											포인트 및 보상 관리
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '활동 통계' ? 'selectTab' : ''
												}`}
											id="close"
										>
											활동 통계
										</a>
									</li>
									<li>
										<a
											onClick={clickTab}
											className={`${tab === '이용자 수 통계' ? 'selectTab' : ''
												}`}
											id="close"
										>
											이용자 수 통계
										</a>
									</li>
								</ul>
							</li> */}
						</ul>
					</nav>
					<div className="mobile_info">
						<a className="txt_blue" id="close" onClick={handlePolicy}>
							개인정보 처리방침
						</a>
						<a id="close" onClick={handleUsePolicy}>
							{' '}
							이용약관
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Popup_mobile_menu;
