import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './Main_NoticePage.scss';
// import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDialog } from '../../dialog/DialogProvider';
import useMember from '../../hooks/useMember';
import moment from 'moment';
import noList from '../../assets/noList.png';
import NoticeAPI from '../../apis/NoticeAPI';
import { NoticeList } from '../../models/Notice';
import Pagination from '../../components/common/Pagination';

// 소통마당 메인
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const Main_NoticePage = () => {

    return (
        <main className="Main_NoticePage">
            <h1 className="notext">전체 영역</h1>
            <Nav />
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <NoticeListComponent />
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

const Nav = () => {
    return (
        <nav id="lnb">
            <h2 className="notext">네비게이션 영역</h2>
            <SubLocation titleText1="공지사항" liList={SubLocationList} />
            <div className="lnb_txt">
                <h3>공지사항</h3>
                {/* <p>모든 마을의 최신 현황을 확인하고 내 마을에 들어가 소통해보세요</p> */}
            </div>
        </nav>
    );
};

// 공지사항 리스트
// SwiperCore.use([Navigation, Pagination, Autoplay]);
const NoticeListComponent = () => {
    const navigate = useNavigate();
    const { member } = useMember();
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [noticeList, setNoticeList] = useState<NoticeList[]>([]);

    useEffect(() => {
        NoticeAPI.getNoticeList({
            page: page,
            pageSize: 10,
        }).then((res) => {
            if (res) {
                // console.log(res);
                setNoticeList(res);
                if (res[0]) {
                    if (res[0].totalCnt) {
                        setCount(res[0].totalCnt);
                    }
                }
            }
        })
    }, [page, member?.id])

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleDetail = (uid: number) => {
        sessionStorage.setItem('noticeN', String(uid));
        navigate('/notice_02/notice_03-detail')
    }

    const handleWriteNotice = () => {
        navigate('/notice_02/notice_04-write');
    }
    return (
        <div className="village_cont mt40">
            <div className="cont_content mt25 village_table">
                {member && member.roles && ['AMN', 'MST'].includes(member.roles) &&
                    <div style={{ marginBottom: '20px' }}>
                        <button onClick={handleWriteNotice} style={{ padding: '0px 20px', borderRadius: '5px', backgroundColor: '#1d5d9b', height: '50px', fontSize: '1.25rem', color: '#fff' }}>등록하기</button>
                    </div>
                }
                <ul className="thead">
                    {/* <li>메뉴명</li> */}
                    <li>등록일</li>
                    <li>제목</li>
                </ul>
                <ul className="tbody">
                    {/* <li className="pa20">
                        <a>
                            <p>2024.08.20</p>
                            <p className="txt_cut1 comm_title">공지사항 제목</p>
                        </a>
                    </li> */}
                    {noticeList &&
                        noticeList.length !== 0 ?
                        noticeList.map((list, index) => (
                            <li
                                key={index}
                                className={list.status === 'N' ? 'non' : ''}
                                onClick={() => handleDetail(list.nntUid)}
                            >
                                <a>
                                    <p>{moment(list.crtDt).format('YYYY.MM.DD')}</p>
                                    <p className="txt_cut1 comm_title">{list.title}</p>
                                </a>
                            </li>
                        ))
                        :
                        <li
                            className="pa20">
                            <a>
                                <p className="villname">-</p>
                                <p className="txt_cut1 comm_title">-</p>
                                <p>-</p>
                            </a>
                        </li>
                    }
                </ul>
            </div>
            <Pagination
                page={Number(page)}
                onPageChange={handlePageChange}
                // totalPageCount={Math.ceil(count / 8)}
                totalPageCount={Math.ceil(count / 10)}
            />
        </div>
    );
};

export default Main_NoticePage;
