import React, { useEffect, useRef, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './JoinInfoPage.scss';
import profile01 from '../../assets/infoimg01.png';
import profile02 from '../../assets/infoimg02.png';
import profile03 from '../../assets/infoimg03.png';
import character01 from '../../assets/character01.png';
import character02 from '../../assets/character02.png';
import { useNavigate } from 'react-router';
import useMember from '../../hooks/useMember';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDialog } from '../../dialog/DialogProvider';
import CharacterDialog from '../../dialog/Join/CharacterDialog';
import NickDialog from '../../dialog/Join/NickDialog';
import NickCheckDialog from '../../dialog/Join/NickCheckDialog';
import TelCheckDialog from '../../dialog/Join/TelCheckDialog';
import ValidDialog from '../../dialog/Join/ValidDialog';
import TelValidFailDialog from '../../dialog/Join/TelCheckFailDialog';
import TelCheckFailDialog from '../../dialog/Join/TelCheckFailDialog';
import NickCheckFailDialog from '../../dialog/Join/NickCheckFailDialog';
import JoinInfoPrevDialog from '../../dialog/Join/JoinInfoPrevDialog';
import { useSearchParams } from 'react-router-dom';
import MemberAPI from '../../apis/MemberAPI';
import TownAPI from '../../apis/TownAPI';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import PostCodeDialog from '../../dialog/activeMap/PostCodeDialog';
import { BugguSelect, DongguSelect, GwangsanguSelect, NamguSelect, SeoguSelect } from '../../models/EnergyDataSelect';
export const nickValid = /^[0-9a-zA-Z가-힣]+$/; // 닉네임
export const nameValid = /^[가-힣]+$/; // 실명
export const telValid = /^([0-9]{11})/;
interface JoinInput {
	townId: number;
	character: number;
	nickname: string;
	username: string;
	telno: string;
	address: string;
	detailAddress: string;
	gu: string;
	dong: string;
}
// 제이쿼리
const FloatingBox: React.FC<{ selector: string }> = ({ selector }) => {
	const classActive = 'active';
	const classCorrect = 'correct';

	const handleFocus = (e: Event) => {
		const parentElement = (e.currentTarget as HTMLElement).parentElement;
		parentElement?.classList.add(classActive);
	};

	const handleBlur = (e: Event) => {
		const target = e.currentTarget as HTMLInputElement | HTMLTextAreaElement;
		const parentElement = target.parentElement;

		if (target.value === '') {
			parentElement?.classList.remove(classActive);
			parentElement?.classList.remove(classCorrect);
		} else {
			parentElement?.classList.add(classCorrect);
		}
	};

	useEffect(() => {
		const inputElement = document.querySelector(selector) as
			| HTMLInputElement
			| HTMLTextAreaElement
			| null;

		if (inputElement) {
			inputElement.addEventListener('focus', handleFocus);
			inputElement.addEventListener('blur', handleBlur);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('focus', handleFocus);
				inputElement.removeEventListener('blur', handleBlur);
			}
		};
	}, [selector]);

	return null;
};

const JoinInfoPage = () => {
	const { showDialog } = useDialog();
	const { member } = useMember();
	const [searchParams] = useSearchParams();
	const selectVillage = sessionStorage.getItem('selectVillage');
	const [selectChar, setSelectChar] = useState<number | undefined>();
	// 닉네임 중복검사 여부
	const [nicknameValid, setNicknameValid] = useState(false);
	// 연락처 중복검사 여부
	const [telnoValid, setTelnoValid] = useState(true);
	// 상세주소 radio
	const [detailAddress, setdetailAddress] = useState<string>('기타');
	// 주소검색
	const [address, setAddress] = useState('');
	// 상세주소 기타
	const [address2, setAddress2] = useState('');
	// 상세주소 아파트
	const [address3, setAddress3] = useState('');
	const [address4, setAddress4] = useState('');
	const [dongGuSelect, setDongGuSelect] = useState(false);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		watch,
		formState: { errors },
	} = useForm<JoinInput>({
		defaultValues: {
			character: member?.character,
			nickname: member?.nickname,
			telno: member?.telno,
			gu: ""
		},
	});
	const nicknameInput = watch('nickname');
	const telnoInput = watch('telno');
	const gugu = watch('gu');
	const dongDong = watch('dong');

	useEffect(() => {
		// console.log('인풋창에 입력된 닉네임', nicknameInput);
		// console.log('멤버 닉네임', member?.nickname);
		if (member?.nickname === nicknameInput) {
			setNicknameValid(true);
			return;
		} else {
			setNicknameValid(false);
			return;
		}
	}, [nicknameInput]);
	useEffect(() => {
		// console.log('인풋창에 입력된 연락처', telnoInput);
		if (!getValues('telno')) {
			setTelnoValid(true);
			return;
		} else if (!telValid.test(telnoInput)) {
			setTelnoValid(false);
			return;
		}
	}, [telnoInput]);

	useEffect(() => {
		// console.log('구 선택 >> ', gugu);
		// console.log('동 선택 >> ', dongDong);
		if (gugu && dongDong) {
			setDongGuSelect(true);
		} else if (gugu === '' || dongDong === '') {
			setDongGuSelect(false);
		} else {
			setDongGuSelect(false);
		}
	}, [gugu, dongDong]);
	// 닉네임 중복확인
	const nicknameCheck = () => {
		if (getValues('nickname').length === 0) {
			// 닉네임 필수
			setNicknameValid(false);
			// window.alert('닉네임을 입력해주세요.');
			showDialog(NickDialog);
			return;
		} else if (!nickValid.test(getValues('nickname'))) {
			setNicknameValid(false);
			// setInputCheck({stat: false, msg: "올바른 아이디를 입력하세요."})
			// alert('올바른 닉네임 입력하세요.');
			const message = '올바른 형식이 아닙니다. 다시 입력해주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (getValues('nickname').length > 8) {
			setNicknameValid(false);
			const message = '닉네임은 최대 8자리입니다.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		}
		MemberAPI.nicknameDuplicate(getValues('nickname')).then((res) => {
			// console.log('닉네임 중복확인', res);
			if (res.data || nicknameValid) {
				setNicknameValid(true);
				const message = '사용 가능한 닉네임입니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			} else {
				setNicknameValid(false);
				const message = '중복된 닉네임입니다. 다른 닉네임으로 다시 시도하세요.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			}
		});
		// await MemberAPI.nickCheck({ nickname: getValues('nickname') }).then(
		// 	(res) => {
		// 		if (res.success) {
		// 			setNickValid(true);
		// 			alert('사용 가능한 닉네임입니다.');
		// 		} else {
		// 			setNickValid(false);
		// 			alert('중복된 닉네임입니다. 다른 닉네임으로 다시 시도하세요.');
		// showDialog(NickCheckFailDialog);
		// 		}
		// 	}
		// );
	};
	// 연락처 중복체크
	const telNoCheck = () => {
		if (getValues('telno').length === 0) {
			if (!telValid.test(getValues('telno'))) {
				setTelnoValid(true);
				const message = '연락처를 입력해주세요.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
				});
				return;
			}
		} else if (!getValues('telno').startsWith('01')) {
			setTelnoValid(false);
			const message = '유효한 연락처를 입력해주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else if (!telValid.test(getValues('telno'))) {
			setTelnoValid(false);
			const message = '유효한 연락처를 입력해주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			return;
		} else {
			// alert('유효한 연락처를 입력해주세요.');
			MemberAPI.telnoDuplicate(getValues('telno')).then((res) => {
				// console.log('연락처 중복확인', res);
				if (res.data || telnoValid) {
					const message = '사용 가능한 연락처입니다.';
					const title = '';
					showDialog(OneButtonAlertDialog, {
						message,
						title,
					});
					setTelnoValid(true);
					return;
				} else {
					const message = '중복된 연락처입니다. 다시 시도해 주세요.';
					const title = '';
					showDialog(OneButtonAlertDialog, {
						message,
						title,
					});
					setTelnoValid(false);
					return;
				}
			});
		}
	};
	// 이전 버튼
	const handlePre = () => {
		showDialog(JoinInfoPrevDialog);
	};
	// 등록 버튼
	const onSubmit: SubmitHandler<JoinInput> = async (data) => {
		// 필수값 입력 확인
		if (!selectChar) {
			// alert('캐릭터를 선택해주세요.');
			showDialog(CharacterDialog);
			return;
		}
		if (!data.nickname) {
			// alert('닉네임을 입력해주세요.');
			showDialog(NickDialog);
			return;
		}
		// 닉네임 중복체크
		if (!nicknameValid) {
			// window.alert('닉네임 중복체크를 해주세요.');
			showDialog(NickCheckDialog);
			return;
		}
		// 연락처 중복체크
		if (!telnoValid) {
			// alert('연락처 중복체크를 해주세요.');
			showDialog(TelCheckDialog);
			return;
		}
		if (!gugu || !dongDong) {
			const title = '회원가입 정보입력';
			const message = '거주지를 선택해주세요.'
			showDialog(OneButtonAlertDialog, {
				title, message
			})
			return;
		}
		// console.log('회원정보', data);
		// console.log('선택한 마을', selectVillage);
		MemberAPI.updateMember({
			...data,
			townId: Number(selectVillage),
			character: selectChar,
			username: data.username ? data.username : undefined,
			telno: data.telno ? data.telno : undefined,
			address: address ? address : undefined,
			detailAddress: address
				? detailAddress === '기타'
					? '2 ' + address2
					: '1 ' + address3 + '동' + ' ' + address4 + '호'
				: undefined,
		})
			.then((res) => {
				// console.log('회원가입완료', res);
				const message = '가입이 완료되었습니다.';
				const title = '';
				showDialog(OneButtonAlertDialog, {
					message,
					title,
					handleDialogResult(result) {
						if (result === 'ok') {
							navigate('/');
							window.location.reload();
						}
					}
				});
			})
			.catch((error) => {
				// console.log('회원가입 실패 ㅠ', error);
			});
		// joinAPI
		// let suc = await MemberAPI.join({
		// 	...data,
		// 	character: data.character || '',
		// 	nickname: data.nickname || '',
		// 	username: data.username || '',
		// 	telNo: data.telNo || '',

		// 	// areaCode: 37
		// }).then((res) => {

		// 	return res;
		// });

		// if (suc.success) {
		// 	// setMember(suc.data);
		// 	MemberAPI.getMember({
		// 		mbrUid: Number(suc.data.mbrUid),
		// 	}).then((res) => {
		// 		setMember(res);
		// 		getMemInfo(res);
		// 		// // console.log('getMember res', res);
		// 	});
		// 	alert('회원가입 성공했습니다.');
		// 	navigate(`/`);
		// 	// alert('로그인 되었습니다.');
		// } else {
		// 	alert('회원가입 실패하였습니다.');
		// }
	};
	// 캐릭터 선택
	const handleSelectChar = (char: number) => {
		setSelectChar(char);
		if (char == selectChar) {
			setSelectChar(undefined);
		}
	};
	useEffect(() => {
		// console.log('selectChar ::: ', selectChar);
	}, [selectChar]);

	// 상세주소 선택하는값에 따라 input창 바뀜
	const handleDetailAddress = (e: any) => {
		setAddress2('');
		setAddress3('');
		setAddress4('');
		// console.log(e.target.value);
		setdetailAddress(e.target.value);
	};
	// 주소검색
	const handlePostShearch = () => {
		showDialog(PostCodeDialog, {
			onComplete(address) {
				// // console.log("address >>>> ", address);
				setAddress(address.roadAddress);
			},
		});
	};
	return (
		<main className="JoinInfoPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>회원가입</h3>

					<p>정보입력</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<p className="only_mobile">정보입력</p>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="join_info">
							<ul className="info_img">
								<li>
									<label>
										<input
											type="radio"
											id="radio1-1"
											{...register('character')}
											name="radio1"
											value={1}
											checked={selectChar == 1}
											onChange={(e) => {
												handleSelectChar(Number(e.target.value));
											}}
										/>
										<img src={character01} alt="기본 프로필 이미지 01" />
									</label>
								</li>

								<li>
									<label>
										<input
											type="radio"
											id="radio1-2"
											{...register('character')}
											name="radio1"
											value={2}
											checked={selectChar == 2}
											onChange={(e) => {
												handleSelectChar(Number(e.target.value));
											}}
										/>
										<img src={character02} alt="기본 프로필 이미지 02" />
									</label>
								</li>
								{/* <li>
									<label>
										<input
											type="radio"
											id="radio1-3"
											{...register('character')}
											name="radio1"
											value={3}
											checked={selectChar == 3}
											onChange={(e) => {
												handleSelectChar(Number(e.target.value));
											}}
										/>
										<img src={profile03} alt="기본 프로필 이미지 03" />
									</label>
								</li> */}
							</ul>

							<ul className="info_txt">
								<li>
									<div className="form-group">
										<div
											className={`floating-box ${member?.nickname ? 'active' : ''
												}`}
										>
											<input
												id="nick"
												className="form-control textbox"
												type="text"
												{...register('nickname')}
											/>
											<label className="floating">
												닉네임 <span>(필수)</span>
											</label>
											<button
												type="button"
												className="btn_duplicate"
												onClick={nicknameCheck}
											// disabled={nicknameValid}
											>
												중복확인
											</button>
										</div>
										{/* 유효성검사 테스트 */}
										{/* <p>{nicknameValid ? 'true' : 'false'}</p> */}
									</div>
									<FloatingBox selector="#nick" />
								</li>
								<li>
									<div className="cont-wrap">
										<h3>
											거주지&nbsp;<span>(필수)</span>
										</h3>
										<div className="cont-box">
											<div className="select-box">
												<select {...register('gu')}>
													<option value="">구 선택</option>
													<option value="동구">동구</option>
													<option value="서구">서구</option>
													<option value="남구">남구</option>
													<option value="북구">북구</option>
													<option value="광산구">광산구</option>
												</select>
												<select {...register('dong')}>
													<option value="">동 선택</option>
													{gugu === '동구' &&
														DongguSelect.map((list, index) => (
															<option key={index} value={list.area}>{list.area}</option>
														))
													}
													{gugu === '서구' &&
														SeoguSelect.map((list, index) => (
															<option key={index} value={list.area}>{list.area}</option>
														))
													}
													{gugu === '남구' &&
														NamguSelect.map((list, index) => (
															<option key={index} value={list.area}>{list.area}</option>
														))
													}
													{gugu === '북구' &&
														BugguSelect.map((list, index) => (
															<option key={index} value={list.area}>{list.area}</option>
														))
													}
													{gugu === '광산구' &&
														GwangsanguSelect.map((list, index) => (
															<option key={index} value={list.area}>{list.area}</option>
														))
													}
													{/* <option value="">동 선택</option>
													<option value="금호동">금호동</option>
													<option value="쌍촌동">쌍촌동</option>
													<option value="풍암동">풍암동</option>
													<option value="치평동">치평동</option> */}
												</select>
											</div>
											{/* {(errors.gu || errors.dong) && (
												<span
													style={{
														color: 'red',
														fontSize: '14px',
													}}
												>
													! 거주지를 선택해주세요.
												</span>
											)} */}
										</div>
									</div>
								</li>

								<li>
									<div className="form-group">
										<div className="floating-box">
											<input
												id="name"
												className="form-control textbox"
												type="text"
												{...register('username')}
											/>
											<label className="floating">실명</label>
										</div>
									</div>
									<FloatingBox selector="#name" />
								</li>
								<li>
									<div className="form-group">
										<div className="floating-box">
											<input
												id="tel"
												className="form-control textbox"
												type="tel"
												{...register('telno', {
													onChange: (e: any) => {
														const inputText = e.target.value;
														if (inputText.length > 11) {
															e.target.value = inputText.slice(0, 11); // 11자 이상의 입력은 자르기
														}
													},
												})}
											/>
											<label className="floating">
												연락처 ( - 없이 11자리를 입력해주세요.)
											</label>
											<button
												type="button"
												className="btn_duplicate"
												onClick={telNoCheck}
											>
												중복확인
											</button>
										</div>
										{/* 유효성검사 테스트 */}
										{/* <p>{telnoValid ? 'true' : 'false'}</p> */}
									</div>
									<FloatingBox selector="#tel" />
								</li>
								<li>
									<div className="cont-wrap">
										<h3>주소</h3>
										<div className="cont-box">
											<input
												type="text"
												className="address"
												value={address}
												placeholder="주소를 입력하세요"
												disabled
											/>
											<button
												type="button"
												className="btn_address"
												onClick={handlePostShearch}
											>
												주소검색
											</button>
										</div>
									</div>
								</li>
								<li>
									<div className="cont-wrap">
										<h3>상세주소</h3>
										<div className="cont-box">
											<ul className="check-inner">
												<li>
													<label htmlFor="detail1-1">
														<input
															type="radio"
															id="detail1-1"
															name="detail1"
															onClick={handleDetailAddress}
															value="아파트"
														/>
														아파트
													</label>
												</li>
												<li>
													<label htmlFor="detail1-2">
														<input
															type="radio"
															id="detail1-2"
															name="detail1"
															defaultChecked
															onClick={handleDetailAddress}
															value="기타"
														/>
														기타
													</label>
												</li>
											</ul>
											<ul className="txt-inner">
												{detailAddress === '기타' ? (
													<li>
														<input
															type="text"
															placeholder="상세주소를 입력해주세요."
															onChange={(e) => setAddress2(e.target.value)}
														/>
													</li>
												) : (
													<li>
														<p>
															<input
																type="text"
																placeholder="000동"
																className="short"
																onChange={(e) => setAddress3(e.target.value)}
															/>
															동
														</p>
														<p>
															<input
																type="text"
																placeholder="0000호"
																className="short"
																onChange={(e) => setAddress4(e.target.value)}
															/>
															호
														</p>
													</li>
												)}
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className="comm_btn_wrap">
							<button type="button" className="btn_cancel" onClick={handlePre}>
								이전
							</button>
							<button
								type="submit"
								// className="btn_next"
								className={`btn_next ${selectChar && nicknameValid && telnoValid && dongGuSelect ? 'on' : ''
									}`}
							// 클래스 네임에 조건 추가 : 닉네임 입력 필수
							>
								완료
							</button>
							{/* 필수값인 캐릭터, 닉네임 입력 시 완료버튼 클래스네임에 on 붙음 -> 파란색 버튼으로 바뀜
									완료 버튼이 회색일 경우에는 넘어가면 안됨
								*/}
						</div>
					</form>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default JoinInfoPage;

// function nickValidation(e: any) {
// 	throw new Error('Function not implemented.');
// }

// function useForm<T>(arg0: {
// 	defaultValues: {
// 		character: any;
// 		nickname: any;
// 		username: any;
// 		telNo: any;
// 		// email: any;
// 		// memberSns: member?.memberSns,
// 		// snsType: any;
// 		// snsId: any;
// 		// thumbnail: any;
// 		// tnCommunityVOS: any;
// 	};
// }): { register: any; handleSubmit: any; getValues: any; setValue: any } {
// 	throw new Error('Function not implemented.');
// }
