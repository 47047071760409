import React from 'react';

import { DialogProps } from '../DialogProvider';

interface JoinVillageDialog extends DialogProps {
	type: '';
}
const JoinVillageDialog = ({ onClose }: JoinVillageDialog) => {
	const handleClose = () => {
		onClose?.();
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>회원가입 마을선택</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">
						마을 선택은 필수입니다.
						<br />
						활동 마을이 없다면 "선택안함" 을 체크해주세요.
					</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default JoinVillageDialog;
