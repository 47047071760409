import React, { useEffect, useRef, useState } from 'react';
import Marker1 from '../../marker/mk_1.png';
import Marker2 from '../../marker/mk_2.png';
import Marker3 from '../../marker/mk_3.png';
import Marker4 from '../../marker/mk_4.png';
import Marker5 from '../../marker/mk_5.png';
import Marker6 from '../../marker/mk_6.png';
import Marker7 from '../../marker/mk_7.png';
import Marker8 from '../../marker/mk_8.png';
import Marker9 from '../../marker/mk_9.png';
import Marker10 from '../../marker/mk_10.png';
import Marker11 from '../../marker/mk_11.png';
import Marker12 from '../../marker/mk_12.png';
import Marker13 from '../../marker/mk_13.png';
import Marker14 from '../../marker/mk_14.png';
import Marker15 from '../../marker/mk_15.png';

import { Content } from '../../models/MissionInfoDetail';
import { Marker } from '../../models/MissionInfo';

interface MapMissionComponentProps {
	certData: Content[];
	listIndex: number;
	listClickEvent: boolean;
	page: number;

	handleList: (index: number, cmmUid: number) => void;
}
export interface MarkerItem {
	index: number;
	cmcUid?: number;
	cmctitle?: string;
}

const MapMissionComponent = ({
	certData,
	listIndex,
	listClickEvent,
	page,
	handleList,
}: MapMissionComponentProps) => {
	const [mapState, setMapState] = useState<any>();
	const resizeTargetRef = useRef(null);
	const [myLatLng, setMyLatLng] = useState();
	const [lat, setLat] = useState<number>();
	const [lng, setLng] = useState<number>();
	const [infowindpwCss, setInfowindpwCss] = useState(false);
	const [findMarkerList, setFindMarkerList] = useState(
		[] as Content[] | undefined
	);
	const [markerList, setMarkerList] = useState([] as any[]);
	const [infoWindowList, setInfoWindowList] = useState([] as any[]);
	const [markerClickBeforeImage, setMarkerClickBeforeImage] = useState<any[]>(
		[]
	);
	const [markerClickAfterImage, setMarkerClickAfterImage] = useState<any[]>([]);
	const [markers, setMarkers] = useState([] as any[]);
	useEffect(() => {
		let info_window = document.querySelectorAll('.comm_infoWindow');
		info_window.forEach((e: any) => {
			e.parentElement.parentElement.style.border = '10px';
			e.parentElement.parentElement.style.background = 'unset';
		});
	}, [infowindpwCss]);
	// 인포윈도우 창 열고 닫기
	useEffect(() => {
		if (infoWindowList[listIndex]) {
			// console.log('listClickEvent::::::', listClickEvent);
			// console.log('listIndex::::::', listIndex);

			if (infoWindowList[listIndex].getMap()) {
				infoWindowList[listIndex].close();
			}

			for (let i = 0; i < infoWindowList.length; i++) {
				if (infoWindowList[i] !== infoWindowList[listIndex]) {
					markerList[i].setImage(markerClickBeforeImage[i]);
					infoWindowList[i].close();
				}
			}
			markerList[listIndex].setImage(markerClickAfterImage[listIndex]);
			infoWindowList[listIndex].open(mapState, markerList[listIndex]);

			mapState.setLevel(2);
			mapState.panTo(markerList[listIndex].getPosition());
			markerList[listIndex].setZIndex(400);
			setInfowindpwCss((cur) => !cur);
		}
	}, [listClickEvent]);
	useEffect(() => {
		// 지도 생성
		const container = document.getElementById('map');
		const options = {
			center: new window.kakao.maps.LatLng(
				35.148227890380454,
				126.8490671051837
			),
			level: 7,
		};
		const map = new window.kakao.maps.Map(container, options);
		setMapState(map);
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				map.relayout();
			}
		});
		if (resizeTargetRef.current) {
			resizeObserver.observe(resizeTargetRef.current);
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					var lat = position.coords.latitude, // 위도
						lng = position.coords.longitude; // 경도
					// console.log('위도:', lat, '경도:', lng);
					// console.log(certData[0]?.cntrLng, certData[0]?.cntrLat);

					const currentLatLng = new window.kakao.maps.LatLng(lat, lng);
					setMyLatLng(currentLatLng);
					// console.log('지도 컴포넌트 ::::::::', certData);
					if (certData.length != 0) {
						map.setCenter(
							new window.kakao.maps.LatLng(
								certData[0]?.cntrLng,
								certData[0]?.cntrLat
							)
						);
					} else {
						// 현재 위치 좌표 생성
						map.setCenter(
							new window.kakao.maps.LatLng(
								35.148227890380454,
								126.8490671051837
							)
						);
					}

					// 현재위치 이동 버튼
					const moveToCurrentButton =
						document.querySelector('.myPositionButton');
					moveToCurrentButton?.addEventListener('click', () => {
						map.panTo(new window.kakao.maps.LatLng(lat, lng)); // 35.14851596242328, 126.84877575766228
					});
				},
				(error) => {
					console.error('position 없으면 에러', error);
				}
			);
		} else {
			console.error('navigator.geolocation else문');
		}
		//
		// 지도 클릭 이벤트 핸들러 등록
		window.kakao.maps.event.addListener(map, 'click', (mouseEvent: any) => {
			const clickedLatLng = mouseEvent.latLng;
			// console.log('clickedLatLng', clickedLatLng);
			setLng(clickedLatLng.getLng());
			setLat(clickedLatLng.getLat());
		});
		//
		// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미
		var zoomControl = new window.kakao.maps.ZoomControl();
		if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
			map.addControl(zoomControl, window.kakao.maps.ControlPosition.TOPRIGHT);
		}
	}, []);
	const [markerItem, setMarkerItem] = useState<MarkerItem>();
	// 인포윈도우 클릭 함수

	const infoWindowClick = (
		index: number,
		cmcUid?: number,
		cmctitle?: string
	) => {
		setMarkerItem({
			index,
			cmcUid,
			cmctitle,
		});
	};

	// 마커 거리 계산
	const getDistance = (
		lat1: number,
		lng1: number,
		lat2: number,
		lng2: number
	) => {
		const radlat1 = (Math.PI * lat1) / 180;
		const radlat2 = (Math.PI * lat2) / 180;
		const theta = lng1 - lng2;
		const radtheta = (Math.PI * theta) / 180;
		let dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		return dist * 1.609344; // kilometer
	};
	//
	const [clustererState, setClustererState] = useState<any>();
	const findNearbyMarkers = (targetMarker: any, allMarkers: any[]) => {
		const radius = 0.1; // 10m
		const nearbyMarkers: any[] = [];
		for (const marker of allMarkers) {
			const distance = getDistance(
				targetMarker.getPosition().La,
				targetMarker.getPosition().Ma,
				marker.getPosition().La,
				marker.getPosition().Ma
			);

			if (distance <= radius) {
				nearbyMarkers.push(marker);
			}
		}
		return nearbyMarkers;
	};
	const handleCloseInfowindow = () => {
		infoWindowList.map((list) => {
			list.close();
		});
		setInfoWindowList([]);
		setMarkerList([]);
	};
	useEffect(() => {
		handleCloseInfowindow();
		// infoWindowList.map((list) => {
		//     list.close();
		// });
		// setInfoWindowList([]);
	}, [page]);
	useEffect(() => {
		// 맵핑 리스트

		setMarkerList([]);
		setInfoWindowList([]);
		setMarkerClickBeforeImage([]);
		setMarkerClickAfterImage([]);
		infoWindowList.forEach((inList, index) => {
			inList.close();
		});
		markerList.forEach((mlist, index) => {
			mlist.setMap(null);
		});
		if (certData) {
			const clusterer = new window.kakao.maps.MarkerClusterer({
				map: mapState, // 클러스터를 표시할 지도 객체
				averageCenter: true, // 클러스터의 중심 위치를 평균으로 설정
				minLevel: 2, // 클러스터를 생성하기 위한 최소 지도 레벨
				gridSize: 10,
			});
			setClustererState(clusterer);

			// setcertData(certData);
			// // // console.log("certData :::: ", certData);
			certData.map((list, index) => {
				// console.log(';;;;;;;;;;;;;;;;;', list.cntrLng, list.cntrLat);
				var marker = new window.kakao.maps.Marker({
					position: new window.kakao.maps.LatLng(list.cntrLng, list.cntrLat),
				});

				marker.id = list.cmcUid;
				marker.index = index;
				// 마커 클러스터에 marker 추가
				clusterer.addMarker(marker);
				setMarkerList((cur) => [...cur, marker]);
				// // // console.log("!!!!!!!!", marker);
				var infoWindow = new window.kakao.maps.InfoWindow({
					content: `<div class="comm_infoWindow">
           								 <div class="infoWindow_wrap pa20">
               								 <p class="category">${list?.cmctitle}</p>
               									 <h3 class="txt_cut2">${list?.certCn}</h3>
               								 <p class="txt_cut1">${list?.locInfo}</p>
           								 </div>
       								 </div>`,
					zIndex: 500,
				});

				setInfoWindowList((cur) => [...cur, infoWindow]);
				// if (list.openTp === "A") {

				const markerColor =
					'Marker' + list?.marker?.markerFile.match(/\d+/)?.[0];
				let markerImg = null;
				switch (markerColor) {
					case 'Marker1':
						markerImg = Marker1;
						break;
					case 'Marker2':
						markerImg = Marker2;
						break;
					case 'Marker3':
						markerImg = Marker3;
						break;
					case 'Marker4':
						markerImg = Marker4;
						break;
					case 'Marker5':
						markerImg = Marker5;
						break;
					case 'Marker6':
						markerImg = Marker6;
						break;
					case 'Marker7':
						markerImg = Marker7;
						break;
					case 'Marker8':
						markerImg = Marker8;
						break;
					case 'Marker9':
						markerImg = Marker9;
						break;
					case 'Marker10':
						markerImg = Marker10;
						break;
					case 'Marker11':
						markerImg = Marker11;
						break;
					case 'Marker12':
						markerImg = Marker12;
						break;
					case 'Marker13':
						markerImg = Marker13;
						break;
					case 'Marker14':
						markerImg = Marker14;
						break;
					case 'Marker15':
						markerImg = Marker15;
						break;
				}
				const markerClickBeforeImage = new window.kakao.maps.MarkerImage(
					markerImg,
					new window.kakao.maps.Size(25, 30)
				);
				setMarkerClickBeforeImage((cur) => [...cur, markerClickBeforeImage]);
				const markerClickAfterImage = new window.kakao.maps.MarkerImage(
					markerImg,
					new window.kakao.maps.Size(40, 50)
				);
				setMarkerClickAfterImage((cur) => [...cur, markerClickAfterImage]);
				const markerElement = marker.getImage();
				// // // console.log("markerElementmarkerElement", markerElement);

				marker.setImage(markerClickBeforeImage);
				// marker.setMap(mapState);
				markers?.push(marker);

				window.kakao.maps.event.addListener(marker, 'mouseover', () => {
					// // console.log("마우스오버")
					marker.setZIndex(300);
				});
				window.kakao.maps.event.addListener(marker, 'mouseout', () => {
					if (!infoWindow.getMap()) {
						// // console.log("마우스아웃")
						marker.setZIndex(290);
					}
				});

				var customOverlay = new window.kakao.maps.CustomOverlay({
					xAnchor: 0.3,
					yAnchor: 0.91,
					zIndex: 400,
				});
				window.kakao.maps.event.addListener(marker, 'click', () => {
					let findMarkerListClose = document.querySelectorAll(
						'.findMarkerList'
					) as NodeListOf<HTMLElement>;
					if (findMarkerListClose) {
						for (let i = 0; i < findMarkerListClose.length; i++) {
							findMarkerListClose[i].style.display = 'none';
						}
					}

					// find마커리스트
					if (findNearbyMarkers(marker, markers).length > 1) {
						if (findMarkerList) {
							findMarkerList.splice(0, findMarkerList.length);
						}
						const markerListCheck = findNearbyMarkers(marker, markers);
						for (let i = 0; i < markerListCheck.length; i++) {
							const findMarker = certData.find(
								(find) => find.cmcUid == markerListCheck[i].id
							);
							// // // console.log(findMarker?.cmUid);
							if (findMarkerList) {
								if (findMarker) {
									findMarkerList.push(findMarker);
								}
							}
						}
						// 클릭되지 않은 상태에서 클릭하면 customOverlay 생성 및 표시
						// var content = `<div class="findMarkerList" style>
						// 									<div class="closeList"><p>마커 목록</p><a class="listCloseButton">Ⅹ</a></div>
						// 									<ul>
						// 									  ${findMarkerList
						// 											?.map(
						// 												(list, index) =>
						// 													`<li>
						// 										  <p class="findListDetail" key="${list.cmctitle}" data-custom-attr1=${markerListCheck[index].index} data-custom-attr2=${list.cmcUid} data-custom-attr3=${list.crtUid}>${list.certCn}</p>
						// 										</li>`
						// 											)
						// 											.join('')}
						// 									</ul>
						// 								  </div>`;

						// customOverlay.setContent(content);
						// customOverlay.setPosition(marker.getPosition());
						// if (customOverlay.getMap()) {
						// 	customOverlay.setMap(null);
						// } else {
						// 	customOverlay.setMap(mapState);
						// }
						// find마커리스트 end
						let listClose = document.querySelectorAll('.listCloseButton');
						let findListDetail = document.querySelectorAll('.findListDetail');
						listClose.forEach((e: any) => {
							e.addEventListener('click', () => {
								e.parentElement.parentElement.style.display = 'none';
							});
						});
						findListDetail.forEach((e: any) => {
							e.addEventListener('click', (event: any) => {
								const index = event.target.dataset.customAttr1;
								const mmcUid = event.target.dataset.customAttr2;
								// const caTitle = event.target.getAttribute('key');
								// const crtUid = event.target.dataset.customAttr3;
								e.parentElement.parentElement.parentElement.style.display =
									'none';
								e.parentElement.parentElement.style.border = '10px';
								e.parentElement.parentElement.style.background = 'unset';

								handleList(index, mmcUid);
							});
						});
					} else {
						if (infoWindow.getMap()) {
							marker.setZIndex(10);
							marker.setImage(markerClickBeforeImage);
							infoWindow.close();
						} else {
							marker.setZIndex(310);
							mapState.setCenter(
								new window.kakao.maps.LatLng(list.cntrLng, list.cntrLat)
							);

							marker.setImage(markerClickAfterImage);
							// handleList(index, list.cmUid, list.caTitle, list.crtUid);
							infoWindow.open(mapState, marker);
							let info_window = document.querySelectorAll('.comm_infoWindow');
							info_window.forEach((e: any) => {
								e.parentElement.parentElement.style.border = '10px';
								e.parentElement.parentElement.style.background = 'unset';
								e.addEventListener('click', () => {
									infoWindowClick(index, list.cmcUid, list.cmctitle);
									handleList(index, Number(list?.mmcUid));
								});
							});
						}
					}
				});

				// }
			});
		}
		// 맵핑 리스트 end
	}, [certData]);
	return <div className="map_inner" id="map" ref={resizeTargetRef} />;
};

export default MapMissionComponent;
