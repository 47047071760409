import React, { useEffect, useState } from 'react';
import './Write_MissionPage.scss';
import camImg from '../../assets/ic_cam.png';
import SubLocation from '../../components/common/SubLocation';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import MissionInfo from '../../models/MissionInfo';
import { SubmitHandler, useForm } from 'react-hook-form';
import MissionAPI from '../../apis/MissionAPI';
const SubLocationList = [
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    // { href: '/info_02/', text: '정보마당' },
];
// 기본미션 등록
const Write_MissionPage = () => {

    const navigate = useNavigate();
    const { showDialog } = useDialog();
    // 미션 이미지
    const [missionUploadFiles, setMissionUploadFiles] = useState([] as File[]);
    const [missionPreviewImages, setMissionPreviewImages] = useState<string[]>([]);
    // 인증예시 이미지
    const [uploadFiles, setUploadFiles] = useState([] as File[]);
    const [previewImages, setPreviewImages] = useState<string[]>([]);
    // 인증방법 radio
    const [selectCertDe, setSelectCertDe] = useState<string>('IMG');
    const [selectCertOpenYn, setSelectCertOpenYn] = useState<string>('Y');
    const [insertOn, setInsertOn] = useState(false);
    const { register, getValues, watch, handleSubmit, setValue, setFocus } = useForm<MissionInfo>();
    const energyRedcnToe = watch('energyRedcnToe');
    const pwrerRedcnToe = watch('pwrerRedcnToe');
    const gasRedcnToe = watch('gasRedcnToe');
    const mssnNm = watch('mssnNm');
    const startDt = watch('startDt');
    const endDt = watch('endDt');
    const mssnDtl = watch('mssnDtl');
    const certDes = watch('certDes');
    const certPdDe = watch('certPdDe');
    const mxCertCo = watch('mxCertCo');
    const pymntPt = watch('pymntPt');
    const redcnDe = watch('redcnDe');
    useEffect(() => {
        const totalToe = Number(pwrerRedcnToe) + Number(gasRedcnToe);
        setValue('energyRedcnToe', totalToe);
    }, [pwrerRedcnToe, gasRedcnToe])
    useEffect(() => {
        if (!getValues('mssnNm')) {
            setInsertOn(false);
            return;
        } else if (!getValues('startDt')) {
            setInsertOn(false);
            return;
        } else if (!getValues('endDt')) {
            setInsertOn(false);
            return;
        } else if (!getValues('mssnDtl')) {
            setInsertOn(false);
            return;
        } else if (!getValues('certDes')) {
            setInsertOn(false);
            return;
        } else if (!getValues('certPdDe')) {
            setInsertOn(false);
            return;
        } else if (!getValues('mxCertCo')) {
            setInsertOn(false);
            return;
        } else if (!getValues('pymntPt')) {
            setInsertOn(false);
            return;
        } else if (!getValues('redcnDe')) {
            setInsertOn(false);
            return;
        }
        const stDt = new Date(getValues('startDt'))
        const enDt = new Date(getValues('endDt'))
        if (stDt > enDt) {
            setInsertOn(false);
            return;
        }
        if (isNaN(Number(energyRedcnToe))) {
            setInsertOn(false);
            return;
        }
        setInsertOn(true);
    }, [mssnNm, startDt, endDt, mssnDtl, certDes, certPdDe, mxCertCo, pymntPt, redcnDe])
    useEffect(() => {
        // console.log("aaaaa", String(pymntPt).length);
    }, [pymntPt])
    // useEffect(() => {
    //     const dateTime = new Date();
    //     const currentHours = ('0' + dateTime.getHours()).slice(-2); // 현재 시간을 가져옵니다.
    //     const currentMinutes = ('0' + dateTime.getMinutes()).slice(-2); // 현재 분을 가져옵니다.
    //     const currentSeconds = ('0' + dateTime.getSeconds()).slice(-2); // 현재 초를 가져옵니다.
    //     // console.log("시 >>> ", currentHours)
    //     // console.log("분 >>> ", currentMinutes)
    //     // console.log("초 >>> ", currentSeconds)
    //     // console.log("시분초 >>> ", `${currentHours}:${currentMinutes}:${currentSeconds}`)
    // }, [])
    const handleCertDe = (e: any) => {
        setSelectCertDe(e.target.value);
    }
    const handleCertOpenYn = (e: any) => {
        setSelectCertOpenYn(e.target.value);
    }
    const handleCancle = () => {
        navigate(-1);
    }

    // 미션 기본정보 이미지 파일 첨부
    const handleMissionImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('files ::::::: ', event.target.files);
        const files = event.target.files;
        if (!files) return;
        const selectedImages = Array.from(files);
        // 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
        if (missionPreviewImages.length + selectedImages.length > 2) {
            // alert('사진은 최대 1개 까지 업로드할수있습니다.');
            const title = '';
            const message = '사진은 최대 2개 까지 업로드할수있습니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (selectedImages && selectedImages[0].size > 15000000) {
            const title = '';
            const message = '파일 크기는 최대 15MB까지만 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
            const title = '';
            const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        setMissionUploadFiles((prevImages: any) => {
            const newImages = [...prevImages];
            selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
            // // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
            return newImages;
        });
        // 미리보기 이미지 추가
        const imagesToSubmit = selectedImages.slice(0, 5);
        const newPreviewImages = imagesToSubmit.map((file) =>
            URL.createObjectURL(file)
        );
        setMissionPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
    };

    // 실천인증 방법 이미지 파일 첨부
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log('files ::::::: ', event.target.files);
        const files = event.target.files;
        if (!files) return;
        const selectedImages = Array.from(files);
        // 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
        if (previewImages.length + selectedImages.length > 2) {
            // alert('사진은 최대 1개 까지 업로드할수있습니다.');
            const title = '';
            const message = '사진은 최대 2개 까지 업로드할수있습니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (selectedImages && selectedImages[0].size > 15000000) {
            const title = '';
            const message = '파일 크기는 최대 15MB까지만 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
            const title = '';
            const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
            showDialog(OneButtonAlertDialog, {
                title,
                message,
            });
            return;
        }
        setUploadFiles((prevImages: any) => {
            const newImages = [...prevImages];
            selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
            // // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
            return newImages;
        });
        // 미리보기 이미지 추가
        const imagesToSubmit = selectedImages.slice(0, 5);
        const newPreviewImages = imagesToSubmit.map((file) =>
            URL.createObjectURL(file)
        );
        setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
    };

    // 미션 기본정보 첨부한 이미지 파일 x 버튼 누를시 삭제
    const handleMissionRemovePreview = (index: number) => {
        setMissionPreviewImages((prevImages) => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
        setMissionUploadFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    // 실천인증 방법 첨부한 이미지 파일 x 버튼 누를시 삭제
    const handleRemovePreview = (index: number) => {
        setPreviewImages((prevImages) => {
            const newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
        setUploadFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            newFiles.splice(index, 1);
            return newFiles;
        });
    };

    const onSubmit: SubmitHandler<MissionInfo> = async (data) => {

        if (!getValues('mssnNm')) {
            alert('미션명을 입력해주세요')
            setFocus('mssnNm')
            return;
        } else if (!getValues('startDt')) {
            alert('미션기간을 설정해주세요')
            setFocus('startDt')
            return;
        } else if (!getValues('endDt')) {
            alert('미션기간을 설정해주세요')
            setFocus('endDt')
            return;
        } else if (!getValues('mssnDtl')) {
            alert('미션설명을 입력해주세요')
            setFocus('mssnDtl')
            return;
        } else if (!getValues('certDes')) {
            alert('실천인증방법 설명을 입력해주세요')
            setFocus('certDes')
            return;
        } else if (!getValues('certPdDe')) {
            alert('인증제한 기간 및 횟수를 설정해주세요')
            setFocus('certPdDe')
            return;
        } else if (!getValues('mxCertCo')) {
            alert('인증제한 기간 및 횟수를 설정해주세요')
            setFocus('mxCertCo')
            return;
        } else if (!getValues('pymntPt')) {
            alert('1회 실천 시 지급할 포인트를 설정해주세요')
            setFocus('pymntPt')
            return;
        } else if (!getValues('redcnDe')) {
            alert('절감구분을 선택해주세요')
            setFocus('redcnDe')
            return;
        }
        const stDt = new Date(data.startDt)
        const enDt = new Date(data.endDt)
        if (stDt > enDt) {
            alert('미션기간이 잘못 설정되었습니다');
            setFocus('endDt')
            return;
        }
        if (isNaN(Number(energyRedcnToe))) {
            alert('에너지 절감수치가 잘못 입력되었습니다.')
            setFocus('energyRedcnToe');
            return;
        }
        const resultStartDateTime = `${data.startDt} 00:00:00`;
        const resultEndDateTime = `${data.endDt} 23:00:00`;
        MissionAPI.insertMission({
            ...data,
            mssnDe: '기본미션',
            certLnk: data.certLnk ? data.certLnk : undefined,
            startDt: resultStartDateTime,
            endDt: resultEndDateTime,
            certDe: selectCertDe,
            certOpenYn: selectCertOpenYn
        }, missionUploadFiles ? missionUploadFiles : undefined, uploadFiles ? uploadFiles : undefined)
            .then((res) => {
                // console.log("등록 후 콘솔로끄", res);
                navigate('/reward_02/mission_03-list');
                alert('미션이 추가되었습니다');
                window.location.reload();
            })
    }

    return (
        <main className="Write_MissionPage">
            <h1 className="notext">전체 영역</h1>
            <nav id="lnb">
                <h2 className="notext">네비게이션 영역</h2>
                <SubLocation titleText1="참여마당" liList={SubLocationList} />
                <div className="lnb_txt">
                    <h3>기본미션 만들기</h3>
                    <select>
                        <option>문산마을</option>
                        <option>첨단마을</option>
                        <option>사직마을</option>
                    </select>
                    {/* <CommSelect />
                <p>마을에 포함되어 있는 동 이름 나와야함</p> */}
                </div>
            </nav>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* 기본정보 시작 */}
                        <p className="comm_title mb25">미션 기본정보</p>
                        <div className="comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">미션명</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="미션명을 입력하세요" {...register('mssnNm')} />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">
                                    미션기간
                                </h3>
                                <div className="cont-box">
                                    <div className="date_box period">
                                        <input type="date" data-placeholder="시작일시" {...register('startDt')} />
                                        <input type="date" data-placeholder="종료일시" className="endDate" {...register('endDt')} />
                                    </div>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">미션설명</h3>
                                <div className="cont-box">
                                    <textarea placeholder="내용을 입력하세요" defaultValue={""} {...register('mssnDtl')} />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>미션 이미지</h3>
                                <div className="cont-box">
                                    <div className="imgBox">
                                        <input className="upload-photo" />
                                        <label htmlFor="img1">
                                            <img src={camImg} alt="사진 첨부" />
                                            사진 첨부
                                            <span>(2장)</span>
                                        </label>
                                        <input
                                            type="file"
                                            id="img1"
                                            accept="image/*"
                                            onChange={handleMissionImageChange}
                                        />
                                    </div>
                                    <div className="imglistBox">
                                        <ul className="no-scroll">
                                            {missionPreviewImages.map((imageUrl, index) => (
                                                <li key={index}>
                                                    <img src={imageUrl} key={imageUrl} />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleMissionRemovePreview(index)}
                                                        className="btn-file-delet"
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 기본정보 종료 */}
                        {/* 인증방법 시작 */}
                        <p className="comm_title mb25 mt40">실천인증 방법</p>
                        <div className="comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">인증방법 선택</h3>
                                <div className="cont-box">
                                    <ul className="village_list">
                                        <li>
                                            <label htmlFor="radio11-2">
                                                <input type="radio" id="radio11-2" value='IMG' onChange={(e) => handleCertDe(e)} name="radio11" defaultChecked />
                                                <p>이미지 인증</p>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="radio11-1">
                                                <input type="radio" id="radio11-1" value='TXT' onChange={(e) => handleCertDe(e)} name="radio11" />
                                                <p>글 인증</p>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="radio11-3">
                                                <input type="radio" id="radio11-3" value='BIL' onChange={(e) => handleCertDe(e)} name="radio11" />
                                                <p>고지서 인증</p>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">인증방법 설명</h3>
                                <div className="cont-box">
                                    <textarea placeholder="내용을 입력하세요" defaultValue={""} {...register('certDes')} />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>인증관련 링크</h3>
                                <div className="cont-box">
                                    <input type="text" className="long" placeholder="링크주소를 입력하세요" {...register('certLnk')} />
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3>인증예시 이미지</h3>
                                <div className="cont-box">
                                    <div className="imgBox">
                                        <input className="upload-photo" />
                                        <label htmlFor="img2">
                                            <img src={camImg} alt="사진 첨부" />
                                            사진 첨부
                                            <span>(2장)</span>
                                        </label>
                                        <input
                                            type="file"
                                            id="img2"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                    <div className="imglistBox">
                                        <ul className="no-scroll">
                                            {previewImages.map((imageUrl, index) => (
                                                <li key={index}>
                                                    <img src={imageUrl} key={imageUrl} />
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemovePreview(index)}
                                                        className="btn-file-delet"
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">인증공개 유무</h3>
                                <div className="cont-box">
                                    <ul className="village_list">
                                        <li>
                                            <label htmlFor="radio12-4">
                                                <input type="radio" id="radio12-4" value='Y' name="radio12" onChange={(e) => handleCertOpenYn(e)} defaultChecked />
                                                <p>공개</p>
                                            </label>
                                        </li>
                                        <li>
                                            <label htmlFor="radio12-5">
                                                <input type="radio" id="radio12-5" value='N' name="radio12" onChange={(e) => handleCertOpenYn(e)} />
                                                <p>비공개</p>
                                            </label>
                                        </li>
                                    </ul>
                                    <span className="explain">※ 인증 비공개시 회원은 자신이 인증한 글만 확인할 수 있습니다. </span>
                                </div>
                            </div>
                            <div className="cont-wrap">
                                <h3 className="important">
                                    인증제한
                                </h3>
                                <div className="cont-box">
                                    <div className="select-box">
                                        <select {...register('certPdDe')}>
                                            <option value=''>선택(기간)</option>
                                            <option value='D'>매일</option>
                                            <option value='M'>매달</option>
                                            <option value='P'>기간내</option>
                                        </select>
                                        <select {...register('mxCertCo')}>
                                            <option value=''>선택(횟수)</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={6}>6</option>
                                            <option value={7}>7</option>
                                            <option value={8}>8</option>
                                            <option value={9}>9</option>
                                            <option value={10}>10</option>
                                        </select>
                                        회까지 인증 가능
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 인증방법 종료 */}
                        {/* 포인트설정 시작 */}
                        <p className="comm_title mb25 mt40">포인트 설정</p>
                        <div className="comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">1회 실천 시<br />지급포인트 (E)</h3>
                                <div className="cont-box">
                                    <input
                                        type="text"
                                        className="long"
                                        placeholder="숫자만 입력"
                                        {...register('pymntPt')}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            setValue('pymntPt', value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* 포인트설정 종료 */}
                        {/* 절감수치 시작 */}
                        <p className="comm_title mb25 mt40">에너지 절감수치</p>
                        <div className="comm_write_wrap">
                            <div className="cont-wrap">
                                <h3 className="important">절감구분</h3>
                                <div className="cont-box">
                                    <div className="select-box">
                                        <select {...register('redcnDe')} defaultValue=''>
                                            <option value=''>선택</option>
                                            <option value='전력'>전력</option>
                                            <option value='가스'>도시가스</option>
                                            <option value='별도'>별도</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {selectCertDe !== 'BIL' &&
                                <>
                                    <div className="cont-wrap">
                                        <h3>전력 절감량</h3>
                                        <div className="cont-box">
                                            <input
                                                type="text"
                                                className="long figure"
                                                placeholder="숫자만 입력"
                                                {...register('pwrerRedcnMwh')}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                                    setValue('pwrerRedcnMwh', value);
                                                }}
                                            />MWh
                                            <input
                                                type="text"
                                                className="long figure mt10"
                                                placeholder="숫자만 입력"
                                                {...register('pwrerRedcnToe')}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                                    setValue('pwrerRedcnToe', value);
                                                }}
                                            />TOE
                                        </div>
                                    </div>
                                    <div className="cont-wrap">
                                        <h3>도시가스 절감량</h3>
                                        <div className="cont-box">
                                            <input
                                                type="text"
                                                className="long figure"
                                                placeholder="숫자만 입력"
                                                {...register('gasRedcnMj')}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                                    setValue('gasRedcnMj', value);
                                                }}
                                            />MJ
                                            <input
                                                type="text"
                                                className="long figure mt10"
                                                placeholder="숫자만 입력"
                                                {...register('gasRedcnToe')}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                                    setValue('gasRedcnToe', value);
                                                }}
                                            />TOE
                                        </div>
                                    </div>
                                    <div className="cont-wrap">
                                        <h3>총 에너지 절감량</h3>
                                        <div className="cont-box">
                                            <input type="text" className="long figure readonly" value={isNaN(Number(energyRedcnToe)) ? '숫자만 입력' : energyRedcnToe} placeholder="자동으로 계산됩니다" readOnly {...register('energyRedcnToe')} />TOE
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {/* 절감수치 종료 */}
                        <div className="comm_btn_wrap">
                            <button type="button" className="btn_cancel" onClick={handleCancle}>취소</button>
                            <button type="submit" className={`btn_next ${insertOn ? 'on' : ''}`}>등록</button> {/* 모두 체크 시 on클래스 붙음*/}
                        </div>
                    </form>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};

export default Write_MissionPage;