import React from 'react';

const PolicyPage = () => {
    sessionStorage.setItem('nowTab', String("홈"));
    return (
        <main>
            <h1 className="notext">전체 영역</h1>
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <p>
                        <br /><br />
                        <h1 style={{ fontSize: '40px', fontWeight: 'bold' }}>개인정보 처리방침</h1>
                        <br />
                        개인정보 수집·이용 안내
                        <br /><br />
                        수집하려는 개인정보의 항목
                        <br />
                        에너지전환마을 지원 플랫폼(이하 "플랫폼"라 합니다) 회원가입, 각종 서비스 등 기본적인 서비스 제공을 위한 필수정보와 맞춤 서비스 제공을 위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고 있습니다.
                        <br /><br />
                        수집항목
                        <br />
                        필수항목 : 닉네임, 거주지
                        <br />
                        선택항목 : 실명, 연락처, 주소
                        <br />
                        별도 수집되는 정보 : SNS/전화번호 개인식별코드, 서버 접근 기록, 통신사실확인 정보 등
                        <br /><br />
                        개인정보의 수집·이용 목적
                        <br />
                        플랫폼은 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 서비스하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.
                        <br /><br />

                        {'<'}개인정보 수집·이용 목적{'>'}
                        <br />
                        광주광역시 에너지전환마을 지원 플랫폼 콘텐츠 제공, E포인트 제공에 따르는 본인인증·상품권 교환 관리, 고지사항 전달 등 회원이 서비스 이용 중 필요가 있다고 인정되는 정보 및 광고 제공
                        <br /><br />
                        개인정보의 보유 및 이용기간
                        <br />
                        플랫폼은 회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다.
                        <br /><br />
                        {'<'}다만, 다음의 정보에 대해서는 아래의 사유로 보존합니다.{'>'}
                        <br />
                        보존 항목 : 이용자가 작성했던 게시글
                        <br />
                        보존 근거 : 서비스 이용의 혼선 방지
                        <br />
                        ※ 게시글 등은 회원탈퇴하시기 전 본인이 직접 삭제하셔야 합니다.
                        기타 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                        <br /><br />
                        개인정보 보유 기간
                        <br />
                        개인정보 동의일로부터 2년 (재 동의시 그 날부터 2년)
                        <br /><br />
                        개인정보보호는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                        <br /><br />
                        가. 파기절차
                        <br />
                        불필요한 개인정보 및 개인정보파일은 개인정보책임자의 책임 하에 내부방침 절차에 따라 다음과 같이 처리하고 있습니다.
                        <br />
                        - 개인정보의 파기 보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.
                        <br />
                        - 개인정보파일의
                        파기 개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을 파기합니다.
                        <br /><br />
                        나. 파기방법
                        <br />
                        1{')'} 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                        <br />
                        2{')'} 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                        <br /><br />
                        동의거부 권리알림
                        <br />
                        누구나 플랫폼 회원가입에 동의거부 버튼을 선택하심으로 동의거부 하실 수 있습니다. 동의 거부시, 회원으로서의 기본활동을 위한 정보 부족으로 회원가입은 하실 수 없으시며, 이에 따라 홈페이지를 통한 회원 로그인, 회원용 서비스 이용은 제한이 됩니다.
                    </p>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>

    );
};

export default PolicyPage;