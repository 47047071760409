import React, { useEffect, useState } from 'react';
import './List_IntroMainPage.scss';
import NavComponent from '../../../components/common/NavComponent';
import CommTab from '../CommTab';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../dialog/DialogProvider';
import Write_VillageHistoryDialog from '../../../dialog/infro/Write_VillageCaseDialog';
import Modify_VillageHistoryDialog from '../../../dialog/infro/Modify_VillageCaseDialog';
import Map_IntroMainPage from './Map_IntroMainPage';
import IntroInfo, { CaseInfo } from '../../../models/IntroInfo';
import IntroAPI from '../../../apis/IntroAPI';
import Write_VillageCaseDialog from '../../../dialog/infro/Write_VillageCaseDialog';
import Modify_VillageCaseDialog from '../../../dialog/infro/Modify_VillageCaseDialog';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TownList from '../../../models/TownList';
import ModifyFailDialog from '../../../dialog/common/ModifyFailDialog';
import ModifyDialog from '../../../dialog/common/ModifyDialog';
import DeleteDialog from '../../../dialog/common/DeleteDialog';
import useMember from '../../../hooks/useMember';
import defaultImg from '../../../assets/img_gjd.png';
// 소통마당 > 마을소통
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];

const List_IntroMainPage = () => {
	const townId = sessionStorage.getItem('town');
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const { member } = useMember();
	const [townIntro, setTownIntro] = useState<IntroInfo>();
	// 소개 등록 및 관리, 사례 등록 및 수정, 삭제
	const [postRoles, setPostRoles] = useState(false);
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setPostRoles(true);

				// setNewsCateRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO?.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setPostRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log('권한 : 마을활동가, 마을주민');

				// if(member.townId === townId && member.userId === crtUid){
				// setPostRoles(true);
				// }
			}
		}
		// console.log('postRoles', postRoles);
	}, [townId]);
	// 마을 소개 조회
	useEffect(() => {
		IntroAPI.getIntroInfo(Number(townId))
			.then((res) => {
				// console.log('getIntroInfo', res);
				setTownIntro(res);
			})
			.catch((e) => { });
	}, []);
	return (
		<main className="List_IntroMainPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>

				<div id="content">
					<CommTab clickTab={5} />
					<div className="village_intro mt40">
						{/* <!-- 네트워크 시작 --> */}
						{/* <!-- 이미지가 없을경우 noimg --> */}
						<Network townIntro={townIntro} postRoles={postRoles} />
						{/* <!-- 네트워크 종료 -->
                        <!-- 거점센터 시작 -->
                        <!-- 이미지가 없을경우 noimg --> */}
						<Center townIntro={townIntro} postRoles={postRoles} />
						{/* <!-- 거점센터 종료 -->
                        <!-- 활동사례 시작 --> */}
						<CaseList postRoles={postRoles} />
						{/* <!-- 활동사례 종료 --> */}
					</div>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};
const Network = ({
	townIntro,
	postRoles,
}: {
	townIntro: IntroInfo | undefined;
	postRoles: boolean;
}) => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	// 소개 등록
	const handleIntroWrite = () => {
		navigate('/commu_02/intro_04-write-village');
	};
	// 소개 관리
	const handleIntroMng = () => {
		navigate('/commu_02/intro_04-modify-village');
	};

	return (
		<div
			// className="intro_wrap"
			className={
				townIntro?.networkFilePath && townIntro?.networkFilePath !== 'null'
					? 'intro_wrap'
					: 'intro_wrap noimg'
			}
		>
			<div className="intro_title">
				<h3>에너지전환마을 네트워크</h3>
				{postRoles && (
					<>
						{townIntro && townIntro?.projectName ? (
							<>
								<button
									type="button"
									// style={{ display: 'none' }}
									onClick={handleIntroMng}
								>
									소개 관리
								</button>
							</>
						) : (
							<>
								<button type="button" onClick={handleIntroWrite}>
									소개 등록
								</button>
							</>
						)}
					</>
				)}

				{/* 컨텐츠가 있을 경우 관리(수정)버튼이 나와야 함 */}
			</div>
			<div className="intro_content mt25">
				<div className="intro_img">
					{townIntro &&
						townIntro.networkFilePath &&
						townIntro.networkFilePath !== 'null' ? (
						<>
							<img
								src={
									townIntro &&
									townIntro.networkFilePath &&
									`/api/attach/getFile?savedPath=${townIntro.networkFilePath}&download=false`
								}
								alt="마을 이미지"
							/>
						</>
					) : (
						<>
							<img src={defaultImg} alt="마을 이미지" />
						</>
					)}
				</div>
				<div className="intro_table">
					<ul>
						<li>
							<h4>사업명</h4>
							<div className="pa20">
								{townIntro?.projectName &&
									townIntro?.projectName.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
							</div>
						</li>
						<li>
							<h4>사업목적</h4>
							<div className="pa20">
								{townIntro?.projectPurpose &&
									townIntro?.projectPurpose.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
							</div>
						</li>
						<li>
							<h4>사업지역</h4>
							<div className="pa20">
								{townIntro?.projectRegion &&
									townIntro?.projectRegion.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
							</div>
						</li>
						<li>
							<h4>
								네트워크
								<br />
								참여연대
							</h4>
							<div className="pa20">
								{townIntro?.networkPartner &&
									townIntro?.networkPartner.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
const Center = ({
	townIntro,
	postRoles,
}: {
	townIntro: IntroInfo | undefined;
	postRoles: boolean;
}) => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	// 센터 등록
	const handleCenterWrite = () => {
		navigate('/commu_02/intro_04-write-center');
	};
	// 센터 관리
	const handleCenterMng = () => {
		navigate('/commu_02/intro_04-modify-center');
	};
	return (
		<div
			// className="intro_wrap mt40"
			className={
				townIntro?.centerFilePath && townIntro?.centerFilePath !== 'null'
					? 'intro_wrap mt40'
					: 'intro_wrap mt40 noimg'
			}
		>
			<div className="intro_title">
				<h3>에너지전환마을 거점센터</h3>
				{postRoles && (
					<>
						{townIntro && townIntro?.centerName ? (
							<>
								<button
									type="button"
									// style={{ display: 'none' }}
									onClick={handleCenterMng}
								>
									센터 관리
								</button>
							</>
						) : (
							<>
								<button type="button" onClick={handleCenterWrite}>
									센터 등록
								</button>
								{/* 컨텐츠가 있을 경우 관리(수정)버튼이 나와야 함 */}
							</>
						)}
					</>
				)}
			</div>
			<div
				className="intro_content mt25"
			// className={
			// 	townIntro && townIntro?.centerFilePath
			// 		? 'intro_content mt25'
			// 		: 'intro_content mt25 noimg'
			// }
			>
				<div className="intro_img">
					{townIntro &&
						townIntro.centerFilePath &&
						townIntro.centerFilePath !== 'null' ? (
						<>
							<img
								src={
									townIntro &&
									townIntro.centerFilePath &&
									`/api/attach/getFile?savedPath=${townIntro.centerFilePath}&download=false`
								}
								alt="마을 이미지"
							/>
						</>
					) : (
						<>
							<img src={defaultImg} alt="마을 이미지" />
						</>
					)}
				</div>
				<div className="intro_table">
					<ul>
						<li>
							<h4>거점센터명</h4>
							<div className="pa20">
								{townIntro?.centerName &&
									townIntro?.centerName.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
							</div>
						</li>
						<li>
							<h4>개소식</h4>
							<div className="pa20">
								{townIntro?.openDt}
								<p className="mt10">
									{/* - 거점센터 디자인스쿨 (5회 10여명 내외 참여)
									<br />
									- 3kW 상당 옥상 태양광 패널 및 태양광 차양막 설치
									<br />
									- 거점센터 디자인스쿨 (5회 10여명 내외 참여)
									<br />
									- 3kW 상당 옥상 태양광 패널 및 태양광 차양막 설치
									<br />
									- 거점센터 디자인스쿨 (5회 10여명 내외 참여)
									<br />- 3kW 상당 옥상 태양광 패널 및 태양광 차양막 설치 */}
									{townIntro?.openingCeremony &&
										townIntro?.openingCeremony
											.split('\n')
											.map((item, index) => (
												<React.Fragment key={index}>
													{item}
													<br />
												</React.Fragment>
											))}
								</p>
							</div>
						</li>
						<li>
							<h4>위치</h4>

							<Map_IntroMainPage townIntro={townIntro} />
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
const CaseList = ({ postRoles }: { postRoles: boolean }) => {
	const tcUid = sessionStorage.getItem('town');
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [caseList, setCaseList] = useState([] as CaseInfo[]);
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	useEffect(() => {
		const listParams: {
			tcUid: number;
			page?: number;
			size?: number;
		} = {
			tcUid: Number(tcUid),
			// page: Number(page) - 1,
			size: 8,
		};
		IntroAPI.getCaseList(listParams).then((res) => {
			// console.log('getCaseList::::', res);
			setCaseList(res.content);
			// if (res?.content?.tccfList) {
			// 	const files: File[] = [];
			// 	const promises: Promise<any>[] = [];
			// 	for (let i = 0; i < res?.content?.tccfList.length; i++) {
			// 		const promise = IntroAPI.getImg({
			// 			savedPath: res?.content?.tccfList[i].filePath,
			// 			// download: false,
			// 		}).then((data) => {
			// 			// console.log(' ::::::::', res);

			// 			if (data) {
			// 				const file = new File(
			// 					[data.data],
			// 					res?.content?.tccfList[i].fileRealName,
			// 					{
			// 						type: 'image/png',
			// 					}
			// 				);
			// 				files.push(file);
			// 				// // 미리보기 이미지 추가
			// 				const previewImage = `/api/attach/getFile?savedPath=${res?.content?.tccfList[i].filePath}`;
			// 				setPreviewImages((prevImages) => [...prevImages, previewImage]);
			// 			}
			// 		});

			// 		promises.push(promise);
			// 	}
			// }
		});
	}, []);
	// 사례 등록
	const handleCaseWrite = () => {
		showDialog(Write_VillageCaseDialog);
	};
	// 사례 수정
	const handleCaseModify = (tccUid: number) => {
		sessionStorage.setItem('tccUid', String(tccUid));
		// console.log('handleCaseModify');
		const tab = 'case';
		showDialog(ModifyDialog, { tab });
	};
	// 사례 삭제
	const handleCaseDel = (tccUid: number) => {
		const tab = 'introCase';
		showDialog(DeleteDialog, { tab, tccUid });
	};

	return (
		<div className="intro_wrap mt40">
			<div className="intro_title">
				<h3>에너지전환마을 활동사례</h3>
				{postRoles && (
					<>
						<button type="button" onClick={handleCaseWrite}>
							사례 등록
						</button>{' '}
					</>
				)}
			</div>
			<div className="intro_content case mt25">
				<ul>
					{caseList &&
						caseList.map((list, index) => (
							<li key={index}>
								{/* <!-- 이미지 없으면 noimg className 붙음 --> */}
								<div
									// className="box_case pa30"
									className={
										list?.tccfList && list?.tccfList.length === 0
											? 'box_case pa30 noimg'
											: 'box_case pa30'
									}
								>
									<h5>
										{list?.name &&
											list?.name.split('\n').map((item, index) => (
												<React.Fragment key={index}>
													{item}
													<br />
												</React.Fragment>
											))}
									</h5>
									<div className="case_txt">
										{/* <p>
											• 오치1동 주민총회, 삼각동 주민총회, 문산마을문제, 문흥초
											· 신용초 · 자연과학고 용봉중 등 학교 축제
											<br />
										</p>
										<p>• 에너지 부스 운영</p> */}
										<p>
											{' '}
											{list.content &&
												list.content.split('\n').map((item, index) => (
													<React.Fragment key={index}>
														{item}
														<br />
													</React.Fragment>
												))}
										</p>
									</div>
									<div className="case_img swiper imgSwiper5">
										<Swiper
											className="mySwiper"
											modules={[Navigation, Pagination]}
											loop={false}
											// autoplay={{
											// 	delay: 2000,
											// 	// pauseOnMouseEnter: true,
											// 	// disableOnInteraction: false,
											// }}
											navigation={{
												nextEl: '.swiper-button-next', // 다음 버튼 클래스 이름
												prevEl: '.swiper-button-prev', // 이전 버튼 클래스 이름
											}}
											pagination={{
												el: '.pagination',
												type: 'fraction',
											}}
											slidesPerView={5}
										// 이 부분을 슬라이드 개수와 동일한 값으로 설정하세요.
										>
											{/* <ul className="swiper-wrapper">
												{list?.tccfList &&
													list?.tccfList.map((imgList, imgIndex) => (
														<SwiperSlide
															className="swiper-slide"
															key={imgIndex}
														>
															<img
																src={`/api/attach/getFile?savedPath=${
																	imgList?.filePath
																}&download=${false}`}
																alt="활동사례 이미지"
															/>
														</SwiperSlide>
													))}
											</ul> */}
											<ul className="swiper-wrapper">
												<li>
													<img
														src={`/api/attach/getFile?savedPath=${list?.tccfList[0]?.filePath
															}&download=${false}`}
														alt="활동사례 이미지"
													/>
												</li>
											</ul>

											<div
												className="swiper-button-next"
												style={{ top: '60px' }}
											></div>
											<div
												className="swiper-button-prev"
												style={{ top: '60px' }}
											></div>
											{/* <div className="swiper-pagination"></div> */}
											{/* <span className="swiper-notification"></span> */}
										</Swiper>
									</div>
									{postRoles && (
										<>
											<div className="case_btn">
												<button
													type="button"
													onClick={() => handleCaseModify(list?.tccUid)}
												>
													수정
												</button>
												<button
													type="button"
													onClick={() => handleCaseDel(list?.tccUid)}
												>
													삭제
												</button>
											</div>
										</>
									)}
								</div>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};
export default List_IntroMainPage;
