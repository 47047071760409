import React, { useEffect } from 'react';
import './PeopleDialog.scss';
import { DialogProps } from '../DialogProvider';
import { CmMasterVO } from '../../models/MappingList';
interface PeopleDialog extends DialogProps {
	type?: '';
	cmMasterVO: CmMasterVO;
}
const PeopleDialog = ({ cmMasterVO, ...props }: PeopleDialog) => {
	const handleClose = () => {
		props.onClose?.();
	};
	useEffect(() => {
		// console.log('cmMasterVO :::: ', cmMasterVO);
		// console.log('cmMasterVO?.nameList.length', cmMasterVO?.nameList?.length);
	}, [cmMasterVO]);
	return (
		<div className="PeopleDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>참여자 목록</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>{' '}
					<div className="comm_popup_content list_table mt25">
						<ul className="thead pa20">
							<li>참여자명</li>
						</ul>
						{cmMasterVO?.nameList && cmMasterVO?.nameList.length !== 0 ? (
							<ul className="tbody">
								{cmMasterVO?.nameList.map((list, index) => (
									<li className="pa20" key={index}>
										<p>{list}</p>
									</li>
								))}
							</ul>
						) : (
							<ul className="tbody">
								<li className="pa20">
									<p>참여한 사람이 없습니다.</p>
								</li>
							</ul>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PeopleDialog;
