import React from 'react';
import { DialogProps } from '../DialogProvider';

interface JoinAgreeDialog extends DialogProps {
	type: '';
}
const JoinAgreeDialog = ({ onClose }: JoinAgreeDialog) => {
	const handleClose = () => {
		onClose?.();
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>회원가입 약관동의</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">필수항목에 모두 동의해주십시오.</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default JoinAgreeDialog;
