import React, { useEffect } from 'react';
import {
	DEV_ROOT,
	N_DEV_URI,
	N_OPER_URI,
	N_REDIRECT_URI,
	OPER_ROOT,
} from '../../App';

const NaverLoginPage = () => {
	useEffect(() => {
		let N_URI = '';
		if (window.location.href.includes(DEV_ROOT)) {
			//개발
			N_URI = N_DEV_URI;
		} else if (window.location.href.includes(OPER_ROOT)) {
			//운영
			N_URI = N_OPER_URI;
		} else {
			//로컬
			N_URI = N_REDIRECT_URI;
		}
	}, []);
	return (
		<div>
			<h1 style={{ color: 'green' }}>Naver Redirect Page</h1>
		</div>
	);
};

export default NaverLoginPage;
