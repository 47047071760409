import React, { useEffect, useState } from 'react';
import './App.css';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
// import 컴포넌트 start
import MainPage from './pages/home/MainPage';

import TopNavBar from './components/common/TopNavBar';
import LoginPage from './pages/login/LoginPage';
import KakaoLoginPage from './pages/login/KakaoLoginPage';
import NaverLoginPage from './pages/login/NaverLoginPage';
import Popup_mobile_menu from './components/common/Popup_mobile_menu';
import Footer from './components/common/Footer';
import List_CommuPage from './pages/communicationYard/Main_CommuPage';
import EnergyStatusPage from './pages/informationYard/EnergyStatusPage';
import EnergyAnalysisPage from './pages/informationYard/EnergyAnalysisPage';
import CarbonCalculatorPage from './pages/informationYard/CarbonCalculatorPage';
import JoinInfoPage from './pages/join/JoinInfoPage';
import JoinVillagePage from './pages/join/JoinVillagePage';
import JoinAgreePage from './pages/join/JoinAgreePage';
import List_VillageCommuPage from './pages/communicationYard/VillageCommunication/List_VillageCommuPage';
import List01_ActiveMapPage from './pages/communicationYard/ActiveMap/List01_ActiveMapPage';
import List02_ActiveMapPage from './pages/communicationYard/ActiveMap/Detail_ActiveMapPage';
import Write_VillageCommuPage from './pages/communicationYard/VillageCommunication/Write_VillageCommuPage';
import Detail_ActiveMapPage from './pages/communicationYard/ActiveMap/Detail_ActiveMapPage';

import Write01_ActivePage from './pages/communicationYard/Activity/Write01_ActivePage';

import Detail_VillageCommuPage from './pages/communicationYard/VillageCommunication/Detail_VillageCommuPage';
import Modify_VillageCommuPage from './pages/communicationYard/VillageCommunication/Modify_VillageCommuPage';
import Main_CommuPage from './pages/communicationYard/Main_CommuPage';
import Main_VillagePage from './pages/communicationYard/Main_VillagePage';
import Write02_ActiveMapPage from './pages/communicationYard/ActiveMap/Write02_ActiveMapPage';
import Write01_ActiveMapPage from './pages/communicationYard/ActiveMap/Write01_ActiveMapPage';

import Modify01_ActiveMapPage from './pages/communicationYard/ActiveMap/Modify01_ActiveMapPage';
import List01_ActivePage from './pages/communicationYard/Activity/List01_ActivePage';
import Detail_ActivePage from './pages/communicationYard/Activity/Detail_ActivePage';
import Write02_ActivePage from './pages/communicationYard/Activity/Write02_ActivePage';
import List02_ActivePage from './pages/communicationYard/Activity/List02_ActivePage';
import List_ProgramPage from './pages/communicationYard/EduProgram/List_ProgramPage';
import Detail_ProgramPage from './pages/communicationYard/EduProgram/Detail_ProgramPage';
import Write_ProgramPage from './pages/communicationYard/EduProgram/Write_ProgramPage';
import Modify01_ActivePage from './pages/communicationYard/Activity/Modify01_ActivePage';
import Modify02_ActivePage from './pages/communicationYard/Activity/Modify02_ActivePage';
import List_VillageManagePage from './pages/communicationYard/VillageManagement/List_VillageManagePage';
import List_IntroMainPage from './pages/communicationYard/VillageIntroduction/List_IntroMainPage';
import Write01_VillageIntroPage from './pages/communicationYard/VillageIntroduction/Write01_VillageIntroPage';
import Write02_CenterInroPage from './pages/communicationYard/VillageIntroduction/Write02_CenterInroPage';
import Modify01_VillageIntroPage from './pages/communicationYard/VillageIntroduction/Modify01_VillageIntroPage';
import Modify02_CenterIntroPage from './pages/communicationYard/VillageIntroduction/Modify02_CenterIntroPage';
import Main_MyPage from './pages/mypage/Main_MyPage';
import MyInfoModify from './pages/mypage/MyInfoModify';
import MyProgram from './pages/mypage/MyProgram';
import MyAlarm from './pages/mypage/MyAlarm';
import MyVillageModify from './pages/mypage/MyIVillageModify';
import Modify_ProgramPage from './pages/communicationYard/EduProgram/Modify_ProgramPage';
import Detail_MissionPage from './pages/missionYard/Detail_MissionPage';
import Detail_VillageMissionPage from './pages/missionYard/Detail_VillageMissionPage';
import Main_MissionPage from './pages/missionYard/Main_MissionPage';
import List_MissionPage from './pages/missionYard/List_MissionPage';
import List_VillageMissionPage from './pages/missionYard/List_VillageMissionPage';
import Write_VillageMissionPage from './pages/missionYard/Write_VillageMissionPage';
import Write_MissionPage from './pages/missionYard/Write_MissionPage';
import Modify_VillageMissionPage from './pages/missionYard/Modify_VillageMissionPage';
import Modify_MissionPage from './pages/missionYard/Modify_MissionPage';
import Main_ChallengePage from './pages/missionYard/Main_ChallengePage';

import Detail_ChallengePage from './pages/missionYard/Detail_ChallengePage';
import Write_ChallengePage from './pages/missionYard/Write_ChallengePage';
import Modify_ChallengePage from './pages/missionYard/Modify_ChallengePage';
import List_ChallengePage from './pages/missionYard/List_ChallengePage';
import PolicyPage from './pages/policy/PolicyPage';
import UsePolicyPage from './pages/policy/UsePolicyPage';
import MissionYardPage from './pages/missionYard/MissionYardPage';

import EnergyMapPage from './pages/informationYard/EnergyMapPage';
import Write_MapMissionPage from './pages/missionYard/Write_MapMissionPage';
import Modify_MapMissionPage from './pages/missionYard/Modify_MapMissionPage';
import Detail_MapMissionPage from './pages/missionYard/Detail_MapMissionPage';
import MyEPointPage from './pages/mypage/MyEPointPage';
import MyEnergyPage from './pages/mypage/MyEnergyPage';
import AdminPage_Member from './pages/adminMenu/AdminPage_Member';
import AdminPage_Point from './pages/adminMenu/AdminPage_Point';
import AdminPage_Static from './pages/adminMenu/AdminPage_Static';
import VillGiftCardPage from './pages/mypage/myEPoint/VillGiftCardPage';
import MyGiftCardPage from './pages/mypage/myEPoint/MyGiftCardPage';
import PointGiftPage from './pages/mypage/myEPoint/PointGiftPage';
import List_PointGiftPage from './pages/mypage/myEPoint/List_PointGiftPage';
import EnergyMapTest from './pages/informationYard/EnergyMapTest';
import Main_NoticePage from './pages/notice/Main_NoticePage';
import Detail_NoticePage from './pages/notice/Detail_NoticePage';
import Main_PopupManagement from './pages/notice/Main_PopupManagement';
import Detail_PopupManagement from './pages/notice/Detail_PopupManagement';
import Modify_PopupManagement from './pages/notice/Modify_PopupManagement';
import Write_NoticePage from './pages/notice/Write_NoticePage';
import Modify_NoticePage from './pages/notice/Modify_NoticePage';
import moment from 'moment';
import UtilizationAPI from './apis/UtilizationAPI';
import AdminPage_TodayList from './pages/adminMenu/AdminPage_TodayList';
import PlatformPage from './pages/platformInfo/PlatformPage';
import DictionaryPage from './pages/dictionary/DictionaryPage';
import EnergyConversionVillage from './pages/platformInfo/EnergyConversionVillage';

// import 컴포넌트 end
// 카카오
export const K_REST_API_KEY = '0795e56744158092b9a0f8891dff586e';
export const K_REDIRECT_URI = 'http://localhost:3000/login';
export const K_DEV_URI = 'http://nubiz.co.kr:12166/login';
export const K_OPER_URI = 'http://139.150.70.6/login';
// 네이버
export const N_REDIRECT_URI = 'http://localhost:3000/login/naver';
export const N_DEV_URI = 'http://nubiz.co.kr:12166/login/naver';
export const N_OPER_URI = 'http://139.150.70.6/login/naver';
export const N_CLIENT_ID = '8jIMsFH3TnTPg_ZZTYpJ';
export const N_CLIENT_SECRET = 'QX95eUgFKy';
// 공통
export const DEV_ROOT = 'http://nubiz.co.kr:12166';
export const OPER_ROOT = '139.150.70.6';
function App() {
	const [isLoading, setIsLoading] = useState(true);
	const userJSON = localStorage.getItem('todayId');
	const toDayUserId = userJSON ? JSON.parse(userJSON) : null;
	const location = useLocation();


	const generateRandomString = () => {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';
		const charactersLength = characters.length;
		for (let i = 0; i < 10; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	useEffect(() => {
		if (!toDayUserId) {
			const toDay = new Date();
			const todayId = String(generateRandomString())
			const user = {
				id: todayId,
				today: moment(toDay).format('YYYY-MM-DD'),
			}
			// console.log("난수 ", generateRandomString());
			// console.log("투데이", toDay);
			localStorage.setItem('todayId', JSON.stringify(user));
			// localStorage.setItem('today', moment(toDay).format('YYYY-MM-DD'));
		} else if (toDayUserId) {
			const userDt = Number(moment(toDayUserId.today).format('YYYYMMDD'));
			const toDay = Number(moment().format('YYYYMMDD'));
			if (userDt < toDay) {
				localStorage.removeItem('todayId');
				localStorage.removeItem('mainMenu');
				localStorage.removeItem('commuMenu');
				localStorage.removeItem('rewardMenu');
				localStorage.removeItem('infoMenu');
				window.location.reload();
			}
		}
	}, [])

	useEffect(() => {
		if (toDayUserId) {

		}
	}, [])

	// 24.08.13 - 모바일 웹 모든 input, textarea 리로드로 인한 입력불가 이슈로 주석처리 
	// useEffect(() => {
	// 	const handleResize = () => {
	// 		// setWindowWidth(window.innerWidth);
	// 		window.location.reload();
	// 	};
	// 	window.addEventListener('resize', handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, []);

	useEffect(() => {
		// 카카오 지도 API 스크립트 동적으로 로드
		// console.log("sdk 로드됨");
		const script = document.createElement('script');
		script.async = true;
		// // 이라는 상대 프로토콜을 사용하면, 사용자의 http, https 환경에 따라 자동으로 해당 프로토콜을 따라가게 됩니다.
		// script.src = `http://dapi.kakao.com/v2/maps/sdk.js?appkey=71d7c09b225d39c20b7775fba998c79e&autoload=false&libraries=services,clusterer,drawing`;
		// script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=71d7c09b225d39c20b7775fba998c79e&autoload=false&libraries=services,clusterer,drawing`;
		// script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=7698d9f589d87240429705c28f1698e6&autoload=false&libraries=services,clusterer,drawing`;
		script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=0ef7429ada9464f8b1b3db7fb8cdbf26&autoload=false&libraries=services,clusterer,drawing`;
		document.head.appendChild(script);

		script.onload = () => {
			window.kakao.maps.load(() => {
				setIsLoading(false);
			});
		};
	}, []);


	let wrapperClassName = 'main_wrapper';
	if (location.pathname.includes('_02')) {
		if (location.pathname.includes('_03')) {
			wrapperClassName = 'sub_village_wrapper';
		} else if (location.pathname.includes('_04')) {
			wrapperClassName = 'write_wrapper';
		} else if (location.pathname.includes('_05')) {
			wrapperClassName = 'detail_wrapper';
		} else {
			wrapperClassName = 'sub_wrapper';
		}
	} else if (location.pathname.includes('_03')) {
		wrapperClassName = 'sub_village_wrapper';
	} else if (location.pathname.includes('_04')) {
		wrapperClassName = 'write_wrapper';
	}



	const routes = useRoutes([
		{
			path: '/*',
			element: <MainPage />,
			// element: <Navigate to="/commu_02/1" replace />,
		},
		{
			path: '/join_04/*',

			children: [
				{
					path: '*',
					element: <Navigate to="/join_04/*" replace />,
				},
				{ path: ':page', element: <JoinAgreePage /> }, // 회원가입 약관동의 페이지
				{ path: 'village', element: <JoinVillagePage /> }, // 회원가입 마을선택 페이지
				{ path: 'info', element: <JoinInfoPage /> }, // 회원가입 회원정보 입력 페이지
			],
		},
		{
			path: '/policy/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/policy/*" replace />,
				},
				{ path: 'policyPage', element: <PolicyPage /> },
				{ path: 'usePolicyPage', element: <UsePolicyPage /> },
			],
		},
		// {
		// 	path: '/login_04/*',
		// 	element: <LoginPage />,
		// },
		// {
		// 	path: '/login/kakao/*',
		// 	element: <KakaoLoginPage />,
		// },
		{
			path: '/login/naver/*',
			element: <NaverLoginPage />,
		},
		// 마이페이지
		{
			path: '/myPage_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/myPage_02/1" replace />,
				},
				// 마이페이지 메인
				{ path: ':page', element: <Main_MyPage /> }, // 마이페이지 메인
				{ path: 'myInfoModify_04', element: <MyInfoModify /> }, // 마이페이지 > 내 정보 수정
				{ path: 'myVillageModify_04', element: <MyVillageModify /> }, // 마이페이지 > 내 마을 변경
				{ path: 'myProgram_04', element: <MyProgram /> }, // 마이페이지 > 내 교육프로그램
				{ path: 'myAlarm_04', element: <MyAlarm /> }, // 마이페이지 > 내 알람
				{ path: 'myEPoint', element: <MyEPointPage /> }, // 마이페이지 > 내 E 포인트
				{ path: 'myEnergy', element: <MyEnergyPage /> }, // 마이페이지 > 내 에너지 활동
				{ path: 'villGiftCard_04', element: <VillGiftCardPage /> }, // 마이페이지 > 내 E포인트 > 마을상품권 교환
				{ path: 'myGiftCard_04', element: <MyGiftCardPage /> }, // 마이페이지 > 내 E포인트 > 내 마을상품권
				{ path: 'pointGift_04', element: <PointGiftPage /> }, // 마이페이지 > 내 E포인트 > 포인트 선물하기
				{ path: 'pointGift_04-list', element: <List_PointGiftPage /> }, // 마이페이지 > 내 E포인트 > 포인트 선물내역
			],
		},
		{
			// 공지사항
			path: '/notice_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/notice_02/list" replace />,
				},
				{ path: 'list', element: <Main_NoticePage /> }, // 공지사항 메인
				{ path: 'notice_03-detail', element: <Detail_NoticePage /> }, // 공지사항 상세
				{ path: 'notice_04-write', element: <Write_NoticePage /> }, // 공지사항 등록
				{ path: 'notice_04-modify', element: <Modify_NoticePage /> }, // 공지사항 수정
			]
		},
		{
			// 팝업 관리
			path: '/popup_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/popup_02/1" replace />,
				},
				{ path: ':page', element: <Main_PopupManagement /> }, // 팝업 메인
				{ path: 'popup_04-detail', element: <Detail_PopupManagement /> }, // 팝업 등록
				{ path: 'popup_04-modify', element: <Modify_PopupManagement /> }, // 팝업 수정
			]
		},
		{
			// 소통마당
			path: '/commu_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/commu_02/1" replace />,
				},
				// 소통마당
				{ path: ':page', element: <Main_CommuPage /> }, // 소통마당 메인
				{ path: 'mainVillage_03', element: <Main_VillagePage /> }, // 소통마당 > 마을 메인
				//마을소통
				{ path: 'villageCm_03', element: <List_VillageCommuPage /> }, // 마을소통
				{ path: 'villageCm_04-write', element: <Write_VillageCommuPage /> }, // 마을소통 > 등록하기
				{ path: 'villageCm_04-modify', element: <Modify_VillageCommuPage /> }, // 마을소통 > 수정하기
				{ path: 'villageCm_03-detail', element: <Detail_VillageCommuPage /> }, // 마을소통 > 상세
				// 활동지도
				{ path: 'actMap_03', element: <List01_ActiveMapPage /> }, // 활동지도
				{ path: 'actMap_04-write', element: <Write01_ActiveMapPage /> }, // 활동지도 > 등록하기
				{ path: 'actMap_04-modify', element: <Modify01_ActiveMapPage /> }, // 활동지도 > 등록하기
				{ path: 'actMap_03-detail', element: <Detail_ActiveMapPage /> }, // 활동지도 > 상세
				// { path: 'actMap_03-detail-write', element: <Write02_ActiveMapPage /> }, // 활동지도 > 상세 > 작성
				// { path: 'actMap_03-detail-modify', element: <Modify02_ActiveMapPage /> }, // 활동지도 > 상세 > 수정
				// 활동일지
				{ path: 'active_03', element: <List01_ActivePage /> }, // 활동일지 주제
				{ path: 'active_03-history', element: <List02_ActivePage /> }, // 활동일지 > 주제내역
				{ path: 'active_03-detail', element: <Detail_ActivePage /> }, // 활동일지 > 상세
				{ path: 'active_04-write', element: <Write01_ActivePage /> }, // 활동일지 > 주제등록
				{ path: 'active_04-write-history', element: <Write02_ActivePage /> }, // 활동일지 > 활동일지 주제 내역등록
				{ path: 'active_04-modify', element: <Modify01_ActivePage /> }, // 활동일지 > 주제수정
				{ path: 'active_04-modify-history', element: <Modify02_ActivePage /> }, // 활동일지 > 주제 내역수정
				// 교육프로그램
				{ path: 'eduProgram_03', element: <List_ProgramPage /> }, // 교육프로그램
				{ path: 'eduProgram_04-write', element: <Write_ProgramPage /> }, // 교육프로그램 > 등록
				{ path: 'eduProgram_04-modify', element: <Modify_ProgramPage /> }, // 교육프로그램 > 수정
				{ path: 'eduProgram_03-detail', element: <Detail_ProgramPage /> }, // 교육프로그램 > 상세

				{ path: 'intro_03', element: <List_IntroMainPage /> }, // 마을소개 메인
				{
					path: 'intro_04-write-village',
					element: <Write01_VillageIntroPage />,
				}, // 마을소개 > 마을 작성
				{
					path: 'intro_04-modify-village',
					element: <Modify01_VillageIntroPage />,
				}, // 마을소개 > 마을 작성 > 수정
				{
					path: 'intro_04-write-center',
					element: <Write02_CenterInroPage />,
				}, // 마을소개 > 센터 작성
				{
					path: 'intro_04-modify-center',
					element: <Modify02_CenterIntroPage />,
				}, // 마을소개 > 센터 작성 > 수정
				// 주민관리
				{ path: 'villageManage_03', element: <List_VillageManagePage /> }, // 주민관리
			],
		},
		{
			// 참여마당
			path: '/reward_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/reward_02/1" replace />,
				},
				{ path: ':page', element: <MissionYardPage /> }, // 에너지전환 활동미션
				{ path: 'mission-main', element: <Main_MissionPage /> }, // 에너지전환 활동미션
				{ path: 'mission_03-list', element: <List_MissionPage /> }, // 기본미션 > 목록
				{
					path: 'villageMission_04-write',
					element: <Write_VillageMissionPage />,
				}, // 마을미션
				{
					path: 'mapMission_04-write',
					element: <Write_MapMissionPage />,
				}, // 마을미션 > 지도미션 작성하기
				{
					path: 'mapMission_04-modify',
					element: <Modify_MapMissionPage />,
				}, // 마을미션 > 지도미션 수정하기
				{
					path: 'mapMission_05-detail',
					element: <Detail_MapMissionPage />,
				}, // 마을미션 > 지도미션 상세
				{ path: 'mission_04-write', element: <Write_MissionPage /> }, // 기본미션 등록
				{
					path: 'villageMission_04-modify',
					element: <Modify_VillageMissionPage />, // 기본미션 수정
				}, // 마을미션
				{ path: 'mission_04-modify', element: <Modify_MissionPage /> }, // 기본미션
				{ path: 'mission_05-detail', element: <Detail_MissionPage /> }, // 기본미션 > 상세
				{
					path: 'villageMission_03-list',
					element: <List_VillageMissionPage />,
				}, // 마을미션 > 목록
				{
					path: 'villageMission_05-detail',
					element: <Detail_VillageMissionPage />,
				}, // 마을미션 > 상세
				{ path: 'challenge-main', element: <Main_ChallengePage /> }, // 챌린지 > 메인(내 챌린지 순위)
				{ path: 'challenge_05-list', element: <List_ChallengePage /> }, // 챌린지 > 메인 > 리스트
				{ path: 'challenge_05-detail', element: <Detail_ChallengePage /> }, // 챌린지 > 메인 > 리스트 > 상세
				{ path: 'challenge_04-write', element: <Write_ChallengePage /> }, // 챌린지 > 메인 > 작성
				{ path: 'challenge_04-modify', element: <Modify_ChallengePage /> }, // 챌린지 > 메인 > 수정
			],
		},
		{
			// 정보마당
			path: '/info_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/info_02/1" replace />,
				},
				{ path: ':page', element: <EnergyStatusPage /> },
				{ path: 'analysis', element: <EnergyAnalysisPage /> },
				{ path: 'map', element: <EnergyMapPage /> },
				{ path: 'map/test', element: <EnergyMapTest /> },
				{ path: 'cal', element: <CarbonCalculatorPage /> },
			],
		},
		{
			// 관리자 메뉴
			path: '/admin_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/admin_02/1" replace />,
				},
				{ path: ':page', element: <AdminPage_Member /> },
				{ path: 'point', element: <AdminPage_Point /> },
				{ path: 'static', element: <AdminPage_Static /> },
				{ path: 'today', element: <AdminPage_TodayList /> },
			],
		},
		{
			// 플랫폼 소개
			path: '/platform_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/platform_02/1" replace />,
				},
				{ path: ':page', element: <PlatformPage /> },
				{ path: 'energyConversionVillage', element: <EnergyConversionVillage /> },
			],
		},
		{
			// 용어 사전
			path: '/dictionary_02/*',
			children: [
				{
					path: '*',
					element: <Navigate to="/dictionary_02/1" replace />,
				},
				{ path: ':page', element: <DictionaryPage /> },
			],
		},
	]);
	// 모바일 사이드 바 설정
	useEffect(() => {
		// 모바일 햄버거 버튼 열고닫기

		// // --------------------------------------------------------------------------

		// document.querySelector('.mobile_ham')?.addEventListener('click', () => {
		// 	document.querySelector('.Poup_mobile_menu')?.classList.add('on');
		// });

		// document.querySelector('.Poup_mobile_menu .mobile_close')?.addEventListener('click', () => {
		// 	document.querySelector('.Poup_mobile_menu')?.classList.remove('on');
		// });

		// document.querySelector('.Poup_mobile_menu .mobile_menu_bg')?.addEventListener('click', () => {
		// 	document.querySelector('.Poup_mobile_menu')?.classList.remove('on');
		// });

		// // 팝업 닫기
		// document.querySelectorAll('#close').forEach(closeButton => {
		// 	closeButton.addEventListener('click', () => {
		// 		document.querySelector('.Poup_mobile_menu')?.classList.remove('on');
		// 	});
		// });

		// // 마이페이지/ 로그아웃 / 로그인
		// document.querySelector('.txt_blue.selectTab')?.addEventListener('click', () => {
		// 	document.querySelector('.Poup_mobile_menu')?.classList.remove('on');
		// });
		// 	$('body').on('click', '.mobile_gnb_dep1_li > a', function () {
		// 		const idx = $('.mobile_gnb_dep1_li').index($(this).parent());
		// 		$('.mobile_gnb_dep1_li').each(function (index) {
		// 			if (idx == index) {
		// 				console.log("this >>>>> ", this)
		// 				if ($(this).is('.on') == false) {
		// 					$(this).addClass('on');
		// 					$(this).children('ul').stop().slideDown(150);
		// 				} else {
		// 					$(this).removeClass('on');
		// 					$(this).children('ul').stop().slideUp(75);
		// 				}
		// 			} else {
		// 				$(this).removeClass('on');
		// 				$(this).children('ul').stop().slideUp(75);
		// 			}
		// 		});
		// 	});
		// 모바일 사이드메뉴 메뉴별 열고닫기
		// document.body.addEventListener('click', function (event: Event) {
		// 	const target = event.target as HTMLElement;

		// 	if (target.closest('.mobile_gnb_dep1_li > a')) {
		// 		event.preventDefault();
		// 		const parentLi = target.closest('.mobile_gnb_dep1_li') as HTMLElement;
		// 		const allLis = Array.from(document.querySelectorAll('.mobile_gnb_dep1_li')) as HTMLElement[];
		// 		const idx = allLis.indexOf(parentLi);

		// 		allLis.forEach((li, index) => {
		// 			const ul = li.querySelector('ul') as HTMLElement;
		// 			if (idx === index) {
		// 				if (!li.classList.contains('on')) {
		// 					li.classList.add('on');
		// 					ul.style.display = 'block';
		// 				} else {
		// 					li.classList.remove('on');
		// 					ul.style.display = 'none';
		// 				}
		// 			} else {
		// 				li.classList.remove('on');
		// 				ul.style.display = 'none';
		// 			}
		// 		});
		// 	}
		// });

		// --------------------------------------------------------------------------


		$('.mobile_ham').click(function () {
			$('.Poup_mobile_menu').addClass('on');
		});
		$('.Poup_mobile_menu .mobile_close').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});
		$('.Poup_mobile_menu .mobile_menu_bg').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});
		// 팝업 닫히기
		$('#close').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});
		$('.mobile_gnb_dep2 #close').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});
		// 모바일 이용약관
		$('.mobile_info #close').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});
		// 마이페이지/ 로그아웃 / 로그인
		$('.txt_blue.selectTab').click(function () {
			$('.Poup_mobile_menu').removeClass('on');
		});

		// // 모바일 사이드메뉴 메뉴별 열고닫기
		// $('body').on('click', '.mobile_gnb_dep1_li > a', function () {
		// 	const idx = $('.mobile_gnb_dep1_li').index($(this).parent());
		// 	$('.mobile_gnb_dep1_li').each(function (index) {
		// 		if (idx == index) {
		// 			// console.log("this >>>>> ", this)
		// 			if ($(this).is('.on') == false) {
		// 				$(this).addClass('on');
		// 				$(this).children('ul').stop().slideDown(150);
		// 			} else {
		// 				$(this).removeClass('on');
		// 				$(this).children('ul').stop().slideUp(75);
		// 			}
		// 		} else {
		// 			$(this).removeClass('on');
		// 			$(this).children('ul').stop().slideUp(75);
		// 		}
		// 	});
		// });
	}, [routes]);
	const commuMenu = localStorage.getItem('commuMenu');
	const rewardMenu = localStorage.getItem('rewardMenu');
	const infoMenu = localStorage.getItem('infoMenu');

	useEffect(() => {
		// console.log("라우트 변경 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
		let menuTitle = ''
		// 소통마당
		if (location.pathname.includes('commu_02')) {
			if (!commuMenu) {
				menuTitle = 'commu';
				localStorage.setItem('commuMenu', 'true');
				// api 호출
				// console.log('commu api 호출 !!!!!');
				if (toDayUserId) {
					UtilizationAPI.todayMenuCount({
						id: toDayUserId.id,
						mainItem: '소통마당'
					}).then((res) => {
						if (res) {
							// console.log("소통마당 투데이 카운트 >>>>> ", res);
						}
					})
				}
			}
		}
		// 참여마당
		else if (location.pathname.includes('reward_02')) {
			if (!rewardMenu) {
				menuTitle = 'reward';
				localStorage.setItem('rewardMenu', 'true');
				// api 호출
				//console.log('reward api 호출 !!!!!');
				if (toDayUserId) {
					UtilizationAPI.todayMenuCount({
						id: toDayUserId.id,
						mainItem: '참여마당'
					}).then((res) => {
						if (res) {
							// console.log("참여마당 투데이 카운트 >>>>> ", res);
						}
					})
				}
			}
		}
		// 정보마당
		else if (location.pathname.includes('info_02')) {
			if (!infoMenu) {
				menuTitle = 'info';
				localStorage.setItem('infoMenu', 'true');
				// api 호출
				//console.log('info api 호출 !!!!!');
				if (toDayUserId) {
					UtilizationAPI.todayMenuCount({
						id: toDayUserId.id,
						mainItem: '정보마당'
					}).then((res) => {
						if (res) {
							// console.log("정보마당 투데이 카운트 >>>>> ", res);
						}
					})
				}
			}
		}
		// console.log("메뉴 >>>>>>>>> ", menuTitle)
	}, [routes])

	if (isLoading) {
		return (
			<div className="dobanjangLoadingLogo">
				<p className="loadingDobanjang">로딩중입니다...</p>
				{/* <div className="loadingDobanjang"> */}
				{/* </div> */}
			</div>
		);
	}
	return (
		<div className="App">
			<div id="wrapper" className={wrapperClassName}>
				<TopNavBar />
				<Popup_mobile_menu type={''} />
				{routes}
				<Footer />
			</div>
		</div>
	);
}

export default App;
