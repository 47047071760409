import React, { ChangeEvent, useEffect, useState } from 'react';
import CommSelect from '../../components/common/CommSelect';
import SubLocation from '../../components/common/SubLocation';
import test04 from '../../../assets/test04.png';
import VillageCommuInfo, {
    boardCommentDTOS,
    formVillageCommuCmt,
    villageCommuContent,
} from '../../models/VillageCommuInfo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { content } from '../../models/TownList';
import VillageCommuAPI from '../../apis/VillageCommuAPI';
import { useDialog } from '../../dialog/DialogProvider';
import ShareDialog from '../../dialog/common/ShareDialog';
import NavComponent from '../../components/common/NavComponent';
import moment from 'moment';
import { listenerCount } from 'process';
import ModifyDialog from '../../dialog/common/ModifyDialog';
import DeleteDialog from '../../dialog/common/DeleteDialog';
import useMember from '../../hooks/useMember';
import PrivateDialog from '../../dialog/common/PrivateDialog';
import PublicDialog from '../../dialog/common/PublicDialog';
import PrivateSuccessDialog from '../../dialog/common/PrivateSuccessDialog';
import PrivateFailDialog from '../../dialog/common/PrivateFailDialog';
import MemberInfo from '../../models/MemberInfo';
import { data } from 'jquery';
import LikeRoleDialog from '../../dialog/common/LikeRoleDialog ';
import { SubmitHandler, useForm } from 'react-hook-form';
import CmtSubmitSuccessDialog from '../../dialog/common/CmtSubmitSuccessDialog';
import CmtSubmitFailDialog from '../../dialog/common/CmtSubmitFailDialog';
import CmtNonmemberDialog from '../../dialog/common/CmtNonmemberDialog';
import CmtDeleteSuccessDialog from '../../dialog/common/CmtDeleteSuccessDialog';
import CmtDeleteFailDialog from '../../dialog/common/CmtDeleteFailDialog';
import CmtDeleteDialog from '../../dialog/common/CmtDeleteDialog';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import CommTab from '../communicationYard/CommTab';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import NoticeAPI from '../../apis/NoticeAPI';
import { NoticeList } from '../../models/Notice';
import './Detail_NoticePage.scss';

// 소통마당 메인
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];

const Detail_NoticePage = () => {
    return (
        <main className="Detail_NoticePage">
            <h1 className="notext">전체 영역</h1>
            <Nav />
            <article id="container">
                <h2 className="notext">컨텐츠 영역</h2>
                {/* 해당 페이지의 시작입니다 */}
                <div id="content">
                    <div className="village_commu_detail mt40">
                        {/* 상세 글 정보 및 내용 */}
                        <Detail
                        />
                    </div>
                </div>
                {/* 해당 페이지의 종료입니다 */}
            </article>
        </main>
    );
};
const Nav = () => {
    return (
        <nav id="lnb">
            <h2 className="notext">네비게이션 영역</h2>
            <SubLocation titleText1="공지사항" liList={SubLocationList} />
            <div className="lnb_txt">
                <h3 style={{ display: 'block' }}>공지사항</h3>
                {/* <p>모든 마을의 최신 현황을 확인하고 내 마을에 들어가 소통해보세요</p> */}
            </div>
        </nav>
    );
};
// 상세 글 정보 및 내용
const Detail = () => {
    const { member } = useMember();
    const navigate = useNavigate();
    const { showDialog } = useDialog();
    const uid = sessionStorage.getItem('noticeN')
    const [noticeDetail, setNoticeDetail] = useState<NoticeList>()
    useEffect(() => {
        NoticeAPI.getNoticeDetail({
            nntUid: Number(uid)
        }).then((res) => {
            if (res) {
                // console.log(res);
                setNoticeDetail(res);
            }
        })
    }, [])
    // 수정
    const handleModify = () => {
        // navigate(`/commu_02/villageCm_04-modify?boardId=${boardId}`);
        const title = ''
        const message = '수정하시겠습니까?'
        const action = 'ok'
        showDialog(TwoButtonAlertDialog, {
            title,
            message,
            action,
            handleDialogResult(result) {
                if (result == '확인') {
                    navigate('/notice_02/notice_04-modify')
                }
            }
        });
    };

    // 삭제
    const handleDelete = () => {
        const title = ''
        const message = '삭제하시겠습니까?'
        const action = '인증ok'
        showDialog(TwoButtonAlertDialog, {
            title,
            message,
            action,
            handleDialogResult(result) {
                if (result == '확인') {
                    NoticeAPI.deleteNotice({
                        nntUid: Number(uid),
                    }).then((res) => {
                        if (res) {
                            const title = '공지사항'
                            const message = '삭제 되었습니다.'
                            showDialog(OneButtonAlertDialog, {
                                title,
                                message,
                                handleDialogResult(result) {
                                    if (result === 'ok') {
                                        navigate('/notice_02/list');
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        });
    };

    const handlePrivate = () => {
        const title = ''
        const message = noticeDetail?.status === 'N' ? '공개 하시겠습니까?' : '비공개 하시겠습니까?'
        const action = '인증ok'
        showDialog(TwoButtonAlertDialog, {
            title,
            message,
            action,
            handleDialogResult(result) {
                if (result == '확인') {
                    NoticeAPI.updateNotice({
                        nntUid: Number(uid),
                        status: noticeDetail?.status === 'N' ? 'Y' : 'N'
                    }).then((res) => {
                        if (res) {
                            const title = '공지사항'
                            const message = noticeDetail?.status === 'N' ? '공개 되었습니다.' : '비공개 되었습니다.'
                            showDialog(OneButtonAlertDialog, {
                                title,
                                message,
                                handleDialogResult(result) {
                                    if (result === 'ok') {
                                        window.location.reload();
                                    }
                                }
                            })
                        }
                    })
                }
            }
        });

    }

    // 목록
	const handleList = () => {
		navigate('/notice_02/list');
	};

    return (
        <>
             {member && member.roles && member.roles === 'AMN' && 
                <div className="comm_detail_title mb25">
                    <div className="comm_btn_set">
                        {noticeDetail && noticeDetail.status === 'N' ?
                            <button
                                type="button"
                                className="pub"
                                onClick={handlePrivate}
                            >
                                공개
                            </button>
                            :
                            <button
                                type="button"
                                className="non"
                                onClick={handlePrivate}
                            >
                                비공개
                            </button>
                        }
                        <>
                            <button
                                type="button"
                                onClick={handleModify}
                            >
                                수정
                            </button>
                            <button
                                type="button"
                                onClick={handleDelete}
                            >
                                삭제
                            </button>
                        </>
                    </div>
                </div>
            }
            <div className="comm_detail_cont pa30">
                <h2 className="title mb25">{noticeDetail && noticeDetail?.title}</h2>
                <div className="info01">
                    {/* 레벨별 class "Lv~" */}
                    {/* <p className="name Lv1"> */}
                    <p
                    //  className={
                    //     villageCommuDetail?.character === 1 ? 'name basic1 '
                    //         : villageCommuDetail?.character === 2 ? 'name basic2'
                    //             : villageCommuDetail?.character === 3 ? 'name Lv1'
                    //                 : villageCommuDetail?.character === 4 ? 'name Lv2'
                    //                     : villageCommuDetail?.character === 5 ? 'name Lv3'
                    //                         : villageCommuDetail?.character === 6 ? 'name Lv4'
                    //                             : ''
                    // }
                    >
                        관리자
                    </p>
                    <p>{noticeDetail && moment(noticeDetail?.crtDt).format('YYYY.MM.DD')}</p>
                </div>
                <p className="mt25">
                    {noticeDetail &&
                        noticeDetail?.content.split('\n').map((item, index) => (
                            <React.Fragment key={index}>
                                {item}
                                <br />
                            </React.Fragment>
                    ))}
                </p>
                {noticeDetail?.fileList[0] &&
					noticeDetail?.fileList.filter((imgList) => imgList.fileName.includes(".jpg") || imgList.fileName.includes(".png")
					).map((imgList2) => (
						<img
							className="mt25"
							key={imgList2.ntfUid}
							src={`/api/attach/getFile?savedPath=${imgList2.savePath}`}
						/>
					))}

				{noticeDetail?.fileList[0] &&
					noticeDetail.fileList.filter((docList) => docList.fileName.includes(".hwp") || docList.fileName.includes(".pdf")
                    ).map((docList2) => (
						<div className="fileList mt25">
							<a
								href={`/api/attach/getFile?savedPath=${docList2.savePath}&download=true`}
							>{docList2.fileName}</a>
						</div>
					))}
            </div>
            <div className="comm_detail_btn mt25">
				<a className="btn_list" onClick={handleList}>
					목록
				</a>
			</div>
        </>
    );
};

export default Detail_NoticePage;
