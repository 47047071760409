import React, { useEffect, useState } from 'react';
import '../missionYard/Detail_ChallengePage.scss';
import { useNavigate } from 'react-router-dom';
import SubLocation from '../../components/common/SubLocation';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import ChallengeAPI from '../../apis/ChallengeAPI';
import { ChallengeContent, ChallengeRewards } from '../../models/ChallengeInfo';
import useMember from '../../hooks/useMember';
import moment from 'moment';
import DetailChanllengeInfo from '../../models/DetailChallengeInfo';
import errorImg from '../../assets/errorImg.jpg';
import MissionInfo from '../../models/MissionInfo';
import { RankContent } from '../../models/MissionInfoDetail';
import noList from '../../assets/noList.png';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import LoginDialog from '../../dialog/Login/LoginDialog';
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/myEPoint', text: '내 E 포인트' },
];
const Detail_ChallengePage = () => {
	const { showDialog } = useDialog();
	const mciUid = sessionStorage.getItem('mciUid');
	const mypoint = sessionStorage.getItem('mypoint');
	const rank = sessionStorage.getItem('rank');
	const navigate = useNavigate();
	const { member } = useMember();
	const [tab, setTab] = useState<number>(1); // 1=현황, 2=순위
	const [challengeOne, setChallengeOne] = useState<DetailChanllengeInfo>(); // 단일조회
	const [toDoMissions, setToDoMissions] = useState([] as MissionInfo[]); // 수행할 미션목록
	const [myMissions, setMyMissions] = useState([] as MissionInfo[]); // 내 미션 현황
	const [rankData, setRankData] = useState([] as RankContent[]); // 순위 데이터
	const [openYn, setOpenYn] = useState<string>();
	const [openYnUpdate, setOpenYnUpdate] = useState(false);
	const [challengeRewards, setChallengeRewards] = useState(
		[] as ChallengeRewards[]
	); // 포인트 지급 순위 (보상기준)
	const [insertCheck, setInsertCheck] = useState(false);
	// 수정하기
	const handleModify = () => {
		const title = '';
		const message = '수정하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					navigate('/reward_02/challenge_04-modify');
				}
			},
		});
	};
	// 삭제하기
	const handleDelete = () => {
		const title = '';
		const message = '삭제하시겠습니까?';
		const action = '인증ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ChallengeAPI.deleteChallenge({
						mciUid: Number(mciUid),
						deletePlag: 'delete',
					}).then((res) => {
						if (res) {
							const title = '';
							const message = '삭제되었습니다';
							showDialog(OneButtonAlertDialog, {
								message,
								title,
								handleDialogResult(result) {
									if (result === 'ok') {
										navigate('/reward_02/challenge_05-list');
									}
								},
							});
						}
					});
				}
			},
		});
	};
	// 비공개
	const handlePrivate = () => {
		const title = '';
		const message = `챌린지를 ${openYn === 'N' ? '공개' : '비공개'
			} 하시겠습니까?`;
		const action = '인증ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					ChallengeAPI.privateChallenge({
						mciUid: Number(mciUid),
						openYn: openYn === 'N' ? 'Y' : 'N',
					})
						.then((res) => {
							// console.log(res);
							if (res) {
								const title = '';
								const message = `${openYn === 'N' ? '공개' : '비공개'
									}되었습니다`;
								showDialog(OneButtonAlertDialog, {
									message,
									title,
									handleDialogResult(result) {
										if (result === 'ok') {
											setOpenYnUpdate(!openYnUpdate);
											// navigate('/reward_02/challenge_05-list');
										}
									},
								});
							}
						})
						.catch((error) => {
							// console.log(error.response.data.message);
							const title = '';
							const message = `참여자가 있는 챌린지는 챌린지기간 종료 후\n비공개가 가능합니다`;
							showDialog(OneButtonAlertDialog, {
								message,
								title,
								handleDialogResult(result) {
									if (result === 'ok') {
										// navigate('/reward_02/challenge_05-list');
									}
								},
							});
						});
				}
			},
		});
	};
	// 목록
	const handleList = () => {
		navigate('/reward_02/challenge_05-list');
	};
	// 오늘 날짜
	const nowDate = new Date();
	useEffect(() => {
		ChallengeAPI.getChallengeOne({
			mciUid: Number(mciUid),
		})
			.then((res) => {
				// console.log('getChallengeOne :::::', res);
				// console.log('getChallengeOne :::::', res?.openYn);
				setChallengeOne(res);
				setToDoMissions(res?.toDoMissions);
				// console.log(':::::::::::::', res?.toDoMissions);

				setOpenYn(res.openYn);
				if (res.toDoMissions) {
					res.toDoMissions.map((list: any, index: number) => {
						const formattedStartDate = `${moment(list.startDt).format(
							'yyyy.MM.DD'
						)}`;
						const formattedEndDate = `${moment(list.endDt).format(
							'yyyy.MM.DD'
						)}`;
						const stDt = new Date(formattedStartDate);
						const edDt = new Date(formattedEndDate);
						// // console.log('엔드 데이트', edDt);
						// if (nowDate > edDt) {
						// 	list.status = '마감';
						// } else if (nowDate >= stDt && nowDate <= edDt) {
						// 	list.status = '진행';
						// } else {
						// 	list.status = '대기';
						// }
						// // console.log('챌린지 투두미션 상태 :::::', list?.status.split(''));

						// list.missionFiles.map((list2: any, index2: number) => {
						// 	list.thumbnail = list2.filePath;
						// });
						// list.startDate = formattedStartDate;
						// list.endDate = formattedEndDate;
						// // console.log('뭐임', formattedStartDate);
					});
				}

				setMyMissions(res?.myMissions);
				setRankData(res.rankings);
				setChallengeRewards(res?.challengeRewards);
			})
			.catch((e) => {
				// console.log('조회 실패:::::::');
			});
	}, [insertCheck, openYnUpdate]);
	// // console.log('myMissions', myMissions);
	// // console.log('todoMissions', toDoMissions);

	// 수행할 미션목록 상세페이지 이동
	const missionDetail = (mssnDe: string, mmiUid: String) => {
		// console.log('mssnDe:::::', mssnDe);
		sessionStorage.setItem('mmiUid', String(mmiUid));
		if (mssnDe === '기본미션') {
			// 기본 미션
			navigate('/reward_02/mission_05-detail');
		} else if (mssnDe === '마을미션') {
			// 마을 미션
			navigate('/reward_02/villageMission_05-detail');
		}
		// 지도미션 추가할 것 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	};

	// 참여하기 버튼
	const handleJoin = () => {
		// 로그인 함
		if (member && member?.id) {
			// 마을있음
			if (member?.townDTO?.townId) {
				// 부가정보 입력된 사람
				if (
					member?.username ||
					member?.telno ||
					member?.address ||
					member?.detailAddress
				) {
					if (challengeOne && !challengeOne?.participation) {
						const title = '참여하기';
						const message = '챌린지에 참여하시겠습니까?';
						const action = 'ok';
						showDialog(TwoButtonAlertDialog, {
							title,
							message,
							action,
							handleDialogResult(result) {
								if (result === '확인') {
									// 참여하기 API 호출
									ChallengeAPI.insertParticipate({
										mciUid: Number(mciUid),
									})
										.then((res) => {
											// console.log('참여하기 성공:::::');
											const title = '참여하기';
											const message = '해당 챌린지에 참여하였습니다.';
											showDialog(OneButtonAlertDialog, { title, message });
											setInsertCheck(true);
										})
										.catch((e) => {
											const title = '참여하기';
											const message = '해당 챌린지 참여하기에 실패하였습니다.';
											showDialog(OneButtonAlertDialog, { title, message });
										});
								}
							},
						});
					} else if (challengeOne && challengeOne?.participation) {
						const title = '참여하기';
						const message = '해당 챌린지에 이미 참여하였습니다.';
						showDialog(OneButtonAlertDialog, { title, message });
					}
				}
				// 부가정보 입력 안한 사람
				else {
					const title = '참여하기';
					const message =
						'에너지전환 활동을 위해서는 부가정보 입력이 필요합니다. 입력하시겠습니까?';
					const action = 'ok';
					showDialog(TwoButtonAlertDialog, {
						title,
						message,
						action,
						handleDialogResult(result) {
							if (result === '확인') {
								navigate('/myPage_02/myInfoModify_04');
							}
						},
					});
				}

				//  마을 없음
			} else {
				const title = '참여하기';
				const message =
					'소속된 마을이 없으면 챌린지에 참여할 수 없습니다. 마을을 선택하시겠습니까?';
				const action = 'ok';
				showDialog(TwoButtonAlertDialog, {
					title,
					message,
					action,
					handleDialogResult(result) {
						if (result === '확인') {
							navigate('/myPage_02');
						}
					},
				});
			}
		}
		// 비로그인
		else {
			const title = '참여하기';
			const message = '챌린지 참여를 위해 로그인해주세요';
			const action = '인증ok';

			showDialog(TwoButtonAlertDialog, {
				title,
				message,
				action,
				handleDialogResult(result) {
					if (result === '확인') {
						showDialog(LoginDialog, {
							title: '로그인',
						});
					}
				},
			});
		}
	};
	// console.log('ggggggg', insertCheck);

	return (
		<main className="Detail_ChallengePage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 챌린지"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>에너지전환활동 챌린지</h3>
					{/* <select>
						<option>기본미션</option>
						<option>마을미션</option>
					</select> */}
					<p>
						챌린지 참여자들의 챌린지 참여현황과 수행해야할 미션을 확인하세요
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="missionVillage_detail_wrap">
						{/* 미션 정보 시작 */}
						<div className="mission_impor_wrap">
							<div className="comm_detail_title mb25">
								<div className="comm_btn_set">
									{member?.roles === 'AMN' ? (
										<>
											{challengeOne?.openYn === 'Y' ? (
												<>
													<button
														type="button"
														className="non"
														onClick={handlePrivate}
													>
														비공개
													</button>
												</>
											) : (
												<>
													{' '}
													<button
														type="button"
														className="pub"
														onClick={handlePrivate}
													>
														공개
													</button>{' '}
												</>
											)}{' '}
											{(challengeOne?.prgrsSt === '대기' &&
												challengeOne?.joinMbr === 0) ||
												(challengeOne?.prgrsSt === '진행' &&
													challengeOne?.joinMbr === 0 && (
														<>
															<button type="button" onClick={handleModify}>
																수정
															</button>{' '}
															<button type="button" onClick={handleDelete}>
																삭제
															</button>
														</>
													))}
										</>
									) : (
										<> </>
									)}
								</div>
							</div>
							<div className="comm_detail_cont pa30">
								<p className="comm_title">{challengeOne?.challengeName}</p>
							</div>
							<div className="comm_mission pa30">
								<ul>
									<li>
										<p className="people">
											<span>{challengeOne?.joinMbr}</span> /{' '}
											{challengeOne?.mxJoinMbr != 0
												? challengeOne?.mxJoinMbr
												: '제한없음'}
										</p>
									</li>
									<li>
										<p className="period">
											{`${moment(challengeOne?.startDt).format('YYYY.MM.DD')}`}~
											{`${moment(challengeOne?.endDt).format('YYYY.MM.DD')}`}
										</p>
									</li>
								</ul>
								{!challengeOne?.participation &&
									challengeOne?.prgrsSt === '진행' && (
										<button
											type="button"
											className="on mt25"
											onClick={handleJoin}
										>
											참여하기
										</button>
									)}

								{challengeOne &&
									challengeOne?.joinMbr === challengeOne?.mxJoinMbr &&
									challengeOne?.prgrsSt === '종료' && (
										<button type="button" className="mt25">
											참여마감
										</button>
									)}
							</div>
							{toDoMissions.length != 0 && (
								<div className="comm_perform pa30">
									<h3>수행할 미션목록</h3>
									<div className="misstionPerform_list">
										<ul>
											{toDoMissions &&
												toDoMissions.map((mList, mIndex) => (
													<li
														key={mIndex}
														onClick={() =>
															missionDetail(
																mList?.mssnDe,
																String(mList?.mmiUid)
															)
														}
													>
														<a className="pa30">
															{/* 이미지 없으면 noimg class 붙음 */}
															<div
																// className="box_misstionPerform"
																className={
																	mList?.missionFiles &&
																		mList?.missionFiles.length === 0
																		? 'box_misstionPerform noimg'
																		: 'box_misstionPerform'
																}
															>
																<div className="misstionPerform_txt">
																	<div className="state">
																		<span
																			className={
																				mList?.prgrsState === '대기'
																					? 'prev'
																					: mList?.prgrsState === '진행'
																						? 'ing'
																						: mList?.prgrsState === '마감'
																							? 'end'
																							: ''
																			}
																		>
																			{mList?.prgrsState === '진행' &&
																				mList?.certPdDe === 'D' &&
																				Number(mList?.mxCertCo) -
																				Number(mList?.certCnt) >
																				0
																				? `매일 ${Number(mList?.mxCertCo) -
																				Number(mList?.certCnt)
																				}회 참여가능`
																				: mList?.prgrsState === '진행' &&
																					mList?.certPdDe === 'D' &&
																					Number(mList?.mxCertCo) -
																					Number(mList?.certCnt) ===
																					0
																					? '인증완료'
																					: mList?.prgrsState === '진행' &&
																						mList?.certPdDe === 'M' &&
																						Number(mList?.mxCertCo) -
																						Number(mList?.certCnt) >
																						0
																						? `매달 ${Number(mList?.mxCertCo) -
																						Number(mList?.certCnt)
																						}회 참여가능`
																						: mList?.prgrsState === '진행' &&
																							mList?.certPdDe === 'M' &&
																							Number(mList?.mxCertCo) -
																							Number(mList?.certCnt) ===
																							0
																							? `인증완료`
																							: mList?.prgrsState === '진행' &&
																								mList?.certPdDe === 'P' &&
																								Number(mList?.mxCertCo) -
																								Number(mList?.certCnt) >
																								0
																								? `기간 내 ${Number(mList?.mxCertCo) -
																								Number(mList?.certCnt)
																								}회 참여가능`
																								: mList?.prgrsState === '진행' &&
																									mList?.certPdDe === 'P' &&
																									Number(mList?.mxCertCo) -
																									Number(mList?.certCnt) ===
																									0
																									? '인증완료'
																									: mList?.prgrsState === '대기'
																										? '대기'
																										: mList?.prgrsState === '마감'
																											? '마감'
																											: '인증횟수 초과'}
																		</span>
																		{mList?.mssnDe === '기본미션' && (
																			<span className="state_number">
																				{mList?.redcnDe === '전력'
																					? '전력'
																					: mList?.redcnDe === '가스'
																						? '도시가스'
																						: mList?.redcnDe === '별도'
																							? '별도'
																							: '절감 구분'}
																			</span>
																		)}
																	</div>
																	<h4 className="comm_title txt_cut1 mt10">
																		{mList?.mssnNm}
																	</h4>
																	<p className="txt_cut1">
																		{`${moment(mList.startDt).format(
																			'YYYY.MM.DD'
																		)}`}
																		{'~'}
																		{`${moment(mList.endDt).format(
																			'YYYY.MM.DD'
																		)}`}
																	</p>
																	<p className="point">
																		{mList?.pymntPt
																			?.toString()
																			.replace(
																				/\B(?=(\d{3})+(?!\d))/g,
																				','
																			)}{' '}
																		E
																	</p>
																</div>
																<div className="misstionPerform_img">
																	{mList?.missionFiles?.length !== 0 ? (
																		<>
																			<img
																				src={
																					mList?.missionFiles &&
																					mList?.missionFiles[0]?.savePath &&
																					`/api/attach/getFile?savedPath=${mList.missionFiles[0]?.savePath}`
																				}
																				alt="지도 이미지"
																			/>{' '}
																		</>
																	) : (
																		<>
																			{' '}
																			<img src={errorImg} alt="지도 이미지" />
																		</>
																	)}
																</div>
															</div>
														</a>
													</li>
												))}
										</ul>
									</div>
								</div>
							)}

							<div className="comm_detail_btn mt25">
								<a className="btn_list" onClick={handleList}>
									목록
								</a>
							</div>
						</div>
						{/* 미션 정보 종료 */}
						{/* 미션 탭 시작 */}
						<div className="tabs mt25">
							<button
								type="button"
								className={tab === 1 ? 'tab tab_active' : 'tab non_active'}
								onClick={() => setTab(1)}
							>
								현황
							</button>
							<button
								type="button"
								className={tab === 2 ? 'tab tab_active' : 'tab non_active'}
								onClick={() => setTab(2)}
							>
								순위
							</button>
						</div>
						{/* 미션 탭 종료 */}
						{/* 미션 탭 (현황) 시작 */}
						{tab === 1 && (
							<div className="tab_content mt25">
								<div className="mission_cont_wrap">
									<div className="mission_cont_title">
										<h3 className="comm_title">내 현황</h3>
									</div>
									{myMissions.length !== 0 ? (
										<>
											<div className="mission_cont_content mt25">
												{/* 랭킹 시작 */}
												<div className="myRanking pa30">
													<p>
														내 순위 <span className="txt_blue">{rank}위</span>
														&nbsp;/&nbsp;참여자수{' '}
														<span>{challengeOne?.joinMbr}명 </span>
													</p>
													<p className="mt5">
														내 포인트{' '}
														<span className="txt_blue">
															{mypoint
																?.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
														&nbsp;/&nbsp;목표 포인트{' '}
														<span>
															{challengeOne?.goalPt
																?.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
													</p>
												</div>
												{/* 랭킹 종료 */}
												{/* 테이블 시작 */}
												<div className="table_inner">
													<ul className="thead pa20">
														<li>미션명</li>
														<li>포인트</li>
													</ul>
													<ul className="tbody">
														{myMissions &&
															myMissions.map((myList, myIndex) => (
																<li className="pa20" key={myIndex}>
																	<p>{myList?.mssnNm}</p>
																	<p>
																		{myList?.pymntPt
																			.toString()
																			.replace(
																				/\B(?=(\d{3})+(?!\d))/g,
																				','
																			)}{' '}
																		E
																	</p>
																</li>
															))}
													</ul>
												</div>
												{/* 테이블 종료 */}
											</div>
										</>
									) : (
										<>
											{' '}
											<div className="comm_noList">
												<img src={noList} alt="내용없음 이미지" />
												<p className="mt25">수행한 미션이 없습니다.</p>
											</div>{' '}
										</>
									)}
								</div>
							</div>
						)}

						{/* 미션 탭 (현황) 종료 */}
						{/* 미션 탭 (순위) 시작 */}
						{tab === 2 && (
							<div className="tab_content mt25">
								<div className="mission_cont_wrap mt25">
									<div className="mission_cont_title">
										<h3 className="comm_title">챌린지 순위</h3>
									</div>
									{rankData && rankData?.length === 0 ? (
										<div className="comm_noList">
											<img src={noList} alt="내용없음 이미지" />
											<p className="mt25">미션 참여자가 없습니다.</p>
										</div>
									) : (
										<div className="mission_cont_content mt25">
											<div className="ranking_list">
												{/* 1등 */}
												{rankData && rankData.length > 0 && (
													<p>
														<span className="num">
															<img src={ic_rank01} alt="랭킹 아이콘" />
														</span>
														{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}
														{rankData.length > 0 && rankData[0].member ? (
															<span
																className={
																	rankData[0].member.character === 1
																		? 'nickname basic1'
																		: rankData[0].member.character === 2
																			? 'nickname basic2'
																			: rankData[0].member.character === 3
																				? 'nickname Lv1'
																				: rankData[0].member.character === 4
																					? 'nickname Lv2'
																					: rankData[0].member.character === 5
																						? 'nickname Lv3'
																						: rankData[0].member.character === 6
																							? 'nickname Lv4'
																							: ''
																}
															>
																{rankData[0]?.member?.nickname}
															</span>
														) : (
															''
														)}
														<span className="point">
															{rankData[0]?.point.toLocaleString()} E
														</span>
													</p>
												)}

												{/* 2등 */}
												{rankData && rankData.length > 1 && (
													<p>
														<span className="num">
															<img src={ic_rank02} alt="랭킹 아이콘" />
														</span>
														{rankData.length > 1 && rankData[1].member ? (
															<span
																className={
																	rankData[1].member &&
																		rankData[1].member.character === 1
																		? 'nickname basic1'
																		: rankData[1].member.character === 2
																			? 'nickname basic2'
																			: rankData[1].member.character === 3
																				? 'nickname Lv1'
																				: rankData[1].member.character === 4
																					? 'nickname Lv2'
																					: rankData[1].member.character === 5
																						? 'nickname Lv3'
																						: rankData[1].member.character === 6
																							? 'nickname Lv4'
																							: ''
																}
															>
																{rankData[1]?.member?.nickname}
															</span>
														) : (
															''
														)}
														<span className="point">
															{rankData[1]?.point.toLocaleString()} E
														</span>
													</p>
												)}

												{/* 3등 */}
												{rankData && rankData.length > 2 && (
													<p>
														<span className="num">
															<img src={ic_rank03} alt="랭킹 아이콘" />
														</span>
														{rankData.length > 2 && rankData[2].member ? (
															<span
																className={
																	rankData[2].member &&
																		rankData[2].member.character === 1
																		? 'nickname basic1'
																		: rankData[2].member.character === 2
																			? 'nickname basic2'
																			: rankData[2].member.character === 3
																				? 'nickname Lv1'
																				: rankData[2].member.character === 4
																					? 'nickname Lv2'
																					: rankData[2].member.character === 5
																						? 'nickname Lv3'
																						: rankData[2].member.character === 6
																							? 'nickname Lv4'
																							: ''
																}
															>
																{rankData[2]?.member?.nickname}
															</span>
														) : (
															''
														)}
														<span className="point">
															{rankData[2]?.point.toLocaleString()} E
														</span>
													</p>
												)}

												{/* 4 ~ 10등 */}
												{rankData &&
													rankData.slice(3, 10).map((list, index) => (
														<p key={index}>
															<span className="num">{index + 4}</span>
															{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}
															<span
																className={
																	rankData[index + 4].member.character === 1
																		? 'nickname basic1'
																		: rankData[index + 4].member.character === 2
																			? 'nickname basic2'
																			: rankData[index + 4].member.character === 3
																				? 'nickname Lv1'
																				: rankData[index + 4].member.character === 4
																					? 'nickname Lv2'
																					: rankData[index + 4].member.character === 5
																						? 'nickname Lv3'
																						: rankData[index + 4].member.character === 6
																							? 'nickname Lv4'
																							: ''
																}
															>
																{list.member.nickname}
															</span>
															<span className="point">
																{list.point.toLocaleString()} E
															</span>
														</p>
													))}

												{/* <p>
												<span className="num">5</span>
												<span className="nickname Lv3">홍길동</span>
												<span className="point">5,000</span>
											</p> */}
											</div>
											<span className="update">
												최근 업데이트 {moment(nowDate).format('YYYY.MM.DD')}
											</span>
										</div>
									)}
								</div>
								<div className="mission_cont_wrap mt25">
									<div className="mission_cont_title">
										<h3 className="comm_title">포인트지급 순위</h3>
									</div>
									<div className="mission_cont_content mt25">
										{/* 테이블 시작 */}
										<div className="table_inner2">
											<ul className="thead pa20">
												<li>순위</li>
												<li>포인트</li>
											</ul>
											<ul className="tbody">
												{challengeRewards &&
													challengeRewards?.map((rewardList, rewardIndex) => (
														<li className="pa20" key={rewardIndex}>
															<p>{rewardList?.topRank}위</p>
															<p>
																{rewardList?.pymntPt
																	.toString()
																	.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
																E
															</p>
														</li>
													))}
											</ul>
										</div>
										{/* 테이블 종료 */}
									</div>
								</div>
							</div>
						)}

						{/* 미션 탭 (순위) 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default Detail_ChallengePage;
