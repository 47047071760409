import React from 'react';
import ActivityLogAPI from '../apis/ActivityLogAPI';
import EduProgramAPI from '../apis/EduProgramAPI';
import EduProgram from '../models/EduProgram';
import { DialogProps, useDialog } from './DialogProvider';
import './Login/LoginDialog.scss';
import SelectDialog from './program/SelectDialog';
interface TwoButtonAlertDialogProps extends DialogProps {
	title: string;
	message: string;
	action: string;
	handleDialogResult: (result?: string) => void;
}
const TwoButtonAlertDialog = ({
	message,
	title,
	action,
	handleDialogResult,
	...props
}: TwoButtonAlertDialogProps) => {
	const { showDialog } = useDialog();

	const handleClose = () => {
		props.onClose?.();
	};
	// appointedMST 운영자 지정, 운영자 양도 clearMST 운영자 해제
	// appointedSMT 부운영자 지정 clearSMT 부운영자 해제
	// appointedABR 활동가 지정 clearABR 활동가 해제
	const handleSubmit = () => {
		// console.log('action', action);
		if (action === 'ok' || action === 'no') {
			handleDialogResult('확인');
			props.onClose?.();
			return;
		}
		if (action === '예약승인' || '예약취소') {
			handleDialogResult('확인');
			// showDialog(SelectDialog,{action, handleClose})
			// props.onClose?.();
		}
		if (action === '인증ok') {
			handleDialogResult('확인');
			// showDialog(SelectDialog,{action, handleClose})
			// props.onClose?.();
		}
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>{title}</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">{message.split('\n').map((item, index) => (
						<React.Fragment key={index}>
							{item}
							<br />
						</React.Fragment>
					))}</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button type="button" className="btn_check" onClick={handleSubmit}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default TwoButtonAlertDialog;
