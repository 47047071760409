import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ic_footer01 from '../../assets/ic_footer01.png';
import ic_footer02 from '../../assets/ic_footer02.png';
import ic_footer03 from '../../assets/ic_footer03.png';
import ic_footer04 from '../../assets/ic_footer04.png';
import ic_footer05 from '../../assets/ic_footer05.png';
import ic_footer06 from '../../assets/ic_footer06.png';
import ic_footer07 from '../../assets/ic_footer07.png';
import ic_footer08 from '../../assets/ic_footer08.png';
import ic_footer09 from '../../assets/ic_footer09.png';
import ic_footer10 from '../../assets/ic_footer10.png';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import useMember from '../../hooks/useMember';

const Footer = () => {
	const { showDialog } = useDialog();
	const { member } = useMember();
	const navigate = useNavigate();
	const [tab, setTab] = useState<string>();
	const [rewardOn, setRewardOn] = useState(false);
	const [infoOn, setInfoOn] = useState(false);
	const [notice, setNotice] = useState(false);
	const [manager, setManager] = useState(false);
	const nowTab = sessionStorage.getItem('nowTab');

	const clickTab = (e: string) => {
		const title = '';
		const message = '현재 준비중인 서비스입니다.';

		const newTab = e;
		// console.log('탭', newTab);
		setTab(newTab);

		sessionStorage.setItem('nowTab', newTab);

		switch (newTab) {
			case '소통마당':
				navigate('/commu_02/', { replace: true });
				// setTab(e.target.innerText);
				break;
			case '참여마당':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				// navigate('/reward_02/', { replace: true });
				setRewardOn(true);
				setTab(e);
				break;
			case '홈':
				navigate('/', { replace: true });
				// setTab(e.target.innerText);
				break;
			case '정보마당':
				// showDialog(OneButtonAlertDialog, {
				// 	title,
				// 	message,
				// });
				// navigate('/info_02/', { replace: true });
				setInfoOn(true);
				setTab(e);
				break;
			case '마이페이지':
				navigate('/myPage_02/', { replace: true });
				// setTab(e.target.innerText);
				break;
			case '공지사항':
				// navigate('/notice_02/list', { replace: true });
				setNotice(true);
				setTab(e);
				break;
			case '관리자메뉴':
				// navigate('/notice_02/list', { replace: true });
				setManager(true);
				setTab(e);
				break;
		}
	};
	const handleMain = () => {
		navigate('/reward_02');
	};
	return (
		<>
			{/* 참여마당 */}
			<div
				className={`Popup_mobile_footer ${rewardOn ? 'on' : ''}`}
			// className={

			// 	// nowTab
			// 	// 	? nowTab === '참여마당'
			// 	// 		? 'Popup_mobile_footer on'
			// 	// 		: 'Popup_mobile_footer'
			// 	// 	: tab === '참여마당'
			// 	// 	? 'Popup_mobile_footer on'
			// 	// 	: 'Popup_mobile_footer'
			// }
			>
				<div className="mobile_footer_bg" />
				<div className="mobile_footer_cont pa30">
					<div className="footer_cont_tit">
						<h3 className="comm_title">참여마당</h3>
						<button
							type="button"
							className="mobile_footer_close"
							onClick={() => setRewardOn(false)}
						>
							닫기
						</button>
					</div>
					<div className="footer_cont_list mt25">
						<ul>
							<li className="pa20">
								<a
									href="/reward_02"
								// onClick={handleMain}
								>
									<p>에너지전환 현황</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/reward_02/mission-main">
									<p>에너지전환 활동미션</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/reward_02/challenge-main">
									<p>에너지전환 챌린지</p>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{/* 정보마당 */}
			<div className={`Popup_mobile_footer ${infoOn ? 'on' : ''}`}>
				<div className="mobile_footer_bg" />
				<div className="mobile_footer_cont pa30">
					<div className="footer_cont_tit">
						<h3 className="comm_title">정보마당</h3>
						<button
							type="button"
							className="mobile_footer_close"
							onClick={() => setInfoOn(false)}
						>
							닫기
						</button>
					</div>
					<div className="footer_cont_list mt25">
						<ul>
							<li className="pa20">
								<a href="/info_02">
									<p>광주 에너지현황</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/info_02/analysis">
									<p>광주 에너지분석</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/info_02/map">
									<p>광주 태양광 에너지지도</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/info_02/cal">
									<p>탄소계산기</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/dictionary_02">
									<p>에너지 용어사전</p>
								</a>
							</li>
							
						</ul>
					</div>
				</div>
			</div>
			{/* 공지사항 */}
			<div className={`Popup_mobile_footer ${notice ? 'on' : ''}`}>
				<div className="mobile_footer_bg" />
				<div className="mobile_footer_cont pa30">
					<div className="footer_cont_tit">
						<h3 className="comm_title">공지사항</h3>
						<button
							type="button"
							className="mobile_footer_close"
							onClick={() => setNotice(false)}
						>
							닫기
						</button>
					</div>
					<div className="footer_cont_list mt25">
						<ul>
							<li className="pa20">
								<a href="/notice_02/list">
									<p>공지사항</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/popup_02/">
									<p>팝업 관리</p>
								</a>
							</li>

						</ul>
					</div>
				</div>
			</div>
			<div className={`Popup_mobile_footer ${manager ? 'on' : ''}`}>
				<div className="mobile_footer_bg" />
				<div className="mobile_footer_cont pa30">
					<div className="footer_cont_tit">
						<h3 className="comm_title">관리자 메뉴</h3>
						<button
							type="button"
							className="mobile_footer_close"
							onClick={() => setManager(false)}
						>
							닫기
						</button>
					</div>
					<div className="footer_cont_list mt25">
						<ul>
							<li className="pa20">
								<a href="/admin_02/">
									<p>회원관리</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/admin_02/point">
									<p>포인트 및 보상 관리</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/admin_02/static">
									<p>활동 통계</p>
								</a>
							</li>
							<li className="pa20">
								<a href="/admin_02/today">
									<p>이용자 수 통계</p>
								</a>
							</li>

						</ul>
					</div>
				</div>
			</div>
			<footer>
				<h1 className="notext">푸터 영역</h1>
				<div id="footer">
					<div className="footer_link">
						<a className="yellow" href="/policy/policyPage">
							개인정보 처리방침
						</a>
						<a href="/policy/usePolicyPage">이용약관</a>
					</div>
					<p>
						주소 : (61954) 광주광역시 서구 천변우하로 181
						<br />
						대표전화 : 062-601-1312 &nbsp; 팩스 : 062-601-1313
						<br />
						Copyright © Gwangju Climate & Energy Agency. All Rights Reserved.
					</p>
				</div>

				<div id="footer_mobile">
					<ul>
						{/* <li>
							<a
								onClick={() => clickTab('관리자메뉴')}
								className={
									nowTab
										? nowTab === '관리자메뉴'
											? 'on'
											: ''
										: tab === '관리자메뉴'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer02} alt="소통마당 검정" />
									<img src={ic_footer07} alt="소통마당 화이트" />
								</div>
								<p>관리자<br />메뉴</p>
							</a>
						</li>
						<li>
							<a
								onClick={() => clickTab('공지사항')}
								className={
									nowTab
										? nowTab === '공지사항'
											? 'on'
											: ''
										: tab === '공지사항'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer02} alt="소통마당 검정" />
									<img src={ic_footer07} alt="소통마당 화이트" />
								</div>
								<p>공지사항</p>
							</a>
						</li> */}
						<li onClick={() => clickTab('소통마당')}>
							<a
								onClick={() => clickTab('소통마당')}
								className={
									nowTab
										? nowTab === '소통마당'
											? 'on'
											: ''
										: tab === '소통마당'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer01} alt="소통마당 검정" />
									<img src={ic_footer06} alt="소통마당 화이트" />
								</div>
								<p>소통마당</p>
							</a>
						</li>
						<li>
							<a
								onClick={() => clickTab('참여마당')}
								className={
									nowTab
										? nowTab === '참여마당'
											? 'on'
											: ''
										: tab === '참여마당'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer02} alt="소통마당 검정" />
									<img src={ic_footer07} alt="소통마당 화이트" />
								</div>
								<p>참여마당</p>
							</a>
						</li>
						<li>
							<a
								onClick={() => clickTab('홈')}
								className={
									nowTab
										? nowTab === '홈'
											? 'on'
											: ''
										: tab === '홈'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer03} alt="소통마당 검정" />
									<img src={ic_footer08} alt="소통마당 화이트" />
								</div>
								<p>홈</p>
							</a>
						</li>
						<li>
							<a
								onClick={() => clickTab('정보마당')}
								className={
									nowTab
										? nowTab === '정보마당'
											? 'on'
											: ''
										: tab === '정보마당'
											? 'on'
											: ''
								}
							>
								<div>
									<img src={ic_footer04} alt="소통마당 검정" />
									<img src={ic_footer09} alt="소통마당 화이트" />
								</div>
								<p>정보마당</p>
							</a>
						</li>
						{member &&
							<li onClick={() => clickTab('마이페이지')}>
								<a
									onClick={() => clickTab('마이페이지')}
									className={
										nowTab
											? nowTab === '마이페이지'
												? 'on'
												: ''
											: tab === '마이페이지'
												? 'on'
												: ''
									}
								>
									<div>
										<img src={ic_footer05} alt="소통마당 검정" />
										<img src={ic_footer10} alt="소통마당 화이트" />
									</div>
									<p>마이페이지</p>
								</a>
							</li>
						}
					</ul>
				</div>
			</footer>
		</>
	);
};

export default Footer;
