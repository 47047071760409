import React from 'react';
import '../Login/LoginDialog.scss';
import { DialogProps } from '../DialogProvider';
import { useNavigate } from 'react-router-dom';
// 수정 완료 팝업
interface ModifySuccessDialog extends DialogProps {
	type?: '';
	tab: string;
	boardId?: string | null;
}

const ModifySuccessDialog = ({
	tab,
	boardId,
	...props
}: ModifySuccessDialog) => {
	// const handleClose = () => {
	// 	props.onClose?.();
	// };

	const navigate = useNavigate();

	const handleClose = (tab: string, boardId: string) => {
		// props.onClose?.();
		// console.log('tab:::::', tab);
		if (tab === 'activeMasterMap') {
			// 활동지도
			navigate('/commu_02/actMap_03');
			props.onClose?.();
		} else if (tab === 'villageCommu') {
			// 소통마당
			navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`);
			props.onClose?.();
		} else if (tab === 'activeMapping') {
			props.onClose?.();
			window.location.reload();
		} else if (tab === 'network') {
			props.onClose?.();
			navigate('/commu_02/intro_03');
		} else if (tab === 'center') {
			props.onClose?.();
			navigate('/commu_02/intro_03');
		} else if (tab === 'case') {
			props.onClose?.();
			window.location.reload();
		}
	};

	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>수정</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">완료되었습니다.</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button
						type="button"
						className="btn_check one"
						onClick={() => handleClose(String(tab), String(boardId))}
					>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModifySuccessDialog;
