import React from 'react';
import { useDialog } from '../DialogProvider';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import './MyGiftCardDialog.scss';
import test11 from '../../assets/test11.png';
const MyGiftCardDialog = ({ ...props }) => {
	const { showDialog } = useDialog();
	const handleClose = () => {
		props.onClose?.();
	};
	const handleClick = () => {
		const title = '받는사람 찾기';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30" style={{ maxWidth: '800px' }}>
				{/* 타이틀 시작 */}
				<div className="comm_popup_title">
					<h3>거점마을 상품권</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>
				{/* 타이틀 종료 */}
				{/* 컨텐츠 시작 */}
				<div className="comm_popup_content">
					<div className="villageGift_wrap">
						{/* 직원확인 시작 */}
						<form>
							<div className="code_inner mt25">
								<input type="text" placeholder="직원확인용 사용코드 입력" />
								<button onClick={handleClick}>확인</button>
							</div>
						</form>
						{/* 직원확인 종료 */}
						{/* 상세내용 시작 */}
						<div className="detail_inner mt25">
							<div className="img_box">
								<img src={test11} alt="상품권 이미지" />
							</div>
							<div className="txt_box">
								<h3 className="time">유효기간</h3>
								<p className="mt10">2023.12.10</p>
							</div>
							<div className="txt_box">
								<h3 className="care">상품권 설명 및 주의사항</h3>
								<p className="mt10">
									거점마을의 상점들에서 사용할 수 있는 상품권입니다.
									<br />
									금액의 일부만 사용할 수 없으며, 유효기간이 만료되면 사용이
									불가능합니다.
								</p>
							</div>
							<div className="txt_box">
								<h3 className="market">이용매장</h3>
								<div className="market_table mt10">
									<ul className="thead pa20">
										<li>상점명</li>
										<li>연락처</li>
										<li>소재지</li>
									</ul>
									<ul className="tbody">
										<li className="pa20">
											<p>커피전문점</p>
											<p>062-1111-1111</p>
											<p>광산구 무진대로 111-22번길</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* 상세내용 종료 */}
					</div>
				</div>
				{/* 컨텐츠 종료 */}
			</div>
		</div>
	);
};

export default MyGiftCardDialog;
