import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import MissionAPI from '../../apis/MissionAPI';
import Pagination from '../../components/common/Pagination';
import { SelectChallengeMissions } from '../../models/ChallengeInput';
import MissionInfo from '../../models/MissionInfo';
import { DialogProps } from '../DialogProvider';
import '../MissionYard/AddMissionDialog.scss';

interface AddVillMissionDialogProps extends DialogProps {
	selectVillMission?: SelectChallengeMissions[]
	handleSelectVillMission: (select: SelectChallengeMissions[]) => void;
}

const AddVillMissionDialog = ({ selectVillMission, handleSelectVillMission, ...props }: AddVillMissionDialogProps) => {
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	const [villageMissionList, setVillageMissionList] = useState([] as MissionInfo[]);
	const [selectMission, setSelectMission] = useState([] as SelectChallengeMissions[]);

	useEffect(() => {
		// setSelectMission([]);
		if (selectVillMission) {
			// console.log("상위에서 받아온 미션리스트 ", selectVillMission);
			setSelectMission(selectVillMission);
		}
	}, [])
	useEffect(() => {
		MissionAPI.getMissionList({
			page: page - 1,
			size: 5,
			mssnDe: '마을미션'
		}).then((res) => {
			// console.log("마을미션 리스트", res.totalElements)
			setCount(res.totalElements)
			setVillageMissionList(res.content)
		})
	}, [page])

	useEffect(() => {
		// console.log("마을미션 체크리스트", selectMission)
	}, [selectMission])

	const handleSelectOk = () => {
		handleSelectVillMission(selectMission);
		props.onClose?.();
	}
	const handlePageChange = (page: number) => {
		setPage(page);
	};

	const handleClose = () => {
		props.onClose?.();
	};
	// 마을미션 체크
	const handleSelectedMission = (e: any, mssnNm: string, mssnDe: string) => {
		// console.log('aa')
		if (e.target.checked) {
			// console.log('체크')
			setSelectMission((cur) => [...cur, {
				mmiUid: e.target.value,
				mssnNm: mssnNm,
				mssnDe: mssnDe
			}]);
		} else {
			// console.log('체크해제')
			const notChecked = selectMission.filter((item) => item.mmiUid != e.target.value);
			setSelectMission(notChecked);
		}
	}

	return (
		<div className="comm_popup AddMissionDialog" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30" style={{ maxWidth: '800px' }}>
				<div className="comm_popup_title">
					<h3>마을미션 선택</h3>
					<a className="popup_close" onClick={handleClose}>
						<img
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
							alt="닫기"
						/>
					</a>
				</div>
				<form>
					<div className="comm_popup_content energy_table mt25">
						<ul className="thead pa20">
							<li>선택</li>
							<li>미션명</li>
							<li>포인트</li>
							<li>인증기간</li>
						</ul>
						<ul className="tbody">
							{villageMissionList && villageMissionList.map((list, index) => (
								<li className="pa20" key={index}>
									<p>
										<label htmlFor={`check1-${index}`}>
											<input
												type="checkbox"
												id={`check1-${index}`}
												value={list.mmiUid}
												onChange={(e) => handleSelectedMission(e, list.mssnNm, list.mssnDe)}
												checked={selectMission?.some((some) => some.mmiUid == list.mmiUid)}
											/>
										</label>
									</p>
									<p className="txt_cut2 title">
										{list.mssnNm}
									</p>
									<p>{list.pymntPt.toLocaleString()}E</p>
									<p>{moment(list.startDt).format('YYYY.MM.DD')} ~ {moment(list.endDt).format('YYYY.MM.DD')}</p>
								</li>
							))}
						</ul>
					</div>
					<Pagination
						page={page}
						// totalPageCount={Math.ceil(8 / 8)}
						totalPageCount={Math.ceil(count / 5)}
						onPageChange={handlePageChange}
					/>
					<div className="comm_popup_btn mt25">
						<button type="button" className="btn_cancel" onClick={handleClose}>
							취소
						</button>
						<button type="button" className="btn_check" onClick={handleSelectOk}>
							선택
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddVillMissionDialog;
