import React, { useEffect, useRef, useState } from 'react';
import './Detail_VillageMissionPage.scss';
import ic_rank01 from '../../assets/ic_rank01.png';
import ic_rank02 from '../../assets/ic_rank02.png';
import ic_rank03 from '../../assets/ic_rank03.png';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../dialog/DialogProvider';
import TextMissionDialog from '../../dialog/MissionYard/TextMissionDialog';
import ImageMissionDialog from '../../dialog/MissionYard/ImageMissionDialog';
import SubLocation from '../../components/common/SubLocation';
import MissionAPI from '../../apis/MissionAPI';
import MissionInfo from '../../models/MissionInfo';
import moment from 'moment';
import errorImg from '../../assets/errorImg.jpg';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import {
	Content,
	MissionCerts,
	RankContent,
} from '../../models/MissionInfoDetail';
import defaultImg from '../../assets/img_gjd.png';
import noList from '../../assets/noList.png';
import Pagination from '../../components/common/Pagination';
import LoginDialog from '../../dialog/Login/LoginDialog';
import useMember from '../../hooks/useMember';

// 마을미션 상세페이지
const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	{ href: '/reward_02/myEPoint', text: '내 E 포인트' },
];

const Detail_VillageMissionPage = () => {
	const { member } = useMember();
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [tab, setTab] = useState<number>(1);
	const [missionDetail, setMissionDetail] = useState<MissionInfo | undefined>();
	const [insertCheck, setInsertCheck] = useState(false);
	const mmiUid = sessionStorage.getItem('mmiUid');
	const [rankData, setRankData] = useState([] as RankContent[]); // 순위 데이터
	const [certData, setCertData] = useState([] as Content[]); // 인증 데이터
	const [page, setPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState(0);
	const [updateStatus, setUpdateStatus] = useState(false);
	const [certCnt, setCertCnt] = useState<number>(0);

	// 관리자 권한
	const [amnBtn, setAmnBtn] = useState(false);

	// 순위 정렬 (주간, 월간, 연간)
	const [sort, setSort] = useState<string>('주간');

	// 목록 버튼
	const handleListPage = () => {
		navigate('/reward_02/villageMission_03-list');
	};

	// 미션 상세조회
	useEffect(() => {
		MissionAPI.getMissionDetail({
			mmiUid: Number(mmiUid),
			period: sort,
			cert_page: page - 1,
			cert_size: 10,
			past: sort === '주간' ? '저번주' : undefined,
		}).then((res) => {
			setMissionDetail(res.missionInfo);
			setRankData(res.rankings.content);
			setCertData(res.missionCerts.content);
			setTotalPage(res.missionCerts.totalPages);
			setCertCnt(res.certCnt);

			if (member) {
				// console.log('멤버 권한-->', member.roles);
				// 관리자
				if (member.roles === 'AMN') {
					setAmnBtn(true);
				}
			}

			// if (res.missionInfo) {
			// 	const nowDate = new Date();
			// 	const formattedStartDate = `${moment(res.missionInfo.startDt).format(
			// 		'yyyy.MM.DD'
			// 	)}`;
			// 	const formattedEndDate = `${moment(res.missionInfo.endDt).format(
			// 		'yyyy.MM.DD'
			// 	)}`;
			// 	const stDt = new Date(formattedStartDate);
			// 	const edDt = new Date(formattedEndDate);
			// 	// // console.log('엔드 데이트', edDt);
			// 	if (nowDate > edDt) {
			// 		res.missionInfo.status = '마감';
			// 	} else if (nowDate >= stDt && nowDate <= edDt) {
			// 		res.missionInfo.status = '진행';
			// 	} else {
			// 		res.missionInfo.status = '대기';
			// 	}
			// }
		});
	}, [page, updateStatus, insertCheck, member, sort]);

	// console.log('마을미션 상세--> ', missionDetail);
	// console.log('마을미션 순위--> ', rankData);
	// console.log('마을미션 현황--> ', certData);
	// console.log('certCnt--> ', certCnt);
	// console.log('totalPage', totalPage);

	// 인증하기(글, 이미지, 큐알)
	const handleCertified = (
		missionDetail: MissionInfo | undefined,
		action?: string,
		id?: number
	) => {
		// 로그인X
		if (!id) {
			const title = '인증하기';
			const message = '미션 참여를 위해 로그인해주세요';
			const action = '인증ok';

			showDialog(TwoButtonAlertDialog, {
				title,
				message,
				action,
				handleDialogResult(result) {
					if (result === '확인') {
						showDialog(LoginDialog, {
							title: '로그인',
						});
					}
				},
			});

			// 로그인O
		} else if (id) {
			// 마을 선택X
			// console.log('member?.townDTO.townId', member?.townDTO.townId);
			if (member?.townDTO.townId === 0) {
				const title = '인증하기';
				const message =
					'소속된 마을이 없으면 마을미션에 참여할 수 없습니다. 마을을 선택하시겠습니까?';
				const action = 'ok';

				showDialog(TwoButtonAlertDialog, {
					title,
					message,
					action,
					handleDialogResult(result) {
						if (result === '확인') {
							navigate('/myPage_02/myVillageModify_04');
						}
					},
				});
			}

			// 부가정보X
			else if (!member?.username || !member?.telno || !member?.address) {
				const title = '인증하기';
				const message =
					`보상프로그램 참여를 위해 \n실명, 연락처, 주소 등 부가정보 입력이 필요합니다 입력하시겠습니까?`;
				const action = 'ok';

				showDialog(TwoButtonAlertDialog, {
					title,
					message,
					action,
					handleDialogResult(result) {
						if (result === '확인') {
							navigate('/myPage_02/myInfoModify_04');
						}
					},
				});
			} else {
				if (action === '글') {
					// console.log('글 인증하기 클릭', missionDetail);
					showDialog(TextMissionDialog, { missionDetail });
				} else if (action === '이미지') {
					// console.log('이미지 인증하기 클릭', missionDetail);
					showDialog(ImageMissionDialog, { missionDetail });
				} else if (action === '큐알') {
					// console.log('큐알 인증하기 클릭', missionDetail);

					const title = 'QR인증';
					const message = '현재 준비중인 서비스입니다.';
					const action = '인증ok';
					showDialog(OneButtonAlertDialog, {
						title,
						message,
						handleDialogResult(result) {
							if (result === '확인') {
							}
						},
					});
				}
			}
		}
	};

	// 큐알코드 출력
	const handleQR = () => {
		const title = 'QR코드 출력';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};

	const handleInsertCheck = () => {
		setInsertCheck(!insertCheck);
	};

	// 미션 비공개, 공개 처리
	const handlePrivateMission = (mmiUid: number, visible: string) => {
		const title = '';
		const message =
			visible === 'Y'
				? '해당 글을 비공개하시겠습니까?'
				: '해당 글을 공개하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					MissionAPI.privateMission({
						mmiUid: mmiUid,
						visible: visible === 'Y' ? 'N' : 'Y',
					}).then((res) => {
						handleInsertCheck();
					});
				}
			},
		});
	};

	// 미션 수정
	const handleUpdateMission = () => {
		const title = '';
		const message = '수정 하시겠습니까?';
		const action = 'ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					navigate('/reward_02/villageMission_04-modify');
				}
			},
		});
	};
	const handleDeleteMission = () => {
		const nowDate = new Date();
		const formatDate = moment(nowDate).format('YYYY-MM-DD HH:mm:ss');
		const title = '';
		const message = '삭제 하시겠습니까?';
		const action = '인증ok';
		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					// console.log('삭제데이트', formatDate);
					MissionAPI.deleteMission({
						mmiUid: Number(mmiUid),
						delDt: formatDate,
					}).then((res) => {
						const title = '';
						const message = '삭제되었습니다.';
						showDialog(OneButtonAlertDialog, {
							title,
							message,
							handleDialogResult(result) {
								if (result == 'ok') {
									navigate('/reward_02/villageMission_03-list');
								}
							},
						});
					});
				}
			},
		});
	};
	// const videoRef = useRef<HTMLVideoElement | null>(null);
	// const startCamera = async () => {
	//     try {
	//         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
	//         if (videoRef.current) {
	//             videoRef.current.srcObject = stream;
	//         }
	//     } catch (err) {
	//         console.error('Error accessing the camera:', err);
	//     }
	// };

	const openCameraApp = () => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(function (stream) {
					// 성공적으로 카메라 액세스 획득
				})
				.catch(function (error) {
					console.error('카메라 액세스 에러:', error);
				});
		} else {
			console.error('getUserMedia를 지원하지 않음');
		}
	};

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	const handleUpdateStatus = () => {
		setUpdateStatus(!updateStatus);
	};

	// 인증 상태 변경
	const handleStatusChange = (
		handleAction: string,
		mmcUid: number,
		visible?: string,
		certProgrsSt?: string,
		nickname?: string,
		accmlPt?: number
	) => {
		let title = '';
		let message = '';

		// 공개, 비공개
		if (handleAction == '공개,비공개') {
			message =
				visible === 'Y'
					? '인증 내역을 비공개하시겠습니까? 포인트는 회수되지 않습니다.'
					: '인증 내역을 공개하시겠습니까?';
		}

		// 포인트 회수
		if (handleAction == '포인트회수') {
			// // console.log("현재상태", certProgrsSt);
			// // console.log("닉네임", nickname);
			// // console.log("포인트", accmlPt);
			if (certProgrsSt === '신청') {
				message = `클릭 시 ${nickname}님에게 지급된 ${accmlPt
					?.toString()
					.replace(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					)} E 포인트를 회수하시겠습니까? 회수된 포인트는 재지급되지 않습니다.`;
			}
		}

		const action = 'ok';

		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					MissionAPI.certStatusChange({
						mmcUid: mmcUid,
						visible: visible === 'Y' ? 'N' : 'Y',
						certProgrsSt: '회수',
					}).then((res) => {
						// console.log('인증 상태 변경 성공');
						let title = '';
						const message = '완료되었습니다.';

						showDialog(OneButtonAlertDialog, {
							title,
							message,
							handleDialogResult(result) {
								if (result === 'ok') {
									handleUpdateStatus();
								}
							},
						});
					});
				}
			},
		});
	};

	return (
		<main className="Detail_VillageMissionPage">
			<h1 className="notext">전체 영역</h1>
			{/* <div>
                <video ref={videoRef} autoPlay muted playsInline />
                <button onClick={startCamera}>Start Camera</button>
            </div> */}
			{/* <div>
                <button onClick={openCameraApp}>카메라 실행</button>
            </div> */}
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="missionVillage_detail_wrap">
						{/* 미션 정보 시작 */}
						<div className="mission_impor_wrap">
							<div className="impor_txt">
								<div className="comm_detail_title mb25">
									{missionDetail?.certDe === 'QR' && (
										<a className="btn_QR" onClick={handleQR}>
											QR코드 출력
										</a>
									)}

									{/* 관리버튼 */}
									{/* 
									대기 - 수정, 삭제 ,비공개
									진행 - 참여자 없을때 / 수정, 삭제, 비공개
									진행 -참여자 있을때 / 비공개만
									마감- 비공개만 가능/ 수정,삭제 안됨 */}
									{/* {(amnBtn) && certData.length < 1 || missionDetail?.status=== "마감" && ( */}
									{amnBtn && (
										<div className="comm_btn_set">
											{/* 비공개 */}
											<button
												type="button"
												className={`${missionDetail?.visible === 'Y' ? 'non' : 'pub'
													}`}
												onClick={() =>
													handlePrivateMission(
														Number(missionDetail?.mmiUid),
														missionDetail?.visible === 'Y' ? 'Y' : 'N'
													)
												}
											>
												{`${missionDetail?.visible === 'Y' ? '비공개' : '공개'
													}`}
											</button>
											{/* 수정, 삭제 */}
											{/* missionDetail?.prgrsState?.length
												대기 = 4 / 진행 = 6 / 종료 = 5 */}
											{/* (missionDetail?.prgrsState?.length ===  4 ) ||
													(missionDetail?.prgrsState?.length === 6 &&
														certData.length < 1 ) && ( */}
											{((missionDetail?.prgrsState?.includes("대기")) ||
												(missionDetail?.prgrsState?.includes("진행") &&
													certData.length <= 1)) && (
													<>
														<button
															type="button"
															onClick={handleUpdateMission}
														>
															수정
														</button>
														<button
															type="button"
															onClick={handleDeleteMission}
														>
															삭제
														</button>
													</>
												)}
										</div>
									)}
								</div>
								<div className="comm_detail_cont pa30">
									<p className="comm_title">{missionDetail?.mssnNm}</p>
								</div>
								<div className="comm_mission pa30">
									<ul>
										<li>
											{/* 인증방법 */}
											<p className="way">
												{missionDetail?.certDe === 'IMG'
													? '이미지 인증'
													: missionDetail?.certDe === 'TXT'
														? '글 인증'
														: missionDetail?.certDe === 'QR'
															? 'QR 인증'
															: ''}
											</p>
										</li>
										<li>
											{/* 인증기간 */}
											<p className="period">
												{' '}
												{`${moment(missionDetail?.startDt).format(
													'yyyy.MM.DD'
												)}`}
												{'~'}
												{`${moment(missionDetail?.endDt).format(
													'yyyy.MM.DD'
												)}`}{' '}
											</p>
										</li>
										<li>
											{/* 지급 포인트 */}
											<p className="point">
												{missionDetail?.pymntPt
													?.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
												E
											</p>
										</li>
										<li>
											{/* 참여 횟수 */}
											<p className="number">
												{missionDetail?.certPdDe === 'D'
													? `매일 `
													: missionDetail?.certPdDe === 'M'
														? `매달 `
														: missionDetail?.certPdDe === 'P'
															? `기간 내 `
															: ''}
												<span>{missionDetail?.mxCertCo}</span>회 참여 가능
											</p>
										</li>
										<li>
											{/* 미션설명 */}
											<p className="cont">{missionDetail?.mssnDtl}</p>
										</li>
									</ul>
									{/* missionDetail?.prgrsState?.length === 6 && */}
									{missionDetail?.prgrsState?.includes("진") &&
										certCnt !== missionDetail?.mxCertCo && (
											<button
												type="button"
												className="on mt25"
												onClick={() =>
													handleCertified(
														missionDetail,
														missionDetail?.certDe === 'TXT'
															? '글'
															: missionDetail?.certDe === 'IMG'
																? '이미지'
																: missionDetail?.certDe === 'QR'
																	? '큐알'
																	: '',
														member?.id
													)
												}
											>
												{missionDetail?.certDe === 'TXT'
													? '글 인증하기'
													: missionDetail?.certDe === 'IMG'
														? '이미지 인증하기'
														: missionDetail?.certDe === 'QR'
															? 'QR 인증하기'
															: ''}
											</button>
										)}

									{missionDetail?.certOpenYn === 'Y' ? (
										<span className="explain mt10">
											※ 이 미션은 실천내역이 모두에게 공개되는 미션입니다.
											<br />※ 인증 완료 시 즉시 승인되며, 이후 관리자 확인을
											통해 잘못된 인증은 승인 취소 및 포인트 반환될 수 있습니다.
										</span>
									) : (
										<span className="explain mt10">
											※ 이 미션은 본인의 실천내역만 확인 가능한 미션입니다.
											<br />※ 인증 완료 시 즉시 승인되며, 이후 관리자 확인을
											통해 잘못된 인증은 승인 취소 및 포인트 반환될 수 있습니다.
										</span>
									)}
								</div>
								<div className="comm_detail_btn mt25">
									<a className="btn_list" onClick={handleListPage}>
										목록
									</a>
								</div>
							</div>
							{/* <div className="impor_img">
                                {missionDetail?.missionFiles.filter((list) => list.imgGb === '미션이미지').map((list2) => (
                                    <img src={list2 
                                        ? `/api/attach/getFile?savedPath=${list2?.savePath}`
                                        : defaultImg} />
                                ))}
                            </div> */}
							<div className="impor_img">
								{missionDetail?.missionFiles.some(
									(list) => list.imgGb === '미션이미지'
								) ? (
									missionDetail.missionFiles
										.filter((list) => list.imgGb === '미션이미지')
										.map((list2) => (
											<img
												key={list2.savePath}
												src={`/api/attach/getFile?savedPath=${list2.savePath}`}
											/>
										))
								) : (
									<img src={defaultImg} />
								)}
							</div>
						</div>
						{/* 미션 정보 종료 */}

						{/* 미션 탭 시작 */}
						<div className="tabs mt25">
							<button
								type="button"
								className={tab === 1 ? 'tab tab_active' : 'tab non_active'}
								onClick={() => setTab(1)}
							>
								방법
							</button>
							<button
								type="button"
								className={tab === 2 ? 'tab tab_active' : 'tab non_active'}
								onClick={() => setTab(2)}
							>
								순위
							</button>
							<button
								type="button"
								className={tab === 3 ? 'tab tab_active' : 'tab non_active'}
								onClick={() => setTab(3)}
							>
								현황
							</button>
						</div>
						{/* 미션 탭 종료 */}

						{/* 미션 탭 (방법) 시작 */}
						{tab === 1 && (
							<div className="tab_content mt25" data-id={0}>
								<div className="mission_cont_wrap">
									<div className="mission_cont_title">
										<h3 className="comm_title">실천인증 방법</h3>
									</div>
									<div className="mission_cont_content mt10">
										<p>{missionDetail?.certDes}</p>
									</div>
								</div>
								{(missionDetail?.certLnk != null ||
									missionDetail?.missionFiles.some(
										(list) => list.imgGb === '인증예시'
									)) && (
										<div className="mission_cont_wrap mt25">
											<div className="mission_cont_title">
												<h3 className="comm_title">실천인증 예시</h3>
											</div>
											<div className="mission_cont_content mt10">
												{/* 인증예시 파일 */}
												{missionDetail?.missionFiles
													.filter(
														(list) =>
															list.imgGb === '인증예시' &&
															list.savePath.includes('.pdf')
													)
													.map((pdfList, index) => (
														<div className="fileList">
															<a
																href={`/api/attach/getFile?savedPath=${pdfList.savePath}&download=true`}
															>
																{pdfList?.fileName}
															</a>
														</div>
													))}

												{/* 인증예시 링크         */}
												{missionDetail?.certLnk != null && (
													<a href={missionDetail?.certLnk} target="_blank">
														{missionDetail?.certLnk}
													</a>
												)}

												{/* 인증예시 이미지         */}
												{missionDetail?.missionFiles
													.filter(
														(list) =>
															list.imgGb === '인증예시' &&
															(list.savePath.includes('.jpg') ||
																list.savePath.includes('.png'))
													)
													.map((imgList, index) => (
														<img
															key={index}
															src={
																imgList.savePath
																	? `/api/attach/getFile?savedPath=${imgList.savePath}`
																	: errorImg
															}
														/>
													))}
											</div>
										</div>
									)}
							</div>
						)}
						{/* 미션 탭 (방법) 종료 */}

						{/* 미션 탭 (순위) 시작 */}
						{tab === 2 && (
							<div className="tab_content mt25" data-id={1}>
								<div className="mission_cont_wrap mt25">
									<div className="mission_cont_title">
										<h3 className="comm_title">마을순위</h3>
										<ul>
											<li>
												<p
													className={`${sort === '주간' && 'on'}`}
													onClick={() => setSort('주간')}
													style={{ cursor: 'pointer' }}
												>
													주간
												</p>
											</li>
											<li>
												<p
													className={`${sort === '월간' && 'on'}`}
													onClick={() => setSort('월간')}
													style={{ cursor: 'pointer' }}
												>
													월간
												</p>
											</li>
											<li>
												<p
													className={`${sort === '연간' && 'on'}`}
													onClick={() => setSort('연간')}
													style={{ cursor: 'pointer' }}
												>
													연간
												</p>
											</li>
										</ul>
									</div>

									{rankData && rankData.length === 0 ? (
										<div className="comm_noList">
											<img src={noList} alt="내용없음 이미지" />
											<p className="mt25">
												해당 기간에 미션 참여자가 없습니다.
											</p>
										</div>
									) : (
										<div className="mission_cont_content mt25">
											<div className="ranking_list">
												{/* 1등 */}
												{rankData.length > 0 && (
													<p>
														<span className="num">
															<img src={ic_rank01} alt="랭킹 아이콘" />
														</span>
														<span className="villname">
															{rankData[0]?.town.townName}{' '}
															<strong>
																(
																{rankData[0] &&
																	rankData[0]?.town &&
																	rankData[0]?.town.districts &&
																	rankData[0]?.town.districts
																		.map((list, index) => list.districtName)
																		.join(', ')}
																)
															</strong>
														</span>
														<span className="point">
															{rankData[0]?.point
																.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
													</p>
												)}

												{/* 2등   */}
												{rankData.length > 1 && (
													<p>
														<span className="num">
															<img src={ic_rank02} alt="랭킹 아이콘" />
														</span>
														<span className="villname">
															{rankData[1]?.town.townName}{' '}
															<strong>
																(
																{rankData[1]?.town.districts
																	.map((list, index) => list.districtName)
																	.join(', ')}
																)
															</strong>
														</span>
														<span className="point">
															{rankData[1]?.point
																.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
													</p>
												)}

												{/* 3등 */}
												{rankData.length > 2 && (
													<p>
														<span className="num">
															<img src={ic_rank03} alt="랭킹 아이콘" />
														</span>
														<span className="villname">
															{rankData[2]?.town.townName}{' '}
															<strong>
																(
																{rankData[2]?.town.districts
																	.map((list, index) => list.districtName)
																	.join(', ')}
																)
															</strong>
														</span>
														<span className="point">
															{rankData[2]?.point
																.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
													</p>
												)}

												{/* 4 ~ 10등 */}
												{rankData.slice(3, 10).map((list, index) => (
													<p key={index}>
														<span className="num">{index + 4}</span>
														<span className="villname">
															{list.town.townName}{' '}
															<strong>
																(
																{list.town.districts
																	.map((list2, index) => list2.districtName)
																	.join(', ')}
																)
															</strong>
														</span>
														<span className="point">
															{list.point
																.toString()
																.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
															E
														</span>
													</p>
												))}
											</div>
											<span className="update">
												최근 업데이트 {moment().format('YYYY.MM.DD')}
												<br />
												{sort === '주간'
													? '주간 순위는 지난주 기준입니다.'
													: sort === '월간'
														? '월간 순위는 이번달 기준입니다.'
														: sort === '연간'
															? '연간 순위는 올해 기준입니다.'
															: ''}
											</span>
										</div>
									)}
								</div>
							</div>
						)}
						{/* 미션 탭 (순위) 종료 */}

						{/* 미션 탭 (현황) 시작 */}
						{tab === 3 && (
							<div className="tab_content mt25" data-id={2}>
								<div className="mission_cont_wrap mt25">
									<div className="mission_cont_title">
										<h3 className="comm_title">
											실천인증 현황 <span>{certData && certData.length}</span>
										</h3>
									</div>

									<div className="mission_cont_content mt25">
										{certData && certData.length === 0 ? (
											<div className="comm_noList">
												<img src={noList} alt="내용없음 이미지" />
												<p className="mt25">미션 참여자가 없습니다.</p>
											</div>
										) : (
											<div className="situation_list">
												{/* 글 인증 */}
												{missionDetail?.certDe === 'TXT' &&
													certData.map((list, index) => (
														<div
															className={`box_situation_list ${list?.visible === 'N' ? 'non' : ''
																}`}
															key={index}
														>
															{((missionDetail?.certOpenYn === 'N' &&
																member?.id === list.crtUid) ||
																amnBtn ||
																missionDetail?.certOpenYn === 'Y') && (
																	<>
																		<div className="sl_info">
																			{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}
																			<h4
																				className={
																					list.charecter === 1
																						? 'basic1'
																						: list.charecter === 2
																							? 'basic2'
																							: list.charecter === 3
																								? 'Lv1'
																								: list.charecter === 4
																									? 'Lv2'
																									: list.charecter === 5
																										? 'Lv3'
																										: list.charecter === 6
																											? 'Lv4'
																											: ''
																				}
																			>
																				{list.nickname}
																			</h4>
																			<ul className="mt5">
																				<li>
																					<p>{list.townName}</p>
																				</li>
																				<li>
																					<p>{`${moment(list.crtDt).format(
																						'yyyy.MM.DD'
																					)}`}</p>
																				</li>

																				{amnBtn && (
																					<li>
																						<a

																							className="text_blue"
																							onClick={() =>
																								handleStatusChange(
																									'공개,비공개',
																									Number(list.mmcUid),
																									String(
																										list.visible === 'Y'
																											? 'Y'
																											: 'N'
																									)
																								)
																							}
																						>
																							{list?.visible === 'Y'
																								? '비공개'
																								: '공개'}
																						</a>
																					</li>
																				)}

																				{list.certProgrsSt === '신청' &&
																					amnBtn && (
																						<li>
																							<a

																								className="text_red"
																								onClick={() =>
																									handleStatusChange(
																										'포인트회수',
																										Number(list.mmcUid),
																										String(''),
																										String(list.certProgrsSt),
																										String(list.nickname),
																										Number(list.accmlPt)
																									)
																								}
																							>
																								포인트 회수
																							</a>
																						</li>
																					)}
																			</ul>
																		</div>
																		<div className="sl_text mt10">
																			<p>{list.certCn}</p>
																		</div>
																	</>
																)}
														</div>
													))}

												{/* 이미지 인증 */}
												{missionDetail?.certDe === 'IMG' &&
													certData.map((list, index) => (
														<div
															className={`box_situation_list ${list?.visible === 'N' ? 'non' : ''
																}`}
														>
															{((missionDetail?.certOpenYn === 'N' &&
																member?.id === list.crtUid) ||
																amnBtn ||
																missionDetail?.certOpenYn === 'Y') && (
																	<>
																		<div className="sl_info">
																			{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}
																			<h4
																				className={
																					list.charecter === 1
																						? 'basic1'
																						: list.charecter === 2
																							? 'basic2'
																							: list.charecter === 3
																								? 'Lv1'
																								: list.charecter === 4
																									? 'Lv2'
																									: list.charecter === 5
																										? 'Lv3'
																										: list.charecter === 6
																											? 'Lv4'
																											: ''
																				}
																			>
																				{list.nickname}
																			</h4>
																			<ul className="mt5">
																				<li>
																					<p>{list.townName}</p>
																				</li>
																				<li>
																					<p>{`${moment(list.crtDt).format(
																						'yyyy.MM.DD'
																					)}`}</p>
																				</li>
																				{amnBtn && (
																					<li>
																						<a

																							className="text_blue"
																							onClick={() =>
																								handleStatusChange(
																									'공개,비공개',
																									Number(list.mmcUid),
																									String(
																										list.visible === 'Y'
																											? 'Y'
																											: 'N'
																									)
																								)
																							}
																						>
																							{list?.visible === 'Y'
																								? '비공개'
																								: '공개'}
																						</a>
																					</li>
																				)}

																				{amnBtn &&
																					list.certProgrsSt === '신청' && (
																						<li>
																							<a

																								className="text_red"
																								onClick={() =>
																									handleStatusChange(
																										'포인트회수',
																										Number(list.mmcUid),
																										String(''),
																										String(list.certProgrsSt),
																										String(list.nickname),
																										Number(list.accmlPt)
																									)
																								}
																							>
																								포인트 회수
																							</a>
																						</li>
																					)}
																			</ul>
																		</div>
																		<div className="sl_text mt10">
																			{list.missionCertFiles &&
																				list.missionCertFiles.map(
																					(imgList, imgIdx) => (
																						<img
																							key={imgIdx}
																							src={
																								imgList.savePath
																									? `/api/attach/getFile?savedPath=${imgList.savePath}`
																									: errorImg
																							}
																						/>
																					)
																				)}
																		</div>
																	</>
																)}
														</div>
													))}

												{/* 큐알 인증 */}
												{missionDetail?.certDe === 'QR' &&
													certData.map((list, index) => (
														<div
															className={`box_situation_list ${list?.visible === 'N' ? 'non' : ''
																}`}
															key={index}
														>
															{((missionDetail?.certOpenYn === 'N' &&
																member?.id === list.crtUid) ||
																amnBtn ||
																missionDetail?.certOpenYn === 'Y') && (
																	<>
																		<div className="sl_info">
																			{/* 레벨별 basic1, basic2, Lv1, Lv2, Lv3, Lv4 */}
																			<h4
																				className={
																					list.charecter === 1
																						? 'basic1'
																						: list.charecter === 2
																							? 'basic2'
																							: list.charecter === 3
																								? 'Lv1'
																								: list.charecter === 4
																									? 'Lv2'
																									: list.charecter === 5
																										? 'Lv3'
																										: list.charecter === 6
																											? 'Lv4'
																											: ''
																				}
																			>
																				{list.nickname}
																			</h4>
																			<ul className="mt5">
																				<li>
																					<p>{list.townName}</p>
																				</li>
																				<li>
																					<p>{`${moment(list.crtDt).format(
																						'yyyy.MM.DD'
																					)}`}</p>
																				</li>
																				{amnBtn && (
																					<li>
																						<a

																							className="text_blue"
																							onClick={() =>
																								handleStatusChange(
																									'공개,비공개',
																									Number(list.mmcUid),
																									String(
																										list.visible === 'Y'
																											? 'Y'
																											: 'N'
																									)
																								)
																							}
																						>
																							{list?.visible === 'Y'
																								? '비공개'
																								: '공개'}
																						</a>
																					</li>
																				)}

																				{amnBtn &&
																					list.certProgrsSt === '신청' && (
																						<li>
																							<a

																								className="text_red"
																								onClick={() =>
																									handleStatusChange(
																										'포인트회수',
																										Number(list.mmcUid),
																										String(''),
																										String(list.certProgrsSt),
																										String(list.nickname),
																										Number(list.accmlPt)
																									)
																								}
																							>
																								포인트 회수
																							</a>
																						</li>
																					)}
																			</ul>
																		</div>
																		<div className="sl_text mt10">
																			<p>QR인증이 완료되었습니다.</p>
																		</div>
																	</>
																)}
														</div>
													))}
												<Pagination
													page={Number(page)}
													onPageChange={handlePageChange}
													// totalPageCount={Math.ceil(count / 8)}
													totalPageCount={Number(totalPage)}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						)}
						{/* 미션 탭 (현황) 종료 */}
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<SubLocation
				titleText1="참여마당"
				liList={SubLocationList}
				titleText2="에너지전환 활동미션"
				liList2={SubLocationList2}
			/>
			<div className="lnb_txt">
				<h3>마을미션</h3>
				<select>
					<option>마을미션</option>
					<option>기본미션</option>
				</select>
				<p>
					마을이 주체가 되어 에너지 전환에 도움이 되는 활동을 실천하고 인증해요
				</p>
			</div>
		</nav>
	);
};

export default Detail_VillageMissionPage;
