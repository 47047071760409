import axios from 'axios';
import MissionInfo, { MissionList } from '../models/MissionInfo';
import MissionInfoDetail, { Content } from '../models/MissionInfoDetail';
import { serialize } from 'object-to-formdata';
import MapMission from '../models/MpaMission';
import { CategoryListInfo } from '../pages/missionYard/Write_MapMissionPage';

export interface GetParams {
	page?: number;
	size?: number;
	mssnDe?: string;
	mssnDe2?: string;
	mmiUid?: number;
	visible?: string;
	delDt?: string;
	mmcUid?: number;
	certProgrsSt?: string;
	period?: string;
	sort?: string;
	prgrsSt?: string;
	cert_page?: number;
	cert_size?: number;
	cmcUid?: number;
	keyword?: string;
	month?: string;
	yeat?: string;
	past?: string;
}
// 미션 목록조회
const getMissionList = (params: GetParams) => {
	return axios
		.get('/api/mission/list?sort=prgrsSt,ASC&sort=crtDt,DESC', { params })
		.then((res) => res.data as MissionList);
};

// 미션 등록
const insertMission = (
	params: MissionInfo,
	missionUploadFiles?: File[] | FileList,
	uploadFiles?: File[] | FileList,
	pdfFiles?: File[] | FileList
) => {
	// console.log('등록 파람1', params);
	// console.log('등록 파람2', missionUploadFiles);
	// console.log('등록 파람3', uploadFiles);
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		if (value != undefined) {
			formData.append(key, value);
		}
	});
	// 미션 파일추가
	if (missionUploadFiles) {
		const missionFiles = Array.from(missionUploadFiles);
		for (let i = 0; i < missionFiles.length; i++) {
			formData.append('mUploadFiles', missionFiles[i], missionFiles[i].name);
		}
	}
	// 인증예시 파일추가
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('cUploadFiles', files[i], files[i].name);
		}
	}

	if (pdfFiles) {
		const FilesPdf = Array.from(pdfFiles);
		for (let i = 0; i < FilesPdf.length; i++) {
			formData.append('pdfFiles', FilesPdf[i], FilesPdf[i].name);
		}
	}
	return axios
		.post('/api/mission/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};

// 지도미션 등록
const insertMapMission = (
	params: MapMission,
	categoryList: CategoryListInfo[],
	missionUploadFiles?: File[] | FileList,
	uploadFiles?: File[] | FileList
) => {
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		if (value != undefined) {
			formData.append(key, value);
		}
	});
	if (categoryList) {
		const cateList = Array.from(categoryList);
		for (let i = 0; i < cateList.length; i++) {
			formData.append(`categoryList[${i}].title`, cateList[i].title);
			formData.append(`markerList[${i}].markerFile`, cateList[i].marker);
		}
	}
	// 미션 파일추가
	if (missionUploadFiles) {
		const missionFiles = Array.from(missionUploadFiles);
		for (let i = 0; i < missionFiles.length; i++) {
			formData.append('mUploadFiles', missionFiles[i], missionFiles[i].name);
		}
	}
	// 인증예시 파일추가
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('cUploadFiles', files[i], files[i].name);
		}
	}

	return axios
		.post('/api/mission/savemap', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};

// 미션 수정
const updateMission = (
	params: MissionInfo,
	missionUploadFiles?: File[] | FileList,
	uploadFiles?: File[] | FileList,
	deleteImgFileIds?: number[],
	pdfFiles?: File[] | FileList,
	deletePdfFileIds?: number[]
) => {
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		if (value != undefined) {
			formData.append(key, value);
		} else {
			if (key == 'certLnk') {
				formData.append(key, 'null');
			}
		}
	});
	// 미션 파일추가
	if (missionUploadFiles) {
		const missionFiles = Array.from(missionUploadFiles);
		for (let i = 0; i < missionFiles.length; i++) {
			formData.append('mUploadFiles', missionFiles[i], missionFiles[i].name);
		}
	}
	// 인증예시 파일추가
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('cUploadFiles', files[i], files[i].name);
		}
	}

	if (deleteImgFileIds?.length !== 0) {
		if (deleteImgFileIds) {
			const imgDel = Array.from(deleteImgFileIds);
			for (let i = 0; i < imgDel.length; i++) {
				if (imgDel[i]) {
					formData.append(`missionFileList[${i}]`, String(imgDel[i]));
				}
			}
		}
	}

	if (pdfFiles) {
		const FilesPdf = Array.from(pdfFiles);
		for (let i = 0; i < FilesPdf.length; i++) {
			formData.append('pdfFiles', FilesPdf[i], FilesPdf[i].name);
		}
	}

	if (deletePdfFileIds?.length !== 0) {
		if (deletePdfFileIds) {
			const pdfDel = Array.from(deletePdfFileIds);
			for (let i = 0; i < pdfDel.length; i++) {
				if (pdfDel[i]) {
					formData.append(`missionFileList[${i}].mmfUid`, String(pdfDel[i]));
				}
			}
		}
	}
	return axios
		.post('/api/mission/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};

// 지도미션 수정
const updateMapMission = (
	params: MapMission,
	categoryList: CategoryListInfo[],
	missionUploadFiles?: File[] | FileList,
	uploadFiles?: File[] | FileList,
	deleteImgFileIds?: number[]
) => {
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		if (value != undefined) {
			formData.append(key, value);
		}
	});
	if (categoryList) {
		const cateList = Array.from(categoryList);
		for (let i = 0; i < cateList.length; i++) {
			formData.append(`categoryList[${i}].title`, cateList[i].title);
			formData.append(`markerList[${i}].markerFile`, cateList[i].marker);
			if (cateList[i].cmcUid) {
				formData.append(
					`categoryList[${i}].cmcUid`,
					JSON.stringify(cateList[i].cmcUid)
				);
				formData.append(
					`markerList[${i}].cmcUid`,
					JSON.stringify(cateList[i].cmcUid)
				);
			}
		}
	}
	// 미션 파일추가
	if (missionUploadFiles) {
		const missionFiles = Array.from(missionUploadFiles);
		for (let i = 0; i < missionFiles.length; i++) {
			formData.append('mUploadFiles', missionFiles[i], missionFiles[i].name);
		}
	}
	// 인증예시 파일추가
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('cUploadFiles', files[i], files[i].name);
		}
	}
	if (deleteImgFileIds?.length !== 0) {
		if (deleteImgFileIds) {
			const imgDel = Array.from(deleteImgFileIds);
			for (let i = 0; i < imgDel.length; i++) {
				if (imgDel[i]) {
					formData.append(`missionFileList[${i}]`, String(imgDel[i]));
				}
			}
		}
	}
	return axios
		.post('/api/mission/updatemap', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};
// 지도미션 인증 단일 조회
export interface CertParams {
	mmcUid: number;
}
const getCertOne = (params: CertParams) => {
	return axios
		.get('/api/missionCert/get', { params })
		.then((res) => res as any);
};
// 미션 상세
const getMissionDetail = (params: GetParams) => {
	return axios
		.get('/api/mission/get?cert_sort=crtDt,DESC', { params })
		.then((res) => {
			// console.log('@@@@', res);
			return res.data as MissionInfoDetail;
		});
};
// 하영 작성 231018

// 미션 비공개, 공개 전환
const privateMission = (params: GetParams) => {
	return axios
		.post('/api/mission/update', serialize(params))
		.then((res) => res as any);
};
// 미션 삭제
const deleteMission = (params: GetParams) => {
	return axios
		.post('/api/mission/update', serialize(params))
		.then((res) => res as any);
};

// 미션 인증하기 등록 > 글 인증하기, 고지서 인증하기
const insertCert = (params: Content) => {
	return axios
		.post('/api/missionCert/save', serialize(params))
		.then((res) => res as any);
};
// 이미지 인증하기, 지도미션 인증하기
const insertCert2 = (data: Content, uploadFiles: File[] | FileList) => {
	// const formData = new FormData();
	// formData.append('mmiUid', JSON.stringify(data.mmiUid));
	// formData.append('tcUid', JSON.stringify(data.tcUid));
	// formData.append('accmlPt', JSON.stringify(data.accmlPt));
	// // 지도미션
	// formData.append('certCn', data.certCn || undefined);
	// formData.append('cntrLat', JSON.stringify(data.cntrLat));
	// formData.append('cntrLng', JSON.stringify(data.cntrLng));
	// formData.append('locInfo', JSON.stringify(data.locInfo));
	// formData.append('cmcUid', JSON.stringify(data.cmcUid));
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		if (value != undefined) {
			formData.append(key, value);
		} else {
			formData.append(key, 'null');
		}
	});
	if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFile', files[i], files[i].name);
		}
	}

	return axios
		.post('/api/missionCert/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};

// 인증 상태변경
const certStatusChange = (params: GetParams) => {
	return axios
		.post('/api/mission/statusChange', serialize(params))
		.then((res) => res as any);
};
// 에너지 현황 > 에너지 전환 활동 데이터 조회
export interface EnergyParams {
	tcUid?: number;
	currentMonth?: string;
}
const getEnergy = (params: EnergyParams) => {
	return axios
		.get('/api/mission/current', { params })
		.then((res) => res as any);
};
// 에너지 현황 > 에너지 전환 랭킹 목록 조회
export interface RankParams {
	mssnDe?: string;
	mmiUid?: number;
	villMmiUid?: number;
	period?: string;
	past?: string;
}
const getRanking = (params: RankParams) => {
	return axios
		.get('/api/mission/ranking', { params })
		.then((res) => res as any);
};
// 하영추가 231102
const getMyEnergy = () => {
	return axios.get('/api/mission/current?my=my').then((res) => res as any);
};
const getMyPoint = () => {
	return axios.get('/api/mission/myCurrentPoint').then((res) => res as any);
};
const MissionAPI = {
	insertMission,
	insertMapMission,
	updateMapMission,
	updateMission,
	getMissionList,
	getMissionDetail,
	privateMission,
	deleteMission,
	insertCert,
	insertCert2,
	certStatusChange,
	getEnergy,
	getRanking,
	getMyEnergy,
	getMyPoint,
	getCertOne,
};

export default MissionAPI;
