import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import NavComponent from '../../../components/common/NavComponent';
import Pagination from '../../../components/common/Pagination';
import CommTab from '../CommTab';
import './List02_ActivePage.scss';
import backIcon from '../../../assets/ic_back.png';
import mapinfoIcon from '../../../assets/ic_mapinfo.png';
import settingIcon from '../../../assets/ic_map_setting.png';
import deleteIcon from '../../../assets/ic_delete.png';
import useMember from '../../../hooks/useMember';
import ActivityLogAPI from '../../../apis/ActivityLogAPI';
import { Content } from '../../../models/ActivityLog';
import { useDialog } from '../../../dialog/DialogProvider';
import ActiveLogInfoDialog from '../../../dialog/active/ActiveLogInfoDialog';
import ModifyDialog from '../../../dialog/common/ModifyDialog';
import DeleteDialog from '../../../dialog/common/DeleteDialog';
import noList from '../../../assets/noList.png';
import moment from 'moment';
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// 소통마당 > 활동일지 > 활동일지 주제 내역목록
const List02_ActivePage = () => {
	const townId = sessionStorage.getItem('town');
	const subjectId = sessionStorage.getItem('subject');
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const { member } = useMember();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(1);
	const [cate, setCate] = useState<string>('createdAt,DESC');
	const [subjectDetail, setSubjectDetail] = useState<Content | null>();
	const [journalList, setJournalList] = useState([] as Content[]);
	const [searchKeyword, setSearchKeyword] = useState<string>();
	const [search, setSearch] = useState<string>();
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	// 글 권한
	const [postRoles, setPostRoles] = useState(false);
	const [postUpdateRoles, setPostUpdateRoles] = useState(false);
	// 글, 댓글 비공개 권한
	const [praviteRoles, setPraviteRoles] = useState(false);
	// 마을뉴스 카테고리 등록 권한
	const [newsCateRoles, setNewsCateRoles] = useState(false);

	//권한 별 글 및 댓글 등록, 수정, 삭제 마을뉴스 카테고리 등록 권한 아직 테스트 못함 2023.09.05
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setPostRoles(true);
				setPraviteRoles(true);
				setPostUpdateRoles(true);
				// setNewsCateRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setPostRoles(true);
					setPraviteRoles(true);
					setPostUpdateRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				if (
					member.townDTO.townId === Number(townId)
					&&
					member.roles === 'ABR'
				) {
					// console.log('권한 : 마을활동가, 마을주민');
					setPostRoles(true);
				}
			}
		}
	}, []);

	useEffect(() => {
		ActivityLogAPI.getSubjectDetail(Number(subjectId))
			.then((res) => {
				if (res) {
					// console.log('활동주제내역 목록 >>', res);
					setSubjectDetail(res);

					const formattedStartDate = `${res.startedAt[0]}.${String(
						res.startedAt[1]
					).padStart(2, '0')}.${String(res.startedAt[2]).padStart(2, '0')}`;
					const formattedEndDate = `${res.endedAt[0]}.${String(
						res.endedAt[1]
					).padStart(2, '0')}.${String(res.endedAt[2]).padStart(2, '0')}`;
					const startDate = new Date(formattedStartDate);
					const endDate = new Date(formattedEndDate);
					// console.log('엔드 데이트', endDate);

					res.startDate = formattedStartDate;
					res.endDate = formattedEndDate;
					// console.log('뭐임', formattedStartDate);
				} else {
					setSubjectDetail(null);
				}
			})
			.catch((error) => {
				setSubjectDetail(null);
			});
		ActivityLogAPI.getJournalList({
			page: page - 1,
			size: 8,
			keyword: searchKeyword ? searchKeyword : undefined,
			subjectId: Number(subjectId),
			sort: cate,
		}).then((res) => {
			// console.log(res);
			setJournalList(res.content);
			setCount(res.totalElements);
		});
	}, [cate, page, searchKeyword]);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// 주제 정보
	const handleSubjectInfo = () => {
		showDialog(ActiveLogInfoDialog);
	};
	// 주제내역 상세이동
	const handleHistoryDetail = (journalId: number) => {
		sessionStorage.setItem('journal', String(journalId));
		navigate('/commu_02/active_03-detail');
	};
	// 주제내역 등록하기
	const handleHistoryWrite = () => {
		navigate('/commu_02/active_04-write-history');
	};
	// 주제 수정하기
	const handleModifySubject = () => {
		const tab = 'activeSubject';
		sessionStorage.setItem('subject', String(subjectId));
		showDialog(ModifyDialog, { tab });
		// navigate('/commu_02/active_04-modify')
	};
	// 주제 삭제하기
	const handleDeleteSubject = (subjectId: number) => {
		const tab = 'activeSubject';
		sessionStorage.setItem('subject', String(subjectId));
		showDialog(DeleteDialog, { tab, subjectId });
		// navigate('/commu_02/active_04-modify')
	};
	// 최신순, 공감순 카테고리
	const handleCate = (cate: string) => {
		setCate(cate);
	};
	// 뒤로가기
	const handleBack = () => {
		navigate('/commu_02/active_03');
	};
	// 검색창 엔터
	const handleEnterSearch = (e: any) => {
		if (e.key === 'Enter') {
			setSearchKeyword(search);
		}
	};
	// 검색 버튼클릭
	const handleClickSearch = () => {
		setSearchKeyword(search);
	};
	return (
		<main className="List02_ActivePage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={3} />

					{/* </div> */}
					<div className="village_record mt40">
						{/* <div className="record_info">
							<div className="record_title_box">
								<a onClick={handleBack}>
									<img src={backIcon} alt="뒤로가기" />
								</a>
								<h3>{subjectDetail?.title}</h3>
							</div>
							<div className="record_btn_box">
								<button
									type="button"
									className="btn_info"
									onClick={handleSubjectInfo}
								>
									<img src={mapinfoIcon} alt="활동일지 주제 정보 아이콘" />
								</button>
								{postUpdateRoles && (
									<button
										type="button"
										className="btn_setting"
										onClick={handleModifySubject}
									>
										<img src={settingIcon} alt="활동일지 주제 설정 아이콘" />
									</button>
								)}
							</div>
						</div> */}
						<div className="record_info">
							<a >
								<img src={backIcon} onClick={handleBack} alt="뒤로가기" />
							</a>
							<div className="record_title_box">
								<h3>{subjectDetail?.title}</h3>
								<p className="mt5">
									{subjectDetail?.startDate}~{subjectDetail?.endDate}
								</p>
								<p className="txt mt10">
									{subjectDetail?.content.split('\n').map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
								</p>
								<div className='record_title_box_con'>
									<Swiper
										className="swiper-wrapper"
										navigation
									// onSwiper={(swiper) => {
									// 	setSwiper(swiper);
									// }}
									>
										{subjectDetail && subjectDetail?.subjectFileList.length > 0 && (
											subjectDetail?.subjectFileList.map((list, index) => (
												<>
													<SwiperSlide>
														<img
															key={index}
															style={{ width: '100%' }}
															src={`/api/attach/getFile?savedPath=${subjectDetail &&
																list.filePath
																}`}
															alt="활동주제 이미지"
															className="mt10"
														/>
													</SwiperSlide>
												</>
											))
										)}
									</Swiper>
								</div>
							</div>
							<div className="record_btn_box">
								{postUpdateRoles && (
									<button
										type="button"
										className="btn_setting"
										onClick={handleModifySubject}
									>
										<img src={settingIcon} alt="활동일지 주제 설정 아이콘" />
									</button>
								)}
								{postUpdateRoles && (
									<button
										type="button"
										className="btn_delete"
										onClick={() => handleDeleteSubject(Number(subjectId))}
									>
										<img src={deleteIcon} alt="활동일지 삭제 아이콘" />
									</button>
								)}
							</div>
						</div>

						<div className="comm_search mt25">
							<ul>
								<li>
									<div className="comm_search_plus">
										<input
											type="text"
											placeholder="검색어를 입력해주세요."
											onChange={(e) => setSearch(e.target.value)}
											onKeyPress={handleEnterSearch}
										/>
									</div>
									<button type="button" onClick={handleClickSearch}>
										검색
									</button>
								</li>
							</ul>
						</div>
						<div className="comm_tool mt25">
							<ul>
								<li>
									<p
										className={`${cate === 'createdAt,DESC' && 'on'}`}
										onClick={() => handleCate('createdAt,DESC')}
										style={{ cursor: 'pointer' }}
									>
										최신순
									</p>
								</li>
								<li>
									<p
										className={`${cate === 'likeCnt,DESC' && 'on'}`}
										onClick={() => handleCate('likeCnt,DESC')}
										style={{ cursor: 'pointer' }}
									>
										공감순
									</p>
								</li>
							</ul>
							{postRoles && (
								<button
									type="button"
									className="comm_write_button"
									onClick={handleHistoryWrite}
								>
									등록하기
								</button>
							)}
						</div>
						<div className="village_record_list mt25">
							<ul>
								{journalList && journalList.length == 0 ? (
									<div className="comm_noList">
										<img src={noList} alt="내용없음 이미지" />
										<p className="mt25">{nullListMessage}</p>
									</div>
								) : (
									journalList.map((list, index) => (
										// 비공개 게시물 non클래스 붙음
										<li
											key={index}
											className={list.visible === 'Y' ? '' : 'non'}
											onClick={() => handleHistoryDetail(list.journalId)}
										>
											<a className="pa30">
												<div className="box_record">
													<h4 className="comm_title txt_cut2">
														{list.content}
													</h4>
													<div className="info01 mt10">
														<p
															className={
																list.character === 1
																	? 'name basic1'
																	: list.character === 2
																		? 'name basic2'
																		: list.character === 3
																			? 'name Lv1'
																			: list.character === 4
																				? 'name Lv2'
																				: list.character === 5
																					? 'name Lv3'
																					: list.character === 6
																						? 'name Lv4'
																						: ''
															}
														>
															{list.nickname}
														</p>
														<p>{moment(list.createdAt).format('yyyy.MM.DD')}</p>
													</div>
													<div className="info02 mt10">
														<p className="ic_comm_heart">{list.likeCnt}</p>
														<p className="ic_comm_comment">{list.commentCnt}</p>
													</div>
												</div>
											</a>
										</li>
									))
								)}
							</ul>
						</div>
						<Pagination
							page={page}
							// totalPageCount={10}
							totalPageCount={Math.ceil(count / 8)}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			</article>
		</main>
	);
};

export default List02_ActivePage;
