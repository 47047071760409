import React from 'react';
import { DialogProps } from '../DialogProvider';

interface ImageDialog extends DialogProps {
	type: '';
}

const ImageDialog = ({ ...props }: ImageDialog) => {
	const handleClose = () => {
		props.onClose?.();
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>사진 첨부</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">사진은 1장만 업로드 할 수 있습니다.</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_check one" onClick={handleClose}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};
export default ImageDialog;
