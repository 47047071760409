import React from 'react';
import { DialogProps } from '../DialogProvider';
import test06 from '../../assets/test06.png'
import './AdminPage_InfoStoreDialog.scss'
interface AdminPage_InfoStoreDialog extends DialogProps {
    type: '';

}
const AdminPage_InfoStoreDialog = ({ ...props }: AdminPage_InfoStoreDialog) => {
    const handleClose = () => {
        props.onClose?.();
    }
    return (
        <div className='AdminPage_InfoStoreDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title">
                        <h3>
                            커피전문점
                            <button type="button">수정</button>
                        </h3>
                        <a className="popup_close" onClick={handleClose}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC" alt="닫기" />
                        </a>
                    </div>
                    <div className="comm_popup_content mt25">
                        {/* 이미지 내용 시작 */}
                        <div className="detailInfo_box">
                            <img src={test06} alt="상점 이미지" />
                            <p>
                                거점마을 대표 커피전문점 "ㅇㅇㅇ"입니다.
                                쌉싸름하고 고소한 풍미가 가득한 커피와 달콤한 마카롱을 판매하고 있어요.
                            </p>
                        </div>
                        {/* 이미지 내용 종료 */}
                        {/*회원정보란 시작*/}
                        <div className="information_box mt25">
                            <ul>
                                <li>
                                    <span>상점코드</span>
                                    <p>F01023451</p>
                                </li>
                                <li>
                                    <span>연락처</span>
                                    <p>010-2222-3333</p>
                                </li>
                                <li>
                                    <span>소재지</span>
                                    <p>서구 상무평화로 89 상무구영드림빌 203호</p>
                                </li>
                                <li>
                                    <span>사업자<br />등록번호</span>
                                    <p>111-1111-111</p>
                                </li>
                                <li>
                                    <span>대표자</span>
                                    <p>홍길동</p>
                                </li>
                                <li>
                                    <span>예금주</span>
                                    <p>홍길동</p>
                                </li>
                                <li>
                                    <span>은행명</span>
                                    <p>광주은행</p>
                                </li>
                                <li>
                                    <span>계좌번호</span>
                                    <p>000-0000-0000</p>
                                </li>
                            </ul>
                        </div>
                        {/*회원정보란 종료*/}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AdminPage_InfoStoreDialog;