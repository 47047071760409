import { useEffect, useRef, useState } from 'react';
import ic_home from '../../assets/ic_home.png';
import CommSelect from './CommSelect';
import $ from 'jquery'; // Import jQuery library

interface Items {
	href: string;
	text: string;
}

interface SubLocationList {
	titleText1?: string;
	titleText2?: string;
	liList?: Items[];
	liList2?: Items[];
}

const SubLocation = ({
	liList,
	liList2,
	titleText1,
	titleText2,
}: SubLocationList) => {
	const [clickCheck, setClickCheck] = useState(false);
	const [clickCheck2, setClickCheck2] = useState(false);

	const handleClickDep1 = () => {
		setClickCheck(!clickCheck);
		setClickCheck2(false);
	};

	const handleClickDep2 = () => {
		setClickCheck2(!clickCheck2);
		setClickCheck(false);
	};

	// console.log("liList",liList)
	// console.log("liList2",liList2)

	//서브 네비게이션 열고닫기
	$('body').on('click', '.lnb_menu .lnb_dep1_li > a', function () {
		const idx = $('.lnb_menu .lnb_dep1_li').index($(this).parent());
		$('.lnb_menu .lnb_dep1_li ').each(function (index) {
			if (idx == index) {
				if ($(this).is('.on') == true) {
					// $(this).addClass('on');
					$(this).children('ul').stop().slideDown(150);
				} else {
					// $(this).removeClass('on');
					$(this).children('ul').stop().slideUp(75);
				}
			} else {
				// $(this).removeClass('on');
				$(this).children('ul').stop().slideUp(75);
			}
		});
	});

	// 서브 네비게이션 외 바깥영역 눌렀을때
	$(document).ready(function () {
		var target = $('.lnb_menu .lnb_dep1_li');

		$(document).mouseup(function (e: any) {
			if (target.has(e.target).length == 0) {
				$('.lnb_menu .lnb_dep1_li').removeClass('on');
				setClickCheck(false);
				setClickCheck2(false);
				$('.lnb_menu .lnb_dep2').hide();
			}
		});
	});

	return (
		<div className="lnb_menu">
			<ul className="lnb_dep1">
				<li className="lnb_dep1_li">
					<a href="/" className="home">
						<img src={ic_home} alt="메인으로" />
					</a>
				</li>

				<li
					className={`lnb_dep1_li ${clickCheck ? 'on' : ''} ${
						!liList2 ? 'end' : ''
					}`}
					onClick={handleClickDep1}
				>
					<a>{titleText1}</a>
					<ul className="lnb_dep2">
						{/* <li className="active">
							<a >소통마당</a>
						</li>
						<li>
							<a >참여마당</a>
						</li>
						<li>
							<a >정보마당</a>
						</li> */}
						{liList?.map((list, index) => (
							<li
								key={index}
								className={titleText1 === list.text ? 'active' : ''}
								style={{ display: liList ? 'block' : 'none' }}
							>
								<a href={list.href}>{list.text}</a>
							</li>
						))}
					</ul>
				</li>

				{/* <li className="lnb_dep1_li end on"> */}
				{liList2 && (
					<li
						className={`lnb_dep1_li ${clickCheck2 ? 'on' : ''}  ${
							liList2 ? 'end' : ''
						}`}
						onClick={handleClickDep2}
						style={{ display: liList2 ? 'block' : 'none' }}
					>
						<a>{titleText2}</a>
						<ul className="lnb_dep2">
							{/* <li className="active">
							<a >참여소개</a>
						</li>
						<li>
							<a >참여내용</a>
						</li>
						<li>
							<a >참여공지</a>
						</li> */}
							{liList2?.map((list, index) => (
								<li
									key={index}
									className={titleText2 === list.text ? 'active' : ''}
								>
									<a href={list.href}>{list.text}</a>
								</li>
							))}
						</ul>
					</li>
				)}
			</ul>
		</div>
	);
};

export default SubLocation;
