import axios from 'axios';
import ChallengeInput, {
	DeleteOrPrivateChallenge,
	SelectChallengeMissions,
} from '../models/ChallengeInput';
import { serialize } from 'object-to-formdata';
import DetailChanllengeInfo from '../models/DetailChallengeInfo';

// 챌린지 등록
const insertChallenge = (
	params: ChallengeInput,
	selectVillMission?: SelectChallengeMissions[],
	selectMission?: SelectChallengeMissions[],
	pymntPt?: string[],
	topRank?: string[]
) => {
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		formData.append(key, value);
	});
	let listIndex = 0;
	// // console.log(params.pymntPt);
	if (selectVillMission) {
		// // console.log('마을미션', selectVillMission);
		for (let i = 0; i < selectVillMission.length; i++) {
			formData.append(
				`missionUids[${listIndex}]`,
				String(selectVillMission[i].mmiUid)
			);
			listIndex++;
		}
	}
	if (selectMission) {
		// // console.log('기본미션', selectMission);
		for (let i = 0; i < selectMission.length; i++) {
			formData.append(
				`missionUids[${listIndex}]`,
				String(selectMission[i].mmiUid)
			);
			listIndex++;
		}
	}
	if (topRank) {
		for (let i = 0; i < topRank.length; i++) {
			formData.append(`challengeRewards[${i}].topRank`, topRank[i]);
		}
	}
	if (pymntPt) {
		for (let i = 0; i < pymntPt.length; i++) {
			formData.append(`challengeRewards[${i}].pymntPt`, pymntPt[i]);
		}
	}

	return axios
		.post('/api/challenge/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};
// 챌린지 수정
const updateChallenge = (
	params: ChallengeInput,
	selectVillMission?: SelectChallengeMissions[],
	selectMission?: SelectChallengeMissions[],
	pymntPt?: string[],
	topRank?: string[]
) => {
	const formData = new FormData();
	Object.entries(params).forEach(([key, value]) => {
		formData.append(key, value);
	});
	let listIndex = 0;
	// // console.log(params.pymntPt);
	if (selectVillMission) {
		// // console.log('마을미션', selectVillMission);
		for (let i = 0; i < selectVillMission.length; i++) {
			formData.append(
				`missionUids[${listIndex}]`,
				String(selectVillMission[i].mmiUid)
			);
			listIndex++;
		}
	}
	if (selectMission) {
		// // console.log('기본미션', selectMission);
		for (let i = 0; i < selectMission.length; i++) {
			formData.append(
				`missionUids[${listIndex}]`,
				String(selectMission[i].mmiUid)
			);
			listIndex++;
		}
	}
	if (topRank) {
		for (let i = 0; i < topRank.length; i++) {
			formData.append(`challengeRewards[${i}].topRank`, topRank[i]);
		}
	}
	if (pymntPt) {
		for (let i = 0; i < pymntPt.length; i++) {
			formData.append(`challengeRewards[${i}].pymntPt`, pymntPt[i]);
		}
	}

	return axios
		.post('/api/challenge/update', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res as any);
};
// 챌린지 삭제
const deleteChallenge = (params: DeleteOrPrivateChallenge) => {
	return axios
		.post('/api/challenge/update', serialize(params))
		.then((res) => res as any);
};
// 챌린지 비공개
const privateChallenge = (params: DeleteOrPrivateChallenge) => {
	return axios
		.post('/api/challenge/update', serialize(params))
		.then((res) => res.data as any);
};
// 챌린지 수정 상세조회
const getModifyChallengeOne = (params: GetAllParams) => {
	return axios
		.get('/api/challenge/get', { params })
		.then((res) => res.data as DetailChanllengeInfo);
};

// 챌린지 목록
const getChallengeList = () => {
	return axios
		.get('/api/challenge/list?sort=prgrsSt,ASC&sort=crtDt,DESC')
		.then((res) => res.data);
};
// 하영 20231025 추가
export interface MyParams {
	my?: string;
	prgrsSt?: string;
	// sort?: string;
}
// 나의 챌린지 목록
const getMyChallengeList = (params: MyParams) => {
	return axios
		.get('/api/challenge/list?sort=prgrsSt,ASC&sort=crtDt,DESC', { params })
		.then((res) => res.data);
};
export interface GetAllParams {
	mciUid?: number;
}
// 챌린지 상세조회
const getChallengeOne = (params: GetAllParams) => {
	return axios.get('/api/challenge/get', { params }).then((res) => res.data);
};
// 챌린지 참여하기
const insertParticipate = (params: GetAllParams) => {
	return axios
		.post('/api/challenge/participate', serialize(params))
		.then((res) => res.data);
};
const ChallengeAPI = {
	insertChallenge,
	updateChallenge,
	deleteChallenge,
	privateChallenge,
	getModifyChallengeOne,
	getChallengeList,
	getChallengeOne,
	insertParticipate,
	getMyChallengeList,
};
export default ChallengeAPI;
