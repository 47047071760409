import React, { useEffect, useState } from 'react';
import SubLocation from '../../components/common/SubLocation';
import './Main_MyPage.scss';
import ic_home from '../../assets/ic_home.png';
import test07 from '../../assets/test07.png';
import useMember from '../../hooks/useMember';
import MemberInfo from '../../models/MemberInfo';
import { useDialog } from '../../dialog/DialogProvider';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import UpdateCharacterDialog from '../../dialog/myPage/UpdateCharacterDialog';

import character01 from '../../assets/character01.png';
import character02 from '../../assets/character02.png';
import character03 from '../../assets/character03.png';
import character04 from '../../assets/character04.png';
import character05 from '../../assets/character05.png';
import character06 from '../../assets/character06.png';
import { useNavigate } from 'react-router-dom';
import MissionAPI from '../../apis/MissionAPI';
import { MyPoint } from '../../models/MissionInfo';

// 마이페이지
const Main_MyPage = () => {
	const { member } = useMember();
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	useEffect(() => {
		if(member?.roles == 'STB' || member?.townDTO.townId == null) {
			const message = '회원가입을 진행해 주세요.';
			const title = '';
			showDialog(OneButtonAlertDialog, {
				message,
				title,
			});
			navigate('/join_04/');
		}
	}, []);

	return (
		<main className="Main_MyPage">
			<h1 className="notext">전체 영역</h1>
			<Nav />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="mypage">
						{/* 캐릭터 현황 및 설정 */}
						<Character />
						{/* 메뉴 이동 */}
						<Menu member={member} />
						{/* 포인트 현황 및 교환 */}
						{/* <Point /> */}
						{/* 에너지전환 활동 현황 */}
						{/* <Energy /> */}
					</div>
				</div>

				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

const Nav = () => {
	return (
		<nav id="lnb">
			<h2 className="notext">네비게이션 영역</h2>
			<div className="lnb_menu">
				<ul className="lnb_dep1">
					<li className="lnb_dep1_li">
						<a href="/" className="home">
							<img src={ic_home} alt="메인으로" />
						</a>
					</li>
					<li className="lnb_dep1_li end">
						<a href="/myPage_02/">마이페이지</a>
					</li>
				</ul>
			</div>
			<div className="lnb_txt">
				<h3>마이페이지</h3>
				<p>내 정보를 확인하고 관리할 수 있습니다.</p>
			</div>
		</nav>
	);
};

// 캐릭터 현황 및 설정
const Character = () => {
	const { member } = useMember();
	const { showDialog } = useDialog();
	const [myCharacter, setMyCharacter] = useState<string>();

	useEffect(() => {
		if (member?.character == 1) {
			setMyCharacter(character01);
		} else if (member?.character == 2) {
			setMyCharacter(character02);
		} else if (member?.character == 3) {
			setMyCharacter(character03);
		} else if (member?.character == 4) {
			setMyCharacter(character04);
		} else if (member?.character == 5) {
			setMyCharacter(character05);
		} else if (member?.character == 6) {
			setMyCharacter(character06);
		}
	}, []);

	const handleChangeCharacter = () => {
		showDialog(UpdateCharacterDialog);
	};
	return (
		<div className="character_wrap pa30">
			<p>
				에너지를 절감하고
				<br />
				캐릭터를 수집해보세요
			</p>
			<img src={myCharacter} alt="캐릭터 이미지" />
			<button
				type="button"
				title="캐릭터 현활 및 설정"
				onClick={handleChangeCharacter}
			>
				캐릭터 현황 및 설정
			</button>
		</div>
	);
};

// 메뉴 이동
const Menu = ({ member }: { member: MemberInfo | undefined }) => {
	const navigate = useNavigate();
	const [myPoint, setMyPoint] = useState<MyPoint>();
	const { showDialog } = useDialog();
	const handleDialog = () => {
		// const title = '내 알람';
		// const message = '현재 준비중인 서비스입니다.';
		// showDialog(OneButtonAlertDialog, { title, message });
		navigate('/myPage_02/myAlarm_04');
	};

	const handleClick = () => {
		if (member?.roles === 'AMN') {
			const title = '내 마을변경';
			const message = '관리자는 마을 변경이 불가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
				handleDialogResult(result) {
					if (result === '확인') {
					}
				},
			});
		} else {
			navigate('/myPage_02/myVillageModify_04');
		}
	};
	// 현재 가용 포인트 조회
	useEffect(() => {
		MissionAPI.getMyPoint().then((res) => {
			setMyPoint(res?.data);
		});
	}, []);
	return (
		<div className="inform_wrap mt40">
			<p>
				안녕하세요! <span>{member?.nickname}</span>
				{member?.townDTO.townId != null && member?.townDTO.townId != 0 &&
					(member?.roles === 'AMN'
						? '(관리자)'
						: member?.roles === 'MST'
						? '(운영자)'
						: member?.roles === 'SMT'
						? '(부운영자)'
						: member?.roles === 'ABR'
						? '(활동가)'
						: member?.roles === 'MBR'
						? '(주민)'
						: member?.roles === 'STB'
						? '(회원)'
						: '')}
			</p>
			{member?.townDTO.townId == 0 ? (
				<p>
					<span>{member?.nickname}</span> 님의 마을이 없습니다!{' '}
					<span className="txt_blue">내 마을 변경</span> 메뉴에서 마을을 선택해
					주세요.
					{/* <span className="txt_blue">{member?.townDTO.townName}</span>입니다. */}
				</p>
			) : (
				<p>
					<span>{member?.nickname}</span> 님의 마을은{' '}
					<span className="txt_blue">{member?.townDTO.townName}</span> 입니다.
				</p>
			)}
			<div className="point_wrap mt25">
				<button type="button">
					<p className="big">
						현재 가용 POINT{' '}
						<span>
							{myPoint?.ablePt ? myPoint?.ablePt.toLocaleString() : 0} E
						</span>
					</p>
				</button>
			</div>
			<ul className="mt25 pa30">
				<li>
					<a href="/myPage_02/myInfoModify_04">내 정보 수정</a>
				</li>
				<li>
					<a onClick={handleClick}>내 마을 변경</a>
				</li>
				<li>
					<a href="/myPage_02/myProgram_04">내 교육프로그램</a>
				</li>
				<li>
					<a href="/mypage_02/myEPoint">내 E포인트</a>
				</li>
				<li>
					<a href="/mypage_02/myEnergy">내 에너지전환활동</a>
				</li>
				<li>
					<a
						// href="/login/myPage_02/myAlarm_04"
						onClick={handleDialog}
					>
						내 알람
					</a>
				</li>
			</ul>
		</div>
	);
};

// 에너지전환 활동 현황
const Energy = () => {
	const { showDialog } = useDialog();
	const handleDialog = () => {
		const title = '상세 내역보기';
		const message = '현재 준비중인 서비스입니다.';
		showDialog(OneButtonAlertDialog, { title, message });
	};
	return (
		<div className="energy_wrap mt40">
			<div className="energy_title">
				<h4>내 에너지전환 활동</h4>
				<button type="button" className="btn_mobile" onClick={handleDialog}>
					상세 내역보기
				</button>
			</div>
			<div className="energy_table mt25">
				<ul className="thead pa20">
					<li>전기</li>
					<li>도시가스</li>
					<li>탄소감축량</li>
					<li>총 에너지 절감량</li>
				</ul>
				<ul className="tbody">
					<li className="pa20">
						<p className="menu">140MWh</p>
						<p className="">360㎥</p>
						<p className="">1.5㎏</p>
						<p className="red">450TOE</p>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default Main_MyPage;
