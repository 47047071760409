import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import CommSearch from '../../../components/common/CommSearch';
import CommSelect from '../../../components/common/CommSelect';
import CommSort from '../../../components/common/CommSort';
import NavComponent from '../../../components/common/NavComponent';
import Pagination from '../../../components/common/Pagination';
import SubLocation from '../../../components/common/SubLocation';
import useMember from '../../../hooks/useMember';
import { content } from '../../../models/TownList';
import VillageCommuInfo, {
	villageCommuContent,
} from '../../../models/VillageCommuInfo';
import CommTab from '../CommTab';
import './List_VillageCommuPage.scss';
import noList from '../../../assets/noList.png';

// 소통마당 > 마을소통
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: "/reward_02/", text: "참여마당" },
	// { href: "/info_02/", text: "정보마당" },
];

const List_VillageCommuPage = () => {
	// 글 등록 권한
	const [writeRoles, setWriteRoles] = useState(false);
	//---------------------------------------------------------------------
	const { member } = useMember();
	const townId = sessionStorage.getItem('town');
	const [townList, setTownList] = useState([] as content[]);
	const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [townInfo, setTownInfo] = useState([] as content[]);
	const [page, setPage] = useState<number>(1);
	const [villageCommuList, setVillageCommuList] = useState([] as villageCommuContent[]);
	const [count, setCount] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	// 카테고리 검색
	const [category, setCategory] = useState<string>();
	// 키워드 검색
	const [keyword, setKeyword] = useState<string>();
	const [length, setLength] = useState(false);
	// 목롤 정렬 (최신순, 좋아요 순)
	const [sort, setSort] = useState<string>("createdAt,DESC");

	// 권한별 버튼
	// 	관리자/운영자/부운영자/활동가/주민/회원/익명
	//  'AMN' 'MST'  'SMT'  'ABR' 'MBR''STB''NON'
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// // console.log('권한 : 플랫폼관리자');
				setWriteRoles(true); // 글 등록
			} else if (
				member.roles === 'MST' ||
				member.roles === 'SMT' ||
				member.roles === 'ABR' ||
				member.roles === 'MBR'
			) {
				if (
					member &&
					member.townDTO &&
					member.townDTO.townId === Number(townId)
				) {
					// // console.log('권한 : 운영자, 부운영자, 활동가, 주민');
					setWriteRoles(true); // 글 등록
				}
			}
		}
	}, []);

	// 마을소통 글 목록 조회
	useEffect(() => {
		const listParams: {
			townId: number;
			page: number;
			size: number;
			category?: string;
			keyword?: string;
			sort?: string;
		} = {
			townId: Number(townId),
			page: Number(page) - 1,
			size: 8,
			sort: sort,
		};

		if (category) {
			listParams.category = category;
		}
		if (keyword) {
			listParams.keyword = keyword;
		}
		if (sort) {
			listParams.sort = sort;
		}

		VillageCommuAPI.getVillageCommuList(listParams).then((res) => {
			setVillageCommuList(res.content);
			setCount(res.totalElements);
			setTotalPage(res.totalPages);
			if (res.content && res.content.length == 0) {
				setLength(true);
			} else {
				setLength(false);
			}
		});
	}, [page, category, keyword, length, sort]);

	// // console.log('마을소통 글 목록', villageCommuList);

	// 검색창
	const handleSearchKeyword = (search: string | undefined) => {
		setPage(1);
		setKeyword(search);
		// setNullListMessage('검색된 게시글이 없습니다.');
		// // // console.log('searchKeyword !!!!!!!!!!!!! ', search);
	};

	// 목록 정렬(최신순, 좋아요순)
	const selectedSort = (sort: string) => {
		// // // console.log('sort-->', sort);
		setPage(1);
		setSort(sort);
	};

	return (
		<main className="List_VillageCommuPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<div>
					<h2 className="notext">컨텐츠 영역</h2>
					{/* 해당 페이지의 시작입니다 */}
					<div id="content">
						<CommTab clickTab={1} />
						<div className="village_commu">
							<div className="comm_search mt40">
								<ul>
									<li>
										<select
											className="comm_select01"
											onChange={(e) => setCategory(e.target.value)}
										>
											<option value={''}>카테고리</option>
											<option value={'ASK'}>마을질문</option>
											<option value={'DAILY'}>마을일상</option>
											<option value={'NEWS'}>마을뉴스</option>
										</select>
									</li>
									<CommSearch handleSearchKeyword={handleSearchKeyword} />
								</ul>
							</div>

							<div className="comm_tool mt25">
								{/* <CommSort selectedSort={selectedSort}/> */}
								<ul>
									<li>
										<p
											className={`${sort === 'createdAt,DESC' && 'on'}`}
											onClick={() => selectedSort('createdAt,DESC')}
											style={{ cursor: 'pointer' }}
										>
											최신순
										</p>
									</li>
									<li>
										<p
											className={`${sort === 'likeCnt,DESC' && 'on'}`}
											onClick={() => selectedSort('likeCnt,DESC')}
											style={{ cursor: 'pointer' }}
										>
											공감순
										</p>
									</li>
								</ul>
								<Write writeRoles={writeRoles} />
								{/* 등록 버튼 */}
							</div>
							{/* 목록 */}
							<List
								page={page}
								setPage={setPage}
								villageCommuList={villageCommuList}
								count={count}
								totalPage={totalPage}
								length={length}
							/>
						</div>
					</div>
					{/* 해당 페이지의 종료입니다 */}
				</div>
			</article>
		</main>
	);
};

// 등록하기
const Write = ({ writeRoles }: { writeRoles: boolean }) => {
	const navigate = useNavigate();

	const handleWrite = () => {
		// // console.log('등록하기 버튼 클릭');
		navigate(`/commu_02/villageCm_04-write`);
	};

	return (
		<>
			{writeRoles && (
				<button
					type="button"
					className="comm_write_button"
					onClick={handleWrite}
				>
					등록하기
				</button>
			)}
		</>
	);
};

// 리스트
const List = ({
	page,
	setPage,
	villageCommuList,
	count,
	totalPage,
	length,
}: {
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	villageCommuList: villageCommuContent[];
	count: number;
	totalPage: number;
	length: boolean;
}) => {
	const navigate = useNavigate();
	const handlePageChange = (page: number) => {
		setPage(page);
	};

	// // // console.log("현재 page", page)
	// // // console.log("리스트 카운트", count)
	// // // console.log("totalPage", totalPage)

	// 상세보기
	const handleDetail = (boardId: string, createdId: string) => {
		// // console.log('마을소통 상세보기 이동');
		sessionStorage.setItem('createdId', String(createdId));
		// sessionStorage.setItem('visible', String(visible));

		navigate(`/commu_02/villageCm_03-detail?boardId=${boardId}`);
		sessionStorage.setItem('boardId', String(boardId));
	};

	return (
		<>
			{length ? (
				<div className="comm_noList">
					<img src={noList} alt="내용없음 이미지" />
					<p className="mt25">
						작성된 게시글이 없습니다.
					</p>
				</div>
			) : (
				<div>
					<div className="village_commu_list mt25">
						<ul>
							{/* 비공개 게시물 non클래스 붙음 */}
							{villageCommuList && villageCommuList.map((list) => (
								<li key={list.boardId} className={list.visible === "N" ? "non" : ""}
									onClick={() => handleDetail(String(list.boardId), String(list.createdId))}>
									<a className="pa30">
										<div className="box_commu">
											{/* 카테고리 (마을뉴스) 일 경우 news class추가 */}
											<span className={`comm_village ${list.category === "NEWS" && "news"}`}>
												{list.category === "NEWS" ? "마을뉴스" : list.category === "ASK" ? "마을질문" : "마을일상"}</span>
											<h4 className="comm_title txt_cut2 mt25">
												{list.content}
											</h4>
											<div className="info01 mt10">
												{/* <p>{list.createdId}</p> */}
												<p className={
													list.character === 1
														? 'name basic1'
														: list.character === 2
															? 'name basic2'
															: list.character === 3
																? 'name Lv1'
																: list.character === 4
																	? 'name Lv2'
																	: list.character === 5
																		? 'name Lv3'
																		: list.character === 6
																			? 'name Lv4'
																			: ''
												}>
													{list.nickname}</p>
												<p>{`${moment(list.createdAt).format('yyyy.MM.DD')}`}</p>
											</div>
											<div className="info02 mt10">
												<p className="ic_comm_heart">{list.likeCnt}</p>
												<p className="ic_comm_comment">{list.commentCnt}</p>
											</div>
										</div>
									</a>
								</li>
							))}
						</ul>
					</div>
					<Pagination
						page={Number(page)}
						onPageChange={handlePageChange}
						// totalPageCount={Math.ceil(count / 8)}
						totalPageCount={Number(totalPage)}
					/>
				</div>
			)}

		</>

	);
};

export default List_VillageCommuPage;
