import axios from "axios";
import { serialize } from "object-to-formdata";
import ActivityLog, { ActivityLogList, Content } from "../models/ActivityLog";
import { likeParam } from "../models/VillageCommuInfo";
interface GetAllParams{
    page?: number;
    size?: number;
    keyword?: string;
    townId?: number;
    sort?: string;
    subjectId?: number;
    savedPath?: string;
}
interface ActivityLogParams{
    journalId?: number;
    journalCommentId?: number;
    content?: string;
    deletedId?: number;
    visible?: string;
    pBoardCommentId?: number;
    pjournalCommentId?:number;
}
// 활동일지 주제목록 조회
const getSubjectList = (params : GetAllParams) => {
    return axios
    .get(`/api/subject`, {params})
    .then((res) => res.data as ActivityLogList)
}
// 활동일지 주제상세 조회
const getSubjectDetail = (subjectId : number) => {
    return axios
    .get(`/api/subject/get?subjectId=${subjectId}`)
    .then((res) => res.data as Content)
}
// 활동일지 주제내역상세 조회
const getJournalDetail = (journalId : number) => {
    return axios
    .get(`/api/journal/get?journalId=${journalId}`)
    .then((res) => res.data as Content)
}
// 활동일지 주제내역 조회
const getJournalList = (params : GetAllParams) => {
    return axios
    .get('/api/journal',{params})
    .then((res) => res.data as ActivityLogList)
}
// 활동일지 주제등록
const insertSubject = (params : ActivityLog, uploadFiles : File[]) => {
    const formData = new FormData();
    // 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
	});
    // 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
    return axios
    .post('/api/subject/save',formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((res) => res as any);
}
// 활동일지 주제내역 등록
const insertJournal = (params : ActivityLog, uploadFiles : File[]) => {
    const formData = new FormData();
    // 데이터 추가
	Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
	});
    // 파일 추가
	const files = Array.from(uploadFiles);
	for (let i = 0; i < files.length; i++) {
		formData.append('uploadFiles', files[i], files[i].name);
	}
    return axios
    .post('/api/journal/save',formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
// 활동일지 주제 수정
const updateSubject = (params: ActivityLog, uploadFiles?: File[], deleteImgFileIds?: number[]) => {
    const formData = new FormData();
    //데이터 추가
    Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
    });
    // 파일 추가
   if (uploadFiles) {
		const files = Array.from(uploadFiles);
		for (let i = 0; i < files.length; i++) {
			formData.append('uploadFiles', files[i], files[i].name);
		}
	}
	if (deleteImgFileIds?.length !== 0) {
		if (deleteImgFileIds) {
			const imgDel = Array.from(deleteImgFileIds);
			for (let i = 0; i < imgDel.length; i++) {
				if (imgDel[i]) {
					formData.append(`subjectFileDTOList[${i}].subjectFileId`, String(imgDel[i]));
				}
			}
		}
	}
    return axios
    .post('/api/subject/update', formData, {
        headers: {
            'Content-Type' : 'multipart/form=data',
        },
    })
}
// 활동일지 주제내역 수정
const updateJournal = (params: ActivityLog, uploadFiles? : File[], deleteImgFileIds?: number[]) => {
    const formData = new FormData();
    //데이터 추가
    Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
    });
    // 파일 추가
   if (uploadFiles) {
    const files = Array.from(uploadFiles);
    for (let i = 0; i < files.length; i++) {
        formData.append('uploadFiles', files[i], files[i].name);
    }
    }
    if (deleteImgFileIds?.length !== 0) {
        if (deleteImgFileIds) {
            const imgDel = Array.from(deleteImgFileIds);
            for (let i = 0; i < imgDel.length; i++) {
                if (imgDel[i]) {
                formData.append(`journalFileDTOList[${i}].journalFileId`, String(imgDel[i]));
                }
            }
        }
    }
    return axios
    .post('/api/journal/update', formData, {
        headers: {
            'Content-Type' : 'multipart/form=data',
        },
    })
}
// 활동일지 주제 삭제
const deleteSubject = (params : GetAllParams) => {
    return axios
    .post('/api/subject/delete', serialize(params))
    .then((res) => res as any);
}
// 활동일지 주제내역 삭제
const deleteJournal = (journalId: ActivityLogParams) => {
    return axios
    .post('/api/journal/delete', serialize(journalId))
    .then((res) => res as any);
}
// 활동일지 주제내역 비공개
const privateJournal = (params: ActivityLogParams) => {
    return axios
    .post('/api/journal/update', serialize(params))
}
// 댓글 등록
const insertComment = (params : ActivityLogParams) => {
    return axios 
    .post('/api/journal/saveComment',serialize(params))
    .then((res) => res as any);
}
// 댓글 수정
const updateComment = (params : ActivityLogParams) => {
    return axios
    .post('/api/journal/updateComment', serialize(params))
    .then((res) => res as any)
}
// 좋아요
const likeJournal = (journalId: likeParam) => {
    return axios
    .post('/api/journal/like', serialize(journalId))
    .then((res) => res as any);
}
// 이미지 조회
const getImages = (params: GetAllParams) => {
    return axios
    .get(`/api/attach/getFile`, {
		responseType: 'blob',
		params: params,
	})
}
const ActivityLogAPI = {
    insertSubject,
    getSubjectList,
    insertJournal,
    updateJournal,
    getSubjectDetail,
    updateSubject,
    getJournalList,
    getJournalDetail,
    insertComment,
    updateComment,
    likeJournal,
    privateJournal,
    deleteJournal,
    getImages,
    deleteSubject,
}
export default ActivityLogAPI;