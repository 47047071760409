import React, { useState } from 'react';
import './List_PointGiftPage.scss';
import { useDialog } from '../../../dialog/DialogProvider';
import { useNavigate } from 'react-router';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import Pagination from '../../../components/common/Pagination';

const List_PointGiftPage = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const handleClick = () => {
		const title = '마을 상품권 교환';
		const message = '현재 준비중인 서비스입니다.';
		const action = '인증ok';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
			handleDialogResult(result) {
				if (result === '확인') {
				}
			},
		});
	};
	//
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);
	const [tab, setTab] = useState<number>(1);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	return (
		<main className="List_PointGiftPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>포인트 선물내역</h3>

					<p>내 E 포인트를 확인하고 사용해보세요</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/* 탭 시작 */}
					<div className="tabs2">
						<button
							type="button"
							className={tab === 1 ? 'tab2 tab_active' : 'tab2 non_active'}
							onClick={() => setTab(1)}
						>
							받은선물
						</button>
						<button
							type="button"
							className={tab === 2 ? 'tab2 tab_active' : 'tab2 non_active'}
							onClick={() => setTab(2)}
						>
							보낸선물
						</button>
					</div>
					{/* 탭 종료 */}
					{/* 사용가능 리스트 시작 */}
					{tab === 1 && (
						<div className="tab_content2 mt40" data-id={0}>
							<div className="present_wrap">
								<ul>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv3" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="pa30">
													친구가 보낸 5,000 E포인트 선물 도착!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv4" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="pa30">
													친구가 보낸 5,000 E포인트 선물 도착!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv2" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="pa30">
													친구가 보낸 5,000 E포인트 선물 도착!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="basic1" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="pa30">
													친구가 보낸 5,000 E포인트 선물 도착!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv1" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="pa30">
													친구가 보낸 5,000 E포인트 선물 도착!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<Pagination
								page={Number(page)}
								totalPageCount={Number(totalPage)}
								// totalPageCount={Math.ceil(count / 8)}
								onPageChange={handlePageChange}
							/>
						</div>
					)}

					{/* 사용가능 리스트 종료 */}
					{/* 사용완료 리스트 시작 */}
					{tab === 2 && (
						<div className="tab_content2 mt40" data-id={1}>
							<div className="present_wrap">
								<ul>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv3" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="gray pa30">
													친구에게 5,000 E포인트 선물 완료!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv4" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="gray pa30">
													친구에게 5,000 E포인트 선물 완료!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv2" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="gray pa30">
													친구에게 5,000 E포인트 선물 완료!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="basic1" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="gray pa30">
													친구에게 5,000 E포인트 선물 완료!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
									<li>
										<div className="present_inner">
											<div className="box_info">
												<div className="info_img">
													<span className="Lv1" />
												</div>
												<div className="info_txt">
													<h5>도토리</h5>
													<p>2023.12.01</p>
												</div>
											</div>
											<div className="box_card mt10">
												<h3 className="gray pa30">
													친구에게 5,000 E포인트 선물 완료!
												</h3>
												<p className="pa30">함께 에너지전환해요</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<Pagination
								page={Number(page)}
								totalPageCount={Number(totalPage)}
								// totalPageCount={Math.ceil(count / 8)}
								onPageChange={handlePageChange}
							/>
						</div>
					)}

					{/* 사용완료 리스트 종료 */}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default List_PointGiftPage;
