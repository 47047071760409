import React, { useEffect, useState } from 'react';
import '../../../styles/commonWrite.css';
import { SubmitHandler, useForm } from 'react-hook-form';
import ic_cam from '../../../assets/ic_cam.png';
import { useDialog } from '../../../dialog/DialogProvider';
import SubmitSuccessDialog from '../../../dialog/common/SubmitSuccessDialog';
import SubmitFailDialog from '../../../dialog/common/SubmitFailDialog';
import { useNavigate } from 'react-router-dom';
import IntroInfo from '../../../models/IntroInfo';
import IntroAPI from '../../../apis/IntroAPI';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';

const Write01_VillageIntroPage = () => {
	const townId = sessionStorage.getItem('town');
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<IntroInfo>();
	// 동록 버튼 활성화
	const [buttonOn, setButtonOn] = useState(false);
	// const townId = watch('townId');
	const projectName = watch('projectName');
	const projectPurpose = watch('projectPurpose');
	const projectRegion = watch('projectRegion');
	const networkPartner = watch('networkPartner');
	// const networkFile = watch('networkFile');
	// 필수값 미입력 에러 메시지
	const [errorName, setErrorName] = useState('');
	const [errorPurpose, setErrorPurpose] = useState('');
	const [errorRegion, setErrorRegion] = useState('');
	const [errorPartner, setErrorPartner] = useState('');
	// 더블클릭 등록 방지
	const [noDoubleClick, setNoDoubleClick] = useState(false);

	useEffect(() => {
		if (projectName && projectPurpose && projectRegion && networkPartner) {
			setButtonOn(true);
		} else {
			setButtonOn(false);
		}
	}, [projectName, projectPurpose, projectRegion, networkPartner]);
	// 첨부파일
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [networkFile, setNetworkFile] = useState([] as File[]);
	// 이미지 파일 첨부
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log('files ::::::: ', event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 1를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 1) {
			// alert('사진은 최대 5개 까지 업로드할수있습니다.');
			return;
		}
		setNetworkFile((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log('imagesToSubmit ::::::::::::::::: ', uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 5);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setNetworkFile((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	// 등록
	const onSubmit: SubmitHandler<IntroInfo> = async (data) => {
		if (!data.projectName) {
			setErrorName('사업명을 입력해주세요.');
			return;
		}
		if (!data.projectPurpose) {
			setErrorPurpose('사업목적을 입력해주세요.');

			return;
		}
		if (!data.projectRegion) {
			setErrorRegion('사업지역을 입력해주세요');
			return;
		}
		if (!data.networkPartner) {
			setErrorPartner('네트워크 참여연대를 입력해주세요.');
			return;
		}
		try {
			await IntroAPI.insertNetwork(
				{
					...data,
					townId: Number(townId),
					projectName: data.projectName,
					projectPurpose: data.projectPurpose,
					projectRegion: data.projectRegion,
					networkPartner: data.networkPartner,
				},
				networkFile
			)
				.then((res) => {
					// console.log('insertNetwork res :::', res);
					if (res.status === 200) {
						const tab = 'network';
						showDialog(SubmitSuccessDialog, { tab });
						setNoDoubleClick(false);
					} else {
						setNoDoubleClick(false);
						showDialog(SubmitFailDialog);
					}
				})
				.catch((e) => {
					const title = '사진 첨부';
					const message = '사진은 한 장에 최대 10MB 등록이 가능합니다.';
					showDialog(OneButtonAlertDialog, { title, message });
				});
		} catch { }
	};

	const handleCancle = () => {
		navigate(-1);
	};
	return (
		<main className="">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>마을소개 등록하기</h3>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_write_wrap">
							<div className="cont-wrap">
								<h3 className="important">사업명</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="사업명을 입력하세요"
										{...register('projectName')}
									/>
								</div>
								{errorName && (
									<p className="error" style={{ color: 'red' }}>
										{errorName}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3 className="important">사업목적</h3>
								<div className="cont-box">
									<textarea
										placeholder="사업목적을 입력하세요"
										{...register('projectPurpose')}
									></textarea>
								</div>
								{errorPurpose && (
									<p className="error" style={{ color: 'red' }}>
										{errorPurpose}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3 className="important">사업지역</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="사업지역을 입력하세요"
										{...register('projectRegion')}
									/>
								</div>
								{errorRegion && (
									<p className="error" style={{ color: 'red' }}>
										{errorRegion}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3 className="important">네트워크 참여연대</h3>
								<div className="cont-box">
									<input
										type="text"
										className="long"
										placeholder="참여연대를 입력하세요"
										{...register('networkPartner')}
									/>
								</div>
								{errorPartner && (
									<p className="error" style={{ color: 'red' }}>
										{errorPartner}
									</p>
								)}
							</div>
							<div className="cont-wrap">
								<h3>대표 이미지</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={ic_cam} alt="사진 첨부" />
											사진 첨부
											<span>(1장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages &&
												previewImages.map((imageUrl, index) => (
													<li key={index}>
														<img src={imageUrl} key={imageUrl} />
														<button
															type="button"
															onClick={() => handleRemovePreview(index)}
															className="btn-file-delet"
														/>
													</li>
												))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_btn_wrap">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleCancle}
							>
								취소
							</button>
							<button
								type="submit"
								// className="btn_next on"
								className={`btn_next ${buttonOn ? 'on' : ''}`}
							>
								등록
							</button>
							{/* <!-- 모두 체크 시 on클래스 붙음--> */}
						</div>
					</form>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default Write01_VillageIntroPage;
