import axios from 'axios';
import React, { useEffect, useState } from 'react';

const EnergyMapTest = () => {
	// 위치 데이터를 저장할 상태 정의
	const [locationData, setLocationData] = useState<null | any[]>(null);

	const data = [
		{ dong: '소태동', address: '광주광역시 동구 남문로 418-15' },
		{ dong: '소태동', address: '광주광역시 동구 소태길 121' },
		{ dong: '소태동', address: '광주광역시 동구 소태길 77' },
		{ dong: '소태동', address: '광주광역시 동구 소태길 22-11' },
		{ dong: '소태동', address: '광주광역시 동구 소태동 470-1' },
		{ dong: '소태동', address: '광주광역시 동구 남문로 628' },
		{ dong: '소태동', address: '광주광역시 동구 남문로 643' },
		{ dong: '소태동', address: '광주광역시 동구 학소로 106번길 30' },
		{ dong: '소태동', address: '광주광역시 동구 의재로 96번길 1' },
		{ dong: '소태동', address: '광주광역시 동구 의재로 106-1' },
		{ dong: '소태동', address: '광주광역시 동구 의재로 88번길 11-12' },
		// 더 많은 데이터 항목을 여기에 추가하세요
	];

	const getLocation = async (address: string) => {
		// console.log('dddddddddd', address);

		try {
			const response = await axios.get(
				`https://dapi.kakao.com/v2/local/search/address.json?query=${address}`,
				{
					headers: {
						Authorization: '0ef7429ada9464f8b1b3db7fb8cdbf26',
						// KA: 'os/your_os_version origin/your_origin',
					},
				}
			);

			// console.log('ffffffff', response.data);
			return response.data;
		} catch (error) {
			console.error(
				`주소 ${address}의 위치 정보를 가져오는 중 오류 발생: ${error}`
			);
			return {};
		}
	};

	// 컴포넌트가 마운트될 때 모든 주소의 위치 데이터를 가져오도록 useEffect를 사용합니다.
	useEffect(() => {
		const fetchData = async () => {
			const locationDataArray = await Promise.all(
				data.map((list) => getLocation(list.address))
			);
			if (locationDataArray) {
				setLocationData(locationDataArray);
			}
		};

		fetchData();
	}, []); // 빈 의존성 배열은 이 효과가 한 번만 실행되도록 합니다

	return (
		<main>
			{' '}
			<div>
				<h1>처리된 데이터:</h1>
				<ul>
					{locationData &&
						locationData.map((list, index) => (
							<li key={index}>
								{list?.documents && list.documents[0] && (
									<span>
										Address: {list.documents[0]?.address_name}, x:{' '}
										{list.documents[0]?.x}, y: {list.documents[0]?.y}
									</span>
								)}
							</li>
						))}
				</ul>
			</div>{' '}
		</main>
	);
};

export default EnergyMapTest;
