import React from 'react';
import { DialogProps } from '../DialogProvider';

interface CmtDeleteDialog extends DialogProps {
	type?: '';
	onComplete: (result: string) => void;
}
const CmtDeleteDialog = ({ ...props }: CmtDeleteDialog) => {
	const handleClose = () => {
		props.onComplete('no');
		// console.log(result);
		props.onClose?.();
	};
	const handleConfirm = () => {
		props.onComplete('yes');
		props.onClose?.();
	};
	return (
		<div className="comm_popup" style={{ display: 'block' }}>
			<div className="comm_popup_wrap pa30">
				<div className="comm_popup_title">
					<h3>댓글 삭제</h3>
				</div>
				<div className="comm_popup_content mt10">
					<p className="explain">댓글을 삭제하시겠습니까?</p>
				</div>
				<div className="comm_popup_btn mt25">
					<button type="button" className="btn_cancel" onClick={handleClose}>
						취소
					</button>
					<button type="submit" className="btn_check" onClick={handleConfirm}>
						확인
					</button>
				</div>
			</div>
		</div>
	);
};

export default CmtDeleteDialog;
