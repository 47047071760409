import React, { useEffect, useState } from 'react';
import SubLocation from '../../../components/common/SubLocation';
import './List01_ActiveMapPage.scss';
import CommSelect from '../../../components/common/CommSelect';
import CommTab from '../CommTab';
import ic_next from '../../../assets/ic_next.png';
import ic_next2 from '../../../assets/ic_next2.png';
import ic_prev from '../../../assets/ic_prev.png';
import ic_prev2 from '../../../assets/ic_prev2.png';
import { useNavigate } from 'react-router';
import Pagination from '../../../components/common/Pagination';
import moment from 'moment';
import ActiveMapAPI from '../../../apis/ActiveMapAPI';
import MapInfo, { AllcmMasterList } from '../../../models/MapInfo';
import { content } from '../../../models/TownList';
import VillageCommuAPI from '../../../apis/VillageCommuAPI';
import NavComponent from '../../../components/common/NavComponent';
import useMember from '../../../hooks/useMember';
import CommSearch from '../../../components/common/CommSearch';
import errorImg from '../../../assets/errorImg.jpg';
import noList from '../../../assets/noList.png';
// 소통마당 > 마을소통
const SubLocationList = [
	{ href: '/commu_02/', text: '소통마당' },
	// { href: '/reward_02/', text: '참여마당' },
	// { href: '/info_02/', text: '정보마당' },
];

const List01_ActiveMapPage = () => {
	// const member = sessionStorage.getItem('member');
	const { member } = useMember();
	const townId = sessionStorage.getItem('town');
	const navigate = useNavigate();

	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	// const [activeMapList, setActiveMapList] = useState([] as MapInfo[]);
	const [allcmMasterList, setAllcmMasterList] = useState(
		[] as AllcmMasterList[]
	);
	const [townList, setTownList] = useState([] as content[]);
	// const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [selectedTown, setSelectedTown] = useState<number | undefined>();
	const [townInfo, setTownInfo] = useState([] as content[]);
	// 지도 주제 작성 권한
	const [postRoles, setPostRoles] = useState(false);
	// 글, 댓글 비공개 권한
	const [praviteRoles, setPraviteRoles] = useState(false);
	// 검색어
	const [search, setSearch] = useState<string>('');
	// const [search, setSearch] = useState<string>();
	// noList
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setPostRoles(true);

				// setNewsCateRoles(true);
			} else if (member.roles === 'MST' || member.roles === 'SMT') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO?.townId === Number(townId)) {
					// console.log('권한 : 마을운영자, 마을부운영자');
					setPostRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log('권한 : 마을활동가, 마을주민');
				setPostRoles(false);
				// if(member.townId === townId && member.userId === crtUid){
				// setPostRoles(true);
				// }
			}
		}
		// console.log('postRoles', postRoles);
	}, [townId]);
	// 마을 목록 조회
	useEffect(() => {
		// // console.log('aa');
		setSelectedTown(Number(townId));
		VillageCommuAPI.getTownList().then((res) => {
			setTownList(res.content);
			if (res.content) {
				setTownInfo(
					res.content.filter((list) => list.townId === Number(townId))
				);
				res.content.map((list, index) => {
					list.townTitle = list.districtDTOList
						.map((list2, index2) => list2.districtName)
						.join(', ');
				});
			}
		});
	}, [townId, selectedTown]);

	// useEffect(() => {
	// 	if (selectedTown) {
	// 		// console.log('bb');
	// 		VillageCommuAPI.getTownList().then((res) => {
	// 			setTownList(res.content);
	// 			setTownInfo(res.content.filter((list) => list.townId === selectedTown));
	// 		});
	// 	}
	// 	// VillageCommuAPI.getTownOne({
	// 	// 	townId: Number(townId),
	// 	// }).then((res) => {
	// 	// 	// setTownInfo(res.content);
	// 	// });
	// }, [selectedTown]);

	// // console.log('townList-->', townList);

	// 선택한 마을 townId
	const changeTownList = (townId: number) => {
		setSelectedTown(townId);
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// 마을 활동지도 목록조회
	useEffect(() => {
		ActiveMapAPI.getAllMapList({
			tcUid: Number(townId),
			crtUid: Number(member?.id) || undefined,
			pageSize: 8,
			page: page,
			search: search === '' ? undefined : search,
		})
			.then((res) => {
				// console.log(res);
				setAllcmMasterList(res.allcmMasterList);
				setCount(Number(res.allcmMasterList[0].totalCount));
			})
			.catch((e) => { });
	}, [townId, search, page]);
	// 등록하기
	const handleWrite = () => {
		navigate('/commu_02/actMap_04-write');
	};
	// 상세 페이지 이동
	const handleDetail = (cmmUid: string, crtUid: number) => {
		navigate('/commu_02/actMap_03-detail');
		sessionStorage.setItem('cmmUid', String(cmmUid));
		sessionStorage.setItem('crtUid', String(crtUid));
	};
	// 검색어를 입력하세요

	const handleSearchKeyword = (search: string) => {
		setPage(1);
		setSearch(search);
		setNullListMessage('검색된 게시글이 없습니다.');
		// console.log('searchKeyword !!!!!!!!!!!!! ', search);
	};

	const getSearchKeyword = (e: any) => {
		if (handleSearchKeyword) {
			handleSearchKeyword(search);
		}
		if (e.key === 'Enter') {
			if (handleSearchKeyword) {
				handleSearchKeyword(search);
			}
		}
	};
	// 이미지 불러오는 중 Error 시 div className 에 noimg 추가
	const onImageError = (e: any) => {
		e.target.src = errorImg;
	};
	return (
		<main className="List01_ActiveMapPage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={2} />
					{/* <div className="comm_tool mt40">
						<button
							type="button"
							className="comm_write_button"
							onClick={handleWrite}
						>
							등록하기
						</button>
					</div> */}
					{ }

					<div className="village_mapList">
						<div className="comm_search mt40">
							<ul>
								{/* <li>
									<div className="comm_search_plus">
										<input
											type="text"
											placeholder="검색어를 입력해주세요."
											onChange={(e) => setSearch(e.target.value)}
											onKeyPress={getSearchKeyword}
										/>
									</div>
									<button type="button" onClick={getSearchKeyword}>
										검색
									</button>
								</li> */}
								<CommSearch handleSearchKeyword={handleSearchKeyword} />
							</ul>
						</div>
						{postRoles ? (
							<>
								<div className="comm_tool mt25">
									<button
										type="button"
										className="comm_write_button"
										onClick={handleWrite}
									>
										주제등록
									</button>
								</div>
							</>
						) : (
							<> </>
						)}

						{allcmMasterList && allcmMasterList.length === 0 ? (
							<>
								<div className="comm_noList">
									<img src={noList} alt="내용없음 이미지" />
									<p className="mt25">{nullListMessage}</p>
								</div>
							</>
						) : (
							<>
								<div className="village_mapList_list mt25">
									<ul>
										{allcmMasterList &&
											allcmMasterList.map((list, index) => (
												<li
													className={list?.openTp === 'N' ? 'non' : ''}
													key={index}
												>
													{' '}
													{/* li 비공개 게시물 non 클래스 붙음 */}
													<a
														className="pa30"
														onClick={() =>
															handleDetail(
																String(list.cmmUid),
																Number(list.crtUid)
															)
														}
													>
														<div
															// className="box_mapList"
															className={
																list.savedPath
																	? 'box_mapList'
																	: 'box_mapList noimg'
															}
														>
															{/* div 이미지 없으면 noimg 클래스 붙음 */}
															<div className="mapList_txt">
																<h4 className="comm_title txt_cut1">
																	{list.title}
																</h4>
																<p className="txt_cut1">
																	{' '}
																	{list?.dtlTxt &&
																		list?.dtlTxt
																			.split('\n')
																			.map((item, index) => (
																				<React.Fragment key={index}>
																					{item}
																					<br />
																				</React.Fragment>
																			))}
																</p>
																{/* 진행중 ing, 종료 end 클래스 붙음 */}
																<p
																	// className="period mt10"
																	className={
																		list.prgrsSt === '종료'
																			? 'period mt10 end'
																			: list.prgrsSt === '진행'
																				? 'period mt10 ing'
																				: list.prgrsSt === '대기'
																					? 'period mt10 prev'
																					: 'period mt10'
																	}
																>
																	{`${moment(list.stDt).format(
																		'yyyy.MM.DD'
																	)} ~ ${moment(list.edDt).format(
																		'yyyy.MM.DD'
																	)}`}
																</p>
															</div>
															<div className="mapList_img">
																<img
																	src={
																		list.savedPath &&
																		`/api/community/getCmFiles?cmmUid=${list.cmmUid}`
																	}
																	onError={onImageError}
																// alt="지도 이미지"
																/>
															</div>
														</div>
													</a>
												</li>
											))}
									</ul>
								</div>
							</>
						)}

						<Pagination
							page={page}
							// totalPageCount={10}
							totalPageCount={Math.ceil(count / 8)}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
				{/* <!-- 해당 페이지의 종료입니다 --> */}
			</article>
		</main>
	);
};

export default List01_ActiveMapPage;
