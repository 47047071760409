import React, { useEffect, useState } from 'react';
import NavComponent from '../../../components/common/NavComponent';
import Pagination from '../../../components/common/Pagination';
import { useDialog } from '../../../dialog/DialogProvider';
import TwoButtonAlertDialog from '../../../dialog/TwoButtonAlertDialog';
import useMember from '../../../hooks/useMember';
import CommTab from '../CommTab';
import './List_VillageManagePage.scss';
import VillageMngAPI from '../../../apis/VillageMngAPI';
import VillageMngInfo from '../../../models/VillageMngInfo';
import noList from '../../../assets/noList.png';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';

// const villageManageList = [
// 	{ roles: 'MST', roles2: '운영자', nickname: '나운영', crtDt: '2023.11.07' },
// 	{ roles: 'SMT', roles2: '부운영자', nickname: '한분영', crtDt: '2023.11.07' },
// 	{ roles: 'SMT', roles2: '부운영자', nickname: '김분영', crtDt: '2023.11.07' },
// 	{ roles: 'SMT', roles2: '부운영자', nickname: '강분영', crtDt: '2023.11.07' },
// 	{ roles: 'ABR', roles2: '마을활동가', nickname: '강한동', crtDt: '2023.11.07' },
// 	{ roles: 'ABR', roles2: '마을활동가', nickname: '박한동', crtDt: '2023.11.07' },
// 	{ roles: 'ABR', roles2: '마을활동가', nickname: '임한동', crtDt: '2023.11.07' },
// 	{ roles: 'ABR', roles2: '마을활동가', nickname: '이한동', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '양주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '강주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '김주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '박주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '김주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '나주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '한주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '방주민', crtDt: '2023.11.07' },
// 	{ roles: 'MBR', roles2: '마을주민', nickname: '주주민', crtDt: '2023.11.07' },
// ]

const List_VillageManagePage = () => {
	const tcUid = sessionStorage.getItem('town');
	const { member } = useMember();
	const { showDialog } = useDialog();
	const [page, setPage] = useState<number>(1);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [generalRoles, setGeneralRoles] = useState(false);
	const [managerRoles, setManagerRoles] = useState(false);
	const [subManagerRoles, setSubManagerRoles] = useState(false);
	const [smtCount, setSmtCount] = useState<number>(0);
	const [abrCount, setAbrCount] = useState<number>(0);
	const [mbrCount, setMbrCount] = useState<number>(0);
	const [dialogResult, setDialogResult] = useState(false);
	const [villageManageList, setVillageManageList] = useState<VillageMngInfo>();
	// 권한 검사
	useEffect(() => {
		if (member) {
			if (member.roles === 'AMN') {
				// console.log('권한 : 플랫폼관리자');
				setGeneralRoles(true);
				// setNewsCateRoles(true);
			} else if (member.roles === 'MST') {
				// townId 와 member.townId 가 같고 글 id 와 member.id 가 같으면
				if (member.townDTO.townId === Number(tcUid)) {
					// console.log('권한 : 마을운영자');
					setManagerRoles(true);
					// setNewsCateRoles(true);
				}
			} else if (member.roles === 'SMT') {
				// console.log('권한 : 마을부운영자');
				setSubManagerRoles(true);
			} else if (member.roles === 'ABR' || member.roles === 'MBR') {
				// console.log('권한 : 마을활동가, 마을주민');
				// if(member.townId === townId && member.userId === crtUid){
				// setPostRoles(true);
				// }
			}
		}
	}, []);

	// 주민관리 목록 불러오는 API
	useEffect(() => {
		VillageMngAPI.getMngList({
			tcUid: Number(tcUid),
			page: page - 1,
			size: 10,
		}).then((res) => {
			// console.log('getMngList', res);
			setVillageManageList(res);
			setTotalCount(Number(res.totalElements));

			if (res) {
				setSmtCount(
					res.content.filter((filter) => filter.roles === 'SMT').length
				);
				setAbrCount(
					res.content.filter((filter) => filter.roles === 'ABR').length
				);
				setMbrCount(
					res.content.filter((filter) => filter.roles === 'MBR').length
				);
			}
		});
	}, [page]);

	// 관리 버튼
	// appointedMST 운영자 지정, asgnmMST 운영자 양도 clearMST 운영자 해제
	// appointedSMT 부운영자 지정 clearSMT 부운영자 해제
	// appointedABR 활동가 지정 clearABR 활동가 해제
	const [currentRoles, setCurrentRoles] = useState<string>('');
	// const [asgnm, setAsgnm] = useState<boolean>(false); // 양도여부
	const handleManagementButton = (
		message: string,
		action: string,
		nickname: string
	) => {
		showDialog(TwoButtonAlertDialog, {
			title: '',
			message: message,
			action: 'ok',
			handleDialogResult(result) {
				if (result === '확인') {
					if (action === 'asgnmMST') {
						// console.log('운영자 양도');
						// setAsgnm(true);
						VillageMngAPI.updateRoles({
							nickname: String(nickname),
							g: true,
							roles: 'MST',
						}).then((res) => {
							// console.log('updateRoles', res);
							const title = '주민 관리';
							const message = '권한을 변경하였습니다.';
							showDialog(OneButtonAlertDialog, { title, message });
							window.location.reload();
							// setVillageManageList(res);
						});
					} else if (action === 'appointedMST') {
						// console.log('운영자 지정');
						VillageMngAPI.updateRoles({
							nickname: String(nickname),
							g: false,
							roles: 'MST',
						}).then((res) => {
							// console.log('updateRoles', res);
							const title = '주민 관리';
							const message = '권한을 변경하였습니다.';
							showDialog(OneButtonAlertDialog, { title, message });
							window.location.reload();
							// setVillageManageList(res);
						});
					} else if (action === 'clearABR') {
						// console.log('활동가 해제');
						VillageMngAPI.updateRoles({
							nickname: String(nickname),
							g: false,
							roles: 'MBR',
						}).then((res) => {
							// console.log('updateRoles', res);
							const title = '주민 관리';
							const message = '권한을 변경하였습니다.';
							showDialog(OneButtonAlertDialog, { title, message });
							window.location.reload();
							// setVillageManageList(res);
						});
					} else if (action === 'appointedABR' || action === 'clearSMT') {
						// console.log('활동가 지정 또는 부운영자 해제');
						VillageMngAPI.updateRoles({
							nickname: String(nickname),
							g: false,
							roles: 'ABR',
						}).then((res) => {
							// console.log('updateRoles', res);
							const title = '주민 관리';
							const message = '권한을 변경하였습니다.';
							showDialog(OneButtonAlertDialog, { title, message });
							window.location.reload();
							// setVillageManageList(res);
						});
					} else if (action === 'appointedSMT' || action === 'clearMST') {
						// console.log('부운영자 지정 또는 운영자 해제');
						VillageMngAPI.updateRoles({
							nickname: String(nickname),
							g: false,
							roles: 'SMT',
						}).then((res) => {
							// console.log('updateRoles', res);
							const title = '주민 관리';
							const message = '권한을 변경하였습니다.';
							showDialog(OneButtonAlertDialog, { title, message });
							window.location.reload();
							// setVillageManageList(res);
						});
					}
				}
			},
		});
	};

	// 다이얼로그에서 확인버튼 눌렀는지 받아오는 함수
	const handleDialogResult = () => {
		// console.log('다이얼로그 확인');
		setDialogResult((cur) => !cur);
	};
	const handlePageChange = (page: number) => {
		setPage(page);
	};
	// noList
	const [nullListMessage, setNullListMessage] =
		useState<string>('해당 마을의 주민이 없습니다.');
	return (
		<main className="List_VillageManagePage">
			<h1 className="notext">전체 영역</h1>
			<NavComponent />
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* <!-- 해당 페이지의 시작입니다 --> */}
				<div id="content">
					<CommTab clickTab={6} />
					{villageManageList ? (
						<>
							<div className="village_manage mt40">
								<div className="manage_number mb25">
									<p>
										부운영자 <span>{smtCount}명</span>, 마을활동가{' '}
										<span>{abrCount}명</span>, 마을주민{' '}
										<span>{mbrCount}명</span>
									</p>
								</div>
								<div className="cont_content manage_table">
									<ul className="thead pa20">
										<li>권한</li>
										<li>닉네임</li>
										<li>가입날짜</li>
										<li>관리</li>
									</ul>
									<ul className="tbody">
										{villageManageList?.content &&
											villageManageList?.content.map((list, index) => (
												<li className="pa20" key={index}>
													<p className="power">
														{list.roles === 'MST'
															? '운영자'
															: list.roles === 'SMT'
																? '부운영자'
																: list.roles === 'ABR'
																	? '마을 활동가'
																	: list.roles === 'MBR'
																		? '마을 주민'
																		: list.roles === 'AMN'
																			? '총괄 관리자'
																			: ''}
													</p>
													<p className="comm_title">{list.nickname}</p>
													<p>{list.crtDt}</p>
													<p>
														{generalRoles && (
															<>
																{list.roles === 'MST' && (
																	<button
																		type="button"
																		onClick={() =>
																			handleManagementButton(
																				'운영자 권한을 해제하시겠습니까?',
																				'clearMST',
																				String(list?.nickname)
																			)
																		}
																	>
																		운영자 해제
																	</button>
																)}
																{list.roles === 'SMT' && (
																	<>
																		<button
																			type="button"
																			className="btn_blue"
																			onClick={() =>
																				handleManagementButton(
																					'운영자 권한을 지정하시겠습니까?',
																					'appointedMST',
																					String(list?.nickname)
																				)
																			}
																		>
																			운영자 지정
																		</button>
																		<button
																			type="button"
																			onClick={() =>
																				handleManagementButton(
																					'부운영자 권한을 해제하시겠습니까?',
																					'clearSMT',
																					String(list?.nickname)
																				)
																			}
																		>
																			부운영자 해제
																		</button>
																	</>
																)}
																{list.roles === 'ABR' && (
																	<>
																		<button
																			type="button"
																			className="btn_blue"
																			onClick={() =>
																				handleManagementButton(
																					'부운영자 권한을 지정하시겠습니까?',
																					'appointedSMT',
																					String(list?.nickname)
																				)
																			}
																		>
																			부운영자 지정
																		</button>
																		<button
																			type="button"
																			onClick={() =>
																				handleManagementButton(
																					'활동가 권한을 해제하시겠습니까?',
																					'clearABR',
																					String(list?.nickname)
																				)
																			}
																		>
																			활동가 해제
																		</button>
																	</>
																)}
																{list.roles === 'MBR' && (
																	<button
																		type="button"
																		className="btn_blue"
																		onClick={() =>
																			handleManagementButton(
																				'활동가 권한을 지정하시겠습니까?',
																				'appointedABR',
																				String(list?.nickname)
																			)
																		}
																	>
																		활동가 지정
																	</button>
																)}
															</>
														)}
														{managerRoles && (
															<>
																{list.roles === 'SMT' && (
																	<>
																		<button
																			type="button"
																			className="btn_blue"
																			onClick={() =>
																				handleManagementButton(
																					'운영자 권한을 양도하시겠습니까?',
																					'asgnmMST',
																					String(list?.nickname)
																				)
																			}
																		>
																			운영자 양도
																		</button>
																		<button
																			type="button"
																			onClick={() =>
																				handleManagementButton(
																					'부운영자 권한을 해제하시겠습니까?',
																					'clearSMT',
																					String(list?.nickname)
																				)
																			}
																		>
																			부운영자 해제
																		</button>
																	</>
																)}
																{list.roles === 'ABR' && (
																	<>
																		<button
																			type="button"
																			className="btn_blue"
																			onClick={() =>
																				handleManagementButton(
																					'부운영자 권한을 지정하시겠습니까?',
																					'appointedSMT',
																					String(list?.nickname)
																				)
																			}
																		>
																			부운영자 지정
																		</button>
																		<button
																			type="button"
																			onClick={() =>
																				handleManagementButton(
																					'활동가 권한을 해제하시겠습니까?',
																					'clearABR',
																					String(list?.nickname)
																				)
																			}
																		>
																			활동가 해제
																		</button>
																	</>
																)}
																{list.roles === 'MBR' && (
																	<button
																		type="button"
																		className="btn_blue"
																		onClick={() =>
																			handleManagementButton(
																				'활동가 권한을 지정하시겠습니까?',
																				'appointedABR',
																				String(list?.nickname)
																			)
																		}
																	>
																		활동가 지정
																	</button>
																)}
															</>
														)}
														{subManagerRoles && (
															<>
																{list.roles === 'ABR' && (
																	<button
																		type="button"
																		onClick={() =>
																			handleManagementButton(
																				'활동가 권한을 해제하시겠습니까?',
																				'clearABR',
																				String(list?.nickname)
																			)
																		}
																	>
																		활동가 해제
																	</button>
																)}
																{list.roles === 'MBR' && (
																	<button
																		type="button"
																		className="btn_blue"
																		onClick={() =>
																			handleManagementButton(
																				'활동가 권한을 지정하시겠습니까?',
																				'appointedABR',
																				String(list?.nickname)
																			)
																		}
																	>
																		활동가 지정
																	</button>
																)}
															</>
														)}
													</p>
												</li>
											))}
									</ul>
									<Pagination
										page={Number(page)}
										// totalPageCount={10}
										totalPageCount={Math.ceil(totalCount / 10)}
										onPageChange={handlePageChange}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="comm_noList">
								<img src={noList} alt="내용없음 이미지" />
								<p className="mt25">{nullListMessage}</p>
							</div>
						</>
					)}
				</div>
			</article>
		</main>
	);
};

export default List_VillageManagePage;
