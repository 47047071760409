import axios from "axios";
import { serialize } from "object-to-formdata";
import { TodayList } from "../models/Utilization";

interface GetAllParams{
    id: number;
    mainItem: string;
}
const todayMenuCount = (params:GetAllParams)=>{
    return axios
    .post('/api/utili/save', serialize(params))
    .then((res) => res as any)
}

const getTodayList = (startDt:string) => {
    return axios 
    .get(`/api/utili/stat?startDt=${startDt}`)
    .then((res) => res.data as TodayList[])
}
const UtilizationAPI = {
    todayMenuCount,
    getTodayList
}
export default UtilizationAPI;