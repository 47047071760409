import React, { useEffect, useState } from 'react';
import '../missionYard/Detail_MapMissionPage.scss';
import SubLocation from '../../components/common/SubLocation';
import ic_mapinfo from '../../assets/ic_mapinfo.png';
import ic_map_setting from '../../assets/ic_map_setting.png';
import Pagination from '../../components/common/Pagination';
import { useDialog } from '../../dialog/DialogProvider';
import MapInfoDialog from '../../dialog/MissionYard/MapInfoDialog';
import { useNavigate } from 'react-router-dom';
import OneButtonAlertDialog from '../../dialog/OneButtonAlertDialog';
import ModifyDialog from '../../dialog/common/ModifyDialog';
import Write_MapMissionDialog from '../../dialog/MissionYard/Write_MapMissionDialog';
import useMember from '../../hooks/useMember';
import MemberInfo from '../../models/MemberInfo';
import TwoButtonAlertDialog from '../../dialog/TwoButtonAlertDialog';
import MapMissionComponent from '../../components/common/MapMissionComponent';
import LoginDialog from '../../dialog/Login/LoginDialog';
import MissionInfo, { MissionCerts } from '../../models/MissionInfo';
import MissionAPI from '../../apis/MissionAPI';
import { Content, RankContent } from '../../models/MissionInfoDetail';
import errorImg from '../../assets/errorImg.jpg';
import moment from 'moment';
import noList from '../../assets/noList.png';

const SubLocationList = [
    { href: '/platform_02/', text: '소개마당' },
    { href: '/commu_02/', text: '소통마당' },
    { href: '/reward_02/', text: '참여마당' },
    { href: '/info_02/', text: '정보마당' },
];
const SubLocationList2 = [
	{ href: '/reward_02/', text: '에너지전환 현황' },
	{ href: '/reward_02/mission-main', text: '에너지전환 활동미션' },
	{ href: '/reward_02/challenge-main', text: '에너지전환 챌린지' },
	// { href: '/reward_02/', text: '내 E 포인트' },
];
const Detail_MapMissionPage = () => {
	// const Detail_MapMissionPage = ({
	// 	sort
	// }:{
	// 	sort: string
	// }) => {
	const { member } = useMember();
	const navigate = useNavigate();
	const { showDialog } = useDialog();
	const [page, setPage] = useState<number>(1);
	const [count, setCount] = useState(0);
	const [contentExtend, setContentExtend] = useState(false);
	const [missionDetail, setMissionDetail] = useState<MissionInfo | undefined>();
	const [certOne, setCertOne] = useState<MissionCerts>();
	const mmiUid = sessionStorage.getItem('mmiUid');
	const [rankData, setRankData] = useState([] as RankContent[]); // 순위 데이터
	const [certData, setCertData] = useState([] as Content[]); // 인증 데이터
	const [totalPage, setTotalPage] = useState(0);
	const [updateStatus, setUpdateStatus] = useState(false);
	const [certCnt, setCertCnt] = useState<number>(0);
	// 순위 정렬 (주간, 월간, 연간)
	const [sort, setSort] = useState<string>('주간');
	// 관리자 권한
	const [amnBtn, setAmnBtn] = useState(false);
	// 분류 선택 시
	const [selectedCmcUid, setSelectedCmcUid] = useState<number>();
	// 검색어
	const [search, setSearch] = useState<string>();
	const [mobileContentExtend, setMobileContentExtend] = useState(false);
	const handlePageChange = (page: number) => {
		setPage(page);
	};

	const handleSort = (sort: string) => {
		setSort(sort);
	};
	useEffect(() => {
		// console.log('팝업에서 보낸 sort값', sort);
	}, [sort]);

	// 미션 상세조회
	useEffect(() => {
		MissionAPI.getMissionDetail({
			mmiUid: Number(mmiUid),
			period: sort,
			cert_page: page - 1,
			cert_size: 10,
			cmcUid: selectedCmcUid,
			keyword: search,
		}).then((res) => {
			// console.log('getMissionDetail ::::::', res);
			setCertCnt(res.certCnt);
			// console.log('랭킹', res.rankings.content);
			setMissionDetail(res.missionInfo);
			setRankData(res.rankings.content);
			setCertData(res.missionCerts.content);
			setTotalPage(res.missionCerts.totalPages);
			// setCertCnt(res.certCnt);

			if (member) {
				// console.log('멤버 권한-->', member.roles);
				// 관리자
				if (member.roles === 'AMN') {
					setAmnBtn(true);
				}
			}
		});
	}, [page, member, sort, selectedCmcUid, search]);

	// console.log('지도미션 상세--> ', missionDetail);
	// console.log('인증 단일조회--> ', certOne);

	// 인증하기
	const handleWrite = (missionDetail: MissionInfo | undefined) => {
		// console.log('certCnt ::::::::', certCnt);

		// showDialog(Write_MapMissionDialog);
		// 로그인 함
		if (member && member?.id) {
			// 마을있음
			if (member?.townDTO?.townId) {
				// 부가정보 입력된 사람
				if (
					member?.username ||
					member?.telno ||
					member?.address ||
					member?.detailAddress
				) {
					showDialog(Write_MapMissionDialog, { missionDetail });
				}
				// 부가정보 입력 안한 사람
				else {
					const title = '인증하기';
					const message =
						'에너지전환 활동을 위해서는 부가정보 입력이 필요합니다. 입력하시겠습니까?';
					const action = 'ok';
					showDialog(TwoButtonAlertDialog, {
						title,
						message,
						action,
						handleDialogResult(result) {
							if (result === '확인') {
								navigate('/myPage_02/myInfoModify_04');
							}
						},
					});
				}

				//  마을 없음
			} else {
				const title = '인증하기';
				const message =
					'소속된 마을이 없으면 미션에 참여할 수 없습니다. 마을을 선택하시겠습니까?';
				const action = 'ok';
				showDialog(TwoButtonAlertDialog, {
					title,
					message,
					action,
					handleDialogResult(result) {
						if (result === '확인') {
							navigate('/myPage_02');
						}
					},
				});
			}
		}
		// 비로그인
		else {
			const title = '인증하기';
			const message = '미션 참여를 위해 로그인해주세요';
			const action = '인증ok';

			showDialog(TwoButtonAlertDialog, {
				title,
				message,
				action,
				handleDialogResult(result) {
					if (result === '확인') {
						showDialog(LoginDialog, {
							title: '로그인',
						});
					}
				},
			});
		}
	};
	// 카테고리 선택
	const handleCmcUidChange = (e: any) => {
		// console.log('cmcUid', e.target.value);
		setSelectedCmcUid(e.target.value);
	};
	// noList
	const [nullListMessage, setNullListMessage] =
		useState<string>('작성된 게시글이 없습니다.');
	// 검색어를 입력하세요
	const handleSearchKeyword = (search: string | undefined) => {
		setPage(1);
		setSearch(search);
		setNullListMessage('검색된 게시글이 없습니다.');
		// console.log('searchKeyword !!!!!!!!!!!!! ', search);
	};
	const getSearchKeyword = (e: any) => {
		if (handleSearchKeyword) {
			handleSearchKeyword(search);
		}
		if (e.key === 'Enter') {
			if (handleSearchKeyword) {
				e.preventDefault();
				handleSearchKeyword(search);
			}
		}
	};
	// 인증 리스트 클릭
	const [selectedListIndex, setSelectedListIndex] = useState<number | null>(
		null
	);
	const [listIndex, setListIndex] = useState<number>(0);
	const [listClickEvent, setListClickEvent] = useState(false);
	const handleList = (mmcUid: number, index: number) => {
		// console.log('리스트 클릭 시 mmcUid:::::::', mmcUid);
		setContentExtend(true);
		setListIndex(index);
		setListClickEvent((cur) => !cur);
		setSelectedListIndex(index);

		// sessionStorage.setItem('mmcUid', String(mmcUid))
		// setSelectedMmcUid(mmcUid);
		// 인증 단일조회

		MissionAPI.getCertOne({ mmcUid: Number(mmcUid) ? Number(mmcUid) : 0 })
			.then((res) => {
				// console.log('getCertOne ::::::::', res);
				setCertOne(res?.data);
			})
			.catch((e) => {
				const title = '';
				const message = '다시 시도해주세요.';
				showDialog(OneButtonAlertDialog, { title, message });
			});
	};

	const handlePrivate = (
		handleAction: string,
		mmcUid: number,
		visible?: string,
		certProgrsSt?: string,
		nickname?: string,
		accmlPt?: number
	) => {
		let title = '';
		let message = '';

		// 공개, 비공개
		if (handleAction == '공개,비공개') {
			message =
				visible === 'Y'
					? '인증 내역을 비공개하시겠습니까? 포인트는 회수되지 않습니다.'
					: '인증 내역을 공개하시겠습니까?';
		}

		// 포인트 회수
		if (handleAction == '포인트회수') {
			// // console.log("현재상태", certProgrsSt);
			// // console.log("닉네임", nickname);
			// // console.log("포인트", accmlPt);
			if (certProgrsSt === '신청') {
				message = `클릭 시 ${nickname}님에게 지급된 ${accmlPt
					?.toString()
					.replace(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					)} E 포인트를 회수하시겠습니까? 회수된 포인트는 재지급되지 않습니다.`;
			}
		}

		const action = 'ok';

		showDialog(TwoButtonAlertDialog, {
			title,
			message,
			action,
			handleDialogResult(result) {
				if (result === '확인') {
					MissionAPI.certStatusChange({
						mmcUid: mmcUid,
						visible: visible === 'Y' ? 'N' : 'Y',
						certProgrsSt: '회수',
					}).then((res) => {
						// console.log('인증 상태 변경 성공');
						let title = '';
						const message = '완료되었습니다.';

						showDialog(OneButtonAlertDialog, {
							title,
							message,
							handleDialogResult(result) {
								if (result === 'ok') {
									setUpdateStatus(!updateStatus);
								}
							},
						});
						window.location.reload();
					});
				}
			},
		});
	};

	// 지도미션 정보
	const handleInfo = (
		missionDetail: MissionInfo | undefined,
		certData: Content[] | undefined
	) => {
		showDialog(MapInfoDialog, {
			missionDetail,
			certData,
		});
	};

	const handleSetting = () => {
		const tab = 'mapMaster';
		showDialog(ModifyDialog, { tab });
	};
	return (
		<main className="Detail_MapMissionPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>
				<SubLocation
					titleText1="참여마당"
					liList={SubLocationList}
					titleText2="에너지전환 활동미션"
					liList2={SubLocationList2}
				/>
				<div className="lnb_txt">
					<h3>지도미션</h3>
					{/* <select>
						<option>마을미션</option>
						<option>기본미션</option>
					</select> */}
					<p>
						마을이 주체가 되어 에너지 전환에 도움이 되는 활동을 실천하고
						인증해요
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					<div className="missionVillage_map_wrap mt40">
						{/* 관리자 접속 시 admin 클래스 붙음 */}
						<div
							// className="missionVillage_map on"
							className={
								contentExtend ? 'missionVillage_map on' : 'missionVillage_map'
							}
						>
							<div className="btn_list">
								<a
									// className="btn_maplist mobile_btn none"
									className={
										mobileContentExtend
											? 'btn_maplist mobile_btn none'
											: 'btn_maplist mobile_btn'
									}
									onClick={() => {

										setMobileContentExtend(true);
									}}
								>
									목록보기
								</a>
								<a
									className={
										!mobileContentExtend
											? 'btn_map mobile_btn none'
											: 'btn_map mobile_btn'
									}
									onClick={() => {

										setMobileContentExtend(false);
									}}
								>
									지도보기
								</a>
								{member &&
									missionDetail?.prgrsState?.includes("진") &&
									certCnt !== missionDetail?.mxCertCo && (
										<a
											className="btn_write"
											onClick={() => handleWrite(missionDetail)}
										>
											인증하기
										</a>
									)}
							</div>
							<div className="left_inner">
								<a
									className="btn_close"
									onClick={() => setContentExtend(false)}
								/>
								<a
									className="btn_left"
									onClick={() => setContentExtend(false)}
								/>
								<Web_LeftBox
									page={page}
									handlePageChange={handlePageChange}
									contentExtend={contentExtend}
									setContentExtend={setContentExtend}
									member={member}
									missionDetail={missionDetail}
									certData={certData}
									handleCmcUidChange={handleCmcUidChange}
									getSearchKeyword={getSearchKeyword}
									setSearch={setSearch}
									totalPage={totalPage}
									handleList={handleList}
									selectedListIndex={selectedListIndex}
									nullListMessage={nullListMessage}
									handleInfo={handleInfo}
									handleSetting={handleSetting}
								/>
								{contentExtend ? (
									<Web_DetailBox
										member={member}
										certOne={certOne}
										amnBtn={amnBtn}
										handleList={handleList}
										handlePrivate={handlePrivate}
									/>
								) : (
									<></>
								)}
							</div>
							<MapMissionComponent
								certData={certData}
								listIndex={listIndex}
								listClickEvent={listClickEvent}
								handleList={handleList}
								page={page}
							/>
							<Mobile
								contentExtend={contentExtend}
								setContentExtend={setContentExtend}
								member={member}
								mobileContentExtend={mobileContentExtend}
								handlePrivate={handlePrivate}
								certOne={certOne}
								amnBtn={amnBtn}
								handleInfo={handleInfo}
								missionDetail={missionDetail}
								certData={certData}
								handleSetting={handleSetting}
								handleCmcUidChange={handleCmcUidChange}
								setSearch={setSearch}
								getSearchKeyword={getSearchKeyword}
								nullListMessage={nullListMessage}
								setMobileContentExtend={setMobileContentExtend}
								handleList={handleList}
							/>
						</div>
					</div>
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};
const Web_LeftBox = ({
	page,
	handlePageChange,
	contentExtend,
	setContentExtend,
	member,
	missionDetail,
	certData,
	handleCmcUidChange,
	getSearchKeyword,
	setSearch,
	totalPage,
	handleList,
	selectedListIndex,
	nullListMessage,
	handleInfo,
	handleSetting,
}: {
	page: number;
	handlePageChange: (page: number) => void;
	contentExtend: boolean;
	setContentExtend: (contentExtend: boolean) => void;
	member: MemberInfo | undefined;
	missionDetail: MissionInfo | undefined;
	certData: Content[] | undefined;
	handleCmcUidChange: (e: any) => void;
	getSearchKeyword: (e: any) => void;
	setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
	totalPage: number;
	handleList: (mmcUid: number, index: number) => void;
	selectedListIndex: number | null;
	nullListMessage: string;
	handleInfo: (
		missionDetail: MissionInfo | undefined,
		certData: Content[] | undefined
	) => void;
	handleSetting: () => void;
}) => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();

	return (
		<div className="left_box">
			<div className="title">
				<h2 className="txt_cut1">{missionDetail?.mssnNm}</h2>
				<div className="map_btn_wrap">
					<button
						type="button"
						className="btn_info"
						onClick={() => handleInfo(missionDetail, certData)}
					>
						<img src={ic_mapinfo} alt="지도 정보 아이콘" />
					</button>
					{member && member?.roles === 'AMN' &&
						(missionDetail?.prgrsState?.includes("대")) ||
						(missionDetail?.prgrsState?.includes("진") && certData && certData.length < 1) &&
						(

							<button type="button" className="btn_setting">
								<img
									src={ic_map_setting}
									onClick={handleSetting}
									alt="지도 설정 아이콘"
								/>
							</button>
						)}
				</div>
			</div>
			<form>
				<div className="category">
					<select className="cate_01" onChange={handleCmcUidChange}>
						<option value="">전체</option>
						{missionDetail?.categorys &&
							missionDetail?.categorys.map((list, index) => (
								<option key={index} value={list.cmcUid}>
									{list.title}
								</option>
							))}
					</select>
				</div>
				<div className="search">
					<input
						type="text"
						placeholder="검색어를 입력하세요"
						onChange={(e) => setSearch(e.target.value)}
						onKeyPress={getSearchKeyword}
					/>
					<button
						type="button"
						className="btn_search"
						onClick={getSearchKeyword}
					></button>
				</div>
			</form>
			{certData && certData.length != 0 ? (
				<div className="web_list">
					<div className="list">
						<ul>
							{certData &&
								certData?.map((certList, index) => (
									<li
										onClick={() => handleList(Number(certList?.mmcUid), index)}
										key={index}
										className={certList?.visible === 'N' ? 'non' : ''}
									>
										<a className={`${selectedListIndex === index ? 'on' : ''}`}>
											{/*선택되면 on 클래스 붙음*/}
											<div
												// className="list_table "
												className={
													certList?.missionCertFiles?.length === 0
														? 'list_table noimg'
														: 'list_table'
												}
											>
												{/*이미지 없으면 noimg 클래스 붙음*/}
												<div className="txt">
													<p className="txt_cut1 cate">{certList?.cmctitle}</p>
													<h5 className="txt_cut2">{certList?.certCn}</h5>
													<p className="address">{certList?.locInfo}</p>
												</div>

												<div className="img">
													<div className="img_box">
														{certList?.missionCertFiles &&
															certList?.missionCertFiles[0] && (
																<img
																	src={
																		certList?.missionCertFiles[0]?.savePath
																			? `/api/attach/getFile?savedPath=${certList?.missionCertFiles[0]?.savePath}`
																			: errorImg
																	}
																/>
															)}
														<span className="number">
															{certList?.missionCertFiles &&
																certList?.missionCertFiles.length}
														</span>
													</div>
												</div>
											</div>
										</a>
									</li>
								))}
						</ul>
						<Pagination
							page={page}
							// totalPageCount={Math.ceil(8 / 8)}
							totalPageCount={Math.ceil(totalPage / 6)}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			) : (
				<>
					<div className="comm_noList">
						<img src={noList} alt="내용없음 이미지" />
						<p className="mt25">{nullListMessage}</p>
					</div>{' '}
				</>
			)}
		</div>
	);
};
const Web_DetailBox = ({
	member,
	certOne,
	amnBtn,
	handleList,
	handlePrivate,
}: {
	member: MemberInfo | undefined;
	certOne: MissionCerts | undefined;
	amnBtn: boolean;
	handleList: (mmcUid: number, index: number) => void;
	handlePrivate: (
		handleAction: string,
		mmcUid: number,
		visible?: string,
		certProgrsSt?: string,
		nickname?: string,
		accmlPt?: number
	) => void;
}) => {
	// console.log('amnBtn::::::::::::::::::::', amnBtn);

	const [updateStatus, setUpdateStatus] = useState(false);
	const { showDialog } = useDialog();

	return (
		<div className="left_detail_box">
			<div className="left_detail_wrap pa20">
				<div className="comm_btn_set_wrap mb25">
					{member && member?.roles === 'AMN' && (
						<div className="comm_btn_set">
							<button
								type="button"
								className="non"
								onClick={() =>
									handlePrivate(
										'공개,비공개',
										Number(certOne?.mmcUid),
										String(certOne?.visible === 'Y' ? 'Y' : 'N')
									)
								}
							>
								{certOne?.visible === 'Y' ? '비공개' : '공개'}
							</button>
							{certOne &&
								certOne?.certProgrsSt != '회수' &&
								certOne?.certProgrsSt != '인증' &&
								amnBtn && (
									<button
										type="button"
										className="delet"
										onClick={() =>
											handlePrivate(
												'포인트회수',
												Number(certOne.mmcUid),
												String(''),
												String(certOne.certProgrsSt),
												String(certOne.nickname),
												Number(certOne.accmlPt)
											)
										}
									>
										포인트회수
									</button>
								)}
						</div>
					)}
				</div>
				<div className="comm_detail_cont">
					<p className="village">{certOne?.townName}</p>
					<div className="info01 mt10">
						{/* 레벨별 class "Lv~" */}
						<p
							className={
								certOne?.charecter === 1
									? 'name basic1'
									: certOne?.charecter === 2
										? 'name basic2'
										: certOne?.charecter === 3
											? 'name Lv1'
											: certOne?.charecter === 4
												? 'name Lv2'
												: certOne?.charecter === 5
													? 'name Lv3'
													: certOne?.charecter === 6
														? 'name Lv4'
														: ''
							}
						>
							{certOne?.nickname}
						</p>
						<p>{moment(certOne?.crtDt).format('YYYY.MM.DD')}</p>
					</div>
					<p className="txt_cut1 cate mt10">{certOne?.cmctitle}</p>
					<p className="mt10">
						{certOne?.certCn &&
							certOne?.certCn.split('\n').map((item, index) => (
								<React.Fragment key={index}>
									{item}
									<br />
								</React.Fragment>
							))}
					</p>
					{certOne?.missionCertFiles &&
						certOne?.missionCertFiles.map((list, index) => (
							<img
								key={index}
								src={
									list.savePath
										? `/api/attach/getFile?savedPath=${list.savePath}`
										: errorImg
								}
								className="mt10"
							/>
						))}
					{/* <img src="../assets/test04.png" alt="상세 이미지" className="mt10" /> */}
					<p className="address mt10">{certOne?.locInfo}</p>
				</div>
			</div>
		</div>
	);
};
const Mobile = ({
	contentExtend,
	setContentExtend,
	member,
	mobileContentExtend,
	handlePrivate,
	certOne,
	amnBtn,
	handleInfo,
	missionDetail,
	certData,
	handleSetting,
	handleCmcUidChange,
	setSearch,
	getSearchKeyword,
	nullListMessage,
	setMobileContentExtend,
	handleList,
}: {
	contentExtend: boolean;
	setContentExtend: (contentExtend: boolean) => void;
	member: MemberInfo | undefined;
	mobileContentExtend: boolean;
	certOne: MissionCerts | undefined;
	handlePrivate: (
		handleAction: string,
		mmcUid: number,
		visible?: string,
		certProgrsSt?: string,
		nickname?: string,
		accmlPt?: number
	) => void;
	amnBtn: boolean;
	handleInfo: (
		missionDetail: MissionInfo | undefined,
		certData: Content[] | undefined
	) => void;
	missionDetail: MissionInfo | undefined;
	certData: Content[];
	handleSetting: () => void;
	handleCmcUidChange: (e: any) => void;
	getSearchKeyword: (e: any) => void;
	setSearch: React.Dispatch<React.SetStateAction<string | undefined>>;
	nullListMessage: string;
	setMobileContentExtend: React.Dispatch<React.SetStateAction<boolean>>;
	handleList: (mmcUid: number, index: number) => void;
}) => {
	const navigate = useNavigate();
	const { showDialog } = useDialog();

	return (
		<div className="mobile_menu_inner">
			<div className="map_btn_popup">
				<button
					type="button"
					className="btn_info"
					onClick={() => handleInfo(missionDetail, certData)}
				>
					<img src={ic_mapinfo} alt="지도 정보 아이콘" />
				</button>
				{member && member?.roles === 'AMN' && (
					<button type="button" className="btn_setting" onClick={handleSetting}>
						<img src={ic_map_setting} alt="지도 설정 아이콘" />
					</button>
				)}
			</div>
			<div
				className="detail_popup"
				style={{
					display: contentExtend ? 'block' : 'none',
				}}
			>
				<div className="white_box">
					<div className="left_detail_box pa20">
						<a
							className="detail_popup_close"
							onClick={() => setContentExtend(!contentExtend)}
						/>
						{contentExtend && (
							<div className="left_detail_wrap mt25">
								<div className="comm_btn_set_wrap mb25">
									{member && member?.roles === 'AMN' && (
										<div className="comm_btn_set">
											<button
												type="button"
												className="non"
												onClick={() =>
													handlePrivate(
														'공개,비공개',
														Number(certOne?.mmcUid),
														String(certOne?.visible === 'Y' ? 'Y' : 'N')
													)
												}
											>
												비공개
											</button>
											{certOne &&
												certOne?.certProgrsSt != '회수' &&
												certOne?.certProgrsSt != '인증' &&
												amnBtn && (
													<button
														type="button"
														className="delet"
														onClick={() =>
															handlePrivate(
																String(certOne?.visible),
																Number(certOne?.mmiUid)
															)
														}
													>
														포인트회수
													</button>
												)}
										</div>
									)}
								</div>
								<div className="comm_detail_cont">
									<p className="village">{certOne?.townName}</p>
									<div className="info01 mt10">
										{/* 레벨별 class "Lv~" */}
										<p
											className={
												certOne?.charecter === 1
													? 'name basic1'
													: certOne?.charecter === 2
														? 'name basic2'
														: certOne?.charecter === 3
															? 'name Lv1'
															: certOne?.charecter === 4
																? 'name Lv2'
																: certOne?.charecter === 5
																	? 'name Lv3'
																	: certOne?.charecter === 6
																		? 'name Lv4'
																		: ''
											}
										>
											{certOne?.nickname}
										</p>
										<p>{moment(certOne?.crtDt).format('YYYY.MM.DD')}</p>
									</div>
									<p className="txt_cut1 cate mt10">{certOne?.cmctitle}</p>
									<p className="mt10">
										{certOne?.certCn &&
											certOne?.certCn.split('\n').map((item, index) => (
												<React.Fragment key={index}>
													{item}
													<br />
												</React.Fragment>
											))}
									</p>
									{certOne?.missionCertFiles &&
										certOne?.missionCertFiles[0] && (
											<img
												src={
													certOne?.missionCertFiles[0]?.savePath
														? `/api/attach/getFile?savedPath=${certOne?.missionCertFiles[0]?.savePath}`
														: errorImg
												}
											/>
										)}
									<p className="address mt10">{certOne?.locInfo}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				className={
					mobileContentExtend ? 'mobile_box_wrap' : 'mobile_box_wrap close'
				}
			>
				{mobileContentExtend && (
					<>
						<form>
							<div className="category">
								<select className="cate_01" onChange={handleCmcUidChange}>
									<option value="">전체</option>
									{missionDetail?.categorys &&
										missionDetail?.categorys.map((list, index) => (
											<option key={index} value={list.cmcUid}>
												{list.title}
											</option>
										))}
								</select>
							</div>
							<div className="search">
								<input
									type="text"
									placeholder="검색어를 입력하세요"
									onChange={(e) => setSearch(e.target.value)}
									onKeyPress={getSearchKeyword}
								/>
								<button
									type="button"
									className="btn_search"
									onClick={getSearchKeyword}
								></button>
							</div>
						</form>
						{certData && certData.length != 0 ? (
							<div className="mobile_list">
								<div className="list">
									<ul>
										{certData &&
											certData?.map((list, idx) => (
												<li
													key={idx}
													className={list?.visible === 'N' ? 'non' : ''}
													// onClick={() => setMobileContentExtend(true)}
													onClick={() => handleList(Number(list?.mmcUid), idx)}
												>
													<a>
														<div className="list_table">
															<div className="txt">
																<p className="txt_cut1 cate">
																	{list?.cmctitle}
																</p>
																<h5 className="txt_cut2">{list?.certCn}</h5>
																<p className="address">{list?.locInfo}</p>
															</div>
														</div>
													</a>
												</li>
											))}
									</ul>
								</div>
								{/* <Pagination
							page={page}
							totalPageCount={Math.ceil(8 / 8)}
							// totalPageCount={Math.ceil(count / 6)}
							onPageChange={handlePageChange}
						/> */}
							</div>
						) : (
							<div className="comm_noList">
								<img src={noList} alt="내용없음 이미지" />
								<p className="mt25">{nullListMessage}</p>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default Detail_MapMissionPage;
