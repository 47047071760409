import React, { useEffect, useState } from 'react';
import { EnergyMap, EnergyMapList, TownNameList } from '../../models/EnergyMap';
import mapMarker1 from '../../assets/mapMarker.png';
import mapMarker2 from '../../assets/mapMarker2.png';
import mapMarker4 from '../../assets/mapMarker4.png';
import IntroAPI from '../../apis/IntroAPI';
import { useNavigate } from 'react-router-dom';
interface EnergyMapComponentProps {

}
const EnergyVillageMapComponent = () => {
    const navigate = useNavigate();
    const [mapState, setMapState] = useState<any>();
    const [polygonList, setPolygonList] = useState<any[]>([]);
    const [markerList, setMarkerList] = useState([] as any[])
    const [infoWindowList, setInfoWindowList] = useState([] as any[]);

    const [markers, setMarkers] = useState([] as any[]);
    const [reload, setReload] = useState(false);
    const geocoder = new window.kakao.maps.services.Geocoder();

    useEffect(() => {

    }, [infoWindowList])

    useEffect(() => {

        // console.log("창크기", window.outerWidth);
        const mapContainer = document.getElementById('map');
        const mapOption = {
            center: new window.kakao.maps.LatLng(
                35.155230170131375,
                126.82782289316579
            ),
            draggable: true,
            level: window.outerWidth <= 1024 || window.outerWidth <= 600 ? 8 : 8
        };
        const map = new window.kakao.maps.Map(mapContainer, mapOption);
        setMapState(map);
        map.setZoomable(true);
        // const infowindow = new window.kakao.maps.InfoWindow({ removable: true });

        // Tileset을 생성하고 지도 오버레이로 추가합니다
        window.kakao.maps.Tileset.add('TILE_NUMBER',
            new window.kakao.maps.Tileset({
                width: 256,
                height: 256,
                getTile: function (x: string, y: string, z: string) {
                    var div = document.createElement('div');
                    // div.innerHTML = x + ', ' + y + ', ' + z;
                    // div.style.fontSize = '36px';
                    // div.style.fontWeight = 'bold';
                    // div.style.lineHeight = '256px'
                    // div.style.textAlign = 'center';
                    // div.style.color = '#4D4D4D';
                    // div.style.border = '1px dashed #ff5050';
                    div.style.backgroundColor = 'rgba(255, 255, 255, 1)'
                    return div;
                }
            }));

        // 지도 위에 TILE_NUMBER 오버레이 레이어를 추가합니다
        map.addOverlayMapTypeId(window.kakao.maps.MapTypeId.TILE_NUMBER);

        var geocoder = new window.kakao.maps.services.Geocoder();
        IntroAPI.getIntroInfoList().then((res) => {
            res.map((list, index) => {
                geocoder.addressSearch(
                    `${list?.location}`,
                    (result: any, status: any) => {
                        // 정상적으로 검색이 완료됐으면
                        if (status === window.kakao.maps.services.Status.OK) {
                            var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                            // console.log('위치', coords);
                            var markerPosition = new window.kakao.maps.LatLng(
                                coords.Ma,
                                coords.La
                            );
                            const markerClickBeforeImage = new window.kakao.maps.MarkerImage(
                                mapMarker1,
                                new window.kakao.maps.Size(25, 42)
                            );
                            var marker = new window.kakao.maps.Marker({
                                position: markerPosition
                            });
                            setMarkerList((cur) => [...cur, marker])
                            marker.setMap(map);
                            marker.setImage(markerClickBeforeImage);
                            var infoWindow = new window.kakao.maps.InfoWindow({
                                content: `<div class="myPosition"><h4 class="myPosition_txt">${list.townName}</h4></div>`,
                            });
                            // infoWindow.open(map, marker);
                            // 지도 클릭 이벤트 핸들러 등록
                            window.kakao.maps.event.addListener(marker, 'mouseover', (mouseEvent: any) => {
                                infoWindow.open(map, marker);
                                const infowindowList = document.querySelectorAll('.myPosition');
                                for (let i = 0; i < infowindowList.length; i++) {
                                    // console.log("22222222222222222222222", infowindowList[i])
                                    infowindowList[i].parentElement!.parentElement!.style.width = '150px';
                                    infowindowList[i].parentElement!.parentElement!.style.border = '10px';
                                    infowindowList[i].parentElement!.parentElement!.style.borderRadius = '5px';
                                }
                            });
                            window.kakao.maps.event.addListener(marker, 'mouseout', (mouseEvent: any) => {
                                infoWindow.close();

                            });
                            window.kakao.maps.event.addListener(marker, 'click', (mouseEvent: any) => {
                                navigate(`/commu_02/mainVillage_03`);
                                sessionStorage.setItem('town', String(list.townId));
                            });
                            setInfoWindowList((cur) => [...cur, infoWindow])

                        }
                    }
                );
            })
            setReload(!reload);
            //console.log("조회 데이터 >>>> ", res);
            // var markerPosition = new window.kakao.maps.LatLng(33.450701, 126.570667);
            // var marker = new window.kakao.maps.Marker({
            //     position: markerPosition
            // });
            // marker.setMap(map);
        })
        const areas = [
            {
                name: '동구', path: [
                    new window.kakao.maps.LatLng(35.16166199485959, 126.93511260589197),
                    new window.kakao.maps.LatLng(35.16159219626494, 126.92975064864252),
                    new window.kakao.maps.LatLng(35.16367028347061, 126.92397566524357),
                    new window.kakao.maps.LatLng(35.165489018030826, 126.91632055314817),
                    new window.kakao.maps.LatLng(35.16405931451299, 126.91575738899938),
                    new window.kakao.maps.LatLng(35.163207381100406, 126.91541973786815),
                    new window.kakao.maps.LatLng(35.160523064785174, 126.91435588721434),
                    new window.kakao.maps.LatLng(35.15578620793457, 126.9124732845657),
                    new window.kakao.maps.LatLng(35.15558738128244, 126.9123303086467),
                    new window.kakao.maps.LatLng(35.15555074750642, 126.91230223101782),
                    new window.kakao.maps.LatLng(35.15511301965724, 126.9117239410763),
                    new window.kakao.maps.LatLng(35.154857725492704, 126.9114032034494),
                    new window.kakao.maps.LatLng(35.154624326287056, 126.91116972733215),
                    new window.kakao.maps.LatLng(35.15434929895339, 126.91082364179364),
                    new window.kakao.maps.LatLng(35.15390847731653, 126.91026681700163),
                    new window.kakao.maps.LatLng(35.153606388617725, 126.90989383522268),
                    new window.kakao.maps.LatLng(35.1533954940815, 126.90962570527107),
                    new window.kakao.maps.LatLng(35.153271475215014, 126.90951677690767),
                    new window.kakao.maps.LatLng(35.15308749942211, 126.90928188314103),
                    new window.kakao.maps.LatLng(35.15298346659805, 126.90915081960422),
                    new window.kakao.maps.LatLng(35.152708430558185, 126.9088009719152),
                    new window.kakao.maps.LatLng(35.152684464452165, 126.90876687937869),
                    new window.kakao.maps.LatLng(35.15249085961258, 126.9084716393501),
                    new window.kakao.maps.LatLng(35.152439400966806, 126.90839795863627),
                    new window.kakao.maps.LatLng(35.152264033560094, 126.90817558256965),
                    new window.kakao.maps.LatLng(35.15153943432953, 126.90727803430096),
                    new window.kakao.maps.LatLng(35.15144018843483, 126.90715348906754),
                    new window.kakao.maps.LatLng(35.149189487975974, 126.90974273852736),
                    new window.kakao.maps.LatLng(35.14914725836389, 126.90976233101075),
                    new window.kakao.maps.LatLng(35.148958523591006, 126.90992939447946),
                    new window.kakao.maps.LatLng(35.14880694916275, 126.91006948463324),
                    new window.kakao.maps.LatLng(35.14872996170195, 126.91013764333451),
                    new window.kakao.maps.LatLng(35.14857696598521, 126.91027258749405),
                    new window.kakao.maps.LatLng(35.14835980724019, 126.91046813560955),
                    new window.kakao.maps.LatLng(35.14821075410722, 126.91059364732988),
                    new window.kakao.maps.LatLng(35.14750956124016, 126.91121016505791),
                    new window.kakao.maps.LatLng(35.146917012619966, 126.9117085957235),
                    new window.kakao.maps.LatLng(35.14657865643468, 126.91200697319489),
                    new window.kakao.maps.LatLng(35.146500127278216, 126.91207564563406),
                    new window.kakao.maps.LatLng(35.14589012774098, 126.9126056203454),
                    new window.kakao.maps.LatLng(35.14543625012745, 126.9130375232349),
                    new window.kakao.maps.LatLng(35.14520527784749, 126.91323563931782),
                    new window.kakao.maps.LatLng(35.144833305781454, 126.91359491100694),
                    new window.kakao.maps.LatLng(35.14356168065804, 126.91514970047535),
                    new window.kakao.maps.LatLng(35.14305130082136, 126.91576544043822),
                    new window.kakao.maps.LatLng(35.14287272886968, 126.91597652447801),
                    new window.kakao.maps.LatLng(35.142582954489846, 126.91648022845237),
                    new window.kakao.maps.LatLng(35.142497546455274, 126.91658971005107),
                    new window.kakao.maps.LatLng(35.142161733797835, 126.91690759198876),
                    new window.kakao.maps.LatLng(35.141376386785126, 126.91766761463614),
                    new window.kakao.maps.LatLng(35.14078394539021, 126.91815515194894),
                    new window.kakao.maps.LatLng(35.13997488768814, 126.9188534400325),
                    new window.kakao.maps.LatLng(35.13931391384637, 126.91943789715344),
                    new window.kakao.maps.LatLng(35.138895376603074, 126.91947638213517),
                    new window.kakao.maps.LatLng(35.13835362072776, 126.91996469693872),
                    new window.kakao.maps.LatLng(35.13777938820622, 126.92053602365615),
                    new window.kakao.maps.LatLng(35.137093807960504, 126.92098451441156),
                    new window.kakao.maps.LatLng(35.13489086879105, 126.92252897976408),
                    new window.kakao.maps.LatLng(35.13430209393392, 126.92328182394212),
                    new window.kakao.maps.LatLng(35.13413625706114, 126.92361011674103),
                    new window.kakao.maps.LatLng(35.133790094372394, 126.92408053650522),
                    new window.kakao.maps.LatLng(35.13297326229711, 126.92477049580397),
                    new window.kakao.maps.LatLng(35.13218426984786, 126.9254178871823),
                    new window.kakao.maps.LatLng(35.13193731603261, 126.92553349768052),
                    new window.kakao.maps.LatLng(35.131784278187254, 126.92561163815272),
                    new window.kakao.maps.LatLng(35.13114430404252, 126.92582669750206),
                    new window.kakao.maps.LatLng(35.13061248822251, 126.92603495987424),
                    new window.kakao.maps.LatLng(35.1289809083135, 126.92743020609771),
                    new window.kakao.maps.LatLng(35.128473782904564, 126.92739861503588),
                    new window.kakao.maps.LatLng(35.12845084260248, 126.92739717865257),
                    new window.kakao.maps.LatLng(35.126663254069854, 126.92937662527859),
                    new window.kakao.maps.LatLng(35.126465659142255, 126.92962392429263),
                    new window.kakao.maps.LatLng(35.126340328500625, 126.92950750137524),
                    new window.kakao.maps.LatLng(35.12581633230525, 126.92866164183474),
                    new window.kakao.maps.LatLng(35.12550020507843, 126.92712701051074),
                    new window.kakao.maps.LatLng(35.12376378673222, 126.92459159028024),
                    new window.kakao.maps.LatLng(35.12367771435974, 126.9245459010189),
                    new window.kakao.maps.LatLng(35.123072824527384, 126.9234653386399),
                    new window.kakao.maps.LatLng(35.12274314729172, 126.92305168640392),
                    new window.kakao.maps.LatLng(35.12256460165369, 126.9228917520095),
                    new window.kakao.maps.LatLng(35.121979614369465, 126.92275895529744),
                    new window.kakao.maps.LatLng(35.12098034205736, 126.92270677530473),
                    new window.kakao.maps.LatLng(35.120851952484365, 126.92258193433052),
                    new window.kakao.maps.LatLng(35.12065645323688, 126.92232809248463),
                    new window.kakao.maps.LatLng(35.119778705506405, 126.9216211834863),
                    new window.kakao.maps.LatLng(35.119704876689795, 126.92136466176207),
                    new window.kakao.maps.LatLng(35.11949131925573, 126.92064666043272),
                    new window.kakao.maps.LatLng(35.11940659975261, 126.92053739344902),
                    new window.kakao.maps.LatLng(35.11928262815957, 126.92005499203786),
                    new window.kakao.maps.LatLng(35.11842190383473, 126.91954967677346),
                    new window.kakao.maps.LatLng(35.117999360388666, 126.91950399247223),
                    new window.kakao.maps.LatLng(35.117167876785146, 126.91829076257892),
                    new window.kakao.maps.LatLng(35.11701264403323, 126.91763546919404),
                    new window.kakao.maps.LatLng(35.11688349736872, 126.91702523807713),
                    new window.kakao.maps.LatLng(35.11705234396053, 126.9154020346597),
                    new window.kakao.maps.LatLng(35.11661416724091, 126.91475903692015),
                    new window.kakao.maps.LatLng(35.11653147284906, 126.91413847355156),
                    new window.kakao.maps.LatLng(35.1164305236611, 126.91379303207523),
                    new window.kakao.maps.LatLng(35.115889915051326, 126.91273057775923),
                    new window.kakao.maps.LatLng(35.11558195894842, 126.91183670484313),
                    new window.kakao.maps.LatLng(35.115155473591365, 126.91218203585026),
                    new window.kakao.maps.LatLng(35.114661974461036, 126.91295386629017),
                    new window.kakao.maps.LatLng(35.11455471958066, 126.91322050965319),
                    new window.kakao.maps.LatLng(35.113741619848454, 126.91355509857023),
                    new window.kakao.maps.LatLng(35.11307529823579, 126.91294838533747),
                    new window.kakao.maps.LatLng(35.11169999434014, 126.9123354224074),
                    new window.kakao.maps.LatLng(35.10969815624788, 126.91295632490241),
                    new window.kakao.maps.LatLng(35.1071247174958, 126.91385389962026),
                    new window.kakao.maps.LatLng(35.105571837484106, 126.91443585196012),
                    new window.kakao.maps.LatLng(35.10529158668109, 126.91446186006384),
                    new window.kakao.maps.LatLng(35.10460516641507, 126.91488194783746),
                    new window.kakao.maps.LatLng(35.10243606276291, 126.91740310489541),
                    new window.kakao.maps.LatLng(35.0989030485084, 126.91828412709883),
                    new window.kakao.maps.LatLng(35.09868779282684, 126.91903492427637),
                    new window.kakao.maps.LatLng(35.098653329299864, 126.92013684590891),
                    new window.kakao.maps.LatLng(35.09713898183358, 126.92132384068535),
                    new window.kakao.maps.LatLng(35.09615004071955, 126.92072384383759),
                    new window.kakao.maps.LatLng(35.09519836273228, 126.92066925520486),
                    new window.kakao.maps.LatLng(35.094729820444535, 126.92050110094998),
                    new window.kakao.maps.LatLng(35.09303742617227, 126.91974367029573),
                    new window.kakao.maps.LatLng(35.091531661290006, 126.91922340854023),
                    new window.kakao.maps.LatLng(35.08799582493881, 126.9246693523631),
                    new window.kakao.maps.LatLng(35.08044213761172, 126.92768735048614),
                    new window.kakao.maps.LatLng(35.077807091690815, 126.93252838567356),
                    new window.kakao.maps.LatLng(35.0743923217763, 126.93229620514683),
                    new window.kakao.maps.LatLng(35.07448168855727, 126.93599501260047),
                    new window.kakao.maps.LatLng(35.07269878901101, 126.94674300372495),
                    new window.kakao.maps.LatLng(35.07315661115519, 126.95188207285698),
                    new window.kakao.maps.LatLng(35.07484269279111, 126.9549865133482),
                    new window.kakao.maps.LatLng(35.08045184785797, 126.95958298768791),
                    new window.kakao.maps.LatLng(35.0821209898022, 126.95855516570117),
                    new window.kakao.maps.LatLng(35.0873306919733, 126.96304572561209),
                    new window.kakao.maps.LatLng(35.08938558980726, 126.96651299758715),
                    new window.kakao.maps.LatLng(35.091862047326124, 126.97403360892109),
                    new window.kakao.maps.LatLng(35.09174752579022, 126.97873347702068),
                    new window.kakao.maps.LatLng(35.09498758281632, 126.98895069187131),
                    new window.kakao.maps.LatLng(35.101328375378074, 126.98875590281861),
                    new window.kakao.maps.LatLng(35.10594533054423, 126.98635174524263),
                    new window.kakao.maps.LatLng(35.10727005947194, 126.98885403981105),
                    new window.kakao.maps.LatLng(35.11073964864987, 126.98933598123206),
                    new window.kakao.maps.LatLng(35.11405020411049, 126.99993185328195),
                    new window.kakao.maps.LatLng(35.115755584397334, 126.99868658180122),
                    new window.kakao.maps.LatLng(35.12129063688343, 127.0043180211158),
                    new window.kakao.maps.LatLng(35.12314613771649, 126.99721180340507),
                    new window.kakao.maps.LatLng(35.12326580399892, 126.9961100941248),
                    new window.kakao.maps.LatLng(35.122770130742296, 126.99248089813561),
                    new window.kakao.maps.LatLng(35.125138043708, 126.99096732271765),
                    new window.kakao.maps.LatLng(35.12647436721488, 126.99001574340826),
                    new window.kakao.maps.LatLng(35.12849585396365, 126.98774918009185),
                    new window.kakao.maps.LatLng(35.13202392237876, 126.98621337785802),
                    new window.kakao.maps.LatLng(35.13449772812023, 126.98511312655326),
                    new window.kakao.maps.LatLng(35.137781401351404, 126.98171539505485),
                    new window.kakao.maps.LatLng(35.13794492684833, 126.98012499340328),
                    new window.kakao.maps.LatLng(35.1382508002182, 126.97773629044491),
                    new window.kakao.maps.LatLng(35.1381793485568, 126.97745410277685),
                    new window.kakao.maps.LatLng(35.137789889937565, 126.97586609731034),
                    new window.kakao.maps.LatLng(35.13783098338751, 126.97506919300126),
                    new window.kakao.maps.LatLng(35.13654750583727, 126.97132317237077),
                    new window.kakao.maps.LatLng(35.13685874401219, 126.9680515239303),
                    new window.kakao.maps.LatLng(35.13768892205866, 126.9668328793271),
                    new window.kakao.maps.LatLng(35.139806338338985, 126.963322525968),
                    new window.kakao.maps.LatLng(35.140520165259936, 126.95887210655994),
                    new window.kakao.maps.LatLng(35.140435436615334, 126.95864000502861),
                    new window.kakao.maps.LatLng(35.13996966274905, 126.9581630884707),
                    new window.kakao.maps.LatLng(35.140614020771, 126.95668721110333),
                    new window.kakao.maps.LatLng(35.14078810776068, 126.95484261773636),
                    new window.kakao.maps.LatLng(35.141855925586384, 126.95372633943181),
                    new window.kakao.maps.LatLng(35.143182901164636, 126.95165964557974),
                    new window.kakao.maps.LatLng(35.14358846197232, 126.95153886680005),
                    new window.kakao.maps.LatLng(35.1437528075303, 126.95151973840191),
                    new window.kakao.maps.LatLng(35.14445187082533, 126.95173483216679),
                    new window.kakao.maps.LatLng(35.14474921099951, 126.95215610416491),
                    new window.kakao.maps.LatLng(35.14488405252203, 126.95300236437186),
                    new window.kakao.maps.LatLng(35.145089266040515, 126.95339164368403),
                    new window.kakao.maps.LatLng(35.1464722225386, 126.95395461242992),
                    new window.kakao.maps.LatLng(35.1472817970238, 126.95371099187776),
                    new window.kakao.maps.LatLng(35.14832922127974, 126.95411111044712),
                    new window.kakao.maps.LatLng(35.148927944567255, 126.95491959361847),
                    new window.kakao.maps.LatLng(35.15058182347766, 126.95536655122821),
                    new window.kakao.maps.LatLng(35.150815827188424, 126.95556395909293),
                    new window.kakao.maps.LatLng(35.152344055936766, 126.95485163785627),
                    new window.kakao.maps.LatLng(35.1526629085152, 126.95410295017048),
                    new window.kakao.maps.LatLng(35.15420112999738, 126.95131306699855),
                    new window.kakao.maps.LatLng(35.15425971646671, 126.9506380638314),
                    new window.kakao.maps.LatLng(35.15429227597627, 126.95036539472542),
                    new window.kakao.maps.LatLng(35.15434194106813, 126.94989841453186),
                    new window.kakao.maps.LatLng(35.1544208649481, 126.94905997957972),
                    new window.kakao.maps.LatLng(35.15599346188357, 126.9456148466117),
                    new window.kakao.maps.LatLng(35.15775299577362, 126.94514633589871),
                    new window.kakao.maps.LatLng(35.15865042191541, 126.94367534360887),
                    new window.kakao.maps.LatLng(35.15874054458792, 126.94248821440982),
                    new window.kakao.maps.LatLng(35.15876342644295, 126.94233299350992),
                    new window.kakao.maps.LatLng(35.15861242814815, 126.94113711547882),
                    new window.kakao.maps.LatLng(35.15856366455617, 126.9407931364146),
                    new window.kakao.maps.LatLng(35.1585394036934, 126.94044108338787),
                    new window.kakao.maps.LatLng(35.15867162477172, 126.93983802509767),
                    new window.kakao.maps.LatLng(35.158785211177644, 126.93969217381775),
                    new window.kakao.maps.LatLng(35.159019004534244, 126.93942755281711),
                    new window.kakao.maps.LatLng(35.15937954470586, 126.93837043364897),
                    new window.kakao.maps.LatLng(35.15976485158392, 126.93843495173678),
                    new window.kakao.maps.LatLng(35.160265150793414, 126.9385755559152),
                    new window.kakao.maps.LatLng(35.161781314552286, 126.93724819738487),
                    new window.kakao.maps.LatLng(35.16166199485959, 126.93511260589197),
                ]
            }
            ,
            {
                name: '서구', path: [new window.kakao.maps.LatLng(35.160753184973146, 126.89272934556192),
                new window.kakao.maps.LatLng(35.161399068653544, 126.8877804071538),
                new window.kakao.maps.LatLng(35.1661549341854, 126.88555537023778),
                new window.kakao.maps.LatLng(35.16630996167808, 126.88537111837603),
                new window.kakao.maps.LatLng(35.16907615729475, 126.88408821813097),
                new window.kakao.maps.LatLng(35.169317576005, 126.88411891405663),
                new window.kakao.maps.LatLng(35.169802635208775, 126.88399438613301),
                new window.kakao.maps.LatLng(35.16986420017085, 126.88386703056246),
                new window.kakao.maps.LatLng(35.16996821254475, 126.88364768063428),
                new window.kakao.maps.LatLng(35.17000124540685, 126.88358109097089),
                new window.kakao.maps.LatLng(35.170041009151525, 126.88349578560545),
                new window.kakao.maps.LatLng(35.17006939218302, 126.88341908176851),
                new window.kakao.maps.LatLng(35.17019462709263, 126.88302510003514),
                new window.kakao.maps.LatLng(35.17021848937246, 126.88294564613118),
                new window.kakao.maps.LatLng(35.170270726007494, 126.88278109102582),
                new window.kakao.maps.LatLng(35.17029388186274, 126.88269838924488),
                new window.kakao.maps.LatLng(35.17041163298706, 126.88228588643095),
                new window.kakao.maps.LatLng(35.17047649141556, 126.88207243118352),
                new window.kakao.maps.LatLng(35.17048954513432, 126.88202884420105),
                new window.kakao.maps.LatLng(35.17053248262145, 126.88185931687184),
                new window.kakao.maps.LatLng(35.170570055419645, 126.88169032517929),
                new window.kakao.maps.LatLng(35.17060694374436, 126.8815425964008),
                new window.kakao.maps.LatLng(35.1706295353772, 126.8814531982277),
                new window.kakao.maps.LatLng(35.17065243039976, 126.88139022255473),
                new window.kakao.maps.LatLng(35.170675133466, 126.8812812745342),
                new window.kakao.maps.LatLng(35.17070695692119, 126.88113045682333),
                new window.kakao.maps.LatLng(35.170734990019966, 126.88099544158291),
                new window.kakao.maps.LatLng(35.17081906034419, 126.88056446608269),
                new window.kakao.maps.LatLng(35.170835444096376, 126.88047130053867),
                new window.kakao.maps.LatLng(35.17087144050017, 126.88026715018549),
                new window.kakao.maps.LatLng(35.17089004564292, 126.88014225786057),
                new window.kakao.maps.LatLng(35.170904389530264, 126.87998580120127),
                new window.kakao.maps.LatLng(35.17092472426445, 126.87975045491379),
                new window.kakao.maps.LatLng(35.17094162086036, 126.87961424745752),
                new window.kakao.maps.LatLng(35.17095279853321, 126.87952554613233),
                new window.kakao.maps.LatLng(35.17098033034836, 126.87931522705968),
                new window.kakao.maps.LatLng(35.170980848303664, 126.87913171108866),
                new window.kakao.maps.LatLng(35.170982413094755, 126.87900117977695),
                new window.kakao.maps.LatLng(35.170983379344094, 126.8788425258199),
                new window.kakao.maps.LatLng(35.17098454528774, 126.87873892136354),
                new window.kakao.maps.LatLng(35.170984478399724, 126.87867289452859),
                new window.kakao.maps.LatLng(35.170985221421205, 126.87858095993677),
                new window.kakao.maps.LatLng(35.170986780046306, 126.87844493938196),
                new window.kakao.maps.LatLng(35.17099956497927, 126.8781382525017),
                new window.kakao.maps.LatLng(35.17100259797973, 126.87794117541164),
                new window.kakao.maps.LatLng(35.17100166363992, 126.87784495136876),
                new window.kakao.maps.LatLng(35.17099560895096, 126.87771683546985),
                new window.kakao.maps.LatLng(35.17098721440224, 126.87749352881585),
                new window.kakao.maps.LatLng(35.17098400145838, 126.87738376238075),
                new window.kakao.maps.LatLng(35.17092203045264, 126.87709741952091),
                new window.kakao.maps.LatLng(35.17078745666876, 126.87648427865278),
                new window.kakao.maps.LatLng(35.17075715767867, 126.87633133769411),
                new window.kakao.maps.LatLng(35.170728131975565, 126.87618096296374),
                new window.kakao.maps.LatLng(35.17067204544299, 126.87586991375356),
                new window.kakao.maps.LatLng(35.17062153072773, 126.87564118517079),
                new window.kakao.maps.LatLng(35.171193880765664, 126.87564212410587),
                new window.kakao.maps.LatLng(35.17136834396039, 126.87564807967131),
                new window.kakao.maps.LatLng(35.1715423424264, 126.8729018962998),
                new window.kakao.maps.LatLng(35.171551384853025, 126.87275914555505),
                new window.kakao.maps.LatLng(35.17170381598829, 126.87026617444346),
                new window.kakao.maps.LatLng(35.17182429868611, 126.86807728398506),
                new window.kakao.maps.LatLng(35.17237384470287, 126.85957955977472),
                new window.kakao.maps.LatLng(35.17624373734655, 126.84290743509631),
                new window.kakao.maps.LatLng(35.17687689746604, 126.84330552776142),
                new window.kakao.maps.LatLng(35.176917799420025, 126.84324197373388),
                new window.kakao.maps.LatLng(35.17699954877371, 126.84254300395041),
                new window.kakao.maps.LatLng(35.1772395969034, 126.84248285023159),
                new window.kakao.maps.LatLng(35.177732361457736, 126.84193144099727),
                new window.kakao.maps.LatLng(35.17775551217006, 126.84186604417225),
                new window.kakao.maps.LatLng(35.178386244282116, 126.84064023469655),
                new window.kakao.maps.LatLng(35.17860731012988, 126.84018094571182),
                new window.kakao.maps.LatLng(35.17848568840904, 126.83990844520335),
                new window.kakao.maps.LatLng(35.17850095048454, 126.83973448889549),
                new window.kakao.maps.LatLng(35.1783930398616, 126.83960553343596),
                new window.kakao.maps.LatLng(35.17831777140931, 126.83945250566082),
                new window.kakao.maps.LatLng(35.178362642948976, 126.83941415740746),
                new window.kakao.maps.LatLng(35.17839418310836, 126.83930071276563),
                new window.kakao.maps.LatLng(35.17843443057894, 126.8387498497354),
                new window.kakao.maps.LatLng(35.17120450333228, 126.83763153971178),
                new window.kakao.maps.LatLng(35.16802512860331, 126.82791854088974),
                new window.kakao.maps.LatLng(35.16386503118092, 126.82417044322162),
                new window.kakao.maps.LatLng(35.160448156359195, 126.82607253712222),
                new window.kakao.maps.LatLng(35.15367278085618, 126.82538074783584),
                new window.kakao.maps.LatLng(35.14861734592283, 126.82912985941775),
                new window.kakao.maps.LatLng(35.1405989399177, 126.82932300480232),
                new window.kakao.maps.LatLng(35.13523469989606, 126.8237320670675),
                new window.kakao.maps.LatLng(35.1328210687021, 126.81900889550558),
                new window.kakao.maps.LatLng(35.129765355396316, 126.81902976442564),
                new window.kakao.maps.LatLng(35.12665075729248, 126.82224903389712),
                new window.kakao.maps.LatLng(35.12072904459607, 126.81694496712508),
                new window.kakao.maps.LatLng(35.116595889102044, 126.81675408466577),
                new window.kakao.maps.LatLng(35.1163604898596, 126.8096429462893),
                new window.kakao.maps.LatLng(35.11239526994397, 126.8094119328336),
                new window.kakao.maps.LatLng(35.11226942880126, 126.8028084062702),
                new window.kakao.maps.LatLng(35.10284999831582, 126.80640681619225),
                new window.kakao.maps.LatLng(35.10087989435158, 126.80101247614378),
                new window.kakao.maps.LatLng(35.097276638251465, 126.80063042192864),
                new window.kakao.maps.LatLng(35.09347727430101, 126.8025248160242),
                new window.kakao.maps.LatLng(35.09355747999089, 126.80317027621818),
                new window.kakao.maps.LatLng(35.0935743843973, 126.80334365281013),
                new window.kakao.maps.LatLng(35.09370134410732, 126.80346654571109),
                new window.kakao.maps.LatLng(35.093856335998346, 126.80351261593621),
                new window.kakao.maps.LatLng(35.09387573830872, 126.80357648345142),
                new window.kakao.maps.LatLng(35.0937842631453, 126.80370607925349),
                new window.kakao.maps.LatLng(35.09449222734735, 126.80446930866697),
                new window.kakao.maps.LatLng(35.09459090104273, 126.8045199571775),
                new window.kakao.maps.LatLng(35.09472600545773, 126.80463633163627),
                new window.kakao.maps.LatLng(35.095041329392515, 126.8051482780893),
                new window.kakao.maps.LatLng(35.09539526127553, 126.80532838511765),
                new window.kakao.maps.LatLng(35.0956351018546, 126.80532113191366),
                new window.kakao.maps.LatLng(35.095732177587564, 126.8052592099903),
                new window.kakao.maps.LatLng(35.0959248870075, 126.80519654338951),
                new window.kakao.maps.LatLng(35.095959540801175, 126.80520571649754),
                new window.kakao.maps.LatLng(35.09656581845279, 126.80553104037507),
                new window.kakao.maps.LatLng(35.096719068253876, 126.80572122817348),
                new window.kakao.maps.LatLng(35.09677239046327, 126.80577456568382),
                new window.kakao.maps.LatLng(35.096783135562056, 126.80579853671252),
                new window.kakao.maps.LatLng(35.09692101816838, 126.8059723114094),
                new window.kakao.maps.LatLng(35.09722704244112, 126.80622622902646),
                new window.kakao.maps.LatLng(35.09724888443241, 126.80632264425192),
                new window.kakao.maps.LatLng(35.097559234814184, 126.80654845629789),
                new window.kakao.maps.LatLng(35.09345284179503, 126.80893885931069),
                new window.kakao.maps.LatLng(35.09246032330802, 126.80954092064991),
                new window.kakao.maps.LatLng(35.092271288284735, 126.80960819359844),
                new window.kakao.maps.LatLng(35.091827018292165, 126.80992384140137),
                new window.kakao.maps.LatLng(35.091625650165795, 126.81012136864582),
                new window.kakao.maps.LatLng(35.091329083088176, 126.81032665106628),
                new window.kakao.maps.LatLng(35.091174148253394, 126.8104070229942),
                new window.kakao.maps.LatLng(35.09109537634837, 126.8110938137155),
                new window.kakao.maps.LatLng(35.09095255231851, 126.8113561433407),
                new window.kakao.maps.LatLng(35.091067123713174, 126.81148798971394),
                new window.kakao.maps.LatLng(35.09168739010127, 126.81240104680978),
                new window.kakao.maps.LatLng(35.09225181324303, 126.81318248259086),
                new window.kakao.maps.LatLng(35.09253865405802, 126.81351390238646),
                new window.kakao.maps.LatLng(35.09294358205354, 126.81397974765966),
                new window.kakao.maps.LatLng(35.093294532176316, 126.81424522707513),
                new window.kakao.maps.LatLng(35.09341238161549, 126.81422421915443),
                new window.kakao.maps.LatLng(35.093227170230264, 126.81448869840798),
                new window.kakao.maps.LatLng(35.09322903964172, 126.81451439999952),
                new window.kakao.maps.LatLng(35.09343221384391, 126.81484671108697),
                new window.kakao.maps.LatLng(35.09345243134955, 126.81489327359847),
                new window.kakao.maps.LatLng(35.09349900109711, 126.81504944340516),
                new window.kakao.maps.LatLng(35.09358630863351, 126.81550144667122),
                new window.kakao.maps.LatLng(35.09285284005856, 126.8171719269585),
                new window.kakao.maps.LatLng(35.09190185228041, 126.81843624641465),
                new window.kakao.maps.LatLng(35.09168702948595, 126.82189716768708),
                new window.kakao.maps.LatLng(35.091864770177516, 126.82266049674315),
                new window.kakao.maps.LatLng(35.09194258954053, 126.82300027766102),
                new window.kakao.maps.LatLng(35.09238600598668, 126.82361395570494),
                new window.kakao.maps.LatLng(35.09303326271736, 126.82464736587282),
                new window.kakao.maps.LatLng(35.09320333144757, 126.82489494415637),
                new window.kakao.maps.LatLng(35.09322162767665, 126.82576282023435),
                new window.kakao.maps.LatLng(35.09369988751556, 126.82652140645635),
                new window.kakao.maps.LatLng(35.09392080483692, 126.82667927373902),
                new window.kakao.maps.LatLng(35.09429336762276, 126.82700542169997),
                new window.kakao.maps.LatLng(35.094417714893936, 126.82748650359748),
                new window.kakao.maps.LatLng(35.09441996279052, 126.8279734922685),
                new window.kakao.maps.LatLng(35.09443739742429, 126.82805073874397),
                new window.kakao.maps.LatLng(35.094630992161306, 126.82830822672277),
                new window.kakao.maps.LatLng(35.09482877594724, 126.82824354212669),
                new window.kakao.maps.LatLng(35.09488151970037, 126.82829244168806),
                new window.kakao.maps.LatLng(35.095165799851834, 126.82863336324706),
                new window.kakao.maps.LatLng(35.096002629693864, 126.82970242156995),
                new window.kakao.maps.LatLng(35.09678359932911, 126.83052093153418),
                new window.kakao.maps.LatLng(35.098058838652236, 126.83149007566774),
                new window.kakao.maps.LatLng(35.09794520010804, 126.83291469283269),
                new window.kakao.maps.LatLng(35.09782913903404, 126.83361546855238),
                new window.kakao.maps.LatLng(35.098946059342616, 126.83664997086483),
                new window.kakao.maps.LatLng(35.10135972499161, 126.83747352211869),
                new window.kakao.maps.LatLng(35.10169626655782, 126.83763376828169),
                new window.kakao.maps.LatLng(35.10221660939186, 126.83811839708314),
                new window.kakao.maps.LatLng(35.10158356782769, 126.83897223098242),
                new window.kakao.maps.LatLng(35.10128945157808, 126.83946842678093),
                new window.kakao.maps.LatLng(35.101202545172825, 126.84020103623362),
                new window.kakao.maps.LatLng(35.101177338835065, 126.84041615275589),
                new window.kakao.maps.LatLng(35.101203725674715, 126.8414061169089),
                new window.kakao.maps.LatLng(35.101498377372955, 126.84184802679063),
                new window.kakao.maps.LatLng(35.1017445967154, 126.8422005681848),
                new window.kakao.maps.LatLng(35.10282189641517, 126.84297771551833),
                new window.kakao.maps.LatLng(35.10436122105561, 126.84381397920619),
                new window.kakao.maps.LatLng(35.104607173403366, 126.84581502985418),
                new window.kakao.maps.LatLng(35.1053520946001, 126.84672364529015),
                new window.kakao.maps.LatLng(35.10553341134529, 126.84899289447193),
                new window.kakao.maps.LatLng(35.10591678264183, 126.85080522371106),
                new window.kakao.maps.LatLng(35.10636091463874, 126.85360770280721),
                new window.kakao.maps.LatLng(35.1065857534631, 126.85435160684133),
                new window.kakao.maps.LatLng(35.106185924972905, 126.85670128092906),
                new window.kakao.maps.LatLng(35.106345614762674, 126.860632170274),
                new window.kakao.maps.LatLng(35.10728349039944, 126.86127033289836),
                new window.kakao.maps.LatLng(35.10752172264375, 126.86121782415961),
                new window.kakao.maps.LatLng(35.10783694352427, 126.86099705563416),
                new window.kakao.maps.LatLng(35.10805416697602, 126.8605485020836),
                new window.kakao.maps.LatLng(35.108156283302826, 126.85983793914613),
                new window.kakao.maps.LatLng(35.10833148809285, 126.8594756683334),
                new window.kakao.maps.LatLng(35.108626298474434, 126.8597454269301),
                new window.kakao.maps.LatLng(35.108827840340076, 126.85999222735046),
                new window.kakao.maps.LatLng(35.109127541345785, 126.8604784513392),
                new window.kakao.maps.LatLng(35.10914808572191, 126.86070550370918),
                new window.kakao.maps.LatLng(35.10909193553758, 126.86110612630789),
                new window.kakao.maps.LatLng(35.108981915732905, 126.86132278483221),
                new window.kakao.maps.LatLng(35.10894497828531, 126.86141848498193),
                new window.kakao.maps.LatLng(35.109030384827236, 126.86159109776422),
                new window.kakao.maps.LatLng(35.10915989551896, 126.86178299514765),
                new window.kakao.maps.LatLng(35.109352296812425, 126.8624077164967),
                new window.kakao.maps.LatLng(35.10944968458359, 126.86270903106933),
                new window.kakao.maps.LatLng(35.10962406837029, 126.86322769541256),
                new window.kakao.maps.LatLng(35.1092083818264, 126.86390418784686),
                new window.kakao.maps.LatLng(35.10922157733056, 126.86424625039713),
                new window.kakao.maps.LatLng(35.11053352577852, 126.86629406316348),
                new window.kakao.maps.LatLng(35.1104577038392, 126.86712817232473),
                new window.kakao.maps.LatLng(35.11026907917616, 126.86760563249597),
                new window.kakao.maps.LatLng(35.110269345306406, 126.86784694967335),
                new window.kakao.maps.LatLng(35.11039043070244, 126.87026267408204),
                new window.kakao.maps.LatLng(35.11385110027597, 126.87092592659549),
                new window.kakao.maps.LatLng(35.114192208530724, 126.87065406282969),
                new window.kakao.maps.LatLng(35.11543062321294, 126.8696486990992),
                new window.kakao.maps.LatLng(35.11581102504203, 126.8695149026255),
                new window.kakao.maps.LatLng(35.11601430609281, 126.86969678309576),
                new window.kakao.maps.LatLng(35.11609392959737, 126.87025764812714),
                new window.kakao.maps.LatLng(35.11633142054928, 126.87069246186489),
                new window.kakao.maps.LatLng(35.11723749569486, 126.87180308983339),
                new window.kakao.maps.LatLng(35.11744523024694, 126.87180138957427),
                new window.kakao.maps.LatLng(35.11776485372571, 126.87265671778627),
                new window.kakao.maps.LatLng(35.11816953873705, 126.87284497412634),
                new window.kakao.maps.LatLng(35.118588575117336, 126.8735448553441),
                new window.kakao.maps.LatLng(35.119955805791896, 126.87433856840808),
                new window.kakao.maps.LatLng(35.11843311915901, 126.87532773584903),
                new window.kakao.maps.LatLng(35.11770243806957, 126.8766661680963),
                new window.kakao.maps.LatLng(35.11702166963614, 126.87850500168688),
                new window.kakao.maps.LatLng(35.11651740247395, 126.88024739428673),
                new window.kakao.maps.LatLng(35.115532188014775, 126.88377149458236),
                new window.kakao.maps.LatLng(35.11697317872121, 126.88664130559711),
                new window.kakao.maps.LatLng(35.11700300842072, 126.88765872083252),
                new window.kakao.maps.LatLng(35.11741906235701, 126.8881370463925),
                new window.kakao.maps.LatLng(35.11802065027321, 126.8883592116395),
                new window.kakao.maps.LatLng(35.11847816951827, 126.88769454990036),
                new window.kakao.maps.LatLng(35.119345284567395, 126.88767311516982),
                new window.kakao.maps.LatLng(35.120118442904946, 126.88838511028108),
                new window.kakao.maps.LatLng(35.120528019738664, 126.88840117613842),
                new window.kakao.maps.LatLng(35.12239376571375, 126.88832830988689),
                new window.kakao.maps.LatLng(35.12283815262421, 126.88837535218342),
                new window.kakao.maps.LatLng(35.12458206520932, 126.88814272976965),
                new window.kakao.maps.LatLng(35.1249480359913, 126.88807862447482),
                new window.kakao.maps.LatLng(35.125238823459874, 126.88788605316059),
                new window.kakao.maps.LatLng(35.125708802025294, 126.88728045749971),
                new window.kakao.maps.LatLng(35.12636238932592, 126.88724766342297),
                new window.kakao.maps.LatLng(35.128811506649626, 126.88674915762569),
                new window.kakao.maps.LatLng(35.128843055599994, 126.88675064889739),
                new window.kakao.maps.LatLng(35.12916027906618, 126.88695987041818),
                new window.kakao.maps.LatLng(35.129401194325375, 126.88690278849015),
                new window.kakao.maps.LatLng(35.13000932939272, 126.88675964608467),
                new window.kakao.maps.LatLng(35.130071150104094, 126.88674429730341),
                new window.kakao.maps.LatLng(35.130175349592804, 126.88673009713858),
                new window.kakao.maps.LatLng(35.13080890616027, 126.8867957189402),
                new window.kakao.maps.LatLng(35.13090323577002, 126.886771931591),
                new window.kakao.maps.LatLng(35.13103523828524, 126.88680637353649),
                new window.kakao.maps.LatLng(35.13130060553357, 126.88684509477326),
                new window.kakao.maps.LatLng(35.13145936912255, 126.88703431182901),
                new window.kakao.maps.LatLng(35.132555013975285, 126.8875601139797),
                new window.kakao.maps.LatLng(35.13316102067689, 126.88739383435099),
                new window.kakao.maps.LatLng(35.133327089626675, 126.88726056639634),
                new window.kakao.maps.LatLng(35.13385120882721, 126.88718165827551),
                new window.kakao.maps.LatLng(35.13391749323171, 126.88713459382735),
                new window.kakao.maps.LatLng(35.13437660085024, 126.88651624940036),
                new window.kakao.maps.LatLng(35.135671367025246, 126.8859781546547),
                new window.kakao.maps.LatLng(35.13663003556974, 126.88566734336881),
                new window.kakao.maps.LatLng(35.13695501477399, 126.88515579192234),
                new window.kakao.maps.LatLng(35.137585805144525, 126.88411113340098),
                new window.kakao.maps.LatLng(35.13799566526887, 126.883407600672),
                new window.kakao.maps.LatLng(35.13840313879545, 126.88315720693136),
                new window.kakao.maps.LatLng(35.13915924335697, 126.88411178346122),
                new window.kakao.maps.LatLng(35.13940498194209, 126.88453543499041),
                new window.kakao.maps.LatLng(35.13962764922518, 126.88483086987952),
                new window.kakao.maps.LatLng(35.13978980376746, 126.88487607753282),
                new window.kakao.maps.LatLng(35.139853656864865, 126.8850856687536),
                new window.kakao.maps.LatLng(35.14026885222809, 126.88539009468917),
                new window.kakao.maps.LatLng(35.14080670907855, 126.88538949552684),
                new window.kakao.maps.LatLng(35.14100745800525, 126.8854425404549),
                new window.kakao.maps.LatLng(35.1413073266679, 126.88561784954503),
                new window.kakao.maps.LatLng(35.14121573379531, 126.8858686494906),
                new window.kakao.maps.LatLng(35.14132766650428, 126.88612430888152),
                new window.kakao.maps.LatLng(35.141479249637705, 126.88647026569349),
                new window.kakao.maps.LatLng(35.14157960949428, 126.88669935389284),
                new window.kakao.maps.LatLng(35.14170226043722, 126.886979350021),
                new window.kakao.maps.LatLng(35.14174996398442, 126.88708832413235),
                new window.kakao.maps.LatLng(35.141935842627994, 126.88751276054637),
                new window.kakao.maps.LatLng(35.14209919996419, 126.8877937225796),
                new window.kakao.maps.LatLng(35.14215196562191, 126.8877482090737),
                new window.kakao.maps.LatLng(35.14621891448209, 126.88566696903182),
                new window.kakao.maps.LatLng(35.1469679469132, 126.88617207984866),
                new window.kakao.maps.LatLng(35.14718848942643, 126.88660764263062),
                new window.kakao.maps.LatLng(35.147509928051, 126.88725344830613),
                new window.kakao.maps.LatLng(35.14798682270443, 126.88818368845723),
                new window.kakao.maps.LatLng(35.14829254559959, 126.88875357708989),
                new window.kakao.maps.LatLng(35.1488013275341, 126.88975169991373),
                new window.kakao.maps.LatLng(35.148860873793446, 126.88986975490177),
                new window.kakao.maps.LatLng(35.14903557998106, 126.89024847750322),
                new window.kakao.maps.LatLng(35.149193083420236, 126.8910797234902),
                new window.kakao.maps.LatLng(35.149294032902496, 126.89166036483348),
                new window.kakao.maps.LatLng(35.15085045092921, 126.89452705635712),
                new window.kakao.maps.LatLng(35.15105405684747, 126.89448718383565),
                new window.kakao.maps.LatLng(35.15144795500337, 126.89429769780648),
                new window.kakao.maps.LatLng(35.151612485146906, 126.89481775224236),
                new window.kakao.maps.LatLng(35.15244155952795, 126.89736501585683),
                new window.kakao.maps.LatLng(35.152319662205066, 126.89827622917181),
                new window.kakao.maps.LatLng(35.15232039645237, 126.89914236770956),
                new window.kakao.maps.LatLng(35.152405656872986, 126.89969819235557),
                new window.kakao.maps.LatLng(35.15247542255031, 126.90026964326127),
                new window.kakao.maps.LatLng(35.15243549480552, 126.90085718771576),
                new window.kakao.maps.LatLng(35.15233821092302, 126.90107370468607),
                new window.kakao.maps.LatLng(35.15216350106435, 126.90132410725633),
                new window.kakao.maps.LatLng(35.15134861417818, 126.90200415223732),
                new window.kakao.maps.LatLng(35.15128118855298, 126.90204299661951),
                new window.kakao.maps.LatLng(35.15119686911803, 126.9020899047757),
                new window.kakao.maps.LatLng(35.150005341986436, 126.90273370198598),
                new window.kakao.maps.LatLng(35.14963982897361, 126.90302752946027),
                new window.kakao.maps.LatLng(35.14915890306968, 126.90358298548776),
                new window.kakao.maps.LatLng(35.14912529970477, 126.90364904706078),
                new window.kakao.maps.LatLng(35.14896156595952, 126.90390302074132),
                new window.kakao.maps.LatLng(35.14893695775242, 126.90394643036876),
                new window.kakao.maps.LatLng(35.14888815707081, 126.90403445805691),
                new window.kakao.maps.LatLng(35.14886255980381, 126.90408078848444),
                new window.kakao.maps.LatLng(35.14871281427033, 126.90438017631287),
                new window.kakao.maps.LatLng(35.148636050806694, 126.90455517371603),
                new window.kakao.maps.LatLng(35.14829695610466, 126.90548956925555),
                new window.kakao.maps.LatLng(35.14836602770349, 126.90574137599727),
                new window.kakao.maps.LatLng(35.14845994009262, 126.90607580091648),
                new window.kakao.maps.LatLng(35.148634617120564, 126.90683642326832),
                new window.kakao.maps.LatLng(35.14878256965153, 126.90747874437142),
                new window.kakao.maps.LatLng(35.14884856434852, 126.9079418224907),
                new window.kakao.maps.LatLng(35.14889272476519, 126.90860210957554),
                new window.kakao.maps.LatLng(35.14893087603369, 126.90912916681596),
                new window.kakao.maps.LatLng(35.14900823965011, 126.9095565568599),
                new window.kakao.maps.LatLng(35.14902667411239, 126.90954744948368),
                new window.kakao.maps.LatLng(35.149189487975974, 126.90974273852736),
                new window.kakao.maps.LatLng(35.15144018843483, 126.90715348906754),
                new window.kakao.maps.LatLng(35.151454819832246, 126.90713341142244),
                new window.kakao.maps.LatLng(35.15201771170446, 126.90636659265374),
                new window.kakao.maps.LatLng(35.15206653115648, 126.90630034803654),
                new window.kakao.maps.LatLng(35.152501677939256, 126.90571613331153),
                new window.kakao.maps.LatLng(35.15309551946706, 126.90492302932213),
                new window.kakao.maps.LatLng(35.15330779504008, 126.90461479344388),
                new window.kakao.maps.LatLng(35.153380248004034, 126.90451593469315),
                new window.kakao.maps.LatLng(35.15399643199194, 126.9036747724336),
                new window.kakao.maps.LatLng(35.15405524432026, 126.90359479406823),
                new window.kakao.maps.LatLng(35.15427583497752, 126.90329648444876),
                new window.kakao.maps.LatLng(35.154541295631574, 126.90293313708968),
                new window.kakao.maps.LatLng(35.154713489307426, 126.90269920750758),
                new window.kakao.maps.LatLng(35.15477116413364, 126.90262094127085),
                new window.kakao.maps.LatLng(35.15546556412364, 126.90184344147706),
                new window.kakao.maps.LatLng(35.15665648857582, 126.90066712705207),
                new window.kakao.maps.LatLng(35.15681886871133, 126.90049784236561),
                new window.kakao.maps.LatLng(35.15792896665128, 126.89901461880356),
                new window.kakao.maps.LatLng(35.158072714904335, 126.89878876303052),
                new window.kakao.maps.LatLng(35.158495797608765, 126.89796832070823),
                new window.kakao.maps.LatLng(35.15902717690824, 126.8965080783117),
                new window.kakao.maps.LatLng(35.15958104984546, 126.89516131988128),
                new window.kakao.maps.LatLng(35.15993122138664, 126.89443148829947),
                new window.kakao.maps.LatLng(35.16058692282654, 126.8931259004157),
                new window.kakao.maps.LatLng(35.160753184973146, 126.89272934556192),]
            }
            ,
            {
                name: '남구', path: [new window.kakao.maps.LatLng(35.15233821092302, 126.90107370468607),
                new window.kakao.maps.LatLng(35.15243549480552, 126.90085718771576),
                new window.kakao.maps.LatLng(35.15247542255031, 126.90026964326127),
                new window.kakao.maps.LatLng(35.152405656872986, 126.89969819235557),
                new window.kakao.maps.LatLng(35.15232039645237, 126.89914236770956),
                new window.kakao.maps.LatLng(35.152319662205066, 126.89827622917181),
                new window.kakao.maps.LatLng(35.15244155952795, 126.89736501585683),
                new window.kakao.maps.LatLng(35.151612485146906, 126.89481775224236),
                new window.kakao.maps.LatLng(35.15144795500337, 126.89429769780648),
                new window.kakao.maps.LatLng(35.15105405684747, 126.89448718383565),
                new window.kakao.maps.LatLng(35.15085045092921, 126.89452705635712),
                new window.kakao.maps.LatLng(35.149294032902496, 126.89166036483348),
                new window.kakao.maps.LatLng(35.149193083420236, 126.8910797234902),
                new window.kakao.maps.LatLng(35.14903557998106, 126.89024847750322),
                new window.kakao.maps.LatLng(35.148860873793446, 126.88986975490177),
                new window.kakao.maps.LatLng(35.1488013275341, 126.88975169991373),
                new window.kakao.maps.LatLng(35.14829254559959, 126.88875357708989),
                new window.kakao.maps.LatLng(35.14798682270443, 126.88818368845723),
                new window.kakao.maps.LatLng(35.147509928051, 126.88725344830613),
                new window.kakao.maps.LatLng(35.14718848942643, 126.88660764263062),
                new window.kakao.maps.LatLng(35.1469679469132, 126.88617207984866),
                new window.kakao.maps.LatLng(35.14621891448209, 126.88566696903182),
                new window.kakao.maps.LatLng(35.14215196562191, 126.8877482090737),
                new window.kakao.maps.LatLng(35.14209919996419, 126.8877937225796),
                new window.kakao.maps.LatLng(35.141935842627994, 126.88751276054637),
                new window.kakao.maps.LatLng(35.14174996398442, 126.88708832413235),
                new window.kakao.maps.LatLng(35.14170226043722, 126.886979350021),
                new window.kakao.maps.LatLng(35.14157960949428, 126.88669935389284),
                new window.kakao.maps.LatLng(35.141479249637705, 126.88647026569349),
                new window.kakao.maps.LatLng(35.14132766650428, 126.88612430888152),
                new window.kakao.maps.LatLng(35.14121573379531, 126.8858686494906),
                new window.kakao.maps.LatLng(35.1413073266679, 126.88561784954503),
                new window.kakao.maps.LatLng(35.14100745800525, 126.8854425404549),
                new window.kakao.maps.LatLng(35.14080670907855, 126.88538949552684),
                new window.kakao.maps.LatLng(35.14026885222809, 126.88539009468917),
                new window.kakao.maps.LatLng(35.139853656864865, 126.8850856687536),
                new window.kakao.maps.LatLng(35.13978980376746, 126.88487607753282),
                new window.kakao.maps.LatLng(35.13962764922518, 126.88483086987952),
                new window.kakao.maps.LatLng(35.13940498194209, 126.88453543499041),
                new window.kakao.maps.LatLng(35.13915924335697, 126.88411178346122),
                new window.kakao.maps.LatLng(35.13840313879545, 126.88315720693136),
                new window.kakao.maps.LatLng(35.13799566526887, 126.883407600672),
                new window.kakao.maps.LatLng(35.137585805144525, 126.88411113340098),
                new window.kakao.maps.LatLng(35.13695501477399, 126.88515579192234),
                new window.kakao.maps.LatLng(35.13663003556974, 126.88566734336881),
                new window.kakao.maps.LatLng(35.135671367025246, 126.8859781546547),
                new window.kakao.maps.LatLng(35.13437660085024, 126.88651624940036),
                new window.kakao.maps.LatLng(35.13391749323171, 126.88713459382735),
                new window.kakao.maps.LatLng(35.13385120882721, 126.88718165827551),
                new window.kakao.maps.LatLng(35.133327089626675, 126.88726056639634),
                new window.kakao.maps.LatLng(35.13316102067689, 126.88739383435099),
                new window.kakao.maps.LatLng(35.132555013975285, 126.8875601139797),
                new window.kakao.maps.LatLng(35.13145936912255, 126.88703431182901),
                new window.kakao.maps.LatLng(35.13130060553357, 126.88684509477326),
                new window.kakao.maps.LatLng(35.13103523828524, 126.88680637353649),
                new window.kakao.maps.LatLng(35.13090323577002, 126.886771931591),
                new window.kakao.maps.LatLng(35.13080890616027, 126.8867957189402),
                new window.kakao.maps.LatLng(35.130175349592804, 126.88673009713858),
                new window.kakao.maps.LatLng(35.130071150104094, 126.88674429730341),
                new window.kakao.maps.LatLng(35.13000932939272, 126.88675964608467),
                new window.kakao.maps.LatLng(35.129401194325375, 126.88690278849015),
                new window.kakao.maps.LatLng(35.12916027906618, 126.88695987041818),
                new window.kakao.maps.LatLng(35.128843055599994, 126.88675064889739),
                new window.kakao.maps.LatLng(35.128811506649626, 126.88674915762569),
                new window.kakao.maps.LatLng(35.12636238932592, 126.88724766342297),
                new window.kakao.maps.LatLng(35.125708802025294, 126.88728045749971),
                new window.kakao.maps.LatLng(35.125238823459874, 126.88788605316059),
                new window.kakao.maps.LatLng(35.1249480359913, 126.88807862447482),
                new window.kakao.maps.LatLng(35.12458206520932, 126.88814272976965),
                new window.kakao.maps.LatLng(35.12283815262421, 126.88837535218342),
                new window.kakao.maps.LatLng(35.12239376571375, 126.88832830988689),
                new window.kakao.maps.LatLng(35.120528019738664, 126.88840117613842),
                new window.kakao.maps.LatLng(35.120118442904946, 126.88838511028108),
                new window.kakao.maps.LatLng(35.119345284567395, 126.88767311516982),
                new window.kakao.maps.LatLng(35.11847816951827, 126.88769454990036),
                new window.kakao.maps.LatLng(35.11802065027321, 126.8883592116395),
                new window.kakao.maps.LatLng(35.11741906235701, 126.8881370463925),
                new window.kakao.maps.LatLng(35.11700300842072, 126.88765872083252),
                new window.kakao.maps.LatLng(35.11697317872121, 126.88664130559711),
                new window.kakao.maps.LatLng(35.11553218801475, 126.88377149458236),
                new window.kakao.maps.LatLng(35.11651740247395, 126.88024739428673),
                new window.kakao.maps.LatLng(35.11702166963614, 126.87850500168688),
                new window.kakao.maps.LatLng(35.11770243806957, 126.8766661680963),
                new window.kakao.maps.LatLng(35.11843311915901, 126.87532773584903),
                new window.kakao.maps.LatLng(35.119955805791896, 126.87433856840808),
                new window.kakao.maps.LatLng(35.118588575117336, 126.8735448553441),
                new window.kakao.maps.LatLng(35.11816953873705, 126.87284497412634),
                new window.kakao.maps.LatLng(35.11776485372571, 126.87265671778627),
                new window.kakao.maps.LatLng(35.11744523024694, 126.87180138957427),
                new window.kakao.maps.LatLng(35.11723749569486, 126.87180308983339),
                new window.kakao.maps.LatLng(35.11633142054928, 126.87069246186489),
                new window.kakao.maps.LatLng(35.11609392959737, 126.87025764812714),
                new window.kakao.maps.LatLng(35.11601430609281, 126.86969678309576),
                new window.kakao.maps.LatLng(35.11581102504203, 126.8695149026255),
                new window.kakao.maps.LatLng(35.11543062321294, 126.8696486990992),
                new window.kakao.maps.LatLng(35.114192208530724, 126.87065406282969),
                new window.kakao.maps.LatLng(35.11385110027597, 126.87092592659549),
                new window.kakao.maps.LatLng(35.11039043070244, 126.87026267408204),
                new window.kakao.maps.LatLng(35.110269345306406, 126.86784694967335),
                new window.kakao.maps.LatLng(35.11026907917616, 126.86760563249597),
                new window.kakao.maps.LatLng(35.1104577038392, 126.86712817232473),
                new window.kakao.maps.LatLng(35.11053352577852, 126.86629406316348),
                new window.kakao.maps.LatLng(35.10922157733056, 126.86424625039713),
                new window.kakao.maps.LatLng(35.1092083818264, 126.86390418784686),
                new window.kakao.maps.LatLng(35.10962406837029, 126.86322769541256),
                new window.kakao.maps.LatLng(35.10944968458359, 126.86270903106933),
                new window.kakao.maps.LatLng(35.109352296812425, 126.8624077164967),
                new window.kakao.maps.LatLng(35.10915989551896, 126.86178299514765),
                new window.kakao.maps.LatLng(35.109030384827236, 126.86159109776422),
                new window.kakao.maps.LatLng(35.10894497828531, 126.86141848498193),
                new window.kakao.maps.LatLng(35.108981915732905, 126.86132278483221),
                new window.kakao.maps.LatLng(35.10909193553758, 126.86110612630789),
                new window.kakao.maps.LatLng(35.10914808572191, 126.86070550370918),
                new window.kakao.maps.LatLng(35.109127541345785, 126.8604784513392),
                new window.kakao.maps.LatLng(35.108827840340076, 126.85999222735046),
                new window.kakao.maps.LatLng(35.108626298474434, 126.8597454269301),
                new window.kakao.maps.LatLng(35.10833148809285, 126.8594756683334),
                new window.kakao.maps.LatLng(35.108156283302826, 126.85983793914613),
                new window.kakao.maps.LatLng(35.10805416697602, 126.8605485020836),
                new window.kakao.maps.LatLng(35.10783694352427, 126.86099705563416),
                new window.kakao.maps.LatLng(35.10752172264375, 126.86121782415961),
                new window.kakao.maps.LatLng(35.10728349039944, 126.86127033289836),
                new window.kakao.maps.LatLng(35.106345614762674, 126.860632170274),
                new window.kakao.maps.LatLng(35.106185924972905, 126.85670128092906),
                new window.kakao.maps.LatLng(35.1065857534631, 126.85435160684133),
                new window.kakao.maps.LatLng(35.10636091463874, 126.85360770280721),
                new window.kakao.maps.LatLng(35.10591678264183, 126.85080522371106),
                new window.kakao.maps.LatLng(35.10553341134529, 126.84899289447193),
                new window.kakao.maps.LatLng(35.1053520946001, 126.84672364529015),
                new window.kakao.maps.LatLng(35.104607173403366, 126.84581502985418),
                new window.kakao.maps.LatLng(35.10436122105561, 126.84381397920619),
                new window.kakao.maps.LatLng(35.10282189641517, 126.84297771551833),
                new window.kakao.maps.LatLng(35.1017445967154, 126.8422005681848),
                new window.kakao.maps.LatLng(35.101498377372955, 126.84184802679063),
                new window.kakao.maps.LatLng(35.101203725674715, 126.8414061169089),
                new window.kakao.maps.LatLng(35.101177338835065, 126.84041615275589),
                new window.kakao.maps.LatLng(35.101202545172825, 126.84020103623362),
                new window.kakao.maps.LatLng(35.10128945157808, 126.83946842678093),
                new window.kakao.maps.LatLng(35.10158356782769, 126.83897223098242),
                new window.kakao.maps.LatLng(35.10221660939186, 126.83811839708314),
                new window.kakao.maps.LatLng(35.10169626655782, 126.83763376828169),
                new window.kakao.maps.LatLng(35.10135972499161, 126.83747352211869),
                new window.kakao.maps.LatLng(35.098946059342616, 126.83664997086483),
                new window.kakao.maps.LatLng(35.09782913903404, 126.83361546855238),
                new window.kakao.maps.LatLng(35.09794520010804, 126.83291469283269),
                new window.kakao.maps.LatLng(35.098058838652236, 126.83149007566774),
                new window.kakao.maps.LatLng(35.09678359932911, 126.83052093153418),
                new window.kakao.maps.LatLng(35.096002629693864, 126.82970242156995),
                new window.kakao.maps.LatLng(35.095165799851834, 126.82863336324706),
                new window.kakao.maps.LatLng(35.09488151970037, 126.82829244168806),
                new window.kakao.maps.LatLng(35.09482877594724, 126.82824354212669),
                new window.kakao.maps.LatLng(35.094630992161306, 126.82830822672277),
                new window.kakao.maps.LatLng(35.09443739742429, 126.82805073874397),
                new window.kakao.maps.LatLng(35.09441996279052, 126.8279734922685),
                new window.kakao.maps.LatLng(35.094417714893936, 126.82748650359748),
                new window.kakao.maps.LatLng(35.09429336762276, 126.82700542169997),
                new window.kakao.maps.LatLng(35.09392080483692, 126.82667927373902),
                new window.kakao.maps.LatLng(35.09369988751556, 126.82652140645635),
                new window.kakao.maps.LatLng(35.09322162767665, 126.82576282023435),
                new window.kakao.maps.LatLng(35.09320333144757, 126.82489494415637),
                new window.kakao.maps.LatLng(35.09303326271736, 126.82464736587282),
                new window.kakao.maps.LatLng(35.09238600598668, 126.82361395570494),
                new window.kakao.maps.LatLng(35.09194258954053, 126.82300027766102),
                new window.kakao.maps.LatLng(35.091864770177516, 126.82266049674315),
                new window.kakao.maps.LatLng(35.09168702948595, 126.82189716768708),
                new window.kakao.maps.LatLng(35.09190185228041, 126.81843624641465),
                new window.kakao.maps.LatLng(35.09285284005856, 126.8171719269585),
                new window.kakao.maps.LatLng(35.09358630863351, 126.81550144667122),
                new window.kakao.maps.LatLng(35.09349900109711, 126.81504944340516),
                new window.kakao.maps.LatLng(35.09345243134955, 126.81489327359847),
                new window.kakao.maps.LatLng(35.09343221384391, 126.81484671108697),
                new window.kakao.maps.LatLng(35.09322903964172, 126.81451439999952),
                new window.kakao.maps.LatLng(35.093227170230264, 126.81448869840798),
                new window.kakao.maps.LatLng(35.09341238161549, 126.81422421915443),
                new window.kakao.maps.LatLng(35.093294532176316, 126.81424522707513),
                new window.kakao.maps.LatLng(35.09294358205354, 126.81397974765966),
                new window.kakao.maps.LatLng(35.09253865405802, 126.81351390238646),
                new window.kakao.maps.LatLng(35.09225181324303, 126.81318248259086),
                new window.kakao.maps.LatLng(35.09168739010127, 126.81240104680978),
                new window.kakao.maps.LatLng(35.091067123713174, 126.81148798971394),
                new window.kakao.maps.LatLng(35.09095255231851, 126.8113561433407),
                new window.kakao.maps.LatLng(35.09109537634837, 126.8110938137155),
                new window.kakao.maps.LatLng(35.091174148253394, 126.8104070229942),
                new window.kakao.maps.LatLng(35.091329083088176, 126.81032665106628),
                new window.kakao.maps.LatLng(35.091625650165795, 126.81012136864582),
                new window.kakao.maps.LatLng(35.091827018292165, 126.80992384140137),
                new window.kakao.maps.LatLng(35.092271288284735, 126.80960819359844),
                new window.kakao.maps.LatLng(35.09246032330802, 126.80954092064991),
                new window.kakao.maps.LatLng(35.09345284179503, 126.80893885931069),
                new window.kakao.maps.LatLng(35.097559234814184, 126.80654845629789),
                new window.kakao.maps.LatLng(35.09724888443241, 126.80632264425192),
                new window.kakao.maps.LatLng(35.09722704244112, 126.80622622902646),
                new window.kakao.maps.LatLng(35.09692101816838, 126.8059723114094),
                new window.kakao.maps.LatLng(35.096783135562056, 126.80579853671252),
                new window.kakao.maps.LatLng(35.09677239046327, 126.80577456568382),
                new window.kakao.maps.LatLng(35.096719068253876, 126.80572122817348),
                new window.kakao.maps.LatLng(35.09656581845279, 126.80553104037507),
                new window.kakao.maps.LatLng(35.095959540801175, 126.80520571649754),
                new window.kakao.maps.LatLng(35.0959248870075, 126.80519654338951),
                new window.kakao.maps.LatLng(35.095732177587564, 126.8052592099903),
                new window.kakao.maps.LatLng(35.0956351018546, 126.80532113191366),
                new window.kakao.maps.LatLng(35.09539526127553, 126.80532838511765),
                new window.kakao.maps.LatLng(35.095041329392515, 126.8051482780893),
                new window.kakao.maps.LatLng(35.09472600545773, 126.80463633163627),
                new window.kakao.maps.LatLng(35.09459090104273, 126.8045199571775),
                new window.kakao.maps.LatLng(35.09449222734735, 126.80446930866697),
                new window.kakao.maps.LatLng(35.0937842631453, 126.80370607925349),
                new window.kakao.maps.LatLng(35.09387573830872, 126.80357648345142),
                new window.kakao.maps.LatLng(35.093856335998346, 126.80351261593621),
                new window.kakao.maps.LatLng(35.09370134410732, 126.80346654571109),
                new window.kakao.maps.LatLng(35.0935743843973, 126.80334365281013),
                new window.kakao.maps.LatLng(35.09355747999089, 126.80317027621818),
                new window.kakao.maps.LatLng(35.09347727430101, 126.8025248160242),
                new window.kakao.maps.LatLng(35.09161432345953, 126.80221370183163),
                new window.kakao.maps.LatLng(35.08799031442004, 126.7959854341078),
                new window.kakao.maps.LatLng(35.08439673111498, 126.79402528074722),
                new window.kakao.maps.LatLng(35.07561569227977, 126.78662294055398),
                new window.kakao.maps.LatLng(35.07160066536728, 126.77965331018483),
                new window.kakao.maps.LatLng(35.07017020703061, 126.7713530997216),
                new window.kakao.maps.LatLng(35.06818125234396, 126.76699767789097),
                new window.kakao.maps.LatLng(35.06837527026988, 126.7569786690736),
                new window.kakao.maps.LatLng(35.06444848108735, 126.75333359367923),
                new window.kakao.maps.LatLng(35.05998956860997, 126.75850036004597),
                new window.kakao.maps.LatLng(35.06245143448809, 126.75963299879429),
                new window.kakao.maps.LatLng(35.06436185411208, 126.76510877377618),
                new window.kakao.maps.LatLng(35.06019829682237, 126.76863177387975),
                new window.kakao.maps.LatLng(35.05635811477433, 126.76734404540493),
                new window.kakao.maps.LatLng(35.05397138362164, 126.76921070329762),
                new window.kakao.maps.LatLng(35.05280303046447, 126.77610334670435),
                new window.kakao.maps.LatLng(35.05556004913988, 126.78506308928165),
                new window.kakao.maps.LatLng(35.06048816166237, 126.79156751950312),
                new window.kakao.maps.LatLng(35.06112285648283, 126.79489572159424),
                new window.kakao.maps.LatLng(35.0594173852821, 126.79921140212491),
                new window.kakao.maps.LatLng(35.055152396519134, 126.80306822941597),
                new window.kakao.maps.LatLng(35.05237098406802, 126.80367730659805),
                new window.kakao.maps.LatLng(35.05220975682779, 126.8085091964308),
                new window.kakao.maps.LatLng(35.053412644076445, 126.81534864837839),
                new window.kakao.maps.LatLng(35.0528108684634, 126.81861323217724),
                new window.kakao.maps.LatLng(35.05994821574632, 126.82185927109485),
                new window.kakao.maps.LatLng(35.062191314363545, 126.82675513063049),
                new window.kakao.maps.LatLng(35.061966159317414, 126.82984155702793),
                new window.kakao.maps.LatLng(35.063856829097965, 126.83596509548066),
                new window.kakao.maps.LatLng(35.06623582632334, 126.83695846238798),
                new window.kakao.maps.LatLng(35.0653812318335, 126.84397733233382),
                new window.kakao.maps.LatLng(35.071947858507855, 126.84818408196693),
                new window.kakao.maps.LatLng(35.07380690949711, 126.85338290758722),
                new window.kakao.maps.LatLng(35.07835952970425, 126.85560924809626),
                new window.kakao.maps.LatLng(35.07894193628355, 126.86113104392844),
                new window.kakao.maps.LatLng(35.07675191349381, 126.8623660722856),
                new window.kakao.maps.LatLng(35.074337826585705, 126.87167669940928),
                new window.kakao.maps.LatLng(35.07887200870837, 126.88126112787366),
                new window.kakao.maps.LatLng(35.08159026749211, 126.88540282227525),
                new window.kakao.maps.LatLng(35.07794529046822, 126.89197650292716),
                new window.kakao.maps.LatLng(35.08071413990702, 126.8951053715901),
                new window.kakao.maps.LatLng(35.08139050806721, 126.90453184385079),
                new window.kakao.maps.LatLng(35.0874312776291, 126.91071144714185),
                new window.kakao.maps.LatLng(35.08865933704928, 126.91064480621677),
                new window.kakao.maps.LatLng(35.091531661290006, 126.91922340854023),
                new window.kakao.maps.LatLng(35.09303742617227, 126.91974367029573),
                new window.kakao.maps.LatLng(35.094729820444535, 126.92050110094998),
                new window.kakao.maps.LatLng(35.09519836273228, 126.92066925520486),
                new window.kakao.maps.LatLng(35.09615004071955, 126.92072384383759),
                new window.kakao.maps.LatLng(35.09713898183358, 126.92132384068535),
                new window.kakao.maps.LatLng(35.098653329299864, 126.92013684590891),
                new window.kakao.maps.LatLng(35.09868779282684, 126.91903492427637),
                new window.kakao.maps.LatLng(35.0989030485084, 126.91828412709883),
                new window.kakao.maps.LatLng(35.10243606276291, 126.91740310489541),
                new window.kakao.maps.LatLng(35.10460516641507, 126.91488194783746),
                new window.kakao.maps.LatLng(35.10529158668109, 126.91446186006384),
                new window.kakao.maps.LatLng(35.105571837484106, 126.91443585196012),
                new window.kakao.maps.LatLng(35.1071247174958, 126.91385389962026),
                new window.kakao.maps.LatLng(35.10969815624788, 126.91295632490241),
                new window.kakao.maps.LatLng(35.11169999434014, 126.9123354224074),
                new window.kakao.maps.LatLng(35.11307529823579, 126.91294838533747),
                new window.kakao.maps.LatLng(35.113741619848454, 126.91355509857023),
                new window.kakao.maps.LatLng(35.11455471958066, 126.91322050965319),
                new window.kakao.maps.LatLng(35.114661974461036, 126.91295386629017),
                new window.kakao.maps.LatLng(35.115155473591365, 126.91218203585026),
                new window.kakao.maps.LatLng(35.11558195894842, 126.91183670484313),
                new window.kakao.maps.LatLng(35.115889915051326, 126.91273057775923),
                new window.kakao.maps.LatLng(35.1164305236611, 126.91379303207523),
                new window.kakao.maps.LatLng(35.11653147284906, 126.91413847355156),
                new window.kakao.maps.LatLng(35.11661416724091, 126.91475903692015),
                new window.kakao.maps.LatLng(35.11705234396053, 126.9154020346597),
                new window.kakao.maps.LatLng(35.11688349736872, 126.91702523807713),
                new window.kakao.maps.LatLng(35.11701264403323, 126.91763546919404),
                new window.kakao.maps.LatLng(35.117167876785146, 126.91829076257892),
                new window.kakao.maps.LatLng(35.117999360388666, 126.91950399247223),
                new window.kakao.maps.LatLng(35.11842190383473, 126.91954967677346),
                new window.kakao.maps.LatLng(35.11928262815957, 126.92005499203786),
                new window.kakao.maps.LatLng(35.11940659975261, 126.92053739344902),
                new window.kakao.maps.LatLng(35.11949131925573, 126.92064666043272),
                new window.kakao.maps.LatLng(35.119704876689795, 126.92136466176207),
                new window.kakao.maps.LatLng(35.119778705506405, 126.9216211834863),
                new window.kakao.maps.LatLng(35.12065645323688, 126.92232809248463),
                new window.kakao.maps.LatLng(35.120851952484365, 126.92258193433052),
                new window.kakao.maps.LatLng(35.12098034205736, 126.92270677530473),
                new window.kakao.maps.LatLng(35.121979614369465, 126.92275895529744),
                new window.kakao.maps.LatLng(35.12256460165369, 126.9228917520095),
                new window.kakao.maps.LatLng(35.12274314729172, 126.92305168640392),
                new window.kakao.maps.LatLng(35.123072824527384, 126.9234653386399),
                new window.kakao.maps.LatLng(35.12367771435974, 126.9245459010189),
                new window.kakao.maps.LatLng(35.12376378673222, 126.92459159028024),
                new window.kakao.maps.LatLng(35.12550020507843, 126.92712701051074),
                new window.kakao.maps.LatLng(35.12581633230525, 126.92866164183474),
                new window.kakao.maps.LatLng(35.126340328500625, 126.92950750137524),
                new window.kakao.maps.LatLng(35.126465659142255, 126.92962392429263),
                new window.kakao.maps.LatLng(35.126663254069854, 126.92937662527859),
                new window.kakao.maps.LatLng(35.12845084260248, 126.92739717865257),
                new window.kakao.maps.LatLng(35.128473782904564, 126.92739861503588),
                new window.kakao.maps.LatLng(35.1289809083135, 126.92743020609771),
                new window.kakao.maps.LatLng(35.13061248822251, 126.92603495987424),
                new window.kakao.maps.LatLng(35.13114430404252, 126.92582669750206),
                new window.kakao.maps.LatLng(35.131784278187254, 126.92561163815272),
                new window.kakao.maps.LatLng(35.13193731603261, 126.92553349768052),
                new window.kakao.maps.LatLng(35.13218426984786, 126.9254178871823),
                new window.kakao.maps.LatLng(35.13297326229711, 126.92477049580397),
                new window.kakao.maps.LatLng(35.133790094372394, 126.92408053650522),
                new window.kakao.maps.LatLng(35.13413625706114, 126.92361011674103),
                new window.kakao.maps.LatLng(35.13430209393392, 126.92328182394212),
                new window.kakao.maps.LatLng(35.13489086879105, 126.92252897976408),
                new window.kakao.maps.LatLng(35.137093807960504, 126.92098451441156),
                new window.kakao.maps.LatLng(35.13777938820622, 126.92053602365615),
                new window.kakao.maps.LatLng(35.13835362072776, 126.91996469693872),
                new window.kakao.maps.LatLng(35.138895376603074, 126.91947638213517),
                new window.kakao.maps.LatLng(35.13931391384637, 126.91943789715344),
                new window.kakao.maps.LatLng(35.13997488768814, 126.9188534400325),
                new window.kakao.maps.LatLng(35.14078394539021, 126.91815515194894),
                new window.kakao.maps.LatLng(35.141376386785126, 126.91766761463614),
                new window.kakao.maps.LatLng(35.142161733797835, 126.91690759198876),
                new window.kakao.maps.LatLng(35.142497546455274, 126.91658971005107),
                new window.kakao.maps.LatLng(35.142582954489846, 126.91648022845237),
                new window.kakao.maps.LatLng(35.14287272886968, 126.91597652447801),
                new window.kakao.maps.LatLng(35.14305130082136, 126.91576544043822),
                new window.kakao.maps.LatLng(35.14356168065804, 126.91514970047535),
                new window.kakao.maps.LatLng(35.144833305781454, 126.91359491100694),
                new window.kakao.maps.LatLng(35.14520527784749, 126.91323563931782),
                new window.kakao.maps.LatLng(35.14543625012745, 126.9130375232349),
                new window.kakao.maps.LatLng(35.14589012774098, 126.9126056203454),
                new window.kakao.maps.LatLng(35.146500127278216, 126.91207564563406),
                new window.kakao.maps.LatLng(35.14657865643468, 126.91200697319489),
                new window.kakao.maps.LatLng(35.146917012619966, 126.9117085957235),
                new window.kakao.maps.LatLng(35.14750956124016, 126.91121016505791),
                new window.kakao.maps.LatLng(35.14821075410722, 126.91059364732988),
                new window.kakao.maps.LatLng(35.14835980724019, 126.91046813560955),
                new window.kakao.maps.LatLng(35.14857696598521, 126.91027258749405),
                new window.kakao.maps.LatLng(35.14872996170195, 126.91013764333451),
                new window.kakao.maps.LatLng(35.14880694916275, 126.91006948463324),
                new window.kakao.maps.LatLng(35.148958523591006, 126.90992939447946),
                new window.kakao.maps.LatLng(35.14914725836389, 126.90976233101075),
                new window.kakao.maps.LatLng(35.14902667411239, 126.90954744948368),
                new window.kakao.maps.LatLng(35.14900823965011, 126.9095565568599),
                new window.kakao.maps.LatLng(35.14893087603369, 126.90912916681596),
                new window.kakao.maps.LatLng(35.14889272476519, 126.90860210957554),
                new window.kakao.maps.LatLng(35.14884856434852, 126.9079418224907),
                new window.kakao.maps.LatLng(35.14878256965153, 126.90747874437142),
                new window.kakao.maps.LatLng(35.148634617120564, 126.90683642326832),
                new window.kakao.maps.LatLng(35.14845994009262, 126.90607580091648),
                new window.kakao.maps.LatLng(35.14836602770349, 126.90574137599727),
                new window.kakao.maps.LatLng(35.14829695610466, 126.90548956925555),
                new window.kakao.maps.LatLng(35.148636050806694, 126.90455517371603),
                new window.kakao.maps.LatLng(35.14871281427033, 126.90438017631287),
                new window.kakao.maps.LatLng(35.14886255980381, 126.90408078848444),
                new window.kakao.maps.LatLng(35.14888815707081, 126.90403445805691),
                new window.kakao.maps.LatLng(35.14893695775242, 126.90394643036876),
                new window.kakao.maps.LatLng(35.14896156595952, 126.90390302074132),
                new window.kakao.maps.LatLng(35.14912529970477, 126.90364904706078),
                new window.kakao.maps.LatLng(35.14915890306968, 126.90358298548776),
                new window.kakao.maps.LatLng(35.14963982897361, 126.90302752946027),
                new window.kakao.maps.LatLng(35.150005341986436, 126.90273370198598),
                new window.kakao.maps.LatLng(35.15119686911803, 126.9020899047757),
                new window.kakao.maps.LatLng(35.15128118855298, 126.90204299661951),
                new window.kakao.maps.LatLng(35.15134861417818, 126.90200415223732),
                new window.kakao.maps.LatLng(35.15216350106435, 126.90132410725633),
                new window.kakao.maps.LatLng(35.15233821092302, 126.90107370468607),]
            }
            ,
            {
                name: '북구', path: [new window.kakao.maps.LatLng(35.12314613771649, 126.99721180340507),
                new window.kakao.maps.LatLng(35.12129063688342, 127.0043180211158),
                new window.kakao.maps.LatLng(35.124313971860886, 127.0106080378886),
                new window.kakao.maps.LatLng(35.12781621296243, 127.01183208722506),
                new window.kakao.maps.LatLng(35.13349598386704, 127.00868038053872),
                new window.kakao.maps.LatLng(35.14178266817931, 127.00927933089548),
                new window.kakao.maps.LatLng(35.14387210376763, 127.0071840974279),
                new window.kakao.maps.LatLng(35.149622469215856, 127.00966537913938),
                new window.kakao.maps.LatLng(35.15549108694287, 127.00819000033376),
                new window.kakao.maps.LatLng(35.16131696798441, 127.01172846721221),
                new window.kakao.maps.LatLng(35.16616909344103, 127.01135845785427),
                new window.kakao.maps.LatLng(35.165331960393964, 127.01681121357845),
                new window.kakao.maps.LatLng(35.16725731642618, 127.02087241599426),
                new window.kakao.maps.LatLng(35.171359644780885, 127.02227522383045),
                new window.kakao.maps.LatLng(35.17563384299294, 127.01579514098506),
                new window.kakao.maps.LatLng(35.18038352424181, 127.01338536038706),
                new window.kakao.maps.LatLng(35.185227060975066, 127.00700036519422),
                new window.kakao.maps.LatLng(35.18881609526294, 126.99996890830442),
                new window.kakao.maps.LatLng(35.189030385010525, 126.9970876354197),
                new window.kakao.maps.LatLng(35.18647316609453, 126.9931426187598),
                new window.kakao.maps.LatLng(35.18704861942781, 126.98388717476696),
                new window.kakao.maps.LatLng(35.18401568704369, 126.98276489453924),
                new window.kakao.maps.LatLng(35.18620729257677, 126.97822243580077),
                new window.kakao.maps.LatLng(35.18495238946582, 126.97212053416564),
                new window.kakao.maps.LatLng(35.17983725501171, 126.97169473165464),
                new window.kakao.maps.LatLng(35.18114273228135, 126.96814715573811),
                new window.kakao.maps.LatLng(35.18431497953897, 126.96634909432696),
                new window.kakao.maps.LatLng(35.19050677842392, 126.95954618207458),
                new window.kakao.maps.LatLng(35.19286351434381, 126.95870809321569),
                new window.kakao.maps.LatLng(35.2002581745295, 126.96450583136559),
                new window.kakao.maps.LatLng(35.20335954457376, 126.965535655131),
                new window.kakao.maps.LatLng(35.204429322105234, 126.96105674133177),
                new window.kakao.maps.LatLng(35.21058738512503, 126.95412684279215),
                new window.kakao.maps.LatLng(35.21501967742294, 126.95079296532882),
                new window.kakao.maps.LatLng(35.220904816710515, 126.94895758791213),
                new window.kakao.maps.LatLng(35.22459112584544, 126.95184100155869),
                new window.kakao.maps.LatLng(35.23077336887449, 126.94757293422886),
                new window.kakao.maps.LatLng(35.231739455587146, 126.94353055590965),
                new window.kakao.maps.LatLng(35.23549545202104, 126.94280213077927),
                new window.kakao.maps.LatLng(35.240652338828944, 126.93228483976144),
                new window.kakao.maps.LatLng(35.24677835422629, 126.93377444561953),
                new window.kakao.maps.LatLng(35.250810281523684, 126.93125022178751),
                new window.kakao.maps.LatLng(35.253045479495825, 126.92521595616338),
                new window.kakao.maps.LatLng(35.25339500968726, 126.92104989111735),
                new window.kakao.maps.LatLng(35.25820163200159, 126.91466128577956),
                new window.kakao.maps.LatLng(35.25824229841328, 126.90546612690378),
                new window.kakao.maps.LatLng(35.25382113587445, 126.90016467463573),
                new window.kakao.maps.LatLng(35.24943594733897, 126.89095539458303),
                new window.kakao.maps.LatLng(35.24665774756066, 126.88962248936345),
                new window.kakao.maps.LatLng(35.246221862951245, 126.88632123964362),
                new window.kakao.maps.LatLng(35.2500577566759, 126.87420883486746),
                new window.kakao.maps.LatLng(35.246127644571146, 126.86871276966642),
                new window.kakao.maps.LatLng(35.2457301787815, 126.8635193975048),
                new window.kakao.maps.LatLng(35.24054212617475, 126.86023016679023),
                new window.kakao.maps.LatLng(35.238489475703226, 126.85237394445606),
                new window.kakao.maps.LatLng(35.23574093301306, 126.84807441151855),
                new window.kakao.maps.LatLng(35.23439442063617, 126.8408065041036),
                new window.kakao.maps.LatLng(35.22982418962609, 126.83848461674712),
                new window.kakao.maps.LatLng(35.22992779502881, 126.83533770763727),
                new window.kakao.maps.LatLng(35.22405820913587, 126.83864409081541),
                new window.kakao.maps.LatLng(35.22588848930511, 126.84472774564452),
                new window.kakao.maps.LatLng(35.22196193657691, 126.84619427429314),
                new window.kakao.maps.LatLng(35.22193347971141, 126.85304369822393),
                new window.kakao.maps.LatLng(35.21073054864014, 126.85264692515196),
                new window.kakao.maps.LatLng(35.20579740805543, 126.85510829272135),
                new window.kakao.maps.LatLng(35.20136135811701, 126.85532471640926),
                new window.kakao.maps.LatLng(35.196735382823, 126.85270401614949),
                new window.kakao.maps.LatLng(35.194919862209325, 126.85842997393611),
                new window.kakao.maps.LatLng(35.18570378294124, 126.86040913964061),
                new window.kakao.maps.LatLng(35.183175059729905, 126.85871008597829),
                new window.kakao.maps.LatLng(35.18241897767512, 126.85325843670782),
                new window.kakao.maps.LatLng(35.17981394627118, 126.85086664251621),
                new window.kakao.maps.LatLng(35.17957233900679, 126.84711337229561),
                new window.kakao.maps.LatLng(35.182210718766754, 126.84477645768861),
                new window.kakao.maps.LatLng(35.18186305771641, 126.84138216062297),
                new window.kakao.maps.LatLng(35.17843443057894, 126.8387498497354),
                new window.kakao.maps.LatLng(35.17839418310836, 126.83930071276563),
                new window.kakao.maps.LatLng(35.178362642948976, 126.83941415740746),
                new window.kakao.maps.LatLng(35.17831777140931, 126.83945250566082),
                new window.kakao.maps.LatLng(35.1783930398616, 126.83960553343596),
                new window.kakao.maps.LatLng(35.17850095048454, 126.83973448889549),
                new window.kakao.maps.LatLng(35.17848568840904, 126.83990844520335),
                new window.kakao.maps.LatLng(35.17860731012988, 126.84018094571182),
                new window.kakao.maps.LatLng(35.178386244282116, 126.84064023469655),
                new window.kakao.maps.LatLng(35.17775551217006, 126.84186604417225),
                new window.kakao.maps.LatLng(35.177732361457736, 126.84193144099727),
                new window.kakao.maps.LatLng(35.1772395969034, 126.84248285023159),
                new window.kakao.maps.LatLng(35.17699954877371, 126.84254300395041),
                new window.kakao.maps.LatLng(35.176917799420025, 126.84324197373388),
                new window.kakao.maps.LatLng(35.17687689746604, 126.84330552776142),
                new window.kakao.maps.LatLng(35.17624373734655, 126.84290743509631),
                new window.kakao.maps.LatLng(35.17237384470287, 126.85957955977472),
                new window.kakao.maps.LatLng(35.17182429868611, 126.86807728398506),
                new window.kakao.maps.LatLng(35.17170381598829, 126.87026617444346),
                new window.kakao.maps.LatLng(35.171551384853025, 126.87275914555505),
                new window.kakao.maps.LatLng(35.1715423424264, 126.8729018962998),
                new window.kakao.maps.LatLng(35.17136834396039, 126.87564807967131),
                new window.kakao.maps.LatLng(35.171193880765664, 126.87564212410587),
                new window.kakao.maps.LatLng(35.17062153072773, 126.87564118517079),
                new window.kakao.maps.LatLng(35.17067204544299, 126.87586991375356),
                new window.kakao.maps.LatLng(35.170728131975565, 126.87618096296374),
                new window.kakao.maps.LatLng(35.17075715767867, 126.87633133769411),
                new window.kakao.maps.LatLng(35.17078745666876, 126.87648427865278),
                new window.kakao.maps.LatLng(35.17092203045264, 126.87709741952091),
                new window.kakao.maps.LatLng(35.17098400145838, 126.87738376238075),
                new window.kakao.maps.LatLng(35.17098721440224, 126.87749352881585),
                new window.kakao.maps.LatLng(35.17099560895096, 126.87771683546985),
                new window.kakao.maps.LatLng(35.17100166363992, 126.87784495136876),
                new window.kakao.maps.LatLng(35.17100259797973, 126.87794117541164),
                new window.kakao.maps.LatLng(35.17099956497927, 126.8781382525017),
                new window.kakao.maps.LatLng(35.170986780046306, 126.87844493938196),
                new window.kakao.maps.LatLng(35.170985221421205, 126.87858095993677),
                new window.kakao.maps.LatLng(35.170984478399724, 126.87867289452859),
                new window.kakao.maps.LatLng(35.17098454528774, 126.87873892136354),
                new window.kakao.maps.LatLng(35.170983379344094, 126.8788425258199),
                new window.kakao.maps.LatLng(35.170982413094755, 126.87900117977695),
                new window.kakao.maps.LatLng(35.170980848303664, 126.87913171108866),
                new window.kakao.maps.LatLng(35.17098033034836, 126.87931522705968),
                new window.kakao.maps.LatLng(35.17095279853321, 126.87952554613233),
                new window.kakao.maps.LatLng(35.17094162086036, 126.87961424745752),
                new window.kakao.maps.LatLng(35.17092472426445, 126.87975045491379),
                new window.kakao.maps.LatLng(35.170904389530264, 126.87998580120127),
                new window.kakao.maps.LatLng(35.17089004564292, 126.88014225786057),
                new window.kakao.maps.LatLng(35.17087144050017, 126.88026715018549),
                new window.kakao.maps.LatLng(35.170835444096376, 126.88047130053867),
                new window.kakao.maps.LatLng(35.17081906034419, 126.88056446608269),
                new window.kakao.maps.LatLng(35.170734990019966, 126.88099544158291),
                new window.kakao.maps.LatLng(35.17070695692119, 126.88113045682333),
                new window.kakao.maps.LatLng(35.170675133466, 126.8812812745342),
                new window.kakao.maps.LatLng(35.17065243039976, 126.88139022255473),
                new window.kakao.maps.LatLng(35.1706295353772, 126.8814531982277),
                new window.kakao.maps.LatLng(35.17060694374436, 126.8815425964008),
                new window.kakao.maps.LatLng(35.170570055419645, 126.88169032517929),
                new window.kakao.maps.LatLng(35.17053248262145, 126.88185931687184),
                new window.kakao.maps.LatLng(35.17048954513432, 126.88202884420105),
                new window.kakao.maps.LatLng(35.17047649141556, 126.88207243118352),
                new window.kakao.maps.LatLng(35.17041163298706, 126.88228588643095),
                new window.kakao.maps.LatLng(35.17029388186274, 126.88269838924488),
                new window.kakao.maps.LatLng(35.170270726007494, 126.88278109102582),
                new window.kakao.maps.LatLng(35.17021848937246, 126.88294564613118),
                new window.kakao.maps.LatLng(35.17019462709263, 126.88302510003514),
                new window.kakao.maps.LatLng(35.17006939218302, 126.88341908176851),
                new window.kakao.maps.LatLng(35.170041009151525, 126.88349578560545),
                new window.kakao.maps.LatLng(35.17000124540685, 126.88358109097089),
                new window.kakao.maps.LatLng(35.16996821254475, 126.88364768063428),
                new window.kakao.maps.LatLng(35.16986420017085, 126.88386703056246),
                new window.kakao.maps.LatLng(35.169802635208775, 126.88399438613301),
                new window.kakao.maps.LatLng(35.169317576005, 126.88411891405663),
                new window.kakao.maps.LatLng(35.16907615729475, 126.88408821813097),
                new window.kakao.maps.LatLng(35.16630996167808, 126.88537111837603),
                new window.kakao.maps.LatLng(35.1661549341854, 126.88555537023778),
                new window.kakao.maps.LatLng(35.16139906961842, 126.88778040806353),
                new window.kakao.maps.LatLng(35.160753184973146, 126.89272934556192),
                new window.kakao.maps.LatLng(35.16058692282654, 126.8931259004157),
                new window.kakao.maps.LatLng(35.15993122138664, 126.89443148829947),
                new window.kakao.maps.LatLng(35.15958104984546, 126.89516131988128),
                new window.kakao.maps.LatLng(35.15902717690824, 126.8965080783117),
                new window.kakao.maps.LatLng(35.158495797608765, 126.89796832070823),
                new window.kakao.maps.LatLng(35.158072714904335, 126.89878876303052),
                new window.kakao.maps.LatLng(35.15792896665128, 126.89901461880356),
                new window.kakao.maps.LatLng(35.15681886871133, 126.90049784236561),
                new window.kakao.maps.LatLng(35.15665648857582, 126.90066712705207),
                new window.kakao.maps.LatLng(35.15546556412364, 126.90184344147706),
                new window.kakao.maps.LatLng(35.15477116413364, 126.90262094127085),
                new window.kakao.maps.LatLng(35.154713489307426, 126.90269920750758),
                new window.kakao.maps.LatLng(35.154541295631574, 126.90293313708968),
                new window.kakao.maps.LatLng(35.15427583497752, 126.90329648444876),
                new window.kakao.maps.LatLng(35.15405524432026, 126.90359479406823),
                new window.kakao.maps.LatLng(35.15399643199194, 126.9036747724336),
                new window.kakao.maps.LatLng(35.153380248004034, 126.90451593469315),
                new window.kakao.maps.LatLng(35.15330779504008, 126.90461479344388),
                new window.kakao.maps.LatLng(35.15309551946706, 126.90492302932213),
                new window.kakao.maps.LatLng(35.152501677939256, 126.90571613331153),
                new window.kakao.maps.LatLng(35.15206653115648, 126.90630034803654),
                new window.kakao.maps.LatLng(35.15201771170446, 126.90636659265374),
                new window.kakao.maps.LatLng(35.151454819832246, 126.90713341142244),
                new window.kakao.maps.LatLng(35.15153943432953, 126.90727803430096),
                new window.kakao.maps.LatLng(35.152264033560094, 126.90817558256965),
                new window.kakao.maps.LatLng(35.152439400966806, 126.90839795863627),
                new window.kakao.maps.LatLng(35.15249085961258, 126.9084716393501),
                new window.kakao.maps.LatLng(35.152684464452165, 126.90876687937869),
                new window.kakao.maps.LatLng(35.152708430558185, 126.9088009719152),
                new window.kakao.maps.LatLng(35.15298346659805, 126.90915081960422),
                new window.kakao.maps.LatLng(35.15308749942211, 126.90928188314103),
                new window.kakao.maps.LatLng(35.153271475215014, 126.90951677690767),
                new window.kakao.maps.LatLng(35.1533954940815, 126.90962570527107),
                new window.kakao.maps.LatLng(35.153606388617725, 126.90989383522268),
                new window.kakao.maps.LatLng(35.15390847731653, 126.91026681700163),
                new window.kakao.maps.LatLng(35.15434929895339, 126.91082364179364),
                new window.kakao.maps.LatLng(35.154624326287056, 126.91116972733215),
                new window.kakao.maps.LatLng(35.154857725492704, 126.9114032034494),
                new window.kakao.maps.LatLng(35.15511301965724, 126.9117239410763),
                new window.kakao.maps.LatLng(35.15555074750642, 126.91230223101782),
                new window.kakao.maps.LatLng(35.15558738128244, 126.9123303086467),
                new window.kakao.maps.LatLng(35.15578620793457, 126.9124732845657),
                new window.kakao.maps.LatLng(35.160523064785174, 126.91435588721434),
                new window.kakao.maps.LatLng(35.163207381100406, 126.91541973786815),
                new window.kakao.maps.LatLng(35.16405931451299, 126.91575738899938),
                new window.kakao.maps.LatLng(35.165489018030826, 126.91632055314817),
                new window.kakao.maps.LatLng(35.16367028347061, 126.92397566524357),
                new window.kakao.maps.LatLng(35.16159219626494, 126.92975064864252),
                new window.kakao.maps.LatLng(35.16166199485959, 126.93511260589197),
                new window.kakao.maps.LatLng(35.161781314552286, 126.93724819738487),
                new window.kakao.maps.LatLng(35.160265150793414, 126.9385755559152),
                new window.kakao.maps.LatLng(35.15976485158392, 126.93843495173678),
                new window.kakao.maps.LatLng(35.15937954470586, 126.93837043364897),
                new window.kakao.maps.LatLng(35.159019004534244, 126.93942755281711),
                new window.kakao.maps.LatLng(35.158785211177644, 126.93969217381775),
                new window.kakao.maps.LatLng(35.15867162477172, 126.93983802509767),
                new window.kakao.maps.LatLng(35.1585394036934, 126.94044108338787),
                new window.kakao.maps.LatLng(35.15856366455617, 126.9407931364146),
                new window.kakao.maps.LatLng(35.15861242814815, 126.94113711547882),
                new window.kakao.maps.LatLng(35.15876342644295, 126.94233299350992),
                new window.kakao.maps.LatLng(35.15874054458792, 126.94248821440982),
                new window.kakao.maps.LatLng(35.15865042191541, 126.94367534360887),
                new window.kakao.maps.LatLng(35.15775299577362, 126.94514633589871),
                new window.kakao.maps.LatLng(35.15599346188357, 126.9456148466117),
                new window.kakao.maps.LatLng(35.1544208649481, 126.94905997957972),
                new window.kakao.maps.LatLng(35.15434194106813, 126.94989841453186),
                new window.kakao.maps.LatLng(35.15429227597627, 126.95036539472542),
                new window.kakao.maps.LatLng(35.15425971646671, 126.9506380638314),
                new window.kakao.maps.LatLng(35.15420112999738, 126.95131306699855),
                new window.kakao.maps.LatLng(35.1526629085152, 126.95410295017048),
                new window.kakao.maps.LatLng(35.152344055936766, 126.95485163785627),
                new window.kakao.maps.LatLng(35.150815827188424, 126.95556395909293),
                new window.kakao.maps.LatLng(35.15058182347766, 126.95536655122821),
                new window.kakao.maps.LatLng(35.148927944567255, 126.95491959361847),
                new window.kakao.maps.LatLng(35.14832922127974, 126.95411111044712),
                new window.kakao.maps.LatLng(35.1472817970238, 126.95371099187776),
                new window.kakao.maps.LatLng(35.1464722225386, 126.95395461242992),
                new window.kakao.maps.LatLng(35.145089266040515, 126.95339164368403),
                new window.kakao.maps.LatLng(35.14488405252203, 126.95300236437186),
                new window.kakao.maps.LatLng(35.14474921099951, 126.95215610416491),
                new window.kakao.maps.LatLng(35.14445187082533, 126.95173483216679),
                new window.kakao.maps.LatLng(35.1437528075303, 126.95151973840191),
                new window.kakao.maps.LatLng(35.14358846197232, 126.95153886680005),
                new window.kakao.maps.LatLng(35.143182901164636, 126.95165964557974),
                new window.kakao.maps.LatLng(35.141855925586384, 126.95372633943181),
                new window.kakao.maps.LatLng(35.14078810776068, 126.95484261773636),
                new window.kakao.maps.LatLng(35.140614020771, 126.95668721110333),
                new window.kakao.maps.LatLng(35.13996966274905, 126.9581630884707),
                new window.kakao.maps.LatLng(35.140435436615334, 126.95864000502861),
                new window.kakao.maps.LatLng(35.140520165259936, 126.95887210655994),
                new window.kakao.maps.LatLng(35.139806338338985, 126.963322525968),
                new window.kakao.maps.LatLng(35.13768892205866, 126.9668328793271),
                new window.kakao.maps.LatLng(35.13685874401219, 126.9680515239303),
                new window.kakao.maps.LatLng(35.13654750583727, 126.97132317237077),
                new window.kakao.maps.LatLng(35.13783098338751, 126.97506919300126),
                new window.kakao.maps.LatLng(35.137789889937565, 126.97586609731034),
                new window.kakao.maps.LatLng(35.1381793485568, 126.97745410277685),
                new window.kakao.maps.LatLng(35.1382508002182, 126.97773629044491),
                new window.kakao.maps.LatLng(35.13794492684833, 126.98012499340328),
                new window.kakao.maps.LatLng(35.137781401351404, 126.98171539505485),
                new window.kakao.maps.LatLng(35.13449772812023, 126.98511312655326),
                new window.kakao.maps.LatLng(35.13202392237876, 126.98621337785802),
                new window.kakao.maps.LatLng(35.12849585396365, 126.98774918009185),
                new window.kakao.maps.LatLng(35.12647436721488, 126.99001574340826),
                new window.kakao.maps.LatLng(35.125138043708, 126.99096732271765),
                new window.kakao.maps.LatLng(35.122770130742296, 126.99248089813561),
                new window.kakao.maps.LatLng(35.12326580399892, 126.9961100941248),
                new window.kakao.maps.LatLng(35.12314613771649, 126.99721180340507),]
            }
            ,
            {
                name: '광산구', path: [new window.kakao.maps.LatLng(35.14661164154581, 126.64470284577456),
                new window.kakao.maps.LatLng(35.14403568742382, 126.64756409792506),
                new window.kakao.maps.LatLng(35.14395995835227, 126.65331605527317),
                new window.kakao.maps.LatLng(35.14091311252728, 126.65282701042041),
                new window.kakao.maps.LatLng(35.137014098935715, 126.65495154849617),
                new window.kakao.maps.LatLng(35.132112692341856, 126.65356808946088),
                new window.kakao.maps.LatLng(35.12041185620218, 126.65165860824834),
                new window.kakao.maps.LatLng(35.117814450111545, 126.65496068937487),
                new window.kakao.maps.LatLng(35.1132836553805, 126.65705889544112),
                new window.kakao.maps.LatLng(35.114800957033516, 126.66338878819806),
                new window.kakao.maps.LatLng(35.111257965726764, 126.66910870169643),
                new window.kakao.maps.LatLng(35.10460244541672, 126.66942088782493),
                new window.kakao.maps.LatLng(35.10488692199365, 126.676329201621),
                new window.kakao.maps.LatLng(35.1075508114033, 126.68001074304283),
                new window.kakao.maps.LatLng(35.1084371132129, 126.68510238383972),
                new window.kakao.maps.LatLng(35.112017224962614, 126.6848998474141),
                new window.kakao.maps.LatLng(35.11030164920422, 126.6914808248478),
                new window.kakao.maps.LatLng(35.10766870232761, 126.69569415367589),
                new window.kakao.maps.LatLng(35.1090931479958, 126.70003332742013),
                new window.kakao.maps.LatLng(35.112324520954196, 126.70162842046422),
                new window.kakao.maps.LatLng(35.11294209536566, 126.70535504036171),
                new window.kakao.maps.LatLng(35.10953262455996, 126.70862254019218),
                new window.kakao.maps.LatLng(35.110739643167896, 126.71548859495128),
                new window.kakao.maps.LatLng(35.10856656107851, 126.71910849076725),
                new window.kakao.maps.LatLng(35.10774133402999, 126.73075314458728),
                new window.kakao.maps.LatLng(35.11293198080419, 126.73243982244885),
                new window.kakao.maps.LatLng(35.10822710451143, 126.73643742682233),
                new window.kakao.maps.LatLng(35.10530971222777, 126.74298482432091),
                new window.kakao.maps.LatLng(35.10029093602243, 126.74912334727898),
                new window.kakao.maps.LatLng(35.09167765497342, 126.7542820084352),
                new window.kakao.maps.LatLng(35.09103305417358, 126.76187234958351),
                new window.kakao.maps.LatLng(35.084271030200824, 126.7633121487253),
                new window.kakao.maps.LatLng(35.0836879908872, 126.76534449259911),
                new window.kakao.maps.LatLng(35.078754646484896, 126.76480207850486),
                new window.kakao.maps.LatLng(35.07268178401058, 126.77054431455787),
                new window.kakao.maps.LatLng(35.07017020716222, 126.77135310040839),
                new window.kakao.maps.LatLng(35.07160066598806, 126.77965331048532),
                new window.kakao.maps.LatLng(35.0756156929435, 126.7866229411888),
                new window.kakao.maps.LatLng(35.08439673166336, 126.7940252812884),
                new window.kakao.maps.LatLng(35.087990315078436, 126.79598543460438),
                new window.kakao.maps.LatLng(35.09161432407946, 126.80221370241242),
                new window.kakao.maps.LatLng(35.09727663844863, 126.80063042191834),
                new window.kakao.maps.LatLng(35.10087989499493, 126.8010124766289),
                new window.kakao.maps.LatLng(35.102849999024514, 126.80640681676697),
                new window.kakao.maps.LatLng(35.11226942897783, 126.80280840681428),
                new window.kakao.maps.LatLng(35.11239527063286, 126.80941193331178),
                new window.kakao.maps.LatLng(35.11636049054824, 126.80964294627732),
                new window.kakao.maps.LatLng(35.1165958893367, 126.81675408509862),
                new window.kakao.maps.LatLng(35.120729045249256, 126.81694496754298),
                new window.kakao.maps.LatLng(35.126650758010626, 126.82224903432697),
                new window.kakao.maps.LatLng(35.12976535608055, 126.81902976481285),
                new window.kakao.maps.LatLng(35.132821068915504, 126.81900889594772),
                new window.kakao.maps.LatLng(35.135234698808645, 126.82373206747216),
                new window.kakao.maps.LatLng(35.140598940625395, 126.82932300515277),
                new window.kakao.maps.LatLng(35.148617346629266, 126.82912985919977),
                new window.kakao.maps.LatLng(35.153672781607014, 126.82538074776133),
                new window.kakao.maps.LatLng(35.160448157085014, 126.82607253692825),
                new window.kakao.maps.LatLng(35.1638650301199, 126.8241704430199),
                new window.kakao.maps.LatLng(35.16802512930677, 126.82791854060578),
                new window.kakao.maps.LatLng(35.17120450397965, 126.83763154005494),
                new window.kakao.maps.LatLng(35.17665679513146, 126.83770619920729),
                new window.kakao.maps.LatLng(35.181863056695434, 126.84138216090102),
                new window.kakao.maps.LatLng(35.182210719037975, 126.84477645745372),
                new window.kakao.maps.LatLng(35.179572339807066, 126.8471133720044),
                new window.kakao.maps.LatLng(35.1798139470273, 126.85086664225639),
                new window.kakao.maps.LatLng(35.1824189766812, 126.85325843688729),
                new window.kakao.maps.LatLng(35.183175058356916, 126.85871008628439),
                new window.kakao.maps.LatLng(35.185703783746234, 126.86040913986999),
                new window.kakao.maps.LatLng(35.19491986292993, 126.85842997368928),
                new window.kakao.maps.LatLng(35.196735383636025, 126.85270401586408),
                new window.kakao.maps.LatLng(35.20136135893655, 126.85532471655316),
                new window.kakao.maps.LatLng(35.20579740876895, 126.85510829294684),
                new window.kakao.maps.LatLng(35.210730549426536, 126.85264692481891),
                new window.kakao.maps.LatLng(35.22193348041928, 126.85304369775629),
                new window.kakao.maps.LatLng(35.22196193685098, 126.84619427385836),
                new window.kakao.maps.LatLng(35.22588848996752, 126.84472774522781),
                new window.kakao.maps.LatLng(35.22405819875515, 126.83864409039475),
                new window.kakao.maps.LatLng(35.227365140718064, 126.8368672228701),
                new window.kakao.maps.LatLng(35.23191549411294, 126.82534148240347),
                new window.kakao.maps.LatLng(35.22578107887389, 126.82394322758441),
                new window.kakao.maps.LatLng(35.224655757204985, 126.81564408455073),
                new window.kakao.maps.LatLng(35.2193080754893, 126.8062244269327),
                new window.kakao.maps.LatLng(35.2197986188479, 126.79527046479062),
                new window.kakao.maps.LatLng(35.223894605282425, 126.78953594737027),
                new window.kakao.maps.LatLng(35.22330062012231, 126.78763877049622),
                new window.kakao.maps.LatLng(35.225677348288535, 126.78019708151572),
                new window.kakao.maps.LatLng(35.230574961605285, 126.77536336904191),
                new window.kakao.maps.LatLng(35.23397311689662, 126.76030969744234),
                new window.kakao.maps.LatLng(35.23436123543294, 126.75525910012112),
                new window.kakao.maps.LatLng(35.237086744384406, 126.75384980993594),
                new window.kakao.maps.LatLng(35.24334151654466, 126.75559659635682),
                new window.kakao.maps.LatLng(35.24744042414113, 126.76081645822865),
                new window.kakao.maps.LatLng(35.25336749055775, 126.76370514919934),
                new window.kakao.maps.LatLng(35.25854689355607, 126.76118335950848),
                new window.kakao.maps.LatLng(35.25646009214384, 126.75505547322558),
                new window.kakao.maps.LatLng(35.25588620746876, 126.7494549343086),
                new window.kakao.maps.LatLng(35.25333236113688, 126.74839321352113),
                new window.kakao.maps.LatLng(35.25080798201381, 126.74177451523032),
                new window.kakao.maps.LatLng(35.2507625942178, 126.73630100004037),
                new window.kakao.maps.LatLng(35.24338827720097, 126.72990010455081),
                new window.kakao.maps.LatLng(35.23730101590622, 126.72909043093678),
                new window.kakao.maps.LatLng(35.23366885732812, 126.72491005107638),
                new window.kakao.maps.LatLng(35.22179869011581, 126.71847734430618),
                new window.kakao.maps.LatLng(35.21883004682076, 126.7207217631757),
                new window.kakao.maps.LatLng(35.21238966196749, 126.71769487049148),
                new window.kakao.maps.LatLng(35.21227280177183, 126.71069933095724),
                new window.kakao.maps.LatLng(35.2075644439578, 126.70255613945079),
                new window.kakao.maps.LatLng(35.2092648623797, 126.69700919443223),
                new window.kakao.maps.LatLng(35.21351124796025, 126.69127010441757),
                new window.kakao.maps.LatLng(35.21521282129969, 126.68712233671981),
                new window.kakao.maps.LatLng(35.20984213254403, 126.6845428080472),
                new window.kakao.maps.LatLng(35.20799394305251, 126.68068601650589),
                new window.kakao.maps.LatLng(35.20404010159252, 126.67997763827645),
                new window.kakao.maps.LatLng(35.202859984606974, 126.67773505023081),
                new window.kakao.maps.LatLng(35.19655626774235, 126.6764926749922),
                new window.kakao.maps.LatLng(35.193764168830945, 126.67784387474273),
                new window.kakao.maps.LatLng(35.19124140906815, 126.67449250901487),
                new window.kakao.maps.LatLng(35.1944916790645, 126.66960256055565),
                new window.kakao.maps.LatLng(35.192262663201674, 126.6590708851031),
                new window.kakao.maps.LatLng(35.19409859444359, 126.65624555937674),
                new window.kakao.maps.LatLng(35.19251802512111, 126.65320393128478),
                new window.kakao.maps.LatLng(35.186285323084874, 126.65792049653767),
                new window.kakao.maps.LatLng(35.185236871634835, 126.66123610541462),
                new window.kakao.maps.LatLng(35.174887115516725, 126.66846732263765),
                new window.kakao.maps.LatLng(35.17308975536826, 126.67211672959233),
                new window.kakao.maps.LatLng(35.169517507188466, 126.67042955076289),
                new window.kakao.maps.LatLng(35.16844007143907, 126.6642841905106),
                new window.kakao.maps.LatLng(35.16639930956429, 126.66264837231309),
                new window.kakao.maps.LatLng(35.16661598258489, 126.65707845491329),
                new window.kakao.maps.LatLng(35.16419727817806, 126.65397485589504),
                new window.kakao.maps.LatLng(35.15887843219149, 126.65533578553077),
                new window.kakao.maps.LatLng(35.154818434283996, 126.6514174462627),
                new window.kakao.maps.LatLng(35.15301076484775, 126.6535568496047),
                new window.kakao.maps.LatLng(35.14717518439839, 126.64910571508608),
                new window.kakao.maps.LatLng(35.14661164154581, 126.64470284577456),
                ]
            }
            // Add more areas as needed
        ];
        const displayArea = (area: any) => {
            const polygon = new window.kakao.maps.Polygon({
                map: map,
                path: area.path,
                strokeWeight: 2,
                strokeColor: '#fff',
                strokeOpacity: 1,
                fillColor: '#cccccc',
                fillOpacity: 0.7,
                zIndex: 10
            });
            // const polygonList: any[] = [];
            polygonList.push(polygon);

        };


        for (let i = 0, len = areas.length; i < len; i++) {
            displayArea(areas[i]);
        }
        window.kakao.maps.event.addListener(map, 'click', (mouseEvent: any) => {
            const clickedLatLng = mouseEvent.latLng;
            console.log("lng :: ", clickedLatLng.getLng(), "lat :: ", clickedLatLng.getLat())
            // setLng(clickedLatLng.getLng());
            // setLat(clickedLatLng.getLat());
        });

    }, []);

    const handleCloseInfowindow = () => {
        infoWindowList.map((list) => {
            list.close();
        });
        setInfoWindowList([]);
        setMarkerList([]);
    }



    const markerClickEvent = (index: number) => {
        infoWindowList.map((list) => {
            if (infoWindowList[index]) {
                list.close();
            }
        });
    };
    return (
        <div
            className="map_inner"
            id="map"
            style={{ width: '100%', height: '100%' }}
        >
            {/* <div className="myPositionButton">
            <img
                src={myPositionImg}
                style={{ zIndex: '1000', position: 'absolute', width: '23px' }}
            ></img>
        </div> */}
            {/* <div className="infoWindow_wrap2 pa20" style={{ zIndex: '10' }}>
                <a className="btn_infoClose">닫기</a>
                <p>지원마을에너지전환연대</p>
                <h3>광주 동구 무등로449번길 12</h3>
                <h4>70kW</h4>
                <div className="plus_info">
                    <p className="name">동구환경관리 사업소</p>
                    <p className="etc">음영</p>
                </div>
            </div> */}
        </div >
    );
};



export default EnergyVillageMapComponent;