import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import { BarChartData, BarChartDataList } from '../../models/ChartList';
// const data = [
//     { 'dong': '송정동', '에너지소비량': 180000 },
//     { 'dong': '도산동', '에너지소비량': 175000 },
//     { 'dong': '신흥동', '에너지소비량': 170000 },
//     { 'dong': '어룡동', '에너지소비량': 160000 },
//     { 'dong': '우산동', '에너지소비량': 158000 },
//     { 'dong': '월곡동', '에너지소비량': 155000 },
//     { 'dong': '운남동', '에너지소비량': 141000 },
//     { 'dong': '하남동', '에너지소비량': 138000 },
//     { 'dong': '첨단동', '에너지소비량': 135000 },
//     { 'dong': '비아동', '에너지소비량': 122000 },
//     { 'dong': '신가동', '에너지소비량': 118000 },
//     { 'dong': '신창동', '에너지소비량': 117000 },
//     { 'dong': '평동', '에너지소비량': 111000 },
//     { 'dong': '임곡동', '에너지소비량': 100000 },
//     { 'dong': '등곡동', '에너지소비량': 90000 },
//     { 'dong': '수완동', '에너지소비량': 82000 },
//     { 'dong': '삼도동', '에너지소비량': 80000 },
//     { 'dong': '본량동', '에너지소비량': 77000 },
// ]

const ChartComponent_bar = ({ data, dongSelect, category, handleChartDongSelect, tab }: { data?: BarChartDataList, dongSelect: string, category: string, handleChartDongSelect: (value: string) => void, tab: number }) => {
    const [barSelete, setBarSelect] = useState<string>(dongSelect)
    const [activeIndex, setActiveIndex] = useState(0);
    const [dataChange, setDataChange] = useState(false);
    const [barChartData, setBarChartData] = useState([] as BarChartData[])
    useEffect(() => {
        console.log("차트 데이터", data?.data.length)
        if (category === '승용차' || category === '승합차' || category === '특수차' || category === '화물차') {
            if (data?.data) {
                setBarChartData(data?.data);
            }
        } else {
            if (data?.data) {
                setBarChartData(data?.data);
            }
        }
    }, [data])

    useEffect(() => {
        setBarSelect(dongSelect);
    }, [dongSelect])
    const handleClick = (item: any) => {
        // console.log(item);
        setBarSelect(item.area);
        handleChartDongSelect(item.area);
        // const index = data?.bulddata.indexOf(item.payload);
        // setActiveIndex(Number(index));
    };
    const formatYAxis = (tickItem: any) => tickItem.toLocaleString();

    const CustomTooltip = (e: any) => {
        const value = e.payload[0]?.value;

        if (value === 0|| value === undefined|| value === null) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '3px', boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.1)' }}>
                    <p className="label">데이터 자료 없음</p>
                </div>
            );
        } else {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '3px', boxShadow: '2px 2px 7px rgba(0, 0, 0, 0.1)' }}>
                    <p className="label">
                        {`${e.label} ${
                            tab === 0 ? '에너지소비량' : ''
                        }${tab === 1 ? '온실가스 배출량' : ''}${
                            tab === 2 ? '인구수 원단위' : ''
                        } : ${value?.toLocaleString()}${
                            tab === 0 ? ' TOE' : ''
                        }${tab === 1 ? ' tCO2eq' : ''}${
                            tab === 2 ? ' tonCO2eq/인' : ''
                        }`}
                    </p>
                </div>
            );
        }

    };

    return (
        barChartData.length != 0 ?
            <ResponsiveContainer width='100%' height="100%" aspect={barChartData.length > 50 ? 0.4 : 1.6}>
                <BarChart margin={{ top: 0, right: 5, bottom: 0, left: 5 }} data={barChartData} barCategoryGap={3} layout="vertical">
                    {/* <CartesianGrid strokeDasharray="3 0" /> */}
                    <CartesianGrid vertical={true} horizontal={false} />
                    <XAxis
                        // dataKey="data"
                        tickSize={0}
                        tick={{ stroke: '#000', strokeWidth: 0 }}
                        tickMargin={10}
                        type='number'
                        tickFormatter={formatYAxis}
                    // scale="auto"
                    />
                    <YAxis
                        dataKey="area"
                        type='category'
                        minTickGap={1}
                    />
                    <Tooltip cursor={true} content={CustomTooltip} formatter={formatYAxis} separator=' - ' />
                    <Bar dataKey="data" fill="#8884d8" onClick={handleClick} maxBarSize={30}>
                        {barChartData.map((list, index) => (
                            <Cell
                                key={list.area}
                                cursor="pointer"
                                fill={list.area === barSelete ? '#FE2E64' : '#58ACFA'}
                            />
                        ))}

                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            :
            <div>데이터 없음</div>
    );
};

export default ChartComponent_bar;