import React, { useEffect, useState } from 'react';
import { DialogProps, useDialog } from '../DialogProvider';
import camImg from '../../assets/ic_cam.png';
import test01 from '../../assets/test01.png';
import './WithdrawalDialog.scss'
import { MyPoint } from '../../models/MissionInfo';
import MissionAPI from '../../apis/MissionAPI';
import MemberAPI from '../../apis/MemberAPI';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import moment from 'moment';
import useMember from '../../hooks/useMember';

// 회원탈퇴 다이얼로그
interface WithdrawalDialog extends DialogProps {

}

const WithdrawalDialog = ({ ...props }: WithdrawalDialog) => {
    const { logout } = useMember();
    const { showDialog } = useDialog();
    const navigate = useNavigate();
    const [myPoint, setMyPoint] = useState<MyPoint>();
    const [checkOK, setCheckOk] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClose = () => {
        props.onClose?.();
    }

    // 내 포인트 조회
    useEffect(() => {
        MissionAPI.getMyPoint().then((res) => {
            // console.log('getMyPoint::::::', res);
            setMyPoint(res.data);
        });
    }, []);

    const todayDate = new Date();
    const formattedDate = moment(todayDate).format("YYYY-MM-DD HH:MM:SS")



    // console.log(isDialogOpen)

    // 탈퇴 유의사항 체크
    const handleWithdrawal = () => {
        if (!checkOK) {
            const message = "유의사항 확인 후 동의에 체크해주세요.";
            const title = "회원 탈퇴";
            showDialog(OneButtonAlertDialog, {
                message,
                title,
                handleDialogResult(result) {
                    if (result === 'ok') {
                    }
                },
            });
            return;
        }
        performWithdrawal();
    }

    // 탈퇴
    const performWithdrawal = () => {
        MemberAPI.updateMember({
            delDt: formattedDate
        }).then((res) => {
            // console.log('탈퇴완료', res);
            const message = "에너지전환마을 플랫폼에서 탈퇴하였습니다. 이용해주셔서 감사합니다.";
            const title = "회원 탈퇴";
            showDialog(OneButtonAlertDialog, {
                message,
                title,
                handleDialogResult(result) {
                    if (result === 'ok') {
                        // window.location.reload();
                        // logout();
                        MemberAPI.logout().then((res) => {
                            if (res == 200) {
                                logout();
                                navigate('/');
                                props.onClose?.();
                                window.location.reload();
                            }
                        })
                        // setTimeout(() => {
                        // }, 0);
                    }
                },
            });

        });
    }

    return (
        <div className='WithdrawalDialog'>
            <div className="comm_popup" style={{ display: 'block' }}>
                <div className="comm_popup_wrap pa30">
                    <div className="comm_popup_title">
                        <h3>플랫폼 탈퇴</h3>
                    </div>
                    <div className="comm_popup_content mt10">
                        <p>
                            ※ 탈퇴 시 유의사항을 꼭 확인해주세요!!<br /><br />
                            - 광주광역시 에너지전환마을 지원 플랫폼 탈퇴 시 <span>회원이 보유하고 있던 포인트는 모두 소멸되며, 복구가 불가능</span>합니다.<br /><br />
                            - 광주광역시 에너지전환마을 지원 플랫폼 탈퇴 시 재가입하더라도 이전 아이디에서 보유하고 있던 포인트, 에너지전환활동 현황, 작성한 게시물, 마을 운영자 또는 마을활동가 권한 등 모든 서비스 이용기록은 복구되지 않습니다. 단, 법령에 의해 보관해야 하는 경우 또는 부정사용 방지 등의 사유로 내부정책에 의해 보관해야 하는 자료는 회원탈퇴 후에도 일정기간 보관됩니다.
                            자세한 사항은 광주광역시 에너지전환마을 지원 플랫폼 개인정보 처리방침에서 확인해주세요.
                        </p>
                        <div className="box_point pa20 mt25">
                            <h4>내 비가용 포인트&nbsp;&nbsp;{myPoint?.disAblePt ? myPoint?.disAblePt.toLocaleString() : 0} E</h4>
                            <p>현재 가용 포인트&nbsp;&nbsp;{myPoint?.ablePt ? myPoint?.ablePt.toLocaleString() : 0} E</p>
                        </div>
                        <div className="box_check mt25">
                            <label htmlFor="check1-2">
                                <input type="checkbox" id="check1-2" onChange={() => setCheckOk(true)} />
                                <span>
                                    유의사항을 모두 확인했으며, 회원탈퇴 시 포인트가 소멸되는 것이 동의합니다.
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="comm_popup_btn mt25">
                        <button type="button" className="btn_cancel" onClick={handleClose}>취소</button>
                        <button type="submit" className="btn_check" onClick={handleWithdrawal}>확인</button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default WithdrawalDialog;