import React, { useEffect, useRef, useState } from 'react';
import './Map.scss';
import myPositionImg from '../../assets/myPosition.png';
import { CmMappingList } from '../../models/MappingList';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import useMember from '../../hooks/useMember';
import Marker1 from '../../marker/mk_1.png';
import Marker2 from '../../marker/mk_2.png';
import Marker3 from '../../marker/mk_3.png';
import Marker4 from '../../marker/mk_4.png';
import Marker5 from '../../marker/mk_5.png';
import Marker6 from '../../marker/mk_6.png';
import Marker7 from '../../marker/mk_7.png';
import Marker8 from '../../marker/mk_8.png';
import Marker9 from '../../marker/mk_9.png';
import Marker10 from '../../marker/mk_10.png';
import Marker11 from '../../marker/mk_11.png';
import Marker12 from '../../marker/mk_12.png';
import Marker13 from '../../marker/mk_13.png';
import Marker14 from '../../marker/mk_14.png';
import Marker15 from '../../marker/mk_15.png';

interface ActiveMapComponentProps {
	cmMappingList?: CmMappingList[];
	listIndex: number;
	listClickEvent: boolean;
	search?: string;
	handleListClick: (
		index: number,
		cmUid?: number,
		caTitle?: string,
		crtUid?: number
	) => void;
	cmmUid?: number;
}

export interface MarkerItem {
	index: number;
	cmUid?: number;
	caTitle?: string;
	crtUid?: number;
}

const ActiveMapComponent = ({
	cmMappingList,
	listIndex,
	listClickEvent,
	search,
	handleListClick,
	cmmUid,
}: ActiveMapComponentProps) => {
	const { member } = useMember();
	// const cmmUid = sessionStorage.getItem('cmmUid');
	const [page, setPage] = useState<number>(1);
	const [mapState, setMapState] = useState<any>();
	const [myLatLng, setMyLatLng] = useState();
	const resizeTargetRef = useRef(null);
	const [markerList, setMarkerList] = useState([] as any[]);
	const [infowindpwCss, setInfowindpwCss] = useState(false);
	const [infoWindowList, setInfoWindowList] = useState([] as any[]);
	const [markerClickBeforeImage, setMarkerClickBeforeImage] = useState<any[]>(
		[]
	);
	const [markerClickAfterImage, setMarkerClickAfterImage] = useState<any[]>([]);

	const [lat, setLat] = useState<number>();
	const [lng, setLng] = useState<number>();
	const [isLoading, setIsLoading] = useState(false);
	const [markers, setMarkers] = useState([] as any[]);
	const [findMarkerList, setFindMarkerList] = useState(
		[] as CmMappingList[] | undefined
	);
	useEffect(() => {
		let info_window = document.querySelectorAll('.comm_infoWindow');
		info_window.forEach((e: any) => {
			e.parentElement.parentElement.style.border = '10px';
			e.parentElement.parentElement.style.background = 'unset';
		});
	}, [infowindpwCss]);
	// 인포윈도우 창 열고 닫기
	useEffect(() => {
		if (infoWindowList[listIndex]) {
			if (infoWindowList[listIndex].getMap()) {
				infoWindowList[listIndex].close();
			}

			for (let i = 0; i < infoWindowList.length; i++) {
				if (infoWindowList[i] !== infoWindowList[listIndex]) {
					markerList[i].setImage(markerClickBeforeImage[i]);
					infoWindowList[i].close();
				}
			}
			markerList[listIndex].setImage(markerClickAfterImage[listIndex]);
			infoWindowList[listIndex].open(mapState, markerList[listIndex]);

			mapState.setLevel(2);
			mapState.panTo(markerList[listIndex].getPosition());
			markerList[listIndex].setZIndex(400);
			setInfowindpwCss((cur) => !cur);
		}
	}, [listClickEvent]);

	useEffect(() => {
		const container = document.getElementById('map');
		const options = {
			center: new window.kakao.maps.LatLng(
				35.148227890380454,
				126.8490671051837
			),
			level: 7,
		};
		const map = new window.kakao.maps.Map(container, options);
		setMapState(map);
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				map.relayout();
			}
		});
		if (resizeTargetRef.current) {
			resizeObserver.observe(resizeTargetRef.current);
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					var lat = position.coords.latitude, // 위도
						lon = position.coords.longitude; // 경도

					// 현재 위치 좌표 생성

					const currentLatLng = new window.kakao.maps.LatLng(lat, lon);
					setMyLatLng(currentLatLng);

					var infoWindow = new window.kakao.maps.InfoWindow({
						content: `<div class="myPosition" style="z-index: 400; position: absolute; background-color:red"><h4 class="myPosition_txt">현재 위치</h4></div>`,
					});

					// 현재 위치에 마커 표시
					const marker = new window.kakao.maps.Marker({
						// position: currentLatLng,
						position: new window.kakao.maps.LatLng(lat, lon), // 35.14851596242328, 126.84877575766228
					});
					// 현재 위치 infowindow
					// infoWindow.open(map, marker);
					// 지도 중심 위치 이동
					// map.setCenter(currentLatLng);
					// 회사 위치 -> new window.kakao.maps.LatLng(35.14851596242328, 126.84877575766228)
					// 동구청 -> new window.kakao.maps.LatLng(35.1461417002004, 126.92309316107)
					ActiveMapAPI.getMappingList({
						crtUid: member?.id || undefined,
						cmmUid: Number(cmmUid),
						page: Number(page),
						search: search ? search : undefined,
					}).then((res) => {
						// // // console.log("ggggggggggggggggggggg", res)
						if (res?.cmMappingList) {
							if (res?.cmMappingList.length != 0) {
								map.setCenter(
									new window.kakao.maps.LatLng(
										res.cmMappingList[0]?.cntrLng,
										res.cmMappingList[0]?.cntrLat
									)
								);
							} else {
								map.setCenter(
									new window.kakao.maps.LatLng(
										35.148227890380454,
										126.8490671051837
									)
								); // 35.14851596242328, 126.84877575766228
							}
						}
					});

					// 현재위치 이동 버튼
					const moveToCurrentButton =
						document.querySelector('.myPositionButton');
					moveToCurrentButton?.addEventListener('click', () => {
						map.panTo(new window.kakao.maps.LatLng(lat, lon)); // 35.14851596242328, 126.84877575766228
					});
				},
				(error) => {
					console.error('Error retrieving location:', error);
				}
			);
		} else {
			console.error('Geolocation is not supported.');
		}
		// 지도 클릭 이벤트 핸들러 등록
		window.kakao.maps.event.addListener(map, 'click', (mouseEvent: any) => {
			const clickedLatLng = mouseEvent.latLng;
			// console.log(clickedLatLng);
			setLng(clickedLatLng.getLng());
			setLat(clickedLatLng.getLat());
		});

		var zoomControl = new window.kakao.maps.ZoomControl();
		// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
		// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미
		if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/)) {
			map.addControl(zoomControl, window.kakao.maps.ControlPosition.TOPRIGHT);
		} else {
		}
		// console.log('ㅎ');
		setIsLoading(true);
	}, []);
	const [markerItem, setMarkerItem] = useState<MarkerItem>();
	//마커 인포윈도우 클릭
	const infoWindowClick = (
		index: number,
		cmUid?: number,
		caTitle?: string,
		crtUid?: number
	) => {
		setMarkerItem({
			index,
			cmUid,
			caTitle,
			crtUid,
		});
	};
	// 마커 거리계산
	const getDistance = (
		lat1: number,
		lng1: number,
		lat2: number,
		lng2: number
	) => {
		const radlat1 = (Math.PI * lat1) / 180;
		const radlat2 = (Math.PI * lat2) / 180;
		const theta = lng1 - lng2;
		const radtheta = (Math.PI * theta) / 180;
		let dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		return dist * 1.609344; // kilometer
	};
	const [clustererState, setClustererState] = useState<any>();
	const findNearbyMarkers = (targetMarker: any, allMarkers: any[]) => {
		const radius = 0.1; // 10m
		const nearbyMarkers: any[] = [];
		for (const marker of allMarkers) {
			const distance = getDistance(
				targetMarker.getPosition().La,
				targetMarker.getPosition().Ma,
				marker.getPosition().La,
				marker.getPosition().Ma
			);

			if (distance <= radius) {
				nearbyMarkers.push(marker);
			}
		}
		return nearbyMarkers;
	};
	useEffect(() => {
		// 맵핑 리스트

		setMarkerList([]);
		setInfoWindowList([]);
		setMarkerClickBeforeImage([]);
		setMarkerClickAfterImage([]);
		infoWindowList.forEach((inList, index) => {
			inList.close();
		});
		markerList.forEach((mlist, index) => {
			mlist.setMap(null);
		});
		if (cmMappingList) {
			// 운영자

			const clusterer = new window.kakao.maps.MarkerClusterer({
				map: mapState, // 클러스터를 표시할 지도 객체
				averageCenter: true, // 클러스터의 중심 위치를 평균으로 설정
				minLevel: 2, // 클러스터를 생성하기 위한 최소 지도 레벨
				gridSize: 10,
			});
			setClustererState(clusterer);
			if (cmMappingList) {
				// setCmMappingList(cmMappingList);
				// // // console.log("cmMappingList :::: ", cmMappingList);
				cmMappingList.map((list, index) => {
					var marker = new window.kakao.maps.Marker({
						position: new window.kakao.maps.LatLng(list.cntrLng, list.cntrLat),
					});
					marker.id = list.cmUid;
					marker.index = index;
					// 마커 클러스터에 marker 추가
					clusterer.addMarker(marker);
					setMarkerList((cur) => [...cur, marker]);
					// // // console.log("!!!!!!!!", marker);
					var infoWindow = new window.kakao.maps.InfoWindow({
						content: `<div class="comm_infoWindow">
           								 <div class="infoWindow_wrap pa20">
               								 <p class="category">${list?.caTitle}</p>
               									 <h3 class="txt_cut2">${list?.cmText}</h3>
               								 <p class="txt_cut1">${list?.locInfo}</p>
           								 </div>
       								 </div>`,
						zIndex: 500,
					});

					setInfoWindowList((cur) => [...cur, infoWindow]);
					// if (list.openTp === "A") {
					const markerColor = 'Marker' + list.markerFile.match(/\d+/)?.[0];
					let markerImg = null;
					switch (markerColor) {
						case 'Marker1':
							markerImg = Marker1;
							break;
						case 'Marker2':
							markerImg = Marker2;
							break;
						case 'Marker3':
							markerImg = Marker3;
							break;
						case 'Marker4':
							markerImg = Marker4;
							break;
						case 'Marker5':
							markerImg = Marker5;
							break;
						case 'Marker6':
							markerImg = Marker6;
							break;
						case 'Marker7':
							markerImg = Marker7;
							break;
						case 'Marker8':
							markerImg = Marker8;
							break;
						case 'Marker9':
							markerImg = Marker9;
							break;
						case 'Marker10':
							markerImg = Marker10;
							break;
						case 'Marker11':
							markerImg = Marker11;
							break;
						case 'Marker12':
							markerImg = Marker12;
							break;
						case 'Marker13':
							markerImg = Marker13;
							break;
						case 'Marker14':
							markerImg = Marker14;
							break;
						case 'Marker15':
							markerImg = Marker15;
							break;
					}
					const markerClickBeforeImage = new window.kakao.maps.MarkerImage(
						markerImg,
						new window.kakao.maps.Size(25, 30)
					);
					setMarkerClickBeforeImage((cur) => [...cur, markerClickBeforeImage]);
					const markerClickAfterImage = new window.kakao.maps.MarkerImage(
						markerImg,
						new window.kakao.maps.Size(40, 50)
					);
					setMarkerClickAfterImage((cur) => [...cur, markerClickAfterImage]);
					const markerElement = marker.getImage();
					// // // console.log("markerElementmarkerElement", markerElement);

					marker.setImage(markerClickBeforeImage);
					// marker.setMap(mapState);
					markers?.push(marker);

					window.kakao.maps.event.addListener(marker, 'mouseover', () => {
						// // console.log("마우스오버")
						marker.setZIndex(300);
					});
					window.kakao.maps.event.addListener(marker, 'mouseout', () => {
						if (!infoWindow.getMap()) {
							// // console.log("마우스아웃")
							marker.setZIndex(290);
						}
					});

					var customOverlay = new window.kakao.maps.CustomOverlay({
						xAnchor: 0.3,
						yAnchor: 0.91,
						zIndex: 400,
					});
					window.kakao.maps.event.addListener(marker, 'click', () => {
						let findMarkerListClose = document.querySelectorAll(
							'.findMarkerList'
						) as NodeListOf<HTMLElement>;
						if (findMarkerListClose) {
							for (let i = 0; i < findMarkerListClose.length; i++) {
								findMarkerListClose[i].style.display = 'none';
							}
						}

						// find마커리스트
						if (findNearbyMarkers(marker, markers).length > 1) {
							if (findMarkerList) {
								findMarkerList.splice(0, findMarkerList.length);
							}
							const markerListCheck = findNearbyMarkers(marker, markers);
							for (let i = 0; i < markerListCheck.length; i++) {
								const findMarker = cmMappingList.find(
									(find) => find.cmUid == markerListCheck[i].id
								);
								// // // console.log(findMarker?.cmUid);
								if (findMarkerList) {
									if (findMarker) {
										findMarkerList.push(findMarker);
									}
								}
							}
							// 클릭되지 않은 상태에서 클릭하면 customOverlay 생성 및 표시
							var content = `<div class="findMarkerList" style>
															<div class="closeList"><p>마커 목록</p><a class="listCloseButton">Ⅹ</a></div>
															<ul>
															  ${findMarkerList
									?.map(
										(list, index) =>
											`<li>
																  <p class="findListDetail" key="${list.caTitle}" data-custom-attr1=${markerListCheck[index].index} data-custom-attr2=${list.cmUid} data-custom-attr3=${list.crtUid}>${list.cmText}</p>
																</li>`
									)
									.join('')}
															</ul>
														  </div>`;

							customOverlay.setContent(content);
							customOverlay.setPosition(marker.getPosition());
							if (customOverlay.getMap()) {
								customOverlay.setMap(null);
							} else {
								customOverlay.setMap(mapState);
							}
							// find마커리스트 end
							let listClose = document.querySelectorAll('.listCloseButton');
							let findListDetail = document.querySelectorAll('.findListDetail');
							listClose.forEach((e: any) => {
								e.addEventListener('click', () => {
									e.parentElement.parentElement.style.display = 'none';
								});
							});
							findListDetail.forEach((e: any) => {
								e.addEventListener('click', (event: any) => {
									const index = event.target.dataset.customAttr1;
									const cmUid = event.target.dataset.customAttr2;
									const caTitle = event.target.getAttribute('key');
									const crtUid = event.target.dataset.customAttr3;
									e.parentElement.parentElement.parentElement.style.display =
										'none';
									e.parentElement.parentElement.style.border = '10px';
									e.parentElement.parentElement.style.background = 'unset';

									handleListClick(index, cmUid, caTitle, crtUid);
								});
							});
						} else {
							if (infoWindow.getMap()) {
								marker.setZIndex(10);
								marker.setImage(markerClickBeforeImage);
								infoWindow.close();
							} else {
								marker.setZIndex(310);
								mapState.setCenter(
									new window.kakao.maps.LatLng(list.cntrLng, list.cntrLat)
								);

								marker.setImage(markerClickAfterImage);
								// handleListClick(index, list.cmUid, list.caTitle, list.crtUid);
								infoWindow.open(mapState, marker);
								let info_window = document.querySelectorAll('.comm_infoWindow');
								info_window.forEach((e: any) => {
									e.parentElement.parentElement.style.border = '10px';
									e.parentElement.parentElement.style.background = 'unset';
									e.addEventListener('click', () => {
										infoWindowClick(
											index,
											list.cmUid,
											list.caTitle,
											list.crtUid
										);
										handleListClick(
											index,
											list.cmUid,
											list.caTitle,
											list.crtUid
										);
									});
								});
							}
						}
					});

					// }
				});
			}
		}
		// 맵핑 리스트 end
	}, [cmMappingList]);
	return (
		<>
			<div className="map_inner" id="map" ref={resizeTargetRef}></div>
		</>
	);
};

export default ActiveMapComponent;
