import React, { useEffect, useState } from 'react';
import './Write_ActiveMapDialog.scss';
import { DialogProps, useDialog } from '../DialogProvider';
import { SubmitHandler, useForm } from 'react-hook-form';

import MappingList from '../../models/MappingList';
import PostCodeDialog from './PostCodeDialog';

import camImg from '../../assets/ic_cam.png';
import ActiveMapAPI from '../../apis/ActiveMapAPI';
import { InsertCmMapping } from '../../models/MapInfo';
import SubmitSuccessDialog from '../common/SubmitSuccessDialog';
import SubmitFailDialog from '../common/SubmitFailDialog';
import useMember from '../../hooks/useMember';
import OneButtonAlertDialog from '../OneButtonAlertDialog';
import LocationDialog from './LocationDialog';
export interface Marker {
	spaceName: string;
	category: string;
	address: string;
}
export interface CmmappingInsertInput {
	cmMappingList: CmMappingList[];
}

interface CmMappingList {
	cmcUid: number;
	cmmUid: number;
	crtUid: number;
	cmTitle: string;
	cntrLat: number;
	cntrLng: number;
	locInfo: string;
}

interface Write_ActiveMapDialog extends DialogProps {
	onComplete: (marker: Marker) => void;
	mappingList?: MappingList;
}
const Write_ActiveMapDialog = ({
	mappingList,
	onClose,
	...props
}: Write_ActiveMapDialog) => {
	const { member } = useMember();
	const cmmUid = sessionStorage.getItem('cmmUid');
	const townId = sessionStorage.getItem('town');
	const handleClose = () => {
		onClose?.();
	};
	const { showDialog } = useDialog();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<InsertCmMapping>();

	const geocoder = new window.kakao.maps.services.Geocoder();
	const [selectLat, setSelectLat] = useState<number>();
	const [selectLng, setSelectLng] = useState<number>();
	// 주소검색
	const [address, setAddress] = useState('');
	// 경도 x
	const [lat, setLat] = useState();
	// 위도 y
	const [lng, setLng] = useState();
	const handleSearch = () => {
		showDialog(LocationDialog, {
			// handleAddressSubmit(address) {
			// 	setAddress(address);
			// },
			handleAddressSubmit(lat: number, lng: number) {
				setSelectLat(lat);
				setSelectLng(lng);
				// // console.log("주소입력 다이얼로그 lat", lat);
				// // console.log("주소입력 다이얼로그 lng", lng);
				// 좌표를 주소로 변환
				geocoder.coord2Address(lat, lng, (result: any, status: any) => {
					if (status === window.kakao.maps.services.Status.OK) {
						if (result[0]) {
							const address = result[0].address.address_name;
							// // console.log("주소:", address);
							setAddress(address);
						} else {
							// // console.log("주소를 찾을 수 없습니다.");
						}
					} else {
						// // console.log("Geocoder 실패:", status);
					}
				});
			},
		});
	};

	const onSubmit: SubmitHandler<InsertCmMapping> = async (data) => {
		if (!address) {
			const title = '지도 참여하기';
			const message = '주소는 필수값입니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		} else if (!data.cmTitle) {
			const title = '지도 참여하기';
			const message = '내용은 필수값입니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		// console.log("data.cmcUid >>>>> ", data.cmcUid);
		await ActiveMapAPI.insertCmMapping(
			{
				...data,
				tcUid: Number(townId),
				locInfo: address,
				cmmUid: Number(cmmUid),
				crtUid: Number(member?.id),
				cntrLat: selectLat || 0,
				cntrLng: selectLng || 0,
				cmTitle: data.cmTitle,
			},
			uploadFiles
		)
			.then((res) => {
				// console.log('InsertCmMapping', res);
				const tab = 'activeMapping';
				showDialog(SubmitSuccessDialog, { tab });
				// window.location.reload();
				// onClose?.();
			})
			.catch((e) => {
				showDialog(SubmitFailDialog);
			});
		// window.location.reload();
	};

	// 이미지 파일 첨부
	const [previewImages, setPreviewImages] = useState<string[]>([]);
	const [uploadFiles, setUploadFiles] = useState([] as File[]);
	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// // console.log("files ::::::: ", event.target.files);
		const files = event.target.files;
		if (!files) return;
		const selectedImages = Array.from(files);
		// 이미지를 추가할 때 previewImages 배열의 길이가 5를 넘어가지 않도록 처리
		if (previewImages.length + selectedImages.length > 3) {
			// alert('사진은 최대 5개 까지 업로드할수있다.');
			const title = '사진 첨부';
			const message = '사진은 최대 3장까지 업로드할 수 있습니다.';
			showDialog(OneButtonAlertDialog, { title, message });
			return;
		}
		if (selectedImages && selectedImages[0].size > 15000000) {
			const title = '';
			const message = '파일 크기는 최대 15MB까지만 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		if (!(selectedImages[0].type === 'image/png' || selectedImages[0].type === 'image/jpeg')) {
			const title = '';
			const message = '파일 형식은 png, jpg 만 등록 가능합니다.';
			showDialog(OneButtonAlertDialog, {
				title,
				message,
			});
			return;
		}
		setUploadFiles((prevImages: any) => {
			const newImages = [...prevImages];
			selectedImages.slice(0, 5).forEach((image) => newImages.push(image));
			// // console.log("imagesToSubmit ::::::::::::::::: ", uploadFiles);
			return newImages;
		});
		// 미리보기 이미지 추가
		const imagesToSubmit = selectedImages.slice(0, 3);
		const newPreviewImages = imagesToSubmit.map((file) =>
			URL.createObjectURL(file)
		);
		setPreviewImages((prevImages) => [...prevImages, ...newPreviewImages]);
	};

	// 첨부한 이미지 파일 x 버튼 누를시 삭제
	const handleRemovePreview = (index: number) => {
		setPreviewImages((prevImages) => {
			const newImages = [...prevImages];
			newImages.splice(index, 1);
			return newImages;
		});
		setUploadFiles((prevFiles) => {
			const newFiles = [...prevFiles];
			newFiles.splice(index, 1);
			return newFiles;
		});
	};
	return (
		<div className="Write_ActiveMapDialog">
			<div className="comm_popup" style={{ display: 'block' }}>
				<div className="comm_popup_wrap pa30">
					<div className="comm_popup_title">
						<h3>지도 참여하기</h3>
						<a className="popup_close" onClick={handleClose}>
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
							/>
						</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="comm_popup_content comm_write_wrap mt25">
							<div className="cont-wrap">
								<h3 className="important">주소</h3>
								<div className="cont-box">
									<input
										type="text"
										className="address"
										placeholder="주소를 입력하세요"
										value={address}
									/>
									<button
										type="button"
										className="btn_address"
										onClick={handleSearch}
									>
										위치 찾기
									</button>
									<input
										type="text"
										className="detail_address"
										placeholder="상세주소를 입력하세요"
										{...register('subLocInfo')}
									/>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">분류</h3>
								<div className="cont-box">
									<div className="select-box">
										<select {...register('cmcUid')}>
											{mappingList?.calist.map((list, idx) => (
												<option
													key={idx}
													value={list.cmcUid}
													selected={idx === 0}
												>
													{list.title}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="cont-wrap">
								<h3 className="important">내용</h3>
								<div className="cont-box">
									<textarea
										placeholder="내용을 입력하세요"
										{...register('cmTitle')}
									></textarea>
								</div>
							</div>
							<div className="cont-wrap">
								<h3>사진첨부</h3>
								<div className="cont-box">
									<div className="imgBox">
										<input className="upload-photo" />
										<label htmlFor="img">
											<img src={camImg} alt="사진 첨부" />
											사진 첨부
											<span>(최대 3장)</span>
										</label>
										<input
											type="file"
											id="img"
											accept="image/*"
											onChange={handleImageChange}
										/>
									</div>
									<div className="imglistBox">
										<ul>
											{previewImages.map((imageUrl, index) => (
												<li key={index}>
													<img src={imageUrl} key={imageUrl} />
													<button
														type="button"
														onClick={() => handleRemovePreview(index)}
														className="btn-file-delet"
													/>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="comm_popup_btn mt25">
							<button
								type="button"
								className="btn_cancel"
								onClick={handleClose}
							>
								취소
							</button>
							<button type="submit" className="btn_check">
								등록
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Write_ActiveMapDialog;
