import React, { useEffect } from 'react';
import './ShareDialog.scss';
import ic_popup_close from '../../assets/ic_popup_close.png';
import ic_facebook2 from '../../assets/ic_facebook2.png';
import ic_kakao from '../../assets/ic_kakao.png';
import ic_url from '../../assets/ic_url.png';
import { DialogProps, useDialog } from '../DialogProvider';
import OneButtonAlertDialog from '../OneButtonAlertDialog';

interface ShareDialogProps extends DialogProps {
	id?: string;
}

const ShareDialog = ({ id, ...props }: ShareDialogProps) => {
	const handleClose = () => {
		props.onClose?.();
	};
	const APP_KEY = '71d7c09b225d39c20b7775fba998c79e';
	// const APP_KEY = "257d285c2782ddfa96f5c8975f1cf9b6";

	// useEffect(() => {
	// 	const handleShareKakao = () => {
	// 		if (window.Kakao) {
	// 			if (!window.Kakao.isInitialized()) {
	// 				window.Kakao.init(APP_KEY);
	// 			}
	// 			window.Kakao.Link.sendDefault({
	// 				objectType: "feed",
	// 				content: {
	// 					title: "우리동네 do반장",
	// 					description: "우리동네 do반장 App을 설치해주세요.",
	// 					imageUrl: "../../assets/두반장공유하기.png",
	// 					link: {
	// 						// webUrl: "http://localhost:3000",
	// 						webUrl: "https://do-banjang.kr/",
	// 					},
	// 				},
	// 				buttons: [
	// 					{
	// 						title: "Web으로 이동",
	// 						link: {
	// 							webUrl: window.document.location.href,
	// 							mobileWebUrl: window.document.location.href,
	// 						},
	// 					},
	// 					{
	// 						title: "App으로 이동",
	// 						link: {
	// 							mobileWebUrl: "",
	// 						},
	// 					},
	// 				],
	// 			});
	// 		}
	// 	};
	// 	const script = document.createElement("script");
	// 	script.async = true;
	// 	script.src = `https://developers.kakao.com/sdk/js/kakao.js`;
	// 	script.onload = () => {
	// 		const kakaoButton = document.getElementById("kakaoButton");
	// 		if (kakaoButton) {
	// 			kakaoButton.addEventListener("click", handleShareKakao);
	// 		}
	// 	};
	// 	document.head.appendChild(script);
	// }, []);

	///////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.3.0/kakao.js';
		script.integrity =
			'sha384-Wgx4fETw6DVTTTsVn3p1wVE/WG1AgQOlBhuxib2oNQqyQW0YVS+eIIPHNlLi+bAb';
		script.crossOrigin = 'anonymous';
		// script.src = `https://developers.kakao.com/sdk/js/kakao.js`;
		// script.onload = () => {
		// 	const kakaoButton = document.getElementById("kakaoButton");
		// 	if (kakaoButton) {
		// 		kakaoButton.addEventListener("click", handleShareKakao);
		// 	}
		// };
		document.head.appendChild(script);
	}, []);

	const handleShareKakao = () => {
		const title = '카카오톡 공유하기';
		const message = '현재 준비중인 서비스입니다.';
		showDialog(OneButtonAlertDialog, {
			title,
			message,
		});
		// if (window.Kakao) {
		// 	if (!window.Kakao.isInitialized()) {
		// 		window.Kakao.init(APP_KEY);
		// 	}

		// 	// window.Kakao.Share.sendCustom({
		// 	// 	templateId: 96309,
		// 	// 	templateArgs: {},
		// 	// });

		// 	window.Kakao.Share.sendDefault({
		// 		objectType: "feed",
		// 		content: {
		// 			title: "우리동네 do반장",
		// 			description: "우리동네 do반장 App을 설치해주세요.",
		// 			imageUrl:
		// 				"https://donggu.kr/dobanjang/api/problem/getFiles?bifUid=359",
		// 			// "https://do-banjang.kr/dobanjang/api/problem/getFiles?bifUid=359",
		// 			// "http://k.kakaocdn.net/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png",

		// 			link: {
		// 				mobileWebUrl: "https://donggu.kr/dobanjang",
		// 				// mobileWebUrl: "https://do-banjang.kr/",
		// 				// mobileWebUrl: window.document.location.href,
		// 			},
		// 		},
		// 		buttons: [
		// 			{
		// 				title: "Web으로 이동",
		// 				link: {
		// 					webUrl: window.document.location.href,
		// 					mobileWebUrl: window.document.location.href,
		// 				},
		// 			},
		// 			{
		// 				title: "App으로 이동",
		// 				link: {
		// 					webUrl: window.document.location.href,
		// 					mobileWebUrl: window.document.location.href,
		// 				},
		// 			},
		// 		],
		// 	});
		// }
	};
	const { showDialog } = useDialog();
	const handleUrl = () => {
		let url = '';
		const textarea = document.createElement('textarea');
		document.body.appendChild(textarea);
		url = window.document.location.href + id;
		textarea.value = url;
		textarea.select();
		document.execCommand('copy');
		document.body.removeChild(textarea);

		// alert("URL이 복사되었습니다.");
		const title = '공유하기';
		const message = 'URL이 복사되었습니다.';
		// console.log('url::::::::::::::::', url);

		showDialog(OneButtonAlertDialog, { title, message });

		// alert(sessionStorage.getItem("dialogDepth"));
	};

	return (
		<div className="ShareDialog">
			<div className="share_popup" style={{ display: 'block' }}>
				<div className="white_box">
					<div className="popup_title">
						공유하기
						<a className="popup_close">
							<img
								src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM5RTc0MUY1ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM5RTc0MUY2ODA5MzExRUJCRjlDODBEQjMyRDE3MzBEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzlFNzQxRjM4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzlFNzQxRjQ4MDkzMTFFQkJGOUM4MERCMzJEMTczMEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7tutPzAAAAlklEQVR42pSTyw2AIBBEV7vwc8X+C1BpQwzaiu4aTDZmkYFkDqDvYRwgInKcmdMTPkaOTyytnIuzgRKB98TIxg8UQImG5d3ufYBIsrAlCR9JEf6TwLAlOThnDawlRwIlMQe3GYGsN2refOZwz1F9SUDOifXD/tqBey5KkKqykpqeB0viK3vWErmINHGWmkOSJAK7W4ABAOxMTDsLu+38AAAAAElFTkSuQmCC"
								alt="닫기"
								onClick={handleClose}
							/>
						</a>
					</div>
					<div className="share_sns">
						{/* <span>
							<a >
								<img src={ic_facebook2} alt="페이스북" />
							</a>
							페이스북
						</span> */}
						<span>
							<a>
								<img
									src={ic_kakao}
									alt="카카오톡"
									// id="kakaoButton"
									onClick={(e: any) => {
										handleShareKakao();
										// e.preventDefault();
									}}
								/>
							</a>
							카카오톡
						</span>
						<span>
							<a>
								<img src={ic_url} alt="url" onClick={handleUrl} />
							</a>
							URL
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShareDialog;
