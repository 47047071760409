import React, { useState } from 'react';
import './MyGiftCardPage.scss';
import { useDialog } from '../../../dialog/DialogProvider';
import { useNavigate } from 'react-router';
import OneButtonAlertDialog from '../../../dialog/OneButtonAlertDialog';
import Pagination from '../../../components/common/Pagination';
import MyGiftCardDialog from '../../../dialog/myPage/MyGiftCardDialog';

const MyGiftCardPage = () => {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const handleClick = () => {
		// const title = '내 마을상품권';
		// const message = '현재 준비중인 서비스입니다.';
		// const action = '인증ok';
		// showDialog(OneButtonAlertDialog, {
		// 	title,
		// 	message,
		// 	handleDialogResult(result) {
		// 		if (result === '확인') {
		// 		}
		// 	},
		// });
		showDialog(MyGiftCardDialog);
	};
	//
	const [totalPage, setTotalPage] = useState(5);
	const [page, setPage] = useState<number>(1);
	const [tab, setTab] = useState<number>(1);

	const handlePageChange = (page: number) => {
		setPage(page);
	};
	return (
		<main className="MyGiftCardPage">
			<h1 className="notext">전체 영역</h1>
			<nav id="lnb">
				<h2 className="notext">네비게이션 영역</h2>

				<div className="lnb_txt">
					<h3>내 마을상품권</h3>

					<p>
						내 E 포인트로 에너지전환마을에서 사용할 수 있는 마을상품권으로
						교환하세요
					</p>
				</div>
			</nav>
			<article id="container">
				<h2 className="notext">컨텐츠 영역</h2>
				{/* 해당 페이지의 시작입니다 */}
				<div id="content">
					{/* 탭 시작 */}
					<div className="tabs2">
						<button
							type="button"
							// className="tab2 tab_active"
							className={tab === 1 ? 'tab2 tab_active' : 'tab2 non_active'}
							onClick={() => setTab(1)}
						>
							사용가능
						</button>
						<button
							type="button"
							// className="tab2 non_active"
							className={tab === 2 ? 'tab2 tab_active' : 'tab2 non_active'}
							onClick={() => setTab(2)}
						>
							사용완료
						</button>
					</div>
					{/* 탭 종료 */}
					{/* 사용가능 리스트 시작 */}
					{tab === 1 && (
						<div className="tab_content2 mt40">
							<div className="gift_wrap">
								<ul>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.12.01 까지</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
								</ul>
							</div>
							<Pagination
								page={Number(page)}
								totalPageCount={Number(totalPage)}
								// totalPageCount={Math.ceil(count / 8)}
								onPageChange={handlePageChange}
							/>
						</div>
					)}

					{/* 사용가능 리스트 종료 */}
					{/* 사용완료 리스트 시작 */}
					{tab === 2 && (
						<div className="tab_content2 mt40">
							<div className="gift_wrap end">
								<ul>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">
													거점마을 상품권 거점마을 상품권 거점마을 상품권
													거점마을 상품권
												</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
									<li onClick={handleClick}>
										<a className="pa30">
											<div className="gift_inner">
												<p className="txt_cut1">2023.11.23</p>
												<h3 className="comm_title txt_cut1">거점마을 상품권</h3>
												<p className="point mt5">5,000</p>
											</div>
										</a>
									</li>
								</ul>
							</div>
							<Pagination
								page={Number(page)}
								totalPageCount={Number(totalPage)}
								// totalPageCount={Math.ceil(count / 8)}
								onPageChange={handlePageChange}
							/>
						</div>
					)}

					{/* 사용완료 리스트 종료 */}
				</div>
				{/* 해당 페이지의 종료입니다 */}
			</article>
		</main>
	);
};

export default MyGiftCardPage;
